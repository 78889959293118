import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { bindEvent, unbindEvent } from "../../services/event";
import { ApplicationState } from "../../types";
import debounce from "../debounce/debounce";
import Calendar from "./Calendar";
import styles from "./DateTimePicker.module.css";
import DateTimePickerInputField from "./DateTimePickerInputField";
import { zeroPad } from "./helpers/calendar";
import TimerSelector from "./TimeSelector";

const parseDate = (date: string) => {
  const parts = date.split(".");

  if (parts?.length !== 3) {
    return null;
  }
  const [day, month, year] = parts;
  try {
    if (!isFinite(+day) || !isFinite(+month) || !isFinite(+year)) {
      return null;
    }
    return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year.padStart(
      4,
      "0"
    )}`;
  } catch (e) {
    return null;
  }
};
const parseTime = (time: string) => {
  const parts = time.split(":");

  if (parts.length != 3) {
    return null;
  }
  const [hour, minute, secms] = parts;
  const sParts = secms.split(".");
  let second = sParts?.[0];
  if (!second) {
    return null;
  }
  let msecond = "0";
  if (sParts?.length == 2) {
    msecond = sParts?.[1];
  }

  try {
    if (
      !isFinite(+hour) ||
      !isFinite(+minute) ||
      !isFinite(+second) ||
      !isFinite(+msecond)
    ) {
      return null;
    }
    return `${hour.padStart(2, "0")}:${minute.padStart(
      2,
      "0"
    )}:${second.padStart(2, "0")}.${msecond.padStart(3, "0")}`;
  } catch (e) {
    return null;
  }
};
const parseDateTime = (datetime: string) => {
  let dateParts = datetime.split(/\s+/);

  if (dateParts?.length != 2) {
    return null;
  }
  const [dt, tm] = dateParts;
  const date = parseDate(dt);
  const time = parseTime(tm);

  return `${date} ${time}`;
};

const timeFormat = "HH:mm:ss.SSS";
const dateFormat = "L";
const clickOutBoundEventId = "click.datetimepicker";

interface DateTimePickerStates {
  // date: string,
  // time: string,
  datetime: string;
  isDateTimeValid: boolean;
  showCalendar: boolean;
  timeSegments: { [segment: string]: string };
  // isDateValid: boolean,
  // isTimeValid: boolean
}
interface DateTimePickerProps {
  lang: string;
  date?: string;
  // dateFormat?: string,
  focus?: boolean;
  displayTime?: boolean;
  inputSize?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  children?: any;
  valid?: boolean;
  calendarPosition?: "absolute" | "fixed";
  getCurrentDate?: (date: Date | null) => void;
}
class DateTimePicker extends React.Component<
  DateTimePickerProps,
  DateTimePickerStates
> {
  static count: number = 0;
  private fullHideId: string = clickOutBoundEventId + ".";
  private isCalendarItemsClicked: any = false;
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  private delayedInvokeCallback = debounce(
    (isDtValid: boolean) => {
      const { datetime, isDateTimeValid } = this.state;
      const { getCurrentDate, valid } = this.props;
      if (!isDtValid) {
        return;
      }
      if (!datetime && valid && getCurrentDate) {
        getCurrentDate(null);
        return;
      }

      if (isDateTimeValid && getCurrentDate) {
        let fullFormat = dateFormat + " " + timeFormat;
        const newDate = moment(datetime, fullFormat).toDate();
        getCurrentDate(newDate);
      }
    },
    200,
    false
  );

  constructor(props: DateTimePickerProps) {
    super(props);
    const { lang, displayTime, date, valid } = this.props;

    this.changeCalendarVisibility = this.changeCalendarVisibility.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onChangeTimeFromTimeSelector =
      this.onChangeTimeFromTimeSelector.bind(this);
    // this.onChangeTimeFromInputField = this.onChangeTimeFromInputField.bind(this);
    this.onHide = this.onHide.bind(this);
    // const date = d ? moment(d).format(dateFormat) : moment().format(dateFormat);

    // else{
    //     date = valid === false ? '' : moment().format(dateFormat)
    // }
    const dateTime = date ? this.convertIsoDate(date) : "";
    const isConvertedDateValid = this.validateDateTime(dateTime);
    const dateTimeValid =
      typeof valid !== "undefined" ? valid : isConvertedDateValid;

    const dateTimeValue = isConvertedDateValid ? dateTime : date ? date : "";
    this.state = {
      showCalendar: false,
      datetime: dateTimeValue,
      timeSegments: this.initializeTimeSegments(dateTime),
      isDateTimeValid: dateTimeValid,
      // time,
      // isDateValid: true,
      // isTimeValid: true
    };
    this.fullHideId += DateTimePicker.count++;
  }

  convertIsoDate = (dateIso: string) => {
    const { lang, displayTime, date: d, valid } = this.props;
    if (displayTime) {
      return moment(dateIso).format(`${dateFormat} ${timeFormat}`);
    } else {
      return moment(dateIso).format(`${dateFormat}`);
    }
  };
  splitDateTime(dateTime: string) {
    const { lang, displayTime, valid } = this.props;
    const splitted = dateTime.split(/\s+/);
    if (splitted.length !== 2) {
      return null;
    }
    return [splitted[0], splitted[1]];
  }
  onHide(event: any) {
    // if(!event?.target?.closest('#calendarContainer')){
    //     this.setState({
    //         showCalendar: false
    //     });
    //     this.isCalendarItemsClicked = false;
    // }
    if (this.isCalendarItemsClicked) {
      this.isCalendarItemsClicked = false;
    } else if (this.state.showCalendar && !this.isCalendarItemsClicked) {
      this.setState({
        showCalendar: false,
      });
      this.isCalendarItemsClicked = false;
    }
  }

  componentDidMount() {
    bindEvent({
      element: document as any,
      event: this.fullHideId,
      handler: this.onHide,
    });
  }

  componentDidUpdate(prevProps: DateTimePickerProps) {
    /* Signal to update data from parent */
    const { date, valid } = this.props;

    if (date !== prevProps.date) {
      // const newDate = this.props.date ? moment(this.props.date).format(dateFormat) : moment().format(dateFormat);
      const newDateTime = date ? this.convertIsoDate(date) : "";
      const propsDateTimeValid = newDateTime
        ? this.validateDateTimeField(newDateTime)
        : false;
      const isDateTimeValid =
        typeof valid !== "undefined" ? valid : propsDateTimeValid;
      if (newDateTime !== this.state.datetime) {
        this.setState({
          ...this.state,
          datetime: newDateTime,
          isDateTimeValid: isDateTimeValid,
        });
      }
      return;
    }
  }

  componentWillUnmount() {
    unbindEvent({ element: document as any, event: this.fullHideId });
  }

  /////////////UTILS METHODS//////////
  //fill number with zero infront of it
  zeroPadTime(value: number, timeSegmentName: string) {
    return timeSegmentName === "milliseconds"
      ? zeroPad(value, 3)
      : zeroPad(value, 2);
  }

  initializeTimeSegments = (datetime: string) => {
    if (!datetime) {
      return {};
    }
    const dateObj: moment.Moment = datetime
      ? moment(datetime, `${dateFormat} ${timeFormat}`)
      : moment();
    const segments = {
      hours: zeroPad(dateObj.hours(), 2),
      minutes: zeroPad(dateObj.minutes(), 2),
      seconds: zeroPad(dateObj.seconds(), 2),
      milliseconds: zeroPad(dateObj.millisecond(), 3),
    };
    return segments;
  };
  changeCalendarVisibility() {
    this.setState({ showCalendar: !this.state.showCalendar });
  }
  updateSegments(momentTimeObject: any, stateTimeObject: any) {
    let updatedStateTimeObject = { ...stateTimeObject };
    for (const key in updatedStateTimeObject) {
      if (updatedStateTimeObject[key] !== momentTimeObject[key]) {
        updatedStateTimeObject[key] = this.fillTimeSegmentWithZero(
          key,
          momentTimeObject[key]
        );
      }
    }
    return updatedStateTimeObject;
  }

  fillTimeSegmentWithZero(timeSegmentName: string, value: string) {
    if (timeSegmentName === "milliseconds") {
      return zeroPad(parseInt(value), 3);
    } else {
      return zeroPad(parseInt(value), 2);
    }
  }

  validateTime = (time: string) => {
    return moment(time, timeFormat, true).isValid();
  };
  validateDate = (date: string) => {
    const { valid } = this.props;
    if (!date && valid) {
      return true;
    }

    return moment(date, dateFormat, true).isValid();
  };

  validateDateTime = (datetime: string) => {
    const { displayTime, valid } = this.props;
    if (!datetime && valid) {
      return true;
    }
    const format = displayTime ? `${dateFormat} ${timeFormat}` : dateFormat;

    return moment(datetime, format, true).isValid();
  };
  validateDateTimeField = (dateTime: string) => {
    const { displayTime } = this.props;
    if (displayTime) {
      return this.validateDateTime(dateTime);
    } else {
      return this.validateDate(dateTime);
    }
  };
  ////////////END OF UTILS METHODS///////////

  ///////////EVENT METHODS///////////////////
  onDateTimeChanged = (dateTime: string) => {
    const { datetime: prevDateTime, timeSegments } = this.state;
    const { displayTime } = this.props;
    // const prevDateTime = this.getDateTime()
    let newTimeSegments = timeSegments;
    if (prevDateTime !== dateTime) {
      if (displayTime) {
        const time = this.splitDateTime(dateTime)?.[1] || null;
        if (time && this.validateTime(time)) {
          newTimeSegments = this.initializeTimeSegments(dateTime);
        }
      }

      const dateTimeIsValid = this.validateDateTimeField(dateTime);
      this.setState(
        {
          datetime: dateTime,
          isDateTimeValid: dateTimeIsValid,
          timeSegments: newTimeSegments,
          // time:time,
          // isDateValid: dateIsValid,
          // isTimeValid: timeIsValid
        },
        () => {
          this.delayedInvokeCallback(dateTimeIsValid);
        }
      );
    }
  };
  onDateChanged(changedDate: string) {
    const { datetime, timeSegments } = this.state;
    const { displayTime, date: defaultDateTime } = this.props;
    const spitted = this.splitDateTime(datetime);
    const date = (spitted && spitted[0]) || undefined;
    const time = (spitted && spitted[1]) || undefined;
    if (date !== changedDate) {
      let newDateTime = changedDate;
      //    const dateValid = this.validateDate(changedDate)
      //    let timeValid = this.validateDate(changedDate)
      let dateTimeIsVaid = this.validateDate(newDateTime);
      let newTimeSegments = timeSegments;
      if (displayTime) {
        let newTime = time && this.validateTime(time) ? time : "";
        if (!newTime) {
          const defTime = defaultDateTime
            ? this.splitDateTime(defaultDateTime)?.[1]
            : null;
          newTime =
            defTime && this.validateTime(defTime)
              ? defTime
              : moment().format(timeFormat);
        }
        newDateTime = newTime ? `${newDateTime} ${newTime}` : newDateTime;
        dateTimeIsVaid = this.validateDateTime(newDateTime);
        newTimeSegments = this.initializeTimeSegments(newDateTime);
      }

      this.setState(
        {
          // date: changedDate,
          // time:!isTimeValid && defTimeIsValid ? time : prevTime,
          datetime: newDateTime,
          isDateTimeValid: dateTimeIsVaid,
          timeSegments: newTimeSegments,
          // isTimeValid:!isTimeValid && defTimeIsValid ? true: isTimeValid,
          // isDateValid: dateIsValid
        },
        () => {
          this.delayedInvokeCallback(dateTimeIsVaid);
        }
      );
    }
  }

  onChangeTimeFromTimeSelector(segmentName: string, segmentValue: string) {
    const { datetime } = this.state;
    const { displayTime, date: defaultDateTime } = this.props;
    const date = this.splitDateTime(datetime)?.[0];
    const defDate = defaultDateTime
      ? this.splitDateTime(defaultDateTime)?.[0]
      : "";

    const convertedValue = this.zeroPadTime(
      parseInt(segmentValue),
      segmentName
    );
    let newTimeSegments = {
      ...this.state.timeSegments,
      [segmentName]: convertedValue,
    };
    const newTime = moment().set(newTimeSegments).format(timeFormat);

    const isTimeValid = moment(newTime, timeFormat).isValid();
    let newState: any = {
      // isTimeValid
    };
    if (isTimeValid) {
      newState.timeSegments = newTimeSegments;
      // newState.time = newTime
    }

    let newDateTime =
      date && this.validateDate(date)
        ? date
        : defDate && this.validateDate(defDate)
        ? defDate
        : moment().format(dateFormat);
    newDateTime += " " + newTime;
    const isDateTimeValid = this.validateDateTime(newDateTime);
    newState.datetime = newDateTime;
    newState.isDateTimeVaid = isDateTimeValid;
    this.setState(newState, () => {
      this.delayedInvokeCallback(isTimeValid);
    });
  }

  onClickInputField() {
    this.isCalendarItemsClicked = true;
  }
  onInputBlur = () => {
    const { datetime, timeSegments } = this.state;
    console.log("datetime", datetime);
    const { displayTime } = this.props;
    if (displayTime) {
      const parsedDateTime = parseDateTime(datetime);
      if (!parsedDateTime) {
        return;
      }
      let newTimeSegments = timeSegments;
      if (displayTime) {
        const time = this.splitDateTime(parsedDateTime)?.[1] || null;
        if (time && this.validateTime(time)) {
          newTimeSegments = this.initializeTimeSegments(parsedDateTime);
        }
      }
      const dateTimeIsValid = this.validateDateTimeField(parsedDateTime);
      if (!dateTimeIsValid) {
        return;
      }
      this.setState(
        {
          datetime: parsedDateTime,
          isDateTimeValid: true,
          timeSegments: newTimeSegments,
        },
        () => {
          this.delayedInvokeCallback(dateTimeIsValid);
        }
      );
    } else {
      const parsedDate = parseDate(datetime);
      if (!parsedDate) {
        return;
      }
      const dateTimeIsValid = this.validateDateTimeField(parsedDate);
      if (!dateTimeIsValid) {
        return null;
      }
      this.setState(
        {
          datetime: parsedDate,
          isDateTimeValid: true,
        },
        () => {
          this.delayedInvokeCallback(dateTimeIsValid);
        }
      );
    }
  };
  ///////////END OF EVENT METHODS///////////////////

  ///////////RENDER METHODS////////////////////////
  render() {
    const { datetime, isDateTimeValid } = this.state;
    const { displayTime, valid, inputSize, disabled, style, className } =
      this.props;
    // const dateTime = this.getDateTime()
    const validClass =
      (!isDateTimeValid && datetime) || valid === false ? "is-invalid" : "";

    return (
      <div className="w-100 position-relative" ref={this.containerRef}>
        <DateTimePickerInputField
          onBlur={this.onInputBlur}
          dateTime={datetime}
          changeCalendarVisibility={this.changeCalendarVisibility}
          focus={this.props.focus}
          validClass={validClass}
          onDateTimeChanged={this.onDateTimeChanged}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          inputSize={inputSize}
          onClickInputItems={this.onClickInputField.bind(this)}
          disabled={disabled}
          style={style}
          className={className}
        >
          {this.props.children}
        </DateTimePickerInputField>
        <DatePickerCalendar
          lang={this.props.lang}
          datetime={this.state.datetime}
          showCalendar={this.state.showCalendar}
          timeSegments={this.state.timeSegments}
          displayTime={this.props.displayTime}
          calendarPosition={this.props.calendarPosition}
          onClickInputField={this.onClickInputField.bind(this)}
          onDateChanged={this.onDateChanged}
          onChangeTimeFromTimeSelector={this.onChangeTimeFromTimeSelector}
          onCloseCalendar={() => this.setState({ showCalendar: false })}
        />
      </div>
    );
  }
}

interface DatePickerCalendarProps {
  lang: string;
  datetime: string;
  showCalendar: boolean;
  timeSegments: { [segment: string]: string };
  displayTime?: boolean;
  calendarPosition?: "absolute" | "fixed";
  onClickInputField: () => void;
  onDateChanged: (changedDate: string) => void;
  onChangeTimeFromTimeSelector: (
    segmentName: string,
    segmentValue: string
  ) => void;
  onCloseCalendar: () => void;
}
interface CalendarBoxProps {
  top: number;
  bottom: number;
  right: number;
  maxHeight: number;
  position: "top" | "bottom";
}
const DatePickerCalendar = React.memo((props: DatePickerCalendarProps) => {
  const {
    lang,
    datetime,
    showCalendar,
    timeSegments,
    displayTime,
    calendarPosition,
  } = props;
  const {
    onClickInputField,
    onDateChanged,
    onChangeTimeFromTimeSelector,
    onCloseCalendar,
  } = props;

  const calendarRef = React.useRef<HTMLDivElement>(null);
  const [fixedBoxProps, setFixedBoxProps] = React.useState<
    CalendarBoxProps | null | false
  >(null);

  const recalculateFixedBox = () => {
    if (calendarPosition !== "fixed") {
      setFixedBoxProps(null);
      return;
    }

    if (!showCalendar || !calendarRef?.current) {
      setFixedBoxProps(null);
      return;
    }

    const parentElm = calendarRef?.current?.parentElement;
    if (!parentElm) {
      console.warn("Can't show fixed calendar: parent element not found");
      setFixedBoxProps(false);
      return;
    }
    const rect = parentElm.getBoundingClientRect();

    const { x, y, width, height, top, right, bottom, left } = rect;
    const visibleElements = document.elementsFromPoint(
      x + width / 2,
      y + height / 2
    );

    const sizeToBottom = window.innerHeight - bottom - 5;
    const sizeToTop = top - 5;
    let maxHeight = sizeToBottom;
    let position: "top" | "bottom" = "bottom";
    if (sizeToBottom < 250 && sizeToTop > sizeToBottom) {
      maxHeight = sizeToTop;
      position = "top";
    }
    setFixedBoxProps({
      top: bottom,
      bottom: window.innerHeight - top,
      right: window.innerWidth - right,
      maxHeight: maxHeight,
      position,
    });
  };

  React.useEffect(recalculateFixedBox, [
    showCalendar,
    calendarPosition,
    calendarRef?.current,
  ]);

  React.useEffect(() => {
    if (!showCalendar) {
      return;
    }
    bindEvent({
      event: "wheel.calendar",
      handler: props.onCloseCalendar,
    });
    bindEvent({
      event: "touchmove.calendar",
      handler: props.onCloseCalendar,
    });
    bindEvent({
      event: "mousedown.calendar",
      handler: props.onCloseCalendar,
    });
    return () => {
      unbindEvent({ event: "wheel.calendar" });
      unbindEvent({ event: "touchmove.calendar" });
      unbindEvent({ event: "mousedown.calendar" });
    };
  }, [showCalendar]);

  if (!showCalendar) {
    return <div ref={calendarRef}></div>;
  }

  const style: React.CSSProperties = {};

  const isFixed = calendarPosition === "fixed";
  if (isFixed && fixedBoxProps !== false) {
    if (fixedBoxProps === null) {
      /**Render of time picker is done, but fixed props are not calculated yet */
      return <div ref={calendarRef}></div>;
    }
    style.position = "fixed";
    style.maxHeight = fixedBoxProps?.maxHeight;
    style.right = fixedBoxProps?.right;
    if (fixedBoxProps?.position === "top") {
      style.bottom = fixedBoxProps?.bottom;
    } else {
      style.top = fixedBoxProps?.top;
    }
  } else {
    style.left = 0;
  }
  let formattedDate;
  const date = datetime.split(/\s+/)[0];
  if (moment(date, dateFormat, true).isValid()) {
    formattedDate = moment(date, dateFormat).toDate();
  }

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div
      ref={calendarRef}
      className=""
      onMouseDown={(event) => event.stopPropagation()}
      onWheel={(event) => event.stopPropagation()}
      onTouchMove={(event) => event.stopPropagation()}
    >
      {isFixed && (
        <div
          className={styles.scrollBlock}
          onWheel={recalculateFixedBox}
          onTouchMove={recalculateFixedBox}
        ></div>
      )}
      <div
        id="calendarContainer"
        className={`${styles.dateTimePickerContainer} d-flex flex-column`}
        style={style}
        onClick={onClickInputField}
      >
        <Calendar
          date={formattedDate}
          onDateChanged={onDateChanged}
          closeCalendar={onCloseCalendar}
          lang={lang}
          dateFormat={dateFormat}
        />
        {displayTime && (
          <TimerSelector
            onChangeTime={onChangeTimeFromTimeSelector}
            timeSegments={timeSegments}
          />
        )}
      </div>
    </div>
  );
});

export default connect((state: ApplicationState) => {
  return {
    lang: state.locale.language,
  };
})(DateTimePicker);
