import React, { Children, CSSProperties } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import './resizer.css'
// import DebounceInput from "../debounceinput.jsx";
import { Button as RButton } from 'react-bootstrap'
import DebounceInput from "../debounce/debounceinput";
const defaultX = 10;
const defaultY = 10;
const minZoom = 0.5;
const maxZoom = 10;
const zoomPoints = [0.5, 0.75, 1, 1.5, 2, 3, 5, 7.5, 10];

const LARGER = 0;
const SMALLER = 1;




interface ZoomInputProps {
    zoom: number
    changeZoom: Function
    minZoom: number
    maxZoom: number
}
class ZoomInput extends React.PureComponent<ZoomInputProps> {

    private inputRef: React.RefObject<any> | null;

    constructor(props: ZoomInputProps) {
        super(props);

        this.inputRef = null;

        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(value: number) {
        const nextZoom = value / 100;
        if (value == 0 || nextZoom < this.props.minZoom || nextZoom > this.props.maxZoom) {
            return;
        }
        this.props.changeZoom(nextZoom);
    }

    /* Check WAS CHANGED */
    componentWillReceiveProps(nextProps: ZoomInputProps) {
        if (this.props.zoom != nextProps.zoom && this.inputRef?.current) {
            // this.inputRef?.current?.wasChanged = false;
        }
    }

    render() {
        return <div className="npt-resizer-input input-group">
            <DebounceInput
                editable={true}
                valid={true}
                /* Store value in percents */
                value={this.props.zoom * 100}
                format={"int"}
                className={"form-control px-1"}
                change={this.changeValue}
                ref={this.inputRef}
            />
            <div className="input-group-append" >
                <span className="input-group-text" id="basic-addon2">%</span>
            </div>
        </div>;
    }
}

interface ButtonProps {
    onClick: (e: any) => void
    icon: string
    theme?: string
    className?:string
    disabled?: boolean
}

class Button extends React.PureComponent<ButtonProps> {

    constructor(props: ButtonProps) {
        super(props);
    }

    render() {
        const {className} = this.props
        const optional: any = {};
        // if (this.props.disabled) {
        //     optional.disabled = true;
        // }
        return <div className={`npt-resizer-button d-flex justify-content-center  ${className}`}>
            <RButton disabled={this.props.disabled} variant={this.props.theme||'primary'} className="flex-shrink px-2" onClick={this.props.onClick} {...optional}>
                <i className={`fa ${this.props.icon} fa-fw`} aria-hidden="true"></i>
            </RButton>
        </div>;
    }
}


class Breaker extends React.PureComponent {

    render() {
        return <hr className="npt-resizer-breaker " />;
    }
}




interface ResizerElementProps {
    fullscreen?: boolean
    toggleFullScreen: () => void
    pan: boolean
    togglePan: () => void
    zoom: number
    changeZoom: Function
    minZoom?: number,
    maxZoom?: number,
    zoomPoints?: number[]
    theme?: string,
    fixed?: boolean
}

interface ResizerElementStates {
    position: {
        x: number,
        y: number
    }
    zoom: number
    minZoom: number
    maxZoom: number
    zoomPoints: number[]
    theme: string
    grabbed: boolean
    grabParameters: any
}

/**
 * Resizing element to be added inside parent
 * 
 * Parent sizes calculating automatically
 */

class ResizerElement extends React.Component<ResizerElementProps, ResizerElementStates> {

    private selfRef: React.RefObject<any> | null;
    private parentRef: React.RefObject<any> | null;
    private objectRef: React.RefObject<any> | null = null;
    private nestedDocumentRef: React.RefObject<any> | null;
    private bgColor: any;

    constructor(props: ResizerElementProps) {
        super(props);
        const minZoom = props.minZoom ||0.5
        const maxZoom = props.maxZoom ||200
        this.state = {
            position: {
                x: defaultX,
                y: defaultY
            },
            zoom: props.zoom || 1,
            minZoom ,
            maxZoom ,
            zoomPoints: this.filterZoomPoints(props.zoomPoints || zoomPoints,minZoom,maxZoom),
            theme: props.theme || "light",
            grabbed: false,
            grabParameters: null
        }
        // this.filterZoomPoints();
        this.changeZoom(this.state.zoom);

        this.selfRef = null;
        this.parentRef = null;
        this.nestedDocumentRef = null;

        this.changeZoom = this.changeZoom.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.grabResizer = this.grabResizer.bind(this);
        this.releaseResizer = this.releaseResizer.bind(this);
    }

    filterZoomPoints(zoomPoints:number[],minZoom:number,maxZoom:number) {
        const newZoomPoints: number[] = zoomPoints.filter((point) => point >= minZoom && point <= maxZoom);
        if (minZoom < newZoomPoints[0]) {
            newZoomPoints.unshift(minZoom);
        }
        if (maxZoom > newZoomPoints[zoomPoints.length - 1]) {
            newZoomPoints.push(maxZoom);
        }
        return newZoomPoints 
    }

    /* CHECK object ref */
    setParentRef(ref: any) {
        this.releaseResizer();
        this.parentRef = ref;
        const objElement = ref.querySelector('object')
        this.objectRef = objElement || null;
        if (this.objectRef?.current) {
            this.objectRef?.current.addEventListener('load', () => {
                this.nestedDocumentRef = this.objectRef?.current?.contentDocument || null;
            });
        }
        this.forceUpdate();
    }

    changeZoom(zoom: number) {
        if (zoom < this.state.minZoom) {
            zoom = this.state.minZoom;
        } else if (zoom > this.state.maxZoom) {
            zoom = this.state.maxZoom;
        }
        if (zoom == this.state.zoom) {
            return;
        }
        this.props.changeZoom(zoom);
    }

    zoomIn() {
        const zoom = this.tryFindZoomPoint(LARGER);
        this.changeZoom(zoom);
    }

    zoomOut() {
        const zoom = this.tryFindZoomPoint(SMALLER);
        this.changeZoom(zoom);
    }

    tryFindZoomPoint(relation: number) {
        let zoom = this.state.zoom;
        try {
            for (let i = 1; i < this.state.zoomPoints.length; ++i) {
                if (this.state.zoomPoints[i] < zoom) {
                    continue;
                }
                if (this.state.zoomPoints[i] == zoom) {
                    zoom = relation == LARGER ? this.state.zoomPoints[i + 1] : this.state.zoomPoints[i - 1];
                } else {
                    zoom = relation == LARGER ? this.state.zoomPoints[i] : this.state.zoomPoints[i - 1];
                }
                break;
            }
        } catch (e) {
            console.error("Can't get zoom point");
        }
        return zoom;
    }

    grabResizer(event: any) {
        if (event.target.localName == "input" || this.props.fixed) {
            return;
        }
        event.preventDefault();
        if (this.state.grabbed) {
            return;
        }
        this.bindElement(this.parentRef);
        if (this.nestedDocumentRef) {
            this.bindElement(this.nestedDocumentRef);
        }
        this.setState(Object.assign({}, this.state, { grabbed: true, grabParameters: { x: event.screenX, y: event.screenY } }));
    }

    moveResizer(point: { x: number, y: number }) {
        const { x, y } = point
        let differenceX = this.state.grabParameters.x - x;
        let differenceY = this.state.grabParameters.y - y;
        let newX = this.state.position.x - differenceX;
        let newY = this.state.position.y - differenceY;
        this.setState(Object.assign({}, this.state, {
            grabbed: true,
            grabParameters: { x: x, y: y },
            position: { x: newX, y: newY }
        }));
    }

    releaseResizer() {
        if (!this.state.grabbed) {
            return;
        }
        this.unbindElement(this.parentRef);
        if (this.nestedDocumentRef) {
            this.unbindElement(this.nestedDocumentRef);
        }
        this.setState(Object.assign({}, this.state, { grabbed: false, grabParameters: null }));
    }

    moveResizerHandler = (event: any) => {
        this.moveResizer({ x: event.screenX, y: event.screenY });
    }
    releaseResizerHandler = (event: any) => {
        this.releaseResizer();
    }

    /* Check  EVENT */
    bindElement(htmlElement: any) {
        htmlElement.addEventListener("mousemove", this.moveResizerHandler);
        htmlElement.addEventListener("mouseup", this.releaseResizerHandler);
        // htmlElement.("mousemove.resizer", (event) => {
        //     this.moveResizer({ x: event.screenX, y: event.screenY });
        // });
        // $(htmlElement).on("mouseup.resizer", (event) => {
        //     this.releaseResizer();
        // });
    }

    unbindElement(htmlElement: any) {
        htmlElement.removeEventListener("mousemove", this.moveResizerHandler);
        htmlElement.removeEventListener("mouseup", this.releaseResizerHandler);
        // $(htmlElement).off("mousemove.resizer");
        // $(htmlElement).off("mouseup.resizer");
    }

    printFixedSvgDecorators() {
        if (!this.props.fixed || !this.bgColor) {
            return null;
        }
        const rightTopOptions = {
            width: 10,
            height: 20,
            left: "100%",
            top: -1
        }
        const leftBottomOptions = {
            width: 20,
            height: 10,
            left: -1,
            top: "100%"
        }
        return [
            <div className="position-absolute" style={rightTopOptions}>
                <svg className="position-absolute" preserveAspectRatio="none" width={rightTopOptions.width} height={rightTopOptions.height} viewBox="0 0 100 100">
                    <path vector-effect="non-scaling-stroke" d="M 0 100 C 10 40, 40 20, 100 0 L 0 0" stroke="#ccc" fill={this.bgColor} />
                    <line vector-effect="non-scaling-stroke" x1="0" x2="90" y1="4" y2="4" stroke="#ccc" />
                </svg>
            </div>,
            <div className="position-absolute" style={leftBottomOptions}>
                <svg className="position-absolute" preserveAspectRatio="none" width={leftBottomOptions.width} height={leftBottomOptions.height} viewBox="0 0 100 100">
                    <path vector-effect="non-scaling-stroke" d="M 100 0 C 40 10, 20 40, 0 100 L 0 0" stroke="#ccc" fill={this.bgColor} />
                    <line vector-effect="non-scaling-stroke" x1="4" x2="4" y1="0" y2="90" stroke="#ccc" />
                </svg>
            </div>
        ];
    }

    componentWillReceiveProps(nextProps: ResizerElementProps) {
        if (this.props.zoom != nextProps.zoom) {
            this.setState({ zoom: nextProps.zoom })
        }
    }

    /* Set parent ref on element mount */
    componentDidMount() {
        // this.selfRef = ReactDOM.findDOMNode(this);
        if (this.selfRef?.current) {
            this.bgColor = window.getComputedStyle(this.selfRef?.current, null).getPropertyValue("background-color");
            this.setParentRef(this.selfRef?.current.parentNode);
        }

    }

    /* Check parent ref on element update */
    componentDidUpdate() {
        try {
            if (!this.parentRef?.current || this.parentRef?.current?.parentNode != this.parentRef?.current) {
                this.setParentRef(this.selfRef?.current?.parentNode)
            }
        } catch (e) { }
    }

    render() {
        const { toggleFullScreen, fullscreen,togglePan,theme } = this.props
        let className = `npt-resizer position-absolute btn-group-vertical bg-${theme}`
        if (this.props.fixed) {
            className += " npt-resizer-fixed";
        } 
        return <div
            ref={this.selfRef}
            className={className}
            style={{ left: this.state.position.x, top: this.state.position.y,width:'3.5rem' }}
            onMouseDown={this.grabResizer}
        >
            {this.printFixedSvgDecorators()}
            <div className="w-100 d-flex justify-content-center">
                <Button icon={fullscreen ? 'fa-window-close':`fa-arrows-alt`} className="mb-1"  theme={theme} onClick={toggleFullScreen} />
            </div>    
            <div className="w-100 d-flex justify-content-center">
                <Button icon="fa-plus" className="mb-1" theme={theme} onClick={this.zoomIn} disabled={this.state.zoom >= this.state.maxZoom} />
            </div>
            <div className="w-100 d-flex justify-content-center">
                <Button icon="fa-minus" className="mb-1" theme={theme} onClick={this.zoomOut} disabled={this.state.zoom <= this.state.minZoom} />
            </div>
             
            <Breaker />
            <ZoomInput
                zoom={this.state.zoom}
                changeZoom={this.changeZoom}
                minZoom={this.state.minZoom}
                maxZoom={this.state.maxZoom}
            />
        </div>
    }
}


 

interface Position{
    top:number
    left:number
    x:number
    y:number
}
interface ResizerProps {
    pan: boolean
    togglePan: () => void
    fullscreen: boolean
    toggleFullScreen: () => void
    maxHeight?: number,
    maxWidth?: number,
    minWidth?: number,
    height?:number
    width?:number
    zoom: number,
    changeZoom: Function,
    minZoom?: number,
    maxZoom?: number,
    zoomPoints?: number[]
    theme?: string,
    fixed?: boolean
    style?: React.CSSProperties
}

interface Position{
    top:number
    left:number
    x:number
    y:number
}
/**
 * Container that will wrap children with div
 * and add resizing element 
 */
class Resizer extends React.Component<ResizerProps> {
    private containerRef:React.RefObject<HTMLDivElement> = React.createRef()
    private panRef:React.RefObject<HTMLDivElement> = React.createRef()
    private pos:Position = { top: 0, left: 0, x: 0, y: 0 };
    
    getContainer=()=>{ 
        let scrollableParent:any = this.containerRef?.current
        
        if(!scrollableParent){
            return null
        }
        return scrollableParent
    }
    getPanElement=()=>{
        return this.panRef.current
    }
    mouseUpHandler = () => { 
        const scrollableParent:any = this.getContainer()
        if(!scrollableParent){
            return null
        }
        const pan = this.getPanElement()
        if(pan){
            pan.style.cursor = 'grab';
        } 
        scrollableParent.style.cursor = 'default';
        scrollableParent.style.removeProperty('user-select');
        document.removeEventListener('mousemove', this.mouseMoveHandler);
        document.removeEventListener('mouseup', this.mouseUpHandler);
    };

    mouseMoveHandler = (e:any) => { 
        const scrollableParent:any = this.getContainer()
        if(!scrollableParent){
            return null
        }
        
        // How far the mouse has been moved
        const dx = e.clientX - this.pos.x;
        const dy = e.clientY - this.pos.y; 
        // Scroll the element
        scrollableParent.scrollTop = this.pos.top - dy;
        scrollableParent.scrollLeft = this.pos.left - dx;
    };
     
    mouseDownHandler = (e:any) => {  
        const scrollableParent:any = this.getContainer()
        if(!scrollableParent){
            return null
        }
        const pan = this.getPanElement()
        if(pan){
            pan.style.cursor = 'grabbing';
        } 
        scrollableParent.style.userSelect = 'none';
        this.pos = {
            // The current scroll 
            left: scrollableParent.scrollLeft,
            top: scrollableParent.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        }; 
        document.addEventListener('mousemove', this.mouseMoveHandler);
        document.addEventListener('mouseup', this.mouseUpHandler);
    };


    handleKeydown = (e: any) => {
        const { fullscreen,pan, toggleFullScreen ,togglePan} = this.props
        if (fullscreen && e.keyCode === 27) {
            toggleFullScreen()
        } 
        if(e.shiftKey && !pan){
            togglePan()
        }
    }
    handleKeyUp = (e: any) => {
        const {  pan   ,togglePan} = this.props 
        if(!e.shiftKey && pan){ 
            togglePan()
        }
    }

    componentDidMount() {
        
        document.addEventListener('keydown', this.handleKeydown)
        document.addEventListener('keyup', this.handleKeyUp)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
        document.removeEventListener('keyup', this.handleKeyUp)
    }

    renderPanZone = ()=>{
        const {pan,height,width} = this.props
        const scrollableParent:any = this.getContainer()
        if(!pan){
            return null
        }
        const style:CSSProperties = {cursor:'grab'}
        if(height && width){ 
           const newHeight = height < scrollableParent.clientHeight ? scrollableParent.clientHeight:height
           const newWidth = width < scrollableParent.clientWidth ? scrollableParent.clientWidth:width
            style.height = `${newHeight}px`
            style.width = `${newWidth}px` 
        }
        return (
            <div ref={this.panRef} className="pan-zone" style={style} onMouseDown={this.mouseDownHandler}>
            </div>
        )
    }

    renderContainer() {
        const { fullscreen,pan,style:s } = this.props 
        const style = {...s}
        
        return <>
         <ResizerElement {...this.props} />
         <div ref={this.containerRef}    className={`npt-resizer-container overflow-auto   ${fullscreen ? 'fs-container' : 'position-relative '}`}>
            {this.renderPanZone()}
            <div className="scheme" style={style}> 
                {this.props.children}
            </div>
        </div>
        </>
    }

    render() {
        const { fullscreen } = this.props
        const container = this.renderContainer()
        const rootIdContainer = document.querySelector('body>div#root')
        if (fullscreen && rootIdContainer != null) {
            return ReactDOM.createPortal(container, rootIdContainer)
        }
        return container
    }
}

export default Resizer;