import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  ALERT_LEVEL_DANGER,
  ALERT_LEVEL_INFO,
  ALERT_LEVEL_SUCCESS,
  ALERT_LEVEL_WARNING,
} from "../constants/alert";
import * as constants from "../constants/fragment";
import {
  CtxOperation,
  FETCH_FRAGMENTS_URL,
  FETCH_SAVE_FRAGMENT_URL,
} from "../constants/fragment";
import { ApplicationAction, ApplicationState } from "../types";
import { FetchError } from "../types/error";
import {
  // EditFragmentInfo,
  FragmentAction,
  FragmentCtxMenu,
  FragmentData,
  FragmentDataRaw,
  FragmentFields,
  FragmentNodeMap,
  FragmentState,
  FragmentTreeBranch,
  SendAddedFragment,
  SendDeletedFragment,
  SendFragmentActive,
  SendFragmentClearSelect,
  SendFragmentCtxMenu,
  SendFragmentCutTarget,
  SendFragmentEditedUpdate,
  //  SendAllItemChecked,
  SendFragmentError,
  SendFragmentLoading,
  SendFragmentRange,
  SendFragments,
  SendFragmentSaveEdited,
  SendFragmentSelect,
  SendFragmentStartEdit,
  SendFragmentStopEdit,
  SendFragmentToggle,
  SendFragmentTogglePath,
  SendFragmentUploadNOdeOver,
  SendMovedFragment,
  SendUpdatedFragment,
  // SendItemChecked, SendLimit,
  // SendOffset,
  // SendSearchInput, SendStartEdit,
  // SendStopEdit
} from "../types/fragment";
import { I18NString } from "../types/modal";
import { MoveFragmentRequest } from "../types/fragment";
import { addAlert, dispatchError } from "./alert";
import { openModal } from "./modal";
import { ServerError } from "./utils";

function parseFragment(json: any) {
  const rawFragment: FragmentDataRaw = json;
  const { d: description, l: label, r: rdfId, pr: parentId } = rawFragment;
  const data: FragmentData = { description, label, rdfId, parentId };
  return data;
}

function convertToFragmentRow(data: FragmentData) {
  const { description: d, parentId: pr, rdfId: r, label: l } = data;
  const rawFragment: FragmentDataRaw = { l, d, pr, r };
  return rawFragment;
}

export function parseFragments(json: any) {
  const fragments: FragmentData[] = [];
  if (Array.isArray(json)) {
    json.forEach((o) => {
      fragments.push(parseFragment(o));
    });
    return fragments;
  }
  return [];
}

/*********************
 * Utility functions *
 *********************/

export async function uploadImpl(
  fragments: FragmentData[],
  parentId: string | null
): Promise<FragmentData[]> {
  const response = await fetch(constants.FRAGMENT_UPLOAD_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(fragments),
  });
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  const savedFragments = await response.json();
  return savedFragments;
}

async function deleteFragmentImpl(rdfId: string): Promise<boolean> {
  const resp = await fetch(`${constants.FRAGMENT_URL}/delete/${rdfId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { status, statusText } = resp;
  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return true;
}

export async function fetchFragmentsPathImpl(rdfId: string): Promise<string[]> {
  const resp = await fetch(`${constants.FRAGMENT_URL}/path/${rdfId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { status, statusText } = resp;
  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return await resp.json();
}
async function deleteFragmentsImpl(rdfIds: string[]): Promise<boolean> {
  const resp = await fetch(`${constants.FRAGMENT_URL}/delete`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rdfIds),
  });
  const { status, statusText } = resp;
  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return true;
}

async function moveFragmentImpl(
  moveRequest: MoveFragmentRequest
): Promise<FragmentData[]> {
  let url = `${constants.FRAGMENT_URL}/move`;

  const resp = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(moveRequest),
  });
  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return parseFragments(await resp.json());
}

export async function fetchFragmentsImpl(
  parentId?: any
): Promise<FragmentTreeBranch> {
  let url = FETCH_FRAGMENTS_URL;
  if (parentId) {
    url += `/${parentId}`;
  }
  const resp = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return await resp.json();
}

export async function fetchSaveFragmentImpl(
  data: FragmentData
): Promise<FragmentData> {
  let url = FETCH_SAVE_FRAGMENT_URL;

  const resp = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(convertToFragmentRow(data)),
  });

  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return parseFragment(await resp.json());
}

/*****************
 * Plain actions *
 *****************/
export function sendFragments(
  list: FragmentData[],
  parentId?: string
): SendFragments {
  return {
    type: constants.SEND_FRAGMENTS,
    payload: {
      list,
      parentId,
    },
  };
}

export function sendFragmentActive(id: string): SendFragmentActive {
  return {
    type: constants.SEND_FRAGMENT_ACTIVE,
    payload: id,
  };
}
export function sendFragmentSelect(id?: string): SendFragmentSelect {
  return {
    type: constants.SEND_FRAGMENT_SELECT,
    payload: id,
  };
}
export function sendFragmentClearSelect(): SendFragmentClearSelect {
  return {
    type: constants.SEND_CLEAR_FRAGMENT_SELECT,
    payload: null,
  };
}
export function sendFragmentRange(id?: string): SendFragmentRange {
  return {
    type: constants.SEND_FRAGMENT_RANGE,
    payload: id,
  };
}
export function sendFragmentCutTarget(id: string): SendFragmentCutTarget {
  return {
    type: constants.SEND_FRAGMENT_CUT_TARGET,
    payload: id,
  };
}
export function sendFragmentUploadNodeOver(
  isOver: boolean
): SendFragmentUploadNOdeOver {
  return {
    type: constants.SEND_FRAGMENT_UPLOAD_NODE_OVER,
    payload: isOver,
  };
}

export function sendFragmentEditedUpdate(
  id: string,
  field: FragmentFields,
  value: string
): SendFragmentEditedUpdate {
  return {
    type: constants.SEND_FRAGMENT_EDITED_UPDATE,
    payload: {
      id,
      field,
      value,
    },
  };
}
export function sendFragmentStartEdit(
  data: FragmentData
): SendFragmentStartEdit {
  return {
    type: constants.SEND_FRAGMENT_EDITED_START,
    payload: data,
  };
}
export function sendAddedFragment(data: FragmentData): SendAddedFragment {
  return {
    type: constants.SEND_ADDED_FRAGMENT,
    payload: data,
  };
}
export function sendDeletedFragment(id: string[]): SendDeletedFragment {
  return {
    type: constants.SEND_DELETED_FRAGMENT,
    payload: id,
  };
}
export function sendUpdatedFragment(data: FragmentData): SendUpdatedFragment {
  return {
    type: constants.SEND_UPDATED_FRAGMENT,
    payload: data,
  };
}
export function sendMovedFragment(data: FragmentData[]): SendMovedFragment {
  return {
    type: constants.SEND_MOVED_FRAGMENT,
    payload: data,
  };
}
export function sendFragmentStopEdit(id: string): SendFragmentStopEdit {
  return {
    type: constants.SEND_FRAGMENT_EDITED_STOP,
    payload: { id },
  };
}
export function sendFragmentSaveEdited(id: string): SendFragmentSaveEdited {
  return {
    type: constants.SEND_FRAGMENT_SAVE_EDITED,
    payload: id,
  };
}

export function sendFragmentLoading(
  id?: string,
  loading: boolean = true
): SendFragmentLoading {
  return {
    type: constants.SEND_FRAGMENT_LOADING,
    payload: {
      id,
      loading,
    },
  };
}

export function sendFragmentError(
  error: FetchError,
  id?: string
): SendFragmentError {
  return {
    type: constants.SEND_FRAGMENT_ERROR,
    payload: {
      error,
      id,
    },
  };
}

export function sendFragmentToggle(id: string): SendFragmentToggle {
  return {
    type: constants.SEND_FRAGMENT_TOGGLE,
    payload: id,
  };
}
export function sendFragmentTogglePath(ids: string[]): SendFragmentTogglePath {
  return {
    type: constants.SEND_FRAGMENT_TOGGLE_PATH,
    payload: ids,
  };
}
export function sendFragmentCtxMenu(ctx: FragmentCtxMenu): SendFragmentCtxMenu {
  return {
    type: constants.SEND_FRAGMENT_CTX_MENU,
    payload: ctx,
  };
}

/***********
 * Actions *
 ***********/
export function startEditFragment(
  id: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    try {
      const s = getState().fragment;
      if (!s) {
        return;
      }
      const edited = s.nodeById && s.nodeById[id];
      if (!edited) {
        return;
      }
      dispatch(sendFragmentStartEdit(edited));
    } catch (e: any) {
      if (e instanceof ServerError) {
        dispatch(sendFragmentError({ code: e.code, message: e.message }));
      } else if (typeof e.message == "string") {
        dispatch(sendFragmentError({ code: -1, message: e.message }));
      } else {
        dispatch(sendFragmentError({ code: -1, message: "Unknown error" }));
      }
    }
  };
}

export function initializeFragments(
  nodeId: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    try {
      const fragState = getState().fragment;
      const expanded = fragState?.expanded;
      const nodeById = fragState?.nodeById;
      if (fragState?.loading) {
        return;
      }
      console.log("exists");
      // if(expanded && nodeById){
      //     const node = nodeById[nodeId];
      //     const parentId = node && node.pr;
      //     const parentExpanded = !parentId ||  expanded[parentId];
      //     const exists = node && expanded[nodeId] && parentExpanded;
      //     console.log('exists',node,expanded[nodeId],parentExpanded)
      //     if(node){
      //         dispatch(sendFragmentSelect());
      //         dispatch(sendFragmentActive(nodeId));
      //         return;
      //     }
      // }
      console.log("not exists");
      const path = await fetchFragmentsPathImpl(nodeId);

      if (!path || !path.length) {
        return;
      }

      console.log("path", path);

      const active = path.pop();
      if (path.length === 0 && active) {
        dispatch(sendFragmentActive(active));
        return;
      }

      dispatch(sendFragmentTogglePath(path));
      active && dispatch(sendFragmentActive(active));

      if (path.length) {
        path.forEach((p) => {
          dispatch(fetchFragments(p));
        });
      } else {
        dispatch(fetchFragments(active));
      }
    } catch (e) {
      dispatch(sendFragmentLoading(undefined, false));
      dispatchError("FRAGMENT_SAVE_ERROR", e, dispatch);
    }
  };
}
export function addFragment(
  fragmentResult: FragmentData
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const fragmentState = s[constants.FRAGMENT];

    if (fragmentState && fragmentResult != null) {
      try {
        dispatch(sendFragmentLoading());
        const fragment: FragmentData = fragmentResult;
        // if(!fragment.pr){
        //     fragment.pr = parentId;
        // }
        const savedFragment = await fetchSaveFragmentImpl(fragment);
        dispatch(sendAddedFragment(savedFragment));
        const messge: I18NString = { id: "FRAGMENT_SAVE_SUCCESS" };
        dispatch(addAlert(ALERT_LEVEL_SUCCESS, messge));
      } catch (e) {
        dispatch(sendFragmentLoading(undefined, false));
        dispatchError("FRAGMENT_SAVE_ERROR", e, dispatch);
      }
    }
  };
}

export function fetchFragments(
  parentId?: string
): ThunkAction<void, ApplicationState, {}, FragmentAction> {
  return async (dispatch, getState) => {
    try {
      const s = getState().fragment;
      if (parentId && s && s.childrenIds && s.childrenIds[parentId]) {
        return;
      }
      // const fragmentState = s[constants.FRAGMENT];
      // if (fragmentState && fragmentState.loading &&parentId&& fragmentState.loading[parentId]) {
      //     return;
      // }

      dispatch(sendFragmentLoading(parentId));
      const fragmentTreeBranch = await fetchFragmentsImpl(parentId);
      const { l, r } = fragmentTreeBranch;
      const fragments: FragmentData[] = parseFragments(l);
      dispatch(sendFragments(fragments, r));
    } catch (e: any) {
      dispatch(sendFragmentLoading(parentId, false));
      if (e instanceof ServerError) {
        dispatch(sendFragmentError({ code: e.code, message: e.message }));
      } else if (typeof e.message == "string") {
        dispatch(sendFragmentError({ code: -1, message: e.message }));
      } else {
        dispatch(sendFragmentError({ code: -1, message: "Unknown error" }));
      }
    }
  };
}

export function saveFragment(
  data: FragmentData
): ThunkAction<void, ApplicationState, {}, FragmentAction> {
  return async (dispatch, getState) => {
    try {
      if (!data.rdfId) {
        return;
      }
      const fState = getState().fragment;
      if (!fState || !fState.nodeById) {
        return;
      }
      dispatch(sendFragmentLoading());
      const oldFragment = fState.nodeById[data.rdfId];
      const { description: d, label: l, parentId: pr } = oldFragment;
      const { label: nl, description: nd, parentId: npr } = data;
      if (
        d === nd &&
        l === nl &&
        ((typeof pr === undefined && typeof npr === undefined) || pr === npr)
      ) {
        dispatch(sendFragmentLoading(undefined, false));
        const messge: I18NString = { id: "FRAGMENT_SAVE_EQUAL" };
        dispatch(addAlert(ALERT_LEVEL_INFO, messge));
        return;
      }

      if ((pr || npr) && pr !== npr) {
        dispatch(moveFragment(data.rdfId, npr));
      }

      const updatedFragment = await fetchSaveFragmentImpl(data);
      dispatch(sendUpdatedFragment(updatedFragment));
      // dispatch(fetchFragments(data.pr));
      dispatch(sendFragmentStopEdit(data.rdfId));

      const messge: I18NString = { id: "FRAGMENT_SAVE_SUCCESS" };
      dispatch(addAlert(ALERT_LEVEL_SUCCESS, messge));
    } catch (e) {
      dispatch(sendFragmentLoading(undefined, false));
      dispatchError("FRAGMENT_SAVE_ERROR", e, dispatch);
    }
  };
}

export function daleteFragment(
  id: string
): ThunkAction<void, ApplicationState, {}, FragmentAction> {
  return async (dispatch, getState) => {
    try {
      const s = getState();
      const fragmentState = s.fragment;
      const data =
        fragmentState && fragmentState.nodeById && fragmentState.nodeById[id];
      if (!data) {
        return;
      }
      dispatch(sendFragmentLoading());
      dispatch(sendFragmentStopEdit(id));
      const isDeleted = await deleteFragmentImpl(id);
      if (isDeleted) {
        dispatch(sendDeletedFragment([id]));
        const messge: I18NString = { id: "FRAGMENT_REMOVE_SUCCESS" };
        dispatch(addAlert(ALERT_LEVEL_SUCCESS, messge));
      } else {
        dispatch(sendFragmentLoading(undefined, false));
        const messge: I18NString = { id: "FRAGMENT_REMOVE_ERROR" };
        dispatch(addAlert(ALERT_LEVEL_DANGER, messge));
      }
    } catch (e) {
      dispatch(sendFragmentLoading(undefined, false));
      dispatchError("FRAGMENT_REMOVE_ERROR", e, dispatch);
    }
  };
}

export function daleteSelectedFragments(): ThunkAction<
  void,
  ApplicationState,
  {},
  FragmentAction
> {
  return async (dispatch, getState) => {
    try {
      const s = getState();
      const fragmentState = s.fragment;
      const nodes = fragmentState && fragmentState.nodeById;
      const selected = fragmentState && fragmentState.selected;
      const active = fragmentState && fragmentState.active;
      if (!nodes) {
        return;
      }
      dispatch(sendFragmentLoading());
      if (selected) {
        selected.forEach((s) => {
          dispatch(sendFragmentStopEdit(s));
        });
      }

      if (!selected && !active) {
        return;
      }
      const deletedFragments = selected ? selected : active ? [active] : [];
      if (deletedFragments.length === 0) {
        return;
      }
      const isDeleted = await deleteFragmentsImpl(deletedFragments);
      if (isDeleted) {
        dispatch(sendDeletedFragment(deletedFragments));
        const messge: I18NString = { id: "FRAGMENT_REMOVE_SUCCESS" };
        dispatch(addAlert(ALERT_LEVEL_SUCCESS, messge));
      } else {
        dispatch(sendFragmentLoading(undefined, false));
        const messge: I18NString = { id: "FRAGMENT_REMOVE_ERROR" };
        dispatch(addAlert(ALERT_LEVEL_DANGER, messge));
      }
    } catch (e) {
      dispatch(sendFragmentLoading(undefined, false));

      dispatchError("FRAGMENT_REMOVE_ERROR", e, dispatch);
    }
  };
}

export function confirmDelete(
  callback: () => void,
  id?: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    const fragmentS = getState().fragment;
    const nodes = fragmentS?.nodeById;
    if (!nodes) {
      return;
    }
    const bodyMess = {
      id: "FRAGMENT_CONFIRM_DELETE_MODAL",
      values: { name: "" },
    };
    const selected = fragmentS?.selected;
    const active = fragmentS?.active;
    let name = "";
    if (id) {
      name = nodes[id].label;
    } else if (selected && selected.length !== 0) {
      name = Object.values(nodes)
        .filter((f) => f.rdfId && selected.includes(f.rdfId))
        .map((f) => f.label)
        .join(", ");
    } else if (active) {
      name = nodes[active].label;
    }
    bodyMess.values.name = name;
    dispatch(
      openModal(
        "fragment.confirmDelete",
        "confirmDelete",
        {
          title: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE" },
          body: bodyMess,
        },
        async (result) => {
          if (result) {
            callback();
          }
        }
      )
    );
  };
}

function validateParentToItsChildren(
  nodeId: string,
  newParentId: string,
  nodes: FragmentNodeMap
) {
  const movedNode = nodes[nodeId];
  let parentId: string | undefined = newParentId;
  let valid: boolean = true;
  while (parentId) {
    if (parentId === nodeId) {
      valid = false;
      break;
    }
    if (!parentId) {
      break;
    }
    parentId = nodes[parentId].parentId;
  }
  return valid;
}

function validateMovedFragment(
  movedFragment: FragmentData,
  dispatch: ThunkDispatch<ApplicationState, {}, FragmentAction>,
  nodes: FragmentNodeMap,
  newParentId?: string
) {
  if (movedFragment.rdfId === newParentId) {
    const messge: I18NString = {
      id: "FRAGMENT_MOVE_ITSELF_ERROR",
      values: { name: movedFragment.label },
    };
    dispatch(addAlert(ALERT_LEVEL_WARNING, messge));
    return false;
  } else if (
    movedFragment.parentId === newParentId ||
    (typeof movedFragment.parentId === undefined &&
      typeof newParentId === undefined)
  ) {
    const messge: I18NString = {
      id: "FRAGMENT_MOVE_SAME_PARENT_ERROR",
      values: { name: movedFragment.label },
    };
    dispatch(addAlert(ALERT_LEVEL_WARNING, messge));
    return false;
  } else if (
    movedFragment.rdfId &&
    newParentId &&
    !validateParentToItsChildren(movedFragment.rdfId, newParentId, nodes)
  ) {
    const messge: I18NString = {
      id: "FRAGMENT_MOVE_PARENT_TO_CHILDREN_ERROR",
      values: {
        nameP: nodes[movedFragment.rdfId].label,
        nameC: nodes[newParentId].label,
      },
    };
    dispatch(addAlert(ALERT_LEVEL_WARNING, messge));
    return false;
  }
  return true;
}

export function moveFragment(
  movedId: string,
  newParentId?: string
): ThunkAction<void, ApplicationState, {}, FragmentAction> {
  return async (dispatch, getState) => {
    try {
      const s = getState();
      const fragmentState = s.fragment;
      const nodes = fragmentState && fragmentState.nodeById;
      if (!nodes) {
        return;
      }
      const data = nodes[movedId];
      if (!data || !data.rdfId) {
        return;
      }
      dispatch(sendFragmentLoading());
      const selectedList = fragmentState && fragmentState.selected;
      let movedIsInvalid = true;
      const movedIds: string[] = [];
      if (
        selectedList &&
        selectedList.length !== null &&
        selectedList.includes(data.rdfId)
      ) {
        for (let id of selectedList) {
          const node = nodes[id];
          movedIsInvalid = validateMovedFragment(
            node,
            dispatch,
            nodes,
            newParentId
          );
          if (!movedIsInvalid) {
            break;
          }
          movedIds.push(id);
        }
      } else {
        movedIsInvalid = validateMovedFragment(
          data,
          dispatch,
          nodes,
          newParentId
        );
        if (movedIsInvalid) {
          movedIds.push(data.rdfId);
        } else {
          movedIsInvalid = false;
        }
      }

      if (movedIsInvalid && movedIds.length !== 0) {
        const moveRequest: MoveFragmentRequest = {
          l: movedIds,
          pr: newParentId,
        };
        const movedFragments = await moveFragmentImpl(moveRequest);
        dispatch(sendMovedFragment(movedFragments));
        const messge: I18NString = { id: "FRAGMENT_MOVE_SUCCESS" };
        dispatch(addAlert(ALERT_LEVEL_SUCCESS, messge));
      } else {
        dispatch(sendFragmentLoading(undefined, false));
        dispatchError("FRAGMENT_MOVE_ERROR", null, dispatch);
      }
    } catch (e) {
      dispatch(sendFragmentLoading(undefined, false));
      dispatchError("FRAGMENT_MOVE_ERROR", e, dispatch);
    }
  };
}

export function confirmMove(
  movedId: string,
  target: string | null,
  callback: () => void
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    const fragment = getState().fragment;
    const nodes = fragment?.nodeById;
    if (!fragment || !nodes) {
      return null;
    }
    const movedNode = nodes[movedId];
    const targetNode = target ? nodes[target] : null;

    const selectedList = fragment.selected;
    const selectedNodes =
      selectedList && selectedList.length !== 0
        ? Object.values(nodes)
            .filter((n) => n.rdfId && selectedList.includes(n.rdfId))
            .map((n) => n.label)
        : [];

    const title: I18NString = { id: "FRAGMENT_CONFIRM_MOVE" };
    const name = !selectedNodes.length
      ? movedNode.label
      : selectedNodes.join(", ");

    if (!targetNode) {
      title.id = "FRAGMENT_CONFIRM_MOVE_TO_ROOT";
      title.values = { moved: name };
    } else {
      title.values = { target: targetNode.label, moved: name };
    }

    dispatch(
      openModal(
        "fragment.confirmDelete",
        "confirmDelete",
        { title: "FRAGMENT_CONFIRM", body: title },
        async (result) => {
          if (result) {
            callback();
          }
        }
      )
    );
  };
}

export function upload(
  fragments: FragmentData[],
  parentId: string | null
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    await Promise.resolve(uploadImpl(fragments, parentId));
    dispatch(fetchFragments(parentId || undefined));
  };
}

export function downloadFragments(): ThunkAction<
  void,
  ApplicationState,
  {},
  AnyAction
> {
  return async (dispatch, getState) => {
    const fragmentState = getState().fragment;
    if (fragmentState?.nodeById) {
      const active = fragmentState.active;
      const fragmentsList: FragmentData[] = Object.values(
        fragmentState.nodeById
      );

      if (fragmentsList.length === 0) {
        return;
      }
      const a = document.createElement("a");
      a.download = "fragments.json";
      const fragmentsText = JSON.stringify(fragmentsList, null, "\t");
      const fragmentsBlob = new Blob([fragmentsText], { type: "text/plain" });
      a.href = URL.createObjectURL(fragmentsBlob);
      a.click();
      URL.revokeObjectURL(a.href);
    }
  };
}
