import { faCaretDown, faCaretUp, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { RefObject } from 'react';
import Alert from 'react-bootstrap/Alert';
import { FormattedMessage } from 'react-intl';
import {
    AutoSizer,
    Column, SortDirection, SortDirectionType, Table,
    TableCellProps,
    TableHeaderProps
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import { bindEvent, unbindEvent } from '../../services/event';
import { I18NString } from '../../types/modal';
import { GlowingLoader } from '../loaders/glowing/GlowingLoader';
import { MSG_TABLE_NO_DATA_TO_DISPLAY } from '../messages';
import styles from './VirtualizedTable.module.css';


export type TableColumnType = 'string' | 'number' | 'date';
export type SortDataType = [string,any];

export const TABLE_HEADER_COLUMN_CLASS = 'npt-vir-header-column';
export const TABLE_BODY_ROW_CLASS = 'npt-vir-body-row';
export const TABLE_CLASS = 'npt-vir-table'
const DEFAULT_DATE_FORMAT = "L H:mm:ss.SSS";
interface HeaderAddon {
    element?: JSX.Element
    action: (headerData: TableHeaderProps) => void
}
interface BodyAddon {
    element?: JSX.Element
    action: (bodyData: TableCellProps, uniqKey: string) => void
}
export interface InlineEditedColumn {
    key: string
    columnName: string
}

export interface TableColumn {
    key: string
    type?:  TableColumnType
    width: number
    sort?:boolean
    label: string | I18NString
    //make column with numbers    
    index?: boolean
    //make column with uniq identifier( values are taken from jeys array of TableData)
    uniq?: boolean
    hidden?: boolean
    checked?: boolean
    editable?: boolean
    cssClass?: string
    dateformat?: string
    download?: boolean
    // render?: (data: string) => JSX.Element
    headerColumnAddon?: HeaderAddon
    bodyColumnAddon?: BodyAddon
    bodyCellRenrerer?: (uniqKey: string, cellValue: string,cellProps: TableCellProps) => JSX.Element
    headerCellRenrerer?: () => JSX.Element
}

//Format of normalized data 
export interface TableData {
    keys: string[]
    //value of uniq column  
    //FIELD get from Column field field
    //type of any must be retrieved from  Column type field
    // [FIELD:string]:{[KEY:string]:any}|string[]
    [FIELD: string]: { [KEY: string]: any }
}

export interface VTableProps {
    modalEditor?: boolean,
    language: string,
    maxRowCount?:number
    data: TableData,
    columns: TableColumn[],
    offset?: number,
    isAllItemsChecked?: boolean,
    paginator?: JSX.Element | null,
    loading?: boolean,
    inlineEditedColumn?: InlineEditedColumn
    checkItem?: (label: string, checked: boolean) => void,
    checkAllItems?: (checked: boolean) => void,
    startEdit?: (id: string, column: string) => void,
    stopEditSuccess?: (newValue: string) => void,
    stopEditCancelled?: () => void,
    editInModal?: (id: any) => void,
    downloadItem?: (uniqKey: string) => void
}

type VTableStates = {
    sortBy: string|null
    sortDirection: SortDirectionType | null
    sortedType: TableColumnType|null
    visibleColumns: TableColumn[]
    //it's needed for editing purposes
    newColumnValue: string,

    // it's necessary to know names of two nearest columns  and x coordinate between them
    //thanks to it we know what column(it's name)   has to be expanded and which one has to be narrowed down 
    draggedColumns: {
        col1: string,
        col2: string,
        x: number
    }
    keys:string[]
    //retreive column width by name from draggedColumns
    columnsWidths: { [columnName: string]: number },

}
 

export default class VirtualizedTable extends React.Component<VTableProps, VTableStates> {
    private ref:RefObject<any> = React.createRef();
    constructor(props: VTableProps) {
        super(props);

        this.captureRightResizer = this.captureRightResizer.bind(this);
        this.captureLeftResizer = this.captureLeftResizer.bind(this);
        this.resiserIsBeingMoved = this.resiserIsBeingMoved.bind(this);

         
        const keys = this.props.data.keys
        
        this.state = {
            newColumnValue: '',
            draggedColumns: {
                col1: '',
                col2: '',
                x: 0
            },
            ...this.getVisibleCols(),
            sortBy:null,
            sortDirection:null,
            sortedType:null,
            keys   
            // visibleColumns: visible,
            // columnsWidths: columnsWidths
        };
    }
 

    getVisibleCols (){
        let visible: TableColumn[] = []

        const { columns } = this.props;
        visible = columns.filter(c => !c.hidden) || [];
        let columnsWidths: { [name: string]: number } = {};
        visible.map(el => {
            columnsWidths[el.key] = el.width
        });
        return {visibleColumns:visible,columnsWidths};
    }

    editEvents = (event:any)=>{
        event.preventDefault();
        if (event.keyCode === 13 && this.isThereEditing() && this.props.stopEditSuccess) {
            this.props.stopEditSuccess(this.state.newColumnValue);
        }
        if (event.keyCode === (27) && this.isThereEditing() && this.props.stopEditCancelled) {
            this.props.stopEditCancelled();
        }
    }
    dragEvents = (event:any)=>{ 
        if (this.state.draggedColumns.col1 !== '' && this.state.draggedColumns.col2 !== '') {
            this.setState({
                draggedColumns: { col1: '', col2: '', x: 0 }
            });
        }
    }
    componentDidMount( ) {
        // let tableInfo: any = { pageable: true, offset: 0, limit: this.props.limit };
        // this.props.fetchData(tableInfo);
        //when we are editing element we may press esc or enter
        //esc - cancelled editing by removing editable object from store (when editable object is removed input field in editable column is removed)
        //enter - commits changes  are made
        document.addEventListener('keyup',this.editEvents)
        document.addEventListener('mouseup',this.dragEvents)
        
        
    }
 componentDidUpdate(prevProps:VTableProps){
    if( prevProps.columns!==this.props.columns && this.ref.current){ 
        this.setState({...this.getVisibleCols()},()=>this.setWidthAfterRisizePage(this.ref.current.state.width||0));  
    }
    if(prevProps.data.keys !== this.props.data.keys){
        this.setState({keys:this.props.data.keys})
    } 
 }


    componentWillUnmount() {
        document.removeEventListener('keyup',this.editEvents)
        document.removeEventListener('mouseup',this.dragEvents) 
    }


    cellEditor(id: string, column: string, isEditable: boolean, cellData: string) {

        const { startEdit, loading } = this.props;
        if (isEditable && startEdit && !loading) {
            startEdit(id, column);
            this.setState({ newColumnValue: cellData || '' });
        }
    }

    ////////////////////DRAGGABLE COLUMNS/////////////////////////////
    //we need  right and left resizer because empty div element can't overlap vertical column border
    //so we add 2 empty div elements , vertical columns bars will be between pairs of resizers 
    captureRightResizer(leftColumnName: string, e: any) {
        const { visibleColumns: columns } = this.state;
        if (!columns) {
            return
        }
        //finds index of the left column( columns with index+1 will be right column)   
        e.preventDefault();
        this.clearSelection();
        let i = this.getColumnIndex(leftColumnName);

        if (i - 1 >= 0) {
            this.setState({
                draggedColumns: {
                    // col1: this.props.columns[i - 1].key,
                    col1: columns[i - 1].key,
                    col2: leftColumnName,
                    x: e.pageX
                }
            })
        }

    }

    captureLeftResizer(leftColumnName: string, e: any) {
        //finds index of the left column( columns with index+1 will be right column)  
        const { visibleColumns: columns } = this.state;
        if (!columns) {
            return
        }
        e.preventDefault();
        this.clearSelection();
        let i = this.getColumnIndex(leftColumnName);

        if (i + 1 < columns.length) {
            this.setState({
                draggedColumns: {
                    col1: leftColumnName,
                    col2: columns[i + 1].key,
                    x: e.pageX
                }
            })
        }

    }

    resiserIsBeingMoved(evt: any) {
        if (this.state.draggedColumns.col1 !== '' && this.state.draggedColumns.col2 !== '') {
            evt.preventDefault();
            let oldX = this.state.draggedColumns.x;
            let newX = evt.pageX;
            let delta = newX - oldX;
            let col1 = this.state.draggedColumns.col1;
            let col2 = this.state.draggedColumns.col2;

            let col1NewWidth = this.state.columnsWidths[col1] + delta;
            let col2NewWidth = this.state.columnsWidths[col2] - delta;
            if (col1NewWidth > 10 && col2NewWidth > 10) {
                this.setState({
                    draggedColumns: { ...this.state.draggedColumns, x: newX }
                    , columnsWidths: { ...this.state.columnsWidths, [col1]: col1NewWidth, [col2]: col2NewWidth }
                });
            }
        }
    }

    getColumnIndex(columnName: string) {
        const { visibleColumns: columns } = this.state;
        let i = 0;
        for (let col of columns) {
            if (col.key === columnName) {
                break;
            }
            i++;
        }
        return i;
    }
    //////////////////// END DRAGGABLE COLUMNS/////////////////////////////


    changeSort = ( newSortBy:string, type: TableColumnType ) => {  
        const {sortBy:oldSortBy,sortDirection} = this.state;
        let newDirection:SortDirectionType|null = null; 
        if(newSortBy !== oldSortBy || sortDirection === null){
            newDirection = 'ASC';
        }else{ 
            if(sortDirection === 'ASC'){
                newDirection = 'DESC';
            }
        }
        const keys = this.sortKeys(newSortBy,newDirection,type); 
        this.setState({sortBy:newSortBy,sortDirection:newDirection,sortedType:type,keys} )
    }

    renderSort = (data: TableHeaderProps) =>{
        const {sortBy, sortDirection:sd} = this.state;
        let sort = data.columnData.sort;
        let type = data.columnData.type;
        let newSortBy = data.dataKey;
        if(!sort  ){
            return null;
        }
        const changed = sortBy === newSortBy; 
        return <div style={{cursor:'pointer'}} onClick={(e)=>  this.changeSort(newSortBy,type||'string')} className="mx-1 d-flex flex-column ">
          { (sd === 'DESC' || !sd || !changed) && <FontAwesomeIcon style={{marginBottom:'-7px'}} className="" icon={faCaretUp}/>}
          { (sd === 'ASC' || !sd || !changed) &&  <FontAwesomeIcon icon={faCaretDown}/> }
        </div>
    }

    //////////////////////HEADER COLUMNS RENDERING///////////////
    headerRenderer = (data: TableHeaderProps) => {
         
        let dataKey = data.dataKey;
        let sort = data.columnData.sort; 
        let isCheckableColumn = this.isCheckableColumn(data.columnData);

        let label = null;
        if (data.columnData.headerRender) {
            label = data.columnData.headerRender(data.label);
        } else {
            let checkboxElement = this.renderHeaderCheckbox(isCheckableColumn);
            label = this.renderLabel(checkboxElement, data.label);
        }

        let renderLeftResizer = this.renderLeftResizer(this.isLastHeaderColumn(dataKey), dataKey);
        let renderRightResizer = this.renderRightResizer(this.isFirstHeaderColumn(dataKey), dataKey);

        let cellData = <>
                {<div className="mr-1" >{label}</div>}
                {this.renderTools(data)}
        </>

        if (data.columnData.headerCellRenrerer) {
            cellData = data.columnData.headerCellRenrerer();
        }

        return < >
            {renderRightResizer}
            <div className="mt-2 d-flex flex-row justify-content-beetwen">
                {cellData}
                {this.renderSort(data)}
            </div>
            {this.renderEmptyDivFiller()}
            {renderLeftResizer}
        </ >
    }

    renderTools(headerData: TableHeaderProps) {
        const columnData = headerData.columnData as TableColumn;
        const { loading } = this.props;
        if (columnData.headerColumnAddon) {
            const icon = columnData.headerColumnAddon.element;
            const callback = columnData.headerColumnAddon.action;
            // return (<div className={`${!loading ? styles.interactive:''} mx-3`}>
            return (<div className={`${!loading ? styles.interactive : ''} `}>
                <div onClick={() => { !loading && callback(headerData) }}>
                    {icon}
                </div>
            </div>);
        }
        return null;
    }


    renderEmptyDivFiller() {
        return <div className={styles.emptyFiller}> </div>
    }

    renderHeaderCheckbox(isCheckable: boolean) {
        let checkbox = <span>
            <input
                className={styles.check}
                type="checkbox"
                checked={this.props.isAllItemsChecked}
                onChange={this.checkAllItems.bind(this)} />
        </span>;

        return isCheckable ? checkbox : <></>;
    }


    renderLeftResizer(isLastHeaderColumn: boolean, columnKey: string) {
        if (!isLastHeaderColumn) {
            let resizerElement = <span onSelectCapture={(e) => { e.preventDefault() }}
                onMouseDown={(e) => this.captureLeftResizer(columnKey, e)}
                className={styles.headerResizerSpan}> </span>;

            return resizerElement;
        } else {
            return <></>;
        }
    }


    renderRightResizer(isFirstHeaderColumn: boolean, columnKey: string) {
        if (!isFirstHeaderColumn) {
            let resizerElement = <span onSelectCapture={(e) => { e.preventDefault() }}
                onMouseDown={(e) => this.captureRightResizer(columnKey, e)}
                className={styles.headerResizerSpan}> </span>;

            return resizerElement;
        } else {
            return <></>;
        }
    }


    renderLabel(checkbox: any = <></>, columnLabel: any) {
        if (!columnLabel) {
            return <></>;
        }
        const label = (<FormattedMessage id={columnLabel}
            defaultMessage={columnLabel || '#'}
            description="Column index" />);
        return <div className={styles.headerLabel}>
            {checkbox}
            {label}
        </div>
    }
    /////////////////////END HEADER RENDERING////////////



    ////////////////////BODY COLUMNS RENDERER///////////
    renderCellForModalEditor(columnChecked: boolean, isCheckableColumn: boolean, isColumnEditable: boolean, id: string, cellData: any) {
        let invokeModalEditor = this.props.editInModal ? this.props.editInModal : (id: string) => { };
        const { loading } = this.props;
        if (!cellData) {
            return <div className="h-100" style={{ visibility: 'hidden' }} >invisible</div>
        }
        const editableContent = !loading ? <a onClick={() => { invokeModalEditor(id) }} href="#">{cellData}</a> : cellData;
        return < div className="mt-2 ml-1" >
            {this.renderBodyColumnCheckbox(columnChecked, isCheckableColumn, id)}
            {isColumnEditable ? editableContent : <span   >{cellData}</span>}
        </div>
    }

    renderColumnPartialEditor(columnChecked: boolean,
        isCheckableColumn: boolean,
        id: string,
        cellData: any,
        key: string,
        isColumnEditable: boolean,
        isBeingEdited: boolean) {
        return <div className={!cellData && !isBeingEdited ? 'h-100 ml-1 mt-2' : 'ml-1 mt-2'} onDoubleClick={() => this.cellEditor(id, key, isColumnEditable, cellData)}  >
            {this.renderBodyColumnCheckbox(columnChecked, isCheckableColumn, id)}
            {isBeingEdited ? this.renderEditTextField() : <span style={{whiteSpace:'break-spaces'}} >{cellData}</span>}
            {/* just filler for h-100 class work correctly */}
            {!cellData && !isBeingEdited && <span style={{ visibility: 'hidden' }}>invisible</span>}
        </div>
    }

    getChecked(data: TableData, key: string): boolean {
        if (!data['checked'] || Array.isArray(data['checked'])) {
            return false;
        }
        return data['checked'][key]
    }

    bodyCellRenderer = (cellProps: TableCellProps) => {
        
        const { columnData } = cellProps;
        const tableColumn = columnData as TableColumn;
        const { data, offset } = this.props;

        let indexedColumn = this.isIndexColumn(columnData);
        let downloadingColumn = this.isDownloadingColumn(columnData);

        let isCheckableColumn = this.isCheckableColumn(columnData);
        let isColumnEditable = this.isColumnEditable(columnData);
        let cellData: any = '';
        let id = cellProps.rowData;
        let key = cellProps.dataKey;
        // let columnChecked = this.props.data.checked ? this.props.data['checked'][id] : false;
        let columnChecked = this.getChecked(this.props.data, id);

        let isBeingEdited = this.isColumnBeingEdited(id, key);



        //If index column is being rendered ordered numbers will go to cellData
        //otherwise data from db will go to celData  
        if (tableColumn.uniq) {
            cellData = data.keys[cellProps.rowIndex];
        }
        else if (indexedColumn) {
            let idx = cellProps.rowIndex + 1;
            cellData = idx + (offset || 0);
        }
        // else if (downloadingColumn) {
        //     cellData = (<div className={styles.DownloadColumn}>
        //         <div onClick={() => { this.onDownloadItem(id) }}>
        //             <FontAwesomeIcon icon={faDownload} />
        //         </div>
        //     </div>);
        // }
        else {
            let data = this.props.data[key];
            // cellData = data instanceof Array ? data[cellProps.rowIndex] : data[id];
            if (data) {
                cellData = data[id];
                if (cellData && cellData.length > 100) {
                    cellData = cellData.substring(0, 255);
                }
                cellData = this.convertConsideringColumnType(cellProps.columnData, cellData);
                // cellData = cellProps.columnData.render ? cellProps.columnData.render(cellData) : cellData;
            }
        }

        if (tableColumn.bodyCellRenrerer) {
            cellData = tableColumn.bodyCellRenrerer(id, cellData,cellProps);
        }

        // if (tableColumn.bodyColumnAddon) {
        //     const { action: callback, element } = tableColumn.bodyColumnAddon;
        //     // const data = this.props.data[key]; 
        //     element && (cellData = element)
        //     // const icon = cellProps.columnData.interactiveBodyColumn.icon; 
        //     cellData = (<div className={styles.interactive} onClick={() => { callback(cellProps, id) }}>
        //         {cellData}
        //     </div>);
        // }


        return this.props.modalEditor ? this.renderCellForModalEditor(columnChecked, isCheckableColumn, isColumnEditable, id, cellData) :
            this.renderColumnPartialEditor(columnChecked,
                isCheckableColumn,
                id,
                cellData,
                key,
                isColumnEditable,
                isBeingEdited);
    }

    renderBodyColumnCheckbox(columnChecked: boolean, isCheckable: boolean, columnUniqKey: string) {
        let checkbox = <span className={styles.check}>
            <input type="checkbox"
                checked={columnChecked}
                onChange={(e) => { this.checkItem(e, columnUniqKey) }} />
        </span>
        return isCheckable ? checkbox : <></>;
    }

    renderEditTextField() {
        return <input type="text" autoFocus={true}
            className="form-control"
            value={this.state.newColumnValue}
            onChange={(e) => this.setState({ newColumnValue: e.target.value })}
        />
    }
    ////////////////////END BODY COLUMNS RENDERER///////////




    ///////////////////UTILS FOR RENDERERS////////////////// 
    convertConsideringColumnType(columnData: any, cellData: string) {
        if (!cellData) {
            return cellData;
        }
        if (columnData.type) {
            const type = columnData.type;
            if (type === 'date') {
                return moment(cellData).format(columnData.dateformat || DEFAULT_DATE_FORMAT);
            } else {
                return cellData;
            }
        }
        return cellData;
    }

    onDownloadItem(key: string) {
        if (this.props.downloadItem) {
            this.props.downloadItem(key);
        }
    }
    isCheckableColumn(columnData: TableColumn) {
        let checkable = false;
        if (typeof columnData.checked !== 'undefined') {
            checkable = columnData.checked;
        }
        return checkable;
    }
    isIndexColumn(columnData: TableColumn) {
        let indexed = false;
        if (typeof columnData.index !== 'undefined') {
            indexed = columnData.index;
        }
        return indexed;
    }
    isDownloadingColumn(columnData: any) {
        let downloading = false;
        if (typeof columnData.download !== 'undefined') {
            downloading = columnData.download;
        }
        return downloading;
    }

    isColumnEditable(columnData: any) {
        let editable = false;
        if (typeof columnData.editable !== 'undefined') {
            editable = columnData.editable;
        }
        return editable;
    }
    //If edited object {name:..,columnValue:..,columnName:...} exists in the store 
    //it's needed which column is represented by this object and put input text field into column
    isColumnBeingEdited(uniqColumnValue: string, columnName: string) {
        const { inlineEditedColumn } = this.props;

        if (typeof inlineEditedColumn !== 'undefined') {
            // const editedObjUniq = this.props.data.edited[uniqColumnName];
            const editedObjUniq = inlineEditedColumn.key;
            const editedObjColumnName = inlineEditedColumn.columnName;
            if (uniqColumnValue === editedObjUniq && columnName === editedObjColumnName) {
                return true;
            }
        }
        return false;
    }
    isThereEditing() {
        if (typeof this.props.inlineEditedColumn !== 'undefined') {
            return true;
        }
        return false;
    }


    isLastHeaderColumn(columnKey: String) {
        const { visibleColumns: columns } = this.state;
        let lastElement = columns.length - 1;
        return columns[lastElement].key === columnKey;
    }
    isFirstHeaderColumn(columnKey: String) {
        const { visibleColumns: columns } = this.state;
        return columns[0].key === columnKey;
    }


    // _rowClassName({ index }: any) {
    //     if (index < 0) {
    //         return styles.headerRow;
    //     } else {
    //         return index % 2 === 0 ? styles.evenRow : styles.oddRow;
    //     }
    // }

    _rowClassName({ index }: any) {
        if (index < 0) {
            return `    text-dark ${styles.headerRow}`;
        } else if (index % 2 !== 0) {
            // return TABLE_BODY_ROW_CLASS;
            return `    ${TABLE_BODY_ROW_CLASS} ${styles.bodyRow}`
        } else {
            return `  ${TABLE_BODY_ROW_CLASS} ${styles.bodyRow}`;
        }
    }
    checkAllItems(e: any) {
        if (this.props.checkAllItems) {
            this.props.checkAllItems(e.target.checked);
        }
    }
    checkItem(e: any, columnUniqKey: string) {
        if (this.props.checkItem) {

            this.props.checkItem(columnUniqKey, e.target.checked);
        }
    }
    ///////////////////END UTILS FOR RENDERERS//////////////////




    //////////////////SIZE SETTERS//////////////////////////////
    setWidthAfterRisizePage(width:number) {
        // let width = evt.width;
        let columnsWidthSum = 0;
        let columnsWidths = this.state.columnsWidths;
        for (let col in columnsWidths) {
            columnsWidthSum += columnsWidths[col];
        }
        if(!columnsWidthSum){
            columnsWidthSum = 1;
            columnsWidths = this.getVisibleCols().columnsWidths;
        }

        for (let col in columnsWidths) {
            let perc = (columnsWidths[col] * 100) / columnsWidthSum;
            let factW = Math.round((width * perc) / 100);
            columnsWidths[col] = factW ;
            this.setState({
                columnsWidths 
            });
        } 
        return width;
    }

    getColumnWidth(col: any) {
        return this.state.columnsWidths[col.key]
    }
    //////////////////END SIZE SETTERS//////////////////////////////



    //Fix unkillable dragging
    clearSelection() {
        if (window.getSelection) {
            let selection = window.getSelection();
            if (selection == null) {
                return;
            }
            if (selection.empty) {  // Chrome
                selection.empty();
            } else if (selection.removeAllRanges) {  // Firefox
                selection.removeAllRanges();
            }
        }
    }

    noDataRowRenderer(width: number) {
        return <div style={{ width: width }} className={`${styles.NoDataRow}   `}>
            {MSG_TABLE_NO_DATA_TO_DISPLAY}
        </div>
    }
    //if grid width equals to header width
    //right borders of body rows is not visible
    //so we increase   width of grid a bit (to 1 %)
    gridStyle(width: number) {
        const perc = width * 0.05 / 100;
        const newWidth = (width - perc) + 'px'
        return { width: newWidth };
    }
    renderLoading() {
        const { loading } = this.props;
        if (!loading) {
            return null
        } 
        return <div className="d-flex justify-content-center" style={{ position: 'absolute', zIndex: 1, top: '50%',left:'50%' }}>
            <GlowingLoader/> 
        </div>
    }
     isTableHeightLess = (rows: any[], tableHeight: number, rowHeight: number, headerHeight: number) => {
        return rows.length && tableHeight > rowHeight * rows.length + headerHeight
    }
    getTableHeight = (rows: any[], rowHeight: number, headerHeight: number, prefferedCount: number) => {
        let tableHeight = rows.length ? rowHeight * prefferedCount + headerHeight : rowHeight + headerHeight;
    
        if (this.isTableHeightLess(rows, tableHeight, rowHeight, headerHeight)) {
            tableHeight = rowHeight * rows.length + headerHeight
        }
        return tableHeight
    }

    getSortFunction = (type: string|null, sortBy: string, sortDirection: SortDirectionType) => {
        let sortFunction:Function = (item1: string, item2: string) => sortDirection === 'ASC' ? item1.localeCompare(item2) : item2.localeCompare(item1);
        if (type === 'number') {
            sortFunction = (item1: number, item2:number) => sortDirection === SortDirection.ASC ? item1 - item2 : item2 - item1;
        }  
        return sortFunction;
    }

    
    sortKeys = (sortBy:string|null,sortDirection:SortDirectionType|null,sortedType:TableColumnType) =>{
        // const {sortBy,sortDirection,sortedType}  = this.state; 
        const {data} = this.props; 
        if(!sortDirection  || !sortBy ){
           return  data.keys;
        }
        
        const sortedData = data[sortBy];
        
        const sortFunction:Function =  this.getSortFunction(sortedType,sortBy,sortDirection); 
        const newData = Object.entries(sortedData).sort((item1, item2) => {  return sortFunction(item1[1], item2[1]) });
        
         
        return  newData.map(e=>e[0]);
    }
    renderTable() {
    
        const { visibleColumns: columns,keys } = this.state;
        const headerHeight = 50
        const rowHeight = 50
        const { data } = this.props;
        const uniqKeys: string[] = [...keys];
        const {maxRowCount} = this.props;

        const prefferedCount = maxRowCount !== undefined ? maxRowCount : 6;
        let tableHeight = this.getTableHeight(uniqKeys, rowHeight, headerHeight, prefferedCount);
        let style = { height: tableHeight + 'px',/* width: '100%', marginBottom: '-.5rem'*/ }
 
        const rowCount = uniqKeys.length;
        return (<div style={style} className={` d-flex flex-fill    `} id="virtualizedTable" onMouseMove={this.resiserIsBeingMoved} >
            <AutoSizer ref={this.ref} onResize={(e) => { this.setWidthAfterRisizePage(e.width); }}>
                {({ width, height }) => (
                    <Table
                        width={width} 
                        rowHeight={rowHeight}
                        headerHeight={headerHeight}
                        // headerClassName={styles.headerColumn}
                        headerClassName={`${styles.headerColumn} npt-table-header-col ${TABLE_HEADER_COLUMN_CLASS} npt-table-header-column`}
                        height={height}
                        gridStyle={this.gridStyle(width)}
                        rowCount={rowCount}
                        scrollToAlignment='start'
                        rowGetter={({ index }) => uniqKeys[index]}
                        rowClassName={this._rowClassName}
                        gridClassName={`${styles.GridStyle} `}
                        noRowsRenderer={() => this.noDataRowRenderer(width)}

                    >
                        {columns.map((col) => {                             
                            return <Column
                                key={col.key}
                                label={col.label}
                                dataKey={col.key}
                                columnData={col}
                                width={this.getColumnWidth(col)}
                                className={styles.bodyColumn}
                                cellRenderer={this.bodyCellRenderer}
                                headerRenderer={this.headerRenderer}
                            />
                        })}
                    </Table>
                )}

            </AutoSizer>
        </div>
        );
    }
 
    render() {
        const { data } = this.props;
        const isDataExist = data.keys.length > 0;
        
        return <div className={`h-100 ${TABLE_CLASS} light-table`}>
            {this.renderLoading()}
            <div className={`${styles.TableContainer}    rounded border bg-white`}>
                {this.renderTable()}
                {isDataExist && this.props.paginator}
            </div>
        </div>
    }
}

