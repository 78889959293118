import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { faExclamationTriangle, faInfo, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApplicationAction, ApplicationState } from '../../../types';
import { FetchError } from '../../../types/error';
import { ModalInfo, ModalStatus, TreeModalInfo } from '../../../types/modal';
import { isFetchError, RawLink, RefTableTreeOptions } from '../../../types/subject';
import { TreeNode, isTreeNode } from '../../../types/tree';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { expandTreeUsingPath, sendTreeActiveNodeValid } from '../../../actions/tree';
import { checkRef } from '../../../actions/subject';
import { ConnectedListTree, ConnectedSimpleNode } from '../../../services/tree';

import TreeFilter from '../../tree/TreeFilter';
import ModalView from '../ModalView';

interface AddRefTableModalProps {
    modal: TreeModalInfo
    loading?: boolean
    loadedHeader?: boolean
    active?: TreeNode | FetchError
    activeValid?: boolean | null
    error?: FetchError
    rootCount?: number
    closeModal: (status: ModalStatus, result: any) => void
    checkRef: (relatedClass: string, reference: any, callback: Function) => void
    cleanTree: (path: string) => void
    nodeValid: (path: string, isValid: boolean, id: string) => void
    expandTreeUsingPath: (treeId: string, rdfId: string, rootNodeRdfId?: string) => void
}

interface AddRefTableModalState {
    data: any | null
}

class AddRefTableModal extends React.Component<AddRefTableModalProps, AddRefTableModalState> {
    checkStack: { data: any, callback: Function }[] = [];
    validatedNodes: { [RDF_ID: string]: boolean } = {};
    title = { id: "NAVTREE_MODAL_TITLE" };
    initialized: boolean = false;

    constructor(props: AddRefTableModalProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.state = { data: null }
    }

    componentDidMount() {
        const { active } = this.props;

        if (!this.initialized) {
            this.initializeRootNode();
        }

        if (isTreeNode(active)) {
            this.selectObject(active);
            return
        }
    }

    componentDidUpdate(previousProps: AddRefTableModalProps) {
        const { active } = this.props;
        const { active: pActive } = previousProps;

        if (!this.initialized) {
            this.initializeRootNode();
        }

        if ((isTreeNode(active) && !pActive) ||
            (isTreeNode(active) && isFetchError(pActive)) ||
            (isTreeNode(active) && isTreeNode(pActive) && active.id !== pActive.id)
        ) {
            this.selectObject(active);
            return
        }
    }

    initializeRootNode() {
        const { treeId, rootRdfId } = this.props.modal.options;
        if (!rootRdfId) {
            return;
        }
        if (treeId && this.props.loadedHeader) {
            this.props.expandTreeUsingPath(treeId, rootRdfId, rootRdfId);
            this.initialized = true;
        }
    }

    checkFinish(isValid: boolean, callback: Function) {
        this.checkStack.shift();
        callback(isValid);
        if (this.checkStack.length !== 0) {
            this.recursiveCheck(this.checkStack[0].data, this.checkStack[0].callback);
        }
    }

    recursiveCheck(data: any, callback: Function) {
        const { checkRef } = this.props;
        const options = this.props.modal.options;
        if (typeof this.validatedNodes[data.$rdfId] != "undefined") {
            this.checkFinish(this.validatedNodes[data.$rdfId], callback);
            return;
        }
        if (!options || !options.className) {
            this.checkFinish(true, callback);
            return;
        }

        const link: RawLink = { $rdfId: data.$rdfId, $namespace: data.$namespace };
        options && checkRef(options.className, link, (isValid: boolean) => {
            this.validatedNodes[data.$rdfId] = isValid;
            this.checkFinish(isValid, callback);
        });
    }

    checkData(data: any, callback: Function) {
        this.checkStack.push({ data, callback });
        /* If stack contains more than one element - then chain of checks will be evaluated directly from inside of checking cycle */
        if (this.checkStack.length !== 1) {
            return;
        }
        this.recursiveCheck(data, callback);
    }

    setResult(data: any) {
        const { nodeValid, active } = this.props;
        const options = this.props.modal.options;
        const nodeId = isTreeNode(active) ? active?.id : null;
        if (!data /*|| typeof this.props.options.checkFunction != "function"*/) {
            options && nodeId && nodeValid(options.treeId, false, nodeId);
            return data;
        }
        this.checkData(data, (isValid: boolean) => {
            options && nodeId && nodeId && nodeValid(options.treeId, isValid, nodeId);
            if (!isValid) {
                this.setState({ data: null });
                return;
            }
            this.setState({ data: data });
        });
    }

    selectData(node: TreeNode) {
        const options = this.props.modal.options;
        if (node && node.data && node.data.$rdfId && options) {
            if (options.nodeTypeId) {
                let nodeTypeIdList: string[] = [];

                if (typeof options.nodeTypeId == "string") {
                    nodeTypeIdList = [options.nodeTypeId];
                } else if (Array.isArray(options.nodeTypeId)) {
                    nodeTypeIdList = options.nodeTypeId;
                }

                let found = false;
                for (let typeId of nodeTypeIdList) {
                    if (node.typeId === typeId) {
                        found = true;
                        this.setResult(node.data);
                        console.log("Select data by typeId:", node);
                        break;
                    }
                }
                if (!found) {
                    this.setResult(null);
                    console.log("Unselect data by typeId:", node);
                }
            } else {
                console.log("Select data without typeId:", node);
                this.setResult(node.data);
            }
        } else {
            console.log("Unselect data without data:", node);
            this.setResult(null);
        }
    }

    selectFragment(node: TreeNode) {
        const options = this.props.modal.options;
        if (node.data && node.data.$fragment && options) {
            if (options.nodeTypeId) {
                let nodeTypeIdList: string[] = [];

                if (typeof options.nodeTypeId == "string") {
                    nodeTypeIdList = [options.nodeTypeId];
                } else if (Array.isArray(options.nodeTypeId)) {
                    nodeTypeIdList = options.nodeTypeId;
                }

                let found = false;
                for (let typeId of nodeTypeIdList) {
                    if (node.typeId === typeId) {
                        found = true;
                        this.setResult(node.data.$fragment);
                        console.log("Select fragment by typeId:", node);
                        break;
                    }
                }
                if (!found) {
                    this.setResult(null);
                    console.log("Unselect fragment by typeId:", node);
                }
            } else {
                console.log("Select fragment without typeId:", node);
                this.setResult(node.data.$fragment);
            }
        } else {
            console.log("Unselect fragment without data:", node);
            this.setResult(null);
        }
    }

    selectObject(node: TreeNode) {
        const options = this.props.modal.options;

        if (options && options.selectFragment) {
            this.selectFragment(node);
        } else {
            this.selectData(node);
        }
    }


    renderTemplate(): React.ReactElement {
        const { loading, active, activeValid, error, rootCount } = this.props;
        const options = this.props.modal.options;
        if (!options) {
            return <></>;
        }
        const { treeId, nodeTypeId, rootRdfId } = options;
        if (error) {
            return <div className="text-danger d-flex justify-content-center">
                <FontAwesomeIcon className="mr-1" icon={faExclamationTriangle} />
                <FormattedMessage id="OBJECTCARD_FAIL_TO_LOAD_TREE" />
            </div>
        }

        const emptyTree = rootCount === 0 && (<div className="text-primary d-flex justify-content-center">
            <FontAwesomeIcon className="mr-1" icon={faInfo} />
            <FormattedMessage id="OBJECTCARD_EMPTY_TREE" />
        </div>
        )
        if (loading) {
            return <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        }

        const warning = activeValid === false && <div className="text-warning mt-1 text-align-center"><FormattedMessage id="SUBJECT_TREE_ADD_ERROR" /></div>

        return (<>
            <div style={{ maxHeight: "25rem", overflowY: 'auto' }}>
                <TreeFilter treeId={treeId} />
                <ConnectedListTree treeId={treeId} renderComponent={ConnectedSimpleNode} initRootRdfId={rootRdfId} />
            </div>
            {warning}
            {emptyTree}
        </>
        );
    }


    closeModal(status: ModalStatus, result: any) {
        const { active, cleanTree } = this.props
        const options = this.props.modal.options;
        const { data } = this.state
        if (status === MODAL_STATUS_CANCEL || status === MODAL_STATUS_CLOSE) {
            this.props.closeModal(status, result);
            options && cleanTree(options.treeId)
        }
        if (status === MODAL_STATUS_OK && data && active) {
            this.props.closeModal(status, data);
            options && cleanTree(options.treeId)
            return;
        }
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}

export default connect((state: ApplicationState, ownProps: { modal: ModalInfo }) => {
    const body = ownProps.modal.options.body || ownProps.modal.options;
    let options: RefTableTreeOptions | null = null;
    if (typeof body === "object") {
        options = body as any as RefTableTreeOptions;
    } else if (typeof body === "string") {
        const opt: RefTableTreeOptions = JSON.parse(body);
        options = opt;
    }
    const treeId = (options && options.treeId) || '';
    const tree = state?.tree?.treeInfo?.[treeId];
    const loading = tree && tree.loading && tree.loading[treeId];
    const loadedHeader = Boolean(tree?.header);
    const active = tree && tree.active;
    const isValid = tree && tree.valid;
    let activeNode = (active && tree?.nodeById?.[active]) || undefined;
    let activeValid = isValid?.isValid
    // if (isFetchError(activeNode)) {
    //     activeValid = false
    // }
    const rootCount = (tree && tree.rootNodesIds && tree.rootNodesIds.length) || 0;
    return {
        rootCount,
        active: activeNode,
        activeValid,
        loading,
        loadedHeader,
        error: tree && tree.error
    }
},
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
        return {
            expandTreeUsingPath: (treeId: string, rdfId: string, rootNodeRdfId?: string) => dispatch(expandTreeUsingPath(treeId, rdfId, rootNodeRdfId)),
            checkRef: (relatedClass: string, reference: any, callback: Function) => dispatch(checkRef(relatedClass, reference, callback, true)),
            cleanTree: (path: string) => { },
            nodeValid: (path: string, isValid: boolean, id: string) => dispatch(sendTreeActiveNodeValid(path, isValid, id))
            // dispatch(sendTreeCompress(path)),
            // dispatch(sendTreeNodeActive(path, ''))
        }
    })
    (AddRefTableModal)