import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect, ReactReduxContext } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { AnyAction, Store } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchMenu, sendMenuEditorClearMenu } from "../../../actions/menueditor";
import menuEditorReducer from '../../../reducers/menueditor';
import { ApplicationAction, ApplicationState, ReducerManagerInterface } from "../../../types";
import MenuEditor from "./MenuEditor";

export function wrapWithTooltip(id: string, component: JSX.Element | null) {
  if (!component) {
    return null;
  }
  return (
    <OverlayTrigger placement="bottom" overlay={
      <Tooltip color="warning" id="tooltip"><FormattedMessage id={id} /></Tooltip>
    }>
      {component}
    </OverlayTrigger>
  )
}

const ConnectedMenuEditor = connect(null,
  (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
    return {
      // fetchMenuTree: () => dispatch(fetchMenuEditorTree()),
      fetchMenu: (path: string) => dispatch(fetchMenu(path)),
      activate: (path: string) => {
        dispatch(sendMenuEditorClearMenu());
      }
    }
  })(MenuEditor)

interface MenuEditorPageRouterProps {
  store: Store<any, AnyAction>;
}


class MenuEditorPageRouter extends React.Component<MenuEditorPageRouterProps> {

  constructor(props: MenuEditorPageRouterProps) {
    super(props);

    //Register reducer to server our requests
    const manager = (props.store as any).reducerManager as ReducerManagerInterface;
    manager.add("menueditor", menuEditorReducer);

  }

  render() {
    return (<Switch >
      <Route path='/developer/menueditor' exact component={ConnectedMenuEditor} />
    </Switch>);
  }
}

export default class MenuEditorPage extends React.Component {

  shouldComponentUpdate(nextProps: any) {
    return false;
  };

  render() {
    return <ReactReduxContext.Consumer>
      {({ store }) => (<MenuEditorPageRouter store={store} />)}
    </ReactReduxContext.Consumer>;
  }
}