import React from 'react';
import { NptTheme } from '../../constants/theme';
import { ThemeService } from '../../services/theme';
import { UIVariant } from '../../types/security'; 

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const ThemeDefault = React.lazy(() => import('./ThemeDefault'));
const ThemeDefaultCompact = React.lazy(() => import('./ThemeDefaultCompact'));
const ThemeCustomeDark = React.lazy(() => import('./ThemeCustomDark'));
const ThemeCustomDarkCompact = React.lazy(() => import('./ThemeCustomDarkCompact'));


const ThemeImporter = (props: { variant?: UIVariant, children: any }) => {
    const currentTheme = ThemeService.getTheme();
    let themeComponent = null;
    switch (currentTheme.platformTheme) {
        case NptTheme.CUSTOM_DARK: 
            themeComponent = <ThemeCustomeDark />
            break;
        case NptTheme.CUSTOM_DARK_COMPACT: 
            themeComponent = <ThemeCustomDarkCompact />
            break;
        case NptTheme.DEFAULT: 
            themeComponent = <ThemeDefault />
            break;
        case NptTheme.DEFAULT_COMPACT: 
            themeComponent = <ThemeDefaultCompact  />
            break;
        default:
            themeComponent = props.variant === 'scm' ? <ThemeCustomDarkCompact /> : <ThemeDefaultCompact/>
            break;
    }
    return (
        <>
            <React.Suspense fallback={null}>
                {themeComponent}
            </React.Suspense>
            {props.children}
        </>
    )
};


export default ThemeImporter;