import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import moment from "moment";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import {
  ModalInfo,
  ModalOptions,
  ModalStatus,
  OkCallback,
} from "../../../types/modal";
import {
  SourceEditorType,
  SourceHistoryInfo,
} from "../../../types/sourceeditor";
// import TableApiComponent, { NptTableColumn, NptTableColumns } from '../../table/TableApiComponent';
import ModalView from "../ModalView";
import { openModal } from "../../../actions/modal";
import { sourceRestoreCode } from "../../../actions/sourceeditor";
import AsyncTable, {
  NptTableColumn,
  NptTableColumnProps,
  NptTableColumns,
} from "../../table/AsyncTable";
import { LocalSimpleTableApi } from "../../../types/table";

const operationMessage: { [k: number]: any } = {
  0: (
    <FormattedMessage
      id="SRCEDITOR_OPERATION_CREATE"
      defaultMessage="Create"
      description="Operation create"
    />
  ),
  1: (
    <FormattedMessage
      id="SRCEDITOR_OPERATION_UPDATE"
      defaultMessage="Update"
      description="Operation update"
    />
  ),
  2: (
    <FormattedMessage
      id="SRCEDITOR_OPERATION_DELETE"
      defaultMessage="Delete"
      description="Operation delete"
    />
  ),
};

interface SourceHistoryModalProps {
  modal: ModalInfo;
  openModal: (
    id: string,
    type: string,
    options: ModalOptions,
    ok: OkCallback
  ) => void;
  closeModal: (status: ModalStatus, result: any) => void;
  restore: (editorId: SourceEditorType, id: number, path: string) => void;
}

class SourceHistoryModal extends React.Component<SourceHistoryModalProps> {
  constructor(props: SourceHistoryModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(status: ModalStatus, result: any) {
    if (status == MODAL_STATUS_OK) {
      this.props.closeModal(status, true);
    } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, false);
    }
  }

  render() {
    const modal = { ...this.props.modal };
    modal.options = {
      ...modal.options,
      title: { id: "SRC_EDITOR_HISTORY_TITLE" },
    };
    return (
      <ModalView
        modal={modal}
        template={
          <HistoryTable
            editorId={modal.options.data.editorId}
            data={modal.options.data.history}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            restore={this.props.restore}
          />
        }
        closeModal={this.closeModal}
      />
    );
  }
}

interface HistoryTableProps {
  editorId: SourceEditorType;
  data?: SourceHistoryInfo[];
  openModal: (
    id: string,
    type: string,
    options: ModalOptions,
    ok: OkCallback
  ) => void;
  closeModal: (status: ModalStatus, result: any) => void;
  restore: (editorId: SourceEditorType, id: number, path: string) => void;
}
const HistoryTable: React.FunctionComponent<HistoryTableProps> = React.memo(
  (props) => {
    const intl = useIntl();
    if (!props.data) {
      console.error("Can't open history modal: no data was provided");
      return null;
    }

    const restore = (cell: any, row: any) => {
      const options = {
        title: { id: "SRC_EDITOR_HISTORY_RESTORE_CONFIRM" },
        data: row.data.path,
      };
      props.openModal("sourceeditor.path", "stringInput", options, (result) => {
        props.restore(props.editorId, row.data.id, result);
        props.closeModal(MODAL_STATUS_CANCEL, null);
      });
    };

    const restoreFormatter = (cell: any, row: SourceHistoryInfo) => {
      return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <button
            type="button"
            className="btn btn-xs btn-secondary"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={intl.formatMessage({
              id: "SRC_EDITOR_HISTORY_RESTORE",
            })}
            onClick={restore.bind(this, cell, row)}
          >
            <i className="fa fa-reply fa-fw" aria-hidden="true"></i>
          </button>
        </div>
      );
    };

    const pathFormatter = (cell: any, row: SourceHistoryInfo) => {
      return cell;
      // return cell.replace(/\//g, "/<wbr>");
    };

    const operationFormatter = (cell: any, row: SourceHistoryInfo) => {
      return operationMessage[cell];
    };
    const dateTimeFormatter = (cell: any, row: SourceHistoryInfo) => {
      return moment(cell).format("L LTS");
    };

    const userFormatter = (cell: any, row: SourceHistoryInfo) => {
      if (!cell) {
        return "--";
      }
      return cell.$label;
    };
    const localApi: LocalSimpleTableApi = {
      external: false,
      scrollable: false,
      pageable: false,
    };
    const columns: NptTableColumnProps[] = [
      {
        width: 50,
        format: "string",
        field: "",
        name: "",
        filterType: null,
        dataFormat: restoreFormatter,
      },
      {
        isKey: true,
        width: 70,
        format: "number",
        field: "id",
        name: intl.formatMessage({ id: "SRC_EDITOR_ID" }),
        filterType: null,
      },
      {
        width: 200,
        format: "html",
        field: "path",
        name: intl.formatMessage({ id: "SRC_EDITOR_PATH" }),
        filterType: null,
        dataFormat: pathFormatter,
      },
      {
        width: 120,
        format: "string",
        field: "operation",
        name: intl.formatMessage({ id: "SRC_EDITOR_OPERATION" }),
        filterType: null,
        dataFormat: operationFormatter,
      },
      {
        width: 150,
        format: "string",
        field: "operationTimestamp",
        name: intl.formatMessage({ id: "SRC_EDITOR_OPERATION_TIMESTAMP" }),
        filterType: null,
        dataFormat: dateTimeFormatter,
      },
      {
        width: 150,
        format: "string",
        field: "user",
        name: intl.formatMessage({ id: "SRC_EDITOR_USER" }),
        filterType: null,
        dataFormat: userFormatter,
      },
      {
        width: 120,
        format: "string",
        field: "mime",
        name: intl.formatMessage({ id: "SRC_EDITOR_MIME" }),
        filterType: null,
      },
    ];
    return (
      <AsyncTable
        localApi={localApi}
        columns={columns}
        rows={props.data.sort(
          (rowA, rowB) =>
            moment(rowB.operationTimestamp).valueOf() -
            moment(rowA.operationTimestamp).valueOf()
        )}
      ></AsyncTable>
    );
  }
);

export default connect(null, (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    openModal: (
      id: string,
      type: string,
      options: ModalOptions,
      ok: OkCallback
    ) => {
      dispatch(openModal(id, type, options, ok));
    },
    restore: (editorId: SourceEditorType, id: number, path: string) => {
      dispatch(sourceRestoreCode(editorId, id, path));
    },
  };
})(SourceHistoryModal);
