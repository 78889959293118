import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { I18NString, isI18NString } from "../../../types/modal";
import { LayoutNode, SubjectComment } from "../../../types/subject";
import styles from "./BasicInput.module.css";

export interface BasicInputProps {
  id: string;
  node: LayoutNode;
  visible: boolean;
  hideLabel?: boolean;
  error?: string | I18NString;
  editable?: boolean;
  cardEditable?: boolean;
  comment?: SubjectComment;
  className?: string;
}

const getGrid = (node: LayoutNode, param: string, def: number): number => {
  const key = "grid-" + param;
  if (!node.options) {
    return def;
  }
  const val = node.options[key];
  if (typeof val == "number") {
    return val;
  }
  if (typeof val == "string") {
    return parseInt(val);
  }
  return def;
};

const getLabelGrid = (node: LayoutNode) => {
  return {
    span: getGrid(node, "label-width", 6),
    offset: getGrid(node, "label-offset", 0),
  };
};

const getInputGrid = (node: LayoutNode) => {
  return {
    span: getGrid(node, "input-width", 6),
    offset: getGrid(node, "input-offset", 0),
  };
};

export default class BasicInput extends React.Component<BasicInputProps> {
  getMandatory() {
    const node = this.props.node;
    let mandatory = node?.options?.mandatory;
    if (typeof mandatory === "undefined") {
      mandatory = node.mandatory;
    }
    if (mandatory) {
      return <span className="text-danger">*</span>;
    }
    return null;
  }
  renderError() {
    let { error } = this.props;
    if (!error || (typeof error !== "string" && !isI18NString(error))) {
      return null;
    }
    return (
      <Form.Text className="text-danger">
        {typeof error === "string" ? (
          error
        ) : (
          <FormattedMessage id={error.id} values={error.values} />
        )}
      </Form.Text>
    );
  }
  renderTooltip = (description: string) => (props: any) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {description}
      </Tooltip>
    );
  wrapLabelWithTooltip = (label: any) => {
    const { hideLabel, children, node, id, editable, error, visible } =
      this.props;
    const description = this.props.node?.description;
    if (!description) {
      return label;
    }
    return (
      <OverlayTrigger overlay={this.renderTooltip(description)}>
        {label}
      </OverlayTrigger>
    );
  };
  render() {
    const { hideLabel, children, node, id, editable, error, visible } =
      this.props;
    if (!visible) {
      return null;
    }
    return (
      <>
        <Row className={`${styles.basic} card-row`}>
          <Form.Label
            column
            md={getLabelGrid(node)}
            className="text-right py-1"
          >
            {this.wrapLabelWithTooltip(
              <span>
                {!hideLabel && (node?.options?.label || node?.label || "")}
              </span>
            )}
            {!hideLabel && this.getMandatory()}
          </Form.Label>
          <Col className="py-1" md={getInputGrid(node)}>
            <div className="position-relative">
              {children}
              {hideLabel && this.getMandatory()}
            </div>
            <div>{editable && this.renderError()}</div>
            <Comment
              value={this.props.comment}
              editable={this.props.editable}
              cardEditable={this.props.cardEditable}
            />
          </Col>
        </Row>
      </>
    );
  }
}

interface CommentProps {
  className?: string;
  value?: SubjectComment;
  editable?: boolean;
  cardEditable?: boolean;
}
export const Comment = React.memo((props: CommentProps) => {
  if (!props.value || !props.value.text) {
    return null;
  }
  /**Check editable state of card */
  if (props.cardEditable) {
    if (props.value.hideOnCardEdit) {
      return null;
    }
  } else if (props.value.hideOnCardLock || props.value.hideOnLock) {
    return null;
  }
  /**Check editable state of input */
  if (props.editable) {
    if (props.value.hideOnEdit) {
      return null;
    }
  } else if (props.value.hideOnLock) {
    return null;
  }
  const style: React.CSSProperties = {};
  if (props.value.color) {
    style.color = props.value.color;
  }
  if (props.value.isHtml) {
    return (
      <div
        className={styles.comment}
        style={style}
        dangerouslySetInnerHTML={{ __html: props.value.text }}
      />
    );
  }
  return (
    <div
      className={`${styles.comment} ${props.className ? props.className : ""}`}
      style={style}
    >
      {props.value.text}
    </div>
  );
});
