import * as React from 'react';
import { useIntl } from 'react-intl';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';

import styles from './Common.module.css';

interface SelectProps extends ReactSelectProps {
}
const Select: React.FunctionComponent<SelectProps> = React.memo((props) => {
    const intl = useIntl();
    return <ReactSelect placeholder={intl.formatMessage({id: "NPT_SELECT_PLACEHOLDER"})} {...props}>
        {props.children}
    </ReactSelect>
});

export default Select;