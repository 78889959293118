import * as React from 'react';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import ModalView from '../ModalView';
import { Form, Card, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { MODAL_STATUS_OK, MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE } from '../../../constants/modal';
import { PROFILE_EDITOR_BASE_URL } from '../../../constants/subject';
import { SubjectCardInfo, SubjectData, isSubject } from '../../../types/subject';
import { Link } from 'react-router-dom';

interface SubjectInfoModalProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void
}

interface SubjectInfoModalState {
    data?: SubjectData
    isSuperUser?: boolean
    contextPath?: string
}

export default class SubjectInfoModal extends React.Component<SubjectInfoModalProps, SubjectInfoModalState> {

    title = { id: "OBJECTCARD_OBJECT_INFO" };


    constructor(props: SubjectInfoModalProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        const { body } = this.props.modal.options

        if (body && typeof body === 'string') {

            const subjectInfo = JSON.parse(body);
            const { data, isSuperUser, contextPath } = subjectInfo

            this.state = { data, isSuperUser, contextPath }
            console.log(this.state)
        } else {
            this.state = {}
        }
    }

    getFragmenPanel() {
        const { data, isSuperUser } = this.state;
        if (!data || !data.$fragment) {
            return <FormattedMessage
                id="OBJECTCARD_HAVE_NO_FRAGMENT"
                defaultMessage="Object haven't fragment."
                description="Object haven't fragment." />;
        }
        let info = data.$fragment;

        let fragmentRdfIdRow = null;
        let fragmentDescriptionRow = null;
        if (isSuperUser) {
            fragmentRdfIdRow = (<Row>
                <Col md={3}>
                    <b><FormattedMessage
                        id="OBJECTCARD_RDF_ID" />
                        :</b>
                </Col>
                <Col md={9}>
                    {info.$rdfId}
                </Col>
            </Row>)

            fragmentDescriptionRow = (<Row>
                <Col md={3}>
                    <b><FormattedMessage
                        id="OBJECTCARD_FRAGMENT_DESCRIPTION"
                        defaultMessage="Description"
                        description="Description of fragment" />
                        :</b>
                </Col>
                <Col md={9}>
                    {info.$description}
                </Col>
            </Row>)
        }

        return [<Row>
            <Col md={3}>
                <b><FormattedMessage
                    id="OBJECTCARD_FRAGMENT_LABEL"
                    defaultMessage="Label"
                    description="Label of fragment" />
                    :</b>
            </Col>
            <Col md={9}>
                {info.$label}
            </Col>
        </Row>,
            fragmentRdfIdRow,
            fragmentDescriptionRow]
    }


    getSubjectPanel() {
        const { data } = this.state;
        if (!data || (!data.$rdfId && !data.$id)) {
            return <FormattedMessage
                id="OBJECTCARD_OBJECT_NOT_FOUND"
                defaultMessage="Can't find info about object."
                description="Can't find info about object." />;
        }
        let rdf = data.$rdfId;
        if (data.$namespace) {
            rdf = `${data.$namespace}:${rdf}`;
        }
        return (<>
            <Row>
                <Col md={3}>
                    <b><FormattedMessage
                        id="OBJECTCARD_RDF_ID"
                        defaultMessage="RdfId"
                        description="RdfId" />:</b>
                </Col>
                <Col md={9}>
                    {rdf}
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <b><FormattedMessage
                        id="OBJECTCARD_ID"
                        defaultMessage="Id"
                        description="Id" />:</b>
                </Col>
                <Col md={9}>
                    {data.$id}
                </Col>
            </Row>
        </>);
    }

    getClassPanel() {
        const { data, isSuperUser, contextPath } = this.state;

        if (!data || (!data.$classId && !data.$class)) {
            return <FormattedMessage
                id="OBJECTCARD_CLASS_NOT_FOUND"
                defaultMessage="Can't find info about object class."
                description="Can't find info about object class." />;
        }
        let id = data.$classId;
        let label = data.$class;
        const ctx = contextPath ? contextPath : "/"
        let classLabel = <>{label}</>;
        let classIdRow = null;
        if (isSuperUser && id) {
            classLabel = <Link to={ctx + PROFILE_EDITOR_BASE_URL + "/" + id} onClick={()=>this.closeModal(MODAL_STATUS_CLOSE, null)} >{label}</Link>
            classIdRow = (<Row>
                <Col md={3}>
                    <b><FormattedMessage
                        id="OBJECTCARD_CLASS_ID"
                        defaultMessage="Id"
                        description="Id" />:</b>
                </Col>
                <Col md={9}>
                    {id}
                </Col>
            </Row>);
        }

        return <>
            <Row>
                <Col md={3}>
                    <b><FormattedMessage
                        id="OBJECTCARD_CLASS_LABEL"
                        defaultMessage="Label"
                        description="Label of class" />:</b>
                </Col>
                <Col md={9}>
                    {classLabel}
                </Col>
            </Row>
            {classIdRow}
        </>
    }


    renderTemplate(): React.ReactElement {
        const { data } = this.state;

        return <div className="npt-subject-info">
            <Card className="border border-dark" style={{ marginBottom: 5 }}>
                <Card.Header className="bg-dark">
                    <Card.Title className="text-light font-weight-bold">
                        <FormattedMessage
                            id="OBJECTCARD_OBJECT"
                            defaultMessage="Object"
                            description="Object" />
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-secondary">
                    {this.getSubjectPanel()}
                </Card.Body>

            </Card >
            <Card className="border border-dark" style={{ marginBottom: 5 }}>
                <Card.Header className="bg-dark">
                    <Card.Title className="text-light font-weight-bold">
                        <FormattedMessage
                            id="OBJECTCARD_CLASS"
                            defaultMessage="Class"
                            description="Class" />
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-secondary">
                    {this.getClassPanel()}
                </Card.Body>

            </Card>
            <Card className="border border-dark">
                <Card.Header className="bg-dark">
                    <Card.Title className=" text-light font-weight-bold">
                        <FormattedMessage
                            id="OBJECTCARD_FRAGMENT"
                            defaultMessage="Fragment"
                            description="Fragment" />
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-secondary">{this.getFragmenPanel()}</Card.Body>
            </Card>
        </div>
    }

    closeModal(status: ModalStatus, result: any) {
        this.props.closeModal(status, result);
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}