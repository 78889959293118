
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps, StaticContext } from 'react-router';
import { FormattedDate } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

interface CustomToggleProps {
    className?: string
    children: any
    key: string
    badge?:any
    onClick: (e: any) => void
    toggle: () => void
}
//  dropdown-toggle nav-link 
const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: any) => (
    <a
        key={props.key}
        href="#"
        ref={ref}
        className={`  nav-link p-0 d-flex flex-row justify-content-between h-100`}
        onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
            props.toggle();
        }}
    >
        <span>
            <span>{props.children}</span>
            {props.badge || <></>}
        </span>
        
        <FontAwesomeIcon className="mt-1 ml-1" icon={faCaretDown} />
    </a>
));


interface CustomMenuProps {
    children: any
    className?: string
    position?: SubmenuPosition | null
    toggleHeight?: number
    key: string
}

const CustomMenu = React.forwardRef(
    (props: CustomMenuProps, ref: any) => {
        const { className, children, position, key, toggleHeight } = props;
        let positionStyle: any = position ? position : { top: '20px' };
        const ulStyle: React.CSSProperties = {
            maxHeight: '30rem',
            overflowY: 'auto',
            position: 'initial'
        }
        const wrapperStyle: React.CSSProperties = {
            position: 'absolute',
            zIndex: 1000,
            // right: '-8px',
            // top: `${toggleHeight}px` || '2.6rem',
            // top: '2.6rem',
            backgroundColor: 'inherit',
        } 
        return (
            <div key={key} ref={ref} className="wrapper   rounded shadow-sm "
                style={{ ...wrapperStyle, ...positionStyle, width: 'max-content' }}
            >
                <ul style={ulStyle} className={`${className} list-unstyled rounded  px-2 py-1 `}>
                    {React.Children.toArray(children).map((ch, idx) => (
                        <li key={idx} className=" " >{ch}</li>
                    ))}
                </ul>
            </div>
        )

    },
);

//dropdown
interface CustomDropdownProps {
    children: any
    className?: string
    clsName?: string
    key: string
}

const CustomDropdown = React.forwardRef(
    (props: CustomDropdownProps, ref: any) => {
        const { clsName: className, children } = props;

        return (
            <li key={props.key} className={`${className} npt-dropdown-item`} style={{ position: 'inherit' }} ref={ref}  >
                {children}
            </li>
        )

    },
);

interface SubmenuPosition {
    right?: number
    left?: number
    top: number
}

interface NavDropdownProperties extends RouteComponentProps<any, StaticContext, any> {
    children?: any;
    id: string;
    className?: string
    title: React.ReactNode;
    alignRight?: boolean;
    bsPrefix?: string
    to?: string
    exact?: boolean
    as?: React.ForwardRefExoticComponent<any>
    root?: boolean
}

interface NavDropdownState {
    active: boolean
    submenuPosition: SubmenuPosition | null
    toggleHeight?: number
    badge?:any
}

//Class to render links in menu
class NavDropdownRenderer extends React.Component<NavDropdownProperties, NavDropdownState> {
    private parentRef: React.RefObject<HTMLLIElement> = React.createRef();
    unlisten?: () => void;
    state = {
        active: false,
        submenuPosition: null,
        toggleHeight: undefined
    };
    linksMap: {
        [path: string]: boolean
    } = {};

    constructor(props: NavDropdownProperties) {
        super(props)
    }


    registerChildren() {
        for (let child of this.props.children) {
            if (!child || !child.props) {
                continue;
            }
            this.linksMap[child.props.to] = true;
        }
        //Set state after links register
        this.setState({ active: Boolean(this.linksMap[this.props.location.pathname]) });
    }

    onLocationChange(path: string) {
        //Set state to force rendering
        this.setState({ active: this.linksMap[path] });
    }

    componentDidMount() {
        this.registerChildren();
        const history = this.props.history;
        const onLocationChange = this.onLocationChange.bind(this);
        this.unlisten = history.listen((location, action) => {
            onLocationChange(location.pathname)
        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }


    toggleDropdown = () => {
        const pr = this.parentRef;
        const { alignRight } = this.props;
        let pos: SubmenuPosition | null = null;
        let toggleHeight: number | undefined;
        if (pr && pr.current) {
            toggleHeight = pr.current.offsetHeight
            if (pr.current.offsetParent) {
                const parent = pr.current.offsetParent.children[0];
                const scroll = parent && parent.scrollTop
                const prElem = pr.current;
                const top = scroll ? prElem.offsetTop - scroll : prElem.offsetTop;
                const x = prElem.offsetWidth;
                pos = alignRight ? { right: x, top: top + toggleHeight + 5 } : { left: 0, top: top + toggleHeight + 5 };
            }
        }
        this.setState({ submenuPosition: pos, toggleHeight });
    }
    render() {
        const { title, children, className, id, to, root } = this.props;
        const { active, submenuPosition: pos, toggleHeight } = this.state;


        const ToggleElement = this.props.as || CustomToggle
        let activeClass = ''
        if (active) {
            activeClass = root ? 'npt-active-root-dropdown' : 'npt-active-child-dropdown'
        }
        const dpdClassName = `${className || "dropdown-item"} ${activeClass}`;
        return (
            <Dropdown key={`${id}_drp`} clsName={dpdClassName} ref={this.parentRef as any} as={CustomDropdown}>
                <Dropdown.Toggle active key={`${id}_toggle`} variant="link" toggle={() => this.toggleDropdown()} as={ToggleElement} id="dropdown-custom-components">
                    {title}
                </Dropdown.Toggle>
                <Dropdown.Menu toggleHeight={toggleHeight} className="npt-dropdown-menu" position={pos} key={`${id}_menu`} as={CustomMenu} >
                    {children}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export const CustomNavDropdown = withRouter(NavDropdownRenderer);



