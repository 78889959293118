import React from "react";
import { CSSTransition } from "react-transition-group";
import { ENTER_TIMEOUT, EXIT_TIMEOUT } from "./AlertContainer";
import styles from './TransitionStyles.module.css';

const AlertTransition = (props: any) => {
    const timeout = { enter: ENTER_TIMEOUT, exit: EXIT_TIMEOUT };
    let transitionClasses = {
        enter: styles.enter,
        enterActive: styles.enterActive,
        exit: styles.exit,
        exitActive: styles.exitActive
    }
    return <CSSTransition timeout={timeout} classNames={transitionClasses} {...props} />;
};
export default AlertTransition