import * as d3 from 'd3';
import { linkHorizontal } from 'd3-shape';
import { fontWidth, levelDepth, margin, nodeSize } from "../constants/sitemap";

function getNodeX(node: any) {
    return node.children ? -10 : 10;
}

export function prepareJson(json: any, counter: { id: number } = { id: 0 }) {
    json.id = ++counter.id;
    for (var i = 0; i < json.name.length; ++i) {
        json.name[i] = { text: json.name[i], x: getNodeX(json) };
    }
    if (json.children) {
        for (var i = 0; i < json.children.length; ++i) {
            prepareJson(json.children[i], counter);
        }
    }
}

export function formatSitemap(sitemap: any) {
    sitemap.params = getTreeParams(sitemap, 0);
    sitemap.sizes = calculateSizes(sitemap.params);
    preventLinksOverflow(sitemap, null, null);
}

function getTreeParams(tree: any, depth: any) {
    if (typeof depth == "undefined") {
        depth = 0;
    }
    var links = 0;
    var maxDepth = depth;
    var maxLinkLength = 0;
    for (var i = 0; i < tree.children.length; ++i) {
        var child = tree.children[i];
        if (child.href) {
            links++;
            if (maxLinkLength < child.href.length) {
                maxLinkLength = child.href.length;
            }
            continue;
        }
        if (!child.children) {
            continue;
        }
        var childParams = getTreeParams(child, depth + 1);
        links += childParams.links;
        if (maxDepth < childParams.depth) {
            maxDepth = childParams.depth;
        }
        if (maxLinkLength < childParams.maxLinkLength) {
            maxLinkLength = childParams.maxLinkLength;
        }
    }
    return {
        links: links,
        depth: maxDepth,
        maxLinkLength: maxLinkLength
    }
}

function calculateSizes(treeParams: any) {
    var minWidth = 100;
    var minHeight = 100;
    var width = treeParams.depth * levelDepth + treeParams.maxLinkLength * fontWidth;
    var height = treeParams.links * nodeSize;
    if (width < minWidth) {
        width = minWidth;
        if (height > minHeight) {
            width -= 20;
        }
    }
    if (height < minHeight) {
        height = minHeight;
        if (width > minWidth) {
            height -= 20;
        }
    }
    return {
        width: width,
        height: height
    }
}

function preventLinksOverflow(node: any, currentDepth: any, treeParams: any) {
    if (typeof currentDepth == "undefined") {
        currentDepth = 0;
    }
    if (!treeParams) {
        treeParams = node.params;
    }
    if (!node.href) {
        for (var i = 0; i < node.children.length; ++i) {
            preventLinksOverflow(node.children[i], currentDepth + 1, treeParams);
        }
        return;
    }
    var linkLength = getFullName(node.name).length * fontWidth;
    var depth = currentDepth;
    var n = node;
    while (depth <= treeParams.depth && linkLength > levelDepth) {
        var hiddenNode = {
            name: [],
            isHidden: true
        }
        n.children = [hiddenNode];
        n = hiddenNode;
        ++depth;
        linkLength -= levelDepth;
    }
}

function getFullName(lines: any) {
    var fullName = "";
    for (var i = 0; i < lines.length; ++i) {
        if (i != 0) {
            fullName += " ";
        }
        fullName += lines[i].text.toLowerCase();
    }
    return fullName;
}

export function getTreeRoot(json: any) {

    const root: any = d3.hierarchy(json);

    root.sizes = json.sizes;
    root.x0 = json.sizes.height / 2 + margin.top;
    root.y0 = margin.left;

    root.descendants().forEach((d: any, i: any) => {
        d.id = d.data.id;
    });

    return root;
}

export const diagonal: any = linkHorizontal()
    .x((d: any) => d.y)
    .y((d: any) => d.x);


export function isLinkDisplayed(link: any, filterString: string) {
    var fullName = getFullName(link.name);
    return fullName.indexOf(filterString) != -1;
}