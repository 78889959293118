/* Links */
export const UPLOAD_FILE_URL = "/rest/file/upload";
export const DONWLOAD_FILE_URL = "/rest/file/download";

export const UPLOAD_SETPOINTS_URL = "/rest/rpasetpoints/setpoints";
export const UPLOAD_SIGNALS_URL = "/rest/rpasetpoints/signals";

/* Constants */
export const FILE_SELECTION = 0;
export const FILE_UPLOADING = 1;
export const READY = 2;
export const ERROR = 3;

export const COMMON_SETPOINTS = "commonSetpoints";
export const COMMON_SIGNALS = "commonSignals";
export const SETPOINTS = "setpoints";
export const SIGNALS = "signals";

export const TAB_SETPOINTS = "setpoints";
export const TAB_ANALOG = "analog";
export const TAB_DISCRETE = "discrete";

export const SOURCE_TREE = "source";
export const TARGET_TREE = "target";

export const SOURCE = {
    [TAB_SETPOINTS]: "setpoints",
    [TAB_ANALOG]: "analogSignals",
    [TAB_DISCRETE]: "discreteSignals"
};
export const TARGET = {
    [TAB_SETPOINTS]: "commonSetpoints",
    [TAB_ANALOG]: "commonAnalogSignals",
    [TAB_DISCRETE]: "commonDiscreteSignals"
};

export const reverse = {
    [SOURCE[TAB_SETPOINTS]]: TARGET[TAB_SETPOINTS],
    [TARGET[TAB_SETPOINTS]]: SOURCE[TAB_SETPOINTS],
    [SOURCE[TAB_ANALOG]]: TARGET[TAB_ANALOG],
    [TARGET[TAB_ANALOG]]: SOURCE[TAB_ANALOG],
    [SOURCE[TAB_DISCRETE]]: TARGET[TAB_DISCRETE],
    [TARGET[TAB_DISCRETE]]: SOURCE[TAB_DISCRETE]
}