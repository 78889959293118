import { MENU_STATE_KEY } from "../constants/menu";
import { MenuState } from "../types/menu";

export function getMenuDefaultState(): MenuState {
    const menuState = {
        minimized: {},
        closed: {}
    }
    try {
        const storageState = localStorage.getItem(MENU_STATE_KEY);
        if (storageState) {
            const parsedState = JSON.parse(storageState);
            if (typeof parsedState.minimized === "object") {
                menuState.minimized = parsedState.minimized;
            }
            if (typeof parsedState.closed === "object") {
                menuState.closed = parsedState.closed;
            }
        }
    } catch { }
    return menuState;
}

export function storeMenuState(state: MenuState) {
    localStorage.setItem(MENU_STATE_KEY, JSON.stringify(state));
}