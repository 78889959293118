
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { createRef } from 'react';
import styles from './Divider.module.css';

function preventEvent(reactEvent: any) {
    reactEvent.stopPropagation();
    reactEvent.preventDefault();
}
interface ArrowNodes {
    left: HTMLDivElement | null
    right: HTMLDivElement | null
}

export interface SplitterBarProps {
    orientation?: 'horizontal' | 'vertical'
    order?: number
    disabledDrag?: boolean
    isEnabledLeft?: boolean
    isEnabledRight?: boolean
    onClickLeft?: () => void
    onClickRight?: () => void
}
interface SplitterBarStates {
    arrows: HTMLDivElement | null
    lockArrow: boolean
}

export class SplitterBar extends React.Component<SplitterBarProps, SplitterBarStates>  {
    private grabberRef: React.RefObject<HTMLDivElement> = createRef()
    private rightArrowRef: React.RefObject<HTMLDivElement> = createRef()
    private leftArrowRef: React.RefObject<HTMLDivElement> = createRef()
    constructor(props: SplitterBarProps) {
        super(props)
        this.state = { arrows: null, lockArrow: false }
    }
    lockArrowHandler = (e: any) => {
        const isLeftMouseButtonPressed = this.detectLeftButton(e)
        const { arrows } = this.state
        let newArrows: any = arrows
        if (isLeftMouseButtonPressed && arrows) {
            newArrows = null
            this.removeArrow()
        }
        this.setState({ lockArrow: isLeftMouseButtonPressed, arrows: newArrows })
    }
    keyDownHandler = (e: any) => {
        if (e.keyCode === 123 && this.state.arrows) {
            this.removeArrow()
        }
    }
    componentWillMount() {
        document.addEventListener('mousemove', this.mouseMoveHandler)
        document.addEventListener('keydown', this.keyDownHandler)
        document.addEventListener('mousedown', this.lockArrowHandler)
        document.addEventListener('mouseup', this.lockArrowHandler)
    }
    componentWillUnmount() {
        document.removeEventListener('mousemove', this.mouseMoveHandler)
        document.removeEventListener('mousedown', this.lockArrowHandler)
        document.removeEventListener('keydown', this.keyDownHandler)
        document.removeEventListener('mouseup', this.lockArrowHandler)
        this.removeArrow()
    }
    grabHandler = (reactEvent: any) => {
        this.removeArrow()
        if (!this.props.disabledDrag) {
            return;
        }
        preventEvent(reactEvent);

    }
    rightClickHandler = (e: any) => {
        const { order, onClickRight } = this.props
        // order && ctx?.setClickData({ order, direction: 'down' })
        onClickRight && onClickRight()
        this.removeArrow()
    }
    leftClickHandler = (e: any) => {
        const { order, onClickLeft } = this.props
        // order && ctx?.setClickData({ order, direction: 'up' })
        onClickLeft && onClickLeft()
        this.removeArrow()
    }
    mouseMoveHandler = (e: any) => {
        const { arrows } = this.state
        if (arrows && (!e.target.closest('#arrow-wrapper'))) {
            this.removeArrow()
        }
    }

    removeArrow = () => {
        const { arrows } = this.state
        if (arrows) {
            arrows?.remove()
            this.setState({ arrows: null })
        }
    }

    detectLeftButton = (evt: any) => {
        evt = evt || window.event;
        if ("buttons" in evt) {
            return evt.buttons == 1;
        }
        const button = evt.which || evt.button;
        return button == 1;
    }
    getChevron = (type: 'left' | 'right' = 'left') => {
        const { orientation } = this.props
        if (orientation === 'horizontal') {
            return type === 'left' ? 'up' : 'down'
        } else {
            return type

        }
    }
    createArrow = (type: 'left' | 'right' = 'left', className: string, rect: any, click: (e: any) => void) => {

        const divLeftArrow = document.createElement('div');
        divLeftArrow.id = `arrow`
        divLeftArrow.className = ` p-1 shadow-lg ${styles.arrow} ${styles.leftArrow}  ${className}`
        divLeftArrow.innerHTML = `<i class="fa fa-chevron-${this.getChevron(type)}" aria-hidden="true"></i>`
        divLeftArrow.onclick = click
        divLeftArrow.onmousedown = preventEvent
        // divLeftArrow.onmouseleave = this.removeArrow
        divLeftArrow.style.top = `${rect.y}px`
        divLeftArrow.style.left = `${rect.x}px`
        divLeftArrow.style.width = `${rect.width}px`
        divLeftArrow.style.height = `${rect.height}px`
        divLeftArrow.style.opacity = '1'
        divLeftArrow.style.zIndex = '10'
        divLeftArrow.style.position = 'fixed'
        return divLeftArrow
    }
    getArrowClassName = (type: 'left' | 'right') => {
        const { isEnabledLeft, isEnabledRight, onClickLeft, onClickRight, orientation, order } = this.props;
        if (type === 'left') {
            const leftDisabled = typeof isEnabledLeft === 'undefined' || isEnabledLeft ? '' : styles.disabled
            const leftBorder = orientation === 'horizontal' ? styles.leftArrowHorizontal : styles.leftArrowVertical
            return `${leftDisabled} ${leftBorder}`
        } else {
            const rightDisabled = typeof isEnabledRight === 'undefined' || isEnabledRight ? '' : styles.disabled
            const rightBorder = orientation === 'horizontal' ? styles.rightArrowHorizontal : styles.rightArrowVertical
            return `${rightDisabled} ${rightBorder}`
        }
    }
    createArrowContainer = (rect: any) => {
        const { orientation } = this.props

        const width = orientation === 'horizontal' ? rect.width : rect.width * 2
        const height = orientation === 'horizontal' ? rect.height * 2 : rect.height

        const arrowContainer = document.createElement('div');
        arrowContainer.id = `arrow-wrapper`
        arrowContainer.className = `position-absolute`
        arrowContainer.style.top = `${rect.y}px`
        arrowContainer.style.zIndex = '100000'

        arrowContainer.style.left = `${rect.x}px`
        arrowContainer.style.width = `${width}px`
        arrowContainer.style.height = `${height}px`
        arrowContainer.onmouseleave = this.removeArrow
        return arrowContainer
    }
    enterGrabber = (e: any) => {
        const grabberCurrent: any = this.grabberRef?.current
        const lArrowCurrent: any = this.leftArrowRef?.current
        const rArrowCurrent: any = this.rightArrowRef?.current
        const { arrows, lockArrow } = this.state

        if (!arrows && !lockArrow && grabberCurrent && lArrowCurrent && rArrowCurrent) {

            const splitter = grabberCurrent?.parentElement

            const lArrowRect = lArrowCurrent.getBoundingClientRect()
            const rArrowRect = rArrowCurrent.getBoundingClientRect()


            const leftArrowClassName = this.getArrowClassName('left')
            const rightArrowClassName = this.getArrowClassName('right')

            const divLeftArrow = this.createArrow('left', leftArrowClassName, lArrowRect, this.leftClickHandler)

            const divRightArrow = this.createArrow('right', rightArrowClassName, rArrowRect, this.rightClickHandler)

            const arrowContainer = this.createArrowContainer(lArrowRect);
            // const arrowContainer =  document.createElement('div');
            // arrowContainer.id = `arrow-wrapper`
            // arrowContainer.className = `position-absolute`
            // arrowContainer.style.top = `${lArrowRect.y}px`
            // arrowContainer.style.left = `${lArrowRect.x}px`
            // arrowContainer.style.width = `${lArrowRect.width * 2}px`
            // arrowContainer.style.height = `${lArrowRect.height}px`
            // arrowContainer.onmouseleave = this.removeArrow

            arrowContainer.appendChild(divLeftArrow)
            arrowContainer.appendChild(divRightArrow)

            document.body.appendChild(arrowContainer)
            this.setState({
                arrows: arrowContainer
            })
        }
    }
    render() {
        const { isEnabledLeft, isEnabledRight, onClickLeft, onClickRight, orientation, order } = this.props;
        const leftDisabledStyle = typeof isEnabledLeft === 'undefined' || isEnabledLeft ? '' : styles.disabled
        const rightDisabledStyle = typeof isEnabledRight === 'undefined' || isEnabledRight ? '' : styles.disabled
        return (
            <div ref={this.grabberRef} className={`${styles.grabber} ${orientation || 'horizontal'}`}>
                <div id="arrow-wrapper" className={`${styles.wrapper}`} onMouseEnter={this.enterGrabber}  >
                    <div ref={this.leftArrowRef} style={{ opacity: 0, zIndex: 0 }} className={`p-1 shadow-lg ${styles.arrow} ${styles.leftArrow} ${leftDisabledStyle}`}>
                        <FontAwesomeIcon className="icon" icon={faChevronLeft} />
                    </div>
                    <div className={`${styles.bar}`} onMouseDown={this.grabHandler}></div>
                    <div ref={this.rightArrowRef} style={{ opacity: 0, zIndex: 0 }} className={`p-1 shadow-lg ${styles.arrow} ${styles.rightArrow} ${rightDisabledStyle}`}>
                        <FontAwesomeIcon className="icon" icon={faChevronRight} />
                    </div>
                </div>
            </div>
        )
    }

}