import { ThunkDispatch, ThunkAction } from "redux-thunk";
import shortid from "shortid";

import { ApplicationState, ApplicationAction } from "../types";

import {
  SendSourceTreeInfo,
  SourceEditorAction,
  SendSourceTreeError,
  SendSourceTreeLoading,
  SourceEditorType,
  SendSourceTreeToggle,
  SendSourceTreeActive,
  SourceEditorCodeData,
  SendSourceCodeInfo,
  SendSourceCodeError,
  SendSourceCodeLoading,
  isSourceEditorCodeData,
  SendSourceCodeTextChanges,
  SendSourceCodeChangeTemplate,
  SendSourceCodeEditCancel,
  Template,
  SendSourceCodeApplyTemplate,
  HierarchicalTemplateTree,
  TemplateTreeNode,
  HierarchicalTemplateTreeNode,
  TemplateTree,
  SendSourceCodeChangeTemplateNode,
  SourceCodeDeleteInfo,
  SendSourceCodeCancelTemplate,
  SendSourceCodeToggleMetaData,
  SourceMetaData,
  SendSourceCodeMetaDataChanges,
  SendSourceCodeInitEdit,
  SourceEditorState,
  SendSourceTreeCreateDirectory,
  SourceCodeCopyMoveInfo,
  SendSourceTreeToggleInplace,
  SourceTreeNode,
  SendSourceTreeSearchFilter,
  SendSourceTreeSearchFilterLoading,
  SourceHistoryInfo,
} from "../types/sourceeditor";

import { FetchError, isFetchError } from "../types/error";
import { ServerError, joinParams } from "./utils";
import {
  SEND_SOURCE_TREE,
  SEND_SOURCE_TREE_ERROR,
  SEND_SOURCE_TREE_LOADING,
  SEND_SOURCE_TREE_TOGGLE,
  SEND_SOURCE_TREE_ACTIVE,
  SEND_SOURCE_CODE,
  SEND_SOURCE_CODE_ERROR,
  SEND_SOURCE_CODE_LOADING,
  SEND_SOURCE_CODE_TEXT_CHANGES,
  SEND_SOURCE_CODE_CHANGE_TEMPLATE,
  SEND_SOURCE_CODE_EDIT_CANCEL,
  SEND_SOURCE_CODE_APPLY_TEMPLATE,
  SEND_SOURCE_CODE_CHANGE_TEMPLATE_NODE,
  SEND_SOURCE_CODE_CANCEL_TEMPLATE,
  SEND_SOURCE_CODE_TOGGLE_META_DATA,
  SEND_SOURCE_CODE_METADATA_CHANGES,
  SEND_SOURCE_CODE_INIT_EDIT,
  SEND_SOURCE_TREE_CREATE_DIRECTORY,
  SEND_SOURCE_CODE_TREE_TOGGLE_INPLACE,
  SEND_SOURCE_TREE_SEARCH_FILTER,
  SEND_SOURCE_TREE_SEARCH_FILTER_LOADING,
} from "../constants/sourceeditor";

import { ALERT_LEVEL_DANGER, ALERT_LEVEL_SUCCESS } from "../constants/alert";
import { addAlert, dispatchError, dispatchSuccess } from "./alert";
import { AnyAction } from "redux";
import { openModal } from "./modal";
import { copyTextToClipboard } from "../services/clipboard";
import { getSearchData } from "../services/location";

async function fetchSourceTreeInfoImpl(
  sourceEditorType: SourceEditorType
): Promise<string[]> {
  const response = await fetch(`/rest/${sourceEditorType}/list`);

  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return await response.json();
}

function searchSourceTreeInfoImpl(
  sourceEditorType: SourceEditorType,
  search: string
): Promise<string[]> {
  return fetch(
    `/rest/${sourceEditorType}/list?search=${encodeURIComponent(search)}`
  )
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function fetchSourceCodeImpl(
  sourceEditorType: SourceEditorType,
  path: string
): Promise<SourceEditorCodeData> {
  return fetch(`/rest/${sourceEditorType}/entity${path}`)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function saveSourceCodeImpl(
  sourceEditorType: SourceEditorType,
  path: string,
  code: SourceEditorCodeData
): Promise<SourceEditorCodeData> {
  const { type, ...codeData } = code;
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(codeData),
  };
  return fetch(`/rest/${sourceEditorType}/entity${path}`, opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function deleteSourceCodeImpl(
  sourceEditorType: SourceEditorType,
  path: string,
  directory: boolean
): Promise<SourceCodeDeleteInfo> {
  const opt: RequestInit = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  };
  return fetch(
    `/rest/${sourceEditorType}/entity${path}${directory ? "/" : ""}`,
    opt
  )
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function moveSourceCodeImpl(
  sourceEditorType: SourceEditorType,
  source: string,
  target: string,
  directory: boolean
): Promise<SourceCodeCopyMoveInfo> {
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: joinParams({
      source: source + (directory ? "/" : ""),
      target,
    }),
  };
  return fetch(`/rest/${sourceEditorType}/move`, opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function copySourceCodeImpl(
  sourceEditorType: SourceEditorType,
  source: string,
  target: string,
  node: SourceTreeNode
): Promise<SourceCodeCopyMoveInfo> {
  const directory = node.directory;
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: joinParams({
      source: source + (directory ? "/" : ""),
      target,
    }),
  };
  return fetch(`/rest/${sourceEditorType}/copy`, opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function fetchSourceHistoryImpl(
  sourceEditorType: SourceEditorType,
  path: string
): Promise<SourceHistoryInfo[]> {
  return fetch(`/rest/${sourceEditorType}/history${path}`)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function restoreSourceCodeImpl(
  sourceEditorType: SourceEditorType,
  id: number,
  path: string
): Promise<SourceHistoryInfo> {
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  };
  return fetch(`/rest/${sourceEditorType}/restore?id=${id}&path=${path}`, opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

export function sendSourceTreeInfo(
  sourceEditorType: SourceEditorType,
  list: string[]
): SendSourceTreeInfo {
  return {
    type: SEND_SOURCE_TREE,
    sourceEditorType,
    payload: {
      list,
    },
  };
}

export function sendSourceTreeError(
  sourceEditorType: SourceEditorType,
  error: FetchError
): SendSourceTreeError {
  return {
    type: SEND_SOURCE_TREE_ERROR,
    sourceEditorType,
    payload: {
      error,
    },
  };
}

export function sendSourceTreeLoading(
  sourceEditorType: SourceEditorType
): SendSourceTreeLoading {
  return {
    type: SEND_SOURCE_TREE_LOADING,
    sourceEditorType,
  };
}

export function sendSourceTreeToggle(
  sourceEditorType: SourceEditorType,
  nodeId: string,
  expanded: boolean
): SendSourceTreeToggle {
  return {
    type: SEND_SOURCE_TREE_TOGGLE,
    sourceEditorType,
    payload: {
      nodeId,
      expanded,
    },
  };
}

export function sendSourceTreeActive(
  sourceEditorType: SourceEditorType,
  nodeId: string
): SendSourceTreeActive {
  return {
    type: SEND_SOURCE_TREE_ACTIVE,
    sourceEditorType,
    payload: {
      nodeId,
    },
  };
}

export function sendSourceTreeSearchFilterLoading(
  sourceEditorType: SourceEditorType
): SendSourceTreeSearchFilterLoading {
  return {
    type: SEND_SOURCE_TREE_SEARCH_FILTER_LOADING,
    sourceEditorType,
  };
}

export function sendSourceTreeSearchFilter(
  sourceEditorType: SourceEditorType,
  filterValue: string,
  filter: string[] | null
): SendSourceTreeSearchFilter {
  return {
    type: SEND_SOURCE_TREE_SEARCH_FILTER,
    sourceEditorType,
    payload: {
      filterValue,
      filter,
    },
  };
}

export function sendSourceTreeToggleInplace(
  sourceEditorType: SourceEditorType,
  nodeId: string,
  inplace: boolean
): SendSourceTreeToggleInplace {
  return {
    type: SEND_SOURCE_CODE_TREE_TOGGLE_INPLACE,
    sourceEditorType,
    payload: {
      nodeId,
      inplace,
    },
  };
}

export function sendSourceTreeCreateDirectory(
  sourceEditorType: SourceEditorType,
  path: string
): SendSourceTreeCreateDirectory {
  return {
    type: SEND_SOURCE_TREE_CREATE_DIRECTORY,
    sourceEditorType,
    payload: {
      path,
    },
  };
}

export function sendSourceCodeInfo(
  sourceEditorType: SourceEditorType,
  path: string,
  code: SourceEditorCodeData
): SendSourceCodeInfo {
  return {
    type: SEND_SOURCE_CODE,
    sourceEditorType,
    payload: {
      path,
      code,
    },
  };
}

export function sendSourceCodeError(
  sourceEditorType: SourceEditorType,
  path: string,
  error: FetchError
): SendSourceCodeError {
  return {
    type: SEND_SOURCE_CODE_ERROR,
    sourceEditorType,
    payload: {
      path,
      error,
    },
  };
}

export function sendSourceCodeLoading(
  sourceEditorType: SourceEditorType,
  path: string,
  loading?: boolean
): SendSourceCodeLoading {
  return {
    type: SEND_SOURCE_CODE_LOADING,
    sourceEditorType,
    payload: {
      path,
      loading,
    },
  };
}

export function sendSourceCodeInitEdit(
  sourceEditorType: SourceEditorType,
  path: string
): SendSourceCodeInitEdit {
  return {
    type: SEND_SOURCE_CODE_INIT_EDIT,
    sourceEditorType,
    payload: {
      path,
    },
  };
}

export function sendSourceCodeTextChanges(
  sourceEditorType: SourceEditorType,
  path: string,
  code: string
): SendSourceCodeTextChanges {
  return {
    type: SEND_SOURCE_CODE_TEXT_CHANGES,
    sourceEditorType,
    payload: {
      path,
      code,
    },
  };
}

export function sendSourceCodeMetaDataChanges(
  sourceEditorType: SourceEditorType,
  path: string,
  metadata: SourceMetaData
): SendSourceCodeMetaDataChanges {
  return {
    type: SEND_SOURCE_CODE_METADATA_CHANGES,
    sourceEditorType,
    payload: {
      path,
      metadata,
    },
  };
}

export function sendSourceCodeEditCancel(
  sourceEditorType: SourceEditorType,
  path: string
): SendSourceCodeEditCancel {
  return {
    type: SEND_SOURCE_CODE_EDIT_CANCEL,
    sourceEditorType,
    payload: {
      path,
    },
  };
}

export function sendSourceCodeChangeTemplate(
  sourceEditorType: SourceEditorType,
  template: Template
): SendSourceCodeChangeTemplate {
  return {
    type: SEND_SOURCE_CODE_CHANGE_TEMPLATE,
    sourceEditorType,
    payload: {
      template,
    },
  };
}

export function sendSourceCodeCancelTemplate(
  sourceEditorType: SourceEditorType
): SendSourceCodeCancelTemplate {
  return {
    type: SEND_SOURCE_CODE_CANCEL_TEMPLATE,
    sourceEditorType,
  };
}

export function sendSourceCodeApplyTemplate(
  sourceEditorType: SourceEditorType,
  path: string
): SendSourceCodeApplyTemplate {
  return {
    type: SEND_SOURCE_CODE_APPLY_TEMPLATE,
    sourceEditorType,
    payload: {
      path,
    },
  };
}

export function sendSourceCodeToggleMetaData(
  sourceEditorType: SourceEditorType
): SendSourceCodeToggleMetaData {
  return {
    type: SEND_SOURCE_CODE_TOGGLE_META_DATA,
    sourceEditorType,
  };
}

export function sendSourceCodeChangeTemplateNode(
  sourceEditorType: SourceEditorType,
  node: TemplateTreeNode
): SendSourceCodeChangeTemplateNode {
  return {
    type: SEND_SOURCE_CODE_CHANGE_TEMPLATE_NODE,
    sourceEditorType,
    payload: {
      node,
    },
  };
}

export function fetchSourceTreeInfo(
  sourceEditorType: SourceEditorType
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    try {
      const s = getState();
      const editorState = s[sourceEditorType];
      if (editorState && editorState.treeLoading) {
        return;
      }
      dispatch(sendSourceTreeLoading(sourceEditorType));
      dispatch(
        sendSourceTreeInfo(
          sourceEditorType,
          await fetchSourceTreeInfoImpl(sourceEditorType)
        )
      );
    } catch (e: any) {
      console.log("333");

      let message = "SOURCEEDITOR_UPLOAD_TREE";

      dispatchError(message, e, dispatch);
      if (e instanceof ServerError) {
        dispatch(
          sendSourceTreeError(sourceEditorType, {
            code: e.code,
            message: e.message,
          })
        );
      } else if (typeof e.message == "string") {
        dispatch(
          sendSourceTreeError(sourceEditorType, {
            code: -1,
            message: e.message,
          })
        );
      } else {
        dispatch(
          sendSourceTreeError(sourceEditorType, {
            code: -1,
            message: "Unknown error",
          })
        );
      }
    }
  };
}

export function activate(
  sourceEditorType: SourceEditorType,
  id: string
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const editorState = getState()[sourceEditorType];
    if (!editorState) {
      return;
    }
    const node = editorState.nodeById && editorState.nodeById[id];
    if (!node) {
      return;
    }

    //Activate tree node
    dispatch(sendSourceTreeActive(sourceEditorType, id));
    const path = node.path;
    if (path) {
      //Refetch code if doesn't exist and is not editing
      updateUrl(id, sourceEditorType);
      try {
        if (editorState.codeLoading && editorState.codeLoading[path]) {
          return;
        }
        if (editorState.editCode && editorState.editCode[path]) {
          return;
        }
        dispatch(sendSourceCodeLoading(sourceEditorType, path));
        dispatch(
          sendSourceCodeInfo(
            sourceEditorType,
            path,
            await fetchSourceCodeImpl(sourceEditorType, path)
          )
        );
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch(
            sendSourceCodeError(sourceEditorType, path, {
              code: e.code,
              message: e.message,
            })
          );
        } else if (typeof e.message == "string") {
          dispatch(
            sendSourceCodeError(sourceEditorType, path, {
              code: -1,
              message: e.message,
            })
          );
        } else {
          dispatch(
            sendSourceCodeError(sourceEditorType, path, {
              code: -1,
              message: "Unknown error",
            })
          );
        }
      }
    }
  };
}

function checkExistingSource(
  s: ApplicationState,
  sourceEditorType: SourceEditorType
): SourceEditorCodeData | null {
  //Check editor state
  const editorState = s[sourceEditorType];
  if (!editorState) {
    return null;
  }
  //Check is source code is selected
  const path = editorState.active;
  if (!path) {
    return null;
  }
  //Check if source code is not loading
  if (editorState.codeLoading && editorState.codeLoading[path]) {
    return null;
  }
  //Check if source code is available
  const code = editorState.code && editorState.code[path];
  if (!isSourceEditorCodeData(code)) {
    return null;
  }
  return code;
}

function checkNewSource(
  s: ApplicationState,
  sourceEditorType: SourceEditorType
): SourceEditorCodeData | null {
  //Check editor state
  const editorState = s[sourceEditorType];
  if (!editorState) {
    return null;
  }
  //Check is source code is selected
  const path = editorState.active;
  if (!path) {
    return null;
  }
  //Check if source code is available
  const editCode = editorState.editCode && editorState.editCode[path];
  if (!isSourceEditorCodeData(editCode)) {
    return null;
  }
  return editCode;
}

export function edit(
  sourceEditorType: SourceEditorType
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const code = checkExistingSource(getState(), sourceEditorType);
    if (code) {
      dispatch(sendSourceCodeInitEdit(sourceEditorType, code.path));
    }
  };
}

export function search(
  sourceEditorType: SourceEditorType,
  search: string
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    if (!search) {
      dispatch(sendSourceTreeSearchFilter(sourceEditorType, "", null));
      return;
    }
    const s = getState();
    const editor = s[sourceEditorType];
    if (!editor) {
      return;
    }
    if (editor.filterLoading || editor.filterValue == search) {
      return;
    }
    try {
      dispatch(sendSourceTreeSearchFilterLoading(sourceEditorType));
      dispatch(
        sendSourceTreeSearchFilter(
          sourceEditorType,
          search,
          await searchSourceTreeInfoImpl(sourceEditorType, search)
        )
      );
    } catch (e) {
      //TODO: dispatch alerts. Now display that nothinhg has been found
      dispatch(sendSourceTreeSearchFilter(sourceEditorType, search, []));
    }
  };
}

export function cancel(
  sourceEditorType: SourceEditorType
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const code =
      checkExistingSource(s, sourceEditorType) ||
      checkNewSource(s, sourceEditorType);
    if (code) {
      dispatch(sendSourceCodeEditCancel(sourceEditorType, code.path));
    }
  };
}
export function copyToClipboard(
  sourceEditorType: SourceEditorType,
  path: string
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const nodes = s && s[sourceEditorType];
    const code = nodes && nodes.nodeById && nodes.code;
    const codeStr = code && code[path].code;
    if (codeStr === "") {
      dispatchSuccess("SRCEDITOR_COPY_TO_CLIPBOARD_EMPTY", dispatch);
      return;
    }
    const copy = codeStr && copyTextToClipboard(codeStr);
    if (copy) {
      dispatchSuccess("SRCEDITOR_COPY_TO_CLIPBOARD_SUCCESS", dispatch);
    } else {
      dispatchError(
        "SRCEDITOR_COPY_TO_CLIPBOARD_ERROR",
        "fail to copy node reference",
        dispatch
      );
    }
  };
}

export function save(
  sourceEditorType: SourceEditorType
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const code =
      checkExistingSource(s, sourceEditorType) ||
      checkNewSource(s, sourceEditorType);
    const source = s[sourceEditorType];
    if (code && source && source.active) {
      const editor = s[sourceEditorType];
      // const path = code.path;
      const path = source.active;
      const changedCode = editor && editor.editCode && editor.editCode[path];
      try {
        if (changedCode) {
          dispatch(sendSourceCodeLoading(sourceEditorType, path));
          dispatch(
            sendSourceCodeInfo(
              sourceEditorType,
              path,
              await saveSourceCodeImpl(sourceEditorType, path, changedCode)
            )
          );
          if (changedCode.path != path) {
            //path have been changed
            //refetch source tree
            dispatch(
              sendSourceTreeInfo(
                sourceEditorType,
                await fetchSourceTreeInfoImpl(sourceEditorType)
              )
            );
          }
        }
      } catch (e: any) {
        dispatch(sendSourceCodeLoading(sourceEditorType, path, false));

        if (e instanceof ServerError) {
          dispatchError("SRC_EDITOR_CODE_SAVE_ERROR_WITH_CODE", e, dispatch, {
            path,
            code: e.code,
            message: e.message,
          });
        } else if (typeof e.message == "string") {
          dispatchError("SRC_EDITOR_CODE_SAVE_ERROR_WITH_CODE", e, dispatch, {
            path,
            code: -1,
            message: e.message,
          });
        } else {
          dispatchError("SRC_EDITOR_CODE_SAVE_ERROR", e, dispatch, {
            path,
          });
        }
      }
    }
  };
}

function updateUrl(url: string, sourceEditorType: SourceEditorType) {
  /** TODO: replace with react-router change path function */
  const newUrl = `/developer/${sourceEditorType}${url}${window.location.search}`;
  window.history.pushState({ search: getSearchData() }, "", newUrl);
}

async function moveSourceCodeCommon(
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
  sourceEditorType: SourceEditorType,
  source: string,
  target: string,
  directory: boolean,
  isRename: boolean,
  active?: string
) {
  try {
    await moveSourceCodeImpl(sourceEditorType, source, target, directory);
    dispatch(
      addAlert(ALERT_LEVEL_SUCCESS, {
        id: isRename
          ? "SRC_EDITOR_CODE_RENAME_SUCCESS"
          : "SRC_EDITOR_CODE_MOVE_SUCCESS",
        values: { source, target },
      })
    );
    dispatch(
      sendSourceTreeInfo(
        sourceEditorType,
        await fetchSourceTreeInfoImpl(sourceEditorType)
      )
    );
    if (active && active === source) {
      dispatch(sendSourceTreeActive(sourceEditorType, target));
      updateUrl(target, sourceEditorType);
      dispatch(
        sendSourceCodeInfo(
          sourceEditorType,
          target,
          await fetchSourceCodeImpl(sourceEditorType, target)
        )
      );
    }
  } catch (e: any) {
    if (e instanceof ServerError) {
      dispatchError("SRC_EDITOR_CODE_MOVE_ERROR_WITH_CODE", e, dispatch, {
        source,
        target,
        code: e.code,
        message: e.message,
      });
    } else if (typeof e.message == "string") {
      dispatchError("SRC_EDITOR_CODE_MOVE_ERROR_WITH_CODE", e, dispatch, {
        source,
        target,
        code: -1,
        message: e.message,
      });
    } else {
      dispatchError("SRC_EDITOR_CODE_MOVE_ERROR", e, dispatch, {
        source,
        target,
      });
    }
  }
}

export function confirmReplace(
  source: string,
  target: string,
  sourceEditorType: SourceEditorType
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal(
        "namespace.confirmAction",
        "confirmAction",
        {
          title: { id: "SRCEDITOR_CONFIRM_CREATION_TITLE" },
          body: {
            id: "SRCEDITOR_CONFIRM_REPLACE",
            values: { path: target },
          },
        },
        async (result) => {
          dispatch(sendSourceCodeLoading(sourceEditorType, target));
          const sourceCode: SourceEditorCodeData = await fetchSourceCodeImpl(
            sourceEditorType,
            source
          );
          const targetCode: SourceEditorCodeData = await fetchSourceCodeImpl(
            sourceEditorType,
            target
          );
          sourceCode.id = targetCode.id;
          sourceCode.path = target;
          try {
            dispatch(
              sendSourceCodeInfo(
                sourceEditorType,
                target,
                await saveSourceCodeImpl(sourceEditorType, target, sourceCode)
              )
            );
            await deleteSourceCodeImpl(sourceEditorType, source, false);
            dispatch(
              sendSourceTreeInfo(
                sourceEditorType,
                await fetchSourceTreeInfoImpl(sourceEditorType)
              )
            );
            dispatch(sendSourceCodeLoading(sourceEditorType, target, false));
          } catch (e: any) {
            dispatch(sendSourceCodeLoading(sourceEditorType, target, false));

            if (e instanceof ServerError) {
              dispatchError(
                "SRC_EDITOR_CODE_SAVE_ERROR_WITH_CODE",
                e,
                dispatch,
                {
                  path: target,
                  code: e.code,
                  message: e.message,
                }
              );
            } else if (typeof e.message == "string") {
              dispatchError(
                "SRC_EDITOR_CODE_SAVE_ERROR_WITH_CODE",
                e,
                dispatch,
                { path: target, code: -1, message: e.message }
              );
            } else {
              dispatchError("SRC_EDITOR_CODE_SAVE_ERROR", e, dispatch, {
                path: target,
              });
            }
          }
        }
      )
    );
  };
}

export function moveSourceCode(
  sourceEditorType: SourceEditorType,
  source: string,
  target: string
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const editorState = s[sourceEditorType];
    if (!editorState) {
      return;
    }
    const node = editorState.nodeById && editorState.nodeById[source];
    if (!node) {
      return;
    }
    const targetNode = editorState.nodeById && editorState.nodeById[target];
    if (targetNode) {
      confirmReplace(source, target, sourceEditorType);
    } else {
      const active = editorState.active;
      moveSourceCodeCommon(
        dispatch,
        sourceEditorType,
        source,
        target,
        node.directory,
        false,
        active
      );
    }
  };
}
export function copyDataToClipboard(
  data: any
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const copy = copyTextToClipboard(data);
    if (!copy) {
      dispatchError("SRCEDITOR_COPY_TO_CLIPBOARD_ERROR", null, dispatch);
    } else {
      dispatchError("SRCEDITOR_COPY_TO_CLIPBOARD_SUCCESS", null, dispatch);
    }
  };
}

export function renameSourceCode(
  sourceEditorType: SourceEditorType,
  source: string,
  name: string
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    dispatch(sendSourceTreeToggleInplace(sourceEditorType, source, false));
    const s = getState();
    const editorState = s[sourceEditorType];
    if (!editorState) {
      return;
    }
    if (!name) {
      return;
    }
    const node = editorState.nodeById && editorState.nodeById[source];
    if (!node) {
      return;
    }
    if (name.charAt(0) == "/") {
      const target = "/" + cleanName(name);
      moveSourceCodeCommon(
        dispatch,
        sourceEditorType,
        source,
        target,
        node.directory,
        true
      );
    } else {
      const path = source
        .trim()
        .replace(/\/+$/, "")
        .replace(/^\/+/, "")
        .split("/");
      const target =
        "/" + path.slice(0, path.length - 1).join("/") + "/" + cleanName(name);
      moveSourceCodeCommon(
        dispatch,
        sourceEditorType,
        source,
        target,
        node.directory,
        true
      );
    }
  };
}

export function copySourceCode(
  sourceEditorType: SourceEditorType,
  source: string,
  target: string
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const editorState = s[sourceEditorType];
    if (!editorState) {
      return;
    }
    const node = editorState.nodeById && editorState.nodeById[source];
    if (!node) {
      return;
    }
    let targetPath = target;
    try {
      const targetNode = editorState?.nodeById?.[target];
      if (targetNode) {
        while (editorState?.nodeById?.[targetPath]) {
          targetPath += "_1";
        }
      }

      await copySourceCodeImpl(sourceEditorType, source, targetPath, node);
      dispatch(
        addAlert(ALERT_LEVEL_SUCCESS, {
          id: "SRC_EDITOR_CODE_COPY_SUCCESS",
          values: { source, target: targetPath },
        })
      );
      dispatch(
        sendSourceTreeInfo(
          sourceEditorType,
          await fetchSourceTreeInfoImpl(sourceEditorType)
        )
      );
      dispatch(sendSourceTreeActive(sourceEditorType, targetPath));
    } catch (e: any) {
      if (e instanceof ServerError) {
        dispatchError("SRC_EDITOR_CODE_COPY_ERROR_WITH_CODE", e, dispatch, {
          source,
          target,
          code: e.code,
          message: e.message,
        });
      } else if (typeof e.message == "string") {
        dispatchError("SRC_EDITOR_CODE_COPY_ERROR_WITH_CODE", e, dispatch, {
          source,
          target,
          code: -1,
          message: e.message,
        });
      } else {
        dispatchError("SSRC_EDITOR_CODE_COPY_ERROR", e, dispatch, {
          source,
          target,
        });
      }
    }
  };
}

export function deleteSourceCode(
  sourceEditorType: SourceEditorType,
  path: string
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const editorState = s[sourceEditorType];
    if (!editorState) {
      return;
    }
    const node = editorState.nodeById && editorState.nodeById[path];
    if (!node) {
      return;
    }
    if (node.directory) {
      try {
        await deleteSourceCodeImpl(sourceEditorType, path, true);
        dispatch(
          addAlert(ALERT_LEVEL_SUCCESS, {
            id: "SRC_EDITOR_DIRECTORY_REMOVE_SUCCESS",
            values: { path },
          })
        );
        dispatch(
          sendSourceTreeInfo(
            sourceEditorType,
            await fetchSourceTreeInfoImpl(sourceEditorType)
          )
        );
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatchError(
            "SRC_EDITOR_DIRECTORY_REMOVE_ERROR_WITH_CODE",
            e,
            dispatch,
            { path, code: e.code, message: e.message }
          );
        } else if (typeof e.message == "string") {
          dispatchError(
            "SRC_EDITOR_DIRECTORY_REMOVE_ERROR_WITH_CODE",
            e,
            dispatch,
            { path, code: -1, message: e.message }
          );
        } else {
          dispatchError("SRC_EDITOR_DIRECTORY_REMOVE_ERROR", e, dispatch, {
            path,
          });
        }
      }
    } else {
      try {
        await deleteSourceCodeImpl(sourceEditorType, path, false);
        dispatch(
          addAlert(ALERT_LEVEL_SUCCESS, {
            id: "SRC_EDITOR_CODE_REMOVE_SUCCESS",
            values: { path },
          })
        );
        dispatch(
          sendSourceTreeInfo(
            sourceEditorType,
            await fetchSourceTreeInfoImpl(sourceEditorType)
          )
        );
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatchError("SRC_EDITOR_CODE_REMOVE_ERROR_WITH_CODE", e, dispatch, {
            path,
            code: e.code,
            message: e.message,
          });
        } else if (typeof e.message == "string") {
          dispatchError("SRC_EDITOR_CODE_REMOVE_ERROR_WITH_CODE", e, dispatch, {
            path,
            code: -1,
            message: e.message,
          });
        } else {
          dispatchError("SRC_EDITOR_CODE_REMOVE_ERROR", e, dispatch, {
            path,
          });
        }
      }
    }
  };
}

export function change(
  sourceEditorType: SourceEditorType,
  changedCode: string
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const code =
      checkExistingSource(getState(), sourceEditorType) ||
      checkNewSource(getState(), sourceEditorType);
    if (code) {
      dispatch(
        sendSourceCodeTextChanges(sourceEditorType, code.path, changedCode)
      );
    }
  };
}

export function changeMetadata(
  sourceEditorType: SourceEditorType,
  metadata: SourceMetaData
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const code =
      checkExistingSource(getState(), sourceEditorType) ||
      checkNewSource(getState(), sourceEditorType);
    const s = getState()[sourceEditorType];
    if (code && s && s.active) {
      // dispatch(sendSourceTreeActive(sourceEditorType,code.path ))
      dispatch(
        sendSourceCodeMetaDataChanges(sourceEditorType, s.active, metadata)
      );
    }
  };
}

function cleanName(name: string) {
  return (name = name
    .trim()
    .replace(/\/+$/, "")
    .replace(/^\/+/, "")
    .replace(/\/+/g, "/"));
}

function generatePathCommon(editorState: SourceEditorState, initial: string) {
  let generatedPath = initial;
  const nodeById = editorState.nodeById;
  if (!nodeById) {
    return generatedPath;
  }
  let found;
  let counter = 0;
  do {
    found = nodeById[generatedPath] ? true : false;
    if (found) {
      ++counter;
      generatedPath = initial + counter;
    }
  } while (found);
  return generatedPath;
}

function generatePath(editorState: SourceEditorState, name?: string) {
  if (!name) {
    name = "new";
  }
  if (name.charAt(0) == "/") {
    return generatePathCommon(editorState, "/" + cleanName(name));
  }
  name = cleanName(name);
  const activeNode =
    editorState.active &&
    editorState.nodeById &&
    editorState.nodeById[editorState.active];
  if (!activeNode) {
    return generatePathCommon(editorState, "/" + name);
  }
  if (activeNode.directory) {
    //If active node is directory
    return generatePathCommon(editorState, activeNode.id + "/" + name);
  }
  if (!activeNode.parentId) {
    //Active node is root file
    return generatePathCommon(editorState, "/" + name);
  }
  //Active node is file
  return generatePathCommon(editorState, activeNode.parentId + "/" + name);
}

export function create(
  sourceEditorType: SourceEditorType,
  template?: Template
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const editorState = getState()[sourceEditorType];
    if (!editorState) {
      return;
    }
    let codeTemplate = template;
    if (!codeTemplate) {
      codeTemplate = {
        path: generatePath(editorState),
        type: sourceEditorType == "vieweditor" ? "view" : "script",
        description: "",
        mime: "application/json",
        code: "",
      };
    }

    dispatch(sendSourceCodeChangeTemplate(sourceEditorType, codeTemplate));
  };
}

export function createDirectory(
  sourceEditorType: SourceEditorType,
  name: string
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const editorState = getState()[sourceEditorType];
    if (!editorState) {
      return;
    }
    const path = generatePath(editorState, name);
    dispatch(sendSourceTreeCreateDirectory(sourceEditorType, path));
    dispatch(sendSourceTreeActive(sourceEditorType, path));
  };
}

function buildNode(
  n: HierarchicalTemplateTreeNode,
  template: Template,
  children: string[]
) {
  const templateTree = template.templateTree;
  if (!templateTree) {
    return;
  }
  const t: TemplateTreeNode = {
    id: shortid.generate(),
    type: n.type,
    code: n.code,
    placeholder: n.placeholder,
    inplace: n.inplace,
  };
  if (n.inplace) {
    //We have special substitutions for template data
    switch (n.type) {
      case "##description##":
        t.type = "#text";
        if (template.description) {
          //Have user filled this field?
          t.code = template.description;
        }
        break;
      case "##path##":
        t.type = "#text";
        t.code = template.path;
        break;
      case "##mime##":
        t.type = "#text";
        t.code = template.mime;
        break;
    }
  }
  templateTree.nodeById[t.id] = t;
  children.push(t.id);
  if (n.children) {
    const subChildren = (templateTree.childrenIds[t.id] = []);
    for (let child of n.children) {
      buildNode(child, template, subChildren);
    }
  }
}

export function initTemplateTree(
  sourceEditorType: SourceEditorType,
  tree: HierarchicalTemplateTree
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const editorState = getState()[sourceEditorType];
    if (!editorState) {
      return;
    }
    const template = editorState.template && { ...editorState.template };
    if (!template) {
      return;
    }
    template.mime = tree.mime;
    template.code = "";
    template.templateTree = {
      rootNodesIds: [],
      nodeById: {},
      childrenIds: {},
      code: tree.code,
      placeholder: tree.placeholder,
    };
    if (tree.children) {
      for (let root of tree.children) {
        buildNode(root, template, template.templateTree.rootNodesIds);
      }
    }
    dispatch(sendSourceCodeChangeTemplate(sourceEditorType, template));
  };
}

export function applyTemplate(
  sourceEditorType: SourceEditorType
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const editorState = getState()[sourceEditorType];
    if (!editorState) {
      return;
    }
    const template = editorState.template;
    if (!template) {
      return;
    }
    const path = generatePath(editorState, template.path);
    dispatch(sendSourceCodeApplyTemplate(sourceEditorType, path));
    dispatch(sendSourceTreeActive(sourceEditorType, path));
  };
}

export function changeTemplateCode(
  sourceEditorType: SourceEditorType,
  nodeId: string,
  code: string
): ThunkAction<void, ApplicationState, {}, SourceEditorAction> {
  return async (dispatch, getState) => {
    const editorState = getState()[sourceEditorType];
    if (!editorState) {
      return;
    }
    const templateTree =
      editorState.template && editorState.template.templateTree;
    if (!templateTree) {
      return;
    }
    const n = templateTree.nodeById[nodeId];
    if (!n) {
      return;
    }
    dispatch(
      sendSourceCodeChangeTemplateNode(sourceEditorType, { ...n, code })
    );
  };
}

export function confirmAction(
  path: string,
  id: SourceEditorType
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal(
        "namespace.confirmAction",
        "confirmAction",
        {
          title: { id: "SRCEDITOR_CONFIRM_CREATION_TITLE" },
          body: {
            id: "SRCEDITOR_CONFIRM_CREATION_BODY",
            values: { path },
          },
        },
        async (result) => {
          const s = getState()[id];
          if (!s || !s.nodeById) {
            return;
          }

          const allPaths = Object.keys(s.nodeById);
          let targetPath = path;
          const pathSegments = path.split("/");
          pathSegments.shift();
          while (!allPaths.includes(targetPath)) {
            const segment = pathSegments.pop();
            targetPath = "/" + pathSegments.join("/");
          }
          dispatch(sendSourceTreeActive(id, targetPath));

          const code: Template = {
            path: `${path}`,
            type: id == "vieweditor" ? "view" : "script",
            description: "",
            mime: "application/json",
            code: "",
          };
          dispatch(create(id, code));
        }
      )
    );
  };
}

export function sourceHistory(
  id: SourceEditorType
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    const editorState = getState()[id];
    const path = editorState?.active;
    if (!path) {
      return;
    }
    try {
      const history = await fetchSourceHistoryImpl(id, path);
      dispatch(
        openModal("sourceeditor.history", "sourceHistory", {
          size: "extra-large",
          data: { editorId: id, history },
        })
      );
    } catch (e) {
      dispatchError("SRCEDITOR_FETCH_HISTORY_ERROR", e, dispatch);
    }
  };
}

export function sourceRestoreCode(
  editorId: SourceEditorType,
  id: number,
  path: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async function (dispatch, getState) {
    try {
      const result = await restoreSourceCodeImpl(editorId, id, path);
      dispatchSuccess("SRCEDITOR_RESTORE_CODE_SUCCESS", dispatch);
      dispatch(fetchSourceTreeInfo(editorId));
      dispatch(
        sendSourceCodeInfo(
          editorId,
          path,
          await fetchSourceCodeImpl(editorId, path)
        )
      );
    } catch (e) {
      dispatchError("SRCEDITOR_RESTORE_CODE_ERROR", e, dispatch);
    }
  };
}
