
import   './glowingLoader.css' 

export const GlowingLoader = ()=>{
    return (
        <div className={` loader  npt-glowing-loader`}>
            <div className={` face  face1 `}>
                <div className={'circle'}></div>
            </div>
            <div className={`face face2`}>
                <div className={' circle'}></div>
            </div>
        </div>
    )
}