import * as React from "react";

//Idle timer
import { IdleTimerAPI, useIdleTimer } from "react-idle-timer";

import {
  Route,
  BrowserRouter as Router,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

//Redux
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "./types";

//Drag and drop support
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

//Resources
import "./App.css";

//Components
import asyncComponent from "./AsyncComponent";
import LoginPage from "./components/login/LoginPage";
import { TestInfiniteTree } from "./components/tree/ListTree";

import ModalStack from "./components/modal/ModalStack";
import AlertStack from "./components/alert/AlertStack";
import ThemeImporter from "./components/theme/ThemeImporter";
import asyncNonVisualComponent from "./NoneVisualAsyncComponent";

import { SelectionState, createObject } from "./types/selection";
import { SearchData } from "./types/location";
import {
  isLoggedInUser,
  isLoginStatus,
  LoggedInUser,
  LoginStatus,
  RealtimeData,
  UIVariant,
} from "./types/security";
import { ContextMenuInfo } from "./types/tree";
import {
  AddedSubjectEventOptions,
  NewSubjectEventOptions,
  SubjectData,
} from "./types/subject";
import {
  ADD_NEW_SUBJECT_EVENT,
  NEW_SUBJECT_ADDED_EVENT,
} from "./constants/subject";
import {
  getLoginInfoImpl,
  logout,
  sendLoginInfo,
  sendLoginInfoToBroadcast,
} from "./actions/security";
import { changeSearch } from "./actions/location";
import { sendSelection } from "./actions/selection";
import { expandTreeNode } from "./actions/tree";
import { addNewSubject } from "./actions/subject";

import { getRdfId } from "./services/subject";

//Asynchronious components
const AsyncView = asyncComponent(() => import("./components/view/ViewPage"));
const SystemInfoIndex = asyncComponent(
  () => import("./components/helpers/SystemInfo")
);
const AsyncObjectCard = asyncComponent(
  () => import("./components/objectcard/ObjectCardPage")
);
const AsyncHelp = asyncComponent(() => import("./components/help/HelpPage"));
const AsyncAdmin = asyncComponent(() => import("./components/admin/AdminPage"));
const AsyncSecurity = asyncComponent(
  () => import("./components/security/SecurityPage")
);
const AsyncDeveloper = asyncComponent(
  () => import("./components/developer/DeveloperPage")
);
const AsyncMessages = asyncComponent(
  () => import("./components/messages/MessagesPage")
);
const AsyncTestTable = asyncComponent(
  () => import("./components/table/TestTable")
);
const AsyncRealtimeDataProvider = asyncNonVisualComponent(
  () => import("./components/rt/RealtimeDataProvider")
);

const MainPage = (props: { variant?: UIVariant }) => {
  return <AsyncView />;
};

const ConnectedMainPage = connect((state: ApplicationState) => {
  return {
    variant: isLoggedInUser(state.security.loginStatus)
      ? state.security.loginStatus.options.variant
      : undefined,
  };
})(MainPage);
const ConnectedLoginPage = connect((state: ApplicationState) => {
  return {
    variant:
      (state.security?.loginStatus as any)?.options?.variant || undefined,
  };
})(LoginPage);

interface AddCardListenerProps {
  contextMenu?: ContextMenuInfo;
  addNewSubject: (
    subjectKey: string,
    data: SubjectData,
    notifyId: string
  ) => void;
  setSelection: (object: string, type: string) => void;
  expandTreeNode: (
    treeId: string,
    nodeId: string,
    activeNodeId?: string
  ) => void;
}
const AddCardListener = React.memo((props: AddCardListenerProps) => {
  React.useEffect(() => {
    const addNewSubjectEvent = (event: any) => {
      //normally called from trees
      const info = event.detail as NewSubjectEventOptions;
      const { addNewSubject, setSelection } = props;

      addNewSubject(
        info.data.$rdfId || info.data.$class,
        info.data,
        info.notifyId
      );
      const rdfId = getRdfId(info.data);
      if (rdfId) {
        setSelection(createObject(rdfId, info.data.$namespace), "");
      }
    };

    const subjectAddedEvent = (event: any) => {
      //normally called from trees
      const { setSelection, contextMenu, expandTreeNode } = props;
      const info = event.detail as AddedSubjectEventOptions;
      setSelection(createObject(info.newRdfId), "");
      if (contextMenu) {
        const { nodeId, treeId } = contextMenu;
        expandTreeNode(treeId, nodeId, info.newRdfId);
      }
    };

    //Subscribe to add new subjects
    document.addEventListener(ADD_NEW_SUBJECT_EVENT, addNewSubjectEvent, false);
    document.addEventListener(
      NEW_SUBJECT_ADDED_EVENT,
      subjectAddedEvent,
      false
    );
    return () => {
      document.removeEventListener(
        ADD_NEW_SUBJECT_EVENT,
        addNewSubjectEvent,
        false
      );
      document.removeEventListener(
        NEW_SUBJECT_ADDED_EVENT,
        subjectAddedEvent,
        false
      );
    };
  }, [props.contextMenu?.nodeId, props.contextMenu?.treeId]);
  return null;
});

const ConnectedAddCardListener = connect(
  (state: ApplicationState) => {
    return {
      contextMenu: state.tree.contextMenuInfo,
    };
  },
  (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
      addNewSubject: (
        subjectKey: string,
        data: SubjectData,
        notifyId: string
      ) => dispatch(addNewSubject(subjectKey, data, notifyId)),
      setSelection: (object: string, type: string) =>
        dispatch(sendSelection(object, type)),
      expandTreeNode: (treeId: string, nodeId: string, activeNodeId?: string) =>
        dispatch(expandTreeNode(treeId, nodeId, activeNodeId)),
    };
  }
)(AddCardListener);

const RootRouter = (props: { logout: () => void }) => {
  let timer: IdleTimerAPI;
  const handleOnIdle = async () => {
    console.log(`Logout on idle`);
    props.logout();
  };
  const handleOnAction = async (event: any) => {
    try {
      const loginInfo = await getLoginInfoImpl();
      if (!loginInfo.loggedIn) {
        console.log(`Logged out: send info to other tabs`);
        sendLoginInfoToBroadcast(loginInfo);
      }
    } catch (e) {
      console.error("Failed to fetch login info", e);
    }
  };
  timer = useIdleTimer({
    timeout: 1000 * 60 * 25,
    onAction: handleOnAction,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true,
    },
    eventsThrottle: 500,
    throttle: 1000 * 60 * 5,
  });
  timer.reset();

  return (
    <Router>
      <ConnectedAddCardListener />
      <ModalStack />
      <AlertStack />
      <Route exact path="/" component={ConnectedMainPage} />
      <Route path="/login" component={AsyncView} />
      <Route path="/scada" component={SystemInfoIndex} />
      <Route path="/view" component={AsyncView} />
      <Route path="/objectcard" component={AsyncObjectCard} />
      <Route path="/help" component={AsyncHelp} />
      <Route path="/admin" component={AsyncAdmin} />
      <Route path="/developer" component={AsyncDeveloper} />
      <Route path="/messages" component={AsyncMessages} />
      <Route path="/security" component={AsyncSecurity} />
      <Route exact path="/test/tree" component={TestInfiniteTree} />
      <Route exact path="/test/table" component={AsyncTestTable} />
    </Router>
  );
};

const ConnectedRootRouter = connect(
  null,
  (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
      logout: () => {
        dispatch(logout());
      },
    };
  }
)(RootRouter);

//Component used to chech auth and redirect to login page
const AuthChecker = (props: {
  loggedIn: boolean;
  loggedUsername: string | null;
  rt?: RealtimeData;
  sendLoginInfo: (info: LoginStatus) => void;
}) => {
  const [activeUsername, setActiveUsername] = React.useState(
    props.loggedUsername
  );

  /**Reset loaded modules on user change to prevent security errors */
  React.useEffect(() => {
    if (!props.loggedUsername || activeUsername === props.loggedUsername) {
      return;
    }
    (window as any).__NPT__.reset();
    setActiveUsername(props.loggedUsername);
  }, [props.loggedUsername]);

  React.useEffect(() => {
    const loginListener = new BroadcastChannel("loginStatus");
    loginListener.onmessage = (event) => {
      props.sendLoginInfo(event.data);
    };
    return () => {
      loginListener.close();
    };
  }, []);

  if (props.loggedIn) {
    if (typeof props.rt != "undefined") {
      //With realtime data
      return (
        <>
          <AsyncRealtimeDataProvider key="rt" {...props.rt} />
          <ConnectedRootRouter key="rootRouter" />
        </>
      );
    }

    //Without realtime data
    return <ConnectedRootRouter />;
  }

  //Go to login page
  return <ConnectedLoginPage />;
};

const ConnectedAuthChecker = connect(
  (state: ApplicationState) => {
    const isLoggedIn = isLoggedInUser(state.security.loginStatus);
    return {
      loggedIn: isLoggedIn ? true : false,
      loggedUsername: isLoggedIn
        ? (state.security.loginStatus as LoggedInUser).username
        : null,
      rt: isLoggedIn
        ? (state.security.loginStatus as LoggedInUser).options.rt
        : undefined,
    };
  },
  (dispatch) => {
    return {
      sendLoginInfo: (info: LoginStatus) => {
        dispatch(sendLoginInfo(info));
      },
    };
  }
)(AuthChecker);

const ConnectedThemeImporter = connect((state: ApplicationState) => {
  const status = isLoginStatus(state.security.loginStatus)
    ? state.security.loginStatus
    : undefined;
  const options = status && status.options && status.options;
  return { variant: options?.variant };
}, null)(ThemeImporter);

class App extends React.Component {
  public render() {
    return (
      <ConnectedThemeImporter>
        <DndProvider backend={HTML5Backend}>
          <ConnectedAuthChecker />
        </DndProvider>
      </ConnectedThemeImporter>
    );
  }
}

export default App;
