import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export interface PasswordGroupProps {
    inputRef: React.MutableRefObject<HTMLInputElement | null>
    onChange: (value: string) => void
    isCode: boolean //if this input is code input
}

export interface PasswordGroupStates {
    visible: boolean
}

export default class PasswordGroup extends React.PureComponent<PasswordGroupProps, PasswordGroupStates> {

    constructor(props: PasswordGroupProps) {
        super(props);
        this.state = {
            visible: false
        }
        this.onChange = this.onChange.bind(this);
        this.toggleVisible = this.toggleVisible.bind(this);
    }

    onChange(event: any) {
        this.props.onChange(event.target.value);
    }

    toggleVisible(event: any) {
        this.setState({ visible: !this.state.visible });
    }

    /* Use style props due to outdated bootstrap */
    render() {
        return <Form.Group>
            <Form.Label htmlFor="passwordInput" >
                {this.props.isCode ?
                    <FormattedMessage
                        id="LOGIN_PANEL_CODE_LABEL"
                        defaultMessage="Code:"
                        description="Label for code field" /> :
                    <FormattedMessage
                        id="LOGIN_PANEL_PASSWORD_LABEL"
                        defaultMessage="Password:"
                        description="Label for password field" />
                }
            </Form.Label>
            <InputGroup className="mb-2">
                <input id={this.props.isCode ? "codeInput" : "passwordInput"} name={this.props.isCode ? "code" : "password"}
                    className="form-control border"
                    type={this.state.visible ? "text" : "password"}
                    ref={this.props.inputRef}
                    onChange={this.onChange} />
                <InputGroup.Append>
                    <Button variant="outline-warning" onClick={this.toggleVisible}><FontAwesomeIcon icon={this.state.visible ? faEyeSlash : faEye} /></Button>
                </InputGroup.Append>
            </InputGroup>
        </Form.Group>
    }
}