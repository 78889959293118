import React from 'react';

interface SettingsProps {
    width: number
    height: number
    hatWidth: number
    hatHeight: number
    headWidth: number
    headHeight: number
    bodyWidth: number
    bodyHeight: number
    stroke: number
}

const NotFoundIcon: React.FunctionComponent = React.memo((props) => {
    /**Settings values */
    const settings: SettingsProps = {
        width: 100,
        height: 100,
        hatWidth: 37,
        hatHeight: 26,
        headWidth: 32,
        headHeight: 30,
        bodyWidth: 85,
        bodyHeight: 40,
        stroke: 3
    }


    return <svg className="w-100 h-100" viewBox={`0 0 100 100`}>
        <Hair {...settings} />
        <Face {...settings} />
        <Glasses {...settings} />
        <Body {...settings} />
        <Questions {...settings} />
    </svg>;
});

const Hair: React.FunctionComponent<SettingsProps> = (props) => {
    const yPadding = (100 - props.height) / 2;
    const hatWidth = props.hatWidth;
    const hatHeight = props.hatHeight;
    const headHeight = props.headHeight;
    const headWidth = props.headWidth;
    const hatHalfWidth = hatWidth / 2;
    const headHalfWidth = headWidth / 2;

    const faceLeft = 50 - headHalfWidth + props.stroke / 2;
    const faceRight = 50 + headHalfWidth - props.stroke / 2;
    const hatLeft = 50 - hatHalfWidth - props.stroke / 2;
    const hatRight = 50 + hatHalfWidth + props.stroke / 2;

    const hairHight = hatHeight * 0.7;

    return <g>
        <path
            style={{
                stroke: "none",
                // fill: "black"
            }}
            d={`M${faceLeft + 1} ${yPadding + hatHeight}
                Q${faceLeft + 1} ${yPadding + hatHeight + 5}, ${faceLeft} ${yPadding + hatHeight + 6}
                Q${hatLeft + 1} ${yPadding + hatHeight + 6}, ${hatLeft} ${yPadding + hatHeight * 0.7}
                Q${hatLeft} ${yPadding + 2}, ${50} ${yPadding + 2}
                Q${50 + hatWidth * 0.1} ${yPadding + 2}, ${50 + hatWidth * 0.25} ${yPadding + 5}
                M${50 + hatWidth * 0.1} ${yPadding + 5}
                Q${hatRight - hatWidth * 0.02} ${yPadding}, ${hatRight} ${yPadding + hatHeight * 0.7}
                Q${hatRight - 1} ${yPadding + hatHeight + 6}, ${faceRight} ${yPadding + hatHeight + 6}
                Q${faceRight - 1} ${yPadding + hatHeight + 5}, ${faceRight - 1.5} ${yPadding + hatHeight}
                Q${faceRight - 1.5} ${yPadding + hatHeight * 0.95}, ${faceRight - headWidth * 0.1} ${yPadding + hatHeight * 0.92}
                C${faceRight - headWidth * 0.15} ${yPadding + hatHeight * 0.85}, ${faceRight - headWidth * 0.2} ${yPadding + hatHeight * 0.85}, ${50 + headWidth * 0.2} ${yPadding + hairHight}
                Q${50 + headWidth * 0.15} ${yPadding + hairHight * 0.9}, ${50 + headWidth * 0.05} ${yPadding + hairHight}
                Q${50 - headWidth * 0.2} ${yPadding + hatHeight}, ${faceLeft + 1} ${yPadding + hatHeight}
                `}
        />
    </g>;
};

const Face: React.FunctionComponent<SettingsProps> = (props) => {
    const yTop = (100 - props.height) / 2;
    const hatHeight = props.hatHeight;
    const headWidth = props.headWidth;
    const headHeight = props.headHeight;
    const headHalfWidth = headWidth / 2;
    return <g>
        <path
            strokeWidth={props.stroke}
            style={{
                // stroke: "black",
                fill: "none"
            }}
            d={`M${50 - headHalfWidth} ${yTop + hatHeight} 
                C${50 - headWidth * 0.47} ${yTop + hatHeight + headHeight / 2}, ${50 - headWidth * 0.3} ${yTop + hatHeight + headHeight * 0.95}, 50 ${yTop + hatHeight + headHeight}
                C${50 + headWidth * 0.3} ${yTop + hatHeight + headHeight * 0.95}, ${50 + headWidth * 0.47} ${yTop + hatHeight + headHeight / 2}, ${50 + headHalfWidth} ${yTop + hatHeight}`}
        />
    </g>;
};

const Glasses: React.FunctionComponent<SettingsProps> = (props) => {
    const hatHeight = props.hatHeight;
    const headWidth = props.headWidth;
    const headHeight = props.headHeight;
    const headHalfWidth = headWidth / 2;

    const faceWidth = headWidth - props.stroke;
    const faceLeft = 50 - headHalfWidth + props.stroke / 2;
    const faceRight = 50 + headHalfWidth - props.stroke / 2;

    const yPadding = (100 - props.height) / 2;
    const yTop = yPadding + hatHeight + headHeight * 0.09;

    const glassesHeight = headHeight * 0.25;
    return <g>
        <path
            strokeWidth={props.stroke / 2}
            style={{
                // stroke: "black",
                fill: "none"
            }}
            d={`M${faceLeft} ${yTop} 
                L${faceLeft + faceWidth * 0.05} ${yTop} 
                C${faceLeft + faceWidth * 0.05} ${yTop + glassesHeight}, ${faceLeft + faceWidth * 0.25} ${yTop + glassesHeight}, ${faceLeft + faceWidth * 0.25} ${yTop + glassesHeight}
                Q${faceLeft + faceWidth * 0.42} ${yTop + glassesHeight}, ${faceLeft + faceWidth * 0.45} ${yTop}
                C${faceLeft + faceWidth * 0.45} ${yTop - headHeight * 0.05}, ${faceLeft + faceWidth * 0.1} ${yTop - headHeight * 0.05}, ${faceLeft + faceWidth * 0.05} ${yTop} 
                M${faceLeft + faceWidth * 0.45} ${yTop}
                Q${faceLeft + faceWidth * 0.5} ${yTop - headHeight * 0.02}, ${faceLeft + faceWidth * 0.55} ${yTop}
                M${faceRight} ${yTop} 
                L${faceRight - faceWidth * 0.05} ${yTop} 
                C${faceRight - faceWidth * 0.05} ${yTop + glassesHeight}, ${faceRight - faceWidth * 0.25} ${yTop + glassesHeight}, ${faceRight - faceWidth * 0.25} ${yTop + glassesHeight}
                Q${faceRight - faceWidth * 0.42} ${yTop + glassesHeight}, ${faceRight - faceWidth * 0.45} ${yTop}
                C${faceRight - faceWidth * 0.45} ${yTop - headHeight * 0.05}, ${faceRight - faceWidth * 0.1} ${yTop - headHeight * 0.05}, ${faceRight - faceWidth * 0.05} ${yTop} 
                `}
        />
    </g>;
};

const Body: React.FunctionComponent<SettingsProps> = (props) => {
    const hatHeight = props.hatHeight;
    const headWidth = props.headWidth;
    const headHeight = props.headHeight;
    const bodyWidth = props.bodyWidth;
    const bodyHeight = props.bodyHeight;

    const halfBodyWidth = bodyWidth / 2;

    const yPadding = (100 - props.height) / 2;
    const yTop = yPadding + hatHeight + headHeight;
    const yBottom = yTop + bodyHeight;

    const neckWidth = headWidth * 0.8;
    const neckHeight = headHeight * 0.31;

    const shouldersWidth = bodyWidth * 0.8;
    const armUplift = bodyHeight * 0.3;
    const armWidth = halfBodyWidth * 0.97;
    return <g>
        <path
            style={{
                stroke: "none",
                // fill: "black"
            }}
            d={`M${50 - neckWidth / 2} ${yTop - neckHeight} 
                C${50 - neckWidth * 0.5} ${yTop}, ${50 - neckWidth * 0.3} ${yTop + bodyHeight * 0.1}, 50 ${yTop + bodyHeight * 0.1}
                C${50 + neckWidth * 0.3} ${yTop + bodyHeight * 0.1}, ${50 + neckWidth * 0.5} ${yTop}, ${50 + neckWidth / 2} ${yTop - neckHeight}
                C${50 + shouldersWidth / 4} ${yTop - neckHeight * 0.5}, ${50 + shouldersWidth * 0.3} ${yTop - neckHeight * 0.8}, ${50 + shouldersWidth / 2} ${yTop}
                C${50 + halfBodyWidth * 0.9} ${yTop - bodyHeight * 0.02}, ${50 + armWidth} ${yTop + bodyHeight * 0.5}, ${50 + armWidth} ${yBottom - armUplift}
                ${getLaptop(props, armWidth, armUplift)}
                C${50 - armWidth} ${yTop + bodyHeight * 0.5}, ${50 - halfBodyWidth * 0.9} ${yTop - bodyHeight * 0.02}, ${50 - shouldersWidth / 2} ${yTop}
                C${50 - shouldersWidth * 0.3} ${yTop - neckHeight * 0.8}, ${50 - shouldersWidth / 4} ${yTop - neckHeight * 0.5}, ${50 - neckWidth / 2} ${yTop - neckHeight}
                `}
        />
        <path
            style={{
                // stroke: "black",
                fill: "none"
            }}
            d={`M${50 + armWidth} ${yBottom - armUplift}
                C${50 - halfBodyWidth * 0.2} ${yBottom}, ${50 + halfBodyWidth * 0.2} ${yBottom}, ${50 - armWidth} ${yBottom - armUplift}
                `}
        />
        <LaptopLogo {...props} />
    </g>;
};

const Questions: React.FunctionComponent<SettingsProps> = (props) => {
    return <g>
        <text
            x={77}
            y={35}
            style={{
                fontSize: 44,
                fontFamily: "auto"
            }}
            textAnchor="middle"
            rotate={24}>?</text>
        <text
            x={14}
            y={50}
            style={{
                fontSize: 24,
                fontFamily: "auto"
            }}
            textAnchor="middle"
            rotate={5}>?</text>
        <text
            x={25}
            y={30}
            style={{
                fontSize: 32,
                fontFamily: "auto"
            }}
            textAnchor="middle"
            rotate={-35}>?</text>
    </g>;
};

const getLaptop = (props: SettingsProps, armWidth: number, armUplift: number) => {
    const yPadding = (100 - props.height) / 2;
    const yTop = yPadding + props.hatHeight + props.headHeight;
    const yBottom = yTop + props.bodyHeight;

    const laptopWidth = props.bodyWidth * 0.7;
    const laptopHeight = props.bodyHeight * 0.45;
    const laptopLeft = 50 - laptopWidth / 2;
    const laptopRight = 50 + laptopWidth / 2;
    const laptopBottom = yBottom - props.bodyHeight * 0.22;
    return `L${laptopRight}  ${laptopBottom}
            L${laptopRight + 1}  ${laptopBottom - laptopHeight}
            L${laptopLeft - 1}  ${laptopBottom - laptopHeight}
            L${laptopLeft} ${laptopBottom}
            L${50 - armWidth} ${yBottom - armUplift}
            `;
};

const LaptopLogo: React.FunctionComponent<SettingsProps> = (props) => {
    const yPadding = (100 - props.height) / 2;
    const yTop = yPadding + props.hatHeight + props.headHeight;
    const yBottom = yTop + props.bodyHeight;

    const logoWidth = 10;
    const logoHeight = 7;
    const logoBottom = yBottom - props.bodyHeight * 0.09;
    return <g>
        <path
            style={{
                // stroke: "black",
                fill: "none"
            }}
            d={`M${50 - logoWidth / 2} ${logoBottom}
                C${50 - logoWidth / 2} ${logoBottom - logoHeight}, ${50 + logoWidth / 2} ${logoBottom - logoHeight}, ${50 + logoWidth / 2} ${logoBottom}
                `}
        />
    </g>;
};

export default NotFoundIcon;