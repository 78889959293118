import React from "react";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { AlertLevelType } from "../../types/alert";
import { I18NString } from "../../types/modal";

interface AlertProps {
	type: AlertLevelType
	icon?: string
	headline?: string
	html?: boolean
	onDismiss?: () => void
}

export default class AlertComponent extends React.Component<AlertProps> {
	constructor(props: AlertProps) {
		super(props);
	}

	createContent(content: string | I18NString) {
		if (typeof content === 'string') {
			if (this.props.html) {
				return <div dangerouslySetInnerHTML={{ __html: content }}></div>
			}
			return content;
		}
		const { id, defaultMessage, values } = content;
		return <FormattedMessage id={id} defaultMessage={defaultMessage || "Formatted message for alert"} values={values} />
	}

	renderHeader() {
		return this.props.headline && (
			<Alert.Heading>{this.props.headline}</Alert.Heading>
		)
	}

	renderMessage(message: any) {
		return <span>
			{this.props.icon && <i className={`fa ${this.props.icon} mr-2`} />}
			{message}
		</span>
	}

	renderDesmissableAlert(content: any) {
		const message = this.createContent(content);
		return (
			<Alert variant={this.props.type} onClose={this.props.onDismiss} dismissible>
				{this.renderHeader()}
				<p className="mb-0">
					{this.renderMessage(message)}
				</p>
			</Alert>
		);
	}

	renderPlainAlert(content: any) {
		const message = this.createContent(content);
		return (
			<Alert variant={this.props.type} >
				{this.renderHeader()}
				<p className="mb-0">
					{this.renderMessage(message)}
				</p>
			</Alert>
		);
	}

	render() {
		const isDismissable = !!this.props.onDismiss;
		return isDismissable ? this.renderDesmissableAlert(this.props.children) :
			this.renderPlainAlert(this.props.children);
	}
};

