import React from 'react';
import Dropzone, { DropzoneState } from 'react-dropzone';
import { FormattedMessage,  injectIntl, IntlShape, useIntl } from 'react-intl';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { READY, SETPOINTS, SIGNALS } from '../../../constants/rpasetpoints';
import styles from './RPASetpoints.module.css';

const COMMON_VENDORS: { [K: string]: string } = {
    "Релематика": "Релематика",
    "Экра": "ЭКРА",
    "Siemens": "Siemens",
    "ABB": "ABB", //English
    "АВВ": "ABB"  //Russian
}

const ENCODE_NAMES: { [K: string]: string } = {
    "ASCII": "ASCII",
    "UTF-8": "UTF-8"
}

const ENCODE_VALUES: { [K: string]: string } = {
    "ASCII": "windows-1251",
    "UTF-8": "utf-8"
}


interface FileUploaderProps {
    hideFiledrop?: {
        setpoints: boolean,
        signals: boolean
    },
    uploadState: {
        setpoints: number,
        signals: number
    },
    uploadSetpoints: (file: File, vendor: string, encode: string) => any,
    uploadSignals: (file: File, encode: string) => any
}

interface FileUploaderState {
    setpointsFile: string | null,
    signalsFile: string | null,
    encode: {
        setpoints: string,
        signals: string
    }
    vendor: string | null
}

export default class FileUploader extends React.Component<FileUploaderProps, FileUploaderState> {

    constructor(props: FileUploaderProps) {
        super(props);

        this.state = {
            setpointsFile: null,
            signalsFile: null,
            encode: {
                setpoints: ENCODE_VALUES["ASCII"],
                signals: ENCODE_VALUES["ASCII"]
            },
            vendor: null
        }

        this.changeVendor = this.changeVendor.bind(this);
        this.changeEncode = this.changeEncode.bind(this);
        this.uploadSetpoints = this.uploadSetpoints.bind(this);
        this.uploadSignals = this.uploadSignals.bind(this);
    }

    isFiledropNeeded(type: string): boolean {
        if (!this.props.hideFiledrop) {
            return true;
        }
        if (type == SETPOINTS) {
            return !this.props.hideFiledrop[SETPOINTS];
        }
        if (type == SIGNALS) {
            return !this.props.hideFiledrop[SIGNALS];
        }
        return false;
    }

    isFiledropsDisabled(type: string): boolean {
        return this.state.vendor == null && this.isFiledropNeeded(type);
    }

    changeVendor(vendor: string): void {
        this.setState(Object.assign({}, this.state, { vendor }));
    }

    changeEncode(type: string, value: string) {
        let newState = Object.assign({}, this.state);
        Object.assign(newState, { encode: Object.assign({}, newState.encode) });
        if (type == SETPOINTS) {
            newState.encode.setpoints = value;
        } else {
            newState.encode.signals = value;
        }
        this.setState(newState)
    }

    uploadSetpoints(files: File[]) {
        if (this.state.vendor == null) {
            return;
        }
        this.setState(Object.assign({}, this.state, { setpointsFile: files[0].name }));
        this.props.uploadSetpoints(files[0], this.state.vendor, this.state.encode["setpoints"]);
    }

    uploadSignals(files: File[]) {
        this.setState(Object.assign({}, this.state, { signalsFile: files[0].name }));
        this.props.uploadSignals(files[0], this.state.encode["signals"]);
    }

    render() {
        const disabled = this.isFiledropsDisabled(SETPOINTS)
        const cls = styles.files + " " + (disabled ? styles.disabled : "");
        return (
            <div className={cls}>
                <I18NVendorSelect
                    visible={this.isFiledropNeeded(SETPOINTS)}
                    vendor={this.state.vendor}
                    changeVendor={this.changeVendor}
                />
                <I18NFileUpload
                    visible={this.isFiledropNeeded(SETPOINTS)}
                    uploadState={this.props.uploadState.setpoints}
                    type={SETPOINTS}
                    file={this.state.setpointsFile}
                    encode={this.state.encode.setpoints}
                    registerUpload={this.uploadSetpoints}
                    changeEncode={this.changeEncode}
                >
                    <FormattedMessage
                        id="RPA_SETPOINTS_UPLOAD_SETPOINTS"
                        defaultMessage="Setpoints file"
                        description="Text of setpoints filedrop" />
                </I18NFileUpload>
                <I18NFileUpload
                    visible={this.isFiledropNeeded(SIGNALS)}
                    uploadState={this.props.uploadState.setpoints}
                    type={SIGNALS}
                    file={this.state.signalsFile}
                    encode={this.state.encode.signals}
                    registerUpload={this.uploadSignals}
                    changeEncode={this.changeEncode}
                >
                    <FormattedMessage
                        id="RPA_SETPOINTS_UPLOAD_SIGNALS"
                        defaultMessage="Oscillography file *.CFG COMTRADE"
                        description="Text of oscillography file filedrop" />
                </I18NFileUpload>
            </div>
        );
    }
}



const SELECT_VENDOR = (<FormattedMessage
    id="RPA_SETPOINTS_SELECT_VENDOR"
    defaultMessage="Select vendor..."
    description="Placeholder of vendor selector" />);

const LOADING = (<FormattedMessage
    id="RPA_SETPOINTS_LOADING"
    defaultMessage="Loading..."
    description="Loading text" />);

interface VendorSelectProps {
    intl: IntlShape,
    visible: boolean,
    vendor: string | null,
    changeVendor: (vendor: string) => any
}
interface VendorSelectState {
}
interface VendorOption {
    value: string,
    label: string
}
class VendorSelect extends React.Component<VendorSelectProps, VendorSelectState> {

    private options: VendorOption[] = [
        { value: 'ekra', label: this.props.intl.formatMessage({ id: "RPA_SETPOINTS_VENDOR_EKRA" }) },
        { value: 'siemens', label: this.props.intl.formatMessage({ id: "RPA_SETPOINTS_VENDOR_SIEMENS" }) },
        { value: 'relematika', label: this.props.intl.formatMessage({ id: "RPA_SETPOINTS_VENDOR_RELEMATIKA" }) },
        { value: 'abb', label: this.props.intl.formatMessage({ id: "RPA_SETPOINTS_VENDOR_ABB" }) }
    ];

    private optionByVendor: { [K: string]: VendorOption } = {};

    constructor(props: VendorSelectProps) {
        super(props);

        for (let vendorOption of this.options) {
            this.optionByVendor[vendorOption.value] = vendorOption;
        }

        this.changeVendor = this.changeVendor.bind(this);
    }

    getSelectedOption(): VendorOption | null {
        if (this.props.vendor == null) {
            return null;
        }
        return this.optionByVendor[this.props.vendor] || null;
    }

    changeVendor(option: ValueType<VendorOption,false>) {
        if (!option) {
            return;
        }
        this.props.changeVendor((option as VendorOption).value);
    }

    render() {
        if (!this.props.visible) {
            return null;
        }
        return [
            <FormattedMessage
                id="RPA_SETPOINTS_VENDOR"
                defaultMessage="Vendor:"
                description="Label of vendor selector" />,
            <Select
                className="npt-select"
                name="vendorSelect"
                loadingPlaceholder={LOADING}
                placeholder={SELECT_VENDOR}
                searchable={false}
                clearable={false}
                backspaceRemoves={false}
                value={this.getSelectedOption()}
                options={this.options}
                onChange={this.changeVendor}
            />
        ];
    }
}
 
interface I18NVendorSelectProps extends Omit<VendorSelectProps,"intl">{

} 
const I18NVendorSelect =(props:I18NVendorSelectProps)=>{    
    const intl = useIntl();
    return <VendorSelect {...props} intl={intl} />
} 
// const I18NVendorSelect =()=> injectIntl(VendorSelect);



const dropZoneStyle = {
    style: {
        width: "auto",
        height: "100px",
        borderWidth: 2,
        borderColor: '#666',
        borderStyle: 'dashed',
        borderRadius: 5
    },
    activeStyle: {
        borderStyle: 'solid',
        height: "100px",
        backgroundColor: '#eee'
    },
    rejectStyle: {
        borderStyle: 'solid',
        height: "100px",
        backgroundColor: '#ffdddd'
    }
}

interface FileUploadProps {
    intl: IntlShape,
    visible: boolean,
    type: string,
    file: string | null,
    uploadState: number,
    encode: string,
    children?:any,
    registerUpload: (files: File[]) => any,
    changeEncode: (type: string, value: string) => any
}
interface FileUploadState {
}
class FileUpload extends React.Component<FileUploadProps, FileUploadState> {

    private fileMap: { [fileName: string]: File } = {};

    constructor(props: FileUploadProps) {
        super(props);

        this.onDrop = this.onDrop.bind(this);
        this.changeEncode = this.changeEncode.bind(this);
    }

    onDrop(acceptedFiles: File[]) {
        //Save for preview
        this.fileMap = {};
        for (let file of acceptedFiles) {
            this.fileMap[file.name] = file;
        }
        this.props.registerUpload(acceptedFiles);
    }

    changeEncode(reactEvent: any) {
        this.props.changeEncode(this.props.type, reactEvent.target.value);
    }

    printUploadedFile(fileName: string | null) {
        return (
            <div className={styles.fileuploaded}>
                <span>{fileName}</span><i className='fa fa-fw fa-check'></i>
            </div>
        );
    }

    printEncodeOption(encode: string) {
        const value: string = ENCODE_VALUES[encode];
        const name: string = ENCODE_NAMES[encode];
        return (
            <option value={value}>{name}</option>
        );
    }

    printEncodeSelect() {
        return (
            <select value={this.props.encode} disabled={this.props.uploadState == READY} onChange={this.changeEncode}>
                {this.printEncodeOption("ASCII")}
                {this.printEncodeOption("UTF-8")}
            </select>
        );
    }

    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <div className={styles.filedrop + (this.props.uploadState == READY ? " uploaded" : "")}>
                <div className={styles.filedropLabel}>{this.props.children}</div>
                {this.props.uploadState == READY ?
                    this.printUploadedFile(this.props.file)
                    :
                    <Dropzone multiple={false} onDrop={(acceptedFiles: File[]) => this.onDrop(acceptedFiles)} {...dropZoneStyle}>
                        {(dropzoneState: DropzoneState) =>
                            <div className="p-2" style={dropZoneStyle.style}>
                                <FormattedMessage
                                    id="FILEDROP_DROP_FILE_HERE"
                                    defaultMessage="Try to drop file here, or click to select file to upload"
                                    description="Inform user how to upload file" />
                            </div>
                        }
                    </Dropzone>
                }
                <div className={styles.filedropEncode}>{this.printEncodeSelect()}</div>
            </div>
        );
    }
}

interface I18NFileUploadProps extends Omit<FileUploadProps,"intl">{

} 
const I18NFileUpload =(props: I18NFileUploadProps)=>{    
    const intl = useIntl();
    return <FileUpload {...props} intl={intl} />
} 
// const I18NFileUpload = injectIntl(FileUpload);