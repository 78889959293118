import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import shortid from 'shortid';
import { getChildrenLinks } from '../../services/navigation';
import { CustomNavDropdown } from '../menu/CustomNavDropdown';
import { OverflowDropDown } from '../menu/OverflowDropDown';
import { isAnyItemWithIconExists } from './Navigation';


interface NavigationGroupProps {
    label: string
    allowedLinks: { [k: string]: boolean }
    inset?: boolean
    className?: string
    icon?: string
    isIconsExists: boolean
    children: any
}
export default class NavigationGroup extends React.PureComponent<NavigationGroupProps> {

    links: string[]

    constructor(props: NavigationGroupProps) {
        super(props);
        this.links = getChildrenLinks(this);
    }


    render() {
        let hidden = true
        for (let link of this.links) {
            if (this.props.allowedLinks[link]) {
                hidden = false
                break
            }
        }
        if(hidden){
            return null;
        }
        const { allowedLinks, label, icon, inset, children, className, isIconsExists } = this.props
        if (inset) {
            return <InsetGroup isIconsExists={isIconsExists} label={label} icon={icon} className={className} allowedLinks={allowedLinks}>
                {children}
            </InsetGroup>
        }
        return <Group isIconsExists={isIconsExists} label={label} icon={icon} allowedLinks={allowedLinks} className={className}>
            {children}
        </Group>
    }
}

interface InsetGroupProps {
    label: string
    isIconsExists?: boolean
    className?: string
    allowedLinks: { [k: string]: boolean }
    icon?: string
}
class InsetGroup extends React.PureComponent<InsetGroupProps> {
    renderIcon() {
        const { isIconsExists } = this.props
        if (isIconsExists && !this.props.icon) {
            return <i className={`fa fa-fw fa-map mt-1 mx-2 d-flex align-items-start`} style={{ opacity: 0 }} aria-hidden="true"></i>
        }

        if (this.props.icon) {
            return <i className={`fa fa-fw ${this.props.icon} mt-1 mx-2 d-flex align-items-start`} aria-hidden="true"></i>
        }

        return null
    }
    renderTitle() {
        return (
            <div className="d-flex   flex-fill">
                {this.renderIcon()}
                <div className="strong d-flex flex-fill align-items-left">{this.props.label}</div>
            </div>
        )
    }
    render() {

        const isIconsExists = isAnyItemWithIconExists(this.props.children)
        return (
            <OverflowDropDown direction="right" className={`${this.props.className} pl-1`} title={this.renderTitle()} id={`navigation-${shortid.generate()}`} key="dropdownMenu">
                {React.Children.map(
                    this.props.children,
                    (child, idx) => React.cloneElement(child as any, { key: idx, inset: true, isIconsExists, allowedLinks: this.props.allowedLinks })
                )}
            </OverflowDropDown>
        )
    }
};

interface GroupProps {
    label: string
    isIconsExists?: boolean
    className?: string
    allowedLinks: { [k: string]: boolean }
    icon?: string
}
class Group extends React.PureComponent<GroupProps> {
    renderIcon() {
        const { isIconsExists } = this.props
        if (isIconsExists && !this.props.icon) {
            return <i className={`fa fa-fw fa-map mt-1 mx-2 d-flex align-items-start`} style={{ opacity: 0 }} aria-hidden="true"></i>
        }

        if (this.props.icon) {
            return <i className={`fa fa-fw ${this.props.icon} mt-1 mx-2 d-flex align-items-start`} aria-hidden="true"></i>
        }

        return null
    }

    renderTitle() {
        const { className } = this.props
        return (
            <div style={{ color: 'inherit' }} className={`d-flex   flex-fill  `}>
                {this.renderIcon()}
                <div className="strong d-flex flex-fill align-items-left">{this.props.label}</div>
            </div>
        )
    }
    render() {
        const isIconsExists = isAnyItemWithIconExists(this.props.children)
        return (
            <OverflowDropDown direction="right" className={`${this.props.className} pl-1`} title={this.renderTitle()} id={`navigation-${shortid.generate()}`} key="dropdownMenu">
                {React.Children.map(
                    this.props.children,
                    (child, idx) => React.cloneElement(child as any, { key: idx, inset: true, isIconsExists, allowedLinks: this.props.allowedLinks })
                )}
            </OverflowDropDown>
        )
    }
};


