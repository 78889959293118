import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { isLoginPageInfo, LoginRequestDetails, LoginStatus } from '../../types/security';
import { FetchError, isFetchError } from '../../types/error';
import { ApplicationState } from '../../types/index.js';
import { login, info, sendCodeRequired } from '../../actions/security';

import LoginForm from './LoginForm';

export interface LoginPageProps {
    variant?: string
    contextPath: string,
    loading: boolean,
    logoutFinished: boolean,
    status?: LoginStatus | FetchError,
    login: (username: string, password: string, details: LoginRequestDetails) => void,
    requestStatus: () => void
    requestCode: () => void
}

export interface LoginWaiterProps {
    error: FetchError
    loading: boolean
    maxCounter: number
    requestStatus: () => void
}

export interface LoginWaiterState {
    counter: number
}

class LoginWaiter extends React.Component<LoginWaiterProps, LoginWaiterState> {

    private timerId: number | undefined

    constructor(props: LoginWaiterProps) {
        super(props);
        this.state = {
            counter: 0
        }
    }

    componentDidMount() {
        const _this = this;
        this.timerId = window.setInterval(() => {
            if (!_this.props.loading) {
                const counter = _this.state.counter;
                if (counter < _this.props.maxCounter) {
                    _this.setState({ counter: counter + 1 })
                } else {
                    _this.setState({ counter: 0 })
                    _this.props.requestStatus();
                    window.location.reload();
                }
            } else {
                _this.setState({ counter: 0 })
            }
        }, 1000)
    }

    componentWillUnmount() {
        window.clearInterval(this.timerId);
    }

    render() {
        if (this.props.loading) {
            return <div></div>
        }
        return <div className="alert alert-danger" role="alert" style={{ marginBottom: "0px" }}>
            <FontAwesomeIcon icon={faSpinner} spin size="5x" />
            {this.props.maxCounter - this.state.counter}
        </div>;
    }
}

class LoginPage extends React.Component<LoginPageProps> {

    constructor(props: LoginPageProps) {
        super(props);
    }

    componentDidMount() {
        //Normally it is on developer computers using npm start
        if (typeof this.props.status == 'undefined') {
            this.props.requestStatus();
        }
    }

    render() { 
        return <div className="d-flex flex-column w-100 h-100 justify-content-center bg-light npt-login-page">
            <div className="card align-self-center npt-login-card">
                {isFetchError(this.props.status) &&
                    <LoginWaiter maxCounter={10}
                        loading={this.props.loading}
                        requestStatus={this.props.requestStatus}
                        error={this.props.status} />}
                {isLoginPageInfo(this.props.status) &&
                    <LoginForm {...this.props.status}
                        variant={this.props.variant}
                        login={this.props.login}
                        requestCode={this.props.requestCode}
                        loading={this.props.loading}
                        logoutFinished={this.props.logoutFinished}
                        contextPath={this.props.contextPath} />}
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            contextPath: "/",
            loading: state.security.loading || false,
            logoutFinished: state.security.logoutFinished || false,
            status: state.security.loginStatus,
        }
    },
    (dispatch) => {
        return {
            requestStatus: () => {
                //thunk actions are not actions because they do not have type field.
                //So we need this ugly hack
                dispatch(info() as any as AnyAction);
            },
            requestCode: () => {
                //thunk actions are not actions because they do not have type field.
                //So we need this ugly hack
                dispatch(sendCodeRequired() as any as AnyAction);
            },
            login: (username: string, password: string, details: LoginRequestDetails) => {
                //thunk actions are not actions because they do not have type field.
                //So we need this ugly hack
                dispatch(login(username, password, details) as any as AnyAction);
            }
        }
    }
)(LoginPage);