import React, { CSSProperties } from "react";
import {
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Autocomplete.module.css";
import ReactDOM from "react-dom";
interface AutocompleteDropdownProps {
  style?: CSSProperties;
  list: string[] | object[] | null;
  parentClass: string;
  error?: string | null;
  loading: boolean;
  onItemClick: (text: string | object) => void;
  onClose: () => void;
  setRef?: (ref: any) => void;
}
interface AutocompleteDropdownState {
  active: number | null;
}
export class AutocompleteDropdown extends React.Component<
  AutocompleteDropdownProps,
  AutocompleteDropdownState
> {
  private scroll: number = 0;
  private containerRef: HTMLDivElement | null = null;
  private element: HTMLDivElement | null = null;
  constructor(props: AutocompleteDropdownProps) {
    super(props);
    const list = props.list;
    let active = null;
    if (list && list?.length > 0) {
      active = 0;
    }
    this.state = {
      active: active,
    };
    this.element = document.createElement("div");
  }
  componentDidUpdate(
    prevProps: Readonly<AutocompleteDropdownProps>,
    prevState: Readonly<AutocompleteDropdownState>,
    snapshot?: any
  ): void {
    const { list } = this.props;
    const { active } = this.state;
    if (list !== prevProps.list) {
      this.setState({ active: 0 });
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown);
    this.scroll = 0;
    if (this.element) {
      document.body.appendChild(this.element);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
    if (this.element) {
      document.body.removeChild(this.element);
    }
  }
  getNextIndex = (dir: "up" | "down") => {
    const { list } = this.props;

    const { active } = this.state;
    if (active == null || !list) {
      return null;
    }
    let newActive = dir == "down" ? active + 1 : active - 1;
    const min = 0;
    const max = list.length - 1;
    if (newActive < min) {
      newActive = max;
    } else if (newActive > max) {
      newActive = min;
    }
    return newActive;
  };
  scrollContainer = (idx: number, container: HTMLDivElement | null) => {
    const { list } = this.props;

    if (!container) {
      return null;
    }
    const children = container.children;
    const current = children[idx];

    const rect = current.getBoundingClientRect();

    const itemTop = rect.top;
    const itemBottom = rect.bottom;

    const containerRect = container.getBoundingClientRect();
    const containerTop = containerRect.top;
    const containerScrollHeight = container.scrollHeight;
    const containerBottom = containerRect.bottom;
    const containerScrollTop = container.scrollTop;
    if (idx === 0) {
      this.setState({ active: idx }, () => {
        container.scrollTo({
          top: 0,
        });
      });
    } else if (idx === children.length - 1) {
      this.setState({ active: idx }, () => {
        container.scrollTo({
          top: containerScrollHeight - 130,
        });
      });
    } else if (
      itemBottom > containerBottom &&
      itemBottom - containerBottom < rect.height
    ) {
      this.setState({ active: idx }, () => {
        container.scrollTo({
          top: containerScrollTop + rect.height,
          behavior: "smooth",
        });
      });
    } else if (
      itemTop < containerTop &&
      containerBottom - itemBottom > rect.height
    ) {
      container.scrollTo({
        top: containerScrollTop - rect.height,
        behavior: "smooth",
      });
    } else if (itemTop > containerTop && itemBottom < containerBottom) {
      this.setState({ active: idx });
    }
  };

  handleKeydown = (e: any) => {
    var event = window.event ? window.event : e;
    const container = this.containerRef;
    //up
    let nextIdx: number | null = null;
    if (event.keyCode === 38) {
      nextIdx = this.getNextIndex("up");
    }
    //down
    if (event.keyCode === 40) {
      nextIdx = this.getNextIndex("down");
    }
    //enter
    if (event.keyCode === 13) {
      this.pickItemOnEnter();
    }
    if (nextIdx !== null) {
      this.scrollContainer(nextIdx, container);
      // this.setState({ active: nextIdx }, () => {
      //   nextIdx !== null && this.scrollContainer(nextIdx, container);
      // });
    }
  };

  pickItemOnEnter = () => {
    const { list } = this.props;
    if (!list) {
      return;
    }
    const { active } = this.state;
    if (active == null) {
      return;
    }
    const item = list[active];
    this.clickHandler(item);
  };

  clickHandler = (item: string | object) => {
    const { onItemClick } = this.props;
    onItemClick(item);
  };
  renderContent = () => {
    const { active } = this.state;
    const { loading, list, onItemClick, error, onClose } = this.props;

    if (error) {
      return (
        <div
          onClick={onClose}
          className={`w-100 d-flex justify-content-center p-1`}
        >
          <div className="text-danger">
            <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
            <span>Ошибка</span>
          </div>
        </div>
      );
    }
    if (loading) {
      return (
        <div
          onClick={onClose}
          className={`w-100 d-flex justify-content-center p-1`}
        >
          <div>
            <span className="mr-2">Загрузка</span>
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        </div>
      );
    }
    if (!list) {
      return null;
    }
    if (!list.length) {
      return (
        <div
          onClick={onClose}
          className={`w-100 d-flex justify-content-center p-1`}
        >
          <div>
            <span>Нет данных</span>
          </div>
        </div>
      );
    }

    return list.map((item, idx) => {
      const activeClass = active === idx ? styles.itemActive : "";
      const strValue =
        typeof item === "string" ? item : (item as any).value || "";
      return (
        <div
          tabIndex={idx}
          className={`${styles.item} ${activeClass}   p-1`}
          onMouseDown={(e) => this.clickHandler(item)}
        >
          {strValue}
        </div>
      );
    });
  };
  initRef = (ref: HTMLDivElement | null) => {
    const { setRef } = this.props;
    if (ref) {
      this.containerRef = ref;
      if (setRef) {
        setRef(ref);
      }
    }
  };
  renderDropdown = () => {
    const { style } = this.props;
    return (
      <div
        style={style}
        ref={(ref) => this.initRef(ref)}
        // ref={this.containerRef}
        className={`${styles.autocomplete} autocompete-dd position-fixed bg-white  overflow-auto`}
      >
        {this.renderContent()}
      </div>
    );
  };
  render() {
    if (!this.element) {
      return null;
    }
    return ReactDOM.createPortal(this.renderDropdown(), this.element);
  }
}
