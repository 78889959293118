import * as React from 'react';
import { connect } from 'react-redux';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { ApplicationState } from '../../../types';
import { ModalInfo, ModalStatus, TableModalOptions } from '../../../types/modal';
import { Column } from '../../../types/table';

import DndOptions from '../../common/DndOptions';
import Select from '../../common/Select';
import ModalView from '../ModalView';

interface Option {
    value: string
    label: string
}

interface ColumnOption extends Option {
    removable?: boolean
    hidden?: boolean
    dynamicHidden?: boolean
}

interface TableDynamicColumnsOptionsModalProps {
    modal: ModalInfo
    columns: Column[]
    selectedColumns: string[] | null
    hiddenColumns: { [k: string]: boolean }
    closeModal: (status: ModalStatus, result: { selectedColumns: string[], hiddenColumns: { [k: string]: boolean } } | null) => void
}
interface TableDynamicColumnsOptionsModalState {
    selectedColumns: string[]
    hiddenColumns: { [k: string]: boolean }
}

class TableDynamicColumnsOptionsModal extends React.Component<TableDynamicColumnsOptionsModalProps, TableDynamicColumnsOptionsModalState> {

    constructor(props: TableDynamicColumnsOptionsModalProps) {
        super(props);

        this.state = {
            selectedColumns: this.getSelectedColumns(props.columns, props.selectedColumns),
            hiddenColumns: props.hiddenColumns
        }

        this.closeModal = this.closeModal.bind(this);
    }

    getSelectedColumns(columns: Column[], propsSelectedColumns: string[] | null): string[] {
        if (propsSelectedColumns) {
            return propsSelectedColumns;
        }
        const selectedColumns: string[] = [];
        for (let column of columns) {
            if (column.dynamic && column.hidden) {
                continue;
            }
            selectedColumns.push(column.field);
        }
        return selectedColumns;
    }

    renderTemplate(): React.ReactElement {
        const changeSelectedColumns = (filter: { selected?: string[], hidden?: { [k: string]: boolean } }) => {
            this.setState(Object.assign({}, this.state, {
                selectedColumns: filter.selected || this.state.selectedColumns,
                hiddenColumns: filter.hidden || this.state.hiddenColumns
            }));
        }
        return <TableDynamicColumnsBody
            columns={this.props.columns}
            selectedColumns={this.state.selectedColumns}
            hiddenColumns={this.state.hiddenColumns}
            changeSelectedColumns={changeSelectedColumns}
        />;
    }

    closeModal(status: ModalStatus, result: any) {
        if (status == MODAL_STATUS_OK) {
            this.props.closeModal(status, { selectedColumns: this.state.selectedColumns, hiddenColumns: this.state.hiddenColumns });
        } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
            this.props.closeModal(status, null);
        }
    }

    componentDidUpdate(prevProps: TableDynamicColumnsOptionsModalProps) {
        if (this.props.columns !== prevProps.columns || this.props.selectedColumns !== prevProps.selectedColumns) {
            this.setState(Object.assign({}, { ...this.state }, {
                selection: this.getSelectedColumns(this.props.columns, this.props.selectedColumns)
            }));
        }
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { ...modal.options, title: { id: "NPT_TABLE_DYNAMIC_COLUMNS_MODAL_TITLE" } };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}

interface TableDynamicColumnsBodyProps {
    selectedColumns: string[]
    hiddenColumns: { [k: string]: boolean }
    columns: Column[]
    changeSelectedColumns: (filter: { selected?: string[], hidden?: { [k: string]: boolean } }) => void
}
const TableDynamicColumnsBody: React.FunctionComponent<TableDynamicColumnsBodyProps> = React.memo((props) => {
    const selection: Option[] = [];
    let selectedOptions: ColumnOption[] = [];
    for (let column of props.columns) {
        if (props.selectedColumns.indexOf(column.field) !== -1) {
            selectedOptions.push({
                value: column.field,
                label: column.name,
                removable: Boolean(column.dynamic),
                hidden: !column.dynamic && column.hidden,
                dynamicHidden: props.hiddenColumns[column.field],
            });
            continue;
        }
        selection.push({
            value: column.field,
            label: column.name
        });
    }
    selectedOptions = selectedOptions.sort((optionA, optionB) => props.selectedColumns.indexOf(optionA.value) - props.selectedColumns.indexOf(optionB.value));
    const changeSelectedColumns = (columns: ColumnOption[]) => {
        let selectedColumns: string[] = [];
        let hiddenColumns: { [k: string]: boolean } = {};
        for (let column of columns) {
            selectedColumns.push(column.value);
            if (column.dynamicHidden) {
                hiddenColumns[column.value] = true;
            }
        }
        props.changeSelectedColumns({
            selected: selectedColumns,
            hidden: hiddenColumns
        });
    }
    const addOption = (option?: Option | null) => {
        if (!option) {
            return;
        }
        const newSelectedColumns = selectedOptions.map((column) => column.value);
        newSelectedColumns.push(option.value);
        props.changeSelectedColumns({ selected: newSelectedColumns });
    }
    return <>
        {selection.length !== 0 && <div className="w-100 d-flex flex-column" style={{ marginBottom: 10 }}>
            <Select
                className="npt-select"
                value={null}
                isClearable
                isSearchable={true}
                options={selection}
                onChange={addOption}
            />
        </div>}
        <DndOptions
            type="tableDynamicColumnsOptionsModal"
            options={selectedOptions}
            onChange={changeSelectedColumns}
            showHidden={false}
            dynamicHidden={true}
        />
    </>
});

export default connect((state: ApplicationState, ownProps: { modal: ModalInfo }) => {
    const tableState = state.table[(ownProps.modal.options as TableModalOptions).tableId];
    return {
        columns: tableState.columns,
        selectedColumns: tableState.dynamicColumns,
        hiddenColumns: tableState.dynamicHiddenColumns
    }
})(TableDynamicColumnsOptionsModal)