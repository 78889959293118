import {
  faArrowCircleLeft,
  faCaretLeft,
  faCaretRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import * as React from "react";
import Arrow from "./Arrow";
import styles from "./Calendar.module.css";
import CalendarCell from "./CalendarCell";
import Select, { ValueType } from "react-select";
import calendar, {
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  isDate,
  isSameDay,
  isSameMonth,
} from "./helpers/calendar";
import {
  MSG_SELECT_LOADING,
  MSG_SELECT_PLACEHOLDER,
  MSG_SELECT_NO_RESULTS,
} from "../messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { YearSelector } from "./YearSelector";
export interface SelectOption {
  value: number;
  label: string;
}
interface CalendarProps {
  dateFormat: string;
  date?: Date;
  lang: string;
  closeCalendar: () => void;
  onDateChanged: (stringisoDate: string) => void;
  // timeSelector?: any
}
interface CalendarStates {
  current: Date | null;
  today: Date;
  month: number;
  year: number;
  showMonth: boolean;
  showYear: boolean;
}
const updateState = (date?: Date) => {
  const _date = date || new Date();
  return {
    current: date || null,
    month: +_date.getMonth() + 1,
    year: _date.getFullYear(),
  };
};
export default class Calendar extends React.Component<
  CalendarProps,
  CalendarStates
> {
  constructor(props: CalendarProps) {
    super(props);
    this.state = {
      ...this.resolveStateFromProp(),
      today: new Date(),
      showMonth: false,
      showYear: false,
    };
    console.log("calendar____", this.props.date);
  }

  //////////////UTIL METHODS////////////////////////
  static getDerivedStateFromProps(
    nextProps: CalendarProps,
    prevState: CalendarStates
  ) {
    if (nextProps.date !== prevState.current) {
      return updateState(nextProps.date);
    }
    return prevState;
  }

  resolveStateFromProp() {
    return updateState(this.props.date);
  }

  getCalendarDates = () => {
    const { current, month, year } = this.state;
    const { lang } = this.props;
    // if(!!current){
    // const calendarMonth = month || +current.getMonth() + 1;
    // const calendarYear = year || current.getFullYear();
    const calendarMonth = month || +new Date().getMonth() + 1;
    const calendarYear = year || new Date().getFullYear();
    return calendar(calendarMonth, calendarYear, lang);
    // }
    // return [];
  };

  changeDate = (isoDate: string) => {
    const date = new Date(isoDate);
    const { onDateChanged } = this.props;
    const { current } = this.state;
    !(current && isSameDay(date, current)) &&
      this.setState(updateState(date), () => {
        onDateChanged(moment(date).format(this.props.dateFormat));
        this.props.closeCalendar();
      });
  };

  getWeekDays() {
    const { lang } = this.props;
    const weekDays = moment.weekdaysShort();
    //As moment.js returns weekdays starting from sunday for russian locale
    //Relocate sunday to the end of list
    if (lang === "ru") {
      const sunday = weekDays[0];
      const newWeekDays = weekDays.slice(1, weekDays.length);
      newWeekDays.push(sunday);
      return newWeekDays;
    }
    return weekDays;
  }
  ///////////////END OF UTIL METHODS////////////////////////

  /////////////EVENT  HANDLERS///////////////////
  handlePrevious = (evt: any) => {
    evt && evt.preventDefault();
    const fn = evt.shiftKey ? this.gotoPreviousYear : this.gotoPreviousMonth;
    fn();
    // this.handlePressure(fn);
  };

  handleNext = (evt: any) => {
    evt && evt.preventDefault();
    const fn = evt.shiftKey ? this.gotoNextYear : this.gotoNextMonth;
    fn();
    // this.handlePressure(fn);
  };
  /////////////END OF EVENT  HANDLERS////////////

  isArrowVisible() {
    const { month, year, showMonth, showYear } = this.state;
    return !showMonth && !showYear;
  }
  changeYearHandler = (year: number) => {
    const { onDateChanged, date } = this.props;
    // const year = (option as SelectOption).value;
    this.setState(
      {
        year,
        showMonth: false,
        showYear: false,
      },
      () => {
        const newDate: Date = date || new Date();
        newDate.setFullYear(year);
        onDateChanged(moment(newDate).format(this.props.dateFormat));
      }
    );
  };
  changeMonthHandler = (option: ValueType<SelectOption, false>) => {
    const { onDateChanged, date } = this.props;
    const month = (option as SelectOption).value;
    this.setState(
      {
        month: (option as SelectOption).value,
        showMonth: false,
        showYear: false,
      },
      () => {
        const newDate: Date = date || new Date();
        newDate.setMonth(month - 1);
        onDateChanged(moment(newDate).format(this.props.dateFormat));
      }
    );
  };

  gotoPreviousMonth = () => {
    const { month, year } = this.state;
    this.setState(getPreviousMonth(month, year));
  };

  gotoNextMonth = () => {
    const { month, year } = this.state;
    this.setState(getNextMonth(month, year));
  };

  gotoPreviousYear = () => {
    const { year } = this.state;
    this.setState({ year: year - 1 });
  };

  gotoNextYear = () => {
    const { year } = this.state;
    this.setState({ year: year + 1 });
  };

  //////////////RENDER METHODS///////////////////
  // Render the month and year header with arrow controls
  // for navigating through months and years
  renderMonthAndYear = () => {
    const { month, year, showMonth, showYear } = this.state;
    const { lang, closeCalendar } = this.props;

    return (
      <CalendarHeader>
        {/* {this.isArrowVisible() && <ArrowLeft
          onMouseDown={this.handlePrevious}
          onMouseUp={() => { }}
          title="Previous Month"
        />} */}
        <div
          style={{ right: "8px", top: "3px", cursor: "pointer" }}
          onClick={closeCalendar}
          className="text-white position-absolute"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <CalendarMonth
          year={year}
          month={month}
          showMonths={showMonth}
          showYears={showYear}
          setShowMonth={(show: boolean) => this.setState({ showMonth: show })}
          setShowYear={(show: boolean) => this.setState({ showYear: show })}
          changeYear={this.changeYearHandler}
          changeMonth={this.changeMonthHandler}
        />

        {/* {this.isArrowVisible() &&<ArrowRight
          onMouseDown={this.handleNext}
          onMouseUp={() => { }}
          title="Next Month"
        />} */}
      </CalendarHeader>
    );
  };

  // Render the label for day of the week
  // This method is used as a map callback as seen in render()
  renderDayLabel = (day: string, index: number) => {
    // Resolve the day of the week label from the WEEK_DAYS object map
    const daylabel = day.toUpperCase();

    return (
      <CalendarCell className={styles.calendarDay} key={daylabel}>
        {daylabel}
      </CalendarCell>
    );
  };

  renderDate(day: any, key: any) {
    return (
      <CalendarCell
        onClick={this.changeDate.bind(this, key)}
        key={key}
        className={styles.calendarDate}
      >
        {day}
      </CalendarCell>
    );
  }

  renderHighlightedDate(day: any, key: any) {
    return (
      <CalendarCell key={key} className={styles.highlitedCalendarDate}>
        {day}
      </CalendarCell>
    );
  }

  renderTodayDate(day: any, key: any) {
    return (
      <CalendarCell
        onClick={this.changeDate.bind(this, key)}
        key={key}
        className={styles.todayCalendarDate}
      >
        {day}
      </CalendarCell>
    );
  }

  // Render a calendar date as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  renderCalendarDate = (date: any[], index: number) => {
    const { current, month, year, today } = this.state;
    const _date = new Date(date.join("-"));

    // Check if calendar date is same day as today
    const isToday = isSameDay(_date, today);

    // Check if calendar date is same day as currently selected date
    const isCurrent = (current && isSameDay(_date, current)) || false;

    // Check if calendar date is in the same month as the state month and year
    const inMonth =
      month && year && isSameMonth(_date, new Date([year, month, 1].join("-")));

    // The click handler
    // console.log('isCurrent,current',isCurrent,current)
    const isoDate = getDateISO(_date);
    // Conditionally render a styled date component
    const DateComponent = isCurrent
      ? this.renderHighlightedDate(_date.getDate(), isoDate)
      : isToday /*&& isSameDay(current, today)*/
      ? this.renderTodayDate(_date.getDate(), isoDate)
      : this.renderDate(_date.getDate(), isoDate);

    return !!isoDate && DateComponent;
  };

  render() {
    const weekDays = this.getWeekDays();
    return (
      <div className="d-flex flex-column overflow-auto">
        {this.renderMonthAndYear()}
        <div className="d-flex flex-fill overflow-auto">
          <CalendarGrid>
            <>{weekDays.map(this.renderDayLabel)}</>
            <>{this.getCalendarDates().map(this.renderCalendarDate)}</>
          </CalendarGrid>
        </div>
      </div>
    );
  }
}

class CalendarHeader extends React.Component {
  render() {
    return <div className={styles.calendarHeader}>{this.props.children}</div>;
  }
}

interface CalendarMonthProps {
  year: number;
  month: number;
  showMonths: boolean;
  showYears: boolean;
  changeMonth: (month: ValueType<SelectOption, false>) => void;
  changeYear: (year: number) => void;
  setShowMonth: (show: boolean) => void;
  setShowYear: (show: boolean) => void;
}
interface CalendarMonthState {}
class CalendarMonth extends React.Component<
  CalendarMonthProps,
  CalendarMonthState
> {
  years: SelectOption[] = [];
  months: SelectOption[] = [];
  private yearRef: React.RefObject<any> = React.createRef();
  private monthRef: React.RefObject<any> = React.createRef();
  constructor(props: CalendarMonthProps) {
    super(props);

    for (let i = 1900; i < 2100; i++) {
      this.years.push({ label: `${i}`, value: i });
    }

    this.months = moment
      .months()
      .map((m: string, idx: number) => ({ label: m, value: idx + 1 }));
  }
  clickControlHandler = () => {};
  componentDidMount() {
    // document.addEventListener('click',clickControlHandler)
  }
  getMonthname = (month: number) => {
    const months = moment.months();

    const monthname = months[Math.max(0, Math.min(month - 1, 11))];
    return monthname;
  };

  onMenuOpen = (ref: React.RefObject<any>, prefix: string) => () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        `${prefix}__option--is-selected`
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ block: "nearest", inline: "start" });
      }
    }, 15);
  };

  // printYear() {
  //   const { year, changeYear } = this.props;
  //   const prefix = "CalendarYear";

  //   const selectedValue: SelectOption = { label: `${year}`, value: year };
  //   return (
  //     <Select
  //       ref={this.yearRef}
  //       // styles={{ option: (styles, { data, isDisabled, isFocused, isSelected }) => { return { ...styles, color: isSelected ? 'white' : 'black', cursor: 'pointer' } } }}
  //       searchable="true"
  //       loadingPlaceholder={MSG_SELECT_LOADING}
  //       placeholder={MSG_SELECT_PLACEHOLDER}
  //       noResultsText={MSG_SELECT_NO_RESULTS}
  //       menuShouldScrollIntoView={true}
  //       simpleValue={true}
  //       onMenuOpen={this.onMenuOpen(this.yearRef, prefix)}
  //       classNamePrefix={prefix}
  //       maxMenuHeight={200}
  //       value={selectedValue}
  //       onChange={changeYear}
  //       className="minified-react-select card-input npt-select flex-fill"
  //       options={this.years}
  //     ></Select>
  //   );
  // }
  printMonth() {
    const { month, changeMonth } = this.props;
    const monthname = this.getMonthname(month);
    const prefix = "CalendarMonth";
    const selectedValue: SelectOption = { label: `${monthname}`, value: month };
    return (
      <Select
        ref={this.monthRef}
        searchable="true"
        //  styles={{ option: (styles, { data, isDisabled, isFocused, isSelected }) => { return { ...styles,fontWeight:'normal', color: isSelected ? 'white' : 'black', cursor: 'pointer' } } }}
        loadingPlaceholder={MSG_SELECT_LOADING}
        placeholder={MSG_SELECT_PLACEHOLDER}
        noResultsText={MSG_SELECT_NO_RESULTS}
        menuShouldScrollIntoView={true}
        onMenuOpen={this.onMenuOpen(this.yearRef, prefix)}
        classNamePrefix={prefix}
        simpleValue={true}
        maxMenuHeight={200}
        value={selectedValue}
        onChange={changeMonth}
        className="minified-react-select card-input npt-select flex-fill"
        options={this.months}
      ></Select>
    );
  }
  printBack() {
    const { setShowMonth, setShowYear } = this.props;
    const controlReset = () => {
      setShowMonth(false);
      setShowYear(false);
    };
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => controlReset()}
        className="d-flex justify-content-center align-items-center "
      >
        <div className="p-1 ">
          <FontAwesomeIcon className="text-white" icon={faArrowCircleLeft} />
        </div>
      </div>
    );
  }

  printContent() {
    const {
      month,
      year,
      showYears,
      showMonths,
      setShowMonth,
      setShowYear,
      changeYear,
    } = this.props;
    const months = moment.months();

    const monthname = months[Math.max(0, Math.min(month - 1, 11))];
    if (!showYears && !showMonths) {
      return (
        <div className="d-flex  ">
          <div
            style={{ cursor: "pointer" }}
            id="month"
            onClick={() => setShowMonth(true)}
            className="mr-1"
          >
            {monthname}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShowYear(true)}
            id="year"
          >
            {year}
          </div>
        </div>
      );
    }
    if (showYears) {
      return (
        <div
          style={{ fontWeight: "normal", color: "black" }}
          className="d-flex flex-grow-1 px-2 position-relative"
        >
          {this.printBack()}
          <YearSelector year={year} changeYear={changeYear as any} />
          {/* {this.printYear()} */}
        </div>
      );
    } else {
      return (
        <div
          style={{ fontWeight: "normal", color: "black" }}
          className="d-flex flex-grow-1 px-2 position-relative"
        >
          {this.printBack()}
          {this.printMonth()}
        </div>
      );
    }
  }
  render() {
    return (
      <div className="d-flex flex-grow-1 justify-content-center">
        {this.printContent()}
      </div>
    );
  }
}
class CalendarGrid extends React.Component {
  render() {
    return <div className={styles.calendarGrid}>{this.props.children}</div>;
  }
}
