import { Reducer } from 'redux';
import { AnyAction } from 'redux';
import * as constants from '../constants/theme';
import { ApplicationAction } from '../types';
import { ThemeAction, ThemeState } from '../types/theme';


const reducer: Reducer<ThemeState, ApplicationAction> = (state: ThemeState = { dark: false }, action: ApplicationAction): ThemeState => {
    switch (action.type) {
        case constants.SEND_THEME_TOGGLE: return { ...state, dark: action.payload }

    }
    return state
}

export default reducer;