import { Reducer } from 'redux';

import { ModalState } from '../types/modal';
import * as constants from '../constants/modal';
import { ApplicationAction } from '../types';


const reducer: Reducer<ModalState, ApplicationAction> = (state: ModalState = {modalList: []}, action: ApplicationAction): ModalState => {
    switch (action.type) {
        case constants.OPEN_MODAL: {
            const modalList = [...state.modalList, action.payload];
            return { ...state, modalList };
        }
        case constants.UPDATE_MODAL: {
            const modalList = state.modalList.map((m) => {
                if (m.id != action.payload.id) {
                    return m;
                }
                return {...m, options: action.payload.options };
            })
            return { ...state, modalList };
        }
        case constants.UPDATE_MODAL_TITLE: {
            const modalList = state.modalList.map((m) => {
                if (m.id != action.payload.id) {
                    return m;
                }
                const options = {...m.options}
                options.title = action.payload.title
                return {...m, options };
            })
            return { ...state, modalList };
        }
        case constants.CLOSE_MODAL: { 
            const modalList = state.modalList.filter((m) => m.id != action.payload.id)
            return { ...state, modalList };
        }

            
    }
    return state;
}

export default reducer;