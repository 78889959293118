import * as React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { BooleanFilterData, ColumnFilterInfo, DateFilterData, DateTimeFilterData, FilterData, FilterInfo, FilterRange, FilterSelection, FilterType, NumberFilterData, StringFilterData } from '../../../types/table';
import { bindEvent, unbindEvent } from '../../../services/event';
import { isColumnFilterActive } from '../../../services/table';

import StringFilter from './StringFilter';
import NumberFilter from './NumberFilter';
import DateFilter from './DateFilter';
import DateTimeFilter from './DateTimeFilter';
import BooleanFilter from './BooleanFilter';

import styles from '../Table.module.css';

const MIN_WIDTH = 350;

function getFilterWidth(width: number) {
    if (width > MIN_WIDTH) {
        return width;
    }
    return MIN_WIDTH;
}

/** Header common cell */
interface HeaderFilterProps {
    field: string
    filterInfo: FilterInfo
    filterType: FilterType | null
    headerRef: React.MutableRefObject<HTMLDivElement | null>
    filterByColumn: (field: string, filterData: FilterData | null) => void
    fetchColumnSelection: (field: string, filterData: FilterData | null) => void
    fetchColumnRange: (field: string, filterData: FilterData | null) => void
}
const HeaderFilter: React.FunctionComponent<HeaderFilterProps> = React.memo((props: HeaderFilterProps) => {
    const [openedFilter, setOpenedFilter] = React.useState(false);
    const openFilter = React.useMemo(() => setOpenedFilter.bind(null, true), []);
    const closeFilter = React.useMemo(() => setOpenedFilter.bind(null, false), []);
    const applyFilter = React.useMemo(() => (data: FilterData | null) => {
        setOpenedFilter(false);
        props.filterByColumn(props.field, data);
    }, [props.field])
    const filterInfo = props.filterInfo.columns.find((columnFilterInfo) => props.field === columnFilterInfo.field);
    const isFilterActive = isColumnFilterActive(filterInfo);
    return <>
        <HeaderFilterItem
            filterType={props.filterType}
            active={isFilterActive}
            openTableFilter={openFilter}
        />
        <HeaderFilterDropdown
            field={props.field}
            cellRef={props.headerRef}
            filterActive={isFilterActive}
            filterType={props.filterType}
            filterInfo={filterInfo}
            filterSelectionInfo={props.filterInfo.selectionByField[props.field]}
            filterRangeInfo={props.filterInfo.rangeByField[props.field]}
            visible={openedFilter}
            closeFilter={closeFilter}
            applyFilter={applyFilter}
            fetchColumnSelection={props.fetchColumnSelection}
            fetchColumnRange={props.fetchColumnRange}
        />
    </>;
});


/** Filter item that shows filter dropdown on click */
interface HeaderFilterItemProps {
    filterType: FilterType | null
    active: boolean
    hashFilter?: boolean
    openTableFilter: (filterType: FilterType) => void
}
const HeaderFilterItem: React.FunctionComponent<HeaderFilterItemProps> = React.memo((props: HeaderFilterItemProps) => {
    const [filterType, setFilterType] = React.useState<FilterType | null>(null);
    React.useEffect(() => {
        let filterType: FilterType | null = null;
        if (typeof props.filterType == "string") {
            filterType = props.filterType;
        } else if (props.hashFilter) {
            filterType = "hash";
        }
        setFilterType(filterType);
    }, [props.filterType, props.hashFilter]);

    if (filterType === null) {
        return null;
    }

    const openTableFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        props.openTableFilter(filterType);
    }

    let className = `fa fa-filter ${styles.nptFilter}`;
    if (props.active) {
        className += ` ${styles.active}`;
    }
    return <i className={className} onClick={openTableFilter}></i>;
});

/** Filter dropdown that allows user to modify column filter */
interface HeaderFilterDropdownProps {
    field: string
    cellRef: React.MutableRefObject<HTMLDivElement | null>
    filterActive: boolean
    filterType: FilterType | null
    filterInfo?: ColumnFilterInfo
    filterSelectionInfo?: FilterSelection
    filterRangeInfo?: FilterRange
    visible: boolean
    closeFilter: () => void
    applyFilter: (data: FilterData | null) => void
    fetchColumnSelection: (field: string, filterData: FilterData | null) => void
    fetchColumnRange: (field: string, filterData: FilterData | null) => void
}
const HeaderFilterDropdown: React.FunctionComponent<HeaderFilterDropdownProps> = React.memo((props) => {
    const getFilterData = () => {
        if (!props.filterInfo) {
            return null;
        }
        return props.filterInfo.data;
    }
    const [filterData, setFilterData] = React.useState<FilterData | null>(getFilterData());
    React.useEffect(() => {
        setFilterData(getFilterData());
    }, [props.filterInfo]);

    const headerCell = props.cellRef.current;
    const isVisible = props.filterType !== null && props.visible && headerCell;
    const closeFilter = () => {
        setFilterData(getFilterData());
        props.closeFilter();
    }
    React.useEffect(() => {
        if (!isVisible) {
            return;
        }
        bindEvent({
            event: `mousedown.table.filter.${props.field}`,
            handler: (event: React.MouseEvent) => {
                closeFilter();
            }
        });
        return () => {
            unbindEvent({ event: `mousedown.table.filter.${props.field}` });
        }
    }, [isVisible, props.filterInfo]);

    const headerRect = headerCell ? (headerCell as HTMLDivElement).getBoundingClientRect() : { width: 0, height: 0, left: 0, top: 0 };
    const [width, setWidth] = React.useState(getFilterWidth(headerRect.width));
    React.useEffect(() => {
        setWidth(getFilterWidth(headerRect.width));
    }, [headerRect.width]);

    if (!isVisible) {
        return null;
    }

    let filter = null;
    switch (props.filterType) {
        case "string":
            filter = <StringFilter
                loading={Boolean(props.filterSelectionInfo && props.filterSelectionInfo.loading)}
                error={Boolean(props.filterSelectionInfo && props.filterSelectionInfo.error)}
                filterData={filterData as StringFilterData | null}
                selectionOptions={props.filterSelectionInfo && props.filterSelectionInfo.selection || []}
                onChange={setFilterData}
                onEnter={props.applyFilter}
                fetchColumnSelection={props.fetchColumnSelection.bind(null, props.field)}
            />
            break;
        case "number":
            filter = <NumberFilter
                loading={Boolean(props.filterRangeInfo && props.filterRangeInfo.loading)}
                error={Boolean(props.filterRangeInfo && props.filterRangeInfo.error)}
                filterData={filterData as NumberFilterData | null}
                range={props.filterRangeInfo && props.filterRangeInfo.range || null}
                onChange={setFilterData}
                fetchColumnRange={props.fetchColumnRange.bind(null, props.field)}
            />
            break;
        case "date":
            filter = <DateFilter
                loading={Boolean(props.filterRangeInfo && props.filterRangeInfo.loading)}
                error={Boolean(props.filterRangeInfo && props.filterRangeInfo.error)}
                filterData={filterData as DateFilterData | null}
                range={props.filterRangeInfo && props.filterRangeInfo.range || null}
                onChange={setFilterData}
                fetchColumnRange={props.fetchColumnRange.bind(null, props.field)}
            />
            break;
        case "date_time":
            filter = <DateTimeFilter
                loading={Boolean(props.filterRangeInfo && props.filterRangeInfo.loading)}
                error={Boolean(props.filterRangeInfo && props.filterRangeInfo.error)}
                filterData={filterData as DateTimeFilterData | null}
                range={props.filterRangeInfo && props.filterRangeInfo.range || null}
                onChange={setFilterData}
                fetchColumnRange={props.fetchColumnRange.bind(null, props.field)}
            />
            break;
        case "boolean":
            filter = <BooleanFilter
                filterData={filterData as BooleanFilterData | null}
                onChange={setFilterData}
            />
            break;
    }

    let left = headerRect.left;
    let endReached = false;
    if (left + width > window.innerWidth) {
        left = window.innerWidth - width;
        endReached = true;
    }
    return <FilterDropdownPortal width={width} left={left} top={headerRect.top + headerRect.height} >
        {filter}
        {filter && <div className={`d-flex justify-content-end ${styles.nptFilterDropdownFooter}`}>
            <button className="btn btn-outline-success mr-2" onClick={() => props.applyFilter(filterData)}>
                <FormattedMessage
                    id="NPT_TABLE_FILTER_APPLY"
                    defaultMessage='Apply'
                    description="Apply filter to table" />
            </button>
            {props.filterActive && <button className="btn btn-outline-danger mr-2" onClick={() => props.applyFilter(null)}>
                <FormattedMessage
                    id="NPT_TABLE_FILTER_RESET"
                    defaultMessage='Reset'
                    description="Reset column's filter" />
            </button>}
            <button className="btn btn-outline-primary mr-2" onClick={closeFilter}>
                <FormattedMessage
                    id="NPT_TABLE_FILTER_CANCEL"
                    defaultMessage='Cancel'
                    description="Cancel column's filter" />
            </button>
        </div>}
        {!endReached && <FilterDropdownResizer field={props.field} width={width} type={"right"} changeWidth={setWidth} />}
        {endReached && <FilterDropdownResizer field={props.field} width={width} type={"left"} changeWidth={setWidth} />}
    </FilterDropdownPortal>;
});

/** Filter dropdown portal that renders filter window in body element */
interface FilterDropdownResizerProps {
    field: string
    width: number
    type: "left" | "right"
    changeWidth: (width: number) => void
}
const FilterDropdownResizer: React.FunctionComponent<FilterDropdownResizerProps> = React.memo((props) => {
    const [initialWidth, setInitialWidth] = React.useState(props.width);
    const [draggingInfo, setDraggingInfo] = React.useState<{ initialX: number } | null>(null);
    React.useEffect(() => {
        if (draggingInfo) {
            return;
        }
        setInitialWidth(props.width);
    }, [props.width, draggingInfo === null]);

    React.useEffect(() => {
        if (!draggingInfo === null) {
            return;
        }
        bindEvent({
            event: `mousemove.table.filter.${props.field}`,
            handler: (event: React.MouseEvent) => {
                if (!draggingInfo) {
                    return;
                }
                let diff = event.screenX - draggingInfo.initialX;
                if (props.type === "left") {
                    diff *= -1;
                }
                let width = initialWidth + diff;
                if (width < MIN_WIDTH) {
                    width = MIN_WIDTH;
                }
                props.changeWidth(width);
            }
        });
        bindEvent({
            event: `mouseup.table.filter.${props.field}`,
            handler: (event: React.MouseEvent) => {
                setDraggingInfo(null);
            }
        });
        return () => {
            unbindEvent({ event: `mousemove.table.filter.${props.field}` });
            unbindEvent({ event: `mouseup.table.filter.${props.field}` });
        }
    }, [draggingInfo === null]);

    const beginDrag = (event: React.MouseEvent) => {
        setDraggingInfo({ initialX: event.screenX });
    }

    return <div className={`${styles.nptFilterDropdownResizer} ${props.type === "left" ? styles.left : ""}`} onMouseDown={beginDrag}></div>
});


/** Filter dropdown portal that renders filter window in body element */
interface FilterDropdownPortalProps {
    width: number
    left: number
    top: number
}
const FilterDropdownPortal: React.FunctionComponent<FilterDropdownPortalProps> = React.memo((props) => {
    return ReactDOM.createPortal(
        <div
            className={`npt-dropdown-menu dropdown-menu show ${styles.nptFilterDropdown}`}
            style={{ width: props.width, left: props.left, top: props.top }}
            onMouseDown={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
            onContextMenu={(event) => event.stopPropagation()}
        >
            <div className={`w-100 h-100 ${styles.nptFilterDropdownContanier}`}>
                {props.children}
            </div>
        </div>,
        document.body
    );
});

export default HeaderFilter;