import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { style } from 'd3';
import * as React from 'react';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage } from 'react-intl';
import { AutoSizer, Column, SortDirection, SortDirectionType, Table, TableCellProps, TableHeaderProps } from 'react-virtualized';
import { I18NString } from '../../../../types/modal';
import { TABLE_BODY_ROW_CLASS, TABLE_CLASS, TABLE_HEADER_COLUMN_CLASS } from '../../../admin/VirtualizedTable';
import { MSG_TABLE_NO_DATA_TO_DISPLAY } from '../../../messages';
import styles from './TableComponent.module.css';

export interface ColumnInfo {
    label: I18NString | string
    width: number
    dataKey: string
    identifier?: boolean
    sort?: boolean
    type?: string
    headerColumnRenderer?: (columnData: TableHeaderProps) => any
    bodyColumnRenderer?: (columnData: TableCellProps) => any
}

////////////////// RENDER ROWS/////////////
const checkHandler = (checkFunction?: any, id?: number) => (checked: boolean) => {
    if (!checkFunction) {
        return;
    }

    id ? checkFunction(id, checked) : checkFunction(checked);
}

const elementWrapper = (element: any,
    cellProps: TableHeaderProps | TableCellProps,
    tableProps: TableComponentProps,
    columnsWidths?: { [name: string]: number },
    draggedColumns?: { col1: string, col2: string, x: number },
    setDraggedColumns?: any,
    setColumnsWidth?: any,) => {
    const { columnData } = cellProps;
    const { identifier } = columnData;
    const { columnsInfo, checkable } = tableProps;
    let wrappedElement = element;
    const isHeaderCell = !(cellProps as TableCellProps)['rowData'];

    if (checkable && isHeaderCell && identifier) {
        const { checkAllItems } = tableProps;
        const checkH = checkHandler(checkAllItems);
        wrappedElement = checkableWrapper(wrappedElement, checkH);
    } else if (checkable && !isHeaderCell && identifier) {
        const { checkItem } = tableProps;
        const { rowData } = (cellProps as TableCellProps);
        const infoKey = columnsInfo.find(info => info.identifier);
        const idKey = infoKey ? infoKey.dataKey : undefined;
        const idValue = idKey ? rowData[idKey] : undefined;
        const checkH = idValue ? checkHandler(checkItem, idValue) : (checked: boolean) => { };
        wrappedElement = checkableWrapper(wrappedElement, checkH, rowData.checked);
    }
    wrappedElement = <div className="w-100 d-flex flex-column justify-content-center">{wrappedElement}</div>
    if (setDraggedColumns) {
        wrappedElement = attachResizers(wrappedElement, columnData.dataKey, columnsInfo, setDraggedColumns);
    }


    return wrappedElement;
}
const checkableWrapper = (element: any, checkFunction: any, checked?: boolean) => {
    const checkValue = typeof checked === undefined ? false : checked;
    return <div className="d-flex justify-content-row w-100"><Form.Check checked={checkValue} onClick={(e: any) => checkFunction(e.target.checked)} type="checkbox" onChange={() => { }} />{element}</div>
}

const attachResizers = (element: any, dataKey: string, columnsInfo: ColumnInfo[], setDraggedColumns: any) => {
    const isLast = isLastHeaderColumn(dataKey, columnsInfo);
    const isFirst = isFirstHeaderColumn(dataKey, columnsInfo);
    const leftResizer = renderLeftResizer(isLast, dataKey, columnsInfo, setDraggedColumns);
    const rightResizer = renderRightResizer(isFirst, dataKey, columnsInfo, setDraggedColumns);

    return <>{rightResizer}{element}{leftResizer}</>
}




const noDataRowRenderer = (width: number) => {
    return <div style={{ width: width }} className={`${styles.NoDataRow}   pb-2`}>
        {MSG_TABLE_NO_DATA_TO_DISPLAY}
    </div>
}

const headerColumnRenderer = (header: TableHeaderProps,
    tableProps: TableComponentProps,
    columnsWidths: { [name: string]: number },
    draggedColumns: { col1: string, col2: string, x: number },
    setDraggedColumns: any,
    setColumnsWidth: any) => {
    const { columnData, sortBy, sortDirection: sd, disableSort, dataKey } = header;


    const { label, headerColumnRenderer, sort } = columnData;
    let id = typeof label === 'string' ? label : label.id;
    const sortDirection = sd && sd === 'ASC' ? 'DESC' :
        sd && sd === 'DESC' ? 'ASC' : null;
    const sortElement = !disableSort && sort && sortDirection ? <span className="ml-1" style={{ cursor: 'pointer' }} onClick={() => sort({ sortBy: dataKey, sortDirection })}><FontAwesomeIcon icon={faCaretDown} /></span> : <></>;
    let renderedElement = <div className="w-100"><FormattedMessage id={id} />{sortElement} </div>
    if (headerColumnRenderer) {
        renderedElement = headerColumnRenderer(header);
    }
    return elementWrapper(renderedElement,
        header,
        tableProps,
        columnsWidths,
        draggedColumns,
        setDraggedColumns,
        setColumnsWidth);
}

const bodyCellRenderer = (body: TableCellProps, tableProps: TableComponentProps) => {
    const { columnData, rowData } = body;
    const { dataKey, bodyColumnRenderer } = columnData;
    let renderedElement = <div>{rowData[dataKey]}</div>;
    if (bodyColumnRenderer) {
        renderedElement = bodyColumnRenderer(body)
    }

    return elementWrapper(renderedElement, body, tableProps);
}

const renderLeftResizer = (isLastHeaderColumn: boolean, columnKey: string, columnsInfo: ColumnInfo[], setDraggedColumns: any) => {
    if (!isLastHeaderColumn) {
        let resizerElement = <span onSelectCapture={(e) => { e.preventDefault() }}
            onMouseDown={(e) => captureLeftResizer(setDraggedColumns, columnsInfo, columnKey, e)}
            className={styles.leftResizer}> </span>;
        return resizerElement;
    } else {
        return <span></span>;
    }
}


const renderRightResizer = (isFirstHeaderColumn: boolean, columnKey: string, columnsInfo: ColumnInfo[], setDraggedColumns: any) => {
    if (!isFirstHeaderColumn) {
        let resizerElement = <span onSelectCapture={(e) => { e.preventDefault() }}
            onMouseDown={(e) => captureRightResizer(setDraggedColumns, columnsInfo, columnKey, e)}
            className={styles.rightResizer}> </span>;
        return resizerElement;
    } else {
        return <span></span>;
    }
}
//////////////////END OF RENDER ROWS///////////// 


//////////////// TABLE COMPONENT ////////////////
interface TableComponentProps {
    columnsInfo: ColumnInfo[]
    rows: any[]
    checkItem?: (id: number, checked: boolean) => void
    checkAllItems?: (checked: boolean) => void
    checkable?: boolean
    bodyRowHeight?: number
    headerRowHeight?: number
    maxRowCount?: number
}

const TableComponent = (props: TableComponentProps) => {
    const { rows, columnsInfo, headerRowHeight, bodyRowHeight, maxRowCount } = props;
    const ref = React.useRef(null)
    const headerHeight = 40;
    const rowHeight = 40;
    const prefferedCount = maxRowCount !== undefined ? maxRowCount : 6;
    let tableHeight = getTableHeight(rows, rowHeight, headerHeight, prefferedCount);
    let style = { height: tableHeight + 'px', width: '100%', }
    const [columnWidths, setColumnWidth] = useState(createInitialColumnWidths(columnsInfo));
    const [draggedColumns, setDraggedColumns] = useState({ col1: '', col2: '', x: 0 });


    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortDirection, setSortDirection] = useState<any>(SortDirection.ASC);
    const [sortedList, setSortedList] = useState(rows);
    const releaseResizer = () => {
        setDraggedColumns({ col1: '', col2: '', x: 0 });
    }
    React.useEffect(() => {
        document.addEventListener('mouseup', releaseResizer);
        setSortedList(rows);
        return function cleanup() { document.removeEventListener('mouseup', releaseResizer) }
    }, [rows.length])
    React.useEffect(() => {
        if (rows) {
            setSortedList(rows);
        }

    }, [rows])
    const bodyRenderer = (cProps: TableCellProps) => bodyCellRenderer(cProps, props)

    const headerRenderer = (hProps: TableHeaderProps) => headerColumnRenderer(hProps,
        props,
        columnWidths,
        draggedColumns,
        setDraggedColumns,
        setColumnWidth)

    if (!columnsInfo) {
        return null
    }

    const resiserIsBeingMoved = (evt: any) => {
        if (draggedColumns.col1 !== '' && draggedColumns.col2 !== '') {
            evt.preventDefault();
            let oldX = draggedColumns.x;
            let newX = evt.pageX;
            let delta = newX - oldX;
            let col1 = draggedColumns.col1;
            let col2 = draggedColumns.col2;

            let col1NewWidth = columnWidths[col1] + delta;
            let col2NewWidth = columnWidths[col2] - delta;
            if (col1NewWidth > 10 && col2NewWidth > 10) {
                setDraggedColumns({ ...draggedColumns, x: newX })
                setColumnWidth({ ...columnWidths, [col1]: col1NewWidth, [col2]: col2NewWidth });
            }
        }
    }
    const moveResizer = (e: any) => { resiserIsBeingMoved(e) }
    const isScrollExists: boolean = !!rows.length && rows.length > prefferedCount;

    const getSortFunction = (type: string, sortBy: string, sortDirection: SortDirectionType) => {
        if (type === 'number') {
            return (item1: any, item2: any) => sortDirection === SortDirection.ASC ? item1[sortBy] - item2[sortBy] : item2[sortBy] - item1[sortBy];
        } else if (type === "string") {
            return (item1: any, item2: any) => sortDirection === 'ASC' ? item1[sortBy].localeCompare(item2[sortBy]) : item2[sortBy].localeCompare(item1[sortBy]);
        }
    }

    const sort = (info: { sortBy: any, sortDirection: SortDirectionType }) => {
        const { sortBy, sortDirection } = info;
        const col = columnsInfo.find(c => c.dataKey === sortBy);
        if (!col || !col.type) {
            return;
        }

        const sortFunction = getSortFunction(col.type, sortBy, sortDirection)

        if (!sortFunction) {
            return;
        }
        const sortedListNew = sortedList.sort((item1, item2) => {
            return sortFunction(item1, item2);
        });

        setSortBy(info.sortBy);
        setSortDirection(info.sortDirection);
        setSortedList(sortedListNew);
    }
    // return  <div style={style}   onMouseMove={moveResizer}> 
    return <div className={`${TABLE_CLASS} mb-1 mt-1  `}  >
        <div style={style} className="rounded   bg-white   w-100" onMouseMove={moveResizer}>
            <AutoSizer onResize={(e) => { setWidthAfterRisizePage(e, setColumnWidth, columnWidths) }}  >
                {({ width, height }) => (
                    <Table
                        ref={ref}
                        width={width}
                        rowHeight={rowHeight}
                        headerHeight={headerHeight}
                        height={height}
                        rowCount={sortedList.length}
                        rowGetter={({ index }) => sortedList[+index]}
                        headerClassName={TABLE_HEADER_COLUMN_CLASS}
                        rowClassName={rowClassName}
                        gridClassName={`${styles.GridStyle} `}
                        noRowsRenderer={() => noDataRowRenderer(width)}
                        // sort={sort}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                    >
                        {columnsInfo.map((col: ColumnInfo, idx: number) => {
                            return <Column
                                key={idx}
                                // label={col.label}
                                disableSort={!col.sort}
                                dataKey={col.dataKey}
                                columnData={{ ...col, sort }}
                                width={columnWidths[col.dataKey]}
                                className={styles.bodyColumn /*+ ' ' + this.hideColumn(col.field)*/}
                                cellRenderer={bodyRenderer}
                                headerRenderer={headerRenderer}
                                headerClassName={`${styles.headerColumn} npt-table-header-column` /*+ ' ' + this.hideColumn(col.field)*/}
                            />
                        })}
                    </Table>
                )}

            </AutoSizer>
        </div>
    </div>
}

export default TableComponent;
////////////////END OF TABLE COMPONENT ////////////////



////////// TABLE UTIL FUNCTIONS ////////////// 

const getTableHeight = (rows: any[], rowHeight: number, headerHeight: number, prefferedCount: number) => {
    return rowHeight * rows.length + headerHeight

    // let tableHeight = rows.length ? rowHeight * prefferedCount + headerHeight : rowHeight + headerHeight;

    // if (isTableHeightLess(rows, tableHeight, rowHeight, headerHeight)) {
    //     tableHeight = rowHeight * rows.length + headerHeight
    // }
    // return tableHeight
}

const isTableHeightLess = (rows: any[], tableHeight: number, rowHeight: number, headerHeight: number) => {
    return rows.length && tableHeight > rowHeight * rows.length + headerHeight
}

const rowClassName = ({ index }: any) => {
    if (index < 0) {
        return `   text-dark ${styles.headerRow}`;
    } else if (index % 2 !== 0) {
        // return TABLE_BODY_ROW_CLASS;
        return `  ${TABLE_BODY_ROW_CLASS} ${styles.bodyRow}`
    } else {
        return `${TABLE_BODY_ROW_CLASS} ${styles.bodyRow}`;
    }
}



const createInitialColumnWidths = (columnsInfo: ColumnInfo[]) => {
    let columnWidth: { [name: string]: number } = {};
    columnsInfo.map(col => {
        columnWidth[col.dataKey] = col.width;
    });
    return columnWidth;
}

const setWidthAfterRisizePage = (evt: any, setColumnWidth: any, columnsWidths: { [name: string]: number }) => {
    let columnsWidthsCopy = { ...columnsWidths };
    let width = evt.width;
    if (width === 0) {
        return width
    }
    let columnsWidthSum = 0;

    for (let col in columnsWidthsCopy) {
        columnsWidthSum += columnsWidthsCopy[col];
    }
    for (let col in columnsWidthsCopy) {
        let perc = (columnsWidthsCopy[col] * 100) / columnsWidthSum;
        let factW = Math.round((width * perc) / 100);
        columnsWidthsCopy[col] = factW;

    }
    setColumnWidth(columnsWidthsCopy);
    return width;
}




const isLastHeaderColumn = (columnKey: String, columnsInfo: ColumnInfo[]) => {
    let lastElement = columnsInfo.length - 1;
    return columnsInfo[lastElement].dataKey === columnKey;
}
const isFirstHeaderColumn = (columnKey: String, columnsInfo: ColumnInfo[]) => {
    return columnsInfo[0].dataKey === columnKey;
}

const captureRightResizer = (setDraggedColumns: any,
    columnsInfo: ColumnInfo[],
    leftColumnName: string,
    e: any) => {
    //finds index of the left column( columns with index+1 will be right column)   
    e.preventDefault();
    // this.clearSelection();
    let i = getColumnIndex(leftColumnName, columnsInfo);

    if (i - 1 >= 0) {
        setDraggedColumns({
            col1: columnsInfo[i - 1].dataKey,
            col2: leftColumnName,
            x: e.pageX
        })
    }

}

const captureLeftResizer = (setDraggedColumns: any,
    columnsInfo: ColumnInfo[],
    leftColumnName: string,
    e: any) => {
    //finds index of the left column( columns with index+1 will be right column)  
    e.preventDefault();
    // this.clearSelection();
    let i = getColumnIndex(leftColumnName, columnsInfo);

    if (i + 1 < columnsInfo.length) {
        setDraggedColumns({
            col1: leftColumnName,
            col2: columnsInfo[i + 1].dataKey,
            x: e.pageX
        }
        )
    }

}



const getColumnIndex = (columnName: string, columnsInfo: ColumnInfo[]) => {
    let i = 0;
    for (let col of columnsInfo) {
        if (col.dataKey === columnName) {
            break;
        }
        i++;
    }
    return i;
}