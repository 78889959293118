import React, { useEffect, useMemo, useState } from 'react';
import {
  ReflexContainer,
  ReflexContainerProps, ReflexElement,
  ReflexElementProps, ReflexSplitter
} from 'react-reflex';
import 'react-reflex/styles.css';
import styles from '../divider/Divider.module.css';

interface CollapsedMap {
  [ORDED: number]: boolean | undefined
}

interface ClickData {
  order: number
  direction: 'up' | 'down'
}

export interface DividerContextProps {
  clickData: ClickData | null
  setClickData: (clickData: ClickData) => void
}

export const DividerContext = React.createContext<DividerContextProps | null>(null)
interface DividerState {
  clickData: {
    direction: 'up' | 'down',
    order: number
  } | null
  collapsedMap: CollapsedMap
  expandedMap: CollapsedMap
}
export const ContextedDivider: React.FunctionComponent<ReflexContainerProps> = React.memo(props => {
  const [clickData, setClickData] = useState<ClickData | null>(null)
  const [collapsedMap, setCollapsedMap] = useState<CollapsedMap>({})
  const [expandedMap, setExpandedMap] = useState<CollapsedMap>({})

  //Fill collapsed and expanded maps
  useEffect(() => {
    const newCollapsedMap: CollapsedMap = {}
    const newExpandedMap: CollapsedMap = {}
    React.Children.map(
      props.children,
      (child, idx) => {
        //Is this a DivPanel component
        if (idx % 2 === 0) {
          newCollapsedMap[idx] = (child as any).props.collapsed
          newExpandedMap[idx] = (child as any).props.expanded
        }
      }
    )
    setCollapsedMap(newCollapsedMap)
    setExpandedMap(newExpandedMap)
  }, [])

  const setCollapse = (map: CollapsedMap, order: number, direction: number) => {
    map[order - direction] = true
    map[order + direction] = false
  }



  useEffect(() => {
    if (clickData) {
      const { direction, order } = clickData

      const oldUpCollapsed = collapsedMap[order - 1]
      const oldDownCollapsed = collapsedMap[order + 1]

      const newCollapsedMap: CollapsedMap = { ...collapsedMap }
      const newExpandedMap: CollapsedMap = { ...expandedMap }

      //If any panel already collapsed reset collapsed and expanded of two panels near splitter
      if ((direction === 'up' && oldDownCollapsed) || (direction === 'down' && oldUpCollapsed)) {
        newCollapsedMap[order - 1] = false
        newCollapsedMap[order + 1] = false
        newExpandedMap[order - 1] = false
        newExpandedMap[order + 1] = false
        setCollapsedMap(newCollapsedMap)
        setExpandedMap(newExpandedMap)

      } else {
        if (direction === 'up') {
          setCollapse(newCollapsedMap, order, 1)
          //expand second paned
          setCollapse(newExpandedMap, order, -1)

        } else {
          // Collapse down/right
          setCollapse(newCollapsedMap, order, -1)
          //expand second paned
          setCollapse(newExpandedMap, order, 1)
        }
      }

      setCollapsedMap(newCollapsedMap)
      setExpandedMap(newExpandedMap)
    }

  }, [clickData])

  console.log('render')
  const renderChild = (child: any, idx: number) => React.cloneElement(child as any, { key: idx, collapsed: collapsedMap[idx], expanded: expandedMap[idx] })
  return (
    <DividerContext.Provider value={{ clickData, setClickData }}>
      <ReflexContainer {...props} className={`${styles.container} ${props.className ? props.className : ''}`}>

        {
          React.Children.map(
            props.children,
            renderChild

          )
        }
      </ReflexContainer>
    </DividerContext.Provider>
  )

});
export const Divider: React.FunctionComponent<ReflexContainerProps> = React.memo(props => {

  return (
    <ReflexContainer {...props} className={`${styles.container} ${props.className ? props.className : ''}`}>

      {
        props.children
      }
    </ReflexContainer>
  )

});

/* ReflexElement uses refs, so DivPanel can't be functional component */
interface DivPanelProps extends ReflexElementProps {
  expanded?: boolean
  collapsed?: boolean
}
export class DivPanel extends React.Component<DivPanelProps> {

  render() {
    const { expanded, collapsed } = this.props
    if (collapsed === true) {
      return <ReflexElement className={`${styles.element} ${styles.collapsed}`} />;
    }
    let className = `${styles.element} ${this.props.className ? this.props.className : ''}`;
    if (expanded === true) {
      className += ` ${styles.expanded}`;
    }
    return <ReflexElement {...this.props} className={className} >
      {this.props.children}
    </ReflexElement>
  }
};

/**
 * ReflexContainer checks ReflexSplitter component by type to identify it
 * from other inner elements.
 * Because of that we can't override it
 **/
export const Splitter = ReflexSplitter;


