import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as React from "react";
import { Form, InputGroup } from "react-bootstrap";
import styles from "./DateTimePickerInputField.module.css";

interface DateTimePickerInputFieldProps {
  onBlur: () => void;
  dateTime: string;
  validClass: string;
  dateFormat: string;
  timeFormat: string;
  focus?: boolean;
  inputSize?: string;
  disabled: boolean | undefined;
  style?: React.CSSProperties;
  className?: string;
  changeCalendarVisibility: () => void;
  // changeDate: (date: string) => void,
  // changeTime: (time: string) => void,
  onDateTimeChanged: (dateTime: string) => void;
  onClickInputItems: () => void;
}

export default class DateTimePickerInputField extends React.Component<DateTimePickerInputFieldProps> {
  constructor(props: DateTimePickerInputFieldProps) {
    super(props);
    this.onChangeDateTime = this.onChangeDateTime.bind(this);
  }

  //Get datetime from input and split it into separate date and time
  getDateTimeSeparated(dateTime: string) {
    const { dateFormat, timeFormat } = this.props;
    let newTime = moment().format(timeFormat);
    let newDate = moment().format(dateFormat);

    let dateTimeMassive = dateTime.split(/\s+/);
    if (dateTimeMassive.length === 2) {
      newTime = dateTimeMassive[1].trim();
      newDate = dateTimeMassive[0].trim();
    } else {
      newDate = dateTimeMassive[0].trim();
    }

    return [newDate, newTime];
  }

  onChangeDateTime(evt: any) {
    const { onDateTimeChanged } = this.props;
    const dateTime = evt.target.value;
    onDateTimeChanged(dateTime);
    // const [date, time] = this.getDateTimeSeparated(dateTime);

    // changeDate(date);
    // changeTime(time);
  }

  onCalendarShow(disabled: boolean | undefined) {
    if (disabled) {
      return;
    }
    const { changeCalendarVisibility, onClickInputItems } = this.props;
    changeCalendarVisibility();
    onClickInputItems();
  }

  render() {
    const {
      dateTime,
      validClass,
      inputSize,
      onClickInputItems,
      disabled,
      style,
      className,
    } = this.props;
    let inputFieldSize: any = undefined;
    if (inputSize) {
      inputFieldSize = inputSize;
    }
    const prependStyle = disabled
      ? styles.disabledInputPrepend
      : styles.inputPrepend;
    return (
      <Form
        inline
        onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
          event.preventDefault()
        }
      >
        <InputGroup size={inputFieldSize} className=" d-flex flex-fill">
          {this.props.children}
          <Form.Control
            onBlur={this.props.onBlur}
            className={`${validClass} ${className}`}
            disabled={disabled}
            style={style}
            id="dateTimeInputField"
            value={dateTime}
            autoFocus={this.props.focus}
            type="input"
            onChange={this.onChangeDateTime}
            onClick={onClickInputItems}
          />
          <InputGroup.Append
            id="dateTimeInputFieldIcon"
            className={prependStyle}
            onClick={this.onCalendarShow.bind(this, disabled)}
          >
            <InputGroup.Text className="px-1" id="basic-addon1">
              <FontAwesomeIcon icon={faCalendarAlt} color="#2FA4E7" />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    );
  }
}
