import { DomainInfo, LoginPageInfo } from "../types/security";

export function fillDomainMap(loginPageInfo: LoginPageInfo) {
    if (!loginPageInfo.domains) {
        return;
    }
    loginPageInfo.netbiosMap = {};
    loginPageInfo.fqdnMap = {};
    for (let domain of loginPageInfo.domains) {
        loginPageInfo.netbiosMap[domain.name.toUpperCase()] = domain;
        loginPageInfo.fqdnMap[domain.fqdn.toUpperCase()] = domain;
    }
}