export const SEND_THEME_TOGGLE = "SEND_THEME_TOGGLE";
export type SEND_THEME_TOGGLE = typeof SEND_THEME_TOGGLE;

export enum NptTheme {
    CUSTOM_DARK = 'custom-dark',
    CUSTOM_DARK_COMPACT = 'custom-dark-compact',
    DEFAULT = 'default',
    DEFAULT_COMPACT = 'default_compact'
} 

export enum CEditorTheme {
    DEFAULT = 'default',
    MATERIAL = 'blackboard'
} 

export const THEME_KEY = 'npt-theme';
export const CODE_EDITOR_THEME = 'npt-code-editor';

 