import * as React from 'react';

import styles from './Finder.module.css';

/******************************
 *  Finder Toolbar Component  *
 ******************************/
const FinderContainer: React.FunctionComponent = React.memo((props) => {
    return <div className={`w-100 h-100 d-flex flex-column ${styles.finder}`}>
        {props.children}
    </div>;
});

export default FinderContainer;