import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { sendRemoveAlert } from '../../actions/alert';
import { ApplicationAction, ApplicationState } from '../../types';
import { AlertInfo } from '../../types/alert';
import AlertList from './AlertList';


export interface AlertStackProps {
    alertList: AlertInfo[]
    removeAlert: (id: string) => void
}

class AlertStack extends React.Component<AlertStackProps> {
    constructor(props: AlertStackProps) {
        super(props);
    }
    render() {
        const len = this.props.alertList.length;
        if (len == 0) {
            return null;
        }
        return <AlertList alerts={this.props.alertList} onDismiss={this.props.removeAlert} timeout={5000} />
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            alertList: state.alert.alertList
        }
    },
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
        return {
            removeAlert: (id: string) => dispatch(sendRemoveAlert(id))
        }
    })(AlertStack)