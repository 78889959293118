import { Reducer } from "redux";
import { ApplicationAction } from "../types";
import { AclMap, EditorState, MenuData, MenuEditorState, MenuItem, SourceCodeState } from "../types/menueditor";
import * as c from '../constants/menueditor'
import { SourceEditorTreeData, SourceTreeNode } from "../types/sourceeditor";
import { parseTreeList } from "./sourceeditor";
import { isFetchError } from "../types/error";
import { EnumerationInfo } from "../types/subject";


export function parseTree(list: string[]): SourceEditorTreeData {
    const treeData = parseTreeList(list);
    const nodes = treeData.nodeById;
    const children = treeData.childrenIds;
    let roots = treeData.rootNodesIds;
    if (nodes) {
        Object.values(nodes).forEach(d => {
            const { directory, name, id, parentId } = d;
            if (!directory && !name.includes('menu')) {
                delete nodes[id];
                if (roots && !isFetchError(roots) && roots.includes(id)) {
                    roots = roots.filter(i => i !== id)
                }
                if (parentId && children && children[parentId]) {
                    children[parentId] = children[parentId].filter(i => i !== id)
                }
            }
        })
    }

    return treeData;
}


function convertToAclMap(enumMap: EnumerationInfo) {
    const aclMap: AclMap = {};
    enumMap.children.forEach(e => {
        if (e.data) {
            const { $namespace: namespace, $class: className, $rdfId: id, $label: label } = e.data;
            aclMap[id] = { namespace, className, rdfId: id, label };
        }
    })
    return aclMap;
}

function convertIdsForMenu(menu: MenuData) {
    const { itemById, mainNode, roots, a, childrenById } = menu;
    for (const itemE of Object.entries(itemById)) {
        const [id, item] = itemE;
        item.id = id;
    }
    if (childrenById) {
        for (const childrenE of Object.entries(childrenById)) {
            const [parentId, children] = childrenE;
            for (const item of children) {
                itemById[item].parentId = parentId;
            }
        }
    }
    return menu;
}

function mergeMenu(state: MenuEditorState, newMenu: MenuData, menuPath: string): MenuEditorState {
    newMenu = convertIdsForMenu(newMenu);
    let newMenuEditor: EditorState = { ...newMenu, path: menuPath, loading: false }
    if (state.editorState[menuPath]) {
        const { loading, h, d, i, id, l, n, a, roots, mainNode, itemById, childrenById, parentId, r, t, sourceCode, path, ...other } = state.editorState[menuPath];
        newMenuEditor = { ...newMenu, ...other, path, loading: false, };
    }


    const newEditorState = { ...state.editorState, [menuPath]: newMenuEditor };
    return { ...state, editorState: newEditorState, menuActive: menuPath }

}

const reducer: Reducer<MenuEditorState, ApplicationAction> = (state: MenuEditorState = { editorState: {} }, action: ApplicationAction): MenuEditorState => {

    switch (action.type) {
        case c.SEND_MENU_EDITOR_MENU: {
            const { menu: newMenu, path } = action.payload;
            return mergeMenu(state, newMenu, path);
        }
        case c.SEND_MENU_EDITOR_CLEAR_MENU: {
            const activePath = state.menuActive;
            if (!activePath) {
                return state;
            }
            const newEditorState = { ...state.editorState }
            delete newEditorState[activePath]
            return { ...state, editorState: newEditorState };
        }
        case c.SEND_MENU_EDITOR_LOADING: {
            const { path, loading } = action.payload;
            const newEditor = { ...state.editorState[path], loading }
            const newEditorState = { ...state.editorState, [path]: newEditor };
            return { ...state, editorState: newEditorState };
        }
        case c.SEND_MENU_EDITOR_CTX_MENU_EDITOR: {
            const { path, ctxMenu: contextMenu } = action.payload;
            const newEditor = { ...state.editorState[path], contextMenu }
            const newEditorState = { ...state.editorState, [path]: newEditor };
            return { ...state, editorState: newEditorState };
        }
        case c.SEND_MENU_EDITOR_OPERATION: {
            const { path, operation } = action.payload;
            const newEditor = { ...state.editorState[path], operation }
            const newEditorState = { ...state.editorState, [path]: newEditor };

            return { ...state, editorState: newEditorState };
        }
        case c.SEND_MENU_EDITOR_ACL_LIST: {
            const { path, aclMap: acl } = action.payload;
            const aclMap = convertToAclMap(acl);
            const newEditor = { ...state.editorState[path], aclMap }
            const newEditorState = { ...state.editorState, [path]: newEditor };
            return { ...state, editorState: newEditorState };
        }

        case c.SEND_MENU_EDITOR_MENU_ACTIVE: {
            const { path, id } = action.payload;
            const newEditor = { ...state.editorState[path] }
            if (!newEditor) {
                return state;
            }
            let active = newEditor.active
            if (!active) {
                active = {}
            }
            const currentActive = active[id];
            if (currentActive) {
                delete active[id]
            } else {
                active[id] = true;
            }
            newEditor.active = active;
            const newEditorState = { ...state.editorState, [path]: newEditor };
            return { ...state, editorState: newEditorState };
        }
        case c.SEND_MENU_EDITOR_MENU_ACTIVE_ALL: {
            const { path, isActive } = action.payload;
            const newEditor = { ...state.editorState[path] }
            if (!newEditor) {
                return state;
            }
            let active: { [ID: string]: boolean } = {}
            if (isActive) {
                Object.entries(newEditor.itemById).forEach(e => {
                    const [k, v] = e;
                    active[k] = isActive;
                })
            }
            newEditor.active = active;
            const newEditorState = { ...state.editorState, [path]: newEditor };
            return { ...state, editorState: newEditorState };
        }
        case c.SEND_MENU_EDITOR_MENU_TOGGLE: {
            const { toggled, id, path } = action.payload;
            if (!state.editorState) {
                return state;
            }
            const newEditor = { ...state.editorState[path] }
            newEditor.expanded = { ...newEditor.expanded, [id]: toggled };
            const newEditorState = { ...state.editorState, [path]: newEditor };
            return { ...state, editorState: newEditorState };
        }
    }

    return state;
}

export default reducer;