import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select';
import { ThunkDispatch } from 'redux-thunk';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { ApplicationAction, ApplicationState } from '../../../types';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import { ClassCard, ClassInfo, ClassInfoContainer, NamespaceContainer, PackageContainer, StereotypeContainer, StoreTypeContainer } from '../../../types/profile';
import TagSelect from '../../developer/profileeditor/tagselect/TagSelect';
import MaskedInput from '../../maskedinput';
import ModalView from '../ModalView';

export const DEPTHS = [
    { name: "one", label: "Один уровень", value: 1 },
    { name: "two", label: "Два уровня", value: 2 },
    { name: "three", label: "Три уровня", value: 3 }
];

type OptionType<L,V> = {label:L,value:V} 

export interface ClassData {
    id?: number
    label: string
    name: string
    namespaceId: number
    packageId: number
    parentClasses: number[]
    stereotype: number | null
    storeType: number
    enumerationDepth?: number
    description?: string
}

interface SaveClassModalProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void,
    card?: ClassCard
    newClassPackageId?: number,
    editedClass?: ClassInfo
}

interface SaveClassModalState {
    classData: ClassData | null
    isNameValid: boolean
    isLabelValid: boolean
}

const NAME_FIRST_SYMBOL = "a-zA-Z_";
const NAME_SYMBOLS = NAME_FIRST_SYMBOL + "0-9";

const LABEL_FIRST_SYMBOL = undefined;
const LABEL_SYMBOLS = undefined;

export const getAllClassesSelectData = (allClassInfoContainer: ClassInfoContainer, namespaceContainer: NamespaceContainer) => {
    const options: OptionType<string,number>[] = [];
    allClassInfoContainer.list.forEach(c => {
        const { id: value, name, namespaceId } = c;
        const namespace = namespaceContainer.namespaceById[namespaceId];
        const label = `${namespace.prefix}:${name}`
        options.push({ label, value })
    })
    return options;
}

class SaveClassModal extends React.Component<SaveClassModalProps, SaveClassModalState> {

    private title: any;

    constructor(props: SaveClassModalProps) {
        super(props);
        const { newClassPackageId, editedClass } = this.props;
        this.closeModal = this.closeModal.bind(this);
        this.title = editedClass ? { id: "CLASSCARD_EDIT_CLASS" } : { id: "CLASSCARD_CREATE_CLASS" };

        const classData: ClassData | null = this.initializeState();
        this.state = {
            classData: classData,
            isNameValid: classData ? this.validateName(classData.name) : false,
            isLabelValid: classData ? this.validateLabel(classData.label) : false
        };
    }

    getNamespaceId(namespaceContainer: NamespaceContainer, url: string) {
        const idEntry = Object.entries(namespaceContainer.namespaceById).find(entry => entry[1].url === url);
        return idEntry && +idEntry[0];
    }


    initializeState() {
        const { card, newClassPackageId, editedClass } = this.props;

        if (!card || (!newClassPackageId && !editedClass)) {
            return null;
        }

        let classData: ClassData | null = null;
        if (editedClass /*&& !newClassPackageId*/) {
            const classInfo: ClassInfo = editedClass;
            const { id, name, label, namespaceId, packageId, parentList: parents, stereotype, storeType, enumerationDepth, description } = classInfo;
            const parentClasses = parents ? parents.map(p => p.peerClass.id) : [];
            classData = {
                id,
                name,
                label,
                namespaceId,
                packageId,
                parentClasses,
                storeType,
                stereotype,
                enumerationDepth,
                description
            }

        } else if (newClassPackageId && !editedClass) {
            if (Object.keys(card.namespace.namespaceById).length === 0) {
                return null
            }
            const namespaceId = +Object.entries(card.namespace.namespaceById)[0][0];
            classData = {
                label: '',
                name: '',
                namespaceId,
                packageId: newClassPackageId,
                parentClasses: [],
                storeType: 0,
                stereotype: null,
                description: ''
            }

        }
        if (!classData) {
            return null
        }
        return classData;
    }

    getLocalizedTypeLabel(label: string) {
        switch (label.toLowerCase()) {
            case 'string': return <FormattedMessage id="CLASSCARD_TYPE_STRING" />;
            case 'number': return <FormattedMessage id="CLASSCARD_TYPE_NUMBER" />;
            case 'boolean': return <FormattedMessage id="CLASSCARD_TYPE_BOOLEAN" />;
            case 'timestamp': return <FormattedMessage id="CLASSCARD_TYPE_TIMESTAMP" />;
            case 'file': return <FormattedMessage id="CLASSCARD_TYPE_FILE" />;
            case 'fragment': return <FormattedMessage id="CLASSCARD_TYPE_FRAGMENT" />;
            case 'decimal': return <FormattedMessage id="CLASSCARD_TYPE_DECIMAL" />;
        }
        return 'UNKNOWN_TYPE';
    }

    validateName(name: string) {
        return !!name;
    }

    validateLabel(label: string) {
        return !!label;
    }
    getPackageSelectData(packageContainer: PackageContainer) {
        const options: OptionType<string,number>[] = [];
        Object.entries(packageContainer.packageById).forEach(entry => {
            const [value, pc] = entry;
            const label = pc.label;
            options.push({ label, value:+value})
        })
        return options;
    }
    getNamespaceSelectData(namespaceContainer: NamespaceContainer) {
        const options: OptionType<string,number>[] = [];
        Object.entries(namespaceContainer.namespaceById).forEach(entry => {
            const [value, ns] = entry;
            const label = ns.prefix;
            options.push({ label, value:+value })
        })
        return options;
    }
    
    getParentClassSelectData(allClassInfoContainer: ClassInfoContainer, parent: number[], namespaceContainer: NamespaceContainer) {
        const options:  OptionType<string,number>[] = [];
        parent.forEach(parentClassId => {
            const value = parentClassId;
            const classInfo = allClassInfoContainer.list.find(c => c.id === parentClassId);
            if (classInfo) {
                const { name, namespaceId } = classInfo;
                const namespace = namespaceContainer.namespaceById[namespaceId];
                const label = `${namespace.prefix}:${name}`
                options.push({ label, value })
            }
        })
        return options;
    }

    getStereotypeSelectData(stereotypeContainer: StereotypeContainer) {
        const options: OptionType<string,number|null>[] = [];
        options.push({ label: '--', value: null })
        stereotypeContainer.list.forEach(st => options.push({ label: st.template, value: st.value }))
        return options;
    }

    getDepthSelectData() {
        const options: OptionType<string,number>[] = [];
        DEPTHS.forEach(d => {
            options.push({ label: d.label, value: d.value });
        })
        return options;
    }
    getStoreTypeSelectData(storeTypeContainer: StoreTypeContainer) {
        const options: OptionType<string|JSX.Element,number>[] = [];
        storeTypeContainer.list.forEach(st => {
            const { value, template } = st;
            const label = this.getLocalizedTypeLabel(template);
            options.push({ value, label });
        })
        return options;
    }

    getDepthByValue(value: number) {
        return DEPTHS.find(d => d.value === value)
    }
    changeSelect = (fieldName: string) => (option: ValueType<OptionType<string|JSX.Element,number|null>,false>) => {
        const { value } = option as OptionType<string,number>;
        const oldClassData = this.state.classData
        if (isFinite(value) && oldClassData) {
            let newValue: number | null = value;
            if (!isFinite(newValue)) {
                newValue = null;
            }
            let classData: ClassData = { ...oldClassData, [fieldName]: newValue };
            this.setState({ classData })
        }
    }
    changeInput = (fieldName: string) => (value: string) => {
        const nextState: SaveClassModalState = { ...this.state };
        let classData: ClassData | null = nextState.classData;
        if (!classData) {
            return;
        }
        classData = { ...classData, [fieldName]: value };
        nextState.classData = classData;
        switch (fieldName) {
            case 'name':
                nextState.isNameValid = this.validateName(value);
                break;
            case 'label':
                nextState.isLabelValid = this.validateLabel(value);
                break;
        }
        this.setState(nextState);
    }

    addParentClassHandler = (classData: ClassData) => (parentData: ValueType<OptionType<string,number>,false>) => {
        const checkedParents: number[] = [...classData.parentClasses];
        if(!parentData){
            return;
        }
        if (checkedParents.some(p => p === parentData.value)) {
            return
        }
        //  const classData:ClassData= {...this.state.classData};
        checkedParents.push(parentData.value);
        const nextClassData: ClassData = { ...classData, parentClasses: checkedParents }
        this.setState({ ...this.state, classData: nextClassData });

    }
    deleteParentClassHandler = (classData: ClassData) => (parentData: ValueType<OptionType<string,number>,false>) => {
        let checkedParents: number[] = [...classData.parentClasses];
        if(!parentData){
            return;
        }
        checkedParents = checkedParents.filter(p => p !== parentData?.value);
        const nextClassData: ClassData = { ...classData, parentClasses: checkedParents }
        this.setState({ ...this.state, classData: nextClassData });

    }

    renderNamespace(classData: ClassData, namespaceContainer: NamespaceContainer) {
        const namespaces = this.getNamespaceSelectData(namespaceContainer);
        const { namespaceId: value } = classData;
        const { prefix: label } = namespaceContainer.namespaceById[value];
        const checkedValue: OptionType<string,number> = { value, label };
        return (<Form.Group as={Row}  className="mb-2" controlId="namespace">
            <Col md={3}>
                <Form.Label>
                    <FormattedMessage id={"CLASSCARD_NAMESPACE"} />
                </Form.Label>
            </Col>
            <Col md={9}>
                <Select
                    className="npt-select"
                    value={checkedValue}
                    isSearchable={true}
                    options={namespaces}
                    onChange={this.changeSelect('namespaceId')} />
            </Col>
        </Form.Group>)
    }

    renderPackage(classData: ClassData, packageContainer: PackageContainer) {
        const packages = this.getPackageSelectData(packageContainer);
        const { packageId: value } = classData;
        const { label } = packageContainer.packageById[value];
        const checkedValue: OptionType<string,number> = { value, label };

        const disablePackages = this.props.newClassPackageId !== undefined ? true : false;
        return (<Form.Group as={Row}  className="mb-2" controlId="namespace">
            <Col md={3}>
                <Form.Label>
                    <FormattedMessage id={"CLASSCARD_PACKAGE"} />
                </Form.Label>
            </Col>
            <Col md={9}>
                <Select
                    className="npt-select"
                    isDisabled={disablePackages}
                    value={checkedValue}
                    isSearchable={true}
                    options={packages}
                    onChange={this.changeSelect('packageId')} />
            </Col>
        </Form.Group>)
    }

    renderIdentifier(classData: ClassData) {
        const { name } = classData;
        return (
            <Form.Group  className="mb-2"  controlId="name">
                <Row>
                    <Col md={3}>
                        <Form.Label>
                            <FormattedMessage id={"CLASSCARD_NAME_OF_PREDICATE"} />
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <MaskedInput
                            className={"form-control" + (name ? "" : " is-invalid")}
                            value={name}
                            onChange={this.changeInput("name")}
                            allowedSymbols={NAME_SYMBOLS}
                            firstSymbol={NAME_FIRST_SYMBOL}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                    </Col>
                    <Col md={9}>
                        <Form.Text className="text-info" >
                            <FormattedMessage id="CLASSCARD_ALLOWED_SYMBOLS" values={{ symbols: NAME_SYMBOLS }} />
                        </Form.Text>
                    </Col>
                </Row>
            </Form.Group>
        )
    }
    renderLabel(classData: ClassData) {
        const { label } = classData;
        return (
            <Form.Group  className="mb-2" as={Row} controlId="label">
                <Col md={3}>
                    <Form.Label>
                        <FormattedMessage id={"CLASSCARD_LABEL_OF_PREDICATE"} />
                    </Form.Label>
                </Col>
                <Col md={9}>
                    <MaskedInput
                        className={"form-control" + (label ? "" : " is-invalid")}
                        value={label}
                        onChange={this.changeInput("label")}
                        allowedSymbols={LABEL_SYMBOLS}
                        firstSymbol={LABEL_FIRST_SYMBOL}
                    />
                </Col>
            </Form.Group>
        )
    }

    renderParents(classData: ClassData, classInfoContainer: ClassInfoContainer, namespaceContainer: NamespaceContainer) {
        const namespace = namespaceContainer.namespaceById[classData.namespaceId];
        let allClasses =  getAllClassesSelectData(classInfoContainer, namespaceContainer);
        namespace && (allClasses = allClasses.filter(cl =>
            cl && cl.label !== `${namespace.prefix}:${classData.name}`
        )
        );
        const parentClasses = this.getParentClassSelectData(classInfoContainer, classData.parentClasses, namespaceContainer)

        return (<Form.Group  className="mb-2" as={Row} controlId="namespace">
            <Col md={3}>
                <Form.Label>
                    <FormattedMessage id={"CLASSCARD_PARENTS_CLASSES"} />
                </Form.Label>
            </Col>
            <Col md={9}>
                <TagSelect
                    tags={parentClasses}
                    placeholder={"CLASSCARD_CHOOSE_PARENT_CLASS"}
                    suggestions={allClasses}
                    displayField={"label"}
                    selectHandler={this.addParentClassHandler(classData)}
                    deleteHandler={this.deleteParentClassHandler(classData)}
                />
            </Col>
        </Form.Group>)
    }

    renderDescription(classData: ClassData) {
        const { description } = classData;
        return (
            <Form.Group  className="mb-2" as={Row} controlId="description">
                <Col md={3}>
                    <Form.Label>
                        <FormattedMessage id={"CLASSCARD_DESCRIPTION"} />
                    </Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control as="textarea" rows={3} value={description}
                        onChange={(e: any) => this.changeInput("description")(e.target.value)}
                    />
                </Col>
            </Form.Group>
        )
    }

    renderDepth(classData: ClassData) {
        const depths = this.getDepthSelectData();
        let checkedValue: OptionType<string,number> | null = null;
        const { enumerationDepth: depthValue } = classData;
        if (depthValue === undefined) {
            checkedValue = depths[0]
        } else {
            const depth = this.getDepthByValue(depthValue);
            const label = depth ? depth.label : depths[0].label;
            const value = depth ? depth.value : depths[0].value;
            checkedValue = { value, label };
        }

        if (checkedValue === null) {
            return null;
        }
        return (<Form.Group  className="mb-2" as={Row} controlId="enumeration">
            <Col md={3}>
                <Form.Label>
                    <FormattedMessage id={"CLASSCARD_DEPTH"} />
                </Form.Label>
            </Col>
            <Col md={9}>
                <Select
                    className="npt-select"
                    value={checkedValue}
                    isSearchable={true}
                    options={depths}
                    onChange={this.changeSelect('enumerationDepth')} />
            </Col>
        </Form.Group>)
    }

    renderStoreType(classData: ClassData, storeTypeContainer: StoreTypeContainer) {
        const storetypes = this.getStoreTypeSelectData(storeTypeContainer);

        const { storeType: value } = classData;
        const label = this.getLocalizedTypeLabel(storeTypeContainer.storeByValue[value].template);
        let checkedValue: OptionType<string|JSX.Element,number> = { label, value };

        return (<Form.Group  className="mb-2" as={Row} controlId="storeType">
            <Col md={3}>
                <Form.Label>
                    <FormattedMessage id={"CLASSCARD_STORE_TYPE"} />
                </Form.Label>
            </Col>
            <Col md={9}>
                <Select
                    className="npt-select"
                    value={checkedValue}
                    isSearchable={true}
                    options={storetypes}
                    onChange={this.changeSelect('storeType')} />
            </Col>
        </Form.Group>)
    }




    renderStereotype(classData: ClassData, stereotypeContainer: StereotypeContainer) {
        const stereotypes = this.getStereotypeSelectData(stereotypeContainer);

        const { stereotype } = classData; 
        const checkedValue: OptionType<string,number|null> = !stereotype  ? stereotypes[0] : 
            {
                label: stereotypeContainer.stereotypeByValue[stereotype].template,
                value: stereotypeContainer.stereotypeByValue[stereotype].value
            };


        return (<Form.Group  className="mb-2" as={Row} controlId="stereotype">
            <Col md={3}>
                <Form.Label>
                    <FormattedMessage id={"CLASSCARD_STEREOTYPE"} />
                </Form.Label>
            </Col>
            <Col md={9}>
                <Select
                    className="npt-select"
                    value={checkedValue}
                    isSearchable={true}
                    options={stereotypes}
                    onChange={this.changeSelect('stereotype')} />
            </Col>
        </Form.Group>)
    }

    renderAdditions(classData: ClassData, storeTypeContainer: StoreTypeContainer) {
        const { stereotype } = classData;
        if (stereotype === 2) {
            return this.renderDepth(classData);
        } else if (stereotype === 0) {
            return this.renderStoreType(classData, storeTypeContainer);
        }
    }
    renderTemplate(): React.ReactElement {
        const { classData } = this.state;
        const { card } = this.props;
        if (!classData || !card) {
            return <></>;
        }

        const { packages, namespace, allClasses, stereotype, storeType } = card;
        return (
            <Form>
                {this.renderPackage(classData, packages)}
                {this.renderNamespace(classData, namespace)}
                {this.renderIdentifier(classData)}
                {this.renderLabel(classData)}
                {this.renderParents(classData, allClasses, namespace)}
                {this.renderStereotype(classData, stereotype)}
                {stereotype && this.renderAdditions(classData, storeType)}
                {this.renderDescription(classData)}
            </Form>
        );
    }

    isClassValid() {
        const { isLabelValid, isNameValid } = this.state;
        return isLabelValid && isNameValid;
    }

    closeModal(status: ModalStatus, result: any) {
        const { classData } = this.state;
        if (status == MODAL_STATUS_OK && this.isClassValid() && classData) {
            const { editedClass } = this.props;
            if (classData.stereotype !== 0) {
                classData.storeType = 0;
            }
            if (classData.stereotype !== 2 && classData.enumerationDepth !== undefined) {
                classData.enumerationDepth = undefined
            }
            this.props.closeModal(status, classData);
        } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
            this.props.closeModal(status, result);
        }
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}

export default connect((state: ApplicationState) => {
    if (!state.profileeditor || !state.profileeditor.card) {
        return {}
    }
    return {
        card: state.profileeditor.card,
        newClassPackageId: state.profileeditor.newClassPackageId,
        editedClass: state.profileeditor.editedClass
    }
},
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
        return {

        }
    })(SaveClassModal)

