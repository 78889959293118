import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import { ThunkDispatch } from "redux-thunk";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import { CREATE_PREDICATE, UPDATE_PREDICATE } from "../../../constants/profile";
import { ApplicationAction, ApplicationState } from "../../../types";
import { ModalInfo, ModalStatus } from "../../../types/modal";
import { NamespaceData } from "../../../types/namespace";
import {
  ClassCard,
  ClassInfo,
  ClassInfoContainer,
  DataType,
  Multiplicity,
  MultiplicityContainer,
  NamespaceContainer,
  Predicate,
  PredicateData,
  PredicateEditorInfo,
  PredicateRelation,
  PrimitiveTypeContainer,
  RelationType,
  RelationTypeContainer,
} from "../../../types/profile";
import MaskedInput from "../../maskedinput";
import ModalView from "../ModalView";
import {
  getRelationByLabel,
  RELATION_ASSOTIATION,
  RELATION_COMPOSITION,
} from "./RelationViewerModal";

type OptionType<L, V> = { label: L; value: V };

interface SavePredicateModalProps {
  modal: ModalInfo;
  closeModal: (status: ModalStatus, result: any) => void;
  card?: ClassCard;
}

interface SavePredicateModalState {
  predicateEditor?: PredicateEditorInfo;
  validation?: { [name: string]: { name: boolean; label: boolean } };
  withReverse: boolean;
  isRelationHidden: boolean;
}

const NAME_FIRST_SYMBOL = "a-zA-Z_";
const NAME_SYMBOLS = NAME_FIRST_SYMBOL + "0-9";

const LABEL_FIRST_SYMBOL = undefined;
const LABEL_SYMBOLS = undefined;

const PREDICATE = "predicate";
const REVERSE_PREDICATE = "reversePredicate";

class SavePredicateModal extends React.Component<
  SavePredicateModalProps,
  SavePredicateModalState
> {
  private title = { id: "PREDICATE" };
  private types: DataType[] = [];

  constructor(props: SavePredicateModalProps) {
    super(props);
    const {} = this.props;
    this.closeModal = this.closeModal.bind(this);

    const editedPredicateId =
      props.modal.options.body !== undefined
        ? props.modal.options.body.toString()
        : undefined;
    editedPredicateId
      ? (this.title = { id: "CLASSCARD_EDIT_PREDICATE" })
      : (this.title = { id: "CLASSCARD_CREATE_PREDICATE" });
    this.state = {
      ...this.initializeState(editedPredicateId),
      withReverse: false,
    };
  }

  isPredicateEditing() {
    return this.props.modal.options.body !== undefined;
  }
  convertToPredicateData(p: Predicate, namespaceContainer: NamespaceContainer) {
    const {
      id,
      name: n,
      label,
      description,
      multiplicity,
      namespace,
      dataTypeId: typeId,
      classId,
      systemDriven,
    } = p;
    const namespaceId = this.getNamespaceId(namespaceContainer, namespace.url);
    const dataTypeId = p.classRelationInfo
      ? p.classRelationInfo.peerClass.id
      : typeId;
    const nameParts = n.split(".");
    const name = nameParts && nameParts.length === 2 ? nameParts[1] : n;
    return {
      id,
      name,
      label,
      description,
      multiplicity,
      namespaceId,
      dataTypeId,
      classId,
      systemDriven,
    };
  }
  predicateTreeToList(data: ClassInfo, card: ClassCard) {
    const { predicateList, parentList } = data;

    const predicateDataList: Predicate[] = [...predicateList] || [];

    if (parentList) {
      for (const parent of parentList) {
        predicateDataList.push(
          ...this.predicateTreeToList(parent.peerClass, card)
        );
      }
    }

    return predicateDataList;
  }

  initializeTypes(
    primitiveTypeContainer: PrimitiveTypeContainer,
    namespaceContainer: NamespaceContainer,
    allClassesContainer: ClassInfoContainer
  ) {
    this.types = [
      ...this.createPrimitiveTypes(primitiveTypeContainer, namespaceContainer),
    ];
    const typeLabels = this.types.map((type) => type.label);
    this.createClassTypes(
      allClassesContainer,
      namespaceContainer.namespaceById
    ).forEach((type) => {
      if (!typeLabels.includes(type.label)) {
        this.types.push(type);
      }
    });
  }

  getNamespaceId(namespaceContainer: NamespaceContainer, url: string) {
    const idEntry = Object.entries(namespaceContainer.namespaceById).find(
      (entry) => entry[1].url === url
    );
    return idEntry && +idEntry[0];
  }
  getDataTypeId(label: string) {
    const typeObj = this.types.find((type) => type.label === label);
    return typeObj && typeObj.id;
  }
  getDataTypeById(id: number) {
    return this.types.find((type) => type.id === id);
  }

  initializeState(editedPredicateId?: string): SavePredicateModalState {
    const { card } = this.props;
    const initState: SavePredicateModalState = { ...this.state };
    if (!card || !card.data) {
      return { isRelationHidden: true, withReverse: false };
    }
    let type: string = UPDATE_PREDICATE;
    let predicateEditor: PredicateEditorInfo | null = null;
    let predicate: PredicateData = {};
    if (!editedPredicateId) {
      type = CREATE_PREDICATE;
      const {
        namespace: namespaceContainer,
        stereotype: st,
        relationType,
        primitiveTypes,
      } = card;

      // const dataTypeId = predicate.dataTypeId;
      const dataTypeId = primitiveTypes ? primitiveTypes.list[0].id : undefined;
      const randomNamespace = namespaceContainer.list[0];
      const namespaceId = this.getNamespaceId(
        namespaceContainer,
        randomNamespace.url
      );
      const { id, namespaceId: nId } = card.data;
      predicate.multiplicity = 0;
      predicate.description = "";
      predicate.name = "";
      predicate.label = "";
      predicate.dataTypeId = dataTypeId;
      predicate.classId = id;
      predicate.namespaceId = nId;
      predicateEditor = { type, predicate };

      // return { ...newState, predicate: predicate };
    } else {
      const data = card.data;
      if (!data) {
        return { isRelationHidden: true, withReverse: false };
      }
      const predicateId = +editedPredicateId;

      const predicates = this.predicateTreeToList(data, card);
      const cimEditedPredicate = predicates.find((p) => p.id === predicateId);
      if (!cimEditedPredicate) {
        return { isRelationHidden: true, withReverse: false };
      }
      const editedPredicate = this.convertToPredicateData(
        cimEditedPredicate,
        card.namespace
      );
      predicate = editedPredicate;

      predicateEditor = { type, predicate };
      if (cimEditedPredicate.classRelationInfo) {
        const classId = cimEditedPredicate.classRelationInfo.peerClass.id;
        const type = getRelationByLabel(
          cimEditedPredicate.classRelationInfo.relationTypeInfo
        );

        if (type !== undefined) {
          const relation: PredicateRelation = {
            classId,
            type,
          };
          const stereotype =
            cimEditedPredicate.classRelationInfo.peerClass.stereotype;
          predicateEditor.relation = relation;
          const isRelationHidden =
            stereotype === 2 || stereotype === 3 ? true : false;
          initState.isRelationHidden = isRelationHidden;
        }
      }
    }

    if (predicateEditor) {
      const { label, name } = predicateEditor.predicate;
      initState.validation = {
        [PREDICATE]: {
          name: this.validateName(name),
          label: this.validateName(label),
        },
        [REVERSE_PREDICATE]: {
          name: true,
          label: true,
        },
      };
    }
    initState.predicateEditor = predicateEditor;
    return initState;
  }

  isPredicateNative(predicate: PredicateData, predicateList: Predicate[]) {
    for (let i = 0; i < predicateList.length; i++) {
      if (predicate.id === predicateList[i].id) {
        return true;
      }
    }
    return false;
  }

  createClassTypes(
    allClasses: ClassInfoContainer,
    namespaces: { [id: number]: NamespaceData }
  ) {
    const classTypes: DataType[] = [];
    const { list: classes } = allClasses;
    classes.forEach((classInfo) => {
      const { namespaceId, name, stereotype } = classInfo;
      const prefix = namespaces[namespaceId].prefix;
      // if (stereotype) {
      classTypes.push({
        prefix,
        primitive: false,
        label: name,
        stereotype: classInfo.stereotype,
        id: classInfo.id,
      });
      // }
    });
    return classTypes;
  }

  createPrimitiveTypes(
    primitiveTypes: PrimitiveTypeContainer,
    namespace: NamespaceContainer
  ) {
    return primitiveTypes.list.map((pt) => {
      const prefix = namespace.namespaceById[pt.namespaceId].prefix;
      return {
        primitive: true,
        prefix,
        label: pt.name,
        stereotype: pt.stereotype,
        id: pt.id,
      };
    });
  }

  isPrimitive(dataType: DataType) {
    return dataType.primitive !== false;
  }

  validateName(name: string | undefined) {
    if (name === undefined) {
      return false;
    }
    return !!name;
  }

  validateLabel(label: string | undefined) {
    if (label === undefined) {
      return false;
    }
    return !!label;
  }
  getNamespaceSelectData(namespaceContainer: NamespaceContainer) {
    const options: OptionType<string, number>[] = [];
    Object.entries(namespaceContainer.namespaceById).forEach((entry) => {
      const [value, ns] = entry;
      const label = ns.prefix;
      options.push({ label, value: +value });
    });
    return options;
  }
  getDataTypeSelectData() {
    const options: OptionType<string, number>[] = [];
    this.types.forEach((type) => {
      const { label: lbl, prefix, id: value } = type;
      const label = `${prefix}:${lbl}`;
      options.push({ label, value });
    });
    return options;
  }
  relationToOption(relation: RelationType) {
    const { value, template: label } = relation;
    return { value, label };
  }
  getRelationSelectData(relationsContainer: RelationTypeContainer) {
    const options: OptionType<string, number>[] = [];
    relationsContainer.list.forEach((r) => {
      if (r.value !== 2) {
        options.push(this.relationToOption(r));
      }
    });
    return options;
  }
  multiplicityToOption(multiplicity: Multiplicity) {
    const { template: label, value } = multiplicity;
    return { value, label };
  }
  getMultiplicitySelectData(multiplicityContainer: MultiplicityContainer) {
    const options: OptionType<string, number>[] = [];
    multiplicityContainer.list.forEach((m) => {
      options.push(this.multiplicityToOption(m));
    });
    return options;
  }

  changepredicateEditor(predicateName: string, fieldName: string, value: any) {
    const predicateEditor: PredicateEditorInfo | undefined =
      this.state.predicateEditor;
    if (!predicateEditor) {
      return;
    }
    let newPredicateEditor: PredicateEditorInfo = { ...predicateEditor };
    let predicate: PredicateData | null = null;

    switch (predicateName) {
      case PREDICATE:
        predicate = { ...newPredicateEditor.predicate, [fieldName]: value };
        newPredicateEditor.predicate = predicate;
        break;
      case REVERSE_PREDICATE:
        if (newPredicateEditor.relation) {
          predicate = {
            ...newPredicateEditor.relation.inverseRole,
            [fieldName]: value,
          };
          newPredicateEditor.relation.inverseRole = predicate;
        }
        break;
    }
    return newPredicateEditor;
  }

  changeSelect =
    (predicateName: string, fieldName: string) =>
    (option: ValueType<OptionType<string, number>, false>) => {
      const value = (option as OptionType<string, number>).value;
      const newPredicateEditor: PredicateEditorInfo | undefined =
        this.changepredicateEditor(predicateName, fieldName, value);
      this.setState({ ...this.state, predicateEditor: newPredicateEditor });
    };

  changeDataTypeSelect =
    (dataTyoe: DataType, data: ClassInfo) =>
    (option: ValueType<OptionType<string, number>, false>) => {
      const { predicateEditor } = this.state;
      const nextState = { ...this.state };
      if (!predicateEditor) {
        return;
      }
      const newPredicateEditor = { ...predicateEditor };
      const value = (option as OptionType<string, number>).value;
      const dataType = this.getDataTypeById(value);
      if (!dataType) {
        return;
      }
      const { stereotype: st, id: classId } = dataType;
      if (!this.isPrimitive(dataType)) {
        const { relation, predicate } = newPredicateEditor;
        const { namespaceId } = predicate;
        const { id: classId } = dataType;
        // let withReverse =st===2 ||st===3 ? false : true;
        let isRelationHidden = st === 2 || st === 3 ? true : false;
        let type = st === 3 ? RELATION_COMPOSITION : RELATION_ASSOTIATION;
        let inverseRole: PredicateData | undefined = undefined;
        newPredicateEditor.relation = { ...relation, type, classId };
        if (st !== 3 && st !== 2) {
          const { label, name } = data;
          inverseRole = { label, name, classId, multiplicity: 0, namespaceId };
          newPredicateEditor.relation.inverseRole = inverseRole;
        } else if (
          (st === 3 || st === 2) &&
          newPredicateEditor.relation.inverseRole
        ) {
          newPredicateEditor.relation.inverseRole = undefined;
        }
        // nextState.withReverse = withReverse;
        nextState.isRelationHidden = isRelationHidden;
        // newPredicateEditor.predicate.dataTypeId = option.value;
      } else {
        nextState.isRelationHidden = true;
        nextState.withReverse = false;
        newPredicateEditor.relation = undefined;
        // newPredicateEditor.predicate.dataTypeId = option.value;
      }
      newPredicateEditor.predicate.dataTypeId = value;
      this.setState({ ...nextState, predicateEditor: newPredicateEditor });
    };

  isFieldOnValidation(fieldName: string) {
    return fieldName === PREDICATE || fieldName === REVERSE_PREDICATE;
  }
  changeInput =
    (fieldName: string, predicateName: string) => (value: string) => {
      const predicateEditor: PredicateEditorInfo | undefined =
        this.changepredicateEditor(predicateName, fieldName, value);
      const validation = this.state.validation;
      if (validation) {
        switch (fieldName) {
          case "name":
            validation[predicateName].name = this.validateName(value);
            break;
          case "label":
            validation[predicateName].label = this.validateLabel(value);
            break;
        }
      }

      this.setState({ ...this.state, predicateEditor, validation });
    };

  changeMandatoryHandler = (evt: any) => {
    const checked = evt.target.checked;
    const { predicateEditor } = this.state;
    if (predicateEditor) {
      const { predicate } = predicateEditor;
      predicate.multiplicity = checked ? 1 : 0;
      this.setState({ predicateEditor });
    }
  };

  changeSystemDriven =
    (predicateName: string, fieldName: string) => (evt: any) => {
      const checked = evt.target.checked;
      const newPredicateEditor: PredicateEditorInfo | undefined =
        this.changepredicateEditor(predicateName, fieldName, checked);
      this.setState({ ...this.state, predicateEditor: newPredicateEditor });
    };

  renderNamespace(
    predicate: PredicateData,
    namespaceContainer: NamespaceContainer,
    predicateName: string,
    disableEditing: boolean = false
  ) {
    const { namespaceId } = predicate;

    if (namespaceId === undefined) {
      return null;
    }
    const namespace = namespaceContainer.namespaceById[namespaceId];
    if (!namespace) {
      return null;
    }
    const namespaces = this.getNamespaceSelectData(namespaceContainer);

    const { prefix: label } = namespace;
    const checkedValue: OptionType<string, number> = {
      value: namespaceId,
      label,
    };
    // disableEditing = predicate.systemDriven && this.isPredicateEditing()? true : disableEditing;
    return (
      <Form.Group as={Row} controlId="namespace">
        <Col md={3}>
          <Form.Label>
            <FormattedMessage id={"CLASSCARD_NAMESPACE"} />
          </Form.Label>
        </Col>
        <Col md={9}>
          <Select
            className="npt-select"
            isDisabled={this.isPredicateEditing() || disableEditing}
            value={checkedValue}
            menuPosition="fixed"
            isSearchable={true}
            options={namespaces}
            onChange={this.changeSelect(predicateName, "namespaceId")}
          />
        </Col>
      </Form.Group>
    );
  }
  renderIdentifier(
    predicate: PredicateData,
    predicateName: string,
    disableEditing: boolean = false
  ) {
    let { name } = predicate;
    if (name === undefined) {
      name = "";
    }
    // disableEditing = predicate.systemDriven && this.isPredicateEditing()? true : disableEditing;
    return (
      <Form.Group controlId="name">
        <Row>
          <Col md={3}>
            <Form.Label>
              <FormattedMessage id={"CLASSCARD_NAME_OF_PREDICATE"} />
            </Form.Label>
          </Col>
          <Col md={9}>
            <MaskedInput
              disabled={disableEditing}
              className={"form-control" + (name ? "" : " is-invalid")}
              value={name}
              onChange={this.changeInput("name", predicateName)}
              allowedSymbols={NAME_SYMBOLS}
              firstSymbol={NAME_FIRST_SYMBOL}
            />
          </Col>
        </Row>
        {!disableEditing && (
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <Form.Text className="text-info">
                <FormattedMessage
                  id="CLASSCARD_ALLOWED_SYMBOLS"
                  values={{ symbols: NAME_SYMBOLS }}
                />
              </Form.Text>
            </Col>
          </Row>
        )}
      </Form.Group>
    );
  }
  renderLabel(
    predicate: PredicateData,
    predicateName: string,
    disableEditing: boolean = false
  ) {
    let { label } = predicate;
    if (label === undefined) {
      label = "";
    }
    // disableEditing = predicate.systemDriven && this.isPredicateEditing()? true : disableEditing;
    return (
      <Form.Group as={Row} controlId="label">
        <Col md={3}>
          <Form.Label>
            <FormattedMessage id={"CLASSCARD_LABEL_OF_PREDICATE"} />
          </Form.Label>
        </Col>
        <Col md={9}>
          {/* <Form.Control value={label} 
                    onChange={(e:any)=>this.changeAndValidate(  predicateName , 'label', e.target.value)}
                    /> */}
          <MaskedInput
            disabled={disableEditing}
            className={"form-control" + (label ? "" : " is-invalid")}
            value={label}
            // onChange={this.changeInputAndValidate.bind(this, predicateName, 'label')}
            onChange={this.changeInput("label", predicateName)}
            allowedSymbols={LABEL_SYMBOLS}
            firstSymbol={LABEL_FIRST_SYMBOL}
          />
        </Col>
      </Form.Group>
    );
  }
  renderDescription(
    predicate: PredicateData,
    predicateName: string,
    disableEditing: boolean = false
  ) {
    let { description } = predicate;
    if (description === undefined) {
      description = "";
    }
    // disableEditing = predicate.systemDriven && this.isPredicateEditing()? true : disableEditing;
    return (
      <Form.Group as={Row} controlId="description">
        <Col md={3}>
          <Form.Label>
            <FormattedMessage id={"CLASSCARD_DESCRIPTION"} />
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            disabled={disableEditing}
            as="textarea"
            rows={3}
            value={description}
            // onChange={(e: any) => this.changeInputAndValidate(predicateName, 'description', e.target.value)}
            onChange={(e: any) =>
              this.changeInput("description", predicateName)(e.target.value)
            }
          />
        </Col>
      </Form.Group>
    );
  }
  renderDataType(
    predicate: PredicateData,
    data: ClassInfo,
    predicateName: string,
    disableEditing: boolean = false
  ) {
    const { dataTypeId } = predicate;
    const dataType =
      dataTypeId !== undefined && this.getDataTypeById(dataTypeId);
    if (!dataType) {
      return null;
    }
    const types = this.getDataTypeSelectData();
    const { prefix, label, id: value } = dataType;
    const selectedDataType: OptionType<string, number> = {
      label: `${prefix}:${label}`,
      value,
    };
    // disableEditing = predicate.systemDriven && this.isPredicateEditing()? true : disableEditing;
    return (
      <Form.Group as={Row} controlId="dataType">
        <Col md={3}>
          <Form.Label>
            <FormattedMessage id={"CLASSCARD_TYPE_OF_DATA"} />
          </Form.Label>
        </Col>
        <Col md={9}>
          <Select
            className="npt-select"
            isDisabled={this.isPredicateEditing() || disableEditing}
            value={selectedDataType}
            isSearchable={true}
            menuPosition="fixed"
            options={types}
            onChange={this.changeDataTypeSelect(dataType, data)}
          />
        </Col>
      </Form.Group>
    );
  }
  renderPrimitiveMultiplicity(
    predicate: PredicateData,
    disableEditing: boolean = false
  ) {
    const { multiplicity, dataTypeId } = predicate;

    if (multiplicity === undefined || dataTypeId === undefined) {
      return null;
    }
    const dataType = this.getDataTypeById(dataTypeId);
    if (!dataType) {
      return null;
    }
    const checked = Boolean(multiplicity);
    const isPrimitive = this.isPrimitive(dataType);
    if (!isPrimitive) {
      return null;
    }
    // disableEditing = predicate.systemDriven && this.isPredicateEditing() ? true : disableEditing;
    return (
      <Form.Group className="d-flex flex-row" controlId="required">
        <Col md={3} className="px-0">
          <Form.Label>
            <FormattedMessage id={"CLASSCARD_MANDATORY"} />
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Check
            disabled={disableEditing}
            type="checkbox"
            onChange={this.changeMandatoryHandler}
            checked={checked}
          />
          {/* <Form.Check type="checkbox" onClick={()=>{}} checked={checked} /> */}
        </Col>
      </Form.Group>
    );
  }
  renderSystemDriven(
    predicateName: string,
    predicateData: PredicateData,
    disableEditing: boolean = false
  ) {
    let { systemDriven } = predicateData;

    if (systemDriven === undefined) {
      systemDriven = false;
    }
    // disableEditing = this.isPredicateEditing() ? true : disableEditing;
    return (
      <Form.Group className="d-flex flex-row" controlId="required">
        <Col md={3} className="px-0">
          <Form.Label>
            <FormattedMessage id={"CLASSCARD_SYSTEM_DRIVEN"} />
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Check
            disabled={disableEditing}
            type="checkbox"
            onChange={this.changeSystemDriven(predicateName, "systemDriven")}
            checked={systemDriven}
          />
          {/* <Form.Check type="checkbox" onClick={()=>{}} checked={checked} /> */}
        </Col>
      </Form.Group>
    );
  }
  renderRelation(
    predicateEditor: PredicateEditorInfo,
    relationsContainer: RelationTypeContainer
  ) {
    const { dataTypeId } = predicateEditor.predicate;
    const dataType =
      dataTypeId !== undefined && this.getDataTypeById(dataTypeId);

    if (
      !dataType ||
      this.isPrimitive(dataType) ||
      dataType.stereotype === 2 ||
      dataType.stereotype === 3
    ) {
      return null;
    }
    const relations = this.getRelationSelectData(relationsContainer);

    if (!predicateEditor.relation) {
      return null;
    }
    const { type: relationId } = predicateEditor.relation;
    const relation: OptionType<string, number> = this.relationToOption(
      relationsContainer.relationTypeByValue[relationId]
    );
    // const disableEditing = this.isPredicateEditing() ? true : disableEditing;
    return (
      <Form.Group as={Row} controlId="relation">
        <Col md={3}>
          <Form.Label>
            <FormattedMessage id={"CLASSCARD_RELATION_TYPE"} />
          </Form.Label>
        </Col>
        <Col md={9}>
          <Select
            className="npt-select"
            isDisabled={this.isPredicateEditing()}
            value={relation}
            isSearchable={true}
            menuPosition="fixed"
            options={relations}
            onChange={() => {}}
          />
        </Col>
      </Form.Group>
    );
  }
  renderMultiplicity(
    predicate: PredicateData,
    predicateName: string,
    multiplicityContainer: MultiplicityContainer,
    disableEditing: boolean = false
  ) {
    const { multiplicity: multiplicityId, dataTypeId } = predicate;
    if (multiplicityId === undefined) {
      return null;
    }

    const multiplicity =
      multiplicityContainer.multiplicityByValue[multiplicityId];
    if (!multiplicity) {
      return null;
    }

    const multiplicityList = this.getMultiplicitySelectData(
      multiplicityContainer
    );
    let multiplicityOption: OptionType<string, number> =
      this.multiplicityToOption(multiplicity);
    // disableEditing = predicate.systemDriven && this.isPredicateEditing() ? true : disableEditing;
    return (
      <Form.Group as={Row} controlId="multiplicity">
        <Col md={3}>
          <Form.Label>
            <FormattedMessage id={"CLASSCARD_MULTIPLICITY"} />
          </Form.Label>
        </Col>
        <Col md={9}>
          <Select
            className="npt-select"
            isDisabled={disableEditing}
            value={multiplicityOption}
            isSearchable={true}
            options={multiplicityList}
            menuPosition="fixed"
            onChange={this.changeSelect(predicateName, "multiplicity")}
          />
        </Col>
      </Form.Group>
    );
  }

  renderReversePredicate(
    multiplicityContainer: MultiplicityContainer,
    relation?: PredicateRelation
  ) {
    const { withReverse, isRelationHidden } = this.state;

    if (isRelationHidden || !relation) {
      return null;
    }
    const { inverseRole } = relation;

    return (
      <>
        <Form.Group as={Row} controlId="withReverse">
          <Col md={12} className="d-flex flex-direction-row">
            <Form.Check
              disabled={this.isPredicateEditing()}
              type="checkbox"
              onChange={(e: any) =>
                this.setState({ withReverse: !withReverse })
              }
              checked={Boolean(withReverse)}
              style={{ cursor: "pointer" }}
            />
            <Form.Label>
              <FormattedMessage id={"CLASSCARD_CREATE_REVERSE_PREDICATE"} />
            </Form.Label>
          </Col>
        </Form.Group>
        {this.state.withReverse && inverseRole && (
          <Form.Group>
            {this.renderIdentifier(inverseRole, REVERSE_PREDICATE)}
            {this.renderLabel(inverseRole, REVERSE_PREDICATE)}
            {this.renderDescription(inverseRole, REVERSE_PREDICATE)}
            {this.renderMultiplicity(
              inverseRole,
              REVERSE_PREDICATE,
              multiplicityContainer
            )}
            {this.renderSystemDriven(REVERSE_PREDICATE, inverseRole)}
          </Form.Group>
        )}
      </>
    );
  }

  renderTemplate(): React.ReactElement {
    const { card } = this.props;

    const { predicateEditor } = this.state;
    if (!card || !predicateEditor || !card.data) {
      return <></>;
    }
    const { predicate, type, relation } = predicateEditor;
    const {
      namespaceId,
      name,
      label,
      description,
      dataTypeId,
      multiplicity: multiplicityId,
    } = predicate;
    const {
      namespace,
      stereotype,
      primitiveTypes,
      allClasses,
      multiplicity: multiplicityContainer,
      relationType,
    } = card;
    const { predicateList } = card.data;
    this.initializeTypes(primitiveTypes, namespace, allClasses);
    const dataType =
      dataTypeId !== undefined && this.getDataTypeById(dataTypeId);
    const multiplicity =
      multiplicityId !== undefined &&
      multiplicityContainer.multiplicityByValue[multiplicityId];

    const disableEditing =
      this.isPredicateEditing() &&
      !this.isPredicateNative(predicate, predicateList);

    let isHaveMultiplicity = false;
    if (dataType) {
      isHaveMultiplicity =
        !this.isPrimitive(dataType) || dataType.label === "base64Binary";
    }
    return (
      <Form>
        {this.renderNamespace(predicate, namespace, PREDICATE, disableEditing)}
        {this.renderIdentifier(predicate, PREDICATE, disableEditing)}
        {this.renderLabel(predicate, PREDICATE, disableEditing)}
        {this.renderDescription(predicate, PREDICATE, disableEditing)}
        {this.renderDataType(predicate, card.data, PREDICATE, disableEditing)}
        {this.renderRelation(predicateEditor, relationType)}
        {!isHaveMultiplicity &&
          this.renderPrimitiveMultiplicity(predicate, disableEditing)}
        {isHaveMultiplicity &&
          this.renderMultiplicity(
            predicate,
            PREDICATE,
            multiplicityContainer,
            disableEditing
          )}
        {this.renderSystemDriven(PREDICATE, predicate, disableEditing)}
        {this.renderReversePredicate(multiplicityContainer, relation)}
      </Form>
    );
  }

  isPredicateValid() {
    const { validation, isRelationHidden, withReverse } = this.state;
    const { predicateEditor } = this.state;
    if (!validation || !predicateEditor) {
      return false;
    }
    const isPredicateValid =
      validation[PREDICATE].name && validation[PREDICATE].label;
    let isReversePredicateValid = true;
    if (!isRelationHidden && withReverse) {
      isReversePredicateValid =
        validation[REVERSE_PREDICATE].name &&
        validation[REVERSE_PREDICATE].label;
    }
    return isPredicateValid && isReversePredicateValid;
  }

  predicateEditorTypeCheck(predicateEditor: PredicateEditorInfo) {
    const { predicate, relation } = predicateEditor;
    const { namespaceId, multiplicity } = predicate;

    namespaceId !== undefined &&
      (predicateEditor.predicate.namespaceId = +namespaceId);
    multiplicity !== undefined &&
      (predicateEditor.predicate.multiplicity = +multiplicity);
    relation && (relation.type = +relation.type);
    if (relation && relation.inverseRole) {
      const { multiplicity, namespaceId } = relation.inverseRole;
      namespaceId !== undefined &&
        (relation.inverseRole.namespaceId = +namespaceId);
      multiplicity !== undefined &&
        (relation.inverseRole.multiplicity = +multiplicity);
    }
  }

  closeModal(status: ModalStatus, result: any) {
    const { predicateEditor: pe, isRelationHidden, withReverse } = this.state;
    const predicateEditor: PredicateEditorInfo = {
      ...pe,
    } as PredicateEditorInfo;
    const { card } = this.props;

    if (
      status == MODAL_STATUS_OK &&
      this.isPredicateValid() &&
      predicateEditor &&
      card &&
      card.data
    ) {
      const disableEditing =
        this.isPredicateEditing() &&
        !this.isPredicateNative(
          predicateEditor.predicate,
          card.data.predicateList
        );

      if (!disableEditing) {
        const predicateName = `${card.data.name}.${predicateEditor.predicate.name}`;
        predicateEditor.predicate.name = predicateName;

        if (!isRelationHidden && !withReverse && !this.isPredicateEditing()) {
          if (predicateEditor.relation?.inverseRole) {
            delete predicateEditor.relation.inverseRole;
          }
        }
        if (
          predicateEditor.relation &&
          predicateEditor.relation.inverseRole &&
          predicateEditor.relation.inverseRole.classId
        ) {
          const type: DataType | undefined = this.getDataTypeById(
            predicateEditor.relation.inverseRole.classId
          );
          if (type) {
            const typeLabel = type.label;
            const predicateName = predicateEditor.relation.inverseRole.name;
            predicateEditor.relation.inverseRole.name = `${typeLabel}.${predicateName}`;
          }
        }

        this.predicateEditorTypeCheck(predicateEditor);
        this.props.closeModal(status, predicateEditor);
      }
      this.props.closeModal(status, false);
    } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, false);
    }
  }

  render() {
    console.log("save predicate");
    const modal = { ...this.props.modal };
    modal.options = { title: this.title, ...modal.options };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}

export default connect(
  (state: ApplicationState) => {
    return {
      card: state.profileeditor && state.profileeditor.card,
    };
  },
  (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
    return {};
  }
)(SavePredicateModal);
