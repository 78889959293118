import * as React from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { triggerEvent } from "../../services/event";
import {
  Column,
  ColumnResizeInfo,
  FilterData,
  FilterInfo,
  FilterType,
  SortInfo,
} from "../../types/table";

import HeaderFilter from "./filters/HeaderFilter";

import styles from "./Table.module.css";

/**Properties of Table header */
interface TableHeaderProps {
  /**Actual width of table (can be larger than visible part) */
  tableWidth: number;
  /**Current scroll position*/
  scrollLeft: number;
  /**Array of table columns*/
  columns: Column[];
  /**Array of table columns widths*/
  columnsWidths: number[] | null;
  /**Counter of selected rows*/
  selectedRowsLength: number;
  /**Numbers of current page rows*/
  pageRows: number[];
  /**Info about table sorting */
  sortInfo: SortInfo;
  /**Info about table filtering */
  filterInfo: FilterInfo;
  /**Select all rows on page*/
  selectAll: () => void;
  /**Sort table by column*/
  sortByColumn: (field: string) => void;
  /**Filter table by column*/
  filterByColumn: (field: string, filterData: FilterData | null) => void;
  /**Grab column resizer item*/
  grabResizer: (leftIdx: number, rightIdx: number, startX: number) => void;
  /**Fetch available selection values for specified field and filter*/
  fetchColumnSelection: (field: string, filterData: FilterData | null) => void;
  /**Fetch available values range for specified field and filter*/
  fetchColumnRange: (field: string, filterData: FilterData | null) => void;
  /**Open sorting and filtering options modal*/
  openTableSortOptions: () => void;
  /**Open dynamic columns options modal*/
  openTableDynamicColumns: () => void;
}

/********************************
 *    Table Header Component    *
 ********************************/
export default class TableHeader extends React.PureComponent<TableHeaderProps> {
  getColumnWidth(column: Column, idx: number): number | undefined {
    if (this.props.columnsWidths == null) {
      return column.width;
    }
    return this.props.columnsWidths[idx];
  }

  render() {
    return (
      <>
        <ContextMenuTrigger holdToDisplay={-1} id="table_header_context_menu">
          <div
            className={`${styles.nptTableHeader} d-flex flex-row position-relative`}
            style={{
              width: this.props.tableWidth,
              left: -this.props.scrollLeft,
            }}
            onMouseDown={() => triggerEvent({ event: "mousedown" })}
          >
            {this.props.columns.map((column: Column, idx: number) => (
              <HeaderCell
                key={idx}
                width={this.getColumnWidth(column, idx) || 0}
                column={column}
                selectedRowsLength={this.props.selectedRowsLength}
                pageRows={this.props.pageRows}
                sortInfo={this.props.sortInfo}
                filterInfo={this.props.filterInfo}
                selectAll={this.props.selectAll}
                sortByColumn={this.props.sortByColumn}
                filterByColumn={this.props.filterByColumn}
                grabResizer={this.props.grabResizer}
                fetchColumnSelection={this.props.fetchColumnSelection}
                fetchColumnRange={this.props.fetchColumnRange}
              />
            ))}
          </div>
        </ContextMenuTrigger>
        <HeaderContextMenu
          openTableSortOptions={this.props.openTableSortOptions}
          openTableDynamicColumns={this.props.openTableDynamicColumns}
        />
      </>
    );
  }
}

/** Header cell */
interface HeaderCellProps {
  width: number;
  column: Column;
  selectedRowsLength: number;
  pageRows: number[];
  sortInfo: SortInfo;
  filterInfo: FilterInfo;
  selectAll: () => void;
  sortByColumn: (field: string) => void;
  filterByColumn: (field: string, filterData: FilterData | null) => void;
  grabResizer: (leftIdx: number, rightIdx: number, startX: number) => void;
  fetchColumnSelection: (field: string, filterData: FilterData | null) => void;
  fetchColumnRange: (field: string, filterData: FilterData | null) => void;
}
const HeaderCell: React.FunctionComponent<HeaderCellProps> = React.memo(
  (props: HeaderCellProps) => {
    if (props.column.hidden) {
      return null;
    }
    if (props.column.format === "selectColumn") {
      return (
        <HeaderSelectCell
          width={props.width}
          type={props.column.field}
          selected={props.selectedRowsLength !== 0}
          selectedAll={props.selectedRowsLength === props.pageRows.length}
          resizeInfo={props.column.resizeInfo}
          selectAll={props.selectAll}
          grabResizer={props.grabResizer}
        />
      );
    }
    return (
      <HeaderCommonCell
        field={props.column.field}
        width={props.width}
        value={props.column.name}
        sortInfo={props.sortInfo}
        filterInfo={props.filterInfo}
        filterType={props.column.filterType}
        resizeInfo={props.column.resizeInfo}
        sortByColumn={props.sortByColumn}
        filterByColumn={props.filterByColumn}
        grabResizer={props.grabResizer}
        fetchColumnSelection={props.fetchColumnSelection}
        fetchColumnRange={props.fetchColumnRange}
      />
    );
  }
);

/** Header common cell */
interface HeaderCommonCellProps {
  field: string;
  width: number;
  value: string | number | null;
  sortInfo: SortInfo;
  filterInfo: FilterInfo;
  filterType: FilterType | null;
  resizeInfo?: ColumnResizeInfo;
  sortByColumn: (field: string) => void;
  filterByColumn: (field: string, filterData: FilterData | null) => void;
  grabResizer: (leftIdx: number, rightIdx: number, startX: number) => void;
  fetchColumnSelection: (field: string, filterData: FilterData | null) => void;
  fetchColumnRange: (field: string, filterData: FilterData | null) => void;
}
const HeaderCommonCell: React.FunctionComponent<HeaderCommonCellProps> =
  React.memo((props: HeaderCommonCellProps) => {
    const cellRef = React.useRef<HTMLDivElement>(null);
    let className = `column_${props.field} ${styles.nptTableCell} ${styles.sortable}`;
    if (
      props.sortInfo.columns.findIndex(
        (columnSortInfo) => props.field === columnSortInfo.field
      ) !== -1
    ) {
      className += ` ${styles.sorted}`;
    }
    return (
      <div
        className={className}
        style={{ minWidth: props.width }}
        onClick={props.sortByColumn.bind(this, props.field)}
        ref={cellRef}
      >
        <span>{props.value}</span>
        <HeaderOrderItem field={props.field} sortInfo={props.sortInfo} />
        <HeaderFilter
          field={props.field}
          filterInfo={props.filterInfo}
          filterType={props.filterType}
          headerRef={cellRef}
          filterByColumn={props.filterByColumn}
          fetchColumnSelection={props.fetchColumnSelection}
          fetchColumnRange={props.fetchColumnRange}
        />
        <HeaderResizeTool
          resizeInfo={props.resizeInfo}
          grabResizer={props.grabResizer}
        />
      </div>
    );
  });

/** Header selection cell */
interface HeaderSelectCellProps {
  width: number;
  type: string;
  selected: boolean;
  selectedAll: boolean;
  resizeInfo?: ColumnResizeInfo;
  selectAll: () => void;
  grabResizer: (leftIdx: number, rightIdx: number, startX: number) => void;
}
const HeaderSelectCell: React.FunctionComponent<HeaderSelectCellProps> =
  React.memo((props: HeaderSelectCellProps) => {
    let checkboxRef: HTMLInputElement | null = null;
    let checked: boolean = false;
    let indeterminate: boolean = false;
    if (props.selectedAll) {
      checked = true;
    } else if (props.selected) {
      indeterminate = true;
    }
    React.useEffect(() => {
      if (!checkboxRef) {
        return;
      }
      const checkbox = ReactDOM.findDOMNode(checkboxRef);
      if (!checkbox) {
        return;
      }
      (checkbox as any).indeterminate = indeterminate;
    }, [indeterminate]);
    if (props.type !== "checkbox") {
      return (
        <div
          style={{ minWidth: props.width }}
          className={`${styles.nptTableCell} ${styles.nptTableSelectCell} d-flex flex-column`}
        >
          <HeaderResizeTool
            resizeInfo={props.resizeInfo}
            grabResizer={props.grabResizer}
          />
        </div>
      );
    }
    return (
      <div
        style={{ minWidth: props.width }}
        className={`${styles.nptTableCell} ${styles.nptTableSelectCell} d-flex flex-column`}
      >
        <input
          type={props.type}
          checked={checked}
          onChange={props.selectAll}
          ref={(ref) => (checkboxRef = ref)}
        ></input>
        <HeaderResizeTool
          resizeInfo={props.resizeInfo}
          grabResizer={props.grabResizer}
        />
      </div>
    );
  });

/** Item that shows direction of sorting for column */
interface HeaderOrderItemProps {
  field: string;
  sortInfo: SortInfo;
}
const HeaderOrderItem: React.FunctionComponent<HeaderOrderItemProps> =
  React.memo((props: HeaderOrderItemProps) => {
    const columnSortInfo = props.sortInfo.columns.find(
      (columnSortInfo) => columnSortInfo.field === props.field
    );
    if (!columnSortInfo) {
      return null;
    }
    const isAsc = columnSortInfo.direction === "asc";
    return (
      <span
        className={`${styles.order} fa fa-angle-${isAsc ? "down" : "up"}`}
      ></span>
    );
  });

/** Resize tool that allow user to change column width on grab and drag */
interface HeaderResizeToolProps {
  resizeInfo?: ColumnResizeInfo;
  grabResizer: (leftIdx: number, rightIdx: number, startX: number) => void;
}
const HeaderResizeTool: React.FunctionComponent<HeaderResizeToolProps> =
  React.memo((props: HeaderResizeToolProps) => {
    if (!props.resizeInfo) {
      return null;
    }
    return (
      <>
        <HeaderResizeItem
          side="left"
          leftIdx={props.resizeInfo.prevColumnIdx}
          rightIdx={props.resizeInfo.curColumnIdx}
          grabResizer={props.grabResizer}
        />
        <HeaderResizeItem
          side="right"
          leftIdx={props.resizeInfo.curColumnIdx}
          rightIdx={props.resizeInfo.nextColumnIdx}
          grabResizer={props.grabResizer}
        />
      </>
    );
  });

/** Single resize item that handles grabbing action */
interface HeaderResizeItemProps {
  side: "left" | "right";
  leftIdx?: number;
  rightIdx?: number;
  grabResizer: (leftIdx: number, rightIdx: number, startX: number) => void;
}
const HeaderResizeItem: React.FunctionComponent<HeaderResizeItemProps> =
  React.memo((props: HeaderResizeItemProps) => {
    const leftIdx = props.leftIdx;
    const rightIdx = props.rightIdx;
    if (typeof leftIdx === "undefined" || typeof rightIdx === "undefined") {
      return null;
    }
    const grabResizer = (
      reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      reactEvent.preventDefault();
      reactEvent.stopPropagation();
      props.grabResizer(leftIdx, rightIdx, reactEvent.pageX);
    };
    const stopPropagation = (
      reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      reactEvent.preventDefault();
      reactEvent.stopPropagation();
    };
    const sideClassName = props.side === "left" ? styles.left : styles.right;
    return (
      <div
        className={`${styles.nptTableHeaderResizer} ${sideClassName}`}
        onMouseDown={grabResizer}
        onClick={stopPropagation}
      ></div>
    );
  });

/** Single resize item that handles grabbing action */
interface HeaderContextMenuProps {
  openTableSortOptions: () => void;
  openTableDynamicColumns: () => void;
}
const HeaderContextMenu: React.FunctionComponent<HeaderContextMenuProps> =
  React.memo((props) => {
    return (
      <ContextMenu id="table_header_context_menu">
        <MenuItem onClick={props.openTableSortOptions}>
          <FormattedMessage
            id="NPT_TABLE_SORT_OPTIONS"
            defaultMessage="Sort/filter options"
            description="Show options for sorting and filtering"
          />
        </MenuItem>
        <MenuItem onClick={props.openTableDynamicColumns}>
          <FormattedMessage
            id="NPT_TABLE_DYNAMIC_COLUMNS_OPTIONS"
            defaultMessage="Columns options"
            description="Show options for dynamic columns"
          />
        </MenuItem>
      </ContextMenu>
    );
  });
