import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import ModalView from '../ModalView';

interface ModalPasswordProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void
}

interface ModalPasswordState {
    oldPass: string,
    repeatedOldPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    containSymbols: boolean
    acceptLenght: boolean
    passMismatch: boolean | null
}

export default class PasswordModal extends React.Component<ModalPasswordProps, ModalPasswordState> {

    title = { id: "USERMENU_CHANGE_PASSWORD" };
    state = {
        oldPass: "",
        oldPassword: "",
        repeatedOldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        containSymbols: false,
        acceptLenght: false,
        passMismatch: null
    };
    constructor(props: ModalPasswordProps) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    /* TODO: add password check */
    checkNewPassword(): boolean {
        return this.state.newPassword.length != 0;
    }

    checkConfirmPassword(): boolean {
        return this.state.newPassword?.trim() == this.state.confirmNewPassword?.trim();
    }
    checkConfirmOldPassword(): boolean {
        return !this.state.oldPassword?.trim()
    }
    changeInputValue = (key: string) => (event: any) => {

        const newValue = event.target.value;
        let newState: ModalPasswordState = { ...this.state, [key]: newValue };
        if (key === 'newPassword') {
            newState = { ...newState, ...this.isNewValid(newValue) }
        }
        this.setState(newState);
    }
    passwordNotEmpty() {
        const { confirmNewPassword, newPassword } = this.state;
        return Boolean(confirmNewPassword) || Boolean(newPassword)
    }
    renderPasswordMismatch() {
        const { newPassword, confirmNewPassword } = this.state;

        if (!newPassword?.trim() && !confirmNewPassword?.trim()) {
            return null
        }
        const match = newPassword?.trim() === confirmNewPassword?.trim()
        return (
            (<Form.Text>
                <FontAwesomeIcon className={`mr-1 font-weight-bold text-success ${match ? 'text-success' : 'text-danger'}`} icon={match ? faCheck : faTimes} />
                <FormattedMessage id={"REQUIREMENT_SAME"} />
            </Form.Text>)
        )
    }
    renderTemplate(): React.ReactElement {
        const { acceptLenght, containSymbols, oldPass } = this.state;
        return (
            <Form>
                <Form.Group  >
                    <Form.Label>
                        <FormattedMessage id={"CHANGE_PASSWORD_PREV_PWD"} />
                    </Form.Label>
                    <Form.Control type="password" style={{ display: 'none' }} value={this.state.oldPass} onChange={this.changeInputValue("oldPass")} />
                    <Form.Control type="password" isInvalid={this.checkConfirmOldPassword()} value={this.state.oldPassword} onChange={this.changeInputValue("oldPassword")} />
                </Form.Group>
                <Form.Group controlId="newPassword">
                    <Form.Label>
                        <FormattedMessage id={"CHANGE_PASSWORD_NEW_PWD"} />
                    </Form.Label>
                    <Form.Control type="password" isInvalid={!this.checkNewPassword()} value={this.state.newPassword} onChange={this.changeInputValue("newPassword")} />
                </Form.Group>
                <Form.Group controlId="confirmNewPassword">
                    <Form.Label>
                        <FormattedMessage id={"CHANGE_PASSWORD_CONF_NEW_PWD"} />
                    </Form.Label>
                    <Form.Control type="password" isInvalid={!this.checkConfirmPassword()} value={this.state.confirmNewPassword} onChange={this.changeInputValue("confirmNewPassword")} />
                </Form.Group>


                <Form.Group controlId="confirmNewPassword">
                    <Form.Text>
                        <FontAwesomeIcon className={`mr-1 font-weight-bold ${containSymbols ? 'text-success' : 'text-danger'}`} icon={containSymbols ? faCheck : faTimes} />
                        <FormattedMessage id={"REQUIREMENT_ONLY_NUMBERS_AND_LETTERS"} />

                    </Form.Text>
                    <Form.Text>
                        <FontAwesomeIcon className={`mr-1 font-weight-bold ${acceptLenght ? 'text-success' : 'text-danger'}`} icon={acceptLenght ? faCheck : faTimes} />
                        <FormattedMessage id={"REQUIREMENT_MIN_SIX_SYMBOLS"} />
                    </Form.Text>
                    {this.renderPasswordMismatch()}
                </Form.Group>
            </Form>
        );
    }

    isNewValid(newPassword: string) {
        const lettersAndNumbers = new RegExp(/^[a-zA-Z0-9!@#$%^&*-]*$/);
        return { acceptLenght: newPassword.trim().length >= 6, containSymbols: newPassword?.trim() && lettersAndNumbers.test(newPassword) || false };
    }
    closeModal(status: ModalStatus, result: any) {
        const { acceptLenght, containSymbols } = this.state;
        const oldNotEmpty = this.state.oldPassword?.trim()
        if (status == MODAL_STATUS_OK && acceptLenght && containSymbols && this.checkConfirmPassword() && oldNotEmpty) {
            this.props.closeModal(status, { currentPassword: this.state.oldPassword.trim(), newPassword: this.state.newPassword.trim() });
            return;
        } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
            this.props.closeModal(status, result);
        }
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}
