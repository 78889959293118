import { Reducer } from 'redux';

import {
    SendFinderHidden,
    SendFinderFragmentsLoading,
    ServerFinderFragment,
    SendFinderFragments,
    SendFinderSelectFragment,
    SendFinderClassesLoading,
    SendFinderClasses,
    ServerFinderClass,
    SendFinderSelectClass,
    SendFinderFieldsLoading,
    SendFinderFields,
    ServerFinderField,
    SendFinderObjectcard,
    SendFinderObjectcardError,
    FinderObjectcard,
    SendFinderView,
    FinderViewType,
    SendFinderCriteriaAdd,
    SendFinderCriteriaRelationAdd,
    SendFinderCriteriaRemove,
    SendFinderCriteriaRelationRemove,
    SendFinderCriteriaRelationsUnlock,
    SendFinderCriteriaRelation,
    FinderRelation,
    SendFinderPredicate,
    FinderPredicate,
    SendFinderCriteriaField,
    SendFinderFragmentsTreeHeader,
    FinderReducerState,
    FinderState,
    FinderData,
    FinderAction,
    SendFinderSearch,
    SendFinderOptions,
    FinderOptions,
    SendFinderChangesConfirm,
    SendFinderChangesDeny,
    SendFinderFragmentsTreeError,
    SendFinderFragmentsError,
    ServerFinderFilter
} from '../types/finder';
import { TreeHeader } from '../types/tree';
import * as constants from '../constants/finder';
import {
    addNewCriteria,
    addRelation,
    changeField,
    changeRelation,
    classesReceived,
    fieldsReceived,
    fragmentsErrorReceived,
    fragmentsReceived,
    fragmentsTreeHeaderErrorReceived,
    fragmentsTreeHeaderReceived,
    importFinderOptions,
    checkFinderReadyState,
    objectcardErrorReceived,
    objectcardReceived,
    predicateReceived,
    removeCriteria,
    removeRelation,
    selectClass,
    selectFragment,
    unlockCriteriaRelations,
    waitForClasses,
    waitForField,
    waitForFragments
} from '../services/finder';

const DEFAULT_STATE: FinderReducerState = {};

export const DEFAULT_FINDER_STATE: FinderState = {
    finderId: "default",
    initialized: false,
    isReady: false,
    isFetching: false,
    isHidden: false,
    options: {},
    loadedFields: {
        byId: {},
        fetched: {},
        loading: {},
        idByPredicateName: {},
        children: {},
        rootIds: [],
        allIds: []
    },
    loadedPredicates: {
        byName: {}
    },
    loadedObjectcards: {
        byId: {},
        fetched: {}
    },
    loadedEnumerations: {
        byId: {},
        fetched: {},
        idByPredicateName: {},
        rootIds: [],
        allIds: []
    },
    loadedFragments: {
        byId: {},
        fetched: {},
        loading: {},
        error: {},
        children: {},
        rootIds: [],
        allIds: []
    },
    loadedClasses: {
        byId: {},
        fetched: {},
        loading: {},
        selection: {},
        idByClassId: {},
        children: {},
        allIds: []
    },
    fragmentsTreeHeader: null,
    fragmentsTreeHeaderError: false,
    classMap: {},
    data: {
        criteria: {
            byId: {},
            allIds: []
        },
        criteriaGroup: {
            byId: {},
            allIds: []
        },
        criteriaGroupList: [],
        sideBar: {
            fragmentLevels: [],
            classLevels: [],
            selectedClass: null
        },
        searchString: null
    },
    changes: null,
    view: constants.FINDER_VIEW_TYPE_ADD,
    initialFilter: null
}

/*********************
 * Utility funcitons *
 *********************/

function initializeChangedData(finderState: FinderState): FinderData {
    let finderChanges: FinderData;
    if (finderState.changes) {
        finderChanges = { ...finderState.changes };
    } else {
        finderChanges = { ...finderState.data };
    }
    return finderChanges;
}

/****************
 *   Reducers   *
 ****************/

function receiveFinderOptions(state: FinderReducerState, payload: { options: FinderOptions, filter?: ServerFinderFilter | null }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    importFinderOptions(finderState, payload.options, payload.filter);
    checkFinderReadyState(finderState);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderHidden(state: FinderReducerState, payload: { hidden?: boolean }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    if (typeof payload.hidden === "undefined") {
        finderState.isHidden = !finderState.isHidden;
    } else {
        finderState.isHidden = payload.hidden;
    }
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderView(state: FinderReducerState, payload: { view: FinderViewType }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.view = payload.view;
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderFragmentsLoading(state: FinderReducerState, payload: { parentId: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    waitForFragments(finderState, payload.parentId);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderFragmentsTreeHeader(state: FinderReducerState, payload: { treeHeader: TreeHeader }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    fragmentsTreeHeaderReceived(finderState, payload.treeHeader);
    checkFinderReadyState(finderState);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderFragmentsTreeError(state: FinderReducerState, payload: null, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    fragmentsTreeHeaderErrorReceived(finderState);
    checkFinderReadyState(finderState);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderFragments(state: FinderReducerState, payload: { parentId: string, fragments: ServerFinderFragment[] }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    fragmentsReceived(finderState, payload.parentId, payload.fragments);
    checkFinderReadyState(finderState);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderFragmentsError(state: FinderReducerState, payload: { parentId: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    fragmentsErrorReceived(finderState, payload.parentId);
    checkFinderReadyState(finderState);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderSelectFragment(state: FinderReducerState, payload: { oldFragmentId: string | null, newFragmentId: string | null, force?: boolean }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    if (payload.force) {
        finderState.data = { ...finderState.data };
        selectFragment(finderState.data, payload.oldFragmentId, payload.newFragmentId, finderState.loadedFragments);
        if (finderState.changes) {
            finderState.changes = { ...finderState.changes };
            selectFragment(finderState.changes, payload.oldFragmentId, payload.newFragmentId, finderState.loadedFragments);
        }
        checkFinderReadyState(finderState);
    } else {
        finderState.changes = initializeChangedData(finderState);
        finderState.changes = { ...finderState.changes };
        selectFragment(finderState.changes, payload.oldFragmentId, payload.newFragmentId, finderState.loadedFragments);
    }
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderClassesLoading(state: FinderReducerState, payload: { levelId: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    waitForClasses(finderState, payload.levelId);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderClasses(state: FinderReducerState, payload: { levelId: string, classes: ServerFinderClass[] }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    classesReceived(finderState, payload.levelId, payload.classes);
    checkFinderReadyState(finderState);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderSelectClass(state: FinderReducerState, payload: { oldClassId: string | null, newClassId: string | null, classLevelIdx: number }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = initializeChangedData(finderState);
    selectClass(finderState.changes, payload.oldClassId, payload.newClassId, payload.classLevelIdx);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderFieldsLoading(state: FinderReducerState, payload: { parentId: string | null }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    waitForField(finderState, payload.parentId);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderFields(state: FinderReducerState, payload: { parentId: string | null, fields: ServerFinderField[] }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    let filteredData = payload.fields;
    /**TODO: add binding */
    // if (tableData.automation && tableData.automation.predicateFilterBinding) {
    //     const filterFunc = retrieveFunction(tableData.automation.predicateFilterBinding);
    //     filteredData = filteredData.filter((field) => filterFunc(field.predicate));
    // };
    fieldsReceived(finderState, payload.parentId, filteredData);
    checkFinderReadyState(finderState);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderObjectcard(state: FinderReducerState, payload: { rdfId: string, objectcard: FinderObjectcard }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    objectcardReceived(finderState, payload.rdfId, payload.objectcard);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderObjectcardError(state: FinderReducerState, payload: { rdfId: string, error: Error }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    objectcardErrorReceived(finderState, payload.rdfId, payload.error);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderCriteriaAdd(state: FinderReducerState, payload: { criteriaGroupId?: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = initializeChangedData(finderState);
    addNewCriteria(finderState.changes, payload.criteriaGroupId || null);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderCriteriaRelationAdd(state: FinderReducerState, payload: { criteriaId: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = initializeChangedData(finderState);
    addRelation(finderState.changes, payload.criteriaId);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderCriteriaRemove(state: FinderReducerState, payload: { criteriaId: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = initializeChangedData(finderState);
    removeCriteria(finderState.changes, payload.criteriaId);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderCriteriaRelationRemove(state: FinderReducerState, payload: { criteriaId: string, relationIdx: number }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = initializeChangedData(finderState);
    removeRelation(finderState.changes, payload.criteriaId, payload.relationIdx);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderCriteriaRelationsUnlock(state: FinderReducerState, payload: { criteriaId: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.data = { ...finderState.data };
    unlockCriteriaRelations(finderState.data, payload.criteriaId);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderCriteriaRelation(state: FinderReducerState, payload: { criteriaId: string, relationIdx: number, relation: FinderRelation }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = initializeChangedData(finderState);
    changeRelation(finderState.changes, payload.criteriaId, payload.relationIdx, payload.relation);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderPredicate(state: FinderReducerState, payload: { predicateName: string, predicate: FinderPredicate, fieldId?: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    predicateReceived(finderState, payload.predicateName, payload.predicate, payload.fieldId);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderCriteriaField(state: FinderReducerState, payload: { criteriaId: string, fieldId: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = initializeChangedData(finderState);
    changeField(finderState.changes, payload.criteriaId, payload.fieldId, finderState.loadedFields);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderSearch(state: FinderReducerState, payload: { value: string }, finderId: string): FinderReducerState {
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = initializeChangedData(finderState);
    finderState.changes.searchString = payload.value;
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderChangesConfirm(state: FinderReducerState, payload: null, finderId: string): FinderReducerState {
    if(!state[finderId]){
        return state;
    }
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.data = { ...finderState.data, ...finderState.changes };
    finderState.changes = null;
    checkFinderReadyState(finderState);
    nextState[finderId] = finderState;
    return nextState;
}

function receiveFinderChangesDeny(state: FinderReducerState, payload: null, finderId: string): FinderReducerState {
    if(!state[finderId]){
        return state;
    }
    const nextState = { ...state };
    const finderState = { ...(nextState[finderId] || DEFAULT_FINDER_STATE), finderId };
    finderState.changes = null;
    nextState[finderId] = finderState;
    return nextState;
}

const reducer: Reducer<FinderReducerState, FinderAction> = (state: FinderReducerState = DEFAULT_STATE, action: FinderAction): FinderReducerState => {
    switch (action.type) {
        case constants.SEND_FINDER_OPTIONS: return receiveFinderOptions(state, (action as SendFinderOptions).payload, (action as SendFinderOptions).finderId);

        case constants.SEND_FINDER_HIDDEN: return receiveFinderHidden(state, (action as SendFinderHidden).payload, (action as SendFinderHidden).finderId);
        case constants.SEND_FINDER_VIEW: return receiveFinderView(state, (action as SendFinderView).payload, (action as SendFinderView).finderId);
        case constants.SEND_FINDER_FRAGMENTS_LOADING: return receiveFinderFragmentsLoading(state, (action as SendFinderFragmentsLoading).payload, (action as SendFinderFragmentsLoading).finderId);
        case constants.SEND_FINDER_FRAGMENTS_TREE_HEADER: return receiveFinderFragmentsTreeHeader(state, (action as SendFinderFragmentsTreeHeader).payload, (action as SendFinderFragmentsTreeHeader).finderId);
        case constants.SEND_FINDER_FRAGMENTS_TREE_ERROR: return receiveFinderFragmentsTreeError(state, (action as SendFinderFragmentsTreeError).payload, (action as SendFinderFragmentsTreeError).finderId);
        case constants.SEND_FINDER_FRAGMENTS: return receiveFinderFragments(state, (action as SendFinderFragments).payload, (action as SendFinderFragments).finderId);
        case constants.SEND_FINDER_FRAGMENTS_ERROR: return receiveFinderFragmentsError(state, (action as SendFinderFragmentsError).payload, (action as SendFinderFragmentsError).finderId);
        case constants.SEND_FINDER_SELECT_FRAGMENT: return receiveFinderSelectFragment(state, (action as SendFinderSelectFragment).payload, (action as SendFinderSelectFragment).finderId);
        case constants.SEND_FINDER_CLASSES_LOADING: return receiveFinderClassesLoading(state, (action as SendFinderClassesLoading).payload, (action as SendFinderClassesLoading).finderId);
        case constants.SEND_FINDER_CLASSES: return receiveFinderClasses(state, (action as SendFinderClasses).payload, (action as SendFinderClasses).finderId);
        case constants.SEND_FINDER_SELECT_CLASS: return receiveFinderSelectClass(state, (action as SendFinderSelectClass).payload, (action as SendFinderSelectClass).finderId);

        case constants.SEND_FINDER_FIELDS_LOADING: return receiveFinderFieldsLoading(state, (action as SendFinderFieldsLoading).payload, (action as SendFinderFieldsLoading).finderId);
        case constants.SEND_FINDER_FIELDS: return receiveFinderFields(state, (action as SendFinderFields).payload, (action as SendFinderFields).finderId);
        case constants.SEND_FINDER_OBJECTCARD: return receiveFinderObjectcard(state, (action as SendFinderObjectcard).payload, (action as SendFinderObjectcard).finderId);
        case constants.SEND_FINDER_OBJECTCARD_ERROR: return receiveFinderObjectcardError(state, (action as SendFinderObjectcardError).payload, (action as SendFinderObjectcardError).finderId);
        case constants.SEND_FINDER_CRITERIA_ADD: return receiveFinderCriteriaAdd(state, (action as SendFinderCriteriaAdd).payload, (action as SendFinderCriteriaAdd).finderId);
        case constants.SEND_FINDER_CRITERIA_RELATION_ADD: return receiveFinderCriteriaRelationAdd(state, (action as SendFinderCriteriaRelationAdd).payload, (action as SendFinderCriteriaRelationAdd).finderId);
        case constants.SEND_FINDER_CRITERIA_REMOVE: return receiveFinderCriteriaRemove(state, (action as SendFinderCriteriaRemove).payload, (action as SendFinderCriteriaRemove).finderId);
        case constants.SEND_FINDER_CRITERIA_RELATION_REMOVE: return receiveFinderCriteriaRelationRemove(state, (action as SendFinderCriteriaRelationRemove).payload, (action as SendFinderCriteriaRelationRemove).finderId);
        case constants.SEND_FINDER_CRITERIA_RELATIONS_UNLOCK: return receiveFinderCriteriaRelationsUnlock(state, (action as SendFinderCriteriaRelationsUnlock).payload, (action as SendFinderCriteriaRelationsUnlock).finderId);
        case constants.SEND_FINDER_CRITERIA_RELATION: return receiveFinderCriteriaRelation(state, (action as SendFinderCriteriaRelation).payload, (action as SendFinderCriteriaRelation).finderId);
        case constants.SEND_FINDER_PREDICATE: return receiveFinderPredicate(state, (action as SendFinderPredicate).payload, (action as SendFinderPredicate).finderId);
        case constants.SEND_FINDER_CRITERIA_FIELD: return receiveFinderCriteriaField(state, (action as SendFinderCriteriaField).payload, (action as SendFinderCriteriaField).finderId);

        case constants.SEND_FINDER_SEARCH: return receiveFinderSearch(state, (action as SendFinderSearch).payload, (action as SendFinderSearch).finderId);

        case constants.SEND_FINDER_CHANGES_CONFIRM: return receiveFinderChangesConfirm(state, (action as SendFinderChangesConfirm).payload, (action as SendFinderChangesConfirm).finderId);
        case constants.SEND_FINDER_CHANGES_DENY: return receiveFinderChangesDeny(state, (action as SendFinderChangesDeny).payload, (action as SendFinderChangesDeny).finderId);
    }
    return state;
}

export default reducer;