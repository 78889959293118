import React, { ChangeEvent, CSSProperties, FC } from "react";
import shortid from "shortid";
import { fetchAutocomplete } from "../../actions/subject";
import { AutocompleteOnChange } from "../../types/autocomplete";
import debounce from "../debounce/debounce";
import { AutocompleteDropdown } from "./AutocompleteDropdown";

interface AutocompleteSize {
  top: number;
  left: number;
  width: number;
}

interface AutocompleteInputProps {
  className?: string;
  value?: string;
  contextSearch?: string | object;
  onChange?: (e: React.ChangeEvent) => void;
  autocomplete: string;
  model?: string;
  onAutocomplete?: (e: any) => void;
}
interface AutocompleteInputState {
  autocompleteList: string[] | null;
  autocompleteLoading: boolean;
  autocompleteError: string | null;
  showAutocomplete: boolean;
  value: string;
  autocompleteSize: AutocompleteSize | null;
}

export class AutocompleteInput extends React.Component<
  AutocompleteInputProps,
  AutocompleteInputState
> {
  private textInput: HTMLInputElement | null = null;
  private autocompleteRef: HTMLDivElement | null = null;
  private debounceFunc: any;
  constructor(props: AutocompleteInputProps) {
    super(props);

    this.state = {
      autocompleteList: null,
      autocompleteLoading: false,
      autocompleteError: null,
      autocompleteSize: null,
      showAutocomplete: false,
      value: props.value ? `${props.value}` : "",
    };
    this.debounceFunc = debounce(
      () => {
        this.requestAutocomplete(this.state.value);
      },
      500,
      false
    );
  }
  componentDidMount() {
    document.addEventListener("click", this.hideDropdownHandler);
  }
  componentWillUnmount(): void {
    document.removeEventListener("click", this.hideDropdownHandler);
  }
  componentDidUpdate(prevProps: Readonly<AutocompleteInputProps>): void {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ value: value || "" });
    }
  }

  hideDropdownHandler = (e: any) => {
    const { showAutocomplete } = this.state;
    const closestInput = e.target.closest(`.autocomplete-input`);
    const closestDD = e.target.closest(`.autocompete-dd`);

    if (
      showAutocomplete &&
      (closestInput || closestDD) &&
      (this.textInput?.isEqualNode(e.target) ||
        this.autocompleteRef?.isEqualNode(e.target))
    ) {
      return;
    }
    this.setState({ showAutocomplete: false });
  };

  requestAutocomplete = async (text: string) => {
    const { autocomplete: script, contextSearch } = this.props;

    if (!script) {
      return;
    }
    this.setState({ autocompleteLoading: true, showAutocomplete: true });
    try {
      const result: any = await fetchAutocomplete(script, text, contextSearch);
      if (result?.list) {
        this.setState({
          autocompleteList: result.list,
          autocompleteLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        autocompleteList: [],
        autocompleteError: "error",
        autocompleteLoading: false,
      });
    }
  };
  onFocus = () => {
    const { autocomplete: autocompleteScript } = this.props;
    if (!autocompleteScript) {
      return;
    }
    const { autocompleteList, showAutocomplete, autocompleteLoading, value } =
      this.state;

    if (!autocompleteList && value) {
      this.requestAutocomplete(value);
    }

    this.setState({ showAutocomplete: true }, () => {
      if (!this.state.value && !autocompleteList?.length) {
        this.requestAutocomplete("");
      }
    });
  };

  renderInput = () => {
    const { value } = this.state;
    const {
      onAutocomplete,
      model,
      autocomplete,
      value: v,
      onChange: chn,
      contextSearch,
      className,
      ...rest
    } = this.props;
    return (
      <input
        id={shortid.generate()}
        ref={(input) => (this.textInput = input)}
        onChange={this.handleChange}
        onFocus={this.onFocus}
        value={value}
        className={`${className} autocomplete-input`}
        {...rest}
      />
    );
  };

  onItemClick = (value: string | object) => {
    const { onAutocomplete, onChange } = this.props;
    this.setState({ showAutocomplete: false });
    if (onAutocomplete && this.textInput) {
      const newInput: any = { ...this.textInput, value };
      const event = {
        target: newInput,
        type: "autocomplete",
      };
      onAutocomplete(event);
    }
    const strValue =
      typeof value === "string" ? value : (value as any)?.value || null;
    if (!onChange) {
      this.setState({
        value: strValue,
      });
    }
  };

  handleChange = (e: any) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(e);
      this.debounceFunc();
    } else {
      this.setState({ value: e.target.value }, () => {
        this.debounceFunc();
      });
    }
  };
  getAutocompleteStyle = () => {
    if (!this.textInput) {
      return null;
    }
    const inputRect = this.textInput.getBoundingClientRect();
    const { top, height, left, width } = inputRect;

    const autocompleteHeight = 130;
    let autocompleteY = top + height;
    const autocompleteX = left;
    const autocompleteWidth = width;

    const bodyRect = document.body.getBoundingClientRect();
    const bodyBottom = bodyRect.bottom;
    if (bodyBottom < autocompleteY + autocompleteHeight) {
      autocompleteY = top - autocompleteHeight;
    }
    const autocompleteSize: CSSProperties = {
      left: autocompleteX,
      top: autocompleteY,
      width: autocompleteWidth,
      zIndex: 999,
    };
    return autocompleteSize;
  };
  setAutocompleteRef = (ref: HTMLDivElement | null) => {
    this.autocompleteRef = ref;
  };
  getAutocomplete = () => {
    const {
      autocompleteList,
      autocompleteError: error,
      showAutocomplete,
      autocompleteLoading: loading,
    } = this.state;
    const style = this.getAutocompleteStyle();
    if (!showAutocomplete || !autocompleteList) {
      return null;
    }

    return (
      <AutocompleteDropdown
        setRef={this.setAutocompleteRef}
        loading={!!loading}
        style={style || undefined}
        error={error}
        parentClass={"autocomplete-input"}
        onClose={() => this.setState({ showAutocomplete: false })}
        list={autocompleteList}
        onItemClick={this.onItemClick}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderInput()}
        {this.getAutocomplete()}
      </>
    );
  }
}
