import { Reducer } from 'redux';

import { LocaleState, LocaleAction } from '../types/locale';
import * as constants from '../constants/locale';


const reducer: Reducer<LocaleState, LocaleAction> = (state: LocaleState = {language: 'en'}, action: LocaleAction): LocaleState => {
    switch (action.type) {
        case constants.CHANGE_LANGUAGE:
            return { ...state, language: action.payload.language };
    }
    return state;
}

export default reducer;