import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { FormattedMessage, injectIntl } from 'react-intl';
import debounce from '../debounce/debounce';

const SEARCH_MENU_CLASS = 'npt-search-menu';

export interface MenuSearchProps {
    intl: any
    loading?: boolean
    search: (text: string) => void
}

export interface MenuSearchState {
    text: string
}

/**
 * https://hackernoon.com/add-keyboard-shortcuts-to-your-web-app-ba358016ff05
 */
class MenuSearch extends React.Component<MenuSearchProps, MenuSearchState> {

    private searchRef = React.createRef<HTMLInputElement>();

    private debouncedSearch: () => void;

    constructor(props: MenuSearchProps) {
        super(props)

        this.state = {
            text: ''
        }

        this.handleSearch = this.handleSearch.bind(this)
        this.handleGlobalKeyDown = this.handleGlobalKeyDown.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)

        const _this = this;
        this.debouncedSearch = debounce(() => {
            _this.handleSearch(_this.state.text)
        }, 500, false);
    }

    handleSearch(text: string) {
        if (this.props.loading) {
            //Do not send requests while loading
            return;
        }
        this.props.search(text);
    }

    handleGlobalKeyDown(evt: KeyboardEvent) {
        if (evt.altKey) {
            if (evt.keyCode == 70) { //Ctrl + F
                evt.preventDefault();
                if (this.searchRef.current) {
                    this.searchRef.current.focus();
                }
            }
        }
    }

    handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ text: evt.target.value });
        this.debouncedSearch();
    }

    handleBlur(evt: React.FocusEvent<HTMLInputElement>) {
        this.handleSearch(this.state.text)
    }

    handleKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.keyCode == 13) { //Enter
            this.handleSearch(this.state.text)
            evt.preventDefault();
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleGlobalKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleGlobalKeyDown);
    }

    render() {
        const { intl } = this.props
        return <Form inline>
            <InputGroup className={`mr-sm-2 ${SEARCH_MENU_CLASS}`}>
                <InputGroup.Prepend >
                    <InputGroup.Text id="menu-search-addon " >
                        <span className="px-1">
                            {this.props.loading ?
                                <FontAwesomeIcon icon={faSpinner} spin={true} /> :
                                <FontAwesomeIcon icon={faSearch} />}
                        </span>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    type="text"
                    placeholder={intl.formatMessage({ id: 'MENU_SEARCH_PLACEHOLDER' })}
                    value={this.state.text}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange as any}
                    ref={this.searchRef as any} />
            </InputGroup>

        </Form>
    }
}

export default injectIntl(MenuSearch)
