import { ThunkAction } from 'redux-thunk'

import { ApplicationState } from '../types';

import {
    SendLoginInfo,
    SecurityAction,
    LoginStatus,
    SendLoginError,
    SendLoginLoading,
    LoginRequestDetails,
    SendLoginCodeRequired,
    SendMessageStatUpdate,
    MessagesStatistics
} from '../types/security';
import { SEND_LOGIN, SEND_LOGIN_CODE_REQUIRED, SEND_LOGIN_ERROR, SEND_LOGIN_LOADING, SEND_MESSAGE_STAT_UPDATE } from '../constants/security';
import { FetchError } from '../types/error';

class ServerError extends Error {

    code: number;

    constructor(code: number, text: string) {
        super(text)
        this.code = code;
    }
}

export function getLoginInfoImpl(): Promise<LoginStatus> {
    const opt: RequestInit = {
        method: 'GET'
    }
    return fetch("/rest/login", opt).then((resp) => {
        if (!resp.ok) {
            throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
    }).then((json) => {
        return json;
    })
}

function loginImpl(username: string, password: string, details: LoginRequestDetails): Promise<LoginStatus> {
    const ad = details.ad;
    const generateToken = details.generateToken;
    const code = details.code;
    const opt: RequestInit = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password, ad, generateToken, code })
    }
    return fetch("/rest/login", opt).then((resp) => {
        if (!resp.ok) {
            /**
             * Try to convert to JSON
             * - May fail if server did not send response.
             * - That may be the case when database was not avalaible and 
             * Login Page Information was no available.
             */
            const contentType = resp.headers.get("content-type");
            if (resp.status == 401 && contentType && contentType.indexOf("application/json") !== -1) {
                return resp.json();
            }
            //Database failed or server not available!
            throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
    }).then((json) => {
        return json;
    })
}

function logoutImpl(): Promise<LoginStatus> {
    const opt: RequestInit = {
        method: 'DELETE'
    }
    return fetch("/rest/login", opt).then((resp) => {
        if (!resp.ok) {
            throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
    }).then((json) => {
        return json;
    })
}

export function sendLoginInfo(info: LoginStatus): SendLoginInfo {
    return {
        type: SEND_LOGIN,
        payload: {
            info
        }
    }
}
export function sendMesageStatUpdate(stats: MessagesStatistics): SendMessageStatUpdate {
    return {
        type: SEND_MESSAGE_STAT_UPDATE,
        payload: stats
    }
}

export function sendLoginError(error: FetchError): SendLoginError {
    return {
        type: SEND_LOGIN_ERROR,
        payload: {
            error
        }
    }
}

export function sendLoginLoading(): SendLoginLoading {
    return {
        type: SEND_LOGIN_LOADING
    }
}

export function sendCodeRequired(): SendLoginCodeRequired {
    return {
        type: SEND_LOGIN_CODE_REQUIRED
    }
}

export function sendLoginInfoToBroadcast(loginInfo: LoginStatus) {
    const loginInfoChannel = new BroadcastChannel("loginStatus");
    loginInfoChannel.postMessage(loginInfo);
    loginInfoChannel.close();
}

export function login(username: string, password: string, details: LoginRequestDetails): ThunkAction<void, ApplicationState, {}, SecurityAction> {
    return async (dispatch, getState) => {
        try {
            const s = getState();
            if (s && s.security.loading) {
                return;
            }
            dispatch(sendLoginLoading());
            const loginInfo = await loginImpl(username, password, details);
            sendLoginInfoToBroadcast(loginInfo);
        } catch (e: any) {
            if (e instanceof ServerError) {
                dispatch(sendLoginError({ code: e.code, message: e.message }));
            } else if (typeof e.message == 'string') {
                dispatch(sendLoginError({ code: -1, message: e.message }));
            } else {
                dispatch(sendLoginError({ code: -1, message: "Unknown error" }));
            }
        }
    };
}

export function logout(): ThunkAction<void, ApplicationState, {}, SecurityAction> {
    return async (dispatch, getState) => {
        try {
            const s = getState();
            if (s && s.security.loading) {
                return;
            }
            const loginInfo = await logoutImpl();
            sendLoginInfoToBroadcast(loginInfo);
        } catch (e: any) {
            if (e instanceof ServerError) {
                dispatch(sendLoginError({ code: e.code, message: e.message }));
            } else if (typeof e.message == 'string') {
                dispatch(sendLoginError({ code: -1, message: e.message }));
            } else {
                dispatch(sendLoginError({ code: -1, message: "Unknown error" }));
            }
        }
    };
}

export function info(): ThunkAction<void, ApplicationState, {}, SecurityAction> {
    return async (dispatch, getState) => {
        try {
            const s = getState();
            if (s && s.security.loading) {
                return;
            }
            dispatch(sendLoginLoading());
            dispatch(sendLoginInfo(await getLoginInfoImpl()));
        } catch (e: any) {
            if (e instanceof ServerError) {
                dispatch(sendLoginError({ code: e.code, message: e.message }));
            } else if (typeof e.message == 'string') {
                dispatch(sendLoginError({ code: -1, message: e.message }));
            } else {
                dispatch(sendLoginError({ code: -1, message: "Unknown error" }));
            }
        }
    };
}