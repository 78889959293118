import * as React from 'react';
import { ValueType } from 'react-select/src/types';
import { TagOptionType } from './TagSelect';

interface TagProps {
    tag: TagOptionType,
    deleteHandler: Function,
    displayField: string
}
class Tag extends React.Component<TagProps> {
    private contentText: string;
    constructor(props: TagProps) {
        super(props);

        this.contentText = this.props.tag.label;
        this.removeTag = this.removeTag.bind(this);
    }

    removeTag() {
        this.props.deleteHandler(this.props.tag);
    }

    render() {
        return (<div style={{ fontWeight: 500 }} className=" shadow p-1 my-1 mr-2 border border rounded bg-white tag-block d-inline-block">
            {this.contentText}
            <div style={{ fontSize: '1.1em', cursor: 'pointer' }} className="close pl-1" aria-label="Close" onClick={this.removeTag}>
                <span aria-hidden="false" className="text-danger">&times;</span>
            </div>
        </div>);
    }
}



export default Tag;   