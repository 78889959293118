import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_PAGE_SELECTION, DEFAULT_PAGE_SIZE } from '../../constants/table';

import styles from './Table.module.css';

/**Properties of Table pagination */
interface TablePaginationProps {
    loading: boolean
    pageable: boolean
    page?: number
    pageSize?: number
    pageSelection?: number[]
    totalRowsLength: number
    changePageSize: (pageSize: number) => void
    changePage: (page: number) => void
}

/********************************
 *  Table Pagination Component  *
 ********************************/
export default class TablePagination extends React.Component<TablePaginationProps> {

    render() {
        if (!this.props.pageable) {
            return null;
        }
        const page = this.props.page || 0;
        const pageSize = this.props.pageSize || DEFAULT_PAGE_SIZE;
        const pageSelection = this.props.pageSelection || DEFAULT_PAGE_SELECTION;
        const totalPagesLength = Math.ceil(this.props.totalRowsLength / pageSize);
        return <div className={styles.nptTablePagination + " d-flex flex-row"} >
            <PageSelection
                loading={this.props.loading}
                currentSize={pageSize}
                selection={pageSelection}
                changePageSize={this.props.changePageSize}
            />
            <PaginationPanel
                page={page}
                pageSize={pageSize}
                totalRowsLength={this.props.totalRowsLength}
                totalPagesLength={totalPagesLength}
                changePage={this.props.changePage}
            />
        </div>;
    }
}

/** Input for date fields with formatter */
interface PageSelectionProps {
    loading: boolean
    currentSize: number
    selection: number[]
    changePageSize: (pageSize: number) => void
}
const PageSelection: React.FunctionComponent<PageSelectionProps> = React.memo((props: PageSelectionProps) => {
    return <div className={`${styles.nptTablePaginationInfo} col-sm-6 px-1`}>
        <span className="form-group">
            <Dropdown>
                <Dropdown.Toggle variant="secondary" className="p-1" id="pageSizeMenu">
                    {props.currentSize}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {props.selection.map((pageSize, i) =>
                        <Dropdown.Item
                            key={i}
                            className={"dropdown-item" + (props.currentSize === pageSize ? " active" : "")}
                            disabled={props.loading}
                            onClick={props.changePageSize.bind(this, pageSize)}>
                            {pageSize}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </span>
        <span className="form-group">
            <FormattedMessage
                id="NPT_TABLE_PER_PAGE"
                defaultMessage="per page"
                description="Table pagination text" />
        </span>
    </div >;
});

/** Pages navigation panel */
interface PaginationPanelProps {
    page: number
    pageSize: number
    totalRowsLength: number
    totalPagesLength: number
    changePage: (page: number) => void
}
const PaginationPanel: React.FunctionComponent<PaginationPanelProps> = React.memo((props: PaginationPanelProps) => {
    return <div className={`${styles.nptTablePaginationPanel} col-sm-6 d-flex justify-content-end px-1`}>
        <div className="form-group">
            <PageInfo
                page={props.page}
                pageSize={props.pageSize}
                totalRowsLength={props.totalRowsLength}
            />
        </div>
        <div className="form-group">
            <PaginationItem icon="angle-double-left" disabled={props.page === 0} onClick={props.changePage.bind(this, 0)} />
            <PaginationItem icon="angle-left" disabled={props.page === 0} onClick={props.changePage.bind(this, props.page - 1)} />
        </div>
        <div className="form-group group-separate d-flex">
            <div className="input-group input-group-sm">
                <PaginationInput
                    page={props.page}
                    totalPagesLength={props.totalPagesLength}
                    changePage={props.changePage}
                />
            </div>
            <FormattedMessage
                id="NPT_TABLE_OF_PAGES"
                defaultMessage="of"
                description="Page number x OF y" />
            <span> {props.totalPagesLength}</span>
        </div>
        <div className="form-group">
            <PaginationItem icon="angle-right" disabled={props.page === props.totalPagesLength - 1} onClick={props.changePage.bind(this, props.page + 1)} />
            <PaginationItem icon="angle-double-right" disabled={props.page === props.totalPagesLength - 1} onClick={props.changePage.bind(this, props.totalPagesLength - 1)} />
        </div>
    </div>;
});

interface PageInfoProps {
    page: number
    pageSize: number
    totalRowsLength: number
}
const PageInfo: React.FunctionComponent<PageInfoProps> = React.memo((props: PageInfoProps) => {
    const total = props.totalRowsLength || 0;
    const page = props.page || 0;
    let from = total === 0 ? 0 : page * props.pageSize + 1;
    let to = Math.min(total, (page + 1) * props.pageSize);
    return (
        <FormattedMessage
            id="NPT_TABLE_PAGE_INFO"
            defaultMessage="{from}-{to} of {total}"
            description="Table pagination info"
            values={{ from, to, total }} />);
});

interface PaginationItemProps {
    icon: string
    active?: boolean
    disabled?: boolean
    onClick: () => void
}
const PaginationItem: React.FunctionComponent<PaginationItemProps> = React.memo((props: PaginationItemProps) => {
    let className = `${styles.nptTablePaginationItem} i fa fa-${props.icon}`;
    if (props.active) {
        className += ` ${styles.active}`;
    }
    if (props.disabled) {
        className += ` ${styles.disabled}`;
    }
    const onClick = () => {
        if (props.active || props.disabled) {
            return;
        }
        props.onClick();
    }
    return <span className={className} onClick={onClick}></span>;
});

interface PaginationInputProps {
    page: number
    totalPagesLength: number
    changePage: (page: number) => void
}
const PaginationInput: React.FunctionComponent<PaginationInputProps> = React.memo((props: PaginationInputProps) => {
    const [page, setPage] = React.useState(props.page);
    React.useEffect(() => {
        setPage(props.page);
    }, [props.page]);

    const inputWidth = 15 + props.totalPagesLength.toString().length * 10;

    const changeStatePage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value === "") {
            setPage(-1);
            return;
        }
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
            /* Reset input value */
            setPage(page);
            return;
        }
        /* Visible page is larger than actual value by one */
        setPage(value - 1);
    }

    const tryChangePage = () => {
        if (page === props.page) {
            return;
        }
        if (page < 0 || page >= props.totalPagesLength) {
            setPage(props.page);
            return;
        }
        props.changePage(page);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        /* Enter */
        if(event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            tryChangePage();
            return;
        }
    }

    /* User counts pages from 1 (not 0) */
    const visiblePage = page < 0 ? "" : page + 1;
    return <input
        type="text"
        placeholder=""
        className="form-control"
        style={{ width: inputWidth }}
        value={visiblePage}
        onChange={changeStatePage}
        onBlur={tryChangePage}
        onKeyDown={handleKeyDown} />;
});