import { Reducer } from 'redux';

import * as constants from '../constants/scm';
import { ApplicationAction } from '../types';
import { SCMState, SCMAction } from '../types/scm';

const reducer: Reducer<SCMState, ApplicationAction> = (state: SCMState = {}, action: ApplicationAction): SCMState => {
    switch (action.type) {
        case constants.SEND_SCM_INFO:
            return { ...state, info: action.payload.info, loading: false };
        case constants.SEND_SCM_INFO_ERROR:
            return { ...state, info: action.payload.error, loading: false };
        case constants.SEND_SCM_INFO_LOADING:
            return { ...state, loading: true };
    }
    return state;
}

export default reducer;