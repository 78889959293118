import * as React from 'react';

import MinifiedMenu from '../menu/MinifiedMenu';

export interface ViewLogoInfo {
    icon?: string
    style?: React.CSSProperties
    src?: string
}

interface ViewToolbarSubItem {
    label: string
    icon?: string
    action?: string
}

export interface ViewToolbarItem extends ViewToolbarSubItem {
    items: ViewToolbarSubItem[]
    className?: string
}

interface ViewMenuProps {
    items?: null | ViewToolbarItem[]
    logoInfo?: ViewLogoInfo | null
    onAction?: (action?: string) => void
}
const ViewMenu: React.FunctionComponent<ViewMenuProps> = React.memo((props) => {

    if (props.items) {
        return <MinifiedMenu logoInfo={props.logoInfo}>
            {props.items.map((item, i) => <button key={i} className={`btn ${item.className || "btn-info"} py-1 px-2 ${i !== 0 ? "ml-2" : ""}`} onClick={() => { props.onAction?.(item.action) }}>
                {item.icon && <i className={`fa ${item.icon} mr-2`} aria-hidden="true"></i>}
                {item.label}
            </button>)}
        </MinifiedMenu>;
    }
    if (props.children) {
        return <MinifiedMenu logoInfo={props.logoInfo}>
            {props.children}
        </MinifiedMenu>;
    }
    return null;
});

export default ViewMenu;