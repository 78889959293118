import moment from 'moment';
import { MONTHS } from "../constants/table";
import { GantDisplayScale, GantHeaderCell, GantRoundingType, GantScale } from "../types/table";


/**Replace all fields placeholders with actual values */
export function replaceFieldPlaceholders(value: any, fields: { [k: string]: string }): string | null {
    if (typeof value !== "string") {
        return null;
    }
    let openBracer: number;
    let closeBracer: number;
    do {
        openBracer = value.lastIndexOf("{");
        closeBracer = value.lastIndexOf("}");
        if (openBracer === -1 || closeBracer === -1) {
            break;
        }
        if (openBracer > closeBracer) {
            value = value.substring(0, openBracer) + value.substring(openBracer + 1);
            continue;
        }
        let placeholder = value.substring(openBracer + 1, closeBracer);
        for (let key in fields) {
            if (value.indexOf(key) === -1) {
                continue;
            }
            placeholder = placeholder.replace(key, fields[key]);
        }
        try {
            /**TODO: replace with math parser to remove eval */
            if (value.search(/[\-\+\*]/g) !== -1) {
                placeholder = eval(placeholder);
            }
        } catch (ex) {
            console.warn("Can't eval date limits function:", placeholder);
        }
        value = value.substring(0, openBracer) + placeholder + value.substring(closeBracer + 1);
    } while (openBracer !== -1 && closeBracer !== -1)
    return value;
}

/**Round Timestamp by scale and rounding values */
export function roundGantTimestamp(timestamp: number, scale: GantScale, rounding: GantRoundingType): number {
    const date = new Date(timestamp);
    /* Minimum scale value*/
    const value = getMinScaleValue(scale, date);
    /* round date to first day of week */
    if (scale === "week") {
        date.setDate(date.getDate() - getCorrectDay(date));
    }
    /* Specify stringDate until get to needed scale*/
    let stringDate: string = date.getFullYear().toString();
    if (scale === "year") {
        let month = date.getMonth();
        if (month > 5) {
            date.setDate(1);
            date.setFullYear(date.getFullYear() + 1);
        }
        stringDate = date.getFullYear() + "-01-01";
    } else if (scale === "month") {
        let day = date.getDate();
        if (day > 15) {
            date.setDate(1);
            date.setMonth(date.getMonth() + 1);
        }
        stringDate = date.getFullYear() + "-" + padDateNumber(date.getMonth() + 1) + "-01";
    } else {
        stringDate = stringDate + "-" + padDateNumber(date.getMonth() + 1);
        stringDate = stringDate + "-" + padDateNumber(date.getDate());
        /* Next scales use locale timezone*/
        if (scale === "day") {
            stringDate = stringDate + " 00:00:00";
        } else if (scale === "half-day") {
            stringDate = stringDate + " 12:00:00";
        } else if (scale === "hour") {
            stringDate = stringDate + " " + date.getHours() + ":00:00";
        }
    }
    const roundedDate = moment.utc(stringDate).toDate();
    let roundedTime = roundedDate.getTime();
    let difference = roundedTime - timestamp;
    if (difference === 0) {
        return roundedTime;
    }
    if (Math.abs(difference) > value / 2) {
        roundedTime -= value * Math.sign(difference);
    }
    difference = roundedTime - timestamp;
    if (rounding !== "auto") {
        let roundingSign = rounding === "ceil" ? 1 : -1;
        if (roundingSign !== Math.sign(difference)) {
            roundedTime += value * roundingSign;
        }
    }
    return roundedTime;
}

/**Add gant header date cell to cells array */
export function addGantDateCell(scale: GantDisplayScale, cells: GantHeaderCell[], value: number | null, cellspan: number) {
    /**Width will be added later */
    const cell: GantHeaderCell = {
        width: 0,
        value: value !== null ? value.toString() : "?",
        cellspan: cellspan
    }
    if (scale === "month") {
        cell.formattedValue = value != null ? "NPT_GANT_MONTH_" + MONTHS[value] : "?";
    }
    cells.push(cell);
}

/**
 * Try to parse input date value.
 * Returns value in ms (-1 if value is invalid)
 **/
export function parseGantDate(date: string | null): number {
    if (date === null) {
        return -1;
    }
    let parsedDate = moment.utc(date).valueOf();
    if (isNaN(parsedDate)) {
        console.warn("Invalid date limits value:", date);
        return -1;
    }
    return parsedDate;
}

/* Get minimal cell scale value */
export function getMinScaleValue(scale: GantScale, date: Date = new Date()): number {
    if (scale === "year") {
        let year = date.getFullYear();
        return moment((year + 1).toString()).valueOf() - moment(year.toString()).valueOf();
    }
    if (scale === "month") {
        let preMonth = new Date(date.getFullYear(), date.getMonth()).getTime();
        let postMonth = new Date(date.getFullYear(), date.getMonth() + 1).getTime();
        return postMonth - preMonth;
    }
    if (scale === "week") {
        return 604800000; //7 * 24 * 3600 * 1000
    }
    if (scale === "day") {
        return 86400000; //24 * 3600 * 1000
    }
    if (scale === "half-day") {
        return 43200000; //12 * 3600 * 1000
    }
    /**Hour */
    return 3600000; //3600 * 1000
}

/**getDay() returns 0 for sunday, so use +6%7 to get right correction*/
export function getCorrectDay(date: Date) {
    return (date.getDay() + 6) % 7;
}

/**Add zero to number if it is lower than 10 */
export function padDateNumber(number: any): string {
    number = parseInt(number.toString());
    if (number >= 10) {
        return number.toString();
    }
    return "0" + number;
}

/* Returns week number in month at selected date moment */
export function getWeekNumber(date: Date): number {
    /* Get first day of selected month */
    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstWeek = monthStart;
    let week = 0;
    let day = 0;
    /* Check if first week is belong to selected month */
    if ((day = getCorrectDay(monthStart)) !== 0) {
        if (day < 4) {
            ++week;
        }
        firstWeek.setDate(firstWeek.getDate() + 7 - day);
    }
    const dateTime = date.getTime();
    const firstWeekTime = firstWeek.getTime();
    if (dateTime < firstWeekTime) {
        return week;
    }
    return week + Math.floor((dateTime - firstWeekTime) / 1000 / 3600 / 24 / 7) + 1;
}

/**Returns initially defined displayed scales in gant header */
export function getStandartDisplayedScales(scale: GantScale): GantDisplayScale[] {
    if (scale === "year") {
        return ["year"];
    }
    if (scale === "month") {
        return ["year", "month"];
    }
    if (scale === "week") {
        return ["year", "month", "week"];
    }
    if (scale === "day") {
        return ["year", "month", "day"];
    }
    if (scale === "hour") {
        return ["month", "day", "hour"];
    }
    return ["year", "month", "day"];
}

/**Returns value of date for specified scale */
export function getValueByScale(date: Date, scale: GantScale): number | null {
    if (scale === "year") {
        return date.getFullYear();
    }
    if (scale === "month") {
        return date.getMonth();
    }
    if (scale === "week") {
        return getWeekNumber(date);
    }
    if (scale === "day") {
        return date.getDate();
    }
    if (scale === "hour") {
        return date.getHours();
    }
    return null;
}

/**Check if new gant date value is correct and non-equal to previous value */
export function checkGantDateValue(scale: GantScale, date: Date, prevValue: number | null): number | null {
    let newValue = getValueByScale(date, scale);

    if (scale === "week") {
        if (newValue === 0) {
            return null;
        }
        if (newValue === 5) {
            let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
            if (getCorrectDay(nextMonth) < 4) {
                newValue = 1;
            }
        }
    }

    if (newValue === prevValue) {
        return null;
    }
    return newValue;
}

/**Add milliseconds to date value */
export function addTime(date: Date, time: number) {
    date.setTime(date.getTime() + time);
}