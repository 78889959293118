import React from "react";
import { connect } from "react-redux";
import {
  UI_BUTTON,
  UI_CARD,
  UI_CHECKBOX,
  UI_COMBOBOX,
  UI_DATE,
  UI_DATE_TIME,
  UI_FILE,
  UI_FLOAT,
  UI_FRAGMENT,
  UI_GRID,
  UI_GRID_CELL,
  UI_IMAGE,
  UI_INT,
  UI_OBJECT_TABLE,
  UI_PANEL,
  UI_PLUGIN,
  UI_REF_TABLE,
  UI_RPA_SETPOINTS,
  UI_SPLITTER_CONTAINER,
  UI_TAB_NAV,
  UI_TEXT,
  UI_VIEW,
} from "../../constants/subject";
import { isCardEdit, isEditable } from "../../services/layout";
import { ApplicationState } from "../../types";
import { isSubject, Layout, Subject, SubjectData } from "../../types/subject";
import Plugin from "../plugin/Plugin";
import { ConnectedView } from "../view/View";
import Grid from "./boxes/Grid";
import GridCell from "./boxes/GridCell";
import Panel from "./boxes/Panel";
import SplitterContainer from "./boxes/SplitterContainer";
import TabNav from "./boxes/TabNav";
import { CardAsValueWrapper } from "./CardValueWrapper";
import CardButton from "./controls/CardButton";
import CheckboxInput from "./inputs/CheckboxInput";
import ComboBoxInput from "./inputs/ComboBoxInput";
import DateInput from "./inputs/DateInput";
import DateTimeInput from "./inputs/DateTimeInput";
import File from "./inputs/File";
import FragmentBox from "./inputs/FragmetBox";
import ObjectTable from "./inputs/ObjectTable";
import ReferenceTable from "./inputs/RefTable";
import TextInput from "./inputs/TextInput";
import RPASetpoints from "./rpasetpoints/RPASetpoints";
import Image from "./views/Image";

export interface CardAreaProps {
  subject?: Subject;
  subjectKey: string;
  nodeId?: string;
  childrenIds?: string[];
  values?: { [K: string]: any };
  layout?: Layout;
  data?: SubjectData;
  editable?: boolean;
}

/**
 * Component used to render area
 * which contains layout nodes passed by their ids
 *
 * Main task of this class is to match layout node to specific component
 * So this component may be reused (as child) for all boxes (containers)
 */
class CardArea extends React.Component<CardAreaProps> {
  render() {
    const { layout, childrenIds, subjectKey, editable, data, values, subject } =
      this.props;
    if (!layout || !childrenIds) {
      return null;
    }
    const result = [];
    for (let nodeId of childrenIds) {
      const node = layout.nodeById[nodeId];
      //console.log(node);
      if (!node) {
        continue;
      }

      switch (node.ui) {
        case UI_TAB_NAV:
          result.push(
            <TabNav key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_BUTTON:
          result.push(
            <CardButton key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_PANEL:
          result.push(
            <Panel key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_GRID:
          result.push(
            <Grid key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_PLUGIN: {
          const nodeOptions = node.options;
          const plugin = nodeOptions?.plugin;
          const view = nodeOptions?.view;
          const visible = subject?.visibility[nodeId] ? true : false;
          if (!plugin || !view || !visible) {
            break;
          }
          const options = {
            subjectData: data,
            editable,
            nodeOptions: nodeOptions,
          };

          result.push(
            <Plugin
              key={nodeId}
              plugin={`${plugin}/${view}`}
              options={options}
            />
          );

          break;
        }
        case UI_CARD:
          result.push(
            <CardAsValueWrapper
              key={nodeId}
              subjectKey={subjectKey}
              nodeId={nodeId}
            />
          );
          break;
        case UI_GRID_CELL:
          result.push(
            <GridCell key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_TEXT:
        case UI_INT:
        case UI_FLOAT:
          result.push(
            <TextInput key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_DATE:
          result.push(
            <DateInput key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_DATE_TIME:
          result.push(
            <DateTimeInput
              key={nodeId}
              subjectKey={subjectKey}
              nodeId={nodeId}
            />
          );
          break;
        case UI_CHECKBOX:
          result.push(
            <CheckboxInput
              key={nodeId}
              subjectKey={subjectKey}
              nodeId={nodeId}
            />
          );
          break;
        case UI_COMBOBOX:
          result.push(
            <ComboBoxInput
              key={nodeId}
              subjectKey={subjectKey}
              nodeId={nodeId}
              menuShouldBlockScroll={true}
            />
          );
          break;
        case UI_REF_TABLE:
          result.push(
            <ReferenceTable
              key={nodeId}
              subjectKey={subjectKey}
              nodeId={nodeId}
            />
          );
          break;
        case UI_IMAGE:
          result.push(
            <Image key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_RPA_SETPOINTS:
          result.push(
            <RPASetpoints
              key={nodeId}
              subjectKey={subjectKey}
              nodeId={nodeId}
            />
          );
          break;
        case UI_FILE:
          result.push(
            <File key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_FRAGMENT:
          result.push(
            <FragmentBox key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_OBJECT_TABLE:
          result.push(
            <ObjectTable key={nodeId} subjectKey={subjectKey} nodeId={nodeId} />
          );
          break;
        case UI_SPLITTER_CONTAINER:
          result.push(
            <SplitterContainer
              key={nodeId}
              subjectKey={subjectKey}
              nodeId={nodeId}
            />
          );
          break;
        case UI_VIEW: {
          let src = node.options?.src;
          const viewData = node.options?.viewData || undefined;
          const visible = subject?.visibility[nodeId] ? true : false;
          if (!src || !visible) {
            break;
          }
          if (src[0] != "/") {
            src = "/" + src;
          }

          result.push(
            <ConnectedView
              key={nodeId}
              viewPath={`/view${src}`}
              subjectData={data}
              editable={editable}
              viewData={viewData}
            />
          );
          break;
        }
      }
    }
    return result;
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: { subjectKey: string; nodeId?: string }
  ) => {
    const { subjectKey, nodeId } = ownProps;
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];

    if (!isSubject(subject)) {
      return {};
    }

    const childrenIds = ownProps.nodeId
      ? subject.childrenNodesById[ownProps.nodeId]
      : subject.rootNodesIds;
    const editCard = isCardEdit(subject);
    const editable = isEditable(subject, nodeId, editCard);
    return {
      subject,
      layout: subject,
      childrenIds,
      values: subject?.values,
      data: subject.subjectData,
      editable,
    };
  }
)(CardArea);
