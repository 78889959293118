import * as React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import ModalView from '../ModalView';

interface AddScriptModalProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void
}

interface AddScriptModalState {
    name: string|null 
}

export default class AddScriptModal extends React.Component<AddScriptModalProps, AddScriptModalState> {

    title = { id: "CLASSCARD_ADD_SCRIPT" };
    state = {
        name: null 
    };

    constructor(props: AddScriptModalProps) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }


    changeInputValue(key: string, event: any) {
        const newValue = event.target.value;
        this.setState({ ...this.state, [key]: newValue.trim() });
    }

    renderTemplate(): React.ReactElement {
        const {name} =this.state
        return (
            <Form>
                <Form.Group controlId="name">
                    <Form.Label>
                        <FormattedMessage id={"CLASSCARD_ADD_SCRIPT_ENTER_NAME"} />
                    </Form.Label>
                    <Form.Control type="input" value={this.state.name||''} onChange={this.changeInputValue.bind(this, "name")} />
                    {name==="" && <Form.Text ><FormattedMessage id="CLASSCARD_ADD_SCRIPT_ENTER_NAME_INVALID"/> </Form.Text>}
                </Form.Group>                
            </Form>
        );
    } 
    closeModal(status: ModalStatus, result: any) {
        if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
            this.props.closeModal(status, result);
        } 
        const {name} = this.state;
        if (status == MODAL_STATUS_OK && name) {
            this.props.closeModal(status, name);
            return;
        }

    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}