import React from "react";

function isReactComponent(obj: any): obj is React.Component<any> {
    return typeof obj.props != "undefined";
}

/**Get list of inner links by parsing children */
export function getChildrenLinks(component: React.Component<any>): string[] {
    const children = component.props.children;
    let links:string[] = [];
    if(component.props.href){
        links.push(component.props.href);
    }
    if(children != null) {
        React.Children.map(
            children,
            child => {
                if(!isReactComponent(child)){
                    return;
                }
                links = links.concat(getChildrenLinks(child));
            }
        )
    }
    links = Array.from(new Set(links));
    return links;
}