import * as React from 'react';
import { AutoSizer, Grid } from 'react-virtualized';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { GantDateLimits, GantGroup, GantHeaderCell, GantPlannedGroups, GantScale, GantViewType, RowData } from '../../types/table';
import { FetchError } from '../../types/error';
import { OnScrollParams } from './TableContainer';
import { GANT_CHOSEN_DATE, GANT_PLANNED_DATE } from '../../constants/table';
import { getGradientColor } from '../../services/table';
import { bindEvent, unbindEvent } from '../../services/event';
import { roundGantTimestamp } from '../../services/gant';

import { LoadingAlert, LoadingError } from './Table';

import styles from './Table.module.css';

/**Properties of Table gant fiagram */
interface TableGantBodyProps {
    /**Width of table gant*/
    width: number
    /**Calculated height of table body*/
    bodyHeight?: number,
    /**Display indicator that table data is loading*/
    loadingData: boolean,
    /**Info about error that occured during data loading*/
    errorData: null | FetchError,
    /**Actual width of diagram (can be larger than visible part) */
    gantWidth: number
    /**Current horizontal scroll position*/
    scrollLeft: number
    /**Current vertical scroll position*/
    scrollTop: number
    /**Array of table rows ids*/
    pageRows: number[]
    /**Map of row data by it idx */
    rowByIdx: { [rowIdx: number]: RowData }
    /**Map of row selected flag by key */
    selectedRows: { [k: string]: boolean }
    /**Selected view type of gant diagram*/
    viewType: GantViewType
    /**Date limits of gant data*/
    dateLimits: GantDateLimits
    /**Map of gant diagram chosen groups */
    gantChosenGroups: { [k: string]: GantGroup }
    /**Map of gant diagram chosen groups */
    gantPlannedGroups: GantPlannedGroups
    /**Gant view scale */
    scale: GantScale
    /**List of scale cells to display background grid */
    scaleCells?: GantHeaderCell[]
    /**Synchronize scrolling function */
    onScroll: (params: OnScrollParams) => void
    /**Handler of gant row element changings */
    onElementChange: (rowIndex: number, itemIndex: number, newDate: GantDateLimits, isPlanned: boolean) => void
}

interface TableGantBodyState {
    altPressed: boolean
}

/*********************************
 *   Table Gant Body Component   *
 *********************************/
export default class TableGantBody extends React.PureComponent<TableGantBodyProps, TableGantBodyState> {

    scrollbarRef: any | null = null;

    constructor(props: TableGantBodyProps) {
        super(props);

        this.state = {
            altPressed: false
        }

        this.cellRenderer = this.cellRenderer.bind(this);
        this.noContentRenderer = this.noContentRenderer.bind(this);
        this.onScrollX = this.onScrollX.bind(this);
        this.onScrollY = this.onScrollY.bind(this);
    }

    cellRenderer(data: {
        columnIndex: number, // Horizontal (column) index of cell
        isScrolling: boolean, // The Grid is currently being scrolled
        isVisible: boolean,   // This cell is visible within the grid (eg it is not an overscanned cell)
        key: string,         // Unique key within array of cells
        parent: any,      // Reference to the parent Grid (instance)
        rowIndex: number,    // Vertical (row) index of cell
        style: any        // Style object to be applied to cell (to position it);
        // This must be passed through to the rendered cell element.
    }) {

        // Style is required since it specifies how the cell is to be sized and positioned.
        // React Virtualized depends on this sizing/positioning for proper scrolling behavior.
        // By default, the grid component provides the following style properties:
        //    position
        //    left
        //    top
        //    height
        //    width
        // You can add additional class names or style properties as you would like.
        // Key is also required by React to more efficiently manage the array of cells.
        const row: RowData = this.props.rowByIdx[this.props.pageRows[data.rowIndex]] || { changedData: {}, bindedData: {}, data: { __unavailable: true }, classes: {}, changed: null };
        return <GantRow
            key={data.key}
            width={this.props.gantWidth}
            dateLimits={this.props.dateLimits}
            viewType={this.props.viewType}
            gantChosenGroups={this.props.gantChosenGroups}
            gantPlannedGroups={this.props.gantPlannedGroups}
            scale={this.props.scale}
            row={row}
            style={data.style}
            onElementChange={(itemIndex: number, newDate: GantDateLimits, isPlanned: boolean) => this.props.onElementChange(this.props.pageRows[data.rowIndex], itemIndex, newDate, isPlanned)}
        />;
    }

    noContentRenderer() {
        if (this.props.loadingData) {
            return <div className="text-center" style={{ width: this.props.gantWidth }}>
                <div className="position-relative" style={{ width: this.props.width, left: this.props.scrollLeft }}>
                    <LoadingAlert />
                </div>
            </div>;
        }
        if (this.props.errorData) {
            return <div className="text-center" style={{ width: this.props.gantWidth }}>
                <div className="position-relative" style={{ width: this.props.width, left: this.props.scrollLeft }}>
                    <LoadingError />
                </div>
            </div>;
        }
        return <div className={`${styles.nptTableRow} text-center`} style={{ width: this.props.gantWidth }}>
            <div className="position-relative" style={{ width: this.props.width, left: this.props.scrollLeft }}>
                <FormattedMessage
                    id="NPT_TABLE_NO_DATA"
                    defaultMessage="No data"
                    description="Table have no data" />
            </div>
        </div>;
    }

    onScrollX(container: HTMLElement) {
        if (this.props.loadingData) {
            return;
        }
        this.props.onScroll({ scrollLeft: container.scrollLeft });
    }

    onScrollY(container: HTMLElement) {
        this.props.onScroll({ scrollTop: container.scrollTop });
    }

    componentDidMount() {
        bindEvent({
            event: "keydown.gant",
            handler: (e: KeyboardEvent) => {
                if (e.key === "Alt") {
                    e.preventDefault();
                    this.setState({ altPressed: true });
                }
            }
        });
        bindEvent({
            event: "keyup.gant",
            handler: (e: KeyboardEvent) => {
                if (e.key === "Alt") {
                    this.setState({ altPressed: false });
                }
            }
        });
    }

    componentWillUnmount() {
        unbindEvent({ event: "keydown.gant" });
        unbindEvent({ event: "keyup.gant" });
    }

    render() {
        /**TODO: FIXME? */
        if (this.scrollbarRef && this.scrollbarRef.scrollTop !== this.props.scrollTop) {
            this.scrollbarRef.scrollTop = this.props.scrollTop;
        }
        /**TODO: get from reducer */
        const rowHeight = 80;

        let grid = null;
        if (typeof this.props.bodyHeight !== "undefined") {
            grid = <Grid
                className={styles.nptGantBody}
                height={this.props.bodyHeight}
                width={this.props.width}
                scrollLeft={this.props.scrollLeft}
                scrollTop={this.props.scrollTop}
                rowCount={this.props.loadingData ? 0 : this.props.pageRows.length}
                rowHeight={rowHeight}
                cellRenderer={this.cellRenderer}
                noContentRenderer={this.noContentRenderer}
                columnCount={1}
                columnWidth={this.props.gantWidth}
                /* Update triggers */
                loadingData={this.props.loadingData}
                errorData={this.props.errorData}
                selectedRows={this.props.selectedRows}
                pageRows={this.props.pageRows}
                rowByIdx={this.props.rowByIdx}
                viewType={this.props.viewType}
                dateLimits={this.props.dateLimits}
                gantChosenGroups={this.props.gantChosenGroups}
                gantPlannedGroups={this.props.gantPlannedGroups}
                scaleCells={this.props.scaleCells}
            />
        } else {
            grid = <AutoSizer disableWidth style={{ height: "100%" }}>
                {({ height }) => (<Grid
                    className={styles.nptGantBody}
                    height={height}
                    width={this.props.width}
                    scrollLeft={this.props.scrollLeft}
                    scrollTop={this.props.scrollTop}
                    rowCount={this.props.loadingData ? 0 : this.props.pageRows.length}
                    rowHeight={rowHeight}
                    cellRenderer={this.cellRenderer}
                    noContentRenderer={this.noContentRenderer}
                    columnCount={1}
                    columnWidth={this.props.gantWidth}
                    /* Update triggers */
                    loadingData={this.props.loadingData}
                    errorData={this.props.errorData}
                    selectedRows={this.props.selectedRows}
                    pageRows={this.props.pageRows}
                    rowByIdx={this.props.rowByIdx}
                    viewType={this.props.viewType}
                    dateLimits={this.props.dateLimits}
                    gantChosenGroups={this.props.gantChosenGroups}
                    gantPlannedGroups={this.props.gantPlannedGroups}
                    scaleCells={this.props.scaleCells}
                />
                )}
            </AutoSizer>
        }
        return <div className={`d-flex flex-row flex-grow-1 overflow-hidden position-relative ${styles.nptGant} ${this.state.altPressed ? styles.hideScrollbars : ""}`}>
            <ScaleCells
                width={this.props.gantWidth}
                height={this.props.pageRows.length * rowHeight}
                scrollLeft={this.props.scrollLeft}
                loadingData={this.props.loadingData}
                cells={this.props.scaleCells}
            />
            <PerfectScrollbar
                containerRef={ref => this.scrollbarRef = ref}
                options={{ scrollXMarginOffset: 2 }}
                className={styles.nptGantScrollArea}
                onScrollX={this.onScrollX}
                onScrollY={this.onScrollY}
            >
                {grid}
            </PerfectScrollbar>
        </div>
    }
}

interface ScaleCellsProps {
    width: number
    height: number
    scrollLeft: number
    loadingData: boolean
    cells?: GantHeaderCell[]
}
const ScaleCells: React.FunctionComponent<ScaleCellsProps> = React.memo((props: ScaleCellsProps) => {
    if (props.loadingData || !props.cells) {
        return null;
    }
    return <div className={styles.nptGantBodyScale} style={{ width: props.width, height: props.height, left: -props.scrollLeft }}>
        {props.cells.map((cell, index) => <ScaleCell key={index} gantWidth={props.width} cell={cell} />)}
    </div>
});

interface ScaleCellProps {
    gantWidth: number
    cell: GantHeaderCell
}
const ScaleCell: React.FunctionComponent<ScaleCellProps> = React.memo((props: ScaleCellProps) => {
    return <div className={styles.nptGantScaleCell} style={{ width: props.cell.width * props.gantWidth }}></div>
});

interface GantRowProps {
    width: number
    viewType: GantViewType
    dateLimits: GantDateLimits
    gantChosenGroups: { [k: string]: GantGroup }
    gantPlannedGroups: GantPlannedGroups
    scale: GantScale
    row: RowData
    style?: React.CSSProperties
    onElementChange: (itemIndex: number, newDate: GantDateLimits, isPlanned: boolean) => void
}
const GantRow: React.FunctionComponent<GantRowProps> = React.memo((props: GantRowProps) => {
    const gantData = props.row.changedData.gantData || props.row.bindedData.gantData || props.row.data.gantData;
    const chosenDate = gantData?.chosenDate || [];
    const plannedDate = gantData?.plannedDate || [];
    const fillDate: any[] = [];
    if (props.viewType === "read" && gantData.fillGroup) {
        const addFillerItem = (from: number | string, to: number | string) => {
            let fromNum = moment(from).valueOf();
            let toNum = moment(to).valueOf();
            if (fromNum > props.dateLimits.to || toNum < props.dateLimits.from) {
                return;
            }
            if (fromNum < props.dateLimits.from) {
                from = props.dateLimits.from;
                fromNum = props.dateLimits.from;
            }
            if (toNum > props.dateLimits.to) {
                to = props.dateLimits.to;
                toNum = props.dateLimits.to;
            }
            if (fromNum >= toNum) {
                return;
            }
            fillDate.push({
                group: gantData.fillGroup,
                from,
                to
            });
        }
        for (let i = 0; i < chosenDate.length; ++i) {
            const fillerStart = i === 0 ? props.dateLimits.from : chosenDate[i - 1].to;
            const fillerEnd = chosenDate[i].from;
            addFillerItem(fillerStart, fillerEnd);
        }
        const fillerStart = chosenDate.length === 0 ? props.dateLimits.from : chosenDate[chosenDate.length - 1].to;
        const fillerEnd = props.dateLimits.to;
        addFillerItem(fillerStart, fillerEnd);
    }
    let className = styles.nptGantRow;
    if (props.row.changed !== null) {
        className += ` ${styles.changed}`;
    }
    return <div className={className} style={{ ...props.style, width: props.width }}>
        {fillDate.map((item: any, i: number) => <ChosenGantItem
            key={i}
            rowWidth={props.width}
            viewType={props.viewType}
            scale={props.scale}
            dateLimits={props.dateLimits}
            groups={props.gantChosenGroups}
            item={item}
            prevItem={fillDate[i - 1]}
            nextItem={fillDate[i + 1]}
            onChange={(newDate: GantDateLimits) => { }}
        />)}
        {chosenDate.map((item: any, i: number) => <ChosenGantItem
            key={i}
            rowWidth={props.width}
            viewType={props.viewType}
            scale={props.scale}
            dateLimits={props.dateLimits}
            groups={props.gantChosenGroups}
            item={item}
            prevItem={chosenDate[i - 1]}
            nextItem={chosenDate[i + 1]}
            onChange={(newDate: GantDateLimits) => props.onElementChange(i, newDate, false)}
        />)}
        {plannedDate.map((item: any, i: number) => <PlannedGantItem
            key={i}
            rowWidth={props.width}
            viewType={props.viewType}
            scale={props.scale}
            dateLimits={props.dateLimits}
            groups={props.gantPlannedGroups}
            item={item}
            prevItem={plannedDate[i - 1]}
            nextItem={plannedDate[i + 1]}
            onChange={(newDate: GantDateLimits) => props.onElementChange(i, newDate, true)}
        />)}
    </div>
});

interface ChosenGantItemProps {
    rowWidth: number
    viewType: GantViewType
    scale: GantScale
    dateLimits: GantDateLimits
    groups: { [k: string]: GantGroup }
    item: any
    prevItem?: any
    nextItem?: any
    onChange: (newDate: GantDateLimits) => void
}
const ChosenGantItem: React.FunctionComponent<ChosenGantItemProps> = React.memo((props: ChosenGantItemProps) => {
    const [itemGroup, setItemGroup] = React.useState<GantItemGroup>();
    React.useEffect(() => {
        if (!props.item?.group) {
            return;
        }
        if (!Array.isArray(props.item.group)) {
            const group = props.item.group;
            setItemGroup(props.groups[group]);
            return;
        }
        let borderColor: string | null = null;
        const colors: string[] = [];
        for (let group of props.item.group) {
            const propsGroup = props.groups[group];
            if (!propsGroup) {
                continue;
            }
            colors.push(propsGroup.color);
            if (!borderColor) {
                borderColor = propsGroup.borderColor;
            }
        }
        if (!borderColor) {
            borderColor = "#000";
        }
        const itemGroup: GantItemGroup = {
            color: colors[0] || "#fff",
            borderColor: borderColor
        }
        if (colors.length > 1) {
            const gradient = getGradientColor(colors);
            if (gradient) {
                itemGroup.gradient = gradient;
            }
        }
        setItemGroup(itemGroup);
    }, [props.groups, props.item?.group]);

    if (!props.item || !props.item.from || !props.item.to) {
        return null;
    }
    return <GantItemComponent
        rowWidth={props.rowWidth}
        viewType={props.viewType}
        scale={props.scale}
        dateLimits={props.dateLimits}
        group={itemGroup}
        from={props.item.from}
        to={props.item.to}
        minFrom={props.prevItem?.to}
        maxTo={props.nextItem?.from}
        dashed={Boolean(props.item.dashed)}
        planned={false}
        onChange={props.onChange}
    />
});

interface PlannedGantItemProps {
    rowWidth: number
    viewType: GantViewType
    scale: GantScale
    dateLimits: GantDateLimits
    groups: GantPlannedGroups
    item: any
    prevItem?: any
    nextItem?: any
    onChange: (newDate: GantDateLimits) => void
}
const PlannedGantItem: React.FunctionComponent<PlannedGantItemProps> = React.memo((props: PlannedGantItemProps) => {
    const [itemGroup, setItemGroup] = React.useState<GantItemGroup>();
    React.useEffect(() => {
        if (!props.item?.group) {
            return;
        }
        const group = props.item.group;
        const groupId = typeof group === "string" ? group : group.id;
        const propsGroup = groupId === "plannedWork" ? props.groups.plannedWork : props.groups.plannedDocs;
        setItemGroup(propsGroup);
    }, [props.groups, props.item?.group]);

    if (!props.item || !props.item.from || !props.item.to) {
        return null;
    }
    return <GantItemComponent
        rowWidth={props.rowWidth}
        viewType={props.viewType}
        scale={props.scale}
        dateLimits={props.dateLimits}
        group={itemGroup}
        from={props.item.from}
        to={props.item.to}
        minFrom={props.prevItem?.to}
        maxTo={props.nextItem?.from}
        dashed={Boolean(props.item.dashed)}
        planned={true}
        onChange={props.onChange}
    />
});

interface GantItemGroup {
    color: string
    gradient?: string
    borderColor: string
}
interface GantItemProps {
    rowWidth: number
    viewType: GantViewType
    scale: GantScale
    dateLimits: GantDateLimits
    group?: GantItemGroup
    from: number | string
    to: number | string
    minFrom?: number | string
    maxTo?: number | string
    dashed: boolean
    planned: boolean
    onChange: (newDate: GantDateLimits) => void
}
const GantItemComponent: React.FunctionComponent<GantItemProps> = (props: GantItemProps) => {
    const getTimestampValue = (timestamp: number | string) => {
        if (typeof timestamp === "string") {
            return moment.utc(timestamp).valueOf();
        }
        return timestamp;
    }
    const getTimestampRange = () => {
        return {
            from: roundGantTimestamp(getTimestampValue(props.from), props.scale, "floor"),
            to: roundGantTimestamp(getTimestampValue(props.to), props.scale, "ceil")
        }
    }
    const [timestampRange, setTimestampRange] = React.useState<{ from: number, to: number }>(getTimestampRange());
    const [widthCoefficient, setWidthCoefficient] = React.useState<number>(props.rowWidth / (props.dateLimits.to - props.dateLimits.from));
    React.useEffect(() => {
        setTimestampRange(getTimestampRange());
    }, [props.from, props.to]);
    React.useEffect(() => {
        setWidthCoefficient(props.rowWidth / (props.dateLimits.to - props.dateLimits.from));
    }, [props.rowWidth, props.dateLimits]);

    const getTimestampPosition = (timestamp: string | number) => {
        if (typeof timestamp === "string") {
            timestamp = moment(timestamp).valueOf();
        }
        return (timestamp - props.dateLimits.from) * widthCoefficient;
    }

    const getPositionByData = () => {
        return {
            left: getTimestampPosition(timestampRange.from),
            right: getTimestampPosition(timestampRange.to)
        }
    };

    const [itemPosition, setItemPosition] = React.useState(getPositionByData());
    React.useEffect(() => {
        setItemPosition(getPositionByData());
    }, [props.dateLimits, timestampRange, widthCoefficient]);

    if (!props.group || timestampRange.to <= props.dateLimits.from || timestampRange.from >= props.dateLimits.to) {
        return null;
    }

    const itemWidth = itemPosition.right - itemPosition.left;
    /**TODO: get from options */
    const rowHeight = 80;
    /**TODO: get from options */
    const itemHeight = 20;
    /**TODO: get from options */
    const rowPadding = ((rowHeight / 2) - itemHeight) / 2;

    const editable = props.planned && props.viewType === GANT_PLANNED_DATE || !props.planned && props.viewType === GANT_CHOSEN_DATE;

    const releaseMouse = (itemPosition: { left: number, right: number }) => {
        unbindEvent({ event: "mousemove.npt.gant" });
        unbindEvent({ event: "mouseup.npt.gant" });
        const newData = {
            from: props.dateLimits.from + itemPosition.left / widthCoefficient,
            to: props.dateLimits.from + itemPosition.right / widthCoefficient
        }
        props.onChange(newData);
        setItemPosition(getPositionByData());
    }

    const grabResizer = (type: GantItemResizerType, position: number) => {
        const startingLeft = itemPosition.left;
        const startingRight = itemPosition.right;
        const calculatePosition = (reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const currentPosition = reactEvent.pageX;
            let difference = currentPosition - position;
            if (type === "left") {
                let min = 0;
                const max = startingRight - 3;
                if (props.minFrom) {
                    min = Math.max(min, getTimestampPosition(props.minFrom));
                }
                if (startingLeft + difference < min) {
                    difference = min - startingLeft;
                }
                let nextLeft = startingLeft + difference;
                if (nextLeft > max) {
                    nextLeft = max;
                }
                return { left: nextLeft, right: startingRight };
            }
            const min = startingLeft + 3;
            let max = props.rowWidth;
            if (props.maxTo) {
                max = Math.min(max, getTimestampPosition(props.maxTo));
            }
            if (startingRight + difference > max) {
                difference = max - startingRight
            }
            let nextRight = startingRight + difference;
            if (nextRight < min) {
                nextRight = min;
            }
            return { left: startingLeft, right: nextRight };
        }
        bindEvent({
            event: "mousemove.npt.gant",
            handler: (reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                setItemPosition(calculatePosition(reactEvent));

            }
        });
        bindEvent({
            event: "mouseup.npt.gant",
            handler: (reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                releaseMouse(calculatePosition(reactEvent));
            }
        });
    }

    const grabItem = (reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!editable) {
            return;
        }
        reactEvent.preventDefault();
        reactEvent.stopPropagation();
        let min = 0;
        if (props.minFrom) {
            min = Math.max(min, getTimestampPosition(props.minFrom));
        }
        let max = props.rowWidth;
        if (props.maxTo) {
            max = Math.min(max, getTimestampPosition(props.maxTo));
        }
        const position = reactEvent.pageX;
        const startingLeft = itemPosition.left;
        const startingRight = itemPosition.right;
        const calculatePosition = (reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const currentPosition = reactEvent.pageX;
            let difference = currentPosition - position;
            if (startingLeft + difference < min) {
                difference = min - startingLeft;
            } else if (startingRight + difference > max) {
                difference = max - startingRight
            }
            const nextLeft = startingLeft + difference;
            const nextRight = startingRight + difference;
            return { left: nextLeft, right: nextRight };
        }
        bindEvent({
            event: "mousemove.npt.gant",
            handler: (reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                setItemPosition(calculatePosition(reactEvent));
            }
        })
        bindEvent({
            event: "mouseup.npt.gant",
            handler: (reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                releaseMouse(calculatePosition(reactEvent));
            }
        })
    }

    return <div
        className={`${styles.nptGantItem} ${editable ? styles.editable : ""}`}
        style={{
            width: itemWidth,
            height: itemHeight,
            top: props.planned ? undefined : rowPadding,
            bottom: props.planned ? rowPadding : undefined,
            left: itemPosition.left,
            backgroundColor: props.group.color,
            background: props.group.gradient || props.group.color,
            borderColor: props.group.borderColor
        }}
        onMouseDown={grabItem}
    >
        <GantItemDashOverflow visible={props.dashed} color={props.group.borderColor} />
        <GantItemResizer
            editable={editable}
            type="left"
            grabResizer={grabResizer}
        />
        <GantItemResizer
            editable={editable}
            type="right"
            grabResizer={grabResizer}
        />
    </div>
};

interface GantItemDashOverflowProps {
    visible: boolean
    color: string
}
const GantItemDashOverflow: React.FunctionComponent<GantItemDashOverflowProps> = React.memo((props: GantItemDashOverflowProps) => {
    if (!props.visible) {
        return null;
    }

    const background = `repeating-linear-gradient(-60deg, ${props.color} 0, ${props.color} 1px, transparent 2px, transparent 5px)`;
    return <div className={styles.nptGantItemDash} style={{ background: background }}></div>;
});

type GantItemResizerType = "left" | "right";
interface GantItemResizerProps {
    editable: boolean
    type: GantItemResizerType
    grabResizer: (type: GantItemResizerType, position: number) => void
}
const GantItemResizer: React.FunctionComponent<GantItemResizerProps> = React.memo((props: GantItemResizerProps) => {
    if (!props.editable) {
        return null;
    }

    const grabResizer = (reactEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        reactEvent.preventDefault();
        reactEvent.stopPropagation();
        const position = reactEvent.pageX;
        props.grabResizer(props.type, position);
    }

    const sideClass = props.type == "right" ? styles.right : styles.left;
    return <div className={`${styles.nptGantItemResizer} ${sideClass}`} onMouseDown={grabResizer}></div>;
});