import { ThunkAction } from "redux-thunk";
import UploadedFileInfoModal from "../components/admin/filearchive/modals/UploadedFileInfo";
import { openNamedModal } from "../components/helpers/NamedModal";
import { ALERT_LEVEL_DANGER, ALERT_LEVEL_SUCCESS } from "../constants/alert";
import * as constants from "../constants/filearchive";
import { ApplicationAction, ApplicationState } from "../types";
import { FetchError } from "../types/error";
import {
  EditFilearchiveInfo,
  FilearchiveAction,
  FilearchiveTableResult,
  FileInfo,
  SendAllItemChecked,
  SendFilearchiveError,
  SendFilearchiveLoading,
  SendFiles,
  SendItemChecked,
  SendLimit,
  SendOffset,
  SendSearchInput,
  SendStartEdit,
  SendStopEdit,
} from "../types/filearchive";
import { I18NString } from "../types/modal";
import { addAlert, dispatchError } from "./alert";
import { openModal } from "./modal";
import { ServerError } from "./utils";

/*********************
 * Utility functions *
 *********************/

const request = (row: any) => {
  return {
    method: "POST",
    body: JSON.stringify(row),
  };
};

async function saveFileImpl(file: FormData): Promise<any> {
  const response = await fetch("/rest/file/upload", {
    method: "POST",
    body: file,
  });

  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }

  return response.json();
}

async function fetchFilesImpl(tableInfo: any): Promise<FilearchiveTableResult> {
  let url = "/rest/file/list";
  let query: string[] = [];
  if (tableInfo) {
    for (const k of Object.keys(tableInfo)) {
      const v = tableInfo[k];
      if (v === undefined || String(v).trim().length === 0) {
        continue;
      }
      query.push(`${k}=${v}`);
    }
  }
  if (query.length > 0) {
    url += `?${query.join("&")}`;
  }

  const response = await fetch(url, {
    method: "GET",
    // headers: {
    //     'Accept': 'application/json'
    // }
  });
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}

export function downloadFileImpl(sha1: string) {
  return fetch("/rest/file/download/" + sha1).then((resp) => {
    if (!resp.ok) {
      throw new ServerError(resp.status, resp.statusText);
    }
    const contentDesp = resp.headers.get("Content-Disposition");
    let filename = "default.txt";
    if (contentDesp) {
      filename = decodeURI(contentDesp.split("filename=")[1]);
      filename = filename.slice(1, filename.length - 1);
    }

    resp.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  });
}
export async function fetchFileBlobImpl(sha1: string): Promise<Blob> {
  const resp = await fetch("/rest/file/download/" + sha1);
  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return await resp.blob();
}

function editFileImpl(editedObjectInfo: any): Promise<void> {
  return fetch("/rest/file/edit", request(editedObjectInfo))
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return;
    });
}
/*****************
 * Plain actions *
 *****************/
export function sendFiles(payload: FilearchiveTableResult): SendFiles {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_FILES,
    payload,
  };
}

export function sendFilearchiveLoading(): SendFilearchiveLoading {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_FILEARCHIVE_LOADING,
  };
}

export function sendFilearchiveError(error: FetchError): SendFilearchiveError {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_FILEARCHIVE_ERROR,
    payload: {
      error: error,
    },
  };
}

export function sendLimit(limit: number): SendLimit {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_LIMIT,
    payload: limit,
  };
}

export function sendOffset(offset: number): SendOffset {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_OFFSET,
    payload: offset,
  };
}
export function sendItemChecked(
  rdfId: string,
  checked: boolean
): SendItemChecked {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_ITEM_CHECKED,
    payload: {
      rdfId: rdfId,
      checked: checked,
    },
  };
}

export function sendAllItemChecked(checked: boolean): SendAllItemChecked {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_ALL_ITEM_CHECKED,
    payload: checked,
  };
}
export function sendStartEdit(sha1: string, columnName: string): SendStartEdit {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_START_EDIT,
    payload: {
      sha1,
      columnName,
    },
  };
}

export function sendStopEdit(): SendStopEdit {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_STOP_EDIT,
  };
}

export function sendSearchInput(searchInput: string): SendSearchInput {
  return {
    fragmentType: constants.FILEARCHIVE,
    type: constants.SEND_SEARCH_INPUT_FILE,
    payload: searchInput,
  };
}

/***********
 * Actions *
 ***********/
export function saveFile(
  file: any
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const filearchiveState = getState().filearchive;
    if (filearchiveState) {
      try {
        const fileInfo = await saveFileImpl(file);
        const uploadedFile: FileInfo = {
          sha1: fileInfo?.sha1 || "",
          contentType: fileInfo.contentType || "",
          contentLength: fileInfo.contentLength || 0,
          originalName: fileInfo.originalName || "",
          uploadTimestamp: fileInfo.uploadTimestamp || "",
          storageUrl: fileInfo.storageUrl || "",
        };

        const tableInfo: any = {
          pageable: true,
          offset: filearchiveState.offset,
          limit: filearchiveState.limit,
        };
        dispatch(fetchFiles(tableInfo));
        openNamedModal(UploadedFileInfoModal.ID, { fileInfo: uploadedFile });
      } catch (e) {
        dispatchError("FILEARCHIVE_UPLOAD_ERROR", e, dispatch);
      }
    }
  };
}

export function downloadFile(
  sha1: string
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    try {
      const file = await downloadFileImpl(sha1);
    } catch (e) {
      dispatchError("FILEARCHIVE_NOT_FOUND", e, dispatch);
    }
  };
}

export function fetchFiles(
  tableInfo: any
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    try {
      const filearchiveState = getState().filearchive;
      if (filearchiveState && filearchiveState.loading) {
        return;
      }
      filearchiveState && (tableInfo.search = filearchiveState.searchInput);
      dispatch(sendFilearchiveLoading());
      dispatch(sendFiles(await fetchFilesImpl(tableInfo)));
      dispatch(sendOffset(tableInfo.offset));
    } catch (e) {
      dispatchError("FILEARCHIVE_FETCH_ERROR", e, dispatch);
    }
  };
}

export function setOffset(
  offset: number
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    dispatch(sendOffset(offset));
  };
}

export function setItemChecked(
  rdfid: string,
  checked: boolean
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    dispatch(sendItemChecked(rdfid, checked));
  };
}

export function setAllItemsChecked(
  checked: boolean
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    dispatch(sendAllItemChecked(checked));
  };
}

export function setLimit(
  limit: number
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    const filearchivState = getState().filearchive;
    if (!filearchivState) {
      return;
    }
    let tableInfo: any = { pageable: true, offset: 0, limit: limit };
    dispatch(sendLimit(limit));
    dispatch(fetchFiles(tableInfo));
  };
}

export function setStartEdit(
  sha1: string,
  field: string
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    dispatch(sendStartEdit(sha1, field));
  };
}

export function setStopEditSuccess(
  newValue: string
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    const filearchiveState = getState().filearchive;
    if (filearchiveState && filearchiveState.info.edited) {
      try {
        let editedObject: EditFilearchiveInfo = {
          ...filearchiveState.info.edited,
          columnValue: newValue,
        };
        let tableInfo: any = {
          pageable: true,
          offset: filearchiveState.offset,
          limit: filearchiveState.limit,
        };
        await editFileImpl(editedObject);
        dispatch(sendStopEdit());
        dispatch(fetchFiles(tableInfo));
        const messge: I18NString = { id: "FILE_EDIT_SUCCESS" };
        dispatch(addAlert(ALERT_LEVEL_SUCCESS, messge));
      } catch (e) {
        dispatchError("FILE_EDIT_ERROR", e, dispatch);
      }
    }
  };
}

export function setStopEditCancelled(): ThunkAction<
  void,
  ApplicationState,
  {},
  FilearchiveAction
> {
  return async (dispatch, getState) => {
    dispatch(sendStopEdit());
  };
}

export function setSearch(
  value: string
): ThunkAction<void, ApplicationState, {}, FilearchiveAction> {
  return async (dispatch, getState) => {
    const filearchiveState = getState().filearchive;
    if (!filearchiveState) {
      return;
    }
    let tableInfo: any = {
      pageable: true,
      offset: 0,
      limit: filearchiveState.limit,
    };
    dispatch(sendSearchInput(value));
    dispatch(fetchFiles(tableInfo));
  };
}
