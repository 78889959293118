import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../types';
import { LoggedInUser } from '../../types/security';
import { Column } from '../../types/table';
import { TableProps } from './Table';

import FinderContainer from '../finder/FinderContainer';
import Finder from '../finder/Finder';
import TableToolbar from './TableToolbar';

import styles from './TableFinder.module.css';

interface TableFinderContainerProps extends TableProps {
    /**List of selected dynamic columns */
    selectedDynamicColumns: Column[] | null
    /** List of user rights */
    userAcl: string[]
}

/********************************
 *    Table Finder Component    *
 ********************************/
const TableFinderContainer: React.FunctionComponent<React.PropsWithChildren<TableFinderContainerProps>> = React.memo((props) => {
    if (!props.finderOptions) {
        return <>
            {props.children}
        </>
    }

    return <FinderContainer>
        <TableToolbar
            tableId={props.tableId}
            loadingData={Boolean(props.loadingData)}
            parameters={props.parameters}
            fields={props.fields}
            filterChanges={props.filterChanges}
            gantOptions={props.gantOptions}
            gantData={props.gantData}
            reports={props.reports}
            toolbar={props.toolbar}
            userAcl={props.userAcl}
            columns={props.selectedDynamicColumns || props.columns}
            totalRowsLength={props.totalRowsLength}
            downloadReport={props.downloadReport}
            buttonClick={props.buttonClick}
            changeField={props.changeField}
            changeViewType={props.changeViewType}
            changeGroup={props.changeGroup}
            confirmFilterChanges={props.confirmFilterChanges}
            denyFilterChanges={props.denyFilterChanges}
            saveTable={props.saveTable}
        />
        <Finder finderId={props.tableId} model={props.tableId}>
            {props.children}
        </Finder>
    </FinderContainer>;
});

export default connect((state: ApplicationState) => {
    return {
        userAcl: (state.security.loginStatus as LoggedInUser)?.generalAccessRules || []
    }
})(TableFinderContainer);