import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FINDER_VIEW_TYPE_ADD, FINDER_VIEW_TYPE_EDIT } from '../../constants/finder';
import { AlertLevelType } from '../../types/alert';
import { CancelCallback, CloseCallback, I18NString, ModalOptions, OkCallback } from '../../types/modal';
import {
    FinderCriteriaTreeSettings,
    FinderRelation,
    FinderViewType,
    FinderData,
    FinderOptions,
    FinderFieldsStore,
    FinderObjectcardsStore,
    FinderPredicatesStore
} from '../../types/finder';
import { MSG_OR } from '../messages';

import FinderCriterion from './FinderCriterion';

import styles from './Finder.module.css';

interface FinderCriteriaBarProps {
    isHidden: boolean
    isManual?: boolean
    view: FinderViewType
    loadedFields: FinderFieldsStore
    loadedObjectcards: FinderObjectcardsStore
    loadedPredicates: FinderPredicatesStore
    finderOptions: FinderOptions
    finderData: FinderData
    finderDataChanges: FinderData | null
    sendHidden: (value?: boolean) => void
    changeFinderView: (view: FinderViewType) => void
    fetchFields: (parentId: string) => void
    fetchObjectcard: (rdfId: string) => void
    addCriteria: (criteriaGroupId?: string) => void
    addCriteriaRelation: (criteriaId: string) => void
    removeCriteria: (criteriaId: string) => void
    removeCriteriaRelation: (criteriaId: string, relationIdx: number) => void
    changeCriteriaRelation: (criteriaId: string, relation: FinderRelation, relationIdx: number) => void
    changeCriteriaField: (criteriaId: string, fieldId: string) => void
    openModal: (type: string, options: ModalOptions, okCallback?: OkCallback, cancelCallback?: CancelCallback, closeCallback?: CloseCallback) => void
    addAlert: (type: AlertLevelType, message: string | I18NString) => void
}

/*********************************
 * Finder Criteria Bar Component *
 *********************************/
const FinderCriteriaBar: React.FunctionComponent<FinderCriteriaBarProps> = React.memo((props) => {
    if (props.isHidden) {
        if (props.isManual) {
            return null;
        }
        return <ExpandBar
            sendHidden={props.sendHidden}
            changeFinderView={props.changeFinderView}
        />;
    }
    return <CriteriaTree
        isManual={props.isManual}
        view={props.view}
        loadedFields={props.loadedFields}
        loadedObjectcards={props.loadedObjectcards}
        loadedPredicates={props.loadedPredicates}
        finderOptions={props.finderOptions}
        finderData={props.finderData}
        finderDataChanges={props.finderDataChanges}
        fetchFields={props.fetchFields}
        fetchObjectcard={props.fetchObjectcard}
        sendHidden={props.sendHidden}
        changeFinderView={props.changeFinderView}
        addCriteria={props.addCriteria}
        addCriteriaRelation={props.addCriteriaRelation}
        removeCriteria={props.removeCriteria}
        removeCriteriaRelation={props.removeCriteriaRelation}
        changeCriteriaRelation={props.changeCriteriaRelation}
        changeCriteriaField={props.changeCriteriaField}
        openModal={props.openModal}
        addAlert={props.addAlert}
    />;
});

interface ExpandBarProps {
    sendHidden: (value?: boolean) => void
    changeFinderView: (view: FinderViewType) => void
}
const ExpandBar: React.FunctionComponent<ExpandBarProps> = React.memo((props) => {
    return <div className={`${styles.table} ${styles.expandBar} card p-0 col`}>
        <div className={`${styles.tableHeading} card-header ${styles.expandRow} p-0`} onClick={() => props.sendHidden()}>
            <h6 className="card-title font-weight-bold">
                <FormattedMessage
                    id="FINDER_EXPAND_SELECTION"
                    defaultMessage="Expand selection"
                    description="Expand selection" />
                ...
            </h6>
            <h6 className={`fa fa-window-maximize ${styles.toggleButton}`}>&nbsp;</h6>
        </div>
    </div>;
});

interface CriteriaTreeProps {
    view: FinderViewType
    isManual?: boolean
    loadedFields: FinderFieldsStore
    loadedObjectcards: FinderObjectcardsStore
    loadedPredicates: FinderPredicatesStore
    finderOptions: FinderOptions
    finderData: FinderData
    finderDataChanges: FinderData | null
    fetchFields: (parentId: string) => void
    fetchObjectcard: (rdfId: string) => void
    sendHidden: () => void
    changeFinderView: (view: FinderViewType) => void
    addCriteria: (criteriaGroupId?: string) => void
    addCriteriaRelation: (criteriaId: string) => void
    removeCriteria: (criteriaId: string) => void
    removeCriteriaRelation: (criteriaId: string, relationIdx: number) => void
    changeCriteriaRelation: (criteriaId: string, relation: FinderRelation, relationIdx: number) => void
    changeCriteriaField: (criteriaId: string, fieldId: string) => void
    openModal: (type: string, options: ModalOptions, okCallback?: OkCallback, cancelCallback?: CancelCallback, closeCallback?: CloseCallback) => void
    addAlert: (type: AlertLevelType, message: string | I18NString) => void
}
const CriteriaTree: React.FunctionComponent<CriteriaTreeProps> = React.memo((props) => {
    const isCriteriaTreeVisible = props.finderOptions.criteriaTree && !props.finderOptions.criteriaTree?.hidden;

    if (!isCriteriaTreeVisible) {
        return null;
    }

    const finderActualData = props.finderDataChanges || props.finderData;

    return <div className={`${styles.criteriaTree} ${props.view == FINDER_VIEW_TYPE_EDIT ? styles.viewTypeEdit : ""} npt-finder-criteria-tree`}>
        <div className={`${styles.table} card`}>
            <div className={`${styles.tableHeading} card-header`}>
                <h6 className="card-title font-weight-bold">
                    <FormattedMessage
                        id="FINDER_SELECTION_CRITERIA"
                        defaultMessage="Selection criteria"
                        description="Selection criteria" />
                </h6>
                {!props.isManual && <h6 className={`fa fa-window-minimize ${styles.minimizeBtn} ${styles.toggleButton}`} onClick={() => props.sendHidden()}>&nbsp;</h6>}
            </div>
            <div className={`${styles.tableBody}`}>
                <div className={`${styles.tableHeaderRow}`}>
                    <div className={`col-md-7 ${styles.tableHeader}`} style={{ borderRight: "none" }}>
                        <FormattedMessage
                            id="FINDER_TABLE_PARAMETER"
                            defaultMessage="Parameter"
                            description="Parameter" />
                    </div>
                    <div className="col-md-5 p-0" style={{ display: "flex", borderLeft: "1px solid #ddd" }}>
                        <div className={`col-md-5 ${styles.tableHeader}`}>
                            <FormattedMessage
                                id="FINDER_TABLE_RELATION"
                                defaultMessage="Relation"
                                description="Relation" />
                        </div>
                        <div className={`col-md-5 ${styles.tableHeader}`}>
                            <FormattedMessage
                                id="FINDER_TABLE_VALUE"
                                defaultMessage="Value"
                                description="Value" />
                        </div>
                        <div className={`col-md-2 ${styles.tableHeader}`}>
                            <div className="btn-group" role="group">
                                <button
                                    type="button"
                                    className={"col btn btn-secondary" + (props.view == FINDER_VIEW_TYPE_EDIT ? " active" : "")}
                                    onClick={props.changeFinderView.bind(null, FINDER_VIEW_TYPE_EDIT)}
                                >
                                    <i className="fa fa-pencil" aria-hidden="true">&nbsp;</i>
                                </button>
                                <button
                                    type="button"
                                    className={"col btn btn-secondary" + (props.view == FINDER_VIEW_TYPE_ADD ? " active" : "")}
                                    onClick={props.changeFinderView.bind(null, FINDER_VIEW_TYPE_ADD)}
                                >
                                    <i className="fa fa-plus" aria-hidden="true">&nbsp;</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {finderActualData.criteriaGroupList.map((criteriaGroupId, index) => <div key={index} className={`${styles.tableRowGroup}`}>
                    <Criteria
                        id={criteriaGroupId}
                        isFirst={index === 0}
                        loadedFields={props.loadedFields}
                        loadedObjectcards={props.loadedObjectcards}
                        loadedPredicates={props.loadedPredicates}
                        finderData={props.finderData}
                        finderDataChanges={props.finderDataChanges}
                        criteriaTree={props.finderOptions.criteriaTree}
                        fetchFields={props.fetchFields}
                        fetchObjectcard={props.fetchObjectcard}
                        addCriteriaRelation={props.addCriteriaRelation}
                        removeCriteria={props.removeCriteria}
                        removeCriteriaRelation={props.removeCriteriaRelation}
                        changeCriteriaRelation={props.changeCriteriaRelation}
                        changeCriteriaField={props.changeCriteriaField}
                        openModal={props.openModal}
                        addAlert={props.addAlert}
                    />
                    {props.view == FINDER_VIEW_TYPE_ADD && <div className={`${styles.tableRowButtons}`}>
                        <button
                            type='button'
                            className={`btn btn-secondary btn-xs ${styles.button}`}
                            onClick={() => { props.addCriteria(criteriaGroupId) }}
                        >
                            <span className="pull-left">
                                <i className="fa fa-plus" aria-hidden="true">&nbsp;</i>
                            </span>
                            <FormattedMessage
                                id="FINDER_ADD_AND_CRITERION"
                                defaultMessage="AND"
                                description="Label of add 'and' criterion button" />
                        </button>
                    </div>}
                </div>)}
                {props.view == FINDER_VIEW_TYPE_ADD && <div className={styles.tableRowOr} style={{ textAlign: "center" }}>
                    <button
                        type='button'
                        className={`btn btn-secondary btn-xs ${styles.button}`}
                        onClick={() => { props.addCriteria() }}>
                        <span className="pull-left">
                            <i className="fa fa-code-fork" aria-hidden="true">&nbsp;</i>
                        </span>
                        <FormattedMessage
                            id="FINDER_ADD_OR_CRITERION"
                            defaultMessage="OR"
                            description="Label of add 'or' criterion button" />
                    </button>
                </div>}
            </div>
        </div>
    </div>;
});

interface CriteriaProps {
    id: string
    isFirst: boolean
    loadedFields: FinderFieldsStore
    loadedObjectcards: FinderObjectcardsStore
    loadedPredicates: FinderPredicatesStore
    finderData: FinderData
    finderDataChanges: FinderData | null
    criteriaTree?: FinderCriteriaTreeSettings
    fetchFields: (parentId: string) => void
    fetchObjectcard: (rdfId: string) => void
    addCriteriaRelation: (criteriaId: string) => void
    removeCriteria: (criteriaId: string) => void
    removeCriteriaRelation: (criteriaId: string, relationIdx: number) => void
    changeCriteriaRelation: (criteriaId: string, relation: FinderRelation, relationIdx: number) => void
    changeCriteriaField: (criteriaId: string, fieldId: string) => void
    openModal: (type: string, options: ModalOptions, okCallback?: OkCallback, cancelCallback?: CancelCallback, closeCallback?: CloseCallback) => void
    addAlert: (type: AlertLevelType, message: string | I18NString) => void
}
const Criteria: React.FunctionComponent<CriteriaProps> = React.memo((props) => {
    const criteriaGroupId = props.id;
    const finderActualData = props.finderDataChanges || props.finderData;
    const criteriaIdList = finderActualData.criteriaGroup.byId[criteriaGroupId].criteriaList;
    if (criteriaIdList.length == 0) {
        return <div><FormattedMessage
            id="FINDER_NO_CRITERION"
            defaultMessage="No criterion"
            description="No criterion" /></div>
    }

    const criteriaList = [];
    for (let criteriaId of criteriaIdList) {
        criteriaList.push(finderActualData.criteria.byId[criteriaId]);
    }
    return <>
        {!props.isFirst && <div className={`${styles.tableRowOr}`}>
            <span className={`${styles.advancedGrayscale}`}>
                {MSG_OR}
            </span>
        </div>}
        <div className={`${styles.tableRowGroup}`}>
            {criteriaList.map((criteria, index) =>
                <FinderCriterion
                    key={index}
                    loadedFields={props.loadedFields}
                    loadedObjectcards={props.loadedObjectcards}
                    loadedPredicates={props.loadedPredicates}
                    criteria={criteria}
                    criteriaTree={props.criteriaTree}
                    fetchFields={props.fetchFields}
                    fetchObjectcard={props.fetchObjectcard}
                    addCriteriaRelation={props.addCriteriaRelation}
                    removeCriteria={props.removeCriteria}
                    removeCriteriaRelation={props.removeCriteriaRelation}
                    changeCriteriaRelation={props.changeCriteriaRelation}
                    changeCriteriaField={props.changeCriteriaField}
                    openModal={props.openModal}
                    addAlert={props.addAlert}
                />)}
        </div>
    </>;
});

export default FinderCriteriaBar;