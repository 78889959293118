import { TableFormattedSelectOption } from "../types/table";

export const COLUMN_MIN_WIDTH = 60;
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGE_SELECTION: number[] = [10, 25, 50, 100];

export const MONTHS = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

/**Standart colors for gant draggable elements */
export const STANDART_COLORS = {
    standartRed: "#B00",
    standartGreen: "#3D990F",
    standartBlue: "#007DCC",
    standartLightBlue: "#80BEE6",
    standartOrange: "#FE8421",
    standartGray: "rgb(200,200,200)",
    standartDarkGray: "rgb(100,100,100)"
};

export const VIEW_TYPE_READ = "NPT_GANT_VIEW_TYPE_READ";
export const VIEW_TYPE_EDIT = "NPT_GANT_VIEW_TYPE_EDIT";
export const VIEW_TYPE_EDIT_PLANNING = "NPT_GANT_VIEW_TYPE_EDIT_PLANNING";
export const VIEW_TYPE_EDIT_CHOSEN = "NPT_GANT_VIEW_TYPE_EDIT_CHOSEN";
export const VIEW_TYPE_ADD = "NPT_GANT_VIEW_TYPE_ADD";
export const VIEW_TYPE_ADD_PLANNING = "NPT_GANT_VIEW_TYPE_ADD_PLANNING";
export const VIEW_TYPE_ADD_CHOSEN = "NPT_GANT_VIEW_TYPE_ADD_CHOSEN";

export const GANT_READ = "read";
export const GANT_CHANGE = "change";
export const GANT_FULL = "full";
export const GANT_PLANNED_DATE = "plannedDate";
export const GANT_CHOSEN_DATE = "chosenDate";

export const VIEW_MODE_LIST: {
    read: TableFormattedSelectOption[],
    change: TableFormattedSelectOption[],
    full: TableFormattedSelectOption[],
    chosenOnly: {
        change: TableFormattedSelectOption[],
        full: TableFormattedSelectOption[]
    }
} = {
    read: [{ formattedMsg: VIEW_TYPE_READ, value: GANT_READ }],
    change: [
        { formattedMsg: VIEW_TYPE_READ, value: GANT_READ },
        { formattedMsg: VIEW_TYPE_EDIT_PLANNING, value: GANT_PLANNED_DATE },
        { formattedMsg: VIEW_TYPE_EDIT_CHOSEN, value: GANT_CHOSEN_DATE }
    ],
    full: [
        { formattedMsg: VIEW_TYPE_READ, value: GANT_READ },
        { formattedMsg: VIEW_TYPE_ADD_PLANNING, value: GANT_PLANNED_DATE },
        { formattedMsg: VIEW_TYPE_ADD_CHOSEN, value: GANT_CHOSEN_DATE }
    ],
    chosenOnly: {
        change: [
            { formattedMsg: VIEW_TYPE_READ, value: GANT_READ },
            { formattedMsg: VIEW_TYPE_EDIT, value: GANT_CHOSEN_DATE }
        ],
        full: [
            { formattedMsg: VIEW_TYPE_READ, value: GANT_READ },
            { formattedMsg: VIEW_TYPE_ADD, value: GANT_CHOSEN_DATE }
        ]
    }
}

/* Actions */
export const SEND_TABLE_INITIAL_DATA = 'SEND_TABLE_INITIAL_DATA';
export type SEND_TABLE_INITIAL_DATA = typeof SEND_TABLE_INITIAL_DATA;

export const SEND_TABLE_HEADER = 'SEND_TABLE_HEADER';
export type SEND_TABLE_HEADER = typeof SEND_TABLE_HEADER;

export const SEND_TABLE_DATA = 'SEND_TABLE_DATA';
export type SEND_TABLE_DATA = typeof SEND_TABLE_DATA;

export const SEND_TABLE_LOADING = 'SEND_TABLE_LOADING ';
export type SEND_TABLE_LOADING = typeof SEND_TABLE_LOADING;

export const SEND_TABLE_ERROR = 'SEND_TABLE_ERROR';
export type SEND_TABLE_ERROR = typeof SEND_TABLE_ERROR;

export const SEND_TABLE_DATA_LOADING = 'SEND_TABLE_DATA_LOADING';
export type SEND_TABLE_DATA_LOADING = typeof SEND_TABLE_DATA_LOADING;

export const SEND_TABLE_DATA_ERROR = 'SEND_TABLE_DATA_ERROR';
export type SEND_TABLE_DATA_ERROR = typeof SEND_TABLE_DATA_ERROR;

export const SEND_TABLE_FILTER_SELECTION_LOADING = 'SEND_TABLE_FILTER_SELECTION_LOADING';
export type SEND_TABLE_FILTER_SELECTION_LOADING = typeof SEND_TABLE_FILTER_SELECTION_LOADING;

export const SEND_TABLE_FILTER_SELECTION_ERROR = 'SEND_TABLE_FILTER_SELECTION_ERROR';
export type SEND_TABLE_FILTER_SELECTION_ERROR = typeof SEND_TABLE_FILTER_SELECTION_ERROR;

export const SEND_TABLE_FILTER_SELECTION = 'SEND_TABLE_FILTER_SELECTION';
export type SEND_TABLE_FILTER_SELECTION = typeof SEND_TABLE_FILTER_SELECTION;

export const SEND_TABLE_FILTER_RANGE_LOADING = 'SEND_TABLE_FILTER_RANGE_LOADING';
export type SEND_TABLE_FILTER_RANGE_LOADING = typeof SEND_TABLE_FILTER_RANGE_LOADING;

export const SEND_TABLE_FILTER_RANGE_ERROR = 'SEND_TABLE_FILTER_RANGE_ERROR';
export type SEND_TABLE_FILTER_RANGE_ERROR = typeof SEND_TABLE_FILTER_RANGE_ERROR;

export const SEND_TABLE_FILTER_RANGE = 'SEND_TABLE_FILTER_RANGE';
export type SEND_TABLE_FILTER_RANGE = typeof SEND_TABLE_FILTER_RANGE;

export const SEND_TABLE_SORT_OPTIONS = 'SEND_TABLE_SORT_OPTIONS';
export type SEND_TABLE_SORT_OPTIONS = typeof SEND_TABLE_SORT_OPTIONS;

export const SEND_TABLE_COLUMNS = 'SEND_TABLE_COLUMNS';
export type SEND_TABLE_COLUMNS = typeof SEND_TABLE_COLUMNS;

export const SEND_SELECT_ROW = 'SEND_SELECT_ROW';
export type SEND_SELECT_ROW = typeof SEND_SELECT_ROW;

export const SEND_SELECT_ALL = 'SEND_SELECT_ALL';
export type SEND_SELECT_ALL = typeof SEND_SELECT_ALL;

export const SEND_PAGE_SIZE = 'SEND_PAGE_SIZE';
export type SEND_PAGE_SIZE = typeof SEND_PAGE_SIZE;

export const SEND_PAGE = 'SEND_PAGE';
export type SEND_PAGE = typeof SEND_PAGE;

export const SEND_SORT_KEY = 'SEND_SORT_KEY';
export type SEND_SORT_KEY = typeof SEND_SORT_KEY;

export const SEND_FILTER_DATA = 'SEND_FILTER_DATA';
export type SEND_FILTER_DATA = typeof SEND_FILTER_DATA;

export const SEND_LOCAL_TABLE_INITIAL_DATA = 'SEND_LOCAL_TABLE_INITIAL_DATA';
export type SEND_LOCAL_TABLE_INITIAL_DATA = typeof SEND_LOCAL_TABLE_INITIAL_DATA;

export const SEND_LOCAL_TABLE_UPDATE_DATA = 'SEND_LOCAL_TABLE_UPDATE_DATA';
export type SEND_LOCAL_TABLE_UPDATE_DATA = typeof SEND_LOCAL_TABLE_UPDATE_DATA;

export const SEND_LOCAL_TABLE_UNINITIALIZE = 'SEND_LOCAL_TABLE_UNINITIALIZE';
export type SEND_LOCAL_TABLE_UNINITIALIZE = typeof SEND_LOCAL_TABLE_UNINITIALIZE;

export const SEND_FIELD = 'SEND_FIELD';
export type SEND_FIELD = typeof SEND_FIELD;

export const SEND_GANT_VIEW_TYPE = 'SEND_GANT_VIEW_TYPE';
export type SEND_GANT_VIEW_TYPE = typeof SEND_GANT_VIEW_TYPE;

export const SEND_GANT_SELECTED_GROUP = 'SEND_GANT_SELECTED_GROUP';
export type SEND_GANT_SELECTED_GROUP = typeof SEND_GANT_SELECTED_GROUP;

export const SEND_GANT_CHANGE_ELEMENT = 'SEND_GANT_CHANGE_ELEMENT';
export type SEND_GANT_CHANGE_ELEMENT = typeof SEND_GANT_CHANGE_ELEMENT;

export const SEND_FILTER_CHANGES_CONFIRM = 'SEND_FILTER_CHANGES_CONFIRM';
export type SEND_FILTER_CHANGES_CONFIRM = typeof SEND_FILTER_CHANGES_CONFIRM;

export const SEND_FILTER_CHANGES_DENY = 'SEND_FILTER_CHANGES_DENY';
export type SEND_FILTER_CHANGES_DENY = typeof SEND_FILTER_CHANGES_DENY;

export const SEND_TABLE_SAVE_START = 'SEND_TABLE_SAVE_START';
export type SEND_TABLE_SAVE_START = typeof SEND_TABLE_SAVE_START;

export const SEND_TABLE_SAVE_SUCCESS = 'SEND_TABLE_SAVE_SUCCESS';
export type SEND_TABLE_SAVE_SUCCESS = typeof SEND_TABLE_SAVE_SUCCESS;

export const SEND_TABLE_SAVE_ERROR = 'SEND_TABLE_SAVE_ERROR';
export type SEND_TABLE_SAVE_ERROR = typeof SEND_TABLE_SAVE_ERROR;

export const SEND_TABLE_ROW_ADD = 'SEND_TABLE_ROW_ADD';
export type SEND_TABLE_ROW_ADD = typeof SEND_TABLE_ROW_ADD;

export const SEND_TABLE_ROW_CHANGE = 'SEND_TABLE_ROW_CHANGE';
export type SEND_TABLE_ROW_CHANGE = typeof SEND_TABLE_ROW_CHANGE;

export const SEND_TABLE_CELL_CHANGE = 'SEND_TABLE_CELL_CHANGE';
export type SEND_TABLE_CELL_CHANGE = typeof SEND_TABLE_CELL_CHANGE;

export const SEND_TABLE_CLIENT_SIDE_FILTER = 'SEND_TABLE_CLIENT_SIDE_FILTER';
export type SEND_TABLE_CLIENT_SIDE_FILTER = typeof SEND_TABLE_CLIENT_SIDE_FILTER;