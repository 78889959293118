import * as React from 'react';

import { FinderOptionsSettings, FinderProps } from '../../types/finder';
import { TreeState } from '../../types/tree';
import { connectFinder } from '../../services/finder';

import FinderSideBar from '../finder/FinderSideBar';
import FinderCriteriaBar from '../finder/FinderCriteriaBar';
import SearchInput from './SearchInput';

import styles from './Search.module.css';

interface SearchFinderProps extends FinderProps {
    finderOptions: FinderOptionsSettings
    treeState?: TreeState
    search: string
    visibility: { [k: string]: boolean }
    changeSearch: (value: string) => void
    finishSearch: (value: string) => void
    changeVisibility: (changes: { [k: string]: boolean }) => void
}
const SearchFinder: React.FunctionComponent<React.PropsWithChildren<SearchFinderProps>> = React.memo((props) => {
    React.useEffect(() => {
        if (!props.finderId || !props.treeState?.header || props.initialized) {
            return;
        }
        const finderOptions: FinderOptionsSettings = {};
        if (props.treeState.header.fragmentTree) {
            finderOptions.fragmentTree = {
                path: props.treeState.header.fragmentTree.model,
                levels: props.treeState.header.fragmentTree.levels,
                hidden: props.finderOptions.fragmentTree?.hidden
            }
        }
        if (props.treeState.header.classTree) {
            finderOptions.classTree = {
                levels: props.treeState.header.classTree.levels,
                hidden: props.finderOptions.classTree?.hidden
            }
        }
        if (props.treeState.header.criteriaTree) {
            finderOptions.criteriaTree = {
                path: props.treeState.header.criteriaTree,
                hidden: props.finderOptions.criteriaTree?.hidden
            }
        }
        props.initializeFinder(finderOptions);
    }, [props.finderId, props.treeState?.header]);

    return <div className={`d-flex flex-fill overflow-hidden ${styles.finder}`}>
        <FinderSideBar
            isHidden={!props.visibility.sidebar}
            isManual={true}
            loadedFragments={props.loadedFragments}
            loadedClasses={props.loadedClasses}
            finderOptions={props.options}
            finderData={props.data}
            finderDataChanges={props.changes}
            fetchFragments={props.fetchFragments}
            selectFragment={props.selectFragment}
            fetchClasses={props.fetchClasses}
            selectClass={props.selectClass}
            sendHidden={props.sendHidden}
        />
        <div className="d-flex flex-column flex-fill overflow-hidden">
            <SearchInput
                className="pt-3"
                value={props.search}
                finderOptions={props.options}
                visibility={props.visibility}
                onChange={props.changeSearch}
                onFinish={props.finishSearch}
                changeVisibility={props.changeVisibility}
            />
            <FinderCriteriaBar
                isHidden={!props.visibility.criteria}
                isManual={true}
                view={props.view}
                loadedFields={props.loadedFields}
                loadedObjectcards={props.loadedObjectcards}
                loadedPredicates={props.loadedPredicates}
                finderOptions={props.options}
                finderData={props.data}
                finderDataChanges={props.changes}
                sendHidden={props.sendHidden}
                fetchFields={props.fetchFields}
                fetchObjectcard={props.fetchObjectcard}
                changeFinderView={props.changeFinderView}
                addCriteria={props.addCriteria}
                addCriteriaRelation={props.addCriteriaRelation}
                removeCriteria={props.removeCriteria}
                removeCriteriaRelation={props.removeCriteriaRelation}
                changeCriteriaRelation={props.changeCriteriaRelation}
                changeCriteriaField={props.changeCriteriaField}
                openModal={props.openModal}
                addAlert={props.addAlert}
            />
            <div className={`d-flex flex-fill overflow-hidden ${styles.innerContainer}`}>
                {props.children}
            </div>
        </div>
    </div>;
});
export default connectFinder(SearchFinder);