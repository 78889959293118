export const ALERT = "alert";

//Actions
// export const ADD_ALERT = "@@ALERT_ADD_ALERT";
// export const REMOVE_ALERT = "@@ALERT_REMOVE_ALERT";

export const SEND_ADD_ALERT = "SEND_ADD_ALERT";
export type SEND_ADD_ALERT = typeof SEND_ADD_ALERT;

export const SEND_REMOVE_ALERT = "SEND_REMOVE_ALERT";
export type SEND_REMOVE_ALERT = typeof SEND_REMOVE_ALERT; 

export const ALERT_LEVEL_INFO = "info";
export type ALERT_LEVEL_INFO = typeof ALERT_LEVEL_INFO;

export const ALERT_LEVEL_SUCCESS = "success";
export type ALERT_LEVEL_SUCCESS = typeof ALERT_LEVEL_SUCCESS;

export const ALERT_LEVEL_WARNING = "warning";
export type ALERT_LEVEL_WARNING = typeof ALERT_LEVEL_WARNING;

export const ALERT_LEVEL_DANGER = "danger";
export type ALERT_LEVEL_DANGER = typeof ALERT_LEVEL_DANGER;

