import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as c from "../constants/menueditor";
import { CtxMenuId } from "../constants/menueditor";
import { ApplicationAction, ApplicationState } from "../types";
import {
  MenuData,
  MenuEditorAction,
  MenuEditorState,
  MenuItem,
  SendMenuEditorAclList,
  SendMenuEditorClearMenu,
  SendMenuEditorCopyItem,
  SendMenuEditorCtxMenuEditor,
  SendMenuEditorCtxMenuList,
  SendMenuEditorCutItem,
  SendMenuEditorEditingMenu,
  SendMenuEditorFilteredFoldersSearch,
  SendMenuEditorItemSourceCode,
  SendMenuEditorItemSourceCodeLoading,
  SendMenuEditorMenu,
  SendMenuEditorMenuActive,
  SendMenuEditorMenuActiveAll,
  SendMenuEditorMenuToggle,
  SendMenuEditorOperation,
  SendMenuEditorRemoveItem,
  SendMenuEditorRemoveMenu,
  SendMenuEditorSaveMenu,
  SendMenuEditorSaveMenuItem,
  SendMenuLoading,
  SendMenuPathActive,
} from "../types/menueditor";
import { CodePosition } from "../types/sourceeditor";
import { EnumerationInfo } from "../types/subject";
import { dispatchError, dispatchSuccess } from "./alert";
import { openModal } from "./modal";
import { fetchEnumeration, parseEnumeration } from "./subject";
import { ServerError } from "./utils";

export function updateTreeFire() {
  const newSubjectAddedEvent = new CustomEvent(
    c.EventType.UPDATE_MENU_LIST_TREE
  );
  document.dispatchEvent(newSubjectAddedEvent);
}

async function fetchMenuItemPositionImpl(
  path: string,
  id: string
): Promise<CodePosition> {
  const response = await fetch(
    `/rest/menueditor/codeposition${path}?itemid=${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );
  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  return await response.json();
}
async function fetchChangeMenuItemOrderImpl(
  path: string,
  itemId: string,
  anchorItemId: string,
  newParentId: string | null,
  isBefore: boolean
): Promise<void> {
  let queryParams = `?itemid=${itemId}&anchoritemid=${anchorItemId}&isbefore=${isBefore}`;
  if (newParentId) {
    queryParams += `&newparent=${newParentId}`;
  }
  const response = await fetch(
    `/rest/menueditor/changeorderitem${path}${queryParams}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );
  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
}

async function fetchMenuImpl(path: string): Promise<MenuData> {
  const response = await fetch(`/rest/menueditor/menu${path}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  const menu = await response.json();
  return menu;
}

async function fetchMoveMenuItemImpl(
  path: string,
  id: string,
  newParentId: string | null,
  copy: boolean = false
): Promise<void> {
  let query = `?itemid=${encodeURIComponent(id)}`;
  newParentId && (query += `&newparent=${encodeURIComponent(newParentId)}`);
  const operation = copy ? "copy" : "cut";
  const response = await fetch(
    `/rest/menueditor/${operation}item${path}${query}`,
    { method: "PUT" }
  );
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
}
async function fetchMoveSelectedItemsImpl(
  path: string,
  items: MenuItem[],
  copy: boolean = false
): Promise<void> {
  const operation = copy ? "itemscopy" : "itemscut";
  const response = await fetch(`/rest/menueditor/${operation}${path}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(items),
  });
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
}

async function fetchSaveItemImpl(
  menu: MenuItem,
  path: string
): Promise<MenuItem> {
  const jsonM = JSON.stringify(menu);
  const response = await fetch(`/rest/menueditor/save${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: jsonM,
  });
  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }

  const item = await response.json();
  if (item) {
    item.parentId = menu.parentId;
  }
  return item;
}
async function fetchRemoveItemImpl(
  menu: MenuItem,
  path: string
): Promise<void> {
  const { id, parentId } = menu;
  let query = `?id=${id}`;
  if (parentId) {
    query += `&parent=${parentId}`;
  }
  const response = await fetch(`/rest/menueditor/removeone${path}${query}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
}
async function fetchRemoveItemAllImpl(
  menuItems: MenuItem[],
  path: string
): Promise<void> {
  const items: MenuItem[] = [];
  menuItems.forEach((m) => {
    const { id, parentId } = m;
    items.push({ id, parentId });
  });
  const response = await fetch(`/rest/menueditor/removeall${path}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(items),
  });
  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
}

// got
export function sendMenuLoading(
  path: string,
  loading: boolean = true
): SendMenuLoading {
  return {
    type: c.SEND_MENU_EDITOR_LOADING,
    payload: { loading, path },
  };
}
//got
export function sendMenuEditorMenuActive(
  path: string,
  id: string
): SendMenuEditorMenuActive {
  return {
    type: c.SEND_MENU_EDITOR_MENU_ACTIVE,
    payload: { id, path },
  };
}
//got
export function sendMenuEditorMenuActiveAll(
  path: string,
  isActive: boolean
): SendMenuEditorMenuActiveAll {
  return {
    type: c.SEND_MENU_EDITOR_MENU_ACTIVE_ALL,
    payload: { isActive, path },
  };
}
//got
export function sendMenuEditorMenuToggled(
  toggled: boolean,
  id: string,
  path: string
): SendMenuEditorMenuToggle {
  return {
    type: c.SEND_MENU_EDITOR_MENU_TOGGLE,
    payload: { toggled, id, path },
  };
}
//got
export function sendMenuEditorMenu(
  menu: MenuData,
  path: string
): SendMenuEditorMenu {
  return {
    type: c.SEND_MENU_EDITOR_MENU,
    payload: { menu, path },
  };
}
//got
export function sendMenuEditorEditingMenu(
  id: string
): SendMenuEditorEditingMenu {
  return {
    type: c.SEND_MENU_EDITOR_EDITING_MENU,
    payload: id,
  };
}
//got
export function sendMenuEditorSaveMenu(menu: MenuItem): SendMenuEditorSaveMenu {
  return {
    type: c.SEND_MENU_EDITOR_SAVE_MENU,
    payload: {
      menu,
    },
  };
}
//got
export function sendMenuEditorSaveMenuItem(
  menu: MenuItem,
  path: string
): SendMenuEditorSaveMenuItem {
  return {
    type: c.SEND_MENU_EDITOR_SAVE_MENU_ITEM,
    payload: {
      menu,
      path,
    },
  };
}
//got
export function sendMenuEditorRemoveItem(
  id: string,
  path: string,
  parentId?: string
): SendMenuEditorRemoveItem {
  return {
    type: c.SEND_MENU_EDITOR_REMOVE_ITEM,
    payload: {
      id,
      path,
      parentId,
    },
  };
}
//got
export function sendMenuEditorRemoveMenu(): SendMenuEditorRemoveMenu {
  return {
    type: c.SEND_MENU_EDITOR_REMOVE_MENU,
    payload: null,
  };
}
//got
export function sendMenuEditorCtxMenuList(
  id: CtxMenuId,
  nodeId?: string
): SendMenuEditorCtxMenuList {
  return {
    type: c.SEND_MENU_EDITOR_CTX_MENU_LIST,
    payload: { id, nodeId },
  };
}
//got
export function sendMenuEditorCtxMenuEditor(
  path: string,
  id: CtxMenuId,
  nodeId?: string
): SendMenuEditorCtxMenuEditor {
  return {
    type: c.SEND_MENU_EDITOR_CTX_MENU_EDITOR,
    payload: { ctxMenu: { id, nodeId }, path },
  };
}
//got
export function sendMenuEditorClearMenu(): SendMenuEditorClearMenu {
  return {
    type: c.SEND_MENU_EDITOR_CLEAR_MENU,
    payload: null,
  };
}
//got
export function sendMenuPathActive(path: string): SendMenuPathActive {
  return {
    type: c.SEND_MENU_EDITOR_PATH_ACTIVE,
    payload: path,
  };
}
//got
export function sendMenuEditorCutItem(
  id: string,
  newParentId: string | null,
  menu: MenuData
): SendMenuEditorCutItem {
  return {
    type: c.SEND_MENU_EDITOR_CUT_ITEM,
    payload: {
      id,
      newParentId,
      menu,
    },
  };
}
//got
export function sendMenuEditorCopyItem(
  id: string,
  newParentId: string | null,
  menu: MenuData
): SendMenuEditorCopyItem {
  return {
    type: c.SEND_MENU_EDITOR_COPY_ITEM,
    payload: {
      id,
      newParentId,
      menu,
    },
  };
}
//got
export function sendMenuEditorOperation(
  path: string,
  op?: c.MenuOperations
): SendMenuEditorOperation {
  return {
    type: c.SEND_MENU_EDITOR_OPERATION,
    payload: { operation: op, path },
  };
}
//got
export function sendMenuEditorFilteredFoldersSearch(
  search: string
): SendMenuEditorFilteredFoldersSearch {
  return {
    type: c.SEND_MENU_EDITOR_FILTER_FOLDERS,
    payload: search,
  };
}
//got
export function sendMenuEditorAclList(
  path: string,
  aclList: EnumerationInfo
): SendMenuEditorAclList {
  return {
    type: c.SEND_MENU_EDITOR_ACL_LIST,
    payload: { aclMap: aclList, path },
  };
}
//got
export function sendMenuEditorItemSourceCode(
  itemId: string,
  code: string
): SendMenuEditorItemSourceCode {
  return {
    type: c.SEND_MENU_EDITOR_ITEM_SOURCE_CODE,
    payload: { itemId, code },
  };
}
//got
export function sendMenuEditorItemSourceCodeLoading(
  loading: boolean = true
): SendMenuEditorItemSourceCodeLoading {
  return {
    type: c.SEND_MENU_EDITOR_ITEM_SOURCE_CODE_LOADING,
    payload: loading,
  };
}

//got
export function fetchMenu(
  path: string,
  forceUpdate?: boolean
): ThunkAction<void, ApplicationState, {}, MenuEditorAction> {
  return async (dispatch, getState) => {
    try {
      const state = getState().menueditor;
      const existedMenu = state && state.editorState[path];
      dispatch(sendMenuLoading(path));
      if (isAclFetchNeeded(path, state)) {
        loadEnumeration(path, dispatch);
      }
      let menu = await fetchMenuImpl(path);
      dispatch(sendMenuEditorMenu(menu, path));
    } catch (e) {
      dispatchError("MENU_EDITOR_FETCH_MENU_ERROR", e, dispatch);
      dispatch(sendMenuLoading(path, false));
      dispatch(sendMenuEditorClearMenu());
    }
  };
}
//got
export function fetchMoveMenuItem(
  path: string,
  id: string,
  newParentId: string | null,
  copy: boolean = false
): ThunkAction<void, ApplicationState, {}, MenuEditorAction> {
  return async (dispatch, getState) => {
    try {
      dispatch(sendMenuLoading(path));
      await fetchMoveMenuItemImpl(path, id, newParentId, copy);

      dispatch(fetchMenu(path, true));
    } catch (e) {
      dispatchError("MENU_EDITOR_MOVE_MENU_ERROR", e, dispatch);
      dispatch(sendMenuLoading(path, false));
    }
  };
}
//got
export function fetchMoveMenuItems(
  path: string,
  newParentId: string | null,
  copy: boolean = false
): ThunkAction<void, ApplicationState, {}, MenuEditorAction> {
  return async (dispatch, getState) => {
    try {
      const s = getState().menueditor;
      const active =
        s && s.editorState && s.editorState[path] && s.editorState[path].active;
      if (!active || !Object.values(active).includes(true)) {
        return;
      }

      const movedItems: MenuItem[] = [];
      Object.entries(active).forEach((e) => {
        const [id, isActive] = e;
        if (isActive) {
          movedItems.push({ id, parentId: newParentId || undefined });
        }
      });
      dispatch(sendMenuLoading(path));
      await fetchMoveSelectedItemsImpl(path, movedItems, copy);

      dispatch(fetchMenu(path, true));
    } catch (e) {
      dispatchError("MENU_EDITOR_MOVE_MENU_ERROR", e, dispatch);
      dispatch(sendMenuLoading(path, false));
    }
  };
}
//got
export function fetchSaveMenuItem(
  menu: MenuItem,
  path: string
): ThunkAction<void, ApplicationState, {}, MenuEditorAction> {
  return async (dispatch, getState) => {
    try {
      dispatch(sendMenuLoading(path));
      await fetchSaveItemImpl(menu, path);
      dispatch(fetchMenu(path, true));
    } catch (e) {
      dispatchError("MENU_EDITOR_SAVE_MENU_ITEM_ERROR", e, dispatch);
      dispatch(sendMenuLoading(path, false));
    }
  };
}
//got
export function fetchDeleteItem(
  menu: MenuItem,
  path: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    const me = getState().menueditor;
    const active =
      me &&
      me.editorState &&
      me.editorState[path] &&
      me.editorState[path].active;
    const options = {
      title: { id: "MSG_CONFIRM_ACTION" },
      body: { id: "NAVTREE_DELETE_CONFIRM", values: { node: path } },
    };
    dispatch(
      openModal(
        "tree.confirmDelete",
        "confirmDelete",
        options,
        async (result) => {
          if (!result) {
            return;
          }
          try {
            const { id } = menu;
            if (!id) {
              return;
            }
            dispatch(sendMenuLoading(path));
            await fetchRemoveItemImpl(menu, path);
            dispatch(fetchMenu(path, true));
            active &&
              active[id] &&
              dispatch(sendMenuEditorMenuActive(path, id));
          } catch (e) {
            dispatchError("MENU_EDITOR_REMOVE_MENU_ITEM_ERROR", e, dispatch);
            dispatch(sendMenuLoading(path, false));
          }
        }
      )
    );
  };
}
//got
export function fetchDeleteItems(
  path: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    const s = getState().menueditor;
    const es = s && s.editorState[path];
    const active = es && es.active;
    const nodesById = es && es.itemById;
    const items: MenuItem[] = [];
    if (!active || !nodesById) {
      return;
    }
    Object.entries(active).forEach((e) => {
      const [id, isActive] = e;
      const node = nodesById[id];
      if (isActive && node) {
        const { id, parentId } = node;
        items.push(node);
      }
    });
    const options = {
      title: { id: "MSG_CONFIRM_ACTION" },
      // body: { id: "NAVTREE_DELETE_CONFIRM", values: { node: node.name } }
      body: { id: "NAVTREE_DELETE_CONFIRM", values: { node: "" } },
    };
    dispatch(
      openModal(
        "tree.confirmDelete",
        "confirmDelete",
        options,
        async (result) => {
          if (!result) {
            return;
          }
          try {
            dispatch(sendMenuLoading(path));
            await fetchRemoveItemAllImpl(items, path);
            items.forEach((i) => {
              const { id, parentId } = i;
              if (id) {
                dispatch(sendMenuEditorRemoveItem(id, path, parentId));
              }
            });
            dispatch(sendMenuEditorMenuActiveAll(path, false));
            dispatch(sendMenuEditorOperation(path));
            dispatch(fetchMenu(path, true));
          } catch (e) {
            dispatchError("MENU_EDITOR_REMOVE_MENU_ITEM_ERROR", e, dispatch);
            dispatch(sendMenuLoading(path, false));
          }
        }
      )
    );
  };
}
//got
export function createMenuItem(
  path: string,
  nodeId?: string
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const mes = getState().menueditor;

    let parentItem: MenuItem | undefined =
      (nodeId &&
        mes &&
        mes.editorState[path] &&
        mes.editorState[path].itemById[nodeId]) ||
      undefined;

    dispatch(
      openModal(
        "menueditor.info",
        "menueditorDisplayInfo",
        { title: { id: "MENU_EDITOR_INFO_MODAL_TITLE_ITEM_CREATE" } },
        async (result: any | null) => {
          if (result !== null) {
            const { item } = result;
            const newItem = item as MenuItem;
            newItem.parentId = parentItem && parentItem.id;
            dispatch(fetchSaveMenuItem(newItem, path));
          }
        }
      )
    );
  };
}
//got
export function updateMenuItem(
  path: string,
  nodeId: string
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const mes = getState().menueditor;

    let oldItem: MenuItem | undefined =
      (mes &&
        mes.editorState &&
        mes.editorState[path] &&
        mes.editorState[path].itemById[nodeId]) ||
      undefined;
    if (!oldItem) {
      return;
    }
    const body = JSON.stringify({ item: oldItem });

    dispatch(
      openModal(
        "menueditor.info",
        "menueditorDisplayInfo",
        { body, title: { id: "MENU_EDITOR_INFO_MODAL_TITLE_ITEM_EDIT" } },
        async (result: any | null) => {
          if (result) {
            const { item } = result;
            const newItem = { ...oldItem, ...item };
            dispatch(fetchSaveMenuItem(newItem, path));
          }
        }
      )
    );
  };
}
//got
function createViewPath(
  ctx: string,
  path: string,
  codePosition: CodePosition | null
) {
  let url = `${ctx}developer/vieweditor${path}`;
  if (codePosition) {
    const { column, line } = codePosition;
    url += `?line=${line}&col=${0}`;
  }
  return url;
}
//got
export function goToView(
  path: string,
  id?: string
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const s = getState().location;
    const ctx = s.contextPath || "/";
    try {
      let codePosition = null;
      if (id) {
        codePosition = await fetchMenuItemPositionImpl(path, id);
      }

      let a = document.createElement("a");
      a.href = createViewPath(ctx, path, codePosition);
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      dispatchError("MENU_EDITOR_GET_POSITION_ERROR", e, dispatch);
    }
  };
}
//got
export function changeOrder(
  path: string,
  itemId: string,
  anchorItemId: string,
  isBefore: boolean
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    try {
      const se = getState().menueditor;
      if (
        !se ||
        !se.editorState ||
        !se.editorState[path] ||
        itemId === anchorItemId
      ) {
        return;
      }

      dispatch(sendMenuLoading(path));
      const newParentId =
        se.editorState[path].itemById[anchorItemId].parentId || null;
      await fetchChangeMenuItemOrderImpl(
        path,
        itemId,
        anchorItemId,
        newParentId,
        isBefore
      );
      dispatch(fetchMenu(path, true));
    } catch (e) {
      dispatch(sendMenuLoading(path, false));
      dispatchError("MENU_EDITOR_FETCH_ITEM_SOURCE_ERROR", e, dispatch);
    }
  };
}
//got
function isAclFetchNeeded(path: string, state?: MenuEditorState) {
  if (!state || !state.editorState) {
    return true;
  }
  return state.editorState[path] && !state.editorState[path].aclMap;
}
//got
async function loadEnumeration(
  path: string,
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>
) {
  const className = "npt:GeneralAccessRule";
  try {
    const aclList = await fetchEnumeration(className);
    dispatch(sendMenuEditorAclList(path, parseEnumeration(aclList)));
  } catch (e) {
    dispatchError("OBJECTCARD_LOADING_ENUMERATIONS_FAILD", e, dispatch);
  }
}
