import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_OK } from '../../../constants/modal';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import ModalView from '../ModalView';

interface ShowSaveChangesModalProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void
}

export default class ShowSaveChangesModal extends React.Component<ShowSaveChangesModalProps> {
    private subjectKey?:string;
    private name?:string;
    private title: any = { id: "OBJECTCARD_SAVE_CHANGES_TITLE" };
    constructor(props: ShowSaveChangesModalProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this); 

        const body = this.props.modal.options.body;  
         if(body && typeof body ==='string'){
            const saveData = JSON.parse(body);
            this.subjectKey = saveData.subjectKey;
            this.name = saveData.name;
         } 

         
    }
    renderTemplate(): React.ReactElement {
          if(!this.name){
              return <></>;
          }
        return ( <div>
             <FormattedMessage id="OBJECTCARD_SAVE_CHANGES_BODY" values={{name:this.name}} />   
        </div>
        );
    }


    closeModal(status: ModalStatus, result: any) {
        if (status == MODAL_STATUS_OK && this.subjectKey) {
            this.props.closeModal(status, true);
        } else if (status == MODAL_STATUS_CANCEL  ) {
            this.props.closeModal(status, false);
        }
        else{
            this.props.closeModal(status, null);  
        }
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}