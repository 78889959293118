import { Reducer } from "redux";
import moment from "moment";

import * as constants from "../constants/table";

import {
  TableState,
  TableAction,
  SendTableError,
  SendTableLoading,
  TableData,
  TableReducerState,
  TableHeader,
  SendTableHeader,
  SendTableData,
  SendTableDataLoading,
  SendTableDataError,
  SendSelectRow,
  SendSelectAll,
  AutomationBindings,
  AutomationBindingFunctions,
  RowData,
  SendPageSize,
  SendPage,
  SendSortKey,
  SendFilterData,
  FilterData,
  SendLocalTableInitialData,
  SendLocalTableUpdateData,
  SendLocalTableUninitialize,
  InitialLocalTableData,
  LocalTableData,
  GantDisplayScale,
  GantHeaderCell,
  TableGantOptions,
  SendGantViewType,
  GantViewType,
  SendGantSelectedGroup,
  SendGantChangeElement,
  GantDateLimits,
  SendTableFilterSelectionLoading,
  SendTableFilterSelectionError,
  SendTableFilterSelection,
  SendTableSortOptions,
  ColumnSortInfo,
  SendTableFilterRangeLoading,
  SendTableFilterRangeError,
  SendTableFilterRange,
  SendTableColumns,
  SendTableSaveStart,
  SendTableSaveSuccess,
  SendTableSaveError,
  SendTableInitialData,
  TableFilterChanges,
  SendField,
  SendFilterChangesConfirm,
  SendFilterChangesDeny,
  SendTableRowAdd,
  RowDataMap,
  SendTableRowChange,
  SendTableCellChange,
  SendTableClientSideFilter,
  isStringFilterData,
  isNumberFilterData,
  NumberFilterData,
  isDateFilterData,
  isDateTimeFilterData,
  DateFilterData,
  DateTimeFilterData,
  isBooleanFilterData,
  isToolbarGroup,
  ToolbarItem,
  ToolbarGroup,
  AutomationTableModule,
} from "../types/table";
import { FetchError } from "../types/error";
import {
  createGantGroup,
  generateTableBindings,
  getGantGroupMap,
  getRowAutomationData,
  getTableRows,
} from "../services/table";
import { retrieveFunction, scriptCompiler } from "../services/automation";
import {
  addGantDateCell,
  addTime,
  checkGantDateValue,
  getMinScaleValue,
  getStandartDisplayedScales,
  getValueByScale,
  parseGantDate,
  replaceFieldPlaceholders,
  roundGantTimestamp,
} from "../services/gant";
import { ThemeService } from "../services/theme";

const DEFAULT_STATE: TableReducerState = {};

export const DEFAULT_TABLE_STATE: TableState = {
  tableId: "default",
  loading: false,
  loadingData: false,
  loadingPages: {},
  error: null,
  errorData: null,
  saving: false,
  savingError: null,
  stylesheets: {},
  parameters: {},
  fields: {},
  pageRows: [],
  selectedRowsLength: 0,
  selectedRows: {},
  toolbar: [],
  reports: [],
  columns: [],
  dynamicColumns: null,
  dynamicHiddenColumns: {},
  rowByIdx: {},
  totalRowsLength: 0,
  pageSize: -1,
  pageSelection: [],
  page: 0,
  pageable: false,
  selectType: null,
  automation: {
    cellClassBindings: null,
    valueBindings: null,
    textBindings: null,
    visibilityBindings: null,
    visibilityToolbarBindings: null,
    accumBindings: null,
    accumFilterBindings: null,
    accumInitialValues: null,
    clickBindings: null,
    postProcessBinding: null,
  },
  sortInfo: {
    columns: [],
  },
  filterInfo: {
    columns: [],
    selectionByField: {},
    rangeByField: {},
  },
  filterChanges: null,
  gantOptions: null,
  gantData: {
    dateLimits: {
      from: -1,
      to: -1,
    },
    displayScales: [],
    scale: "year",
    cellsByScale: {},
    gantMinWidth: 0,
    viewType: "read",
    selectedGroup: "",
  },
  dragOptions: null,
  dropOptions: null,
  clientSideFilter: false,
  clientSideData: null,
};

/*********************
 * Utility funcitons *
 *********************/

/**Parse user script to get binding functions */
function parseAutomation(script: string | null): AutomationBindings {
  const parsedAutomation: AutomationBindings = Object.assign(
    {},
    DEFAULT_TABLE_STATE.automation
  );
  if (!script) {
    return parsedAutomation;
  }
  const bindings: AutomationBindingFunctions =
    generateTableBindings(parsedAutomation);
  scriptCompiler(script, bindings);
  return parsedAutomation;
}

/**Apply automations for page rows */
function applyAutomations(
  tableState: TableState,
  rows: RowData[],
  automationTableModule?: AutomationTableModule
) {
  applyValueBindings(tableState, rows);
  applyClassBindings(tableState, rows);
  applyVisibilityAutomations(tableState, rows);
  applyVisibilityToolbarAutomations(tableState, rows);
  runAutomationAccumulators(tableState, rows);
  applyTextBindings(tableState, rows, automationTableModule);
}

function applyValueBindings(tableState: TableState, rows: RowData[]) {
  if (!tableState.automation.valueBindings) {
    return;
  }
  applyBindings(tableState, rows, tableState.automation.valueBindings, true);
}

function applyTextBindings(
  tableState: TableState,
  rows: RowData[],
  automationTableModule?: AutomationTableModule
) {
  if (!tableState.automation.textBindings) {
    return;
  }
  applyBindings(
    tableState,
    rows,
    tableState.automation.textBindings,
    false,
    automationTableModule
  );
}

function applyClassBindings(tableState: TableState, rows: RowData[]) {
  if (!tableState.automation.cellClassBindings) {
    return;
  }
  for (let rowIndex = 0; rowIndex < rows.length; ++rowIndex) {
    let row = rows[rowIndex];
    if (!row) {
      continue;
    }
    for (
      let columnIndex = 0;
      columnIndex < tableState.columns.length;
      ++columnIndex
    ) {
      let field = tableState.columns[columnIndex].field;
      let bindings = tableState.automation.cellClassBindings[field];
      if (!bindings) {
        continue;
      }
      let columnClassNameFunc = retrieveFunction(bindings);
      if (!columnClassNameFunc) {
        continue;
      }
      const currentTheme = ThemeService.getTheme();
      const rowData = getRowAutomationData(row);
      row.classes[field] = columnClassNameFunc(
        rowData[field],
        rowData,
        rowIndex,
        columnIndex,
        currentTheme.platformTheme
      );
    }
  }
}

function applyVisibilityAutomations(tableState: TableState, rows: RowData[]) {
  if (!tableState.automation.visibilityBindings || !tableState.columns) {
    return;
  }
  tableState.columns = tableState.columns.slice();
  for (let i = 0; i < tableState.columns.length; ++i) {
    const column = tableState.columns[i];
    const bindings = tableState.automation.visibilityBindings[column.field];
    if (!bindings) {
      continue;
    }
    const columnVisibilityFunc = retrieveFunction(bindings);
    if (!columnVisibilityFunc) {
      continue;
    }
    const rowDataList = rows.map((row) => getRowAutomationData(row));
    tableState.columns[i] = {
      ...tableState.columns[i],
      hidden: !columnVisibilityFunc(rowDataList, tableState.fields),
    };
  }
}

function applyVisibilityToolbarAutomations(
  tableState: TableState,
  rows: RowData[]
) {
  if (!tableState.automation.visibilityToolbarBindings || !tableState.toolbar) {
    return;
  }
  const visibilityToolbarBindings =
    tableState.automation.visibilityToolbarBindings;
  const rowDataList = rows.map((row) => getRowAutomationData(row));

  const getBindingFunction = (id: string) => {
    const bindings = visibilityToolbarBindings[id];
    if (!bindings) {
      return null;
    }
    const itemVisibilityFunc = retrieveFunction(bindings);
    if (!itemVisibilityFunc) {
      return null;
    }
    return itemVisibilityFunc;
  };

  const checkItemsVisibility = (items: ToolbarItem[]) => {
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      if (isToolbarGroup(item)) {
        items[i] = { ...item };
        (items[i] as ToolbarGroup).items = item.items.slice();
        checkItemsVisibility((items[i] as ToolbarGroup).items);
      }
      const itemVisibilityFunc = getBindingFunction(item.id);
      if (!itemVisibilityFunc) {
        continue;
      }
      items[i] = {
        ...items[i],
        hidden: !itemVisibilityFunc(rowDataList, tableState.fields),
      };
    }
  };

  tableState.toolbar = tableState.toolbar.slice();
  checkItemsVisibility(tableState.toolbar);
}

function applyBindings(
  tableState: TableState,
  rows: RowData[],
  bindings: { [col: string]: string },
  ignoreBindings: boolean,
  automationTableModule?: AutomationTableModule
) {
  for (let rowIndex = 0; rowIndex < rows.length; ++rowIndex) {
    let row = rows[rowIndex];
    if (!row) {
      continue;
    }
    for (
      let columnIndex = 0;
      columnIndex < tableState.columns.length;
      ++columnIndex
    ) {
      let columnField = tableState.columns[columnIndex].field;
      let columnBindings = bindings[columnField];
      if (!columnBindings) {
        continue;
      }
      let bindingsFunc = retrieveFunction(columnBindings);
      if (!bindingsFunc) {
        continue;
      }
      const rowData = ignoreBindings ? row.data : getRowAutomationData(row);
      row.bindedData[columnField] = bindingsFunc(
        rowData[columnField],
        rowData,
        rowIndex,
        columnIndex,
        automationTableModule
      );
    }
  }
}

function runAutomationAccumulators(tableState: TableState, rows: RowData[]) {
  if (!tableState.automation) {
    return;
  }
  if (tableState.automation.accumFilterBindings) {
    tableState.fields = Object.assign({}, tableState.fields);
    runAccumulators(
      rows,
      tableState.automation.accumInitialValues,
      tableState.fields,
      tableState.automation.accumFilterBindings
    );
  }
  //Postprocess
  if (tableState.automation.postProcessBinding) {
    const func = retrieveFunction(tableState.automation.postProcessBinding);
    const rowDataList = rows.map((row) => getRowAutomationData(row));
    func(tableState.fields, rowDataList);
  }
}

function runAccumulators(
  rows: RowData[],
  initialValues: { [accum: string]: string } | null = {},
  fields: { [k: string]: string },
  bindings: { [accum: string]: string }
) {
  if (!initialValues) {
    initialValues = {};
  }
  //Initialize accumulator values
  for (let accum in bindings) {
    fields[accum] = initialValues[accum];
  }
  //Process data
  for (let row of rows) {
    if (!row) {
      continue;
    }
    const rowData = getRowAutomationData(row);
    for (let accum in bindings) {
      let func = retrieveFunction(bindings[accum]);
      let prev = fields[accum];
      fields[accum] = func(rowData, prev);
    }
  }
}

function resetTableData(tableState: TableState, savePage?: boolean) {
  tableState.rowByIdx = { ...DEFAULT_TABLE_STATE.rowByIdx };
  tableState.pageRows = DEFAULT_TABLE_STATE.pageRows.slice();
  tableState.loadingData = DEFAULT_TABLE_STATE.loadingData;
  tableState.loadingPages = { ...DEFAULT_TABLE_STATE.loadingPages };
  tableState.totalRowsLength = DEFAULT_TABLE_STATE.totalRowsLength;
  if (!savePage) {
    changePage(tableState, 0);
  }
}

function resetGantData(tableState: TableState) {
  if (tableState.gantOptions === null) {
    return;
  }
  tableState.gantData = { ...tableState.gantData };
  tableState.gantData.dateLimits = {
    from: -1,
    to: -1,
  };
}

function changePage(
  tableState: TableState,
  page: number,
  automationTableModule?: AutomationTableModule
) {
  tableState.page = page;
  updatePageData(tableState, automationTableModule);
}

function updatePageData(
  tableState: TableState,
  automationTableModule?: AutomationTableModule
) {
  let rowStartIdx = 0;
  if (tableState.pageSize !== -1) {
    rowStartIdx += tableState.pageSize * tableState.page;
  }

  let rowEndIdx = tableState.totalRowsLength;
  if (tableState.pageSize !== -1) {
    rowEndIdx =
      rowStartIdx + tableState.pageSize < tableState.totalRowsLength
        ? rowStartIdx + tableState.pageSize
        : tableState.totalRowsLength;
  }
  tableState.pageRows = [];
  if (!tableState.clientSideFilter || !tableState.clientSideData) {
    for (let i = rowStartIdx; i < rowEndIdx; ++i) {
      tableState.pageRows.push(i);
    }
  } else {
    for (let i = rowStartIdx; i < rowEndIdx; ++i) {
      tableState.pageRows.push(tableState.clientSideData.sortedRows[i]);
    }
  }

  const rows = getTableRows(tableState);
  applyAutomations(tableState, rows, automationTableModule);
}

/**Try to get limit value by parsing table data */
function calculateLimitGantDate(
  tableState: TableState,
  field: string,
  optionsLimit: number,
  isLarger: boolean
): number {
  let limit = optionsLimit;
  for (let index of tableState.pageRows) {
    const row = tableState.rowByIdx[index];
    const gantData =
      row.changedData.gantData ||
      row.bindedData.gantData ||
      row.data.gantData ||
      null;
    if (gantData === null) {
      continue;
    }
    let items: any[] = [];
    if (Array.isArray(gantData.chosenDate)) {
      items = items.concat(gantData.chosenDate);
    }
    if (Array.isArray(gantData.plannedDate)) {
      items = items.concat(gantData.plannedDate);
    }
    for (let item of items) {
      if (!item || typeof item !== "object") {
        continue;
      }
      let value = item[field];
      if (typeof value === "string") {
        value = moment(value).valueOf();
      }
      if (typeof value !== "number" || isNaN(value)) {
        continue;
      }
      if (limit === -1) {
        limit = value;
        continue;
      }
      limit = isLarger ? Math.max(limit, value) : Math.min(limit, value);
    }
  }
  return limit;
}

/**Set new date limits based on gant options, fields and table data (returns true if date was changed) */
function updateGantDateLimits(tableState: TableState, reset?: boolean) {
  if (tableState.gantOptions === null) {
    return;
  }

  tableState.gantData = { ...tableState.gantData };
  /**On reset setup undefined date limits */
  if (reset) {
    tableState.gantData.dateLimits = { from: -1, to: -1 };
    return;
  }
  tableState.gantData.dateLimits = { ...tableState.gantData.dateLimits };

  /**Get string values of date limits from options */
  const options = {
    from: replaceFieldPlaceholders(
      tableState.gantOptions.dateLimits.from,
      tableState.fields
    ),
    to: replaceFieldPlaceholders(
      tableState.gantOptions.dateLimits.to,
      tableState.fields
    ),
    maxFrom: replaceFieldPlaceholders(
      tableState.gantOptions.dateLimits.maxFrom,
      tableState.fields
    ),
    minTo: replaceFieldPlaceholders(
      tableState.gantOptions.dateLimits.minTo,
      tableState.fields
    ),
  };

  /**Calculate actual date limits */
  let from = parseGantDate(options.from);
  let to = parseGantDate(options.to);
  /**If value was not already defined, than we need to calculate value from data */
  if (from === -1) {
    let maxFrom = parseGantDate(options.maxFrom);
    from = calculateLimitGantDate(tableState, "from", maxFrom, false);
  }
  if (to === -1) {
    let minTo = parseGantDate(options.minTo);
    to = calculateLimitGantDate(tableState, "to", minTo, true);
  }
  /**Check if limits is still undefined and set current date as value */
  if (from === -1) {
    from = to !== -1 ? to : Date.now();
  }
  if (to === -1) {
    /**At this moment 'from' value is defined */
    to = from;
  }
  const dateLimits = {
    from: roundGantTimestamp(from, tableState.gantOptions.scale, "floor"),
    to: roundGantTimestamp(to, tableState.gantOptions.scale, "ceil"),
  };
  if (tableState.gantOptions.fullWeeks) {
    dateLimits.from = roundGantTimestamp(dateLimits.from, "week", "floor");
    dateLimits.to = roundGantTimestamp(dateLimits.to, "week", "ceil");
  }
  let changed = false;
  /**Date limits can only grow */
  if (
    tableState.gantData.dateLimits.from === -1 ||
    tableState.gantData.dateLimits.from > dateLimits.from
  ) {
    tableState.gantData.dateLimits.from = dateLimits.from;
    changed = true;
  }
  if (
    tableState.gantData.dateLimits.to === -1 ||
    tableState.gantData.dateLimits.to < dateLimits.to
  ) {
    tableState.gantData.dateLimits.to = dateLimits.to;
    changed = true;
  }
  if (changed) {
    console.log(
      "Update date limits:",
      new Date(tableState.gantData.dateLimits.from),
      new Date(tableState.gantData.dateLimits.to)
    );
  }
  return changed;
}

function updateDisplayedScales(tableState: TableState) {
  if (tableState.gantOptions === null) {
    return;
  }
  tableState.gantData = { ...tableState.gantData };
  tableState.gantData.scale = tableState.gantOptions.scale;
  tableState.gantData.displayScales = getStandartDisplayedScales(
    tableState.gantOptions.scale
  );
  /**TODO: check options for defined displayed scales */
}

/**Find column with minimal width and calculate gant min width based on it */
function updateGantMinimumWidth(tableState: TableState) {
  tableState.gantData = { ...tableState.gantData };

  let columnMinCoef = 1;
  for (let scale of tableState.gantData.displayScales) {
    const cells = tableState.gantData.cellsByScale[scale];
    if (!cells) {
      continue;
    }
    for (let cell of cells) {
      if (cell.width < columnMinCoef) {
        columnMinCoef = cell.width;
      }
    }
  }
  /**TODO: use reducer column min width */
  const minCellWidth = tableState.gantOptions?.minCellWidth || 20;
  tableState.gantData.gantMinWidth = minCellWidth / columnMinCoef;
}

/**Create scale cells for every scale inside of date limits */
function updateGantDateCells(tableState: TableState) {
  if (tableState.gantOptions === null) {
    return;
  }

  tableState.gantData = { ...tableState.gantData };
  tableState.gantData.cellsByScale = {};

  const cells = {
    hour: [],
    day: [],
    week: [],
    month: [],
    year: [],
  };
  const currentWidth = {
    hour: 0,
    day: 0,
    week: 0,
    month: 0,
    year: 0,
  };
  const initialDate = new Date(tableState.gantData.dateLimits.from);
  const endDate = new Date(tableState.gantData.dateLimits.to);
  initialDate.setTime(
    initialDate.getTime() + initialDate.getTimezoneOffset() * 60 * 1000
  );
  endDate.setTime(endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000);
  const values = {
    hour: getValueByScale(initialDate, "hour"),
    day: getValueByScale(initialDate, "day"),
    week: checkGantDateValue("week", initialDate, null),
    month: getValueByScale(initialDate, "month"),
    year: getValueByScale(initialDate, "year"),
  };
  const step: number =
    tableState.gantOptions.scale === "hour"
      ? getMinScaleValue("hour")
      : getMinScaleValue("day");
  let totalCells = 0;

  const makeStep = () => {
    addTime(initialDate, step);
    ++totalCells;
    for (let scale of tableState.gantData.displayScales) {
      ++currentWidth[scale];
      let newValue = checkGantDateValue(scale, initialDate, values[scale]);
      if (newValue === null) {
        continue;
      }
      addGantDateCell(scale, cells[scale], values[scale], currentWidth[scale]);
      values[scale] = newValue;
      currentWidth[scale] = 0;
    }
  };

  /**Add all full cells */
  while (initialDate.getTime() < endDate.getTime()) {
    makeStep();
  }
  /**Check if last cell is not full and add it */
  for (let scale of tableState.gantData.displayScales) {
    if (currentWidth[scale] !== 0) {
      addGantDateCell(
        scale as GantDisplayScale,
        cells[scale],
        getValueByScale(initialDate, scale),
        currentWidth[scale]
      );
    }
  }
  /**Setup widths of cells */
  for (let scale of tableState.gantData.displayScales) {
    for (let cell of cells[scale]) {
      const headerCell: GantHeaderCell = cell as GantHeaderCell;
      headerCell.width = headerCell.cellspan / totalCells;
    }
  }
  for (let scale of tableState.gantData.displayScales) {
    tableState.gantData.cellsByScale[scale] = cells[scale];
  }
  updateGantMinimumWidth(tableState);
}

/**Check page gant data and create dynamic gant groups for all data with unidentified group */
function updateGantGroups(tableState: TableState) {
  if (tableState.gantOptions === null) {
    return;
  }
  const groupsMap = getGantGroupMap(tableState.gantOptions);
  let isChanged = false;
  const getUnidentifiedGroup = () => {
    if (!groupsMap["_unidentified"]) {
      /**Initialize default unidentified group */
      groupsMap["_unidentified"] = createGantGroup("_unidentified", "?");
      isChanged = true;
    }
    return groupsMap["_unidentified"];
  };
  for (let rowIdx of tableState.pageRows) {
    const row = tableState.rowByIdx[rowIdx];
    const gantData =
      row &&
      (row.changedData.gantData ||
        row.bindedData.gantData ||
        row.data.gantData);
    if (!gantData || !Array.isArray(gantData.chosenDate)) {
      continue;
    }
    for (let date of gantData.chosenDate) {
      if (!Array.isArray(date.group)) {
        if (groupsMap[date.group]) {
          continue;
        }
        isChanged = true;
        console.log("Gant group not found:", date.group, date, row);
        date.group = [getUnidentifiedGroup()];
        continue;
      }
      if (date.group.length === 0) {
        date.group = [getUnidentifiedGroup()];
        continue;
      }
      for (let i = 0; i < date.group.length; ++i) {
        if (groupsMap[date.group[i]]) {
          continue;
        }
        isChanged = true;
        console.log("Gant group not found:", date.group, date, row);
        date.group[i] = getUnidentifiedGroup();
      }
    }
  }
  if (isChanged) {
    tableState.gantOptions = { ...tableState.gantOptions };
    tableState.gantOptions.gantGroups = [];
    for (let id in groupsMap) {
      tableState.gantOptions.gantGroups.push(groupsMap[id]);
    }
  }
}

function updateGantData(tableState: TableState, reset?: boolean) {
  if (tableState.gantOptions === null) {
    return;
  }
  updateGantGroups(tableState);
  const limitsChanged = updateGantDateLimits(tableState, reset);
  if (limitsChanged) {
    updateGantDateCells(tableState);
  }
}

function updateColumnsResizeInfo(tableState: TableState) {
  const generatedColsIncrement = tableState.selectType != null ? 1 : 0;
  tableState.columns = tableState.columns.slice();
  let columnInfoList = [];
  if (tableState.dynamicColumns) {
    const columnIdxMap: { [k: string]: number } = {};
    const visibilityMap: { [k: string]: boolean } = {};
    for (let i = 0; i < tableState.dynamicColumns.length; ++i) {
      const columnField = tableState.dynamicColumns[i];
      columnIdxMap[columnField] = i;
      visibilityMap[columnField] = true;
    }
    for (let i = 0; i < tableState.columns.length; ++i) {
      if (tableState.columns[i].resizeInfo) {
        tableState.columns[i] = { ...tableState.columns[i] };
        delete tableState.columns[i].resizeInfo;
      }
      if (!visibilityMap[tableState.columns[i].field]) {
        continue;
      }
      columnInfoList.push({
        idx: i,
        column: tableState.columns[i],
      });
    }
    columnInfoList = columnInfoList.sort(
      (infoA, infoB) =>
        columnIdxMap[infoA.column.field] - columnIdxMap[infoB.column.field]
    );
  } else {
    for (let i = 0; i < tableState.columns.length; ++i) {
      if (tableState.columns[i].resizeInfo) {
        tableState.columns[i] = { ...tableState.columns[i] };
        delete tableState.columns[i].resizeInfo;
      }
      if (tableState.columns[i].hidden && tableState.columns[i].dynamic) {
        continue;
      }
      columnInfoList.push({
        idx: i,
        column: tableState.columns[i],
      });
    }
  }
  let prevVisibleColumnPos: number | null = null;
  for (let i = 0; i < columnInfoList.length; ++i) {
    const column = columnInfoList[i].column;
    if (
      (column.hidden && !column.dynamic) ||
      tableState.dynamicHiddenColumns?.[column.field]
    ) {
      continue;
    }
    if (prevVisibleColumnPos === null) {
      prevVisibleColumnPos = i;
      continue;
    }
    /**Index of column in original array */
    const prevColumnIdx = columnInfoList[prevVisibleColumnPos].idx;
    const columnIdx = columnInfoList[i].idx;
    /**Visible position of column */
    const prevColumnPos = prevVisibleColumnPos + generatedColsIncrement;
    const columnPos = i + generatedColsIncrement;
    tableState.columns[prevColumnIdx] = {
      ...tableState.columns[prevColumnIdx],
    };
    tableState.columns[columnIdx] = { ...tableState.columns[columnIdx] };
    const prevColumn = tableState.columns[prevColumnIdx];
    const curColumn = tableState.columns[columnIdx];
    if (!prevColumn.resizeInfo) {
      prevColumn.resizeInfo = {
        curColumnIdx: prevColumnPos,
      };
    }
    if (!curColumn.resizeInfo) {
      curColumn.resizeInfo = {
        curColumnIdx: columnPos,
      };
    }
    prevColumn.resizeInfo.nextColumnIdx = columnPos;
    curColumn.resizeInfo.prevColumnIdx = prevColumnPos;
    prevVisibleColumnPos = i;
  }
}

function isEmptyObject(obj: any): boolean {
  if (typeof obj != "object") {
    return false;
  }
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

function setPageLoading(
  tableState: TableState,
  page: number,
  pageSize: number
) {
  tableState.loadingPages = { ...tableState.loadingPages };
  tableState.loadingPages[`${page}_${pageSize}`] = true;
  tableState.loadingData = true;
  return;
}

function setPageLoaded(tableState: TableState, page: number, pageSize: number) {
  tableState.loadingPages = { ...tableState.loadingPages };
  delete tableState.loadingPages[`${page}_${pageSize}`];
  if (isEmptyObject(tableState.loadingPages)) {
    tableState.loadingData = false;
  }
  return;
}

function initializeChangedFilter(tableState: TableState): TableFilterChanges {
  let filterChanges: TableFilterChanges;
  if (tableState.filterChanges) {
    filterChanges = { ...tableState.filterChanges };
  } else {
    filterChanges = {
      fields: null,
    };
  }
  if (!filterChanges.fields) {
    filterChanges.fields = { ...tableState.fields };
  }
  return filterChanges;
}

function checkNumberValue(value: number, filter: NumberFilterData): boolean {
  if (filter.operation === "between") {
    if (filter.valueFrom === null || filter.valueTo === null) {
      return true;
    }
    return value >= filter.valueFrom && value <= filter.valueTo;
  }
  if (filter.value === null) {
    return true;
  }
  if (filter.operation === "less") {
    return value < filter.value;
  }
  if (filter.operation === "lessOrEqual") {
    return value <= filter.value;
  }
  if (filter.operation === "equal") {
    return value == filter.value;
  }
  if (filter.operation === "more") {
    return value > filter.value;
  }
  if (filter.operation === "moreOrEqual") {
    return value >= filter.value;
  }
  return true;
}

function checkDateValue(
  value: number,
  filter: DateFilterData | DateTimeFilterData
): boolean {
  if (filter.operation === "between") {
    if (filter.valueFrom === null || filter.valueTo === null) {
      return true;
    }
    const fromValue = moment(filter.valueFrom + "z").valueOf();
    const toValue = moment(filter.valueTo + "z").valueOf();
    return value >= fromValue && value <= toValue;
  }
  if (filter.value === null) {
    return true;
  }
  const filterValue = moment(filter.value).valueOf();
  if (filter.operation === "less") {
    return value < filterValue;
  }
  if (filter.operation === "lessOrEqual") {
    return value <= filterValue;
  }
  if (filter.operation === "equal") {
    return value == filterValue;
  }
  if (filter.operation === "more") {
    return value > filterValue;
  }
  if (filter.operation === "moreOrEqual") {
    return value >= filterValue;
  }
  return true;
}

function clientSideFilterData(tableState: TableState) {
  if (!tableState.clientSideFilter) {
    tableState.clientSideData = null;
    return;
  }
  if (!tableState.clientSideData) {
    tableState.clientSideData = {
      filteredRows: [],
      sortedRows: [],
    };
  } else {
    tableState.clientSideData = { ...tableState.clientSideData };
  }
  tableState.clientSideData.filteredRows = Object.keys(tableState.rowByIdx)
    .map((idx) => Number(idx))
    .filter((rowIdx) => {
      if (tableState.filterInfo.columns.length === 0) {
        return true;
      }
      const row = tableState.rowByIdx[rowIdx];
      for (let columnFilter of tableState.filterInfo.columns) {
        const fieldId = columnFilter.field;
        let rowValue = null;
        if (typeof row.bindedData[fieldId] !== "undefined") {
          rowValue = row.bindedData[fieldId];
        } else if (typeof row.data[fieldId] !== "undefined") {
          rowValue = row.data[fieldId];
        }
        if (isStringFilterData(columnFilter.data)) {
          const checkSelection = Boolean(
            Object.values(columnFilter.data.selected).find(
              (selected) => selected === true
            )
          );
          const stringValue: string = rowValue ? rowValue.toString() : "";
          if (
            (checkSelection && !columnFilter.data.selected[stringValue]) ||
            !stringValue
              .toLocaleLowerCase()
              .includes(columnFilter.data.contain.toLocaleLowerCase())
          ) {
            return false;
          }
          continue;
        }
        if (isNumberFilterData(columnFilter.data)) {
          if (rowValue === null) {
            return false;
          }
          const numberValue: number = Number(rowValue);
          if (
            isNaN(numberValue) ||
            !checkNumberValue(numberValue, columnFilter.data)
          ) {
            return false;
          }
          continue;
        }
        if (
          isDateFilterData(columnFilter.data) ||
          isDateTimeFilterData(columnFilter.data)
        ) {
          if (!rowValue) {
            return false;
          }
          const numberValue: number = moment(rowValue).valueOf();
          console.log(
            `Data filter. value:${rowValue}, from:${columnFilter.data.valueFrom}, to:${columnFilter.data.valueTo}`
          );
          if (
            isNaN(numberValue) ||
            !checkDateValue(numberValue, columnFilter.data)
          ) {
            return false;
          }
          continue;
        }
        if (
          isBooleanFilterData(columnFilter.data) &&
          Boolean(rowValue) !== columnFilter.data.selected
        ) {
          return false;
        }
      }
      return true;
    });
  tableState.totalRowsLength = tableState.clientSideData.filteredRows.length;
  clientSideSortData(tableState);
}

function clientSideSortData(tableState: TableState) {
  if (!tableState.clientSideFilter) {
    tableState.clientSideData = null;
    return;
  }
  if (!tableState.clientSideData) {
    const rows = Object.keys(tableState.rowByIdx).map((idx) => Number(idx));
    tableState.clientSideData = {
      filteredRows: rows,
      sortedRows: [],
    };
  } else {
    tableState.clientSideData = { ...tableState.clientSideData };
  }
  tableState.clientSideData.sortedRows = tableState.clientSideData.filteredRows
    .slice()
    .sort((idxA, idxB) => {
      if (tableState.sortInfo.columns.length === 0) {
        return 0;
      }
      let result = 0;
      const rowA = tableState.rowByIdx[idxA as any];
      const rowB = tableState.rowByIdx[idxB as any];
      for (let i = tableState.sortInfo.columns.length - 1; i >= 0; --i) {
        const columnSort = tableState.sortInfo.columns[i];
        const fieldId = columnSort.field;
        let valueA = null;
        if (typeof rowA.bindedData[fieldId] !== "undefined") {
          valueA = rowA.bindedData[fieldId];
        } else if (typeof rowA.data[fieldId] !== "undefined") {
          valueA = rowA.data[fieldId];
        }
        let valueB = null;
        if (typeof rowB.bindedData[fieldId] !== "undefined") {
          valueB = rowB.bindedData[fieldId];
        } else if (typeof rowB.data[fieldId] !== "undefined") {
          valueB = rowB.data[fieldId];
        }
        if (valueA === valueB) {
          continue;
        }
        /**Check if first value is defined and second is not */
        if (valueA === null) {
          result = -1;
          break;
        }
        /**Check if second value is defined and first is not */
        if (valueB === null) {
          result = 1;
          break;
        }
        /**Check column type */
        const column = tableState.columns.find(
          (columnData) => columnData.field === fieldId
        );
        if (!column) {
          continue;
        }
        if (column.format === "date" || column.format === "dateTime") {
          valueA = moment(valueA).valueOf();
          valueB = moment(valueB).valueOf();
        } else if (column.format === "currency") {
          valueA = Number(valueA);
          valueB = Number(valueB);
        }
        if (typeof valueA === "string" && typeof valueB === "string") {
          result = valueA.localeCompare(valueB);
        } else {
          result = valueA > valueB ? 1 : -1;
        }
        if (columnSort.direction === "desc") {
          result = -result;
        }
      }
      return result;
    });
}

/****************
 *   Reducers   *
 ****************/
function receiveTableInitialData(
  state: TableReducerState,
  payload: { initialSorting?: ColumnSortInfo[] },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState: TableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  if (payload.initialSorting) {
    tableState.sortInfo = {
      columns: payload.initialSorting,
    };
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableHeader(
  state: TableReducerState,
  payload: { header: TableHeader },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    loading: false,
    parameters: payload.header.parameters,
    stylesheets: payload.header.stylesheets,
    toolbar: payload.header.toolbar,
    reports: payload.header.reports,
    columns: payload.header.columns,
    pageable: payload.header.pageable,
    pageSize: payload.header.pagination.size,
    pageSelection: payload.header.pagination.selection,
    selectType: payload.header.selectType,
    automation: parseAutomation(payload.header.automation),
    gantOptions: payload.header.gantOptions,
    dragOptions: payload.header.dragOptions || null,
    dropOptions: payload.header.dropOptions || null,
  };
  if (payload.header.userSettings) {
    tableState.fields = payload.header.userSettings.fields || tableState.fields;
    tableState.dynamicColumns =
      payload.header.userSettings.dynamicColumns || tableState.dynamicColumns;
    tableState.dynamicHiddenColumns =
      payload.header.userSettings.dynamicHiddenColumns ||
      tableState.dynamicHiddenColumns;
    tableState.sortInfo =
      payload.header.userSettings.sortInfo || tableState.sortInfo;
    tableState.filterInfo =
      payload.header.userSettings.filterInfo || tableState.filterInfo;
    const existColumnsMap: { [k: string]: boolean } = {};
    for (let column of tableState.columns) {
      existColumnsMap[column.field] = true;
    }
    if (tableState.dynamicColumns) {
      /**Check if dynamic columns doesn't contain all static columns */
      const dynamicColumnsMap: { [k: string]: boolean } = {};
      for (let columnField of tableState.dynamicColumns) {
        dynamicColumnsMap[columnField] = true;
      }
      for (let column of tableState.columns) {
        if (column.dynamic || dynamicColumnsMap[column.field]) {
          continue;
        }
        tableState.dynamicColumns.push(column.field);
        if (
          typeof tableState.dynamicHiddenColumns[column.field] === "undefined"
        ) {
          tableState.dynamicHiddenColumns[column.field] = true;
        }
      }
      /**Remove non-existing dynamic columns */
      tableState.dynamicColumns = tableState.dynamicColumns.filter(
        (columnField) => existColumnsMap[columnField]
      );
    }
    /**Remove non-existing columns from other user settings */
    for (let columnField of Object.keys(tableState.dynamicHiddenColumns)) {
      if (!existColumnsMap[columnField]) {
        delete tableState.dynamicHiddenColumns[columnField];
      }
    }
    tableState.sortInfo.columns = tableState.sortInfo.columns.filter(
      (column) => existColumnsMap[column.field]
    );
    tableState.filterInfo.columns = tableState.filterInfo.columns.filter(
      (column) => existColumnsMap[column.field]
    );
  }
  updateDisplayedScales(tableState);
  updateGantData(tableState, true);
  updateColumnsResizeInfo(tableState);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableData(
  state: TableReducerState,
  payload: {
    data: TableData;
    getAutomationTableModule?: (state: TableState) => AutomationTableModule;
    reset: boolean;
    savePage?: boolean;
  },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState: TableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    loading: false,
    loadingData: false,
    errorData: null,
  };

  if (payload.reset) {
    resetTableData(tableState, payload.savePage);
    resetGantData(tableState);
  }

  tableState.fields = { ...tableState.fields, ...payload.data.fields };
  if (typeof payload.data.totalRowsLength != "undefined") {
    tableState.totalRowsLength = payload.data.totalRowsLength;
  }
  const receivedPageSize = payload.data.pageSize;
  const receivedPage = payload.data.page;

  let rowStartIdx = 0;
  if (receivedPageSize !== -1) {
    rowStartIdx += receivedPageSize * receivedPage;
  }

  if (!payload.reset) {
    tableState.rowByIdx = { ...tableState.rowByIdx };
    setPageLoaded(tableState, receivedPage, receivedPageSize);
  }
  for (let i = 0; i < payload.data.rows.length; ++i) {
    const row: RowData = {
      data: { ...payload.data.rows[i].data },
      bindedData: {},
      changed: null,
      changedData: {},
      classes: {},
    };
    tableState.rowByIdx[rowStartIdx + i] = row;
  }
  const automationModule = payload.getAutomationTableModule?.(tableState);
  clientSideFilterData(tableState);
  updatePageData(tableState, automationModule);
  updateGantData(tableState);

  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableLoading(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    loading: true,
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableError(
  state: TableReducerState,
  payload: { error: FetchError },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    loading: false,
    error: payload.error,
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableDataLoading(
  state: TableReducerState,
  payload: { page: number; pageSize: number },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  setPageLoading(tableState, payload.page, payload.pageSize);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableDataError(
  state: TableReducerState,
  payload: { error: FetchError; page: number; pageSize: number },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    errorData: payload.error,
  };
  setPageLoaded(tableState, payload.page, payload.pageSize);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterSelectionLoading(
  state: TableReducerState,
  payload: { field: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.selectionByField = {
    ...tableState.filterInfo.selectionByField,
    [payload.field]: { loading: true, error: false, selection: [] },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterSelectionError(
  state: TableReducerState,
  payload: { field: string; error: FetchError },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.selectionByField = {
    ...tableState.filterInfo.selectionByField,
    [payload.field]: { loading: false, error: true, selection: [] },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterSelection(
  state: TableReducerState,
  payload: { field: string; selection: any[] },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.selectionByField = {
    ...tableState.filterInfo.selectionByField,
    [payload.field]: {
      loading: false,
      error: false,
      selection: payload.selection,
    },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterRangeLoading(
  state: TableReducerState,
  payload: { field: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.rangeByField = {
    ...tableState.filterInfo.rangeByField,
    [payload.field]: { loading: true, error: false, range: null },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterRangeError(
  state: TableReducerState,
  payload: { field: string; error: FetchError },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.rangeByField = {
    ...tableState.filterInfo.rangeByField,
    [payload.field]: { loading: false, error: true, range: null },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterRange(
  state: TableReducerState,
  payload: { field: string; range: { min: any; max: any } },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.rangeByField = {
    ...tableState.filterInfo.rangeByField,
    [payload.field]: { loading: false, error: false, range: payload.range },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableSortOptions(
  state: TableReducerState,
  payload: { sortColumns: ColumnSortInfo[]; filterColumns: string[] },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.sortInfo = { ...tableState.sortInfo };
  tableState.sortInfo.columns = payload.sortColumns.slice();
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.columns = tableState.filterInfo.columns.filter(
    (column) => payload.filterColumns.indexOf(column.field) !== -1
  );
  tableState.filterInfo.columns.sort((columnA, columnB) => {
    const idxA = payload.filterColumns.indexOf(columnA.field);
    const idxB = payload.filterColumns.indexOf(columnB.field);
    return idxA - idxB;
  });
  clientSideFilterData(tableState);
  if (tableState.clientSideFilter) {
    updatePageData(tableState);
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableColumns(
  state: TableReducerState,
  payload: {
    selectedColumns: string[] | null;
    hiddenColumns: { [k: string]: boolean };
  },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.dynamicColumns = payload.selectedColumns;
  tableState.dynamicHiddenColumns = payload.hiddenColumns;
  updateColumnsResizeInfo(tableState);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveSelectRow(
  state: TableReducerState,
  payload: { key: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  if (tableState.selectType === "radio") {
    const radioValue = !Boolean(tableState.selectedRows[payload.key]);
    tableState.selectedRows = {};
    if (radioValue) {
      tableState.selectedRows[payload.key] = true;
    }
    tableState.selectedRowsLength = radioValue ? 1 : 0;
  } else {
    tableState.selectedRows = { ...tableState.selectedRows };
    tableState.selectedRows[payload.key] =
      !tableState.selectedRows[payload.key];
    if (tableState.selectedRows[payload.key]) {
      tableState.selectedRowsLength++;
    } else {
      tableState.selectedRowsLength--;
    }
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveSelectAll(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.selectedRows = {};
  const selected = tableState.selectedRowsLength !== 0;
  tableState.selectedRowsLength = 0;
  if (!selected) {
    for (let rowIdx of tableState.pageRows) {
      const key = tableState.rowByIdx[rowIdx].data.key;
      if (!key) {
        continue;
      }
      tableState.selectedRows[key] = true;
      tableState.selectedRowsLength++;
    }
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receivePageSize(
  state: TableReducerState,
  payload: { pageSize: number },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const firstPageRowIdx = tableState.page * tableState.pageSize;
  tableState.pageSize = payload.pageSize;
  const page = Math.floor(firstPageRowIdx / payload.pageSize);
  changePage(tableState, page);
  nextState[tableId] = tableState;
  return nextState;
}

function receivePage(
  state: TableReducerState,
  payload: { page: number; automationTableModule: AutomationTableModule },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const totalPages = Math.ceil(
    tableState.totalRowsLength / tableState.pageSize
  );
  let page = payload.page;
  if (page < 0) {
    page = 0;
  } else if (page >= totalPages) {
    page = totalPages - 1;
  }
  changePage(tableState, page, payload.automationTableModule);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveSortKey(
  state: TableReducerState,
  payload: { key: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.sortInfo = { ...tableState.sortInfo };
  tableState.sortInfo.columns = tableState.sortInfo.columns.slice();

  const existingSortInfoIdx = tableState.sortInfo.columns.findIndex(
    (columnSortInfo) => columnSortInfo.field === payload.key
  );
  if (existingSortInfoIdx === -1) {
    tableState.sortInfo.columns.push({
      field: payload.key,
      direction: "asc",
    });
  } else {
    const columnSortInfo = tableState.sortInfo.columns[existingSortInfoIdx];
    if (columnSortInfo.direction === "desc") {
      tableState.sortInfo.columns.splice(existingSortInfoIdx, 1);
    } else {
      tableState.sortInfo.columns[existingSortInfoIdx] = {
        field: payload.key,
        direction: "desc",
      };
    }
  }
  clientSideSortData(tableState);
  if (tableState.clientSideFilter) {
    updatePageData(tableState);
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveFilterData(
  state: TableReducerState,
  payload: { field: string; filterData: FilterData },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.columns = tableState.filterInfo.columns.slice();

  const existingFilterInfoIdx = tableState.filterInfo.columns.findIndex(
    (columnFilterInfo) => columnFilterInfo.field === payload.field
  );
  if (existingFilterInfoIdx === -1) {
    tableState.filterInfo.columns.push({
      field: payload.field,
      data: payload.filterData,
    });
  } else {
    if (!payload.filterData) {
      tableState.filterInfo.columns.splice(existingFilterInfoIdx, 1);
    } else {
      tableState.filterInfo.columns[existingFilterInfoIdx] = {
        field: payload.field,
        data: payload.filterData,
      };
    }
  }
  clientSideFilterData(tableState);
  if (tableState.clientSideFilter) {
    updatePageData(tableState);
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveLocalTableInitialData(
  state: TableReducerState,
  payload: InitialLocalTableData,
  tableId: string
): TableReducerState {
  let nextState = { ...state };
  nextState[tableId] = { ...DEFAULT_TABLE_STATE };
  const page = payload.page || DEFAULT_TABLE_STATE.page;
  const pageSize = payload.pageSize || DEFAULT_TABLE_STATE.pageSize;
  if (payload.pageable) {
    nextState[tableId].pageable = true;
    nextState[tableId].page = page;
    nextState[tableId].pageSize = pageSize;
  }
  nextState = receiveTableData(
    nextState,
    {
      data: {
        fields: payload.fields,
        rows: payload.data,
        page: page,
        pageSize: pageSize,
        totalRowsLength: payload.data.length,
      },
      reset: true,
    },
    tableId
  );
  const { automation, ...initialData } = payload;
  const tableState = { ...nextState[tableId], ...initialData };
  if (automation) {
    tableState.automation = parseAutomation(automation);
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveLocalTableUpdateData(
  state: TableReducerState,
  payload: LocalTableData,
  tableId: string
): TableReducerState {
  const nextState = receiveTableData(
    state,
    {
      data: {
        fields: {},
        rows: payload.data,
        page: state[tableId].page || DEFAULT_TABLE_STATE.page,
        pageSize: state[tableId].pageSize || DEFAULT_TABLE_STATE.pageSize,
        totalRowsLength: payload.data.length,
      },
      reset: true,
    },
    tableId
  );
  const tableState = { ...nextState[tableId], ...payload };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveLocalTableUninitialize(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  if (nextState[tableId]) {
    delete nextState[tableId];
  }
  return nextState;
}

function receiveField(
  state: TableReducerState,
  payload: { parameter: string; value: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterChanges = initializeChangedFilter(tableState);
  tableState.filterChanges.fields = { ...tableState.filterChanges.fields };
  tableState.filterChanges.fields[payload.parameter] = payload.value;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveGantViewType(
  state: TableReducerState,
  payload: { type: GantViewType },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const gantData = { ...tableState.gantData };
  gantData.viewType = payload.type;
  if (gantData.viewType === constants.GANT_READ) {
    gantData.selectedGroup = "";
  } else if (gantData.viewType === constants.GANT_CHOSEN_DATE) {
    gantData.selectedGroup = tableState.gantOptions?.gantGroups[0]?.id || "";
  } else {
    gantData.selectedGroup = "plannedDocs";
  }
  tableState.gantData = gantData;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveGantSelectedGroup(
  state: TableReducerState,
  payload: { group: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const gantData = { ...tableState.gantData };
  gantData.selectedGroup = payload.group;
  tableState.gantData = gantData;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveGantChangeElement(
  state: TableReducerState,
  payload: {
    rowIndex: number;
    itemIndex: number;
    newDate: GantDateLimits;
    isPlanned: boolean;
  },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const dataType = payload.isPlanned
    ? constants.GANT_PLANNED_DATE
    : constants.GANT_CHOSEN_DATE;
  const roundedFrom = roundGantTimestamp(
    payload.newDate.from,
    (tableState.gantOptions as TableGantOptions).scale,
    "auto"
  );
  const roundedTo = roundGantTimestamp(
    payload.newDate.to,
    (tableState.gantOptions as TableGantOptions).scale,
    "auto"
  );
  if (roundedFrom === roundedTo) {
    //TODO: delete element
    return nextState;
  }
  tableState.rowByIdx = { ...tableState.rowByIdx };
  tableState.rowByIdx[payload.rowIndex] = {
    ...tableState.rowByIdx[payload.rowIndex],
  };
  if (
    !tableState.rowByIdx[payload.rowIndex].changed ||
    !(tableState.rowByIdx[payload.rowIndex].changed as { [k: string]: boolean })
      .gantData
  ) {
    tableState.rowByIdx[payload.rowIndex].changed = Object.assign(
      {},
      tableState.rowByIdx[payload.rowIndex].changed,
      { gantData: true }
    );
  }
  tableState.rowByIdx[payload.rowIndex].changedData = {
    ...tableState.rowByIdx[payload.rowIndex].changedData,
  };
  tableState.rowByIdx[payload.rowIndex].changedData.gantData = {
    ...tableState.rowByIdx[payload.rowIndex].bindedData.gantData,
    ...tableState.rowByIdx[payload.rowIndex].changedData.gantData,
  };
  tableState.rowByIdx[payload.rowIndex].changedData.gantData[dataType] =
    tableState.rowByIdx[payload.rowIndex].changedData.gantData[
      dataType
    ].slice();
  const gantRowItems =
    tableState.rowByIdx[payload.rowIndex].changedData.gantData[dataType];
  gantRowItems[payload.itemIndex] = Object.assign(
    {},
    gantRowItems[payload.itemIndex]
  );
  if (typeof gantRowItems[payload.itemIndex].originalFrom === "undefined") {
    gantRowItems[payload.itemIndex].originalFrom =
      gantRowItems[payload.itemIndex].from;
    gantRowItems[payload.itemIndex].originalTo =
      gantRowItems[payload.itemIndex].to;
  }
  Object.assign(gantRowItems[payload.itemIndex], {
    from: roundedFrom,
    to: roundedTo,
  });
  nextState[tableId] = tableState;
  return nextState;
}

function receiveFilterChangesConfirm(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.fields = {
    ...tableState.fields,
    ...tableState.filterChanges?.fields,
  };
  tableState.filterChanges = null;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveFilterChangesDeny(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterChanges = null;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableSaveStart(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.savingError = null;
  tableState.saving = true;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableSaveSuccess(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.savingError = null;
  tableState.saving = false;
  tableState.rowByIdx = { ...tableState.rowByIdx };
  for (let rowIdx in tableState.rowByIdx) {
    if (!tableState.rowByIdx[rowIdx].changed) {
      continue;
    }
    delete tableState.rowByIdx[rowIdx];
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableSaveError(
  state: TableReducerState,
  payload: { error: FetchError },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.savingError = payload.error;
  tableState.saving = false;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableRowAdd(
  state: TableReducerState,
  payload: { row: RowDataMap },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const newRowIdx = tableState.totalRowsLength || 0;
  tableState.rowByIdx = { ...tableState.rowByIdx };
  const changed: { [k: string]: boolean } = {};
  for (let key of Object.keys(payload.row)) {
    changed[key] = true;
  }
  const newRow: RowData = {
    data: { ...payload.row },
    bindedData: {},
    changed: changed,
    changedData: {},
    classes: {},
  };
  tableState.rowByIdx[newRowIdx] = newRow;
  tableState.totalRowsLength++;
  clientSideFilterData(tableState);
  updatePageData(tableState);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableRowChange(
  state: TableReducerState,
  payload: { row: RowDataMap; rowIdx: number },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  if (payload.rowIdx >= tableState.totalRowsLength) {
    console.warn(
      `Can't change row data: idx is out of bounds (idx: ${payload.rowIdx}, total: ${tableState.totalRowsLength})`
    );
    return state;
  }
  tableState.rowByIdx = { ...tableState.rowByIdx };
  tableState.rowByIdx[payload.rowIdx] = {
    ...tableState.rowByIdx[payload.rowIdx],
  };
  tableState.rowByIdx[payload.rowIdx].changedData = {
    ...tableState.rowByIdx[payload.rowIdx].changedData,
  };
  const changed = { ...tableState.rowByIdx[payload.rowIdx].changed };
  for (let key of Object.keys(payload.row)) {
    tableState.rowByIdx[payload.rowIdx].changedData[key] = payload.row[key];
    changed[key] = true;
  }
  tableState.rowByIdx[payload.rowIdx].changed = changed;
  clientSideFilterData(tableState);
  updatePageData(tableState);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableCellChange(
  state: TableReducerState,
  payload: { value: any; rowIdx: number; column: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  if (
    payload.rowIdx >= tableState.totalRowsLength &&
    !tableState.rowByIdx[payload.rowIdx]
  ) {
    console.warn(
      `Can't change cell data: row idx is out of bounds (idx: ${payload.rowIdx}, total: ${tableState.totalRowsLength})`
    );
    return state;
  }
  if (!payload.column) {
    console.warn(`Can't change cell data: column is undefined`);
    return state;
  }
  tableState.rowByIdx = { ...tableState.rowByIdx };
  tableState.rowByIdx[payload.rowIdx] = {
    ...tableState.rowByIdx[payload.rowIdx],
  };
  tableState.rowByIdx[payload.rowIdx].changedData = {
    ...tableState.rowByIdx[payload.rowIdx].changedData,
    [payload.column]: payload.value,
  };
  tableState.rowByIdx[payload.rowIdx].changed = {
    ...tableState.rowByIdx[payload.rowIdx].changed,
    [payload.column]: true,
  };
  clientSideFilterData(tableState);
  updatePageData(tableState);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableClientSideFilter(
  state: TableReducerState,
  payload: { clientSideFilter: boolean },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.clientSideFilter = payload.clientSideFilter;
  nextState[tableId] = tableState;
  return nextState;
}

const reducer: Reducer<TableReducerState, TableAction> = (
  state: TableReducerState = DEFAULT_STATE,
  action: TableAction
): TableReducerState => {
  switch (action.type) {
    case constants.SEND_TABLE_INITIAL_DATA:
      return receiveTableInitialData(
        state,
        (action as SendTableInitialData).payload,
        (action as SendTableInitialData).tableId
      );
    case constants.SEND_TABLE_HEADER:
      return receiveTableHeader(
        state,
        (action as SendTableHeader).payload,
        (action as SendTableHeader).tableId
      );
    case constants.SEND_TABLE_DATA:
      return receiveTableData(
        state,
        (action as SendTableData).payload,
        (action as SendTableData).tableId
      );
    case constants.SEND_TABLE_LOADING:
      return receiveTableLoading(
        state,
        (action as SendTableLoading).payload,
        (action as SendTableLoading).tableId
      );
    case constants.SEND_TABLE_ERROR:
      return receiveTableError(
        state,
        (action as SendTableError).payload,
        (action as SendTableError).tableId
      );
    case constants.SEND_TABLE_DATA_LOADING:
      return receiveTableDataLoading(
        state,
        (action as SendTableDataLoading).payload,
        (action as SendTableDataLoading).tableId
      );
    case constants.SEND_TABLE_DATA_ERROR:
      return receiveTableDataError(
        state,
        (action as SendTableDataError).payload,
        (action as SendTableDataError).tableId
      );
    case constants.SEND_TABLE_FILTER_SELECTION_LOADING:
      return receiveTableFilterSelectionLoading(
        state,
        (action as SendTableFilterSelectionLoading).payload,
        (action as SendTableFilterSelectionLoading).tableId
      );
    case constants.SEND_TABLE_FILTER_SELECTION_ERROR:
      return receiveTableFilterSelectionError(
        state,
        (action as SendTableFilterSelectionError).payload,
        (action as SendTableFilterSelectionError).tableId
      );
    case constants.SEND_TABLE_FILTER_SELECTION:
      return receiveTableFilterSelection(
        state,
        (action as SendTableFilterSelection).payload,
        (action as SendTableFilterSelection).tableId
      );
    case constants.SEND_TABLE_FILTER_RANGE_LOADING:
      return receiveTableFilterRangeLoading(
        state,
        (action as SendTableFilterRangeLoading).payload,
        (action as SendTableFilterRangeLoading).tableId
      );
    case constants.SEND_TABLE_FILTER_RANGE_ERROR:
      return receiveTableFilterRangeError(
        state,
        (action as SendTableFilterRangeError).payload,
        (action as SendTableFilterRangeError).tableId
      );
    case constants.SEND_TABLE_FILTER_RANGE:
      return receiveTableFilterRange(
        state,
        (action as SendTableFilterRange).payload,
        (action as SendTableFilterRange).tableId
      );
    case constants.SEND_TABLE_SORT_OPTIONS:
      return receiveTableSortOptions(
        state,
        (action as SendTableSortOptions).payload,
        (action as SendTableSortOptions).tableId
      );
    case constants.SEND_TABLE_COLUMNS:
      return receiveTableColumns(
        state,
        (action as SendTableColumns).payload,
        (action as SendTableColumns).tableId
      );
    case constants.SEND_SELECT_ROW:
      return receiveSelectRow(
        state,
        (action as SendSelectRow).payload,
        (action as SendSelectRow).tableId
      );
    case constants.SEND_SELECT_ALL:
      return receiveSelectAll(
        state,
        (action as SendSelectAll).payload,
        (action as SendSelectAll).tableId
      );
    case constants.SEND_PAGE_SIZE:
      return receivePageSize(
        state,
        (action as SendPageSize).payload,
        (action as SendPageSize).tableId
      );
    case constants.SEND_PAGE:
      return receivePage(
        state,
        (action as SendPage).payload,
        (action as SendPage).tableId
      );
    case constants.SEND_SORT_KEY:
      return receiveSortKey(
        state,
        (action as SendSortKey).payload,
        (action as SendSortKey).tableId
      );
    case constants.SEND_FILTER_DATA:
      return receiveFilterData(
        state,
        (action as SendFilterData).payload,
        (action as SendFilterData).tableId
      );
    case constants.SEND_LOCAL_TABLE_INITIAL_DATA:
      return receiveLocalTableInitialData(
        state,
        (action as SendLocalTableInitialData).payload,
        (action as SendLocalTableInitialData).tableId
      );
    case constants.SEND_LOCAL_TABLE_UPDATE_DATA:
      return receiveLocalTableUpdateData(
        state,
        (action as SendLocalTableUpdateData).payload,
        (action as SendLocalTableUpdateData).tableId
      );
    case constants.SEND_LOCAL_TABLE_UNINITIALIZE:
      return receiveLocalTableUninitialize(
        state,
        (action as SendLocalTableUninitialize).payload,
        (action as SendLocalTableUninitialize).tableId
      );
    case constants.SEND_FIELD:
      return receiveField(
        state,
        (action as SendField).payload,
        (action as SendField).tableId
      );
    case constants.SEND_GANT_VIEW_TYPE:
      return receiveGantViewType(
        state,
        (action as SendGantViewType).payload,
        (action as SendGantViewType).tableId
      );
    case constants.SEND_GANT_SELECTED_GROUP:
      return receiveGantSelectedGroup(
        state,
        (action as SendGantSelectedGroup).payload,
        (action as SendGantSelectedGroup).tableId
      );
    case constants.SEND_GANT_CHANGE_ELEMENT:
      return receiveGantChangeElement(
        state,
        (action as SendGantChangeElement).payload,
        (action as SendGantChangeElement).tableId
      );
    case constants.SEND_FILTER_CHANGES_CONFIRM:
      return receiveFilterChangesConfirm(
        state,
        (action as SendFilterChangesConfirm).payload,
        (action as SendFilterChangesConfirm).tableId
      );
    case constants.SEND_FILTER_CHANGES_DENY:
      return receiveFilterChangesDeny(
        state,
        (action as SendFilterChangesDeny).payload,
        (action as SendFilterChangesDeny).tableId
      );
    case constants.SEND_TABLE_SAVE_START:
      return receiveTableSaveStart(
        state,
        (action as SendTableSaveStart).payload,
        (action as SendTableSaveStart).tableId
      );
    case constants.SEND_TABLE_SAVE_SUCCESS:
      return receiveTableSaveSuccess(
        state,
        (action as SendTableSaveSuccess).payload,
        (action as SendTableSaveSuccess).tableId
      );
    case constants.SEND_TABLE_SAVE_ERROR:
      return receiveTableSaveError(
        state,
        (action as SendTableSaveError).payload,
        (action as SendTableSaveError).tableId
      );
    case constants.SEND_TABLE_ROW_ADD:
      return receiveTableRowAdd(
        state,
        (action as SendTableRowAdd).payload,
        (action as SendTableRowAdd).tableId
      );
    case constants.SEND_TABLE_ROW_CHANGE:
      return receiveTableRowChange(
        state,
        (action as SendTableRowChange).payload,
        (action as SendTableRowChange).tableId
      );
    case constants.SEND_TABLE_CELL_CHANGE:
      return receiveTableCellChange(
        state,
        (action as SendTableCellChange).payload,
        (action as SendTableCellChange).tableId
      );
    case constants.SEND_TABLE_CLIENT_SIDE_FILTER:
      return receiveTableClientSideFilter(
        state,
        (action as SendTableClientSideFilter).payload,
        (action as SendTableClientSideFilter).tableId
      );
  }
  return state;
};

export default reducer;
