import * as React from 'react';

import { FormattedMessage } from 'react-intl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCogs, faGlobe, faSpinner, faArchive, faCompactDisc, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';
import { SCMInformation, isSCMInformation, isFetchError } from '../../types/scm';
import { fetchSCMInfo } from '../../actions/scm';
import { FetchError } from '../../types/error';
import { ApplicationAction, ApplicationState, ReducerManagerInterface } from '../../types';
import { connect, ReactReduxContext } from 'react-redux';
import { AnyAction, Store } from 'redux';
import moment from 'moment';
import reducer from "../../reducers/scm";
import { ThunkDispatch } from 'redux-thunk';
import MainMenu from '../menu/MainMenu';

interface ErrorMessageProps {
    error: FetchError
}

const ErrorMessage = (props: ErrorMessageProps) => (
    <div className="text-warning d-flex justify-content-center">
        <span>
            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="pr-2" />
            <FormattedMessage id='HOME_PAGE_ERROR'
                defaultMessage='Loading SCM Information Failed: {message} [{code}]'
                description='Error message if loading of SCM information failed' values={{
                    message: props.error.message,
                    code: props.error.code
                }} />
        </span>
    </div>
)

const SCMInformationForm = (props: SCMInformation) => (
    <>
        {props.pm && <Row className="justify-content-center">
            <Col md={12}>
                <Card  >
                    <Card.Header className="text-white  font-weight-bold bg-primary">
                        <FontAwesomeIcon icon={faProjectDiagram} className="mr-2" />
                        <FormattedMessage id='HOME_PAGE_CONFIG_PROJECT'
                            defaultMessage='Project'
                            description='Project information' />
                    </Card.Header>
                    <Card.Body  >
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='HOME_PAGE_CONFIG_PROJECT_LABEL'
                                        defaultMessage='Name'
                                        description='Project label' />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={props.pm.SCMLabel}
                                    disabled={true} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='HOME_PAGE_CONFIG_SERVER_LABEL'
                                        defaultMessage='Server'
                                        description='Server label' />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={props.pm.serverLabel}
                                    disabled={true} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {
                                        !props.pm.licenseIsValid ?
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                                className="text-warning mr-2" /> :
                                            null
                                    }
                                    <FormattedMessage id='HOME_PAGE_CONFIG_LICENSE'
                                        defaultMessage='License'
                                        description='License' />
                                </Form.Label>
                                {
                                    props.pm.licenseIsValid ?
                                        <FormattedMessage id="HOME_PAGE_CONFIG_LICENSE_VALID"
                                            defaultMessage='License is valid'
                                            description='License is valid'>
                                            {(message) => (
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    value={typeof message == 'string' ? message : undefined}
                                                    disabled={true} />)
                                            }
                                        </FormattedMessage> :
                                        <FormattedMessage id="HOME_PAGE_CONFIG_LICENSE_INVALID"
                                            defaultMessage='License is invalid'
                                            description='License is invalid'>
                                            {(message) => (
                                                <Form.Control
                                                    type="text"
                                                    className="text-danger"
                                                    placeholder=""
                                                    value={typeof message == 'string' ? message : undefined}
                                                    disabled={true} />)
                                            }
                                        </FormattedMessage>
                                }
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>}

        <Row className="justify-content-center mt-2">
            <Col md={12}>
                <Card  >
                    <Card.Header className="text-white  font-weight-bold  bg-primary">
                        <FontAwesomeIcon icon={faCompactDisc} className="mr-2" />
                        <FormattedMessage id='HOME_PAGE_CONFIG_INSTALLATION'
                            defaultMessage='Installation'
                            description='Installation information' />
                    </Card.Header>
                    <Card.Body  >
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='HOME_PAGE_CONFIG_HOME_DIR'
                                        defaultMessage='Home directory'
                                        description='Home directory' />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={props.homeDir}
                                    disabled={true} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='HOME_PAGE_CONFIG_HOME_SOURCE'
                                        defaultMessage='Executable files'
                                        description='Executable source files' />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={props.homeSource}
                                    disabled={true} />
                            </Form.Group>
                            {props.needsDbConfigure && <Alert variant="warning" className="d-flex">
                                <div>
                                    <span>
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="pr-2" />
                                    </span>
                                </div>
                                <div>
                                    <Link to="/db">
                                        <FormattedMessage id='HOME_PAGE_CONFIG_DB_LINK'
                                            defaultMessage='Please configure database'
                                            description='Please configure database' />
                                    </Link>
                                </div>
                            </Alert>}
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Row className="justify-content-center mt-2">
            <Col md={12}>
                <Card  >
                    <Card.Header className="text-white font-weight-bold bg-primary">
                        <FontAwesomeIcon icon={faArchive} className="mr-2" />
                        <FormattedMessage id='HOME_PAGE_CONFIG_SOFTWARE'
                            defaultMessage='Software Version'
                            description='Software version information' />
                    </Card.Header>
                    <Card.Body className=" ">
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='HOME_PAGE_CONFIG_SOFTWARE_VERSION'
                                        defaultMessage='Version'
                                        description='Version' />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={props.version.version}
                                    disabled={true} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='HOME_PAGE_CONFIG_SOFTWARE_REVISION'
                                        defaultMessage='Revision'
                                        description='Revision' />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={props.version.hash}
                                    disabled={true} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='HOME_PAGE_CONFIG_SOFTWARE_BRANCH'
                                        defaultMessage='Branch'
                                        description='Branch' />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={props.version.branch}
                                    disabled={true} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='HOME_PAGE_CONFIG_SOFTWARE_TIMESTAMP'
                                        defaultMessage='Timestamp'
                                        description='Timestamp' />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={moment(props.version.timestamp).format('LLLL')}
                                    disabled={true} />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
)

interface HomeProperties {
    loading: boolean
    info?: SCMInformation | FetchError
    store: Store<any, AnyAction>
    fetchScmInfo: () => void
}

class Home extends React.Component<HomeProperties> {

    constructor(props: HomeProperties) {
        super(props);

        // Register reducer to server our requests
        const manager = (props.store as any).reducerManager as ReducerManagerInterface;
        manager.add('scm', reducer);
    }

    componentDidMount() {
        this.props.fetchScmInfo();
    }

    renderLoading() {
        return <div className="d-flex justify-content-center mt-4">
            <FontAwesomeIcon icon={faSpinner} spin size="5x" />
        </div>
    }

    renderContent() {
        if (this.props.loading) {
            return this.renderLoading();
        }
        if (isSCMInformation(this.props.info)) {
            return (<SCMInformationForm {...this.props.info} />)
        }
        if (isFetchError(this.props.info)) {
            return <ErrorMessage error={this.props.info} />
        }
        return null;
    }

    render() {
        return (<> 
            <Container fluid className="mt-2 flex-fill scm-home-page" style={{ overflowY: 'scroll' }}>
                {this.renderContent()}
            </Container>
        </>)
    }
}

const ConnectedHome = connect(
    (state: ApplicationState) => {
        return {
            loading: state.scm && state.scm.loading || false,
            info: state.scm && state.scm.info
        }
    }, (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
        return {
            fetchScmInfo: () => dispatch(fetchSCMInfo())
        }
    }
)(Home)

export default class SCMInfo extends React.Component {

    shouldComponentUpdate(nextProps: any) {
        return false;
    };

    render() {
        return <ReactReduxContext.Consumer>
            {({ store }) => (<ConnectedHome store={store} />)}
        </ReactReduxContext.Consumer>;
    }
}

