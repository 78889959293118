import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { ValueType } from 'react-select';
import { MSG_SELECT_LOADING, MSG_SELECT_NO_RESULTS } from '../../../messages';
import Tag from './Tag';
// import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../messages.jsx';
export type TagOptionType = {label:string,value:number};
function sortedAdd(array: any[], item: any, func: any) {
    for (let i = 0; i < array.length; ++i) {
        if (func(array[i], item)) {
            array.splice(i, 0, item);
            return;
        }
    }
    array.push(item);
}

interface TagSelectProps {
    tags: TagOptionType[],
    suggestions: TagOptionType[],
    selectHandler: Function,
    deleteHandler: Function,
    displayField: string,
    placeholder: any
}

class TagSelect extends React.Component<TagSelectProps> {
    private displayField: string;
    private options: any[] = [];
    private dataMap: any = {};
    constructor(props: TagSelectProps) {
        super(props);

        this.displayField = props.displayField ? props.displayField : "label";

        this.updateTags(props);

        this.selectTag = this.selectTag.bind(this);
    }

    updateTags(props: TagSelectProps) {
        const { suggestions } = props;
        this.options = [];
        this.dataMap = {};
        for (let suggestion of suggestions) {
            const { value, label } = suggestion;
            sortedAdd(this.options, { value, label }, function (a: any, b: any) { return a.label.toLowerCase() > b.label.toLowerCase() });
            this.dataMap[value] = suggestion;
        }
    }

    selectTag(newValue: ValueType<TagOptionType,any>) {
        const value = (newValue as TagOptionType).value;
        this.props.selectHandler(this.dataMap[value]);
    }

    shouldComponentUpdate(nextProps: TagSelectProps) {
        if (this.props.suggestions != nextProps.suggestions
            || this.props.tags != nextProps.tags) {
            return true;
        }
        return false;
    }

    componentWillReceiveProps(nextProps: TagSelectProps) {
        if (this.props.suggestions != nextProps.suggestions) {
            this.updateTags(nextProps);
        }
    }

    render() {
        const placeholderElement = (
            <FormattedMessage id={this.props.placeholder} />
        );
        return (<div className="tag-select">
            <div className="npt-parent-tags">
                {this.props.tags.map((tag) =>
                    <Tag tag={tag} key={tag.value} displayField={this.displayField} deleteHandler={this.props.deleteHandler}></Tag>
                )}
            </div>
            <Select 
                className='pt-1 npt-select'
                name="tagSelect"
                loadingPlaceholder={MSG_SELECT_LOADING}
                placeholder={placeholderElement}
                noResultsText={MSG_SELECT_NO_RESULTS}
                value={null}
                options={this.options}
                onChange={this.selectTag}
                clearable={false}
            />
        </div>);
    }
}


export default TagSelect;    