import React from 'react';
import { Form } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { FormattedMessage } from 'react-intl';
import { I18NString } from '../../../types/modal';
import { LayoutNode, SubjectComment } from '../../../types/subject';

import { Comment } from './BasicInput';

import styles from '../boxes/Panel.module.css';

export interface LargeInputProps {
    id: string
    node: LayoutNode
    visible: boolean
    hideLabel?: boolean
    error?: string | I18NString
    editable?: boolean
    cardEditable?: boolean
    comment?: SubjectComment
}

export default class LargeInput extends React.Component<LargeInputProps> {

    getMandatory() {
        if (this.props.node.mandatory) {
            return (<span className="text-danger">*</span>);
        }
        return null;
    }

    renderError() {
        const { error } = this.props;
        if (!error) {
            return null;
        }
        return (
            <Form.Text className="text-danger">
                {typeof error === 'string' ? error :
                    <FormattedMessage id={error.id} values={error.values} />
                }
            </Form.Text>
        )
    }

    render() {
        if (!this.props.visible) {
            return null;
        }
        if (this.props.hideLabel) {
            return this.props.children;
        }
        const isErrorDisplayed = this.props.editable && this.props.error;
        const cardClassName = `mb-1 border ${isErrorDisplayed ? "border-danger" : "border-primary"}`;
        const headerClassName = `${isErrorDisplayed ? "bg-danger" : "bg-primary"} font-weight-bold px-1 card-title text-white`;
        return <div className="card-row">
            <Card className={cardClassName}>
                <Card.Header className={headerClassName}>
                    {this.props.node.label} {this.getMandatory()}
                </Card.Header>
                <Card.Body className={styles.body}>
                    {this.props.children}
                    <div>
                        {isErrorDisplayed && this.renderError()}
                    </div>
                    <Comment
                        className="w-100 text-center"
                        value={this.props.comment}
                        editable={this.props.editable}
                        cardEditable={this.props.cardEditable}
                    />
                </Card.Body>
            </Card>
        </div>

    }
}