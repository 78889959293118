import { ComponentClass } from 'react';
import { Action } from 'redux';
import { ConnectedComponent } from 'react-redux';
import { FetchError } from './error';

import * as constants from '../constants/tree';

//Common types for select range functions
export interface NodeData {
    parentId?: string
}

export interface NodeMap {
    [id: string]: NodeData
}

export interface TreeNodeState {
    rootNodesIds?: string[]
    nodeById?: NodeMap
    childrenIds?: { [ID: string]: string[] }
    selected?: string[] | null
    pivotPoint?: string
    active?: string
}





export interface ExpandTreeNodeEventOptions {
    treeId: string
    nodeId: string
}

export interface Parameter {
    object: string
    addId: string
    namespace?: any
}
export interface AddAction {
    id: string
    clazz: string
    parentRef: string
    label: string
    table?: any
}
export interface MenuItemAction {
    id?: string
    label?: string
    onAction: (param?: any) => void
}



/**Map of tree states in reducer state */
export interface TreeReducerState {
    treeInfo: { [treeId: string]: TreeState }
    contextMenuInfo?: ContextMenuInfo
}

export interface TreeHeaderFilterOption {
    label: string
    value: string
    checked: boolean
}

export interface TreeHeaderFilter {
    key: string
    options: TreeHeaderFilterOption[]
}
enum ModalSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    FULLSCREEN = 'fullscreen'
}
interface ModalSizeType {
    value: ModalSize
}

export interface TreeHeader {
    //If tree must be selected from model
    model?: string
    filter?: TreeHeaderFilter
    modalSize?: ModalSizeType
    hash: string
    automation?: string
    typeMap: { [k: string]: TreeNodeType }
    fragmentTree?: TreeHeaderFragmentTree
    classTree?: TreeHeaderClassTree
    criteriaTree?: string
}

export interface TreeHeaderFragmentTree {
    model: string
    levels: TreeHeaderFragmentTreeLevel[]
}

interface TreeHeaderFragmentTreeLevel {
    label: string
    types: string[]
}

export interface TreeHeaderClassTree {
    levels: TreeHeaderClassTreeLevel[]
}

interface TreeHeaderClassTreeLevel {
    id: string
    label: string
}

export interface ContextMenuInfo {
    treeId: string
    nodeId: string
}

/**State of tree */
export interface TreeState {
    treeId: string
    rootNodesIds?: string[]
    nodeIdByRdfId?: { [RDF_ID: string]: string }
    nodeById?: { [ID: string]: TreeNode }
    children?: { [ID: string]: string[] | null }
    toggled?: { [ID: string]: boolean }
    active?: string | null
    updateNodes?: (string | null)[]
    valid?: { id: string, isValid: boolean }
    loading?: { [ID: string]: boolean }
    error?: FetchError
    header?: TreeHeader
    contextMenuInfo?: ContextMenuInfo
    /**Bindings of tree automation */
    automation: TreeAutomationBindings
}
// export interface TreeState {
//     /**Tree ID. May be used to identify redux store data location.*/
//     treeId: string,
//     /**Root ids of tree*/
//     rootNodesIds: string[],
//     /**Loading state of tree*/
//     loading: boolean,
//     /**Error state of tree*/
//     error: FetchError | null,
//     /**Map of tree nodes by id*/
//     nodeById: { [id: string]: TreeNode | FetchError }
//     /**List of children id's by node id. undefined means that node is leaf, null means that children must be requested.*/
//     children: { [id: string]: string[] | null }
//     /**Map of node toggle state by id*/
//     toggled: { [id: string]: boolean }
//     /**Id of active node*/
//     active: string | null

//     header: TreeHeader
// }

/**Binding funcitons ids map of tree automation */
export interface TreeAutomationBindings {
    /**
     * Sorting function by parent type 
     * (parentType, function) => number
     */
    sortBindings: { [parentType: string]: string } | null //(nodeA, nodeB) => number

    /**
     * Handle click on custom context menu actions
     * (itemId, function) => void
     */
    actionBindings: { [compositeActionId: string]: string } | null //(tree) => void

    /**
     * Decorate node by type and other options 
     * ({type, ...options}, function) => {color, iconColor, icon}
     */
    decoratorBindings: { [compositeDecoratorId: string]: string } | null //(node, active, toggled, treeFilter) => {color, iconColor, icon, hidden, textStyle, iconStyle}

    /**
     * Decorate node by type and other options (used after children decorators)
     * ({type, ...options}, function) => {color, iconColor, icon}
     */
    reverseDecoratorBindings: { [compositeDecoratorId: string]: string } | null //(node, active, toggled, treeFilter, children) => {color, iconColor, icon, hidden, textStyle, iconStyle}
}

/**Bindings to store automations after eval user script */
export interface TreeAutomationBindingFunctions {
    /**Store sort bindings */
    bindSort: (parentType: string, func: Function) => void
    /**Store action bindings */
    bindAction: (options: { action: string, type?: string }, func: Function) => void
    /**Store decorator bindings */
    bindDecorator: (options: { type: string }, func: Function) => void
    /**Store reverse decorator bindings */
    bindReverseDecorator: (options: { type: string }, func: Function) => void
}

export interface TreeNodeType {
    id: number
    type: string
    leaf: boolean
    icon?: string
    iconColor?: string
    actions?: TreeNodeAction[]
    deleteLock?: boolean
    moveLock?: boolean
    copyLock?: boolean
    pasteTypes?: string[]
}

export interface TreeCommonNodeAction {
    id: string
    label: string
}

export interface TreeNodeAddAction extends TreeCommonNodeAction {
    type: "ADD" | "ADD_ACTION"
    prototype?: TreeNodeAddActionPrototype
}

export interface TreeNodeAddActionPrototype {
    model: string
    type: "TABLE"
}

export interface TreeNodeGenericAction extends TreeCommonNodeAction {
    type: "GENERIC"
    icon?: string
}

export function isTreeNodeAddAction(action: TreeNodeAction): action is TreeNodeAddAction {
    return (action as TreeNodeAddAction).type === "ADD" || (action as TreeNodeAddAction).type === "ADD_ACTION";
}

export function isTreeNodeGenericAction(action: TreeNodeAction): action is TreeNodeGenericAction {
    return (action as TreeNodeGenericAction).type === "GENERIC";
}

export type TreeNodeAction = TreeNodeAddAction | TreeNodeGenericAction;

export interface ServerTreeNode {
    cd?: any
    f: string
    l: string
    o: string
    t: number
    s?: boolean
    d?: string
    m?: string
    icon?: string
    iconColor?: string
}

export interface TreeNode {
    id: string
    nodeId: string
    parentId: string | null
    typeId: string
    label: string
    description: string
    leaf: boolean
    visible: boolean
    deleteLock: boolean
    moveLock: boolean
    copyLock: boolean
    data: TreeNodeData | null
    /**Special data of node for filters and automations*/
    cache: any | null
    /**Name of node*/
    name?: string,
    /**Loading state of node */
    loading: boolean,
    /**Error state of node */
    error?: FetchError,
    /**Icon of node*/
    icon?: string,
    /**Color of node icon*/
    iconColor?: string,
    packageId?: string
    /**Last script or SPEL execution error*/
    e?: string
    /**Available add actions for node*/
    addActions?: TreeNodeAddAction[]
    /**Available generic actions for node*/
    genericActions?: TreeNodeGenericAction[]
    /**Flag of syntetic node */
    syntetic?: boolean
    /**Acceptable types as children */
    pasteTypes?: string[]
}

export interface TreeNodeData {
    $class?: string //TODO: node from server doesn't have this value
    $fragment?: {
        $rdfId: string
    }
    $namespace?: string
    $label?: string
    $description?: string
    $rdfId: string
    $isNew?: boolean
}


/**
 * Properties of List Tree Node 
 * that should be defined
 */
export interface NodeProps {
    /** Tree ID that this node belongs to*/
    treeId: string,
    /** Node id (unique id of node in the tree)*/
    id: string,
    /** Node name (displayed for user) */
    name?: string,
    /** List of children id's of node. undefined means that node is leaf, null means that children must be requested.*/
    childrenIds?: string[] | null,
    /** For branched nodes means expanded state */
    expanded?: boolean,
    /** If this node is selected*/
    active?: boolean,
    /** If ctrl click was pressed. undefined - select mode off,false - not selected,true - selected */
    selected?: boolean
    /** Display indicator that node is loading. That means NOT ONLY children loading but any background activity with node */
    loading?: boolean,
    /** Display indicator that node fetch was failed. */
    error?: boolean,
    /** Node have been changed (may be connected source was changed) */
    changed?: boolean,
    /** Node is being edited */
    editing?: boolean,
    /** Mark that node could be edited inplace */
    inplace?: boolean,
    /** Mark node as hidden */
    hidden?: boolean,
    /** Toggle inplace on change from false to true (see 'inplace' property) */
    edgeTriggerInplace?: boolean,
    /** Node data (serializable!) used for drag-n-drop, copy-n-paste, context menu. If not defined { id } will be used */
    data?: any,
    /** Context menu id trigger*/
    menuId?: string,
    /** Enable dragging by specifing drag type*/
    dragType?: string,
    /** Enable droping by specifing drop types */
    dropTypes?: string[],
    /** Handle droping by specifing acceptable paste node types */
    pasteTypes?: string[],
    uploadTypes?: string[],
    pathParam?: string,
    searchParams?: { [NAME: string]: string }
    contextPath?: string
    /** Name of icon */
    icon?: string | null
    /** Color of icon */
    iconColor?: string | null
    /** Special class for icon */
    iconClass?: string | null
    /** Id of node automation decorator */
    decorator?: string
    /** Id of node automation reverse decorator */
    reverseDecorator?: string
    /** Tree filter (if exists) */
    treeFilter?: TreeHeaderFilter
    /** If current user have super user rights */
    isSuperUser?: boolean
    /** Component used to render children */
    renderComponent?: ComponentClass<NodeProps> | ConnectedComponent<any, any>,
    /** Function that will be called to load children of the node */
    loadChildren?: () => void,
    /** Toggle node state to specified state*/
    toggle?: (expanded: boolean) => void,
    /** Connecting function should handle this link and modyfi it to change search/hash of location*/
    linkHandle?: (link: string) => void,
    /** Make this node active (selected)*/
    activate?: (data: any) => void,
    /** Ctrl + click handler */
    select?: () => void,
    /** Shift + click handler */
    range?: () => void,
    readonly?: boolean,
    /*To do smth while right click has been done*/
    contextMenu?: () => void,
    /** Change inplace text */
    editInplace?: (text: string) => void
    /**Called when item is dropped on node*/
    drop?: (data: object, isCopy: boolean) => void
    upload?: (acceptedFiles: File[]) => void
    /** Callback function that will be called after node selection */
    selectHandler?: (data: any) => void
    /** Function that changing container scroll position to display node */
    scrollToNode?: (data: { elm: Element | null }) => void
    /** Callback function that will be called after child update */
    sendDecorationsToParent?: (nodeId: string, decorations?: NodeDecorations) => void
}

/**
 * Properties of List Tree Node decorations 
 */
export interface NodeDecorations {
    color?: string
    iconColor?: string
    icon?: string
    hidden?: boolean
    textStyle?: React.CSSProperties
    iconStyle?: React.CSSProperties
}

/**Propertied of List Tree */
export interface TreeProps {
    /**Additional className */
    className?: string
    /**Context menu id */
    menuId?: string
    /**Tree ID. May be used to identify redux store data location.*/
    treeId: string,
    /**Root ids of tree*/
    roots?: string[],
    /**Display indicator that tree is loading*/
    loading?: boolean,
    readonly?: boolean
    /**Component used to render*/
    renderComponent?: ComponentClass<NodeProps> | ConnectedComponent<any, NodeProps>,
    /** Function that will be called to load roots */
    loadRoots?: () => void
    /** Callback function that will be called after node selection */
    selectHandler?: (data: any) => void
    /** Function that changing container scroll position to display node */
    scrollToNode?: (data: { elm: Element | null }) => void
}


/**
 * Actions
 */
// export interface SendTreeLoading extends Action {
//     type: constants.SEND_TREE_LOADING
//     treeId: string
//     payload: {
//         parentNodeId?: string
//     }
// }

// export interface SendTreeNodes extends Action {
//     type: constants.SEND_TREE_NODES
//     treeId: string
//     payload: {
//         nodes: TreeNode[] | FetchError,
//         parentNodeId?: string
//     }
// }

// export interface SendTreeNodeToggle extends Action {
//     type: constants.SEND_TREE_NODE_TOGGLE
//     treeId: string
//     payload: {
//         nodeId: string,
//         value: boolean
//     }
// }

// export interface SendTreeNodeActive extends Action {
//     type: constants.SEND_TREE_NODE_ACTIVE
//     treeId: string
//     payload: {
//         nodeId: string
//     }
// }
export interface SendExpandTreeWithEditingSubjects extends Action {
    type: constants.SEND_EXPAND_TREE_WITH_EDITING_SUBJECTS
    payload: { nodes: string[], treeId: string }
}







export interface SendTreeHeader extends Action {
    type: constants.SEND_TREE_HEADER;
    payload: {
        path: string,
        header: TreeHeader
    }
}

export interface SendTreeNodes extends Action {
    type: constants.SEND_TREE_NODES;
    payload: {
        path: string,
        parentId?: string,
        nodes: TreeNode[] | FetchError
    }
}
export interface SendTreeNodeToggle extends Action {
    type: constants.SEND_TREE_NODE_TOGGLE;
    payload: {
        path: string,
        nodeId: string,
        expanded: boolean
    }
}
export interface SendTreeNodeActive extends Action {
    type: constants.SEND_TREE_NODE_ACTIVE;
    payload: {
        path: string,
        nodeId: string
    }
}
export interface SendTreeActiveNodeValid extends Action {
    type: constants.SEND_TREE_ACTIVE_NODE_VALID;
    payload: { isValid: boolean, path: string, id: string }
}
export interface SendTreeLoading extends Action {
    type: constants.SEND_TREE_LOADING;
    payload: { path: string, loading: boolean, id: string }
}
export interface SendTreeCompress extends Action {
    type: constants.SEND_TREE_COMPRESS;
    payload: { path: string }
}
export interface SendTreeNodesForceUpdate extends Action {
    type: constants.SEND_TREE_NODES_FORCE_UPDATE;
    payload: { path: string, nodesIdList: (string | null)[] }
}

export interface SendContextMenuInfo extends Action<constants.SEND_TREE_NODE_CTX_MENU_INFO> {
    payload: ContextMenuInfo
}

export interface SendTreeFilterChange extends Action<constants.SEND_TREE_FILTER_CHANGE> {
    payload: { path: string, value: string, checked: boolean }
}

export type TreeAction = SendTreeLoading
    | SendTreeHeader
    | SendTreeNodes
    | SendExpandTreeWithEditingSubjects
    | SendTreeNodeToggle
    | SendTreeCompress
    | SendTreeNodeActive
    | SendTreeActiveNodeValid
    | SendTreeNodesForceUpdate
    | SendContextMenuInfo
    | SendTreeFilterChange;

export function isTreeNode(x: TreeNode | FetchError | undefined): x is TreeNode {
    return typeof x != 'undefined' && typeof (x as TreeNode).id !== 'undefined';
}


export function isTree(x: TreeState | FetchError | undefined): x is TreeState {
    return typeof x != 'undefined' && typeof (x as TreeState).rootNodesIds != 'undefined';
}
export function isTreeNodes(x: TreeNode[] | FetchError | undefined): x is TreeNode[] {
    return typeof x != 'undefined' && Array.isArray(x);
}
