import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as d3 from 'd3';
import { tree } from 'd3-hierarchy';

import styles from "./Sitemap.module.css";
import { duration, levelDepth, margin, nodeSize } from '../../constants/sitemap';
import { diagonal } from '../../services/sitemap';

function getNodeClass(node: any) {
    if (node.data.href) {
        return `node ${styles.nptSitemapNode} ${styles.nptSitemapLinkNode}`;
    }
    if (node.data.isHidden) {
        return `node ${styles.nptSitemapNode} ${styles.nptSitemapHiddenNode}`;
    }
    return `node ${styles.nptSitemapNode}`;
}

function getlinkClass(link: any) {
    if (link.target && link.target.data.isHidden) {
        return `link ${styles.nptSitemapLink} ${styles.nptSitemapHiddenLink}`;
    }
    return `link ${styles.nptSitemapLink}`;
}

function parseHref(href?: string){
    if(!href){
        return null;
    }
    if(href[0] !== "/"){
        href = `/${href}`;
    }
    return href;
}

interface BodyElements {
    rootTree: any,
    svg: any,
    gContainer: any,
    gLink: any,
    gNode: any,
    i: number
}

interface BodyProps {
    root: any
}
const Body = React.memo((props: BodyProps) => {
    const ref = React.useRef(null);
    const history = useHistory();

    const [state, setState] = React.useState<BodyElements>({
        rootTree: null,
        svg: null,
        gContainer: null,
        gLink: null,
        gNode: null,
        i: 0
    });

    React.useEffect(() => {
        const rootTree = tree()
            .separation(function (a, b) { return (a.parent == b.parent ? 1 : 2) / a.depth; })
            .nodeSize([nodeSize, levelDepth]);
        const svg = d3.select(ref.current).append("svg");
        const gContainer = svg.append("g");
        const gLink = gContainer.append("g");
        const gNode = gContainer.append("g");
        setState({
            rootTree,
            svg,
            gContainer,
            gLink,
            gNode,
            i: 0
        })
    }, []);

    React.useEffect(() => {
        if (!props.root || !state.rootTree) {
            return;
        }
        update(props.root);
    }, [props.root, state]);

    const update = (source: any) => {

        const nodes = props.root.descendants().reverse();
        const links = props.root.links();

        state.rootTree(props.root);

        let top = props.root;
        let bottom = props.root;
        props.root.eachBefore((node: any) => {
            if (node.x < top.x) top = node;
            if (node.x > bottom.x) bottom = node;
        });

        const width = props.root.sizes.width + margin.right + margin.left;
        const height = bottom.x - top.x + margin.top + margin.bottom;

        const svgRect = (ref.current as any).getBoundingClientRect();

        const svgWidth = svgRect.width > width ? svgRect.width : width;
        const svgHeight = svgRect.height > height ? svgRect.height : height;

        state.svg
            .attr("width", svgRect.width > svgWidth ? svgRect.width : svgWidth)
            .attr("height", svgRect.height > svgHeight ? svgRect.height : svgHeight);

        const xShift = margin.left;
        let yShift = -top.x + margin.top;
        if (svgHeight > height) {
            yShift += (svgHeight - height) / 2;
        }

        state.gContainer
            .attr("transform", (d: any) => `translate(${xShift},${yShift})`)

        // Update the nodes…
        const node = state.gNode.selectAll("g.node")
            .data(nodes, (d: any) => d.id);

        // Enter any new nodes at the parent's previous position.
        const nodeEnter: any = node.enter().append("g")
            .attr("class", getNodeClass)
            .attr("transform", function (d: any) { return "translate(" + source.y0 + "," + source.x0 + ")"; })
            .on("click", (event: any, d: any) => {
                if (d.data.href) {
                    return;
                }
                if (d.children) {
                    d._children = d.children;
                    d.children = null;
                } else {
                    d.children = d._children;
                    d._children = null;
                }
                update(d);
            });

        nodeEnter.append("circle");

        nodeEnter.append("a")
            .attr("href", function (d: any) { return parseHref(d.data.href) })
            .append("text")
            .attr("x", function (d: any) { return d.data.href ? 10 : -10; })
            .attr("y", function (d: any) { return (-d.data.name.length / 2 + 0.85) + "em"; })
            .attr("text-anchor", function (d: any) { return d.data.href ? "start" : "end"; })
            .attr("href", function (d: any) { return d.data.href ? d.data.href : ""; })
            .style("fill-opacity", 1e-6)
            .selectAll().data(function (d: any) { return d.data.name || []; })
            .enter().append('tspan')
            .attr("x", function (d: any) { return d.x; })
            .attr("dy", function (d: any, i: any) { return (i == 0 ? 0 : 1) + "em" })
            .text(function (d: any) { return d.text; });

        // Transition nodes to their new position.
        const nodeUpdate = node.merge(nodeEnter).transition()
            .duration(duration)
            .attr("class", getNodeClass)
            .attr("transform", function (d: any) { return "translate(" + d.y + "," + d.x + ")"; });

        nodeUpdate.select("circle")
            .attr("r", 4.5);

        nodeUpdate.select("text")
            .style("fill-opacity", 1);

        // Transition exiting nodes to the parent's new position.
        const nodeExit = node.exit().transition()
            .duration(duration)
            .attr("transform", function (d: any) { return "translate(" + source.y + "," + source.x + ")"; })
            .remove();

        nodeExit.select("circle")
            .attr("r", 1e-6);

        nodeExit.select("text")
            .style("fill-opacity", 1e-6);

        // Update the links…
        const link = state.gLink.selectAll("path.link")
            .data(links, function (d: any) { return d.target.id; });

        // Enter any new links at the parent's previous position.
        const linkEnter: any = link.enter().append("path")
            .attr("class", getlinkClass)
            .attr("d", function (d: any) {
                var o = { x: source.x0, y: source.y0 };
                return diagonal({ source: o, target: o });
            });

        // Transition links to their new position.
        link.merge(linkEnter).transition()
            .duration(duration)
            .attr("d", diagonal);

        // Transition exiting nodes to the parent's new position.
        link.exit().transition()
            .duration(duration)
            .attr("d", function (d: any) {
                var o = { x: source.x, y: source.y };
                return diagonal({ source: o, target: o });
            })
            .remove();

        // Stash the old positions for transition.
        nodes.forEach(function (d: any) {
            d.x0 = d.x;
            d.y0 = d.y;
        });

    }

    return <div className={styles.nptSitemapBody} ref={ref}></div>;
});

export default Body;