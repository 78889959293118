import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import shortid from 'shortid';
interface InplaceProps {
    text: string
    id: string
    style?: CSSProperties
    className?: string
    change: (e: any) => void
}

export const Inplace: FC<InplaceProps> = ({ text, style, id, className, change }) => {
    const [edit, setEdit] = useState(false)


    const hideInplace = (e: any) => {
        if (!e.target.closest(`#${id}`) && edit) {
            setEdit(false);
        }
    }

    useEffect(() => {
        document.addEventListener("click", hideInplace)
        return () => document.removeEventListener("click", hideInplace)
    })
    const renderText = () => {
        if (edit) {
            return <Form.Control autoFocus id={id} size="sm" style={{ width: 'unset' }} value={text} onChange={change} />
        }
        return <span onClick={showInput} style={{ cursor: 'pointer' }} className={className}>{text}</span>
    }

    const showInput = () => {
        if (!edit) {
            setEdit(true)
        }
    }

    return renderText()
}