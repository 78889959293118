import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import shortid from 'shortid';
import { closeModal, openModal, updateModalTitle } from '../../actions/modal';
import { MODAL_STATUS_CLOSE } from '../../constants/modal';
import { ApplicationAction, ApplicationState } from '../../types';
import { CancelCallback, CloseCallback, I18NString, ModalInfo, ModalOptions, OkCallback, PortalCallback, PortalOptions } from '../../types/modal';

export interface ModalProps extends PortalOptions {
    modal: string
    modalList: ModalInfo[]
    delegate?: React.ComponentType<any>
    openModal: (
        id: string,
        type: string,
        options: ModalOptions,
        okCallback?: OkCallback,
        cancelCallback?: CancelCallback,
        closeCallback?: CloseCallback,
        portalCallback?: PortalCallback) => void
    closeModal: (id: string) => void
    updateModalTitle: (id: string, title?: string | I18NString) => void
    [P: string]: any
}

export interface ModalState {
    domNode: Element | null
    //Some modal result that could used in views as modal state (filled with props.data as initial value)
    result: any
    //If modal result is valid (TODO: move into the state)
    valid: boolean
}

class Modal extends React.Component<ModalProps, ModalState> {

    private extraProps?: object

    constructor(props: ModalProps) {
        super(props)
        this.state = {
            domNode: null,
            result: typeof props.data != 'undefined' ? props.data : null,
            valid: true
        }
    }

    getModalId = () => {
        return this.props.id || this.props.modal
    }

    componentDidMount(): void {
        const id = this.getModalId();
        if (typeof id == 'string') {
            registerNamedModal(id, this);
        }
    }

    componentWillUnmount(): void {
        const id = this.getModalId();
        if (typeof id == 'string') {
            unregisterNamedModal(id)
        }
    }

    componentDidUpdate(prevProps: ModalProps) {
        if (this.props.title != prevProps.title) {
            const id = this.getModalId()
            if (typeof id == 'string') {
                this.props.updateModalTitle(id, this.props.title)
            }
        }
    }

    //Hack: okCallback wrapper to pass modal result
    handleOk = () => {
        if (!this.state.valid) {
            console.error("RESULT IS NOT VALID");
            return
        }
        if (this.props.onOk) {
            this.props.onOk(this.state.result)
        }
    }

    open = (extraProps?: object): boolean => {
        const { onOk, onCancel, onClose, modalList, delegate, ...modalOptions } = this.props
        const id = this.props.id || this.props.modal || shortid.generate()
        for (let modal of modalList) {
            if (modal.id == id) {
                console.error("Modal is already openned");
                return false
            }
        }
        this.extraProps = extraProps
        const title = (extraProps as any)?.title              
        if (typeof this.props.title == 'undefined' && typeof title  == 'string') {
            modalOptions.title = title
        }
        this.props.openModal(id, "portal", modalOptions, this.handleOk, onCancel, onClose, this.setPortal);
        return true
    }

    close = () => {
        const id = this.props.id || this.props.modal
        if (typeof id == 'string') {
            this.props.closeModal(this.props.id)
        } else {
            console.error("Cannot close modal without id");
        }
    }

    setPortal = (container: Element) => {
        this.setState({ domNode: container })
    }

    setModalResult = (result: any, validOrUndefined?: boolean) => {
        const valid: boolean = typeof validOrUndefined == 'boolean' ?
            validOrUndefined : result != null;
        this.setState({ result, valid })
    }

    render() {
        const domNode = this.state.domNode
        if (!domNode) {
            return null
        }
        const modalProps = {
            ...this.props, //Pass all modal properties
            modalResult: this.state.result,
            setModalResult: this.setModalResult,
        }
        //Extra props have priority over modal props!
        const extraProps = this.extraProps ? { ...modalProps, ...this.extraProps } : modalProps;
        if (this.props.delegate) {
            const C = this.props.delegate
            return ReactDOM.createPortal(
                <C {...extraProps} />,
                domNode);
        }
        let el = React.Children.only(this.props.children);
        if (React.isValidElement<any>(el)) {
            el = React.cloneElement(el, extraProps)
        }
        return ReactDOM.createPortal(el, domNode);
    }
}

export default connect((state: ApplicationState) => {
    return {
        modalList: state.modal.modalList
    };
}, (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
    return {
        openModal: (id: string,
            type: string,
            options: ModalOptions,
            okCallback?: OkCallback,
            cancelCallback?: CancelCallback,
            closeCallback?: CloseCallback,
            portalCallback?: PortalCallback) =>
            dispatch(openModal(id, type, options, okCallback, cancelCallback, closeCallback, portalCallback)),
        closeModal: (id: string) => dispatch(closeModal(id, MODAL_STATUS_CLOSE)),
        updateModalTitle: (id: string, title?: string | I18NString) => dispatch(updateModalTitle(id, title))
    }
})(Modal);

///////////////////////////////////////////
//PUBLIC API TO OPERATE WITH NAMED MODALS//
///////////////////////////////////////////
export type NamedModalRegistry = { [ID: string]: Modal }

export function isNamedModalRegistry(x: any): x is NamedModalRegistry {
    return typeof x == 'object' && x != null;
}

export function registerNamedModal(id: string, modal: Modal) {
    const namedModals = (window as any).__NPT__?.namedModals
    if (!isNamedModalRegistry(namedModals)) {
        console.error("No named modal registry: __NPT__.namedModals");
        return
    }
    namedModals[id] = modal
}

export function unregisterNamedModal(id: string) {
    const namedModals = (window as any).__NPT__?.namedModals
    if (!isNamedModalRegistry(namedModals)) {
        console.error("No named modal registry: __NPT__.namedModals");
        return
    }
    delete namedModals[id]
}

export function isRegisteredNamedModal(id: string): boolean {
    const namedModals = (window as any).__NPT__?.namedModals
    if (!isNamedModalRegistry(namedModals)) {
        console.error("No named modal registry: __NPT__.namedModals");
        return false
    }
    const modal = namedModals[id]
    return typeof modal != 'undefined';
}

export function openNamedModal(id: string, extraProps?: { [PROP: string]: any }): boolean {
    const namedModals = (window as any).__NPT__?.namedModals
    if (!isNamedModalRegistry(namedModals)) {
        console.error("No named modal registry: __NPT__.namedModals");
        return false
    }
    const modal = namedModals[id];
    if (!modal) {
        console.error("No named modal: " + id);
        return false
    }
    return modal.open(extraProps)
}

