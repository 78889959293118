import { Action } from 'redux';
import * as c from '../constants/selection'

export interface SelectionInfo {
    object?: string
    type?: string
}

export interface SelectionState {
    info?: SelectionInfo
    history?: {
        past: (SelectionInfo | undefined)[],
        present: SelectionInfo | undefined,
        future: (SelectionInfo | undefined)[]
    }
}

export interface SendSelection extends Action {
    type: c.SEND_SELECTION;
    payload: {
        object: string,
        type: string
    }
}

export interface SendSelectionUndo extends Action {
    type: c.SEND_SELECTION_UNDO;
    payload: null
}

export interface SendSelectionRedo extends Action {
    type: c.SEND_SELECTION_REDO;
    payload: null
}

export type SelectionAction = SendSelection | SendSelectionUndo | SendSelectionRedo;

export const createObject = (rdfId: string, namespace?: string) => {
    return namespace ? `${namespace}:${rdfId}` : rdfId
}