import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { FetchError } from '../../types/error';
import { TreeNode } from '../../types/tree';

import './Breadcrumbs.module.css';

interface BreadcrumbsProps {
    nodeById: { [k: string]: TreeNode }
    active?: string | null
    error?: FetchError
    errorId?: string
    messageId?: string
    loading?: boolean
    selectNode: (node: TreeNode) => void
}
class Breadcrumbs extends React.PureComponent<BreadcrumbsProps> {

    constructor(props: BreadcrumbsProps) {
        super(props);

        this.click = this.click.bind(this);
    }

    getItems(active: string) {
        const items: TreeNode[] = [];
        const parsedNodes: { [k: string]: boolean } = {};
        let node = this.props.nodeById[active];
        while (node && !parsedNodes[node.id]) {
            items.push(node);
            parsedNodes[node.id] = true;
            if (!node.parentId) {
                break;
            }
            node = this.props.nodeById[node.parentId];
        }
        return items.reverse();
    }

    click(item: TreeNode, event: React.MouseEvent) {
        event.preventDefault();
        this.props.selectNode(item);
    }

    render() {
        if (this.props.error || this.props.errorId) {
            return <Error error={this.props.error} errorId={this.props.errorId} />;
        }
        if (this.props.messageId) {
            return <Message messageId={this.props.messageId} />;
        }
        if (this.props.loading) {
            return <Loading />;
        }
        if (!this.props.active) {
            return <Empty />;
        }
        return <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {this.getItems(this.props.active).map((item, idx) => <li key={idx} className="breadcrumb-item">
                    <a href="#" onClick={this.click.bind(this, item)}>{item.label}</a>
                </li>)}
            </ol>
        </nav>;
    }
}

interface MessageProps {
    messageId: string
}
class Message extends React.PureComponent<MessageProps> {

    render() {
        let messageText: string | ReactElement<any, any> = "";
        if (this.props.messageId) {
            messageText = <FormattedMessage id={this.props.messageId} />;
        }
        return <nav aria-label="breadcrumb">
            <ol className="breadcrumb">{messageText}</ol>
        </nav>;
    }
}

interface ErrorProps {
    error?: FetchError
    errorId?: string
}
class Error extends React.PureComponent<ErrorProps> {

    render() {
        let errorText: string | ReactElement<any, any> = "Error";
        if (this.props.errorId) {
            errorText = <FormattedMessage id={this.props.errorId} />;
        } else if (this.props.error) {
            errorText = <span>{`Error ${this.props.error.code}: ${this.props.error.message}`}</span>;
        }
        return <div className="alert alert-danger m-0" role="alert">{errorText}</div>
    }
}

const Loading = () => (<div className="alert alert-info m-0" role="alert">
    <FormattedMessage
        id="BREADCRUMBS_LOADING"
        defaultMessage="Loading..."
        description="Corresponding tree is loading" />
</div>);

const Empty = () => (<div className="alert alert-info m-0" role="alert">
    <FormattedMessage
        id="BREADCRUMBS_SELECT_NODE"
        defaultMessage="Select node in corresponding tree"
        description="Corresponding tree doesn't have active node" />
</div>);

export default Breadcrumbs;


