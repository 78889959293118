export  function Deferred() {
    let thens:any[] = []
    let catches:any[] = []
  
    let status:string
    let resolvedValue:any
    let rejectedError:any
  
    return {
      resolve: (value:any) => {
        status = 'resolved'
        resolvedValue = value
        thens.forEach(t => t(value))
        thens = [] // Avoid memleaks.
      },
      reject: (error:any) => {
        status = 'rejected'
        rejectedError = error
        catches.forEach(c => c(error))
        catches = [] // Avoid memleaks.
      },
      then: (cb:(val:any)=>void) => {
        if (status === 'resolved') {
          cb(resolvedValue)
        } else {
          thens.unshift(cb)
        }
      },
      catch: (cb:(val:any)=>void) => {
        if (status === 'rejected') {
          cb(rejectedError)
        } else {
          catches.unshift(cb)
        }
      },
    }
  }
  