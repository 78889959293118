import { NodeMap, TreeNodeState } from "../types/tree";

 

export function isInRange (nodes:NodeMap, r1: string, r2: string) {
    const p1 = nodes[r1].parentId;
    const p2 = nodes[r2].parentId;
    return (typeof p1 === undefined && typeof p2 === undefined) || p1 === p2;
}
export function createRange<T extends TreeNodeState>(state: T, id1: string, id2: string) {
    const roots = state.rootNodesIds;
    const children = state.childrenIds;
    const nodes = state.nodeById;

    if (!nodes || !roots) {
        return [];
    }

    let branchNodes: string[] = [];
    const commParent = nodes[id1].parentId;
    if (!commParent) {
        branchNodes = roots;
    } else if (commParent && children) {
        branchNodes = children[commParent] || [];
    }

    const range: string[] = [];
    for (const id of branchNodes) {
        if (range.length === 0) {
            (id === id1 || id === id2) && range.push(id);
        } else {
            range.push(id);
            if (id === id1 || id === id2) {
                break;
            }
        }
    }
    return range;
}
export function isOver<T extends TreeNodeState>(state: T, id1: string, id2: string) {
    const roots = state.rootNodesIds;
    const children = state.childrenIds;
    const nodes = state.nodeById;

    if (!nodes || !roots) {
        return [];
    }

    let branchNodes: string[] = [];
    const commParent = nodes[id1].parentId;
    if (!commParent) {
        branchNodes = roots;
    } else if (commParent && children) {
        branchNodes = children[commParent] || [];
    }

    const range: string[] = [];
    let isOver = true;
    for (const id of branchNodes) {
        if (id2 === id && isOver) {
            isOver = false; break;
        } else if (id1 === id && isOver) {
            break;
        }
    }
    return range;
}

export function newRange<T extends TreeNodeState>(first: string, last: string, ranged: string, state: T, pivotPoint?: string) {
    const nodes = state.nodeById;
    if (!nodes) {
        return [];
    }
    if (isInRange(nodes, pivotPoint || first, ranged) && isOver(state, ranged, pivotPoint || first)) {
        return createRange(state, pivotPoint || first, ranged);
    } else if (!isOver(state, ranged, pivotPoint || last)) {
        return createRange(state, pivotPoint || last, ranged);
    } else if (!isOver(state, ranged, first) && isOver(state, ranged, last)) {
        return createRange(state, pivotPoint || first, ranged);
    }
    return [];
}


export  function createSelected(selectedList: string[]|null,active:string|null ,selected:string|null){ 
    if (!selected) { 
        return undefined ;
    }
    if (!selectedList) {
        selectedList = [];
        if (active && !selectedList.includes(active)) {
            selectedList.push(active);
        }
    }
    if (selectedList.includes(selected)) {
        selectedList = selectedList.filter(i => i !== selected);
    } else {
        selectedList.push(selected);
    }
    if (selectedList.length === 0) {
        selectedList = null;
    } 
    return selectedList;
}


export function setupRange<T extends TreeNodeState>(ranged:string|null,tree:T){
    const newTreeState = {...tree}; 
    const nodes = newTreeState.nodeById;
    let selectedList = newTreeState.selected;
    let active = newTreeState.active;
    let pivotPoint = newTreeState.pivotPoint;
    if (!ranged || !nodes) {
        newTreeState.selected = undefined;
        return  newTreeState 
    }
    if (!selectedList || selectedList.length === 0) {
        selectedList = [];
        if (active) {
            isInRange(nodes, active, ranged) ? (selectedList = createRange(newTreeState, active, ranged)) :
                (selectedList.push(ranged))
            pivotPoint = active;
        } else {
            pivotPoint = ranged;
            selectedList.push(ranged);
        }
    }
    else {
        if (selectedList.length > 1) {
            const first = selectedList[0];
            const last = selectedList[selectedList.length - 1];
            selectedList = newRange(first, last, ranged, newTreeState, pivotPoint);
        } else {
            if (isInRange(nodes, selectedList[0], ranged)) {
                pivotPoint = selectedList[0];
                selectedList = createRange(newTreeState, selectedList[0], ranged);
            } else {
                selectedList = [ranged];
                pivotPoint = selectedList[0];
            }
        }
    }

    if (selectedList.length === 1 && selectedList[0] === ranged) {
        selectedList = [ranged];
    }
    newTreeState.selected = selectedList;
    newTreeState.pivotPoint = pivotPoint;
    return newTreeState;
}