import React from 'react';
import ViewMenu, { ViewLogoInfo } from '../view/ViewMenu';

interface ToolbarProps {
    logo?: ViewLogoInfo
}
const Toolbar: React.FunctionComponent<ToolbarProps> = React.memo((props) => {
    return <ViewMenu logoInfo={props.logo}>
        {props.children}
    </ViewMenu>;
});

export default Toolbar;