import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { RouteComponentProps, StaticContext } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

export type DirectionType = "right" | "down" | "left" | "up";

interface CustomToggleProps {
  clsName?: string;
  style?: React.CSSProperties;
  children: any;
  direction: DirectionType;
  key: string;
  badge?: any;
  onClick: (e: any) => void;
  toggle: () => void;
}
//  dropdown-toggle nav-link
const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: any) => {
  let caretIcon = faCaretDown;
  if (props.direction === "left") {
    caretIcon = faCaretLeft;
  } else if (props.direction === "right") {
    caretIcon = faCaretRight;
  }
  return (
    <a
      key={props.key}
      style={{ color: "inherit" }}
      href="#"
      ref={ref}
      className={`  d-flex flex-row justify-content-between h-100 ${props.clsName} over-drop-item-title`}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
        props.toggle();
      }}
    >
      <span className="d-flex flex-column justify-content-center">
        {props.children}
      </span>
      {props.badge && (
        <span className="d-flex ml-1 flex-column justify-content-center">
          {props.badge}
        </span>
      )}

      {/* <span>{props.children}</span> */}
      <FontAwesomeIcon className="  ml-1" icon={faCaretDown} />
    </a>
  );
});

interface CustomMenuProps {
  children: any;
  className?: string;
  position?: SubmenuPosition | null;
  toggleHeight?: number;
  key: string;
}

const CustomMenu = React.forwardRef((props: CustomMenuProps, ref: any) => {
  const { className, children, position, key, toggleHeight } = props;
  let positionStyle: any = position ? position : { top: "20px" };
  const ulStyle: React.CSSProperties = {
    maxHeight: "30rem",
    overflowY: "auto",
    position: "initial",
  };
  const wrapperStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: 1000,
    backgroundColor: "inherit",
  };
  return (
    <div
      key={key}
      ref={ref}
      className="wrapper   rounded shadow-sm over-drop-submenu"
      style={{ ...wrapperStyle, ...positionStyle, width: "max-content" }}
    >
      <ul style={ulStyle} className={`${className} list-unstyled rounded     `}>
        {React.Children.toArray(children).map((ch, idx) => (
          <li key={idx}>{ch}</li>
        ))}
      </ul>
    </div>
  );
});

//dropdown
interface CustomDropdownProps {
  children: any;
  className?: string;
  clsName?: string;
  key: string;

  isSubMenu?: boolean;
}

const CustomDropdown = React.forwardRef(
  (props: CustomDropdownProps, ref: any) => {
    const { clsName: className, children } = props;
    if (props.isSubMenu) {
      return (
        <div
          key={props.key}
          className={`${className} npt-dropdown-item`}
          ref={ref}
        >
          {children}
        </div>
      );
    }
    return (
      <li
        key={props.key}
        className={`${className} npt-dropdown-item`}
        ref={ref}
      >
        {children}
      </li>
    );
  }
);

interface SubmenuPosition {
  right?: number;
  left?: number;
  top: number;
}

interface NavDropdownProperties
  extends RouteComponentProps<any, StaticContext, any> {
  children?: any;
  id: string;
  className?: string;
  style?: React.CSSProperties;
  title: React.ReactNode;
  // alignRight?: boolean;
  direction: DirectionType;
  bsPrefix?: string;
  to?: string;
  exact?: boolean;
  rootActiveEnable?: boolean;
  badge?: any;
  as?: React.ForwardRefExoticComponent<any>;
  root?: boolean;
  isSubMenu?: boolean;
}

interface NavDropdownState {
  active: boolean;
  submenuPosition: SubmenuPosition | null;
  toggleHeight?: number;
  isOpen: boolean;
  bodyRect: DOMRect | null;
}

//Class to render links in menu
class NavDropdownRenderer extends React.Component<
  NavDropdownProperties,
  NavDropdownState
> {
  private parentRef: React.RefObject<HTMLLIElement> = React.createRef();
  unlisten?: () => void;
  state = {
    active: false,
    submenuPosition: null,
    toggleHeight: undefined,
    isOpen: false,
    bodyRect: null,
  };
  linksMap: {
    [path: string]: boolean;
  } = {};

  constructor(props: NavDropdownProperties) {
    super(props);
  }

  registerChildren() {
    for (let child of this.props.children) {
      if (!child || !child.props) {
        continue;
      }
      this.linksMap[child.props.to] = true;
    }
    //Set state after links register
    this.setState({
      active: Boolean(this.linksMap[this.props.location.pathname]),
    });
  }

  onLocationChange(path: string) {
    //Set state to force rendering
    this.setState({ active: this.linksMap[path] });
  }

  componentDidMount() {
    this.registerChildren();
    const history = this.props.history;
    const onLocationChange = this.onLocationChange.bind(this);
    this.unlisten = history.listen((location, action) => {
      onLocationChange(location.pathname);
    });
    const bodyRect = document.body.getBoundingClientRect();
    this.setState({ bodyRect });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  componentDidUpdate() {
    if (this.state.isOpen && !this.state.submenuPosition) {
      const pr = this.parentRef;
      const { direction } = this.props;
      const { bodyRect } = this.state;
      let pos: SubmenuPosition | null = null;
      let toggleHeight: number | undefined;
      if (pr && pr.current && bodyRect) {
        toggleHeight = pr.current.offsetHeight;
        if (pr.current.offsetParent) {
          const parent = pr.current.offsetParent.children[0];
          const scroll = parent && parent.scrollTop;
          const prElem = pr.current;
          const top = scroll ? prElem.offsetTop - scroll : prElem.offsetTop;
          const x = prElem.offsetWidth;
          const rect = prElem.getBoundingClientRect();
          const submenu = prElem.children[1];
          const submenuRect = prElem.children[1]?.getBoundingClientRect();
          const bodyHeight = (bodyRect as any)?.height || 0;
          const bodyWidth = (bodyRect as any)?.width || 0;
          const submenuHeight = submenuRect?.height || 0;
          const submenuTop = top;
          const submenuBottom = submenuTop + (submenuRect?.bottom || 0);
          const submenuWidth = submenuRect?.width || 0;
          const bottomDelta = bodyHeight - submenuBottom;
          const rightDelta =
            bodyWidth - (submenuRect?.width || 0) - (submenuRect?.x || 0);
          pos = { left: 0, top: top + toggleHeight + 5 };

          if (direction === "right") {
            pos = { left: x + 5, top: top };
          }
          if (direction === "left") {
            pos.left = -submenuWidth;
            pos.top = top;
          }

          if (bottomDelta < 0) {
            pos.top += bottomDelta;
          }

          if (rightDelta < 0 && direction === "down") {
            pos = { ...pos, left: (pos.left || 0) + rightDelta - 10 };
          }
        }
      }
      this.setState({ submenuPosition: pos, toggleHeight });
    }
  }
  toggleOpen = (isOpen: boolean) => {
    const bodyRect = isOpen ? document.body.getBoundingClientRect() : null;
    this.setState({
      isOpen,
      submenuPosition: !isOpen ? null : this.state.submenuPosition,
      bodyRect,
    });
  };
  render() {
    const {
      title,
      children,
      className,
      id,
      to,
      root,
      direction,
      style,
      badge,
      rootActiveEnable,
    } = this.props;
    const { active, submenuPosition: pos, toggleHeight, isOpen } = this.state;

    const ToggleElement = this.props.as || CustomToggle;
    let activeClass = "";
    if (active) {
      activeClass = root
        ? "npt-active-root-dropdown"
        : "npt-active-child-dropdown";
    }
    return (
      <Dropdown
        key={`${id}_drp`}
        onToggle={(isOpen: boolean) => {
          this.toggleOpen(isOpen);
        }}
        clsName={` over-drop-item ${rootActiveEnable ? activeClass : ""} ${
          isOpen ? "show" : ""
        }`}
        ref={this.parentRef as any}
        isSubMenu={this.props.isSubMenu}
        as={CustomDropdown}
      >
        {/* <Dropdown.Toggle direction={direction} style={style} active key={`${id}_toggle`} variant="link" clsName={className} toggle={() => this.toggleOpen(!isOpen)} as={ToggleElement} id="dropdown-custom-components"> */}
        <Dropdown.Toggle
          badge={badge}
          direction={direction}
          style={style}
          active
          key={`${id}_toggle`}
          variant="link"
          clsName={className}
          toggle={() => this.toggleOpen(!isOpen)}
          as={ToggleElement}
          id="dropdown-custom-components"
        >
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu
          toggleHeight={toggleHeight}
          className="npt-dropdown-menu"
          position={pos}
          key={`${id}_menu`}
          as={CustomMenu}
        >
          {children}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export const OverflowDropDown = withRouter(NavDropdownRenderer);
