import React from 'react';
import { CancelCallback, CloseCallback, ModalOptions, OkCallback } from '../../types/modal';
import { AlertLevelType } from '../../types/alert';
import { SearchData } from '../../types/location';

export interface ViewContextProps {
    setTitle: (title: string) => void
    onAction: (bindInfo: { action: string }, handler: () => void) => void
    onSelection: (handler: (selection: { object?: string, type?: string }) => void) => void
    select: (object: string, type: string) => void
    onSave: (handler: (data?: any) => void) => void
    save: (data?: any) => void
    onTriggerSave: (handler: (callback: (result: boolean) => void, data?: any) => void) => void
    triggerSave: (callback: (result: boolean) => void, data?: any) => void
    onChange: (handler: (data?: any) => void) => void
    change: (data?: any) => void
    onPageUpdate: (handler: () => boolean) => void
    getParams: () => SearchData,
    getSelection: () => { object?: string, type?: string },
    openModal: (type: string, options: ModalOptions, okCallback?: OkCallback, cancelCallback?: CancelCallback, closeCallback?: CloseCallback) => void,
    addAlert: (message: string, options?: { type?: AlertLevelType, icon?: string, dissmissable?: boolean, html?: boolean }) => void
}

export const ViewContext = React.createContext<ViewContextProps | null>(null);

