import { SourceEditorTreeData, SourceEditorTreeState } from "./sourceeditor";
import * as c from '../constants/menueditor'
import { Action } from "redux";
import { CtxMenuId } from "../constants/menueditor";
import { ServerError } from "../actions/utils";
import { EnumerationInfo } from "./subject";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { UIVariant } from "./security";


export function getDividerType(strDivider: string) {
    switch (strDivider.toLowerCase()) {
        case 'before': return c.DividerType.BEFORE
        case 'after': return c.DividerType.AFTER
        case 'both': return c.DividerType.BOTH
        default: return null;
    }
}




export interface ContextMenuInfo {
    nodeId?: string
    id: CtxMenuId
}

export interface RawLogo {
    href: string
    lang?: string
}
export interface RawTitle {
    // value?:string
    value: string
    lang: string
}
export interface RawMenuItem {
    logo?: RawLogo[]
    title?: RawTitle[]
    menu?: RawMenuItem[]
    id?: string
    name?: string
    hidden?: boolean
    href?: string
    divider?: string
    icon?: string
    acl?: string
    parentId?: string
}

export interface Title {
    // [LANG: string]: string|undefined
    [LANG: string]: string
}
export interface Logo {
    [LANG: string]: string
}

export interface MenuItem {
    //Name
    n?: string,
    //Hidden
    h?: boolean,
    //Reference
    r?: string,
    //Divider
    d?: c.DividerType,
    //Icon
    i?: string,
    //Title language -> title
    t?: Title,
    l?: Logo,
    id?: string,
    a?: string[],
    v?:UIVariant
    parentId?: string
}

export interface MenuData extends MenuItem {
    //Root menu item ids
    roots: string[],
    mainNode: MenuItem,
    //id -> children ids
    childrenById?: { [ID: string]: string[] },
    //id -> menu item
    itemById: { [ID: string]: MenuItem },
}

export interface Acl {
    namespace: string
    className: string
    rdfId: string
    label: string
}
export interface AclMap {
    [RDFID: string]: Acl
}

export interface SourceCodeState {
    loading: boolean
    code: string
    itemId: string
}

export interface EditorState extends MenuData {
    loading?: boolean
    expanded?: { [ID: string]: boolean }
    active?: { [ID: string]: boolean }
    contextMenu?: ContextMenuInfo
    operation?: c.MenuOperations
    aclMap?: AclMap
    sourceCode?: SourceCodeState
    path?: string
}

export interface MenuMap{
    [PATH:string]:EditorState
}

export interface MenuEditorState {
    editorState: MenuMap    
    menuActive?:string
}

 
export interface SendMenuPathActive extends Action<c.SEND_MENU_EDITOR_PATH_ACTIVE> {
    payload: string
}
export interface SendMenuLoading extends Action<c.SEND_MENU_EDITOR_LOADING> {
    payload: {loading:boolean,path:string}
}
export interface SendMenuEditorMenuToggle extends Action<c.SEND_MENU_EDITOR_MENU_TOGGLE> {
    payload: { id: string, toggled: boolean ,path:string}
}

export interface SendMenuEditorMenuActive extends Action<c.SEND_MENU_EDITOR_MENU_ACTIVE> {
    payload: {id:string,path:string}
}
export interface SendMenuEditorMenu extends Action<c.SEND_MENU_EDITOR_MENU> {
    payload: { menu: MenuData, path: string }
}
export interface SendMenuEditorEditingMenu extends Action<c.SEND_MENU_EDITOR_EDITING_MENU> {
    payload: string
}
export interface SendMenuEditorSaveMenu extends Action<c.SEND_MENU_EDITOR_SAVE_MENU> {
    payload: {
        menu: MenuItem
    }
}
export interface SendMenuEditorSaveMenuItem extends Action<c.SEND_MENU_EDITOR_SAVE_MENU_ITEM> {
    payload: {
        menu: MenuItem
        path: string,
    }
}
export interface SendMenuEditorRemoveMenu extends Action<c.SEND_MENU_EDITOR_REMOVE_MENU> {
    payload: null
}
export interface SendMenuEditorOperation extends Action<c.SEND_MENU_EDITOR_OPERATION> {
    payload:{operation:c.MenuOperations | undefined,path:string}
}
export interface SendMenuEditorCtxMenuList extends Action<c.SEND_MENU_EDITOR_CTX_MENU_LIST> {
    payload: ContextMenuInfo
}
export interface SendMenuEditorMenuActiveAll extends Action<c.SEND_MENU_EDITOR_MENU_ACTIVE_ALL> {
    payload:{isActive:boolean,path:string}
}
export interface SendMenuEditorCtxMenuEditor extends Action<c.SEND_MENU_EDITOR_CTX_MENU_EDITOR> {
    payload: {ctxMenu:ContextMenuInfo,path:string}
}
export interface SendMenuEditorClearMenu extends Action<c.SEND_MENU_EDITOR_CLEAR_MENU> {
    payload: null
}
export interface SendMenuEditorAclList extends Action<c.SEND_MENU_EDITOR_ACL_LIST> {
    payload: {aclMap:EnumerationInfo,path:string}
}
export interface SendMenuEditorFilteredFoldersSearch extends Action<c.SEND_MENU_EDITOR_FILTER_FOLDERS> {
    payload: string
}
export interface SendMenuEditorItemSourceCode extends Action<c.SEND_MENU_EDITOR_ITEM_SOURCE_CODE> {
    payload: { code: string, itemId: string }
}

export interface SendMenuEditorItemSourceCodeLoading extends Action<c.SEND_MENU_EDITOR_ITEM_SOURCE_CODE_LOADING> {
    payload: boolean
}

export interface SendMenuEditorRemoveItem extends Action<c.SEND_MENU_EDITOR_REMOVE_ITEM> {
    payload: {
        path: string,
        id: string,
        parentId?: string
    }
}
export interface SendMenuEditorCutItem extends Action<c.SEND_MENU_EDITOR_CUT_ITEM> {
    payload: {
        id: string,
        newParentId: string | null,
        menu: MenuData
    }
}
export interface SendMenuEditorCopyItem extends Action<c.SEND_MENU_EDITOR_COPY_ITEM> {
    payload: {
        id: string,
        newParentId: string | null,
        menu: MenuData
    }
}

export type MenuEditorAction =
    SendMenuLoading |
    SendMenuEditorMenu |
    SendMenuEditorMenuToggle |
    SendMenuEditorMenuActive |
    SendMenuEditorEditingMenu |
    SendMenuEditorSaveMenu |
    SendMenuEditorRemoveMenu |
    SendMenuEditorRemoveItem |
    SendMenuEditorCtxMenuList |
    SendMenuEditorCtxMenuEditor |
    SendMenuEditorClearMenu |
    SendMenuEditorCutItem |
    SendMenuEditorCopyItem |
    SendMenuEditorSaveMenuItem |
    SendMenuEditorMenuActiveAll |
    SendMenuEditorOperation |
    SendMenuEditorFilteredFoldersSearch |
    SendMenuEditorAclList |
    SendMenuEditorItemSourceCode |
    SendMenuEditorItemSourceCodeLoading;

export const isFullIconName = (iconName: string) => {
    return iconName.match(/(fa fa(-[a-zA-z0-9]+)+)/);
    //    return  iconName.match(/.*[\ \"\']+(fa-[a-zA-z0-9]*).*/);
}
//  .*[\ \"\']+(fa-[a-zA-z0-9]*)[^ ]*  exclude size( fa-5x)
export const extractFaFullName = (iconName: string) => {
    const nameList = iconName.match(/(fa fa(-[a-zA-z0-9]+)+)/);
    return nameList ? nameList[0] : null;
}
// (fa fa-[a-zA-z0-9][^ ]*)