import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from '../../types';
import { TreeHeaderFilter, TreeHeaderFilterOption } from '../../types/tree';
import { sendTreeFilterChange } from '../../actions/tree';

import styles from './ListTree.module.css';

interface TreeFilterProps {
    loading: boolean
    filter: TreeHeaderFilter | null
    onChange: (value: string, checked: boolean) => void
}
const TreeFilter: React.FunctionComponent<TreeFilterProps> = React.memo((props) => {
    if (props.loading || !props.filter) {
        return null;
    }
    return <div className={`${styles.nptTreeFilter} d-flex mx-1`}>
        <span>
            <FormattedMessage
                id="NAVTREE_FILTERS"
                defaultMessage="Filters:"
                description="Filters:" />
        </span>
        {props.filter.options.map((option, idx)=><TreeFilterItem key={idx} option={option} onChange={props.onChange}/>)}
    </div>;
});


interface TreeFilterItemProps {
    option: TreeHeaderFilterOption
    onChange: (value: string, checked: boolean) => void
}
const TreeFilterItem: React.FunctionComponent<TreeFilterItemProps> = React.memo((props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        props.onChange(props.option.value, event.target.checked);
    }
    return <span className="ml-2 d-flex align-items-center">
        <input className="mr-1" type="checkbox" checked={props.option.checked} onChange={onChange} />
        <span>{props.option.label}</span>
    </span>;
});

export default connect((state: ApplicationState, ownProps: { treeId: string }) => {
    const { treeId } = ownProps;
    const treeState = treeId && state.tree?.treeInfo?.[treeId];
    if (!treeState) {
        return {
            loading: false,
            filter: null
        }
    }
    const loadingState = treeState.loading;

    return {
        loading: loadingState && loadingState[treeId] || false,
        filter: treeState.header?.filter || null
    }
}, (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>, ownProps: { treeId: string }) => {
    const { treeId } = ownProps;
    return {
        onChange: (value: string, checked: boolean) =>{
            dispatch(sendTreeFilterChange(treeId, value, checked));
        }
    }
})(TreeFilter);