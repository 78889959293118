import * as React from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { BooleanFilterData } from '../../../types/table';

import EmptyCellsFilter from './EmptyCellsFilter';

import styles from '../Table.module.css';

interface BooleanFilterProps {
    filterData: BooleanFilterData | null
    onChange: (filterData: BooleanFilterData) => void
}
const BooleanFilter: React.FunctionComponent<BooleanFilterProps> = React.memo((props) => {
    const intl = useIntl();
    let propsSelected = true;
    if (props.filterData && props.filterData.selected === false) {
        propsSelected = false;
    }
    const propsAllowNulls = props.filterData ? props.filterData.allowNulls : false;

    const options = [{
        label: "--",
        value: null
    }, {
        label: intl.formatMessage({ id: "NPT_TABLE_FILTER_TRUE" }),
        value: true
    }, {
        label: intl.formatMessage({ id: "NPT_TABLE_FILTER_FALSE" }),
        value: false
    }];

    const [selected, setSelected] = React.useState(options.find(option => option.value === props.filterData?.selected) || options[0]);
    const [allowNulls, setAllowNulls] = React.useState<boolean>(propsAllowNulls);
    React.useEffect(() => {
        props.onChange({
            type: "boolean",
            selected: selected.value,
            allowNulls
        });
    }, [selected, allowNulls]);
    return <div className={`w-100 d-flex flex-column`}>
        <div className={`w-100 d-flex flex-column ${styles.nptFilterValues}`}>
            <Select
                className="npt-select"
                value={selected}
                options={options}
                onChange={(option) => setSelected(option || options[0])}
            />
        </div>
        <EmptyCellsFilter value={allowNulls} onChange={setAllowNulls} />
    </div>;
});

export default BooleanFilter;