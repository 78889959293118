import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  fetchDeleteItems,
  goToView,
  sendMenuEditorFilteredFoldersSearch,
  sendMenuEditorMenuActiveAll,
  sendMenuEditorOperation,
} from "../../../actions/menueditor";
import { CtxMenuId, MenuOperations } from "../../../constants/menueditor";
import { ApplicationState } from "../../../types";
import MenuSearch from "../../menu/MenuSearch";
import DeveloperLayout, { DeveloperNavbarWrapper } from "../DeveloperLayout";
import Menu from "./Menu";
import MenuEditorToolbar from "./MenuEditorToolbar";

export function getSearch(search: string) {
  return new URLSearchParams(search);
}

export const ConnectedSearchInput = connect(
  (state: ApplicationState) => {
    return {};
  },
  (dispatch: ThunkDispatch<ApplicationState, {}, AnyAction>) => {
    return {
      search: (value: string) =>
        dispatch(sendMenuEditorFilteredFoldersSearch(value)),
    };
  }
)(MenuSearch);

const ConnectedMenuEditorToolbar = connect(
  (state: ApplicationState, ownProps: { path?: string }) => {
    const { path } = ownProps;
    const mEditor = state.menueditor && state.menueditor.editorState;
    const editor = path && mEditor && mEditor[path];
    const activeValues = editor && editor.active;
    let disabled = true;
    if (activeValues) {
      disabled = !Object.values(activeValues).includes(true);
    }
    const operation = (editor && editor.operation) || undefined;
    const activeMenu = state.menueditor && state.menueditor.menuActive;
    return {
      disabled,
      operation,
      isMenuExist: editor !== undefined,
      activeMenu,
    };
  },
  (
    dispatch: ThunkDispatch<ApplicationState, {}, AnyAction>,
    ownProps: { path?: string }
  ) => {
    const { path } = ownProps;
    return {
      delete: () => path && dispatch(fetchDeleteItems(path)),
      clearSelected: () => {
        path && dispatch(sendMenuEditorMenuActiveAll(path, false));
        path && dispatch(sendMenuEditorOperation(path));
      },
      copySelected: () =>
        path &&
        dispatch(sendMenuEditorOperation(path, MenuOperations.COPY_ITEM)),
      cutSelected: () =>
        path &&
        dispatch(sendMenuEditorOperation(path, MenuOperations.CUT_ITEM)),
      goToView: (path: string) => dispatch(goToView(path)),
    };
  }
)(MenuEditorToolbar);

enum PageTabs {
  MAIN = "/npt/platform/menu",
  SECURITY = "/npt/platform/security-menu",
}

interface MenuEditorProps extends RouteComponentProps {
  active?: string;
  fetchMenuTree: () => void;
  fetchMenu: (path: string) => void;
  activate: (path: string) => void;
}

interface MenuEditorStates {
  active: string;
}

class MenuEditor extends React.Component<MenuEditorProps, MenuEditorStates> {
  constructor(props: MenuEditorProps) {
    super(props);
    this.state = { active: PageTabs.MAIN };
  }
  componentDidMount() {
    const { activate, location } = this.props;
    const { active } = this.state;
    const path = getSearch(location.search).get("path");
    if (path) {
      this.setState({ active: path as PageTabs });
      activate(path);
    } else if (active) {
      activate(active);
    }
  }
  componentDidUpdate(nextProps: MenuEditorProps, nextState: MenuEditorStates) {
    const { active } = this.state;
    const oldMenuPath = active;
    const menuPath = nextState.active;
    if (oldMenuPath !== menuPath) {
      this.props.fetchMenu(active);
    }
  }

  title() {
    return (
      <h4 className="m-0">
        <FontAwesomeIcon icon={faBars} className="mr-2" />
        <FormattedMessage id="MENU_EDITOR_MENU_TITLE" />
      </h4>
    );
  }
  search() {
    return <ConnectedSearchInput />;
  }
  tabs() {
    const { active: activeTab } = this.state;
    return (
      <Tabs
        id="cim-tabs"
        variant="tabs"
        onSelect={(k: string | null) => k && this.setState({ active: k })}
        defaultActiveKey={activeTab}
      >
        <Tab
          tabClassName={"border"}
          eventKey={PageTabs.MAIN}
          title={<FormattedMessage id="MENU_EDITOR_MAIN_MENU" />}
        ></Tab>
        <Tab
          tabClassName={"border"}
          eventKey={PageTabs.SECURITY}
          title={<FormattedMessage id="MENU_EDITOR_SECURITY_MENU" />}
        ></Tab>
      </Tabs>
    );
  }
  render() {
    const { active } = this.state;
    return (
      <DeveloperNavbarWrapper
        style={{ overflowY: "auto" }}
        search={this.search()}
        toolbar={<ConnectedMenuEditorToolbar path={active} />}
      >
        <div className=" w-100 mt-1  npt-menu-editor ">
          <div className="mx-1   mb-2">{this.tabs()}</div>
          <ContextMenuTrigger
            holdToDisplay={-1}
            id={CtxMenuId.EDITOR}
            renderTag="div"
            attributes={{ style: { height: "100%" } }}
          >
            <Menu active={active} />
          </ContextMenuTrigger>
        </div>
      </DeveloperNavbarWrapper>
    );
  }
}

export default withRouter(MenuEditor);
