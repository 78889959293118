import { ThunkAction } from 'redux-thunk';
import { SEND_MENU_CLOSED, SEND_MENU_MINIMISED, SEND_MENU_ACTIVE } from '../constants/menu';
import { ApplicationState } from '../types';
import {
    MenuType,
    SendMenuClosed,
    SendMenuMinimized,
    SendMenuActive
} from '../types/menu';
import { ModalAction } from '../types/modal';
import { dispatchError, dispatchSuccess } from './alert';
import { openModal } from './modal';

export function sendMenuMinimized(minimized: boolean, type: MenuType): SendMenuMinimized {
    return {
        type: SEND_MENU_MINIMISED,
        payload: {
            minimized,
            type
        }
    }
}

export function sendMenuClosed(closed: boolean, type: MenuType): SendMenuClosed {
    return {
        type: SEND_MENU_CLOSED,
        payload: {
            closed,
            type
        }
    }
}
export function sendMenuActive(active: MenuType): SendMenuActive {
    return {
        type: SEND_MENU_ACTIVE,
        payload: active
    }
}


export function setupHomePage(): void {
    fetch("/rest/user/sethomepage", {
        method: "POST",
        body: JSON.stringify({ "link": window.location.href })
    });
}

export function changePassword(): ThunkAction<void, ApplicationState, {}, ModalAction> {
    return async (dispatch, getState) => {
        dispatch(openModal("changePassword", "password", {}, (result) => {
            if (result == null) {
                return;
            }
            fetch("/rest/self/changepassword", {
                method: "POST",
                body: JSON.stringify(result),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(r => {
                if (!r.ok) {
                    const { status } = r
                    if (status === 403) {
                        dispatchError("PASSWORD_WRONG_OLD_PASSWORD", {}, dispatch);
                    } else {
                        dispatchError("PASSWORD_CHANGE_ERROR", {}, dispatch);
                    }

                } else {
                    dispatchSuccess("PASSWORD_CHANGED_SUCCESSFULLY", dispatch,);
                }
            }).catch(e => {
                dispatchError("PASSWORD_CHANGE_ERROR", {}, dispatch);
            });
        }));
    };
}


export function invokeAvatarHelp(): ThunkAction<void, ApplicationState, {}, ModalAction> {
    return async (dispatch, getState) => {
        dispatch(openModal("invokeAvatarHelp", "invokeAvatarHelp", {}, (result) => {
            
        }));
    };
}
