 
export const DEFAULT_VERSION_STATE:VersionState = {
    branch:(window as any).__npt_version__?.branch, 
    hash:(window as any).__npt_version__?.hash, 
    version:(window as any).__npt_version__?.version,
    timestamp:(window as any).__npt_version__?.timestamp,
}

export interface  VersionState{
    version:string
    hash:string
    branch:string
    timestamp:string
}

 