import * as React from 'react';

interface FillerProps {
    className?: string
    style?: React.CSSProperties
}
const Filler: React.FunctionComponent<FillerProps> = React.memo((props) => {
    return <div className={`d-flex flex-fill overflow-hidden ${props.className ? props.className : ""}`} style={props.style}>
        <div className="w-100 h-100 overflow-auto">
            {props.children}
        </div>
    </div>
});

export default Filler;