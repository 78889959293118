import * as React from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { createMenuItem, fetchDeleteItem, fetchMoveMenuItem, fetchMoveMenuItems, sendMenuEditorMenuActiveAll, sendMenuEditorOperation, updateMenuItem } from '../../../actions/menueditor';
import { MenuOperations } from '../../../constants/menueditor';
import { ApplicationAction, ApplicationState } from '../../../types';
import { ClipboardData } from '../../../types/clipboard';
import { MenuItem as Item } from '../../../types/menueditor';




export interface EditorContextMenuProps {
    hasClipborad?: boolean
    operation?: MenuOperations,
    clipboardData?: ClipboardData
    id?: string
    node?: Item
    path?: string
    active?: { [ID: string]: boolean }
    editItem: (id: string, path: string) => void
    removeItem: (menu: Item, path: string) => void
    createMenuItem: (path: string, parentId?: string) => void
    moveMenuItem: (path: string, id: string, newParentId: string | null, copy: boolean) => void
    moveMenuItems: (path: string, newParentId: string | null, copy: boolean) => void
    clearSelected: () => void
}

interface EditorContextMenuStates {

}

class EditorContextMenu extends React.Component<EditorContextMenuProps, EditorContextMenuStates> {
    private menu?: Item;
    private copy?: boolean;
    constructor(props: EditorContextMenuProps) {
        super(props);
        this.state = {

        }
    }


    shouldComponentUpdate(nextProps: EditorContextMenuProps) {
        const { path } = nextProps;
        return true;
    }

    handleAddMenu = () => {
        const { createMenuItem, node, path } = this.props;
        if (!path) {
            return
        }
        if (!node) {
            createMenuItem(path);
        }
        else if (node && node.id) {
            createMenuItem(path, node.id);
        }
    }
    handleEditMenu = () => {
        const { editItem, node, path } = this.props;
        if (node && node.id && path) {
            editItem(node.id, path);
        }
    }
    handleRemoveMenuItem = () => {
        const { removeItem, node, path } = this.props;
        if (!path) {
            return
        }
        if (node) {
            removeItem(node, path);
        }
    }

    handleMoveMenu = (copy: boolean) => () => {
        const { node } = this.props;
        if (!node || !node.id) {
            return;
        }
        this.menu = node;
        this.copy = copy;
    }
    handlePasteMenu = () => {
        const { moveMenuItem, node, path } = this.props;
        const nodeId = this.menu && this.menu.id;
        const newParentId = node && node.id || null;
        if (nodeId && this.copy !== undefined && path) {
            moveMenuItem(path, nodeId, newParentId, this.copy);
            this.menu = undefined;
            this.copy = undefined;
        }
    }
    handlePasteSelected = () => {
        const { moveMenuItems, clearSelected, node, path, operation } = this.props;
        const newParentId = node && node.id || null;
        let isCopy = null;
        switch (operation) {
            case MenuOperations.COPY_ITEM:
                isCopy = true; break;
            case MenuOperations.CUT_ITEM:
                isCopy = false; break;
        }
        if (isCopy !== null && path) {
            moveMenuItems(path, newParentId, isCopy);
            clearSelected()
        }
    }

    renderAddItem() {
        return (
            <MenuItem key='add' onClick={this.handleAddMenu}>
                <FormattedMessage id="MENU_EDITOR_INFO_MODAL_TITLE_ITEM_CREATE" />
            </MenuItem>
        )
    }
    renderEditItem() {
        const { node } = this.props;
        if (!node) {
            return <></>
        }
        return (
            <MenuItem key='edit' onClick={this.handleEditMenu}>
                <FormattedMessage id="MENU_EDITOR_INFO_MODAL_TITLE_ITEM_EDIT" />
            </MenuItem>
        )
    }

    renderRemoveMenuItem() {
        const { node } = this.props;
        if (!node) {
            return null
        }
        return (
            <MenuItem key='remove' onClick={this.handleRemoveMenuItem}>
                <FormattedMessage id="MENU_EDITOR_INFO_MODAL_ITEM_DELETE" />
            </MenuItem>
        )

    }

    renderCutMenuItem() {
        const { node } = this.props;
        if (!node) {
            return <></>
        }
        return (
            <MenuItem key='cut' onClick={this.handleMoveMenu(false)}>
                <FormattedMessage id="MENU_EDITOR_INFO_MODAL_MENU_CUT" />
            </MenuItem>
        )
    }
    renderCopyMenuItem() {
        const { node } = this.props;
        if (!node) {
            return <></>
        }
        return (
            <MenuItem key='copy' onClick={this.handleMoveMenu(true)}>
                <FormattedMessage id="MENU_EDITOR_INFO_MODAL_MENU_COPY" />
            </MenuItem>
        )
    }
    renderPasteMenuItem() {
        const { node } = this.props;
        const menu = this.menu;
        const copy = this.copy;
        if (!menu || copy === undefined) {
            return <></>
        }
        if (!copy && ((node && node.id === menu.parentId) || (!node && !menu.parentId))) {
            return null;
        }
        return (
            <MenuItem key='paste' onClick={this.handlePasteMenu}>
                <FormattedMessage id="MENU_EDITOR_INFO_MODAL_MENU_PASTE" />
            </MenuItem>
        )
    }
    renderPasteMenuSelected() {
        const { node, active, operation } = this.props;

        const activeExists = active && Object.values(active).includes(true) || false;
        if (!activeExists || !operation) {
            return null;
        }

        const pasteInActive = active && node && Object.entries(active).find(e => node.id === e[0] && e[1])
        if (operation === MenuOperations.CUT_ITEM && pasteInActive) {
            return null;
        }
        return (
            <MenuItem key='pasteSelected' onClick={this.handlePasteSelected}>
                <FormattedMessage id="MENU_EDITOR_INFO_MODAL_MENU_PASTE_SELECTED" />
            </MenuItem>
        )
    }
    render() {
        const { id } = this.props;
        if (!id) {
            return <></>
        }
        return (
            <ContextMenu id={id}  >
                {this.renderAddItem()}
                {this.renderEditItem()}
                {this.renderRemoveMenuItem()}
                {this.renderCutMenuItem()}
                {this.renderCopyMenuItem()}
                {this.renderPasteMenuItem()}
                {this.renderPasteMenuSelected()}
            </ContextMenu>)

    }
}

export default connect(
    (state: ApplicationState, ownProps: { path?: string }) => {
        const {path} = ownProps
        const editor = path && state.menueditor && state.menueditor.editorState[path];
        let ctxMenu =  editor && editor.contextMenu;
        let menuId = ctxMenu && ctxMenu.id;
        const nodeId = ctxMenu && ctxMenu.nodeId;
        const node =  nodeId && editor && editor.itemById && editor.itemById[nodeId] || undefined;
        const active = editor &&  editor.active || undefined;
        const operation = editor && editor.operation || undefined;

        return {
            id: menuId,
            operation,
            node, 
            active
        }
    },
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>, ownProps: { path?: string }) => {
        const { path } = ownProps;
        return {
            createMenuItem: (path: string, parentId?: string) => dispatch(createMenuItem(path, parentId)),
            removeItem: (menu: Item, path: string) => dispatch(fetchDeleteItem(menu, path)),
            editItem: (id: string, path: string) => dispatch(updateMenuItem(path, id)),
            moveMenuItem: (path: string, id: string, newParentId: string | null, copy: boolean) => dispatch(fetchMoveMenuItem(path, id, newParentId, copy)),
            moveMenuItems: (path: string, newParentId: string | null, copy: boolean = false) => dispatch(fetchMoveMenuItems(path, newParentId, copy)),
            clearSelected: () => {
               path&& dispatch(sendMenuEditorMenuActiveAll(path,false));
                path&&dispatch(sendMenuEditorOperation(path));
            },
        }
    }
)(EditorContextMenu)