import React, { FC } from "react";
import { fetchAutocomplete } from "../../actions/subject";
import DebounceInput from "../debounce/debounceinput";
import { AutocompleteDropdown } from "./AutocompleteDropdown";
interface AutocompleteSize {
  top: number;
  left: number;
  width: number;
}

interface AutocompleteWrapperProps {
  children: any;
  value?: any;
  contextSearch?: string | object;
  autocomplete: string;
  onAutocomplete?: (e: any) => void;
  //   onChange: (e: React.ChangeEvent) => void;
}
interface AutocompleteWrapperState {
  autocompleteList: string[] | null;
  autocompleteLoading: boolean;
  autocompleteError: string | null;
  showAutocomplete: boolean;
  autocompleteSize: AutocompleteSize | null;
}

export class AutocompleteWrapper extends React.Component<
  AutocompleteWrapperProps,
  AutocompleteWrapperState
> {
  private textInput: React.RefObject<DebounceInput> = React.createRef();
  private autocompleteRef: HTMLDivElement | null = null;
  private cachedValue: string | null = null;
  constructor(props: AutocompleteWrapperProps) {
    super(props);

    this.state = {
      autocompleteList: null,
      autocompleteLoading: false,
      autocompleteError: null,
      autocompleteSize: null,
      showAutocomplete: false,
    };
  }

  hideDropdownHandler = (e: any) => {
    const { showAutocomplete } = this.state;
    const closestInput = e.target.closest(`.autocomplete-input`);
    const closestDD = e.target.closest(`.autocompete-dd`);
    const input = this.textInput?.current?.textInput;
    if (
      showAutocomplete &&
      (closestInput || closestDD) &&
      (input?.isEqualNode(e.target) ||
        this.autocompleteRef?.isEqualNode(e.target))
    ) {
      return;
    }
    this.setState({ showAutocomplete: false });
  };

  requestAutocomplete = async (text: any) => {
    const { autocomplete: script, contextSearch } = this.props;
    if (typeof text !== "string") {
      return;
    }
    if (!script) {
      return;
    }
    this.setState({ autocompleteLoading: true, showAutocomplete: true });
    try {
      const result: any = await fetchAutocomplete(script, text, contextSearch);
      if (result?.list) {
        this.setState({
          autocompleteList: result.list,
          autocompleteLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        autocompleteList: [],
        autocompleteError: "error",
        autocompleteLoading: false,
      });
    }
  };
  onFocus = () => {
    const { autocomplete: autocompleteScript, value } = this.props;
    if (!autocompleteScript) {
      return;
    }
    const { autocompleteList, showAutocomplete, autocompleteLoading } =
      this.state;

    if (!autocompleteList && value) {
      this.requestAutocomplete(value);
      return;
    }

    this.setState({ showAutocomplete: true }, () => {
      if (!this.props.value && !autocompleteList?.length) {
        this.requestAutocomplete("");
      }
    });
  };
  onItemClick = (value: string | object) => {
    const { onAutocomplete } = this.props;

    const currentCachedValue =
      typeof value === "object" ? (value as any)?.value || "" : value;
    this.cachedValue = currentCachedValue;
    this.setState({ showAutocomplete: false }, () => {
      const input = this.textInput?.current?.textInput;
      if (onAutocomplete && input) {
        const newInput: any = { ...input, value };
        const event = {
          target: newInput,
          type: "autocomplete",
        };
        onAutocomplete(event);
      }
    });
  };
  renderChid = () => {
    const { children, ...rest } = this.props;
    return React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ref: this.textInput,
        onFocus: this.onFocus,
      })
    );
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.hideDropdownHandler);
    const textInput = this.textInput?.current?.textInput;
    if (textInput) {
      textInput.classList.toggle("autocomplete-input");
    }
  }
  componentWillUnmount(): void {
    document.removeEventListener("mousedown", this.hideDropdownHandler);
    const textInput = this.textInput?.current?.textInput;
    if (textInput) {
      textInput.classList.toggle("autocomplete-input");
    }
  }
  componentDidUpdate(prevProps: AutocompleteWrapperProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      if (this.cachedValue === value) {
        return;
      }
      this.requestAutocomplete(value || "");
    }
  }
  getAutocompleteStyle = () => {
    const input = this.textInput?.current?.textInput;
    if (!input) {
      return null;
    }
    const inputRect = input.getBoundingClientRect();
    const { top, height, left, width } = inputRect;

    const autocompleteHeight = 130;
    let autocompleteY = top + height;
    const autocompleteX = left;
    const autocompleteWidth = width;

    const bodyRect = document.body.getBoundingClientRect();
    const bodyBottom = bodyRect.bottom;
    if (bodyBottom < autocompleteY + autocompleteHeight) {
      autocompleteY = top - autocompleteHeight;
    }
    const autocompleteSize: AutocompleteSize = {
      left: autocompleteX,
      top: autocompleteY,
      width: autocompleteWidth,
    };
    return autocompleteSize;
  };
  setAutocompleteRef = (ref: HTMLDivElement | null) => {
    this.autocompleteRef = ref;
  };
  getAutocomplete = () => {
    const {
      autocompleteList,
      autocompleteError: error,
      showAutocomplete,
      autocompleteLoading: loading,
    } = this.state;
    const style = this.getAutocompleteStyle();
    if (!showAutocomplete || !autocompleteList) {
      return null;
    }

    return (
      <AutocompleteDropdown
        setRef={this.setAutocompleteRef}
        loading={!!loading}
        style={style || undefined}
        error={error}
        parentClass={"autocomplete-input"}
        onClose={() => this.setState({ showAutocomplete: false })}
        list={autocompleteList}
        onItemClick={this.onItemClick}
      />
    );
  };
  render() {
    const { autocomplete, children } = this.props;
    if (!autocomplete) {
      return children;
    }
    return (
      <>
        {this.renderChid()}
        {this.getAutocomplete()}
      </>
    );
  }
}
