import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { changeOrder, createMenuItem, fetchMenu, goToView, sendMenuEditorCtxMenuEditor, sendMenuEditorMenuActive, sendMenuEditorMenuToggled, updateMenuItem } from "../../../actions/menueditor";
import { CtxMenuId } from "../../../constants/menueditor";
import { ApplicationAction, ApplicationState } from "../../../types";
import { isFetchError } from "../../../types/error";
import { EditorState, MenuEditorState } from "../../../types/menueditor";
import EditorContextMenu from "./EditorContextMenu";
import { MenuListTree, MenuNode } from "./MenuNode";
const DEFAULT_LOCALE = '';
const renderTreeFetchLoading = () => {
    return (
        <div style={{ zIndex: 1, height: 0 }} className=" position-relative d-flex justify-content-center">
            <div className="position-absolute" style={{ height: '3rem' }}>
                <Alert className="mx-1 mt-1  " variant="info">
                    <FontAwesomeIcon className="mx-1" icon={faSpinner} spin />
                    <FormattedMessage id={"MENU_EDITOR_TREE_FETCH_LOADING"} />
                </Alert>
            </div>
        </div>
    )
}



const ConnectedNode = connect((_, ownProps: { treeId: string, id: string }) => {
    return (state: ApplicationState) => {
        const { treeId, id } = ownProps;
        const editorState = treeId && state.menueditor && state.menueditor.editorState[treeId];
        if (!editorState || (editorState.itemById && (!editorState.itemById[id] || isFetchError(editorState.itemById[id])))) {
            return {
                error: true
            };
        }

        const node = editorState.itemById && editorState.itemById[id];
        // const rdfId: string | null =node && node.data && node.data.$rdfId; 
        const active = editorState.active && editorState.active[id] || false;
        const locale = state.locale.language;
        const t = node.t && node.t
        let title = node.n;
        if (t && t[locale]) {
            title = t[locale];
        } else if (t && t[DEFAULT_LOCALE]) {
            title = t[DEFAULT_LOCALE];
        }


        const toggled = editorState.expanded ? editorState.expanded[id] : false;


        let children = editorState.childrenById && editorState.childrenById[id] || undefined;
        if (children && children.length === 0) {
            children = undefined;
        }
        return {
            name: title,
            expanded: toggled,
            hidden:node.h,
            active: active, 
            inplace: false,
            edgeTriggerInplace: true,
            loading: false,
            editing: false,
            changed: false,
            childrenIds: children,
            data: node,
            icon: null,
            menuId: CtxMenuId.EDITOR

        };
    }
}, (dispatch: ThunkDispatch<{}, {}, any>, ownProps: { treeId: string, id: string }) => {
    const { id, treeId } = ownProps;
    return {
        toggle: (expanded: boolean) => dispatch(sendMenuEditorMenuToggled(expanded, id, treeId)),
        activate: () => dispatch(sendMenuEditorMenuActive(treeId, id)),
        contextMenu: () => dispatch(sendMenuEditorCtxMenuEditor(treeId, CtxMenuId.EDITOR, id)),
        editItem: (id: string, path: string) => dispatch(updateMenuItem(path, id)),
        createMenuItem: (path: string, parentId?: string) => dispatch(createMenuItem(path, parentId)),

        goToView: (path: string, id: string) => dispatch(goToView(path, id)),
        changeOrder: (itemId: string, isBefore: boolean) => dispatch(changeOrder(treeId, itemId, id, isBefore))
    }
})(MenuNode);


const ConnectedTree = connect(null, (dispatch: ThunkDispatch<{}, {}, any>, ownProps: { treeId: string }) => {
    const { treeId } = ownProps;
    return {
        expandNode: (id: string, expanded: boolean) => dispatch(sendMenuEditorMenuToggled(expanded, id, treeId))
    }
})(MenuListTree);


interface MenuProps extends RouteComponentProps {
    editorState?: EditorState
    className?: string
    style?: React.CSSProperties
    menuId?: string
    loading?: boolean
    active?: string
    fetchMenu: (path: string) => void
    treeContextMenu: () => void
}

interface MenuState {
}

class Menu extends React.Component<MenuProps, MenuState> {
    componentDidMount() {
        const { fetchMenu, active, history } = this.props;
        if (active) {
            const path = active;
            fetchMenu(path);
        }
    }
    // componentDidUpdate(nextProps: MenuProps){
    //     const { active, fetchMenu } = this.props;
    //     const oldMenuPath = active;
    //     const menuPath = nextProps.active;
    //     if ((oldMenuPath !== menuPath) && menuPath) {
    //         fetchMenu(menuPath);
    //     }
    // } 
    renderNotSelected() {
        const { active, loading } = this.props;
        if (loading) {
            return null;
        }
        return !active && (
            <div className="d-flex justify-content-center">
                <Alert variant="info">
                    <FormattedMessage id="MENU_EDITOR_EDIT_NOT_SELECTED" />
                </Alert>
            </div>
        )
    }
    renderEmptyWorkspace() {
        const { editorState: s, active, loading } = this.props;
        if (loading) {
            return null;
        }

        return (!s || s.roots && s.roots.length === 0 || (!s.roots && active)) && (
            <div className="d-flex justify-content-center">
                <Alert variant="info">
                    <FormattedMessage id="MENU_EDITOR_EMPTY" />
                </Alert>
            </div>
        )
    }

    renderWorkspace() {
        const { editorState: s, active } = this.props;
        if (!s) {
            return null;
        }
        return (
            <ConnectedTree
                treeId={active || "undefined"}
                renderComponent={ConnectedNode}
                roots={s.roots}
            />
        )
    }

    render() {
        const { loading, treeContextMenu, active } = this.props;

        return (<>
            <div className="workspace px-5 pb-5 h-100" onContextMenu={() => treeContextMenu()}>
                {loading && renderTreeFetchLoading()}
                {this.renderNotSelected()}
                {this.renderEmptyWorkspace()}
                {this.renderWorkspace()}
            </div>
            <EditorContextMenu path={active} />
        </>)
    }
}
const RoutedMenu = withRouter(Menu);
export default connect((state: ApplicationState, ownProps: { active?: string }) => {
    const { active } = ownProps;
    const editor = active && state.menueditor && state.menueditor.editorState[active];
    const loading = editor && editor.loading || undefined;
    return {
        loading,
        editorState: editor || undefined
    }
},
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>, ownProps: { active?: string }) => {
        const { active } = ownProps;
        return {
            fetchMenu: (path: string) => dispatch(fetchMenu(path)),
            treeContextMenu: () => {
                active && dispatch(sendMenuEditorCtxMenuEditor(active, CtxMenuId.EDITOR))
            }
        }
    }
)(RoutedMenu)

