import { ThunkAction } from 'redux-thunk'
import shortid from 'shortid';
import { ApplicationAction, ApplicationState } from '../types';
import {
    FinderAction,
    FinderObjectcard,
    FinderOptions,
    FinderPredicate,
    FinderRelation,
    FinderViewType,
    SendFinderChangesConfirm,
    SendFinderChangesDeny,
    SendFinderClasses,
    SendFinderClassesLoading,
    SendFinderCriteriaAdd,
    SendFinderCriteriaField,
    SendFinderCriteriaRelation,
    SendFinderCriteriaRelationAdd,
    SendFinderCriteriaRelationRemove,
    SendFinderCriteriaRelationsUnlock,
    SendFinderCriteriaRemove,
    SendFinderFields,
    SendFinderFieldsLoading,
    SendFinderFragments,
    SendFinderFragmentsError,
    SendFinderFragmentsLoading,
    SendFinderFragmentsTreeError,
    SendFinderFragmentsTreeHeader,
    SendFinderHidden,
    SendFinderObjectcard,
    SendFinderObjectcardError,
    SendFinderOptions,
    SendFinderPredicate,
    SendFinderSearch,
    SendFinderSelectClass,
    SendFinderSelectFragment,
    SendFinderView,
    ServerFinderClass,
    ServerFinderField,
    ServerFinderFilter,
    ServerFinderFragment
} from '../types/finder';
import { TreeHeader, TreeNode } from '../types/tree';
import {
    SEND_FINDER_CHANGES_CONFIRM,
    SEND_FINDER_CHANGES_DENY,
    SEND_FINDER_CLASSES,
    SEND_FINDER_CLASSES_LOADING,
    SEND_FINDER_CRITERIA_ADD,
    SEND_FINDER_CRITERIA_FIELD,
    SEND_FINDER_CRITERIA_RELATION,
    SEND_FINDER_CRITERIA_RELATIONS_UNLOCK,
    SEND_FINDER_CRITERIA_RELATION_ADD,
    SEND_FINDER_CRITERIA_RELATION_REMOVE,
    SEND_FINDER_CRITERIA_REMOVE,
    SEND_FINDER_FIELDS,
    SEND_FINDER_FIELDS_LOADING,
    SEND_FINDER_FRAGMENTS,
    SEND_FINDER_FRAGMENTS_ERROR,
    SEND_FINDER_FRAGMENTS_LOADING,
    SEND_FINDER_FRAGMENTS_TREE_ERROR,
    SEND_FINDER_FRAGMENTS_TREE_HEADER,
    SEND_FINDER_HIDDEN,
    SEND_FINDER_OBJECTCARD,
    SEND_FINDER_OBJECTCARD_ERROR,
    SEND_FINDER_OPTIONS,
    SEND_FINDER_PREDICATE,
    SEND_FINDER_SEARCH,
    SEND_FINDER_SELECT_CLASS,
    SEND_FINDER_SELECT_FRAGMENT,
    SEND_FINDER_VIEW
} from '../constants/finder';
import { ServerError } from './utils';
import { dispatchError } from './alert';
import { openModal } from './modal';
import { fetchTreeHeaderImpl, fetchTreeNodesImpl, parseTreeNodes } from './tree';
import { buildUrl } from '../services/location';
import { getPredicateName } from '../services/finder';


/*********************
 * Utility functions *
 *********************/

/*******************
 * Fetch functions *
 *******************/
async function fetchFinderFragmentImpl(fragmentPath: string, parentFragmentId: string, treeHeader: TreeHeader): Promise<ServerFinderFragment[]> {
    const parentId = parentFragmentId !== "null" ? parentFragmentId : null;
    try {
        const nodes = parseTreeNodes(treeHeader, await fetchTreeNodesImpl(fragmentPath, parentId != null? parentId: undefined));
        return parseFinderFragments(nodes, parentId);
    } catch (e) {
        console.error("Failed to download fragments: ", e);
        throw e;
    }
}

async function fetchFinderClassesImpl(model: string, levelId: string, isTree?: boolean): Promise<ServerFinderClass[]> {
    const url = buildUrl({ url: `/rest/${isTree ? "tree" : "table"}/classes${model}`, search: { id: levelId } });
    return fetch(url).then((resp) => {
        if (!resp.ok) {
            console.error("Failed to download classes: ", resp.status, resp.statusText);
            throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
    }).then((json) => {
        return parseFinderClasses(json);
    })
}

async function fetchFinderFieldsImpl(criteriaPath: string, parentId: string | null): Promise<ServerFinderField[]> {
    const search: { [k: string]: string } = {};
    if (parentId) {
        search.node = parentId;
    }
    const url = buildUrl({ url: `/rest/finder/detailed/criteria${criteriaPath}`, search });
    return fetch(url).then((resp) => {
        if (!resp.ok) {
            console.error("Failed to download fields: ", resp.status, resp.statusText);
            throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
    }).then((json) => {
        return json;
    })
}

async function fetchFinderObjectcardImpl(finderId: string, rdfId: string): Promise<FinderObjectcard> {
    const url = buildUrl({ url: `/rest/subject/header/${rdfId}` });
    return fetch(url).then((resp) => {
        if (!resp.ok) {
            console.error("Failed to download objectcard: ", resp.status, resp.statusText);
            throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
    }).then((json) => {
        return json;
    })
}

async function fetchFinderPredicateImpl(finderId: string, predicate: string): Promise<FinderPredicate> {
    const url = buildUrl({ url: `/rest/finder/predicate`, search: { name: predicate } });
    return fetch(url).then((resp) => {
        if (!resp.ok) {
            console.error("Failed to download predicate: ", resp.status, resp.statusText);
            throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
    }).then((json) => {
        return json;
    })
}


/*********************
 * Parsers functions *
 *********************/
export function parseFinderFragments(nodes: TreeNode[], parentId: string | null): ServerFinderFragment[] {
    const fragments: ServerFinderFragment[] = [];
    for (let node of nodes) {
        const fragment: ServerFinderFragment = {
            id: node.id,
            name: node.name || "",
            fragmentId: node.data?.$fragment?.$rdfId || "",
            leaf: node.leaf,
            label: node.label,
            parentId
        }
        fragments.push(fragment);
    }
    return fragments
}

export function parseFinderClasses(data: any): ServerFinderClass[] {
    const classes: ServerFinderClass[] = [];
    if (!data?.nodes) {
        return classes;
    }
    for (let node of data.nodes) {
        const classData: ServerFinderClass = {
            id: node.nodeId,
            label: node.label,
            name: node.label,
            className: node.object
        }
        classes.push(classData);
    }
    return classes
}


/*****************
 * Plain actions *
 *****************/
export function sendFinderOptions(finderId: string, options: FinderOptions, filter?: ServerFinderFilter | null): SendFinderOptions {
    return {
        type: SEND_FINDER_OPTIONS,
        finderId,
        payload: { options, filter }
    }
}

export function sendFinderHidden(finderId: string, hidden?: boolean): SendFinderHidden {
    return {
        type: SEND_FINDER_HIDDEN,
        finderId,
        payload: { hidden }
    }
}

export function sendFinderView(finderId: string, view: FinderViewType): SendFinderView {
    return {
        type: SEND_FINDER_VIEW,
        finderId,
        payload: { view }
    }
}

export function sendFinderFragmentsLoading(finderId: string, parentId: string): SendFinderFragmentsLoading {
    return {
        type: SEND_FINDER_FRAGMENTS_LOADING,
        finderId,
        payload: { parentId }
    }
}

export function sendFinderFragmentsTreeHeader(finderId: string, treeHeader: TreeHeader): SendFinderFragmentsTreeHeader {
    return {
        type: SEND_FINDER_FRAGMENTS_TREE_HEADER,
        finderId,
        payload: { treeHeader }
    }
}

export function sendFinderFragmentsTreeHeaderError(finderId: string): SendFinderFragmentsTreeError {
    return {
        type: SEND_FINDER_FRAGMENTS_TREE_ERROR,
        finderId,
        payload: null
    }
}

export function sendFinderFragments(finderId: string, parentId: string, fragments: ServerFinderFragment[]): SendFinderFragments {
    return {
        type: SEND_FINDER_FRAGMENTS,
        finderId,
        payload: { parentId, fragments }
    }
}

export function sendFinderFragmentsError(finderId: string, parentId: string): SendFinderFragmentsError {
    return {
        type: SEND_FINDER_FRAGMENTS_ERROR,
        finderId,
        payload: { parentId }
    }
}

export function sendFinderSelectFragment(finderId: string, oldFragmentId: string | null, newFragmentId: string | null, force?: boolean): SendFinderSelectFragment {
    return {
        type: SEND_FINDER_SELECT_FRAGMENT,
        finderId,
        payload: { oldFragmentId, newFragmentId, force }
    }
}

export function sendFinderClassesLoading(finderId: string, levelId: string): SendFinderClassesLoading {
    return {
        type: SEND_FINDER_CLASSES_LOADING,
        finderId,
        payload: { levelId }
    }
}

export function sendFinderClasses(finderId: string, levelId: string, classes: ServerFinderClass[]): SendFinderClasses {
    return {
        type: SEND_FINDER_CLASSES,
        finderId,
        payload: { levelId, classes }
    }
}

export function sendFinderSelectClass(finderId: string, oldClassId: string | null, newClassId: string | null, classLevelIdx: number): SendFinderSelectClass {
    return {
        type: SEND_FINDER_SELECT_CLASS,
        finderId,
        payload: { oldClassId, newClassId, classLevelIdx }
    }
}

export function sendFinderFieldsLoading(finderId: string, parentId: string | null): SendFinderFieldsLoading {
    return {
        type: SEND_FINDER_FIELDS_LOADING,
        finderId,
        payload: { parentId }
    }
}

export function sendFinderFields(finderId: string, parentId: string | null, fields: ServerFinderField[]): SendFinderFields {
    return {
        type: SEND_FINDER_FIELDS,
        finderId,
        payload: { parentId, fields }
    }
}

export function sendFinderObjectcard(finderId: string, rdfId: string, objectcard: FinderObjectcard): SendFinderObjectcard {
    return {
        type: SEND_FINDER_OBJECTCARD,
        finderId,
        payload: { rdfId, objectcard }
    }
}

export function sendFinderObjectcardError(finderId: string, rdfId: string, error: Error): SendFinderObjectcardError {
    return {
        type: SEND_FINDER_OBJECTCARD_ERROR,
        finderId,
        payload: { rdfId, error }
    }
}

export function sendCriteriaAdd(finderId: string, criteriaGroupId?: string): SendFinderCriteriaAdd {
    return {
        type: SEND_FINDER_CRITERIA_ADD,
        finderId,
        payload: { criteriaGroupId }
    }
}

export function sendCriteriaRelationAdd(finderId: string, criteriaId: string): SendFinderCriteriaRelationAdd {
    return {
        type: SEND_FINDER_CRITERIA_RELATION_ADD,
        finderId,
        payload: { criteriaId }
    }
}

export function sendCriteriaRemove(finderId: string, criteriaId: string): SendFinderCriteriaRemove {
    return {
        type: SEND_FINDER_CRITERIA_REMOVE,
        finderId,
        payload: { criteriaId }
    }
}

export function sendCriteriaRelationRemove(finderId: string, criteriaId: string, relationIdx: number): SendFinderCriteriaRelationRemove {
    return {
        type: SEND_FINDER_CRITERIA_RELATION_REMOVE,
        finderId,
        payload: { criteriaId, relationIdx }
    }
}

export function sendCriteriaRelationsUnlock(finderId: string, criteriaId: string): SendFinderCriteriaRelationsUnlock {
    return {
        type: SEND_FINDER_CRITERIA_RELATIONS_UNLOCK,
        finderId,
        payload: { criteriaId }
    }
}

export function sendCriteriaRelation(finderId: string, criteriaId: string, relationIdx: number, relation: FinderRelation): SendFinderCriteriaRelation {
    return {
        type: SEND_FINDER_CRITERIA_RELATION,
        finderId,
        payload: { criteriaId, relationIdx, relation }
    }
}

export function sendFinderPredicate(finderId: string, predicateName: string, predicate: FinderPredicate, fieldId?: string): SendFinderPredicate {
    return {
        type: SEND_FINDER_PREDICATE,
        finderId,
        payload: { predicateName, predicate, fieldId }
    }
}

export function sendCriteriaField(finderId: string, criteriaId: string, fieldId: string): SendFinderCriteriaField {
    return {
        type: SEND_FINDER_CRITERIA_FIELD,
        finderId,
        payload: { criteriaId, fieldId }
    }
}

export function sendFinderSearch(finderId: string, value: string): SendFinderSearch {
    return {
        type: SEND_FINDER_SEARCH,
        finderId,
        payload: { value }
    }
}

export function sendFinderChangesConfirm(finderId: string): SendFinderChangesConfirm {
    return {
        type: SEND_FINDER_CHANGES_CONFIRM,
        finderId,
        payload: null
    }
}

export function sendFinderChangesDeny(finderId: string): SendFinderChangesDeny {
    return {
        type: SEND_FINDER_CHANGES_DENY,
        finderId,
        payload: null
    }
}

/***********
 * Actions *
 ***********/
export function initializeFinder(finderId: string, finderOptions: FinderOptions, finderFilter?: ServerFinderFilter | null): ThunkAction<void, ApplicationState, {}, FinderAction> {
    return async (dispatch, getState) => {
        dispatch(sendFinderOptions(finderId, finderOptions, finderFilter));
        if (!finderFilter) {
            return;
        }
        if (finderFilter.criteriaFetchList.length !== 0) {
            dispatch(fetchFields(finderId, null));
        }
        for (let criteriaId of finderFilter.criteriaFetchList) {
            dispatch(fetchFields(finderId, criteriaId));
        }
        for (let predicateName of finderFilter.predicateFetchList) {
            dispatch(fetchPredicate(finderId, predicateName));
        }
        for (let subjectRdfId of finderFilter.subjectFetchList) {
            dispatch(fetchObjectcard(finderId, subjectRdfId));
        }
        for (let classId of finderFilter.classLevelFetchList) {
            dispatch(fetchFinderClasses(finderId, finderId, classId));
        }
        if (finderFilter.fragmentFetchList.length !== 0) {
            dispatch(fetchFinderFragment(finderId, "null"));
        }
        for (let fragmentId of finderFilter.fragmentFetchList) {
            dispatch(fetchFinderFragment(finderId, fragmentId));
        }
    };
}

export function fetchFinderFragmentList(finderId: string, parentFragmentIds: string[] | null, types: string | string[]): ThunkAction<void, ApplicationState, {}, FinderAction> {
    return async (dispatch, getState) => {
        const s = getState();
        const finderState = s.finder[finderId];
        const fragmentTree = finderState.options.fragmentTree;
        if (!fragmentTree) {
            /**TODO: add alert */
            return;
        }
        if (parentFragmentIds == null) {
            parentFragmentIds = ["null"];
        }
        for (let fragmentId of parentFragmentIds) {
            if (finderState.loadedFragments.fetched[fragmentId]) {
                continue;
            }
            dispatch(fetchFinderFragment(finderId, fragmentId, types));
        }
    };
}

export function fetchFinderFragment(finderId: string, fragmentId: string, types?: string | string[]): ThunkAction<void, ApplicationState, {}, FinderAction> {
    return async (dispatch, getState) => {
        const s = getState();
        const finderState = s.finder[finderId];
        const fragmentTree = finderState.options.fragmentTree;
        if (!fragmentTree) {
            /**TODO: add alert */
            return;
        }
        dispatch(sendFinderFragmentsLoading(finderId, fragmentId));
        let treeHeader = finderState.fragmentsTreeHeader;
        try {
            if (!treeHeader) {
                treeHeader = await fetchTreeHeaderImpl(fragmentTree.path);
                dispatch(sendFinderFragmentsTreeHeader(finderId, treeHeader));
            }
        } catch (e) {
            dispatch(sendFinderFragmentsTreeHeaderError(finderId));
            if (e instanceof ServerError && e.code === 403) {
                dispatchError("FINDER_DOWNLOAD_FRAGMENT_TREE_ACCESS_DENIED", e, dispatch);
            } else {
                dispatchError("FINDER_DOWNLOAD_FRAGMENT_TREE_ERROR", e, dispatch);
            }
            return;
        }
        try {
            const fragments = await fetchFinderFragmentImpl(fragmentTree.path, fragmentId, treeHeader);
            dispatch(sendFinderFragments(finderId, fragmentId, fragments));
        } catch (e) {
            dispatch(sendFinderFragmentsError(finderId, fragmentId));
            if (e instanceof ServerError && e.code === 403) {
                dispatchError("FINDER_DOWNLOAD_FRAGMENT_ACCESS_DENIED", e, dispatch);
            } else {
                dispatchError("FINDER_DOWNLOAD_FRAGMENT_ERROR", e, dispatch);
            }
        }
    };
}

export function fetchFinderClasses(finderId: string, model: string, levelId: string, isTree?: boolean): ThunkAction<void, ApplicationState, {}, FinderAction> {
    return async (dispatch, getState) => {
        const s = getState();
        const finderState = s.finder[finderId];
        if (finderState.loadedClasses.fetched[levelId]) {
            /**TODO: add alert */
            return;
        }
        try {
            dispatch(sendFinderClassesLoading(finderId, levelId));
            const classes = await fetchFinderClassesImpl(model, levelId, isTree);
            dispatch(sendFinderClasses(finderId, levelId, classes));
        } catch (e) {
            /**TODO: add alert */
        }
    };
}

export function fetchFields(finderId: string, parentId: string | null): ThunkAction<void, ApplicationState, {}, FinderAction> {
    return async (dispatch, getState) => {
        const s = getState();
        const finderState = s.finder[finderId];
        const criteriaTree = finderState.options.criteriaTree;
        if (!criteriaTree) {
            return;
        }
        if (finderState.loadedFields.fetched[parentId || "null"]) {
            /**TODO: add alert */
            return;
        }
        try {
            dispatch(sendFinderFieldsLoading(finderId, parentId));
            const fields = await fetchFinderFieldsImpl(criteriaTree.path, parentId);
            dispatch(sendFinderFields(finderId, parentId, fields));
        } catch (e) {
            /**TODO: add alert */
        }
    };
}

export function fetchObjectcard(finderId: string, rdfId: string): ThunkAction<void, ApplicationState, {}, FinderAction> {
    return async (dispatch, getState) => {
        const s = getState();
        const finderState = s.finder[finderId];
        if (finderState.loadedObjectcards.fetched[rdfId]) {
            return;
        }
        try {
            const objectcard = await fetchFinderObjectcardImpl(finderId, rdfId);
            dispatch(sendFinderObjectcard(finderId, rdfId, objectcard));
        } catch (e: any) {
            dispatch(sendFinderObjectcardError(finderId, rdfId, e));
            if (e instanceof ServerError && e.code === 403) {
                dispatchError("FINDER_DOWNLOAD_SUBJECT_HEADER_ACCESS_DENIED", e, dispatch);
            } else {
                dispatchError("FINDER_DOWNLOAD_SUBJECT_HEADER_ERROR", e, dispatch);
            }
        }
    };
}

export function removeCriteria(finderId: string, criteriaId: string): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
    return async (dispatch, getState) => {
        const options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "MSG_CONFIRM_REMOVE_CRITERION" }
        }
        dispatch(openModal(shortid.generate(), "confirm", options, function () {
            dispatch(sendCriteriaRemove(finderId, criteriaId));
        }));
    };
}

export function removeCriteriaRelation(finderId: string, criteriaId: string, relationIdx: number): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
    return async (dispatch, getState) => {
        const options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "MSG_CONFIRM_REMOVE_RELATION" }
        }
        dispatch(openModal(shortid.generate(), "confirm", options, function () {
            dispatch(sendCriteriaRelationRemove(finderId, criteriaId, relationIdx));
            dispatch(sendCriteriaRelationsUnlock(finderId, criteriaId));
        }));
    };
}

export function fetchPredicate(finderId: string, predicateName: string, fieldId?: string): ThunkAction<void, ApplicationState, {}, FinderAction> {
    return async (dispatch, getState) => {
        const s = getState();
        const finderState = s.finder[finderId];
        if (finderState.loadedPredicates.byName[predicateName]) {
            return;
        }
        try {
            const predicate = await fetchFinderPredicateImpl(finderId, predicateName);
            dispatch(sendFinderPredicate(finderId, predicateName, predicate, fieldId));
        } catch (e) {
            console.error("Failed to download predicate: ", e);
            if (e instanceof ServerError && e.code === 403) {
                dispatchError("FINDER_DOWNLOAD_PREDICATE_ACCESS_DENIED", e, dispatch);
            } else {
                dispatchError("FINDER_DOWNLOAD_PREDICATE_ERROR", e, dispatch);
            }
        }
    };
}

export function changeCriteriaField(finderId: string, criteriaId: string, fieldId: string): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
    return async (dispatch, getState) => {
        const s = getState();
        const finderState = s.finder[finderId];
        const field = finderState.loadedFields.byId[fieldId];
        if (!field) {
            console.error("Can't find field with id: '" + fieldId + "'");
            return;
        }
        const predicateName = getPredicateName(field);
        if (predicateName) {
            dispatch(fetchPredicate(finderId, predicateName, fieldId));
        }
        dispatch(sendCriteriaField(finderId, criteriaId, fieldId));
    };
}