import { Action } from "redux";

import { FetchError } from "./error";
import {
  CancelCallback,
  CloseCallback,
  ModalOptions,
  OkCallback,
} from "./modal";
import { AlertLevelType } from "./alert";
import {
  FinderOptions,
  FinderOptionsSettings,
  ServerFinderFilter,
  ServerFinderFilterWithQuery,
} from "./finder";

import * as constants from "../constants/table";
import { NptTableColumnProps } from "../components/table/AsyncTable";

/* API for using external table from react */
export interface ExternalTableApi {
  /**Table ID. Using it to identify redux store data location.*/
  tableId: string;
  /**Height of table (100% by default) */
  height?: number;
  /**Scrollable flag of table (true by default) */
  scrollable?: boolean;
  /**View options of gant diagram */
  gantViewOptions?: TableGantOptionsSettings;
  /**View options of table finder */
  finderViewOptions?: FinderOptionsSettings;
  /**Initial fields values that will be traversed to table */
  initialFields?: { [k: string]: string };
  /**Forced fields values that will be traversed to table */
  forcedFields?: { [k: string]: string };
  /**Initial sorting that will be traversed to table */
  initialSorting?: ColumnSortInfo[];
  /**Flag that determinate should table update query params or not */
  paramsReadOnly?: boolean;
  /**Flag that determinate should table handle filtering|sorting on client side or not */
  clientSideFilter?: boolean;
  /**Flag that determinate should table reset fields values or not */
  resetFields?: boolean;
  /**External table will define all data functions automatically */
  external: true;
  /**Table drag options */
  drag?: TableDragViewOptions;
  /**Table drop options */
  drop?: TableDropViewOptions;
  /**Toolbar visibility flag */
  toolbarHidden?: boolean;
  /**Toolbar items visibility flag */
  toolbarHiddenItems?: { [k: string]: boolean };
  /**Callback function to be called after row select or any other changes of selection */
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
  /**Function that will be called on fetch to dynamically change request filter */
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
}

/* Common API for using local table from react */
interface LocalTableApi {
  /**Table ID. Using it to identify redux store data location.*/
  tableId?: string;
  /**Height of table (100% by default) */
  height?: number;
  /**Scrollable flag of table (true by default) */
  scrollable?: boolean;
  /**External table will define all data functions automatically */
  external: false;
  /**Display indicator that table is loading*/
  loading?: boolean;
  /**Display indicator that table data is loading*/
  loadingData?: boolean;
  /**Error data that occured on table loading*/
  error?: null | FetchError;
  /**Info about error, that occured during data fetch */
  errorData?: null | FetchError;
  /**Stylesheet map*/
  stylesheets?: { [k: string]: string };
  /**Info about table parameters*/
  // parameters?: { [k: string]: TableParameter }
  /**Values of table parameters*/
  // fields?: { [k: string]: string }
  /**Report items of table*/
  // reports?: ToolbarReport[]
  /**Array of table toolbar items*/
  // toolbar: ToolbarItem[],
  /**Array of table columns*/
  // columns: Column[],
  /**Array of table rows*/
  // data: RowData[] | RowDataMap[];
  /**Select type of table (null if table rows cannot be selected) */
  selectType?: SelectType | null;
  /**Map of row selected flag by key */
  selectedRows?: string[] | null;
  /**Counter of selected rows */
  // selectedRowsLength: number
  /**Total table rows counter */
  // totalRowsLength: number
  /**Info about table sorting */
  // sortInfo: SortInfo
  /**Info about table filtering */
  // filterInfo: FilterInfo
  /**Automation script */
  automation?: (() => void) | string;
  /**Toolbar visibility flag */
  toolbarHidden?: boolean;
  /**Callback function to be called after row select or any other changes of selection */
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
}

/* API for using local non-pageable table from react */
export interface LocalSimpleTableApi extends LocalTableApi {
  /**Flag that show if this table have pagination */
  pageable: false;
}

/* API for using pageable table from react */
export interface LocalPageableTableApi extends LocalTableApi {
  /**Flag that show if this table have pagination */
  pageable: true;
  /**Selected page of table */
  page?: number;
  /**Size of one page */
  pageSize: number;
  /**Possible values of table page size */
  pageSelection: number[];
}

export type TableApi =
  | ExternalTableApi
  | LocalSimpleTableApi
  | LocalPageableTableApi;

export function instanceOfExternalTableApi(
  props: TableApi
): props is ExternalTableApi {
  return props.external === true;
}

export function isExternalTable(
  columns?: NptTableColumnProps[],
  toolbar?: ToolbarItem[],
  rows?: RowData[] | RowDataMap[]
) {
  return !columns && !toolbar && !rows;
}
export function isLocalTable(
  columns?: NptTableColumnProps[],
  toolbar?: ToolbarItem[],
  rows?: RowData[] | RowDataMap[]
) {
  return !columns && !toolbar && !rows;
}
export function instanceOfLocalSimpleTableApi(
  props: TableApi
): props is LocalSimpleTableApi {
  return props.external === false && props.pageable === false;
}

export function instanceOfLocalPageableTableApi(
  props: TableApi
): props is LocalPageableTableApi {
  return props.external === false && props.pageable === true;
}

/**Updating data of local table */
export interface LocalTableData {
  /**List of local table rows data (must contain all rows data even for pageable tables) */
  data: RowData[];
  /**Loading flag of table */
  loading?: boolean;
  /**Data loading flag of table */
  loadingData?: boolean;
  /**Info about error, that occured during fetch */
  error?: null | FetchError;
  /**Info about error, that occured during data fetch */
  errorData?: null | FetchError;
}

/**Initial data of local table */
export interface InitialLocalTableData extends LocalTableData {
  /**List of columns options */
  columns: Column[];
  /**Map of parameters options by name */
  parameters: { [k: string]: TableParameter };
  /**Map of fields values by parameter name */
  fields: { [k: string]: string };
  /**List of reports items */
  reports: ToolbarReport[];
  /**List of toolbar items */
  toolbar: ToolbarItem[];
  /**Select type of table */
  selectType: SelectType | null;
  /**Select type of table */
  selectedRows?: { [k: string]: boolean };
  /**Select type of table */
  selectedRowsLength?: number;
  /**Automation script */
  automation?: string;
  /**Flag that show if this table have pagination */
  pageable?: boolean;
  /**Selected page of table */
  page?: number;
  /**Size of one page */
  pageSize?: number;
  /**Possible values of table page size */
  pageSelection?: number[];
}

/**Map of table states in reducer state */
export interface TableReducerState {
  [tableId: string]: TableState;
}

/**State of table */
export interface TableState {
  /**Table ID. May be used to identify redux store data location.*/
  tableId: string;
  /**Display indicator that table is loading*/
  loading: boolean;
  /**Display indicator that table data is loading*/
  loadingData: boolean;
  /**Map of currently loading pages*/
  loadingPages: {
    [p_ps: string]: boolean;
  };
  /**Display indicator that error occured on table loading*/
  error: null | FetchError;
  /**Display indicator that error occured on table data loading*/
  errorData: null | FetchError;
  /**Display indicator that table is saving*/
  saving: boolean;
  /**Display indicator that error occured on table data saving*/
  savingError: null | FetchError;
  /**Special stylesheets defined in model */
  stylesheets: { [k: string]: string };
  /**Parameters of table */
  parameters: { [k: string]: TableParameter };
  /**Fields values of table */
  fields: { [k: string]: string };
  /**Id's of page rows in display sequence
   * NOTE: We use page data as default data list.
   *       If table isn't pageable - then use it as single page table.
   */
  pageRows: number[];
  /**Counter of currently selected rows */
  selectedRowsLength: number;
  /**Map of selected rows*/
  selectedRows: {
    /**True if row is selected */
    [k: string]: boolean;
  };
  /**List of table toolbar items */
  toolbar: ToolbarItem[];
  /**List of table report items */
  reports: ToolbarReport[];
  /**List of table columns */
  columns: Column[];
  /**List of selected columns for filter */
  dynamicColumns: string[] | null;
  /**Map of dynamically hidden columns for filter */
  dynamicHiddenColumns: { [k: string]: boolean };
  /**Map of row data by it idx */
  rowByIdx: { [rowIdx: number]: RowData };
  /**Total count of rows */
  totalRowsLength: number;
  /**Possible page size values list */
  pageSelection: number[];
  /**Number of rows on one page (equals -1 if table is not pageable) */
  pageSize: number;
  /**Number of displayed page */
  page: number;
  /**Flag that show if this table have pagination */
  pageable: boolean;
  /**Selection type of table */
  selectType: SelectType | null;
  /**Bindings of table automation */
  automation: AutomationBindings;
  /**Sorting info of table */
  sortInfo: SortInfo;
  /**Filtering info of table */
  filterInfo: FilterInfo;
  /**Changed filter data */
  filterChanges: TableFilterChanges | null;
  /**Options of gant diagram (if exists) */
  gantOptions: TableGantOptions | null;
  /**Calculated data of gant diagram for printing */
  gantData: TableGantData;
  /**Table drag options */
  dragOptions: TableDragOptions | null;
  /**Table drop options */
  dropOptions: TableDropOptions | null;
  /**Flag if client-side filtering|sorting is used */
  clientSideFilter: boolean;
  /**Data of client-side filtering|sorting */
  clientSideData: TableClientSideData | null;
}
export type SelectType = "radio" | "checkbox";

/**Binding funcitons ids map of table automation */
export interface AutomationBindings {
  /**
   * Get class of cell by column
   * (col, function) => className
   */
  cellClassBindings: { [col: string]: string } | null; //(cell, row, rowIndex, columnIndex, theme) => className

  /**
   * Get value of cell by column
   * (col, function) => value
   */
  valueBindings: { [col: string]: string } | null; //(cell, row) => value

  /**
   * Get displayed text of cell (may be different than value) by column
   * (col, function) => text
   */
  textBindings: { [col: string]: string } | null; //(cell, row) => text

  /**
   * Get column visibility by rows
   * (col, function) => isVisible
   */
  visibilityBindings: { [col: string]: string } | null; //(rows, fields) => isVisible

  /**
   * Get toolbar item visibility by rows
   * (id, function) => isVisible
   */
  visibilityToolbarBindings: { [id: string]: string } | null; //(rows, fields) => isVisible

  /**
   * Get accumulate value of field by parsing rows
   * (accum, initialValue, function) => accumValue
   */
  accumBindings: { [accum: string]: string } | null; //(row, prev) => accumValue

  /**
   * Get accumulate value of field by parsing rows and accumulate values
   * (accum, initialValue, function) => accumValue
   */
  accumFilterBindings: { [accum: string]: string } | null; //(row, prev) => accumValue

  /**
   * Get initial values of field by parsing rows and accumulate values
   * (accum, initialValue, function) => initialAccumValue
   */
  accumInitialValues: { [accum: string]: string } | null; //(row, prev) => initialAccumValue

  /**
   * Get action to be performed on button click
   * (id, func) => action
   */
  clickBindings: { [buttonId: string]: string } | null; //(table) => action

  /**
   * Get action to be performed after table data is received
   * (func) => action
   */
  postProcessBinding: string | null; //(values) => action
}

/**Bindings to store automations after eval user script */
export interface AutomationBindingFunctions {
  /**Store cell class bindings */
  bindCellClass: (col: string, func: Function) => void;
  /**Store cell value bindings */
  bindValue: (col: string, func: Function) => void;
  /**Store cell text bindings */
  bindText: (col: string, func: Function) => void;
  /**Store row visibility bindings */
  bindVisible: (col: string, func: Function) => void;
  /**Store toolbar item visibility bindings */
  bindToolbarVisible: (id: string, func: Function) => void;
  /**Store accumulate bindings */
  bindAccum: (accum: string, initialValue: any, func: Function) => void;
  /**Store accumulate bindings */
  bindFilterAccum: (accum: string, initialValue: any, func: Function) => void;
  /**Store button click bindings */
  bindClick: (id: string, func: Function) => void;
  /**Store post process bindings */
  bindPostProcess: (func: Function) => void;
}

/**Special functions to provide automations necessary methods and fields from state */
export interface AutomationTableModule {
  /**Get fields values of table */
  fields: { [k: string]: string };
  /**Get columns of table */
  columns: Column[];
  /**Get data of table rows (without filtering) */
  data: RowDataMap[];
  /**Get data of table rows (without filtering, with changes) */
  changed: RowDataMap[];
  /**Legacy support */
  /**TODO: remove as deprecated */
  props: {
    fields: { [k: string]: string };
    columns: Column[];
    data: RowDataMap[];
  };
  /**Get data of filtered table rows */
  getFilteredRows: () => RowDataMap[];
  /**Get data of filtered table rows (with changes) */
  getFilteredChangedRows: () => RowDataMap[];
  /**Reload table data */
  reload: (parameters?: { [k: string]: string }) => void;
  /**Open modal (id will be generated automatically) */
  openModal: (
    type: string,
    options: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback
  ) => void;
  /**Add alert */
  addAlert: (
    message: string,
    options?: { type?: AlertLevelType; icon?: string }
  ) => void;
  /**Check row selected state */
  isSelected: (row: RowDataMap) => boolean;
  /**Add new row */
  addRow: (row: RowDataMap) => void;
  /**Change row data */
  changeRow: (row: RowDataMap, rowIdx: number) => void;
  /**Change cell data */
  changeCell: (value: any, rowIdx: number, column: string) => void;
  /**Save current table filter */
  saveFilter: () => void;
  /**Reset current table filter */
  resetFilter: () => void;
}

/**Format of server data object to be send to server */
export interface ServerTableData {
  /**Array of columns */
  columns: string[];
  /**Fields data */
  fields: { [k: string]: string };
  /**Rows data */
  rows: any[][];
}

/**Sorting info of table */
export interface SortInfo {
  /**Array of columns sorting info */
  columns: ColumnSortInfo[];
}
/**Sorting info of column */
export interface ColumnSortInfo {
  field: string;
  direction: SortDirection;
}
export type SortDirection = "asc" | "desc";

/**Filtering info of table */
export interface FilterInfo {
  /**Array of columns filter info */
  columns: ColumnFilterInfo[];
  /**Selection data by column field */
  selectionByField: { [k: string]: FilterSelection };
  /**Range of data by column field */
  rangeByField: { [k: string]: FilterRange };
}
/**Sorting info of column */
export interface ColumnFilterInfo {
  field: string;
  data: FilterData;
}
export type FilterType =
  | "string"
  | "number"
  | "date"
  | "date_time"
  | "hash"
  | "boolean";

/**Format of server object to be send to server */
export interface ServerTableFilter {
  /**Array of columns */
  columns: string[] | null;
  /**Finder info */
  finder: ServerFinderFilterWithQuery | null;
  /**Special selectors */
  keyFilters: any;
  /**Filters of columns */
  columnFilters: ServerColumnFilter[] | null;
}

/**Format of column filter on server */
export interface ServerColumnFilter {
  /**Field of column */
  field: string;
  /**Type of filter */
  type: FilterType;
  /**Filter of column */
  filter: ServerFilterData;
}

export interface FilterSelection {
  loading: boolean;
  error: boolean;
  selection: any[];
}

export interface FilterRange {
  loading: boolean;
  error: boolean;
  range: {
    min: any;
    max: any;
  } | null;
}

/**Info for column resizing */
export interface ColumnResizeInfo {
  /**Index of previous visible column (or undefined if resizing to left is disabled)*/
  prevColumnIdx?: number;
  /**Index of column (to fast identify column index on resizer grab)*/
  curColumnIdx?: number;
  /**Index of next visible column (or undefined if resizing to right is disabled)*/
  nextColumnIdx?: number;
}

/**Data of filter modal */
export interface StringFilterData {
  type: "string";
  contain: string;
  selected: { [k: string]: boolean };
  allowNulls: boolean;
}

export interface NumberFilterData {
  type: "number";
  operation: EqualityValue | "between";
  value: number | null;
  valueFrom: number | null;
  valueTo: number | null;
  allowNulls: boolean;
}

export interface DateFilterData {
  type: "date";
  operation: EqualityValue | "between";
  value: string | null;
  valueFrom: string | null;
  valueTo: string | null;
  allowNulls: boolean;
}

export interface DateTimeFilterData {
  type: "date_time";
  operation: EqualityValue | "between";
  value: string | null;
  valueFrom: string | null;
  valueTo: string | null;
  allowNulls: boolean;
}

export interface BooleanFilterData {
  type: "boolean";
  selected: boolean | null;
  allowNulls: boolean;
}

export interface HashFilterData {
  type: "hash";
  allowNulls: boolean;
}

export function isStringFilterData(
  filterData: any
): filterData is StringFilterData {
  return filterData?.type === "string";
}
export function isNumberFilterData(
  filterData: any
): filterData is NumberFilterData {
  return filterData?.type === "number";
}
export function isDateFilterData(
  filterData: any
): filterData is DateFilterData {
  return filterData?.type === "date";
}
export function isDateTimeFilterData(
  filterData: any
): filterData is DateTimeFilterData {
  return filterData?.type === "date_time";
}
export function isBooleanFilterData(
  filterData: any
): filterData is BooleanFilterData {
  return filterData?.type === "boolean";
}
export function isHashFilterData(
  filterData: any
): filterData is HashFilterData {
  return filterData?.type === "hash";
}
export interface TableFilterItem {
  label: string;
  value: string;
}

export type EqualityValue =
  | "less"
  | "lessOrEqual"
  | "equal"
  | "more"
  | "moreOrEqual";

export type FilterData =
  | StringFilterData
  | NumberFilterData
  | DateFilterData
  | DateTimeFilterData
  | BooleanFilterData;

export interface ServerEmptyFilterData {
  allowNulls: boolean;
}
export interface ServerStringFilterData {
  contains: string;
  selected: string[] | null;
  allowNulls?: boolean;
}
export interface ServerNumberFilterData {
  op: EqualityValue | "between";
  v?: number | null;
  f?: number | null;
  t?: number | null;
  allowNulls?: boolean;
}
export interface ServerDateFilterData {
  op: EqualityValue | "between";
  v?: string | null;
  f?: string | null;
  t?: string | null;
  allowNulls?: boolean;
}
export interface ServerDateTimeFilterData {
  op: EqualityValue | "between";
  v?: string | null;
  f?: string | null;
  t?: string | null;
  allowNulls?: boolean;
}
export interface ServerBooleanFilterData {
  selected: boolean;
  allowNulls?: boolean;
}
export type ServerFilterData =
  | ServerEmptyFilterData
  | ServerStringFilterData
  | ServerNumberFilterData
  | ServerDateFilterData
  | ServerDateTimeFilterData
  | ServerBooleanFilterData;

export interface TableFilterChanges {
  fields: { [k: string]: string } | null;
}

/**Single table row data (we store it as a map<string, string|number|null>) */
export interface RowData {
  /**Row data after bindings */
  bindedData: RowDataMap;
  /**Original row data */
  data: RowDataMap;
  /**Changed flags of row */
  changed: { [k: string]: boolean } | null;
  /**Changed data of row */
  changedData: RowDataMap;
  /**Special classes, generated by automations */
  classes: { [field: string]: string };
}

export interface RowDataMap {
  [field: string]: any | null;
}

export function isRowDataList(data: any): data is RowData[] {
  const rowData = data as RowData[];
  if (!rowData) {
    return false;
  }
  if (rowData.length === 0) {
    return true;
  }
  return isRowData(rowData[0]);
}

export function isRowData(row: any): row is RowData {
  const rowData = row as RowData;
  if (!rowData) {
    return false;
  }
  return Boolean(
    rowData.bindedData &&
      rowData.changedData &&
      rowData.data &&
      rowData.changed &&
      rowData.classes
  );
}

/**Table pagination data */
export interface TablePagination {
  size: number;
  selection: number[];
}

/**Table gant options settings (used in table API) */
export interface TableGantOptionsSettings {
  /**Date limits of visible part of diagram */
  dateLimits: GantDateLimitsOptionsSettings;
  /**Info about chosen data groups */
  gantGroups?: GantGroupSettings[];
  /**Info about planned data groups */
  gantPlannedGroups: GantPlannedGroupsSettings;
  /**Info about gant permissions */
  permissions: GantPermissions;
  /**Scale of diagram */
  scale: GantScale;
  /**Rounding of diagram values */
  rounding: GantScale;
  /**Initial rounding of diagram data */
  initialRoundingType: GantRoundingType;
  /**Rounding of data on data change */
  roundingType: GantRoundingType;
  /**Width of diagram headers (relevant value from 0 to 100 in %) */
  headersWidth: string;
  /**Height of diagram elements (in px) */
  elementHeight: number;
  /**Minimum width of scale cell */
  minCellWidth: number;
  /**Flag to prevent separation of weeks on month start/end */
  fullWeeks: boolean;
  /**Flag to allow only one chosen data element on row */
  singleElementOnRow: boolean;
}

/**Table gant options */
export interface TableGantOptions {
  /**Date limits of visible part of diagram */
  dateLimits: GantDateLimitsOptions;
  /**Info about chosen data groups */
  gantGroups: GantGroup[];
  /**Info about planned data groups */
  gantPlannedGroups: GantPlannedGroups;
  /**Info about gant permissions */
  permissions: GantPermissions;
  /**Selected scale of diagram */
  scale: GantScale;
  /**Rounding of diagram values */
  rounding: GantScale;
  /**Width of diagram headers (relevant value from 0 to 1) */
  headersWidth: number;
  /**Height of diagram elements (in px) */
  elementHeight: number | null;
  /**Minimum width of scale cell */
  minCellWidth: number;
  /**Initial rounding of diagram data */
  initialRoundingType: GantRoundingType;
  /**Rounding of data on changing */
  roundingType: GantRoundingType;
  /**Flag to prevent separation of weeks on month start/end */
  fullWeeks: boolean;
  /**Flag to allow only one chosen data element on row */
  singleElementOnRow: boolean;
}

/**
 * Table gant date limits options.
 * Values can have fields placeholders.
 * Example: {year}-{month}-01 */
export interface GantDateLimitsOptionsSettings {
  /**Static from value */
  from?: string;
  /**Static to value */
  to?: string;
  /**Maximum from value when date limits is calculated via data */
  maxFrom?: string;
  /**Minimum to value when date limits is calculated via data */
  minTo?: string;
}

/**
 * Table gant date limits options.
 * Values can have fields placeholders.
 * Example: {year}-{month}-01 */
export interface GantDateLimitsOptions {
  /**Static from value */
  from: string | number | null;
  /**Static to value */
  to: string | number | null;
  /**Maximum from value when date limits is calculated via data */
  maxFrom: string | number | null;
  /**Minimum to value when date limits is calculated via data */
  minTo: string | number | null;
}

/**Info of gant chosen group */
export interface GantGroupSettings {
  id: string;
  name: string;
  color: string;
}

/**Info of gant chosen group */
export interface GantGroup {
  id: string;
  name: string;
  color: string;
  borderColor: string;
}

/**Info of gant planned groups */
export interface GantPlannedGroupsSettings {
  plannedWork?: GantPlannedGroup;
  plannedDocs?: GantPlannedGroup;
}

/**Info of gant planned groups */
export interface GantPlannedGroups {
  plannedWork: GantPlannedGroup;
  plannedDocs: GantPlannedGroup;
}

/**Info of gant single planned group */
export interface GantPlannedGroup {
  id: string;
  name: string | null;
  color: string;
  borderColor: string;
}

/**Permissions to change gant data */
export interface GantPermissions {
  value: GantPermissionsValue;
  chosenOnly: boolean;
}

/**Table gant printing data */
export interface TableGantData {
  /**Calculated date limits for received data */
  dateLimits: GantDateLimits;
  /**Order of displaying scales */
  displayScales: GantDisplayScale[];
  /**Name of main scale to be printed in gant body */
  scale: GantScale;
  /**Header cells info for selected scale */
  cellsByScale: {
    year?: GantHeaderCell[];
    month?: GantHeaderCell[];
    week?: GantHeaderCell[];
    day?: GantHeaderCell[];
    hour?: GantHeaderCell[];
  };
  /**Calciulated minimum width of gant diagram */
  gantMinWidth: number;
  /**Selected view type of gant diagram */
  viewType: GantViewType;
  /**Selected id of group that will be setted to added element */
  selectedGroup: string;
}

export interface GantDateLimits {
  /**Start date of diagram view (in ms) */
  from: number;
  /**End date of diagram view (in ms) */
  to: number;
}

export interface GantHeaderCell {
  /**Width of cell (in px) */
  width: number;
  /**Text value of cell */
  value: string;
  /**Formatted id of cell text */
  formattedValue?: string;
  /**Number of minimal displayed scale cells that contain this cell */
  cellspan: number;
}

export type GantDisplayScale = "year" | "month" | "week" | "day" | "hour";
export type GantScale = GantDisplayScale | "half-day";
export type GantRoundingType = "floor" | "auto" | "ceil";
export type GantPermissionsValue = "full" | "change" | "read";
export type GantViewType = "read" | "chosenDate" | "plannedDate";

/**Data of gant diagram single row */
export interface GantRowData {
  chosenDate: GantElementData[];
  plannedDate: GantElementData[];
}

/**Data of gant diagram single element */
export interface GantElementData {
  /**String value of start date */
  from: string;
  /**String value of end date */
  to: string;
  /**Flag if element should have dashed background */
  dashed: boolean;
  /**Group(s) of element */
  group: {
    id: string;
    name?: string;
    color?: string;
  }[];
}

/**Table parameter data */
export interface TableParameter {
  name: string;
  label: string;
  type: TableParameterType;
}
export type TableParameterType = "string" | "number" | "date" | "boolean";

/**Compose all toolbar item types */
export type ToolbarItem =
  | CommonToolbarItem
  | ToolbarField
  | ToolbarCopyRefs
  | ToolbarGroup;

/**Table toolbar item properties */
export interface CommonToolbarItem {
  /**Unique id of item */
  id: string;
  /**Displayed label of item */
  label: string;
  /**Type of item */
  type: ToolbarItemType;
  /**Specified width of item (in px) */
  width?: number;
  /**Specified variant of item */
  variant?: string;
  /**Id of formatted message to localize standart labels */
  formattedId?: string;
  /**Format of item for special view parsing */
  format?: ToolbarItemFormat;
  /**Icon of item */
  icon?: string;
  /**Flag if item is hidden */
  hidden?: boolean;
  /**Acl list to check user rights to see item */
  acl?: string;
}
export type ToolbarItemFormat =
  | "string"
  | "date"
  | "dateTime"
  | "boolean"
  | "integer"
  | "float"
  | "selectColumn"
  | "view"
  | "group"
  | "save";
export type ToolbarItemType =
  | "button"
  | "field"
  | "report"
  | "gant"
  | "copyRefs"
  | "dropdown";

/**Table toolbar field properties */
export interface ToolbarField extends CommonToolbarItem {
  /**Width of toolbar field in px (if not specified default value will be used) */
  width?: number;
  /**Path to get field value from row data */
  path: string;
}

export function isToolbarField(toolbarItem: any): toolbarItem is ToolbarField {
  return toolbarItem && toolbarItem.type === "field";
}

/**Table toolbar reports link properties */
export interface ToolbarReport {
  /**Label of report link */
  label: string;
  /**Id of report to prepare link */
  name: string;
  /**Name of report file on server */
  file: string;
  /**Type of report file */
  type: ToolbarReportType;
  /**Name of report file for downloading */
  filename?: string;
}
export type ToolbarReportType =
  | "xlsx"
  | "docx"
  | "docx_to_pdf"
  | "docx_to_xhtml";

/**Table toolbar copyRefs button properties */
export interface ToolbarCopyRefs extends CommonToolbarItem {
  /**Default value of ref namespace */
  namespace?: string;
  /**Default value of ref rdfId */
  rdfId?: string;
}

export function isToolbarCopyRefs(
  toolbarItem: any
): toolbarItem is ToolbarCopyRefs {
  return toolbarItem && toolbarItem.type === "copyRefs";
}

/**Table toolbar item group (for now only dropdowns) properties */
export interface ToolbarGroup {
  /**Unique id of group */
  id: string;
  /**Displayed label of toolbar group */
  label: string;
  /**Type of group */
  type: ToolbarGroupType;
  /**Items of toolbar group */
  items: ToolbarItem[];
  /**Specified width of item (in px) */
  width?: number;
  /**Specified variant of item */
  variant?: string;
  /**Id of formatted message to localize standart labels */
  formattedId?: string;
  /**Icon of group */
  icon?: string;
  /**Flag if group is hidden */
  hidden?: boolean;
  /**Acl list to check user rights to see group */
  acl?: string;
}
export type ToolbarGroupType = "dropdown";

export function isToolbarGroup(
  toolbarItem: ToolbarItem
): toolbarItem is ToolbarGroup {
  return toolbarItem && toolbarItem.type === "dropdown";
}

interface TableCommonSelectOption {
  value: string;
}
export interface TableStringSelectOption extends TableCommonSelectOption {
  msg: string;
}
export interface TableFormattedSelectOption extends TableCommonSelectOption {
  formattedMsg: string;
}
export type TableSelectOption =
  | TableStringSelectOption
  | TableFormattedSelectOption;

export function isTableFormattedOption(
  toolbarMsg: any
): toolbarMsg is TableFormattedSelectOption {
  return toolbarMsg && typeof toolbarMsg.formattedMsg === "string";
}

/**Table columns properties */
export interface CommonColumn {
  /**Original value of column width, may be in px or in % */
  originalWidth?: number | string;
  /**Width of column in px (if not specified it will be calculated automaticly) */
  width?: number;
  /**Minimal width of column in px */
  minWidth?: number;
  /**Width coefficient for resizing */
  widthCoeff?: number;
  /**Name of column field in row (to receive value) */
  field: string;
  /**Format of column value in row data (make difference on cell parsing step) */
  format: ColumnFormat;
  /**Type of column filter or null is column doesn't have filter */
  filterType: FilterType | null;
  /**Visible name of column */
  name: string;
  /**Flag of column visibility */
  hidden?: boolean;
  /**Flag of dynamic column */
  dynamic?: boolean;
  /**Key column is unique identifier of row */
  key?: boolean;
  /**Key of special generated columns */
  autoGenerated?: boolean;
  /**Path to reference if column has subject reference format */
  subjectRef?: string;
  /**Path to file if column has file reference format */
  fileRef?: string;
  /**Info for column resizing (if undefined - then column cannot be resized)*/
  resizeInfo?: ColumnResizeInfo;
  /**Function that will be used to show cell*/
  dataFormat?: (cell: any, row: RowDataMap | any) => void;
}

/**Extended properties of currency column */
export interface CurrencyColumn extends CommonColumn {
  /**Name of integer part of currency */
  currencyName: string | null;
  /**Name of fractional part of currency */
  currencyCents: string | null;
}

export type Column = CommonColumn | CurrencyColumn;

export type ColumnFormat =
  | "string"
  | "number"
  | "html"
  | "boolean"
  | "selectColumn"
  | "date"
  | "dateTime"
  | "currency"
  | "list";

/**Initial options parsed to table */
export interface TableInitialOptions {
  initialSorting?: ColumnSortInfo[];
  gantViewOptions?: TableGantOptionsSettings;
  finderViewOptions?: FinderOptionsSettings;
  drag?: TableDragViewOptions;
  drop?: TableDropViewOptions;
  paramsReadOnly?: boolean;
  resetFields?: boolean;
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
  forcedFields?: { [k: string]: string };
}

/**Received from action table info */
export interface TableHeader {
  parameters: { [k: string]: TableParameter };
  stylesheets: { [k: string]: string };
  toolbar: ToolbarItem[];
  reports: ToolbarReport[];
  columns: Column[];
  pageable: boolean;
  pagination: TablePagination;
  selectType: SelectType | null;
  automation: string | null;
  gantOptions: TableGantOptions | null;
  finderOptions: FinderOptions | null;
  dragOptions: TableDragOptions | null;
  dropOptions: TableDropOptions | null;
  userSettings: TableUserSettings | null;
}

/**Received from action table data */
export interface TableData {
  fields: { [k: string]: string };
  rows: RowData[];
  page: number;
  pageSize: number;
  totalRowsLength?: number;
}

/**Table DnD options used in viewcards */
export interface TableDragViewOptions {
  type: string;
  row?: boolean;
  columns?: string[];
  collect?: (dragInfo: {
    cell?: any;
    row?: RowData;
    rowIdx?: number;
    column?: string;
  }) => any;
}

export interface TableDropViewOptions {
  accepts: string | string[];
  table?: boolean;
  row?: boolean;
  columns?: string[];
  resolveDrop?: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    table: AutomationTableModule
  ) => any;
}

/**Table DnD options stored in redux */
export interface TableDragOptions {
  type: string;
  row?: boolean;
  columns?: string[];
  collectFunctionId: string | null;
}

export interface TableDropOptions {
  accepts: string[];
  table?: boolean;
  row?: boolean;
  columns?: string[];
  resolveDropFunctionId: string | null;
}

/**Table request options */
export interface TableRequest {
  parameters?: { [k: string]: string };
  filter?: ServerTableFilter | null;
  range?: TableRequestRange;
  searchInput?: string;
  metadata?: boolean;
}

export interface TableRequestRange {
  offset?: number;
  limit?: number;
  sortItemList?: TableRequestSorting[];
}

export interface TableRequestSorting {
  name: string;
  ascending: boolean;
}

export interface TableUserSettings {
  fields: { [k: string]: string };
  dynamicColumns: string[] | null;
  dynamicHiddenColumns: { [k: string]: boolean };
  sortInfo: SortInfo;
  filterInfo: FilterInfo;
  finderInfo: any;
}

export interface TableClientSideData {
  filteredRows: number[];
  sortedRows: number[];
}

///////////
//Actions//
///////////
export interface SendTableInitialData extends Action {
  type: constants.SEND_TABLE_INITIAL_DATA;
  tableId: string;
  payload: {
    initialSorting?: ColumnSortInfo[];
  };
}

export interface SendTableHeader extends Action {
  type: constants.SEND_TABLE_HEADER;
  tableId: string;
  payload: {
    header: TableHeader;
  };
}

export interface SendTableData extends Action {
  type: constants.SEND_TABLE_DATA;
  tableId: string;
  payload: {
    data: TableData;
    reset: boolean;
    savePage: boolean;
    getAutomationTableModule: (state: TableState) => AutomationTableModule;
  };
}

export interface SendTableLoading extends Action {
  type: constants.SEND_TABLE_LOADING;
  tableId: string;
  payload: null;
}

export interface SendTableError extends Action {
  type: constants.SEND_TABLE_ERROR;
  tableId: string;
  payload: {
    error: FetchError;
  };
}

export interface SendTableDataLoading extends Action {
  type: constants.SEND_TABLE_DATA_LOADING;
  tableId: string;
  payload: {
    page: number;
    pageSize: number;
  };
}

export interface SendTableDataError extends Action {
  type: constants.SEND_TABLE_DATA_ERROR;
  tableId: string;
  payload: {
    error: FetchError;
    page: number;
    pageSize: number;
  };
}

export interface SendTableFilterSelectionLoading extends Action {
  type: constants.SEND_TABLE_FILTER_SELECTION_LOADING;
  tableId: string;
  payload: {
    field: string;
  };
}

export interface SendTableFilterSelectionError extends Action {
  type: constants.SEND_TABLE_FILTER_SELECTION_ERROR;
  tableId: string;
  payload: {
    field: string;
    error: FetchError;
  };
}

export interface SendTableFilterSelection extends Action {
  type: constants.SEND_TABLE_FILTER_SELECTION;
  tableId: string;
  payload: {
    field: string;
    selection: any[];
  };
}

export interface SendTableFilterRangeLoading extends Action {
  type: constants.SEND_TABLE_FILTER_RANGE_LOADING;
  tableId: string;
  payload: {
    field: string;
  };
}

export interface SendTableFilterRangeError extends Action {
  type: constants.SEND_TABLE_FILTER_RANGE_ERROR;
  tableId: string;
  payload: {
    field: string;
    error: FetchError;
  };
}

export interface SendTableFilterRange extends Action {
  type: constants.SEND_TABLE_FILTER_RANGE;
  tableId: string;
  payload: {
    field: string;
    range: {
      min: any;
      max: any;
    };
  };
}

export interface SendTableSortOptions extends Action {
  type: constants.SEND_TABLE_SORT_OPTIONS;
  tableId: string;
  payload: {
    sortColumns: ColumnSortInfo[];
    filterColumns: string[];
  };
}

export interface SendTableColumns extends Action {
  type: constants.SEND_TABLE_COLUMNS;
  tableId: string;
  payload: {
    selectedColumns: string[] | null;
    hiddenColumns: { [k: string]: boolean };
  };
}

export interface SendSelectRow extends Action {
  type: constants.SEND_SELECT_ROW;
  tableId: string;
  payload: {
    key: string;
  };
}

export interface SendSelectAll extends Action {
  type: constants.SEND_SELECT_ALL;
  tableId: string;
  payload: null;
}

export interface SendPageSize extends Action {
  type: constants.SEND_PAGE_SIZE;
  tableId: string;
  payload: {
    pageSize: number;
  };
}

export interface SendPage extends Action {
  type: constants.SEND_PAGE;
  tableId: string;
  payload: {
    page: number;
    automationTableModule: AutomationTableModule;
  };
}

export interface SendSortKey extends Action {
  type: constants.SEND_SORT_KEY;
  tableId: string;
  payload: {
    key: string;
  };
}

export interface SendFilterData extends Action {
  type: constants.SEND_FILTER_DATA;
  tableId: string;
  payload: {
    field: string;
    filterData: any;
  };
}

export interface SendLocalTableInitialData extends Action {
  type: constants.SEND_LOCAL_TABLE_INITIAL_DATA;
  tableId: string;
  payload: InitialLocalTableData;
}

export interface SendLocalTableUpdateData extends Action {
  type: constants.SEND_LOCAL_TABLE_UPDATE_DATA;
  tableId: string;
  payload: LocalTableData;
}

export interface SendLocalTableUninitialize extends Action {
  type: constants.SEND_LOCAL_TABLE_UNINITIALIZE;
  tableId: string;
  payload: null;
}

export interface SendField extends Action {
  type: constants.SEND_FIELD;
  tableId: string;
  payload: {
    parameter: string;
    value: string;
  };
}

export interface SendGantViewType extends Action {
  type: constants.SEND_GANT_VIEW_TYPE;
  tableId: string;
  payload: {
    type: GantViewType;
  };
}

export interface SendGantSelectedGroup extends Action {
  type: constants.SEND_GANT_SELECTED_GROUP;
  tableId: string;
  payload: {
    group: string;
  };
}

export interface SendGantChangeElement extends Action {
  type: constants.SEND_GANT_CHANGE_ELEMENT;
  tableId: string;
  payload: {
    rowIndex: number;
    itemIndex: number;
    newDate: GantDateLimits;
    isPlanned: boolean;
  };
}

export interface SendFilterChangesConfirm extends Action {
  type: constants.SEND_FILTER_CHANGES_CONFIRM;
  tableId: string;
  payload: null;
}

export interface SendFilterChangesDeny extends Action {
  type: constants.SEND_FILTER_CHANGES_DENY;
  tableId: string;
  payload: null;
}

export interface SendTableSaveStart extends Action {
  type: constants.SEND_TABLE_SAVE_START;
  tableId: string;
  payload: null;
}

export interface SendTableSaveSuccess extends Action {
  type: constants.SEND_TABLE_SAVE_SUCCESS;
  tableId: string;
  payload: null;
}

export interface SendTableSaveError extends Action {
  type: constants.SEND_TABLE_SAVE_ERROR;
  tableId: string;
  payload: {
    error: FetchError;
  };
}

export interface SendTableRowAdd extends Action {
  type: constants.SEND_TABLE_ROW_ADD;
  tableId: string;
  payload: {
    row: RowDataMap;
  };
}

export interface SendTableRowChange extends Action {
  type: constants.SEND_TABLE_ROW_CHANGE;
  tableId: string;
  payload: {
    row: RowDataMap;
    rowIdx: number;
  };
}

export interface SendTableCellChange extends Action {
  type: constants.SEND_TABLE_CELL_CHANGE;
  tableId: string;
  payload: {
    value: any;
    rowIdx: number;
    column: string;
  };
}

export interface SendTableClientSideFilter extends Action {
  type: constants.SEND_TABLE_CLIENT_SIDE_FILTER;
  tableId: string;
  payload: {
    clientSideFilter: boolean;
  };
}

//It may be combined  with |
export type TableAction =
  | SendTableInitialData
  | SendTableHeader
  | SendTableData
  | SendTableLoading
  | SendTableError
  | SendTableDataLoading
  | SendTableDataError
  | SendSelectRow
  | SendSelectAll
  | SendPageSize
  | SendPage
  | SendSortKey
  | SendFilterData
  | SendLocalTableInitialData
  | SendLocalTableUpdateData
  | SendLocalTableUninitialize
  | SendField
  | SendGantViewType
  | SendGantSelectedGroup
  | SendGantChangeElement
  | SendTableFilterSelectionLoading
  | SendTableFilterSelectionError
  | SendTableFilterSelection
  | SendTableFilterRangeLoading
  | SendTableFilterRangeError
  | SendTableFilterRange
  | SendTableSortOptions
  | SendTableColumns
  | SendFilterChangesConfirm
  | SendFilterChangesDeny
  | SendTableSaveStart
  | SendTableSaveSuccess
  | SendTableSaveError
  | SendTableRowAdd
  | SendTableRowChange
  | SendTableCellChange
  | SendTableClientSideFilter;
