import React from "react";
import { AlertLevelType } from "../../types/alert";
import Alert from "./AlertComponent";
import { ENTER_TIMEOUT, EXIT_TIMEOUT } from "./AlertContainer";

interface AlertTimerProps {
	type: AlertLevelType
	timeout: number
	icon?: string
	headline?: string
	dismissable?: boolean
	html?: boolean
	onDismiss: (() => void) | null
};

//Alert wrapped by component which dismisses Alert by timeout 
export default class AlertTimer extends React.Component<AlertTimerProps> {
	private timer: any;
	private timerTimeout: any = null;

	constructor(props: AlertTimerProps) {
		super(props);
	}

	componentDidMount() {
		if (!!this.props.onDismiss) {
			this.setupTimer(this.props.timeout, this.props.onDismiss);
		}
	}

	componentDidUpdate(prevProps: AlertTimerProps) {
		if (
			this.props.timeout != prevProps.timeout ||
			this.props.onDismiss != prevProps.onDismiss
		) {
			this.setupTimer(this.props.timeout, this.props.onDismiss);
		}
	}

	componentWillUnmount() {
		// need to clean up after ourselves
		this.clearTimer();
	}

	clearTimer() {
		clearTimeout(this.timer);
		this.timer = null;
		this.timerTimeout = null;
	}

	setupTimer(timeout: number, onDismiss: any) {

		if (this.timer && this.timerTimeout != timeout) {
			// the timeout value has changed, setup a new timer
			clearTimeout(this.timer);
			this.timer = null;
		}

		// add new timer if we don't already have one
		if (!this.timer) {
			this.timer = setTimeout(
				this.dismissAlert.bind(this, onDismiss),
				timeout + ENTER_TIMEOUT + EXIT_TIMEOUT
			);
			this.timerTimeout = timeout;
		}

	}

	dismissAlert(onDismiss: any) {
		// clear the timer if it hasn't fired yet
		clearTimeout(this.timer);
		// we don't need to keep track of any timers for this alert anymore
		this.timer = null;
		this.timerTimeout = null;
		// actually dismiss the alert
		onDismiss();
	}

	render() {
		const onDismiss = this.props.dismissable !== false && this.props.onDismiss
			? () => { this.dismissAlert(this.props.onDismiss) }
			: undefined;
		return <Alert {...this.props} onDismiss={onDismiss} />;
	}
}

