import { ThunkAction } from 'redux-thunk'

import { ApplicationState } from '../types';

import {
    SendSCMInfo,
    SendSCMInfoError,
    SendSCMInfoLoading,
    SCMInformation,
    SCMAction
} from '../types/scm';
import { SEND_SCM_INFO, SEND_SCM_INFO_LOADING, SEND_SCM_INFO_ERROR } from '../constants/scm';
import { FetchError } from '../types/error';

class ServerError extends Error {

    code: number;

    constructor(code: number, text: string) {
        super(text)
        this.code = code;
    }
}

function fetchSCMInfoImpl(): Promise<SCMInformation> {
    return fetch("/scm/system/info").then((resp) => {
        if (!resp.ok) {
            throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
    }).then((json) => {
        return json;
    })
}

export function sendSCMInfo(info: SCMInformation): SendSCMInfo {
    return {
        type: SEND_SCM_INFO,
        payload: {
            info
        }
    }
}

export function sendSCMInfoError(error: FetchError): SendSCMInfoError {
    return {
        type: SEND_SCM_INFO_ERROR,
        payload: {
            error
        }
    }
}

export function sendSCMInfoLoading(): SendSCMInfoLoading {
    return {
        type: SEND_SCM_INFO_LOADING
    }
}

export function fetchSCMInfo(): ThunkAction<void, ApplicationState, {}, SCMAction> {
    return async (dispatch, getState) => {
        try {
            const s = getState();
            if (s && s.security.loading) {
                return;
            }
            dispatch(sendSCMInfoLoading());
            dispatch(sendSCMInfo(await fetchSCMInfoImpl()));
        } catch (e:any) {
            if (e instanceof ServerError) {
                dispatch(sendSCMInfoError({ code: e.code, message: e.message }));
            } else if (typeof e.message == 'string') {
                dispatch(sendSCMInfoError({ code: -1, message: e.message }));
            } else {
                dispatch(sendSCMInfoError({ code: -1, message: "Unknown error" }));
            }
        }
    };
}

