import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../types';
import { FetchError } from '../../types/error';
import { isSubject, Subject } from '../../types/subject';
import CardArea from './CardArea';

export interface CardFormProps {
    subjectKey: string
    subject?: Subject | FetchError
    loading?: boolean 
    className?:string
    style?:React.CSSProperties
}

/**
 * Component represents form part 
 * (which contains inputs and labels) of object card.
 */
class CardForm extends React.Component<CardFormProps> {

    constructor(props: CardFormProps) {
        super(props); 
    }

    componentDidMount() { 
    }

     
    
    render() {
        const {subjectKey,loading,subject,style,className:cn} = this.props;          
        const cardArea = isSubject(subject) && <CardArea  subjectKey={this.props.subjectKey} />;
        // const style = s || { overflowX: "hidden", overflowY: "auto" };
        const className = cn||"d-flex  flex-column  ";
        return <div className={`${className}  `} style={style}> 
            {cardArea}
        </div>
    }
}

export default connect(
    (state: ApplicationState, ownProps: { subjectKey: string }) => {
        return {
            loading: state.subject && state.subject.loading[ownProps.subjectKey],
            subject: state.subject && state.subject.subjects[ownProps.subjectKey]
        }
    } )(CardForm);