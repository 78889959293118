import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as React from 'react';
import styles from './BulbComponent.module.css'
interface BulbComponentProps {
    type?: 'success' | 'danger' | 'warning'
    children?:any
    style?: React.CSSProperties
}
export const BulbComponent: React.FC<BulbComponentProps> = ({ type, style, children }) => {
    let concreteBulb = styles.pulseRed
    switch (type) {
        case 'success': concreteBulb = styles.pulseGreen; break;
        case 'warning': concreteBulb = styles.pulseYellow; break;
    }

    return <div style={{ height: '25px', width: '25px' }} className={`  ${styles.pulse} ${concreteBulb}`}>
        {children }
    </div>
}