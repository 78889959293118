import { faCopy, faCut, faEye, faMinusSquare, faSquare, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { MenuOperations } from '../../../constants/menueditor';
import { wrapWithTooltip } from './MenuEditorPage';




export interface MenuEditorToolbarProps {
    disabled: boolean
    loading?: boolean,
    operation?: MenuOperations
    isMenuExist?: boolean
    activeMenu?: string
    delete: () => void,
    clearSelected: () => void,
    copySelected: () => void
    cutSelected: () => void
    goToView: (path: string) => void
}

export default class MenuEditorToolbar extends React.Component<MenuEditorToolbarProps> {



    renderDelete() {
        if (!this.props.isMenuExist) {
            return null;
        }
        const buttonDelete = (<Button disabled={this.props.disabled} variant="danger" active className="ml-2 px-2" onClick={this.props.delete}>
            <FontAwesomeIcon className="mr-1" icon={faMinusSquare} />
            <FormattedMessage id="MENU_EDITOR_DELETE_SELECTED_TOOLTIP" />
        </Button>);
        return buttonDelete;
    }
    renderClearSelect() {
        if (!this.props.isMenuExist) {
            return null;
        }
        const clearButton = (<Button variant="warning" disabled={this.props.disabled} active className="ml-2 px-2" onClick={this.props.clearSelected}>
            <FontAwesomeIcon className="mr-1" icon={faSquare} />
            <FormattedMessage id='MENU_EDITOR_CLEAR_SELECTED_TOOLTIP' />
        </Button>);
        return clearButton;
    }

    renderGoToView() {
        const { isMenuExist, goToView, activeMenu } = this.props; 
        if (!isMenuExist || !activeMenu) {
            return null;
        }
        return <Dropdown.Item href="#" onClick={()=>goToView(activeMenu)}>
            <FontAwesomeIcon className="mr-1" icon={faEye} />
            <FormattedMessage id='MENU_EDITOR_GO_TO_VIEW_TOOLTIP' />
        </Dropdown.Item>
    }

    renderCopySelected() {
        if (!this.props.isMenuExist) {
            return null;
        }
        const { operation } = this.props;

        return <Dropdown.Item active={operation && operation === MenuOperations.COPY_ITEM} href="#" onClick={this.props.copySelected}>
            <FontAwesomeIcon className="mr-1" icon={faCopy} />
            <FormattedMessage id="MENU_EDITOR_COPY_SELECTED_TOOLTIP" />
        </Dropdown.Item>
    }
    renderCutSelected() {
        if (!this.props.isMenuExist) {
            return null;
        }
        const { operation } = this.props;

        return <Dropdown.Item active={operation && operation === MenuOperations.CUT_ITEM} href="#" onClick={this.props.cutSelected}>
            <FontAwesomeIcon className="mr-1" icon={faCut} />
            <FormattedMessage id="MENU_EDITOR_CUT_SELECTED_TOOLTIP" />
        </Dropdown.Item>
    }

    render() {
        return <Form inline>
            {this.renderClearSelect()}
            {this.renderDelete()}
            <Dropdown className="ml-1">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {this.renderCopySelected()}
                    {this.renderCutSelected()}
                    {this.renderGoToView()}
                </Dropdown.Menu>
            </Dropdown>

        </Form>;
    }
}
