import {
  faExclamation,
  faQuestion,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as Redux from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import * as ReactIntl from "react-intl";
import {
  changePassword,
  invokeAvatarHelp,
  setupHomePage,
} from "../../actions/menu";
import { logout } from "../../actions/security";
import { ApplicationAction, ApplicationState } from "../../types";
import { isLoggedInUser, isLoginStatus, MenuData } from "../../types/security";
import { Logout } from "./Menu";
import { ToggleWithoutArrow, UserMenu, UserMenuProps } from "./UserMenu";
import styles from "./MenuControls.module.css";
import { UnreadMessages } from "./UnreadMessages";
import { FormattedMessage } from "react-intl";
import { BulbComponent } from "../bulb/BulbComponent";
import Plugin from "../plugin/Plugin";
import { ThunkDispatch } from "redux-thunk";
import { SelectionState } from "../../types/selection";
import { messages } from "../..";
import { MenuControlsProps } from "./MenuControls";

export const MinifiedMenuControls: React.FC<MenuControlsProps> = ({
  changePassword,
  invokeAvatarHelp,
  isSuperUser,
  authorities,
  selection,
  badge,
  isAvatar,
  language,
  logout,
  setupHomePage,
  userLabel: label,
  systemMenu,
  className,
}) => {
  const userLabel = !isAvatar ? (
    label
  ) : (
    <FormattedMessage id="NPT_AVATAR_LABEL" />
  );
  const menuBadge = badge;
  console.log("menuBadge", menuBadge);
  return (
    <Nav className="ml-auto mr-2">
      <div className="mx-1 d-flex justify-content-center align-items-center text-white">
        <span style={{ fontSize: ".8rem" }}>{userLabel}</span>
      </div>
      <UserMenu
        language={language}
        extended={true}
        logout={logout}
        isSuperUser={isSuperUser}
        badge={menuBadge}
        setupHomePage={setupHomePage}
        systemMenu={systemMenu}
        changePassword={changePassword}
        as={ToggleWithoutArrow}
        authorities={authorities}
        selection={selection}
      />
    </Nav>
  );
};

export const undearBadgeWithStore =
  (store: Redux.Store<ApplicationState, ApplicationAction>) => () => {
    const state = store.getState();
    console.log("get undread", state);
    let componentProps: any = {};
    if (isLoggedInUser(state.security?.loginStatus)) {
      componentProps = {
        unread: state.security?.loginStatus?.messages?.unread,
      };
    }

    return class extends React.Component {
      render() {
        return <UnreadMessages {...componentProps} />;
      }
    };
  };

export const menuComponentWithStore =
  (store: Redux.Store<ApplicationState, ApplicationAction>) => () => {
    const state = store.getState();
    const dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction> =
      store.dispatch;
    let componentProps: any = {
      language: state.locale?.language || "",
      userLabel: "",
      dark: state?.theme?.dark || false,
    };
    const loginStatus = state.security?.loginStatus;
    if (isLoggedInUser(loginStatus)) {
      let isSuperUser = isLoginStatus(loginStatus)
        ? loginStatus?.superUser
        : false;
      const authorities =
        (isLoginStatus(loginStatus) && loginStatus?.authorities) || undefined;
      const selection = state.selection;
      componentProps = {
        language: state.locale.language,
        userLabel: loginStatus.label,
        systemMenu: loginStatus.systemMenu,
        isSuperUser,
        selection,
        authorities,
      };
    }
    let functions = {
      changePassword: () => {
        dispatch(changePassword());
      },
      setupHomePage: () => {
        setupHomePage();
      },
      logout: () => {
        dispatch(logout());
      },
    };
    const UnreadBadge = undearBadgeWithStore(store)();
    return class extends React.Component {
      render() {
        const Intl = ReactIntl.IntlProvider;

        return (
          <Intl
            locale={state.locale?.language}
            messages={messages[state.locale.language]}
          >
            <MinifiedMenuControls
              {...componentProps}
              {...this.props}
              {...functions}
              badge={<UnreadBadge />}
            />
          </Intl>
        );
      }
    };
  };
