import { Reducer } from 'redux';
import { AnyAction } from 'redux';
import * as constants from '../constants/theme';
import { ApplicationAction } from '../types';
import { ThemeAction, ThemeState } from '../types/theme';
import { DEFAULT_VERSION_STATE, VersionState } from '../types/version';


const reducer: Reducer<VersionState, ApplicationAction> = (state: VersionState = DEFAULT_VERSION_STATE, action: ApplicationAction): VersionState => {
    
    return state
}

export default reducer;