import * as React from 'react';
import styles from './Calendar.module.css';

interface CalendarCellProps {
    className?: string,
    onClick?: () => void,
    date?: Date
}

export default class CalendarCell extends React.Component<CalendarCellProps>{
    constructor(props: CalendarCellProps) {
        super(props);
        this.onCellClick = this.onCellClick.bind(this);
    }
    onCellClick() {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
        return;
    }

    render() {
        const { children } = this.props;
        return (<div onClick={this.onCellClick} className={styles.calendarCell + ' ' + this.props.className}>
            {children}
        </div>)
    }
}