import { AlertLevelType } from "../types/alert";
import { ALERT_LEVEL_DANGER, ALERT_LEVEL_INFO, ALERT_LEVEL_SUCCESS, ALERT_LEVEL_WARNING } from "../constants/alert";

export function parseAlertMessage(message: string, type: AlertLevelType): { message: string, type: AlertLevelType } {
    if (message.toLowerCase().startsWith("info::")) {
        message = message.substring(6);
        type = ALERT_LEVEL_INFO;
    } else if (message.toLowerCase().startsWith("success::")) {
        message = message.substring(9);
        type = ALERT_LEVEL_SUCCESS;
    } else if (message.toLowerCase().startsWith("warning::")) {
        message = message.substring(9);
        type = ALERT_LEVEL_WARNING;
    } else if (message.toLowerCase().startsWith("danger::")) {
        message = message.substring(8);
        type = ALERT_LEVEL_DANGER;
    }
    return { message, type }
}