import { ThunkAction } from "redux-thunk";
import { ApplicationAction, ApplicationState } from "../types";
import {
  LocationAction,
  SearchData,
  SendHideQuery,
  SendSearchParameters,
} from "../types/location";
import { SEND_HIDE_QUERY, SEND_SEARCH_PARAMETERS } from "../constants/location";
import { sendSelection } from "./selection";
import { getSearchData, getSearchString } from "../services/location";

/*****************
 * Plain actions *
 *****************/
export function sendSearchParameters(
  params: SearchData,
  reset: boolean = false
): SendSearchParameters {
  return {
    type: SEND_SEARCH_PARAMETERS,
    payload: {
      params,
      reset,
    },
  };
}

export function sendHideQuery(hideQuery: boolean): SendHideQuery {
  return {
    type: SEND_HIDE_QUERY,
    payload: {
      hideQuery,
    },
  };
}

/***********
 * Actions *
 ***********/
export function changeSearch(
  dataChanges: SearchData,
  reset?: boolean
): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
  return async (dispatch, getState) => {
    const locationState = getState().location;
    const selectionState = getState().selection;
    const searchData: { [k: string]: string } = {
      ...locationState.params,
      ...selectionState.info,
    };
    const data = reset
      ? Object.assign({}, dataChanges)
      : Object.assign({}, searchData, dataChanges);
    for (let param in data) {
      if (data[param] === null || data[param] === "") {
        delete data[param];
      }
    }
    if (dataChanges.object || dataChanges.type || reset) {
      dispatch(sendSelection(data.object || "", data.type || ""));
    }
    delete data.object;
    delete data.type;
    dispatch(sendSearchParameters(data, reset));
  };
}
export function removeSearchParam(
  key: string
): ThunkAction<void, ApplicationState, {}, LocationAction> {
  return async (dispatch, getState) => {
    let data = getSearchData();
    if (!data[key]) {
      return;
    }
    dispatch(changeSearch({ [key]: "" }));
  };
}
export function removeSearchParams(
  keys: string[]
): ThunkAction<void, ApplicationState, {}, LocationAction> {
  return async (dispatch, getState) => {
    let changeObj: { [k: string]: string } = {};
    let data = getSearchData();
    let changed = false;
    for (let key of keys) {
      if (!data[key]) {
        continue;
      }
      changed = true;
      changeObj[key] = "";
    }
    if (!changed) {
      return;
    }
    dispatch(changeSearch(changeObj));
  };
}
