import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import shortid from "shortid";

import { DEFAULT_FINDER_STATE } from "../reducers/finder";

import { Translations } from "../index";
import {
  booleanRelationTypeList,
  booleanRelationValueList,
  dateRelationTypeList,
  enumerationRelationTypeList,
  fileRelationTypeList,
  fragmentRelationTypeList,
  numberRelationTypeList,
  referenceRelationTypeList,
  stringRelationTypeList,
  TYPE,
} from "../constants/finder";
import { ApplicationState } from "../types";
import { EnumerationInfo } from "../types/profile";
import {
  FinderState,
  FinderClassLevel,
  FinderCriteria,
  FinderEnumeration,
  FinderEnumerationsStore,
  FinderField,
  FinderFieldsStore,
  FinderFragmentsStore,
  FinderObjectcard,
  FinderPredicate,
  FinderRelation,
  isFileRelation,
  ServerFinderClass,
  ServerFinderField,
  ServerFinderFilterWithQuery,
  ServerFinderFilterAndBlock,
  ServerFinderFilterOrBlock,
  ServerFinderFilterOrCondition,
  ServerFinderFragment,
  FinderOptions,
  FinderData,
  FinderPredicatesStore,
  FinderViewType,
  FinderOptionsSettings,
  ServerFinderFilter,
  FinderNumberRelation,
  FinderDateRelation,
} from "../types/finder";
import { TreeHeader } from "../types/tree";
import {
  CancelCallback,
  CloseCallback,
  I18NString,
  ModalOptions,
  OkCallback,
} from "../types/modal";
import { AlertLevelType } from "../types/alert";
import {
  changeCriteriaField,
  fetchFields,
  fetchFinderClasses,
  fetchFinderFragmentList,
  fetchObjectcard,
  initializeFinder,
  removeCriteria as removeCriteriaAction,
  removeCriteriaRelation,
  sendCriteriaAdd,
  sendCriteriaRelation,
  sendCriteriaRelationAdd,
  sendFinderHidden,
  sendFinderSelectClass,
  sendFinderSelectFragment,
  sendFinderView,
} from "../actions/finder";
import { openModal } from "../actions/modal";
import { addAlert } from "../actions/alert";

export const connectFinder = connect(
  (state: ApplicationState, ownProps: { finderId: string }) => {
    const finderState = state.finder[ownProps.finderId] || DEFAULT_FINDER_STATE;
    return {
      initialized: finderState.initialized,
      isHidden: finderState.isHidden,
      view: finderState.view,
      loadedFragments: finderState.loadedFragments,
      loadedClasses: finderState.loadedClasses,
      loadedFields: finderState.loadedFields,
      loadedObjectcards: finderState.loadedObjectcards,
      loadedPredicates: finderState.loadedPredicates,
      options: finderState.options,
      data: finderState.data,
      changes: finderState.changes,
    };
  },
  (
    dispatch: ThunkDispatch<{}, {}, any>,
    ownProps: { finderId: string; model: string; isTree?: boolean }
  ) => {
    const { finderId, model, isTree } = ownProps;
    return {
      initializeFinder: (finderOptions: FinderOptionsSettings) => {
        dispatch(initializeFinder(finderId, finderOptions));
      },
      fetchFields: (parentId: string) => {
        dispatch(fetchFields(finderId, parentId));
      },
      fetchObjectcard: (rdfId: string) => {
        dispatch(fetchObjectcard(finderId, rdfId));
      },
      fetchFragments: (
        fragmentIds: string[] | null,
        types: string | string[]
      ) => {
        dispatch(fetchFinderFragmentList(finderId, fragmentIds, types));
      },
      selectFragment: (
        oldFragmentId: string | null,
        newFragmentId: string | null,
        force?: boolean
      ) => {
        dispatch(
          sendFinderSelectFragment(
            finderId,
            oldFragmentId,
            newFragmentId,
            force
          )
        );
      },
      fetchClasses: (parentClassId: string) => {
        dispatch(fetchFinderClasses(finderId, model, parentClassId, isTree));
      },
      selectClass: (
        oldClassId: string | null,
        newClassId: string | null,
        classLevelIdx: number
      ) => {
        dispatch(
          sendFinderSelectClass(finderId, oldClassId, newClassId, classLevelIdx)
        );
      },
      sendHidden: (value?: boolean) => {
        dispatch(sendFinderHidden(finderId, value));
      },
      changeFinderView: (view: FinderViewType) => {
        dispatch(sendFinderView(finderId, view));
      },
      addCriteria: (criteriaGroupId?: string) => {
        dispatch(sendCriteriaAdd(finderId, criteriaGroupId));
      },
      addCriteriaRelation: (criteriaId: string) => {
        dispatch(sendCriteriaRelationAdd(finderId, criteriaId));
      },
      removeCriteria: (criteriaId: string) => {
        dispatch(removeCriteriaAction(finderId, criteriaId));
      },
      removeCriteriaRelation: (criteriaId: string, relationIdx: number) => {
        dispatch(removeCriteriaRelation(finderId, criteriaId, relationIdx));
      },
      changeCriteriaRelation: (
        criteriaId: string,
        relation: FinderRelation,
        relationIdx: number
      ) => {
        dispatch(
          sendCriteriaRelation(finderId, criteriaId, relationIdx, relation)
        );
      },
      changeCriteriaField: (criteriaId: string, fieldId: string) => {
        dispatch(changeCriteriaField(finderId, criteriaId, fieldId));
      },
      openModal: (
        type: string,
        options: ModalOptions,
        okCallback?: OkCallback,
        cancelCallback?: CancelCallback,
        closeCallback?: CloseCallback
      ) => {
        dispatch(
          openModal(
            shortid.generate(),
            type,
            options,
            okCallback,
            cancelCallback,
            closeCallback
          )
        );
      },
      addAlert: (type: AlertLevelType, message: string | I18NString) => {
        dispatch(addAlert(type, message));
      },
    };
  }
);

function generateUniqueId(idMap: { [k: string]: any }) {
  let id = shortid.generate();
  if (idMap) {
    /* Prevent id duplicate */
    while (idMap[id]) {
      id = shortid.generate();
    }
  }
  return id;
}

function compareFieldsById(
  normalizedFields: { byId: { [k: string]: any } },
  firstId: string,
  secondId: string
) {
  if (
    normalizedFields.byId[firstId].label < normalizedFields.byId[secondId].label
  ) {
    return -1;
  }
  if (
    normalizedFields.byId[firstId].label ==
    normalizedFields.byId[secondId].label
  ) {
    return 0;
  }
  return 1;
}

function getDepthOfFragment(
  fragments: FinderFragmentsStore,
  fragmentId: string
) {
  let fragment = fragments.byId[fragmentId];
  let depth = 0;
  while (fragment && fragment.parentId && fragment.parentId != fragment.id) {
    ++depth;
    fragment = fragments.byId[fragment.parentId];
  }
  return depth;
}

function deleteKeyFromArray(array: any[], value: any) {
  for (let i = 0; i < array.length; ++i) {
    if (array[i] == value) {
      array.splice(i, 1);
      return;
    }
  }
}

function changeKeyInArray(array: any[], oldValue: any, newValue: any) {
  for (let i = 0; i < array.length; ++i) {
    if (array[i] == oldValue) {
      array.splice(i, 1, newValue);
      return;
    }
  }
}

function getClassId(level: FinderClassLevel, classObject: ServerFinderClass) {
  let classId;
  let className = classObject.className;
  if (typeof level.joinId != "undefined" && level.joinId != null) {
    classId = level.joinId + "[" + className + "]";
  } else {
    classId = className;
  }
  return classId;
}

export function getJoinedPredicateName(field: FinderField) {
  if (field.joinId) {
    return field.joinId + "[" + field.predicate + "]";
  }
  return field.predicate;
}

export function getPredicateName(field: FinderField) {
  return field.predicate;
}

export function isRdfId(value: any) {
  if (typeof value != "string") {
    return false;
  }
  /** Platform rdfId can have any string value, so RegExp matcher of rdfId structure is redundant */
  //const matcher = new RegExp(/^_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);
  const matcher = new RegExp(/^[a-zA-Z_]/);
  return matcher.test(value);
}

export function importFinderOptions(
  nextState: FinderState,
  finderOptions: FinderOptions | null,
  filderFilter?: ServerFinderFilter | null
) {
  if (!finderOptions) {
    return;
  }
  nextState.initialized = true;
  nextState.isFetching = false;
  nextState.options = { ...finderOptions };
  nextState.initialFilter = filderFilter ? { ...filderFilter } : null;
  nextState.data = { ...nextState.data };
  nextState.data.sideBar = { ...nextState.data.sideBar };
  nextState.data.searchString = finderOptions?.search?.value || null;
  if (finderOptions.fragmentTree?.levels) {
    nextState.data.sideBar.fragmentLevels = [];
    for (let level of finderOptions.fragmentTree.levels) {
      nextState.data.sideBar.fragmentLevels.push({
        name: level.label,
        types: level.types,
        autofill: Boolean(level.autofill),
        hidden: Boolean(level.hidden),
        selected: [],
      });
    }
  }
  if (finderOptions.classTree?.levels) {
    nextState.data.sideBar.classLevels = [];
    for (let level of finderOptions.classTree.levels) {
      nextState.data.sideBar.classLevels.push({
        id: level.id,
        name: level.label,
        joinId: level.joinId,
        selected: [],
      });
    }
  }

  resetCriteria(nextState.data);
  addNewCriteria(nextState.data, null);
}

export function waitForFragments(nextState: FinderState, parentId: string) {
  nextState.loadedFragments = Object.assign({}, nextState.loadedFragments);
  nextState.loadedFragments.loading = Object.assign(
    {},
    nextState.loadedFragments.loading
  );
  nextState.loadedFragments.loading[parentId] = true;
}

export function fragmentsReceived(
  nextState: FinderState,
  parentId: string | null,
  fragments: ServerFinderFragment[]
) {
  nextState.loadedFragments = Object.assign({}, nextState.loadedFragments);
  nextState.loadedFragments.fetched = Object.assign(
    {},
    nextState.loadedFragments.fetched
  );
  nextState.loadedFragments.fetched[parentId || "null"] = true;
  nextState.loadedFragments.loading = Object.assign(
    {},
    nextState.loadedFragments.loading
  );
  nextState.loadedFragments.loading[parentId || "null"] = false;
  nextState.loadedFragments.error = Object.assign(
    {},
    nextState.loadedFragments.error
  );
  nextState.loadedFragments.error[parentId || "null"] = false;
  nextState.loadedFragments.children = Object.assign(
    {},
    nextState.loadedFragments.children
  );

  if (parentId === "null") {
    parentId = null;
  }
  if (!parentId) {
    nextState.loadedFragments.rootIds = [];
  }

  for (let child of fragments) {
    let id = child.id
      ? child.id
      : generateUniqueId(nextState.loadedFragments.byId);
    let fragment = Object.assign(child, {
      id,
      label: child.name,
    });
    if (!nextState.loadedFragments.children[id]) {
      nextState.loadedFragments.children[id] = [];
    }

    nextState.loadedFragments.byId[id] = fragment;
    nextState.loadedFragments.allIds.push(id);
    if (parentId) {
      fragment.parentId = parentId;
      if (!nextState.loadedFragments.children[parentId]) {
        nextState.loadedFragments.children[parentId] = [];
      } else {
        nextState.loadedFragments.children[parentId] =
          nextState.loadedFragments.children[parentId].slice();
      }
      nextState.loadedFragments.children[parentId].push(id);
    } else {
      fragment.parentId = null;
      nextState.loadedFragments.rootIds.push(id);
    }
  }
  if (parentId) {
    nextState.loadedFragments.children[parentId].sort((a, b) =>
      compareFieldsById(nextState.loadedFragments, a, b)
    );
  }
}

export function fragmentsErrorReceived(
  nextState: FinderState,
  parentId: string | null
) {
  nextState.loadedFragments = Object.assign({}, nextState.loadedFragments);
  nextState.loadedFragments.loading = Object.assign(
    {},
    nextState.loadedFragments.loading
  );
  nextState.loadedFragments.loading[parentId || "null"] = false;
  nextState.loadedFragments.error = Object.assign(
    {},
    nextState.loadedFragments.error
  );
  nextState.loadedFragments.error[parentId || "null"] = true;
}

export function fragmentsTreeHeaderReceived(
  nextState: FinderState,
  treeHeader: TreeHeader
) {
  nextState.fragmentsTreeHeader = treeHeader;
  nextState.fragmentsTreeHeaderError = false;
}

export function fragmentsTreeHeaderErrorReceived(nextState: FinderState) {
  nextState.fragmentsTreeHeaderError = true;
}

export function selectFragment(
  finderData: FinderData,
  oldFragmentId: string | null,
  newFragmentId: string | null,
  loadedFragments: FinderFragmentsStore
) {
  finderData.sideBar = Object.assign({}, finderData.sideBar);

  /* If new id is null then user wants to remove fragment from selection */
  if (!newFragmentId) {
    /* Check if both ids is undefined */
    if (!oldFragmentId) {
      return;
    }
    removeFragment(finderData, oldFragmentId, loadedFragments);
    return;
  }

  /* If old id is null then user wants to add fragment to selection */
  if (!oldFragmentId) {
    /* Check if both ids is undefined */
    if (!newFragmentId) {
      return;
    }
    addFragment(finderData, newFragmentId, loadedFragments);
    return;
  }

  /* If both id is not null then user wants to change fragment in selection */
  changeFragment(finderData, oldFragmentId, newFragmentId, loadedFragments);
}

export function removeFragmentChildrens(
  finderData: FinderData,
  depth: number,
  fragmentId: string,
  loadedFragments: FinderFragmentsStore
) {
  if (depth >= finderData.sideBar.fragmentLevels.length - 1) {
    return;
  }
  let selectedChildren =
    finderData.sideBar.fragmentLevels[depth + 1].selected.slice();
  for (let selectedChildrenId of selectedChildren) {
    let fragment = loadedFragments.byId[selectedChildrenId];
    if (fragment.parentId == fragmentId) {
      removeFragment(finderData, selectedChildrenId, loadedFragments);
    }
  }
}

export function removeFragment(
  finderData: FinderData,
  fragmentId: string,
  loadedFragments: FinderFragmentsStore
) {
  let depth = getDepthOfFragment(loadedFragments, fragmentId);
  if (depth >= finderData.sideBar.fragmentLevels.length) {
    return;
  }
  finderData.sideBar.fragmentLevels = finderData.sideBar.fragmentLevels.slice();
  finderData.sideBar.fragmentLevels[depth] = Object.assign(
    {},
    finderData.sideBar.fragmentLevels[depth]
  );
  finderData.sideBar.fragmentLevels[depth].selected =
    finderData.sideBar.fragmentLevels[depth].selected.slice();
  deleteKeyFromArray(
    finderData.sideBar.fragmentLevels[depth].selected,
    fragmentId
  );
  removeFragmentChildrens(finderData, depth, fragmentId, loadedFragments);
}

export function addFragment(
  finderData: FinderData,
  fragmentNodeId: string,
  loadedFragments: FinderFragmentsStore
) {
  let depth = getDepthOfFragment(loadedFragments, fragmentNodeId);
  if (depth >= finderData.sideBar.fragmentLevels.length) {
    return;
  }
  finderData.sideBar.fragmentLevels = finderData.sideBar.fragmentLevels.slice();
  finderData.sideBar.fragmentLevels[depth] = Object.assign(
    {},
    finderData.sideBar.fragmentLevels[depth]
  );
  finderData.sideBar.fragmentLevels[depth].selected =
    finderData.sideBar.fragmentLevels[depth].selected.slice();
  if (
    finderData.sideBar.fragmentLevels[depth].selected.indexOf(
      fragmentNodeId
    ) === -1
  ) {
    finderData.sideBar.fragmentLevels[depth].selected.push(fragmentNodeId);
  }
}

export function changeFragment(
  finderData: FinderData,
  oldFragmentId: string,
  newFragmentId: string,
  loadedFragments: FinderFragmentsStore
) {
  let oldDepth = getDepthOfFragment(loadedFragments, oldFragmentId);
  let newDepth = getDepthOfFragment(loadedFragments, newFragmentId);

  /* Check if error is occused and depth of fragments is different */
  if (oldDepth != newDepth) {
    console.error(
      "Depths not matching: old - ",
      oldDepth,
      ", new - ",
      newDepth
    );
    return;
  }
  if (oldDepth >= finderData.sideBar.fragmentLevels.length) {
    return;
  }
  const depth = oldDepth;

  finderData.sideBar.fragmentLevels = finderData.sideBar.fragmentLevels.slice();
  finderData.sideBar.fragmentLevels[depth] = Object.assign(
    {},
    finderData.sideBar.fragmentLevels[depth]
  );
  finderData.sideBar.fragmentLevels[depth].selected =
    finderData.sideBar.fragmentLevels[depth].selected.slice();
  changeKeyInArray(
    finderData.sideBar.fragmentLevels[depth].selected,
    oldFragmentId,
    newFragmentId
  );
  removeFragmentChildrens(finderData, oldDepth, oldFragmentId, loadedFragments);
}

export function waitForClasses(nextState: FinderState, levelId: string) {
  nextState.loadedClasses = Object.assign({}, nextState.loadedClasses);
  nextState.loadedClasses.loading = Object.assign(
    {},
    nextState.loadedClasses.loading
  );
  nextState.loadedClasses.loading[levelId] = true;
}

export function classesReceived(
  nextState: FinderState,
  levelId: string,
  classes: ServerFinderClass[]
) {
  nextState.data = Object.assign({}, nextState.data);
  nextState.data.sideBar = Object.assign({}, nextState.data.sideBar);
  nextState.data.sideBar.classLevels =
    nextState.data.sideBar.classLevels.slice();

  let levelIdx = null;
  for (let i = 0; i < nextState.data.sideBar.classLevels.length; ++i) {
    if (nextState.data.sideBar.classLevels[i].id == levelId) {
      levelIdx = i;
      break;
    }
  }
  if (levelIdx == null) {
    console.error("Can't find level by id: '" + levelId + "'");
    return;
  }
  nextState.data.sideBar.classLevels[levelIdx] = Object.assign(
    {},
    nextState.data.sideBar.classLevels[levelIdx]
  );

  nextState.loadedClasses = Object.assign({}, nextState.loadedClasses);
  nextState.loadedClasses.fetched = Object.assign(
    {},
    nextState.loadedClasses.fetched
  );
  nextState.loadedClasses.fetched[levelId] = true;
  nextState.loadedClasses.loading = Object.assign(
    {},
    nextState.loadedClasses.loading
  );
  nextState.loadedClasses.loading[levelId] = false;
  nextState.loadedClasses.selection = Object.assign(
    {},
    nextState.loadedClasses.selection
  );
  nextState.loadedClasses.selection[levelId] =
    nextState.loadedClasses.selection[levelId]?.slice() || [];

  let classLevel = nextState.data.sideBar.classLevels[levelIdx];

  for (let classObject of classes) {
    let id = classObject.id
      ? classObject.id
      : generateUniqueId(nextState.loadedClasses.byId);

    nextState.loadedClasses.byId[id] = {
      ...classObject,
      id,
      label: classObject.name,
      level: levelIdx,
    };
    nextState.loadedClasses.idByClassId[getClassId(classLevel, classObject)] =
      id;
    nextState.loadedClasses.allIds.push(id);

    nextState.loadedClasses.selection[levelId].push(id);
  }
  nextState.loadedClasses.selection[levelId].sort((a, b) =>
    compareFieldsById(nextState.loadedClasses, a, b)
  );
}

export function selectClass(
  finderData: FinderData,
  oldClassId: string | null,
  newClassId: string | null,
  level: number
) {
  finderData.sideBar = Object.assign({}, finderData.sideBar);

  /* If new id is null then user wants to remove class from selection */
  if (!newClassId) {
    /* Check if both ids is undefined */
    if (!oldClassId) {
      return;
    }
    removeClass(finderData, oldClassId, level);
    return;
  }

  /* If old id is null then user wants to add class to selection */
  if (!oldClassId) {
    /* Check if both ids is undefined */
    if (!newClassId) {
      return;
    }
    addClass(finderData, newClassId, level);
    return;
  }

  /* If both id is not null then user wants to change class in selection */
  changeClass(finderData, oldClassId, newClassId, level);
}

export function removeClass(
  finderData: FinderData,
  classId: string,
  level: number
) {
  finderData.sideBar.classLevels = finderData.sideBar.classLevels.slice();
  finderData.sideBar.classLevels[level] = Object.assign(
    {},
    finderData.sideBar.classLevels[level]
  );
  finderData.sideBar.classLevels[level].selected =
    finderData.sideBar.classLevels[level].selected.slice();
  deleteKeyFromArray(finderData.sideBar.classLevels[level].selected, classId);
}

export function addClass(
  finderData: FinderData,
  classId: string,
  level: number
) {
  finderData.sideBar.classLevels = finderData.sideBar.classLevels.slice();
  finderData.sideBar.classLevels[level] = Object.assign(
    {},
    finderData.sideBar.classLevels[level]
  );
  finderData.sideBar.classLevels[level].selected =
    finderData.sideBar.classLevels[level].selected.slice();
  finderData.sideBar.classLevels[level].selected.push(classId);
}

export function changeClass(
  finderData: FinderData,
  oldClassId: string,
  newClassId: string,
  level: number
) {
  finderData.sideBar.classLevels = finderData.sideBar.classLevels.slice();
  finderData.sideBar.classLevels[level] = Object.assign(
    {},
    finderData.sideBar.classLevels[level]
  );
  finderData.sideBar.classLevels[level].selected =
    finderData.sideBar.classLevels[level].selected.slice();
  changeKeyInArray(
    finderData.sideBar.classLevels[level].selected,
    oldClassId,
    newClassId
  );
}

export function resetCriteria(finderData: FinderData) {
  finderData.criteria = {
    byId: {},
    allIds: [],
  };

  finderData.criteriaGroup = {
    byId: {},
    allIds: [],
  };

  finderData.criteriaGroupList = [];
}

export function addNewCriteria(
  finderData: FinderData,
  criteriaGroupId: string | null
) {
  /* Check if we adding "or" criteria */
  if (criteriaGroupId == null) {
    /* Create new "and" group */
    criteriaGroupId = generateUniqueId(finderData.criteriaGroup.byId);
    finderData.criteriaGroup = Object.assign({}, finderData.criteriaGroup);
    finderData.criteriaGroup.byId = Object.assign(
      {},
      finderData.criteriaGroup.byId
    );
    finderData.criteriaGroup.byId[criteriaGroupId] = {
      criteriaList: [],
    };
    finderData.criteriaGroup.allIds = finderData.criteriaGroup.allIds.slice();
    finderData.criteriaGroup.allIds.push(criteriaGroupId);

    /* Add new criteriaGroup to criteriaGroupList */
    finderData.criteriaGroupList = finderData.criteriaGroupList.slice();
    finderData.criteriaGroupList.push(criteriaGroupId);
  }

  let criteriaId = generateUniqueId(finderData.criteria.byId);
  let newCriteria: FinderCriteria = {
    fieldId: null,
    relations: [],
    criteriaGroupId: criteriaGroupId,
    id: criteriaId,
  };

  /* Add new criteria to group */
  finderData.criteriaGroup = Object.assign({}, finderData.criteriaGroup);
  finderData.criteriaGroup.byId = Object.assign(
    {},
    finderData.criteriaGroup.byId
  );
  finderData.criteriaGroup.byId[criteriaGroupId] = Object.assign(
    {},
    finderData.criteriaGroup.byId[criteriaGroupId]
  );
  finderData.criteriaGroup.byId[criteriaGroupId].criteriaList =
    finderData.criteriaGroup.byId[criteriaGroupId].criteriaList.slice();
  finderData.criteriaGroup.byId[criteriaGroupId].criteriaList.push(criteriaId);

  /* Add new criteria to criteria map */
  finderData.criteria = Object.assign({}, finderData.criteria);
  finderData.criteria.byId = Object.assign({}, finderData.criteria.byId);
  finderData.criteria.byId[criteriaId] = newCriteria;
  finderData.criteria.allIds = finderData.criteria.allIds.slice();
  finderData.criteria.allIds.push(criteriaId);

  return { criteriaGroupId, criteriaId };
}

export function waitForField(nextState: FinderState, parentId: string | null) {
  nextState.loadedFields = Object.assign({}, nextState.loadedFields);
  nextState.loadedFields.loading = Object.assign(
    {},
    nextState.loadedFields.loading
  );
  nextState.loadedFields.loading[parentId || "null"] = true;
}

function parseFields(
  loadedFields: FinderFieldsStore,
  loadedPredicates: FinderPredicatesStore,
  data: ServerFinderField[],
  parentId: string | null
) {
  loadedFields.byId = { ...loadedFields.byId };
  loadedFields.children = { ...loadedFields.children };
  loadedFields.idByPredicateName = { ...loadedFields.idByPredicateName };
  loadedFields.allIds = loadedFields.allIds.slice();
  loadedFields.rootIds = loadedFields.rootIds.slice();
  loadedPredicates.byName = { ...loadedPredicates.byName };
  for (let child of data) {
    let id = child.id ? child.id : generateUniqueId(loadedFields.byId);
    const joinedPredicateName = getJoinedPredicateName(child);
    let field = Object.assign(
      { id, joinIdPredicate: joinedPredicateName },
      child
    );
    loadedFields.byId[id] = field;
    if (!loadedFields.children[id]) {
      loadedFields.children[id] = [];
    }
    if (joinedPredicateName) {
      const predicateName = getPredicateName(child) as string;
      loadedFields.idByPredicateName[joinedPredicateName] = id;
      /**Check if predicate for field was already fetched */
      if (loadedPredicates.byName[predicateName]) {
        loadedPredicates.byName[joinedPredicateName] =
          loadedPredicates.byName[predicateName];
      }
    }
    loadedFields.allIds.push(id);
    if (parentId) {
      field.parentId = parentId;
      if (!loadedFields.children[parentId]) {
        loadedFields.children[parentId] = [];
      } else {
        loadedFields.children[parentId] =
          loadedFields.children[parentId].slice();
      }
      loadedFields.children[parentId].push(id);
    } else {
      field.parentId = null;
      loadedFields.rootIds.push(id);
    }
  }
  if (parentId && loadedFields.children[parentId]) {
    loadedFields.children[parentId] = loadedFields.children[parentId].slice();
    loadedFields.children[parentId].sort((a, b) =>
      compareFieldsById(loadedFields, a, b)
    );
  }
}

export function fieldsReceived(
  nextState: FinderState,
  parentId: string | null,
  data: ServerFinderField[]
) {
  nextState.loadedFields = Object.assign({}, nextState.loadedFields);
  nextState.loadedFields.fetched = Object.assign(
    {},
    nextState.loadedFields.fetched
  );
  nextState.loadedFields.fetched[parentId || "null"] = true;
  nextState.loadedFields.loading = Object.assign(
    {},
    nextState.loadedFields.loading
  );
  nextState.loadedFields.loading[parentId || "null"] = false;
  nextState.loadedPredicates = Object.assign({}, nextState.loadedPredicates);
  parseFields(
    nextState.loadedFields,
    nextState.loadedPredicates,
    data,
    parentId
  );
}

export function objectcardReceived(
  nextState: FinderState,
  rdfId: string,
  data: FinderObjectcard
) {
  nextState.loadedObjectcards = Object.assign({}, nextState.loadedObjectcards);
  nextState.loadedObjectcards.fetched = Object.assign(
    {},
    nextState.loadedObjectcards.fetched
  );
  nextState.loadedObjectcards.fetched[rdfId] = true;
  nextState.loadedObjectcards.byId = Object.assign(
    {},
    nextState.loadedObjectcards.byId
  );
  nextState.loadedObjectcards.byId[rdfId] = data;
}

export function objectcardErrorReceived(
  nextState: FinderState,
  rdfId: string,
  error: Error
) {
  nextState.loadedObjectcards = Object.assign({}, nextState.loadedObjectcards);
  nextState.loadedObjectcards.fetched = Object.assign(
    {},
    nextState.loadedObjectcards.fetched
  );
  nextState.loadedObjectcards.fetched[rdfId] = true;
  nextState.loadedObjectcards.byId = Object.assign(
    {},
    nextState.loadedObjectcards.byId
  );
  delete nextState.loadedObjectcards.byId[rdfId];
}

export function addRelation(finderData: FinderData, criteriaId: string) {
  finderData.criteria = Object.assign({}, finderData.criteria);
  finderData.criteria.byId = Object.assign({}, finderData.criteria.byId);
  finderData.criteria.byId[criteriaId] = Object.assign(
    {},
    finderData.criteria.byId[criteriaId]
  );
  finderData.criteria.byId[criteriaId].relations =
    finderData.criteria.byId[criteriaId].relations.slice();

  let newRelation: FinderRelation = {
    type: null,
  };
  finderData.criteria.byId[criteriaId].relations.push(newRelation);
}

export function removeCriteriaGroup(
  finderData: FinderData,
  criteriaGroupId: string
) {
  /* If group have children - remove them and return (when last child will be removed it will triger actual deletion of group) */
  if (finderData.criteriaGroup.byId[criteriaGroupId].criteriaList.length != 0) {
    /* Remove childs from group */
    let criteriaIdList =
      finderData.criteriaGroup.byId[criteriaGroupId].criteriaList.slice();
    for (let criteriaId of criteriaIdList) {
      removeCriteria(finderData, criteriaId);
    }
    return;
  }

  /* Remove criteria from parent */
  finderData.criteriaGroupList = finderData.criteriaGroupList.slice();
  deleteKeyFromArray(finderData.criteriaGroupList, criteriaGroupId);

  /* Delete criteriaGroup */
  finderData.criteriaGroup = Object.assign({}, finderData.criteriaGroup);
  finderData.criteriaGroup.byId = Object.assign(
    {},
    finderData.criteriaGroup.byId
  );
  finderData.criteriaGroup.allIds = finderData.criteriaGroup.allIds.slice();
  deleteKeyFromArray(finderData.criteriaGroup.allIds, criteriaGroupId);
  delete finderData.criteriaGroup.byId[criteriaGroupId];
}

export function removeCriteria(finderData: FinderData, criteriaId: string) {
  /* Remove criteria from parent */
  let criteriaGroupId = finderData.criteria.byId[criteriaId].criteriaGroupId;
  finderData.criteriaGroup = Object.assign({}, finderData.criteriaGroup);
  finderData.criteriaGroup.byId = Object.assign(
    {},
    finderData.criteriaGroup.byId
  );
  finderData.criteriaGroup.byId[criteriaGroupId] = Object.assign(
    {},
    finderData.criteriaGroup.byId[criteriaGroupId]
  );
  finderData.criteriaGroup.byId[criteriaGroupId].criteriaList =
    finderData.criteriaGroup.byId[criteriaGroupId].criteriaList.slice();
  deleteKeyFromArray(
    finderData.criteriaGroup.byId[criteriaGroupId].criteriaList,
    criteriaId
  );

  /* Check if removed criteria was last one in group */
  if (finderData.criteriaGroup.byId[criteriaGroupId].criteriaList.length == 0) {
    removeCriteriaGroup(finderData, criteriaGroupId);
  }

  /* Delete criteria */
  finderData.criteria = Object.assign({}, finderData.criteria);
  finderData.criteria.byId = Object.assign({}, finderData.criteria.byId);
  finderData.criteria.allIds = finderData.criteria.allIds.slice();
  deleteKeyFromArray(finderData.criteria.allIds, criteriaId);
  delete finderData.criteria.byId[criteriaId];
}

export function removeRelation(
  finderData: FinderData,
  criteriaId: string,
  relationIdx: number
) {
  finderData.criteria = Object.assign({}, finderData.criteria);
  finderData.criteria.byId = Object.assign({}, finderData.criteria.byId);
  finderData.criteria.byId[criteriaId] = Object.assign(
    {},
    finderData.criteria.byId[criteriaId]
  );
  finderData.criteria.byId[criteriaId].locked = true;
  finderData.criteria.byId[criteriaId].relations =
    finderData.criteria.byId[criteriaId].relations.slice();
  finderData.criteria.byId[criteriaId].relations.splice(relationIdx, 1);
}

export function unlockCriteriaRelations(
  finderData: FinderData,
  criteriaId: string
) {
  /* Unlock criteria relations */
  finderData.criteria.byId = Object.assign({}, finderData.criteria.byId);
  finderData.criteria.byId[criteriaId] = Object.assign(
    {},
    finderData.criteria.byId[criteriaId]
  );
  finderData.criteria.byId[criteriaId].locked = false;
}

export function changeRelation(
  finderData: FinderData,
  criteriaId: string,
  relationIdx: number,
  relation: FinderRelation
) {
  finderData.criteria = Object.assign({}, finderData.criteria);
  finderData.criteria.byId = Object.assign({}, finderData.criteria.byId);
  finderData.criteria.byId[criteriaId] = Object.assign(
    {},
    finderData.criteria.byId[criteriaId]
  );
  finderData.criteria.byId[criteriaId].relations =
    finderData.criteria.byId[criteriaId].relations.slice();
  finderData.criteria.byId[criteriaId].relations[relationIdx] = relation;
}

export function predicateReceived(
  nextState: FinderState,
  predicateName: string,
  predicate: FinderPredicate,
  fieldId?: string
) {
  nextState.loadedPredicates = Object.assign({}, nextState.loadedPredicates);
  nextState.loadedPredicates.byName = Object.assign(
    {},
    nextState.loadedPredicates.byName
  );
  nextState.loadedPredicates.byName[predicateName] = predicate;
  const connectWithField = (field: FinderField) => {
    const fieldPredicateName = field ? getPredicateName(field) : null;
    if (!fieldPredicateName || fieldPredicateName !== predicateName) {
      return;
    }
    const joinedPredicateName = getJoinedPredicateName(field) as string;
    nextState.loadedPredicates.byName[joinedPredicateName] = predicate;
    nextState.loadedFields = Object.assign({}, nextState.loadedFields);
    nextState.loadedFields.idByPredicateName = Object.assign(
      {},
      nextState.loadedFields.idByPredicateName
    );
    nextState.loadedFields.idByPredicateName[joinedPredicateName] = field.id;
  };
  if (fieldId) {
    /**If we adding predicate after selection of field - connect them */
    const field = nextState.loadedFields.byId[fieldId];
    connectWithField(field);
  } else {
    /**Check all fields for connection with this predicate */
    for (let field of Object.values(nextState.loadedFields.byId)) {
      connectWithField(field);
    }
  }
  nextState.loadedEnumerations = Object.assign(
    {},
    nextState.loadedEnumerations
  );
  parsePredicate(nextState.loadedEnumerations, predicate);
}

function recursiveAddEnumerations(
  loadedEnumerations: FinderEnumerationsStore,
  children: EnumerationInfo[],
  parentId: string | null
) {
  if (!children) {
    return;
  }
  for (let child of children) {
    if (!child.data) {
      continue;
    }
    let predicateName = child.data.$namespace + ":" + child.data.$rdfId;
    if (loadedEnumerations.idByPredicateName[predicateName]) {
      continue;
    }
    let id = child.data.$id
      ? child.data.$id.toString()
      : generateUniqueId(loadedEnumerations.byId);
    let enumeration: FinderEnumeration = {
      id,
      label: child.data.$label,
      children: [],
      parentId: null,
    };
    loadedEnumerations.byId[id] = enumeration;
    loadedEnumerations.idByPredicateName[predicateName] = id;
    if (parentId) {
      enumeration.parentId = parentId;
      loadedEnumerations.byId[parentId].children.push(id);
    } else {
      loadedEnumerations.rootIds.push(id);
    }
    if (child.children) {
      recursiveAddEnumerations(loadedEnumerations, child.children, id);
    }
  }
  if (parentId) {
    loadedEnumerations.byId[parentId].children.sort((a, b) =>
      compareFieldsById(loadedEnumerations, a, b)
    );
  }
}

function parsePredicate(
  loadedEnumerations: FinderEnumerationsStore,
  predicate: FinderPredicate
) {
  let fieldType;
  if (
    typeof predicate.classRelationInfo != "undefined" &&
    predicate.classRelationInfo != null
  ) {
    let peerClass = predicate.classRelationInfo.peerClass;
    /**TODO: check stereotype */
    if ((peerClass as any).stereotypeInfo == "enumeration") {
      fieldType = TYPE.ENUMERATION;
      recursiveAddEnumerations(
        loadedEnumerations,
        peerClass.enumerationInfo.children,
        null
      );
    } else {
      let relationTypeInfo =
        predicate.classRelationInfo.relationTypeInfo.toLowerCase();
      if (relationTypeInfo == "composition") {
        fieldType = TYPE.TABLE;
      } else {
        fieldType = TYPE.REFERENCE;
      }
    }
  } else if (
    typeof predicate.dataType != "undefined" &&
    predicate.dataType != null
  ) {
    let dataType = predicate.dataType.name;
    if (dataType === "anyURI") {
      fieldType = TYPE.FRAGMENT;
    } else if (dataType === "boolean") {
      fieldType = TYPE.BOOLEAN;
    } else if (dataType === "base64Binary") {
      fieldType = TYPE.FILE;
    } else {
      fieldType = TYPE.STRING;
      predicate.format = dataType;
    }
  } else {
    fieldType = TYPE.STRING;
  }

  if (fieldType == TYPE.STRING && predicate.dataType) {
    switch (predicate.dataType.name) {
      case "double": //double
      case "float": //float
      case "decimal": //decimal
      case "int": //int
      case "short": //short
      case "positiveInteger": //positive integer
      case "negativeInteger": //negative integer
      case "integer": //integer
        fieldType = TYPE.NUMBER;
        break;
      case "dateTime": //date
        fieldType = TYPE.DATETIME;
        break;
      case "date": //date
        fieldType = TYPE.DATE;
        break;
      default: //string
        break;
    }
  }

  predicate.type = fieldType;
}

export function changeField(
  finderData: FinderData,
  criteriaId: string,
  fieldId: string,
  loadedFields: FinderFieldsStore,
  ignoreRelation?: boolean
) {
  finderData.criteria = Object.assign({}, finderData.criteria);
  finderData.criteria.byId = Object.assign({}, finderData.criteria.byId);
  finderData.criteria.byId[criteriaId] = Object.assign(
    {},
    finderData.criteria.byId[criteriaId]
  );
  finderData.criteria.byId[criteriaId].fieldId = fieldId;
  finderData.criteria.byId[criteriaId].relations = [];
  if (!ignoreRelation && fieldId && loadedFields.byId[fieldId].id) {
    addRelation(finderData, criteriaId);
  }
}

function parseByType(value: any, type?: number) {
  switch (type) {
    case TYPE.NUMBER:
      value = Number(value);
      break;
    case TYPE.BOOLEAN:
      value = value == "true";
      break;
    default:
      break;
  }
  return value;
}

/* By reverse parsing find necessary fields */
function getFieldFetchList(
  predicates: string[],
  loadedFields: FinderFieldsStore
) {
  let fields = [];
  let fieldMap: { [k: string]: boolean } = {};
  for (let predicateName of predicates) {
    let fieldId = loadedFields.idByPredicateName[predicateName];
    let field = loadedFields.byId[fieldId];
    while (field && field.parentId) {
      field = loadedFields.byId[field.parentId];
      if (!field || fieldMap[field.id]) {
        break;
      }
      fieldMap[field.id] = true;
      fields.push(field.id);
    }
  }
  /* Reverse fields for proper downloading order */
  return fields.reverse();
}

function setCriteriaValues(
  result: ServerFinderFilter,
  finderData: FinderData,
  loadedFields: FinderFieldsStore,
  loadedPredicates: FinderPredicatesStore
) {
  let predicateNameList = [];
  let predicates = [];
  result.subjectFetchList = [];
  /* Parse local values */
  for (let criteriaGroupId of finderData.criteriaGroupList) {
    let criteriaGroup = finderData.criteriaGroup.byId[criteriaGroupId];
    let orBlock: ServerFinderFilterOrBlock = {
      andPredicates: [],
    };
    result.orBlocks.push(orBlock);
    for (let criteriaId of criteriaGroup.criteriaList) {
      let criteria = finderData.criteria.byId[criteriaId];
      if (!criteria.fieldId) {
        /* Selected field isn't last */
        continue;
      }

      let field = loadedFields.byId[criteria.fieldId];
      let joinedPredicateName = getJoinedPredicateName(field);
      if (!joinedPredicateName) {
        /* Selected field isn't last */
        continue;
      }
      let predicate = loadedPredicates.byName[joinedPredicateName];
      if (!predicate) {
        /* Selected field predicate wasn't loaded */
        continue;
      }
      predicateNameList.push(getPredicateName(field) as string);
      predicates.push(joinedPredicateName);

      let andPredicate: ServerFinderFilterAndBlock = {
        field: field.id,
        predicate: joinedPredicateName,
        orConditions: [],
      };

      orBlock.andPredicates.push(andPredicate);
      for (let relation of criteria.relations) {
        const isFile = isFileRelation(relation.type);
        const noValue =
          !relation.value && (!(relation as any).from || !(relation as any).to);
        if (!relation.type || (noValue && !isFile)) {
          /* Relation value wasn't selected */
          continue;
        }

        let operator: string;
        let parameters: (string | number | boolean)[] | null = null;

        /* Check if relation type isn't simple - parse relation */
        if (isFile) {
          operator = relation.type;
        } else if (relation.type === "between") {
          if (relation.from == null || relation.to == null) {
            continue;
          }
          let from = parseByType(relation.from, predicate.type);
          let to = parseByType(relation.to, predicate.type);

          operator = relation.type;
          parameters = [];
          parameters.push(from);
          parameters.push(to);
        } else {
          let value = parseByType(relation.value, predicate.type);

          /* Simple operator - just add it */
          operator = relation.type;
          parameters = [];
          parameters.push(value);

          /* Check if it is ref predicate */
          if (predicate.type === TYPE.REFERENCE) {
            result.subjectFetchList.push(value);
          }
        }

        let orCondition: ServerFinderFilterOrCondition = {
          operator: operator as any,
          parameters: parameters,
        };

        andPredicate.orConditions.push(orCondition);
      }
    }
  }

  /* Cleanup result */
  for (let i = result.orBlocks.length - 1; i >= 0; --i) {
    let orBlock = result.orBlocks[i];
    for (let j = orBlock.andPredicates.length - 1; j >= 0; --j) {
      let andPredicate = orBlock.andPredicates[j];
      for (let k = andPredicate.orConditions.length - 1; k >= 0; --k) {
        let orCondition = andPredicate.orConditions[k];
        if (orCondition.operator == null) {
          andPredicate.orConditions.splice(k, 1);
        }
      }
      if (andPredicate.orConditions.length == 0) {
        orBlock.andPredicates.splice(j, 1);
      }
    }
    if (orBlock.andPredicates.length == 0) {
      result.orBlocks.splice(i, 1);
    }
  }

  result.criteriaFetchList = getFieldFetchList(predicates, loadedFields);
  result.predicateFetchList = predicateNameList;
}

/* By reverse parsing find necessary fragments */
function getRequiredFragments(
  lastFragmentsIdList: string[] | null,
  loadedFragments: FinderFragmentsStore
) {
  if (!loadedFragments || !lastFragmentsIdList) {
    return [];
  }
  let fragments = [];
  let fragmentMap: { [k: string]: boolean } = {};
  for (let fragmentId of lastFragmentsIdList) {
    let fragment = loadedFragments.byId[fragmentId];
    while (fragment && fragment.parentId) {
      fragment = loadedFragments.byId[fragment.parentId];
      if (fragmentMap[fragment.id]) {
        break;
      }
      fragmentMap[fragment.id] = true;
      fragments.push(fragment.id);
    }
  }
  /* Reverse fragments for proper downloading order */
  return fragments.reverse();
}

function getFieldName(
  field: FinderField,
  predicate: FinderPredicate,
  loadedFields: FinderFieldsStore
) {
  if (!predicate) {
    return "";
  }
  let predicateName = predicate.label;
  while (field.parentId) {
    field = loadedFields.byId[field.parentId];
    predicateName = field.label + "/" + predicateName;
  }
  return predicateName;
}

function getEnumerationName(
  enumeration: FinderEnumeration,
  loadedEnumerations: FinderEnumerationsStore
) {
  if (!enumeration) {
    return "";
  }
  let fieldName = enumeration.label;
  while (enumeration.parentId) {
    enumeration = loadedEnumerations.byId[enumeration.parentId];
    fieldName = enumeration.label + "/" + fieldName;
  }
  return fieldName;
}

function findRelationNameByValue(
  relationArray: { label: string; value: string }[],
  value: string | null
) {
  if (value == null) {
    return relationArray[0].label;
  }
  for (let relation of relationArray) {
    if (relation.value == value) {
      return relation.label;
    }
  }
  return "";
}

function getRelationValue(relation: FinderRelation, messages: Translations) {
  /* Check if relation type isn't simple - parse relation */
  switch (relation.type) {
    case "between":
      let result = "";
      if (relation.from != null) {
        result += messages["MSG_FROM"] + ": " + relation.from + "   ";
      }
      if (relation.to != null) {
        result += messages["MSG_TO"] + ": " + relation.to;
      }
      return result;
    default:
      return relation.value;
  }
}

function createFinderQueryText(
  finderState: FinderState,
  finderData: FinderData,
  messages: Translations
) {
  let header = "";
  if (!finderData.sideBar) {
    return null;
  }
  if (
    finderData.sideBar.fragmentLevels &&
    finderData.sideBar.fragmentLevels.length > 0
  ) {
    header += messages["FINDER_SELECTION_AREA"] + ":\n";
    for (let level of finderData.sideBar.fragmentLevels) {
      if (level.selected.length == 0) {
        continue;
      }
      header += "\t" + level.name + ":\n";
      for (let selectedId of level.selected) {
        header +=
          "\t\t" + finderState.loadedFragments.byId[selectedId].label + "\n";
      }
    }
    header += "\n";
  }
  if (
    finderData.sideBar.classLevels &&
    finderData.sideBar.classLevels.length > 0
  ) {
    for (let level of finderData.sideBar.classLevels) {
      if (level.selected.length == 0) {
        continue;
      }
      header += level.name + ":\n";
      for (let selectedId of level.selected) {
        header +=
          "\t" + finderState.loadedClasses.byId[selectedId].label + "\n";
      }
    }
    header += "\n";
  }
  if (finderData.criteria && finderData.criteria.allIds.length > 0) {
    header += messages["FINDER_SELECTION_CRITERIA"] + ":\n";
    for (let i = 0; i < finderData.criteriaGroup.allIds.length; ++i) {
      const criteriaGroup =
        finderData.criteriaGroup.byId[finderData.criteriaGroup.allIds[i]];
      if (i > 0) {
        header +=
          "=========================" +
          messages["MSG_OR"] +
          "=========================\n";
      }
      for (let criteriaId of criteriaGroup.criteriaList) {
        const criteria = finderData.criteria.byId[criteriaId];
        if (!criteria.fieldId) {
          continue;
        }
        const field = finderState.loadedFields.byId[criteria.fieldId];
        if (!field) {
          continue;
        }
        const predicate =
          finderState.loadedPredicates.byName[
            getJoinedPredicateName(field) || ""
          ];
        if (!predicate) {
          continue;
        }
        const fieldType = predicate.type;
        const dataType = predicate.dataType;
        header +=
          "\t" +
          getFieldName(field, predicate, finderState.loadedFields) +
          ":\n";
        for (let relation of criteria.relations) {
          let relationName;
          let relationValue;
          switch (fieldType) {
            case TYPE.STRING:
              if (!dataType) {
                relationName = findRelationNameByValue(
                  stringRelationTypeList,
                  relation.type
                );
                relationValue = getRelationValue(relation, messages);
                break;
              }
              switch (dataType.name) {
                case "date": //date
                  relationName = findRelationNameByValue(
                    numberRelationTypeList,
                    relation.type
                  );
                  relationValue = getRelationValue(relation, messages);
                  break;
                default: //string
                  relationName = findRelationNameByValue(
                    stringRelationTypeList,
                    relation.type
                  );
                  relationValue = getRelationValue(relation, messages);
                  break;
              }
              break;
            case TYPE.NUMBER:
              relationName = findRelationNameByValue(
                numberRelationTypeList,
                relation.type
              );
              relationValue = getRelationValue(relation, messages);
              break;
            case TYPE.DATE:
              relationName = findRelationNameByValue(
                dateRelationTypeList,
                relation.type
              );
              relationValue = getRelationValue(relation, messages);
              break;
            case TYPE.BOOLEAN:
              relationName = findRelationNameByValue(
                booleanRelationTypeList,
                "equal"
              );
              relationValue =
                messages[
                  booleanRelationValueList[relation.value == "true" ? 0 : 1]
                    .label
                ];
              break;
            case TYPE.FILE:
              relationName = findRelationNameByValue(
                fileRelationTypeList,
                relation.type
              );
              relationValue = getRelationValue(relation, messages);
              break;
            case TYPE.FRAGMENT:
              relationName = findRelationNameByValue(
                fragmentRelationTypeList,
                relation.type
              );
              relationValue = getRelationValue(relation, messages);
              break;
            case TYPE.ENUMERATION:
              relationName = findRelationNameByValue(
                enumerationRelationTypeList,
                relation.type
              );
              relationValue = getEnumerationName(
                finderState.loadedEnumerations.byId[
                  finderState.loadedEnumerations.idByPredicateName[
                    relation.value
                  ]
                ],
                finderState.loadedEnumerations
              );
              break;
            case TYPE.REFERENCE:
            case TYPE.TABLE:
            /* Reference data type may be class name string */
            default:
              relationName = findRelationNameByValue(
                referenceRelationTypeList,
                relation.type
              );
              relationValue = "";
              break;
          }

          header +=
            "\t\t" + messages[relationName] + ": " + relationValue + "\n";
        }
      }
    }
  }
  if (header === "") {
    return null;
  }
  return header;
}

export function parseFinderToFilter(
  finderState: FinderState,
  finderData: FinderData
): ServerFinderFilter {
  const classList: string[] = [];

  const result: ServerFinderFilter = {
    orBlocks: [],
    criteriaFetchList: [],
    predicateFetchList: [],
    subjectFetchList: [],
    classList: null,
    classLevelFetchList: [],
    fragmentList: null,
    fragmentFetchList: [],
  };

  if (finderData.criteriaGroupList) {
    setCriteriaValues(
      result,
      finderData,
      finderState.loadedFields,
      finderState.loadedPredicates
    );
  }

  if (finderData.sideBar && finderData.sideBar.classLevels) {
    for (let level of finderData.sideBar.classLevels) {
      if (level.selected.length != 0) {
        result.classLevelFetchList.push(level.id);
      }
      for (let classShortId of level.selected) {
        let classObject = finderState.loadedClasses.byId[classShortId];
        let classId = getClassId(level, classObject);
        classList.push(classId);
      }
    }
  }

  let fragmentList = null;
  let fragmentIdList: string[] = [];
  if (finderData.sideBar && finderData.sideBar.fragmentLevels) {
    for (let i = finderData.sideBar.fragmentLevels.length - 1; i >= 0; --i) {
      let level = finderData.sideBar.fragmentLevels[i];
      if (level.selected.length > 0) {
        fragmentList = level.selected.map(
          (nodeId) => finderState.loadedFragments.byId[nodeId].fragmentId
        );
        fragmentIdList = level.selected.slice();
        break;
      }
    }
  }
  result.fragmentFetchList = getRequiredFragments(
    fragmentIdList,
    finderState.loadedFragments
  );
  if (classList.length !== 0) {
    result.classList = classList;
  }
  if (fragmentList) {
    result.fragmentList = fragmentList;
  }

  return result;
}

export function parseFinderToFilterWithQuery(
  finderState: FinderState,
  finderData: FinderData,
  messages: Translations
): ServerFinderFilterWithQuery {
  const filter = parseFinderToFilter(finderState, finderData);
  return {
    ...filter,
    queryText: createFinderQueryText(finderState, finderData, messages),
  };
}

/**Executed after finder change ready state to true */
export function onFinderReady(finder: FinderState) {
  if (!finder.initialFilter) {
    return;
  }
  finder.data = { ...finder.data };
  if (Array.isArray(finder.initialFilter.orBlocks)) {
    resetCriteria(finder.data);
    for (let orBlock of finder.initialFilter.orBlocks) {
      let criteriaGroupId = null;
      for (let andPredicate of orBlock.andPredicates) {
        const criteriaAddInfo = addNewCriteria(finder.data, criteriaGroupId);
        criteriaGroupId = criteriaAddInfo.criteriaGroupId;
        const criteriaId = criteriaAddInfo.criteriaId;
        changeField(
          finder.data,
          criteriaId,
          andPredicate.field,
          finder.loadedFields,
          true
        );
        for (let i = 0; i < andPredicate.orConditions.length; ++i) {
          const condition = andPredicate.orConditions[i];
          addRelation(finder.data, criteriaId);
          if (!condition.parameters || condition.parameters.length === 0) {
            continue;
          }
          let relation: FinderRelation = { type: condition.operator as any };
          if (condition.parameters.length >= 2) {
            relation = relation as FinderNumberRelation | FinderDateRelation;
            relation.from = condition.parameters[0] as
              | number
              | string
              | undefined;
            relation.to = condition.parameters[1] as
              | number
              | string
              | undefined;
          } else if (typeof condition.parameters[0] === "boolean") {
            relation.value = condition.parameters[0].toString();
          } else {
            relation.value = condition.parameters[0];
          }
          changeRelation(finder.data, criteriaId, i, relation);
        }
      }
    }
  }
  if (Array.isArray(finder.initialFilter.classList)) {
    const selectionMap: { [k: string]: boolean } = {};
    for (let selectedId of finder.initialFilter.classList) {
      selectionMap[selectedId] = true;
    }
    const joinIdByLevel: { [k: number]: string | null | undefined } = {};
    for (
      let levelIdx = 0;
      levelIdx < finder.data.sideBar.classLevels.length;
      ++levelIdx
    ) {
      joinIdByLevel[levelIdx] =
        finder.data.sideBar.classLevels[levelIdx].joinId;
    }
    for (let classData of Object.values(finder.loadedClasses.byId)) {
      const level = classData.level;
      const joinId = joinIdByLevel[level];
      let className = classData.className;
      if (joinId) {
        className = `${joinId}[${className}]`;
      }
      if (!selectionMap[className]) {
        continue;
      }
      addClass(finder.data, classData.id, level);
    }
  }
  if (Array.isArray(finder.initialFilter.fragmentList)) {
    /**Select all parent fragments */
    if (Array.isArray(finder.initialFilter.fragmentFetchList)) {
      for (let fragmentId of finder.initialFilter.fragmentFetchList) {
        addFragment(finder.data, fragmentId, finder.loadedFragments);
      }
    }
    const selectionMap: { [k: string]: boolean } = {};
    for (let selectedId of finder.initialFilter.fragmentList) {
      selectionMap[selectedId] = true;
    }
    for (let fragmentData of Object.values(finder.loadedFragments.byId)) {
      if (!selectionMap[fragmentData.fragmentId]) {
        continue;
      }
      addFragment(finder.data, fragmentData.id, finder.loadedFragments);
    }
  }
}

/**Check if finder ready to get data */
export function checkFinderReadyState(finder: FinderState) {
  if (finder.isReady) {
    return;
  }
  if (!isAutofillReady(finder)) {
    finder.isReady = false;
    return;
  }
  if (!isDependenciesFetched(finder)) {
    finder.isReady = false;
    return;
  }
  finder.isReady = true;
  onFinderReady(finder);
}

/**Check if finder sidebar was autofilled before data load */
export function isAutofillReady(finder: FinderState) {
  const finderOptions = finder.options;
  const finderData = finder.data;
  const loadedFragments = finder.loadedFragments;

  /**Currently only fragment tree can have autofill */
  if (
    !finderOptions.fragmentTree ||
    finder.fragmentsTreeHeaderError ||
    loadedFragments.error["null"]
  ) {
    return true;
  }

  /**Wait until root nodes will be loaded */
  if (!loadedFragments.fetched["null"]) {
    return false;
  }

  const fragmentsByLevel: { [k: number]: string[] } = {};
  for (let i = 0; i < finderOptions.fragmentTree.levels.length; ++i) {
    const level = finderOptions.fragmentTree.levels[i];
    if (!level.autofill) {
      break;
    }
    if (!finderData) {
      return false;
    }
    const levelData = finderData.sideBar.fragmentLevels[i];
    if (!levelData) {
      return false;
    }
    let shouldBeSelected: string[];
    if (i === 0) {
      shouldBeSelected = loadedFragments.rootIds;
    } else {
      shouldBeSelected = [];
      for (let parentLevelId of fragmentsByLevel[i - 1]) {
        /**Skip erroneous nodes */
        if (loadedFragments.error[parentLevelId]) {
          continue;
        }
        /**Wait until nodes will be loaded */
        if (!loadedFragments.fetched[parentLevelId]) {
          return false;
        }
        shouldBeSelected = shouldBeSelected.concat(
          loadedFragments.children[parentLevelId]
        );
      }
    }
    if (shouldBeSelected.length > levelData.selected.length) {
      return false;
    }
    fragmentsByLevel[i] = levelData.selected;
  }
  return true;
}

/**Check if all dependencies from initial filter was fetched */
export function isDependenciesFetched(finder: FinderState) {
  if (!finder.initialFilter) {
    return true;
  }
  for (let criteriaId of finder.initialFilter.criteriaFetchList) {
    if (!finder.loadedFields.fetched[criteriaId]) {
      return false;
    }
  }
  for (let predicateName of finder.initialFilter.predicateFetchList) {
    if (!finder.loadedPredicates.byName[predicateName]) {
      return false;
    }
  }
  for (let subjectRdfId of finder.initialFilter.subjectFetchList) {
    if (!finder.loadedObjectcards.fetched[subjectRdfId]) {
      return false;
    }
  }
  for (let classId of finder.initialFilter.classLevelFetchList) {
    if (!finder.loadedClasses.fetched[classId]) {
      return false;
    }
  }
  for (let fragmentId of finder.initialFilter.fragmentFetchList) {
    if (!finder.loadedFragments.fetched[fragmentId]) {
      return false;
    }
  }
  return true;
}
