import * as React from 'react';

import styles from "./Sitemap.module.css";

interface HeaderProps {
    filterSitemap: (filterString: string) => void
}
interface HeaderState {
    filter: string
}
export default class Header extends React.PureComponent<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);

        this.state = {
            filter: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.applyChange = this.applyChange.bind(this);
    }

    handleChange(event: any) {
        this.setState({ filter: event.target.value });
    }

    keyDown(event: any) {
        if (event.keyCode == '13') {
            this.applyChange();
            event.preventDefault();
        }
    }

    applyChange(){
        this.props.filterSitemap(this.state.filter);
    }

    render() {
        return <div className={styles.nptSitemapHeader}>
            <div className={styles.nptSitemapSearch}>
                <div className="input-group input-group-sm">
                    <input className={"form-control"} onChange={this.handleChange} onKeyDown={this.keyDown} />
                    <div className="input-group-append" onClick={this.applyChange}>
                        <button className="btn btn-primary" type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>;
    }
}