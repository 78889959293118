import * as React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { FragmentData, FragmentNodeMap, FragmentOption } from '../../../types/fragment';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import ModalView from '../ModalView';
import Select from 'react-select';
import { ActionMeta, ValueType } from 'react-select/src/types'
import { connect } from 'react-redux';
import { ApplicationAction, ApplicationState } from '../../../types';
import { ThunkDispatch } from 'redux-thunk'; 

interface ModalTableProps {
    modal: ModalInfo, 
    closeModal: (status: ModalStatus, result: any) => void
}
 

  
export default class AvatarHelpModal extends React.Component<ModalTableProps > {
    defaultValue:FragmentOption = { value: '', label: <FormattedMessage id="FRAGMENT_SELECT_PARENT" /> }
    title = { id:"NPT_AVATAR_MODAL_TITLE" };
  
    constructor(props: ModalTableProps) {
        super(props);
        
        this.closeModal = this.closeModal.bind(this);
    } 

    changeInputValue(key: string, event: any) {
        const newValue = event.target.value;
        this.setState({ ...this.state, [key]: newValue.trim() });
    }
    handleSelect = (option: ValueType<FragmentOption,false>, actionMeta: ActionMeta<FragmentOption>) => {
       
        const picked: FragmentOption = (option as FragmentOption); 
        if (actionMeta && actionMeta.action === 'clear'  ) {
            // this.props.fetchSubjects({});
            this.setState({ parent: this.defaultValue }) 
        } else  {
            // this.props.fetchSubjects({ id:picked.value });
            this.setState({ parent: picked}) 
        }
          
    }
 
    renderTemplate(): React.ReactElement {
        
        return (
           <div><FormattedMessage id="Вы находитесь не на своем сервере"/></div>
        );
    }
   
    closeModal(status: ModalStatus, result: any) {
      
            this.props.closeModal(status, result);
       

    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}
 