import * as constants from '../constants/fragment';
import {
    FragmentState,
    FragmentData,
    FragmentNodeMap,
    FragmentTreeState,
    FragmentTreeData,
} from '../types/fragment';
import { ApplicationAction } from '../types';
import { Reducer } from 'redux';
import { createRange, createSelected, isInRange, newRange, setupRange } from './utils';

function sortFunction(l1: string, l2: string) {
    return l1.localeCompare(l2, ['en', 'ru']) >= 0;
}

function sortOne(sortedNode: FragmentData, list: string[], nodeById: FragmentNodeMap) {
    const newList: string[] = []
    if (!sortedNode) {
        return list;
    }
    let templFragment: FragmentData | null = { ...sortedNode };
    const { rdfId: nodeId, label } = templFragment;
    list.forEach(r => {
        const node = nodeById[r];
        if (node.rdfId !== nodeId) {
            if (templFragment && !sortFunction(label, node.label)) {
                nodeId && newList.push(nodeId);
                templFragment = null;
            }
            newList.push(r)
        }
    })
    if (templFragment && nodeId) {
        newList.push(nodeId)
    }
    return newList;
}

function sortFragments(state: FragmentState, nodeIds: string[], parentId?: string) {
    let rootNodesIds = state.rootNodesIds;
    const childrenIds = state.childrenIds || {};
    const nodeById = state.nodeById;
    if (!rootNodesIds || !nodeById) {
        return state;
    }

    const sortedNodes: FragmentData[] = Object.values(nodeById).filter(f => f.rdfId && nodeIds.includes(f.rdfId));
    if (sortedNodes.length === 0) {
        return state;
    }

    if (!parentId && rootNodesIds.length > 1) {
        for (let node of sortedNodes) {
            rootNodesIds = sortOne(node, rootNodesIds, nodeById);
        }

    }
    else if (parentId && childrenIds[parentId] && childrenIds[parentId].length > 1) {
        for (let node of sortedNodes) {
            childrenIds[parentId] = sortOne(node, childrenIds[parentId], nodeById);
        }

    }

    state.rootNodesIds = rootNodesIds;
    state.childrenIds = childrenIds;
}


export function parseFragmentList(state: FragmentTreeData, list: FragmentData[], parentId?: string) {
    let newFragmentState = { ...state };
    let rootNodesIds = newFragmentState.rootNodesIds || [];
    const childrenIds = newFragmentState.childrenIds || {};
    const nodeById = newFragmentState.nodeById || {};
    // const loading = newFragmentState.loading || {};
    if (!parentId) {
        const roots: string[] = []
        list.forEach(f => f.rdfId && roots.push(f.rdfId))
        rootNodesIds = roots;
    } else {
        const childrenList: string[] = [];
        list.forEach(f => f.rdfId && childrenList.push(f.rdfId));
        childrenIds[parentId] = childrenList;
        // loading[parentId] = false;
    }

    list.forEach(f => {
        const { rdfId } = f;
        rdfId && (nodeById[rdfId] = { ...f, parentId });
    });

    newFragmentState = { ...newFragmentState, rootNodesIds, nodeById, childrenIds }
    return newFragmentState;
}

function moveFragment(fragments: FragmentData[], state: FragmentState): FragmentState {
    let nodes = { ...state.nodeById };
    let children = { ...state.childrenIds };
    let roots = state.rootNodesIds ? [...state.rootNodesIds] : [];
    if (!nodes || !fragments.length) {
        return state;
    }
    for (const fragment of fragments) {
        const { rdfId, parentId } = fragment;
        if (!rdfId) {
            continue;
        }
        const oldParent = nodes[rdfId].parentId;
        if (oldParent) {
            if (children[oldParent]) {
                children[oldParent] = children[oldParent].filter(i => i !== rdfId)
            }
        } else {
            roots = roots.filter(id => id !== rdfId)
        }

        if (parentId) {
            if (!children[parentId]) {
                children[parentId] = [];
            }
            children[parentId].push(rdfId);
        } else {
            roots.push(rdfId);
        }
        nodes[rdfId] = fragment;
    }
    const newState = { ...state, nodeById: nodes, childrenIds: children, rootNodesIds: roots, treeLoading: false }
    const movedIds: string[] = []
    fragments.forEach(f => f.rdfId && movedIds.push(f.rdfId));
    const parentId = fragments[0].parentId;
    if (movedIds.length) {
        sortFragments(newState, movedIds, parentId);
    }

    return newState;

}

function updateFragment(fragment: FragmentData, state: FragmentState): FragmentState {
    const { rdfId, parentId } = fragment;

    if (!rdfId) {
        return state;
    }
    let nodes = { ...state.nodeById };
    let childrenIds = { ...state.childrenIds } || {};
    let roots = state.rootNodesIds ? [...state.rootNodesIds] : [];
    if (!nodes || roots.length === 0) {
        return state;
    }

    const oldFragment = nodes[rdfId];
    const oldParent = oldFragment.parentId;
    if ((typeof oldParent !== undefined || typeof parentId !== undefined) && oldParent !== parentId) {
        if (parentId) {
            if (!childrenIds[parentId]) {
                childrenIds[parentId] = []
            }
            childrenIds[parentId] = [...childrenIds[parentId], rdfId];
        } else {
            roots.push(rdfId)
        }

        if (oldParent) {
            childrenIds[oldParent] && (childrenIds[oldParent] = childrenIds[oldParent].filter(i => i !== rdfId));
        } else {
            roots = roots.filter(i => i !== rdfId)
        }
    }
    nodes[rdfId] = fragment;
    const newState = { ...state, nodeById: nodes, treeLoading: false, childrenIds, rootNodesIds: roots };
    sortFragments(newState, [rdfId], parentId);
    return newState;
}



function addFragment(fragment: FragmentData, state: FragmentState): FragmentState {
    const { rdfId, parentId } = fragment; 
    if (!rdfId) {
        return state;
    }

    let nodes = { ...state.nodeById };
    let roots = state.rootNodesIds ? [...state.rootNodesIds] : [];
    let children = { ...state.childrenIds };

    if (!nodes) {
        nodes = { [rdfId]: fragment };
        roots = [rdfId];
        return { ...state, nodeById: nodes, rootNodesIds: roots }
    }

    if (parentId) {
        if (!children[parentId]) {
            children[parentId] = []
        }
        children[parentId].push(rdfId);
    } else {
        roots.push(rdfId)
    }
    nodes[rdfId] = fragment;
    state.nodeById = nodes;
    state.childrenIds = children;
    state.rootNodesIds = roots;
    const newState = { ...state, nodeById: nodes, rootNodesIds: roots, childrenIds: children, treeLoading: false };
    sortFragments(newState, [rdfId], parentId)
    return newState;
}
function deleteFragment(ids: string[], state: FragmentState): FragmentState {
    let nodes = { ...state.nodeById };
    let roots = state.rootNodesIds ? [...state.rootNodesIds] : [];
    let children = { ...state.childrenIds };

    for (const id of ids) {
        const deletedFragment = nodes[id];
        delete nodes[id];
        const { parentId } = deletedFragment;
        if (parentId) {
            if (children[parentId]) {
                children[parentId] = children[parentId].filter(i => i !== id);
            }
        } else {
            roots = roots.filter(i => i !== id)
        }
    }



    const active = state.active;
    const newActive = active && ids.includes(active) ? undefined : active;
    return { ...state, nodeById: nodes, rootNodesIds: roots, childrenIds: children, treeLoading: false, active: newActive };
}


const reducer: Reducer<FragmentState, ApplicationAction> = (state: FragmentState = {}, action: ApplicationAction): FragmentState => {

    switch (action.type) {
        case constants.SEND_FRAGMENTS: {
            const { list, parentId } = action.payload;
            const loading = state.loading || {};
            if (parentId) {
                loading[parentId] = false;
            }
            const newFrData = parseFragmentList(state, list, parentId);
            return { ...state, ...newFrData, treeError: undefined, treeLoading: false, loading };
        }
        case constants.SEND_ADDED_FRAGMENT: {
            const fragment = action.payload;
            return addFragment(fragment, state);
        }
        case constants.SEND_DELETED_FRAGMENT: {
            const id = action.payload;
            if (id.length === 0) {
                return state;
            }
            return deleteFragment(id, state);
        }
        case constants.SEND_UPDATED_FRAGMENT: {
            const fragment = action.payload;
            return updateFragment(fragment, state);
        }
        case constants.SEND_MOVED_FRAGMENT: {
            const fragmenta = action.payload;
            return moveFragment(fragmenta, state);
        }
        case constants.SEND_FRAGMENT_EDITED_START: {
            const fragment = action.payload;
            let edited = state.edited;
            if (!edited) {
                edited = {};
            }
            fragment.rdfId && (edited[fragment.rdfId] = fragment);
            return { ...state, edited }
        }
        case constants.SEND_FRAGMENT_EDITED_STOP: {
            const { id } = action.payload;
            let edited = state.edited;
            if (!edited) {
                return state;
            }
            delete edited[id];
            return { ...state, edited, hasSaved: undefined }
        }
        case constants.SEND_FRAGMENT_SAVE_EDITED: {
            const hasSaved = action.payload;
            return { ...state, hasSaved }
        }
        case constants.SEND_FRAGMENT_EDITED_UPDATE: {
            const { field, id, value } = action.payload;
            let edited = state.edited;
            if (!edited) {
                edited = {}
            }
            let editedFragment: FragmentData = edited[id];
            if (!editedFragment) {
                editedFragment = { label: '', description: '', rdfId: id };
            }
            editedFragment = { ...editedFragment, [field]: value };
            edited[id] = editedFragment;
            state.edited = edited;
            return { ...state, edited }
        }
        case constants.SEND_FRAGMENT_CTX_MENU: {
            const ctxMenu = action.payload;
            return { ...state, ctxMenu }
        }
        case constants.SEND_FRAGMENT_CUT_TARGET: {
            const cutTarget = action.payload;
            return { ...state, cutTarget }
        }
        case constants.SEND_FRAGMENT_UPLOAD_NODE_OVER: {
            const nodeUploadOver = action.payload;
            return { ...state, nodeUploadOver }
        }
        case constants.SEND_FRAGMENT_ACTIVE: {
            const active = action.payload;
            const prevActive = state.active;
            const selected = state.selected;
            if (prevActive && prevActive === active &&
                (!selected || (selected && !selected.includes(active)))) {
                return { ...state, active: undefined };
            }
            return { ...state, active };
        }
        case constants.SEND_FRAGMENT_SELECT: {
            const selected = action.payload;
            let selectedList = state.selected;
            let active = state.active;
            return { ...state, selected: createSelected(selectedList || null, active || null, selected || null) };
        }
        case constants.SEND_CLEAR_FRAGMENT_SELECT: {
            return { ...state, selected:  undefined, active: undefined };
        }
        case constants.SEND_FRAGMENT_RANGE: {
            const ranged = action.payload;
            if (!state) {
                return state
            }
            return { ...setupRange<FragmentState>(ranged || null, state) };
        }
        case constants.SEND_FRAGMENT_TOGGLE: {
            const expanded = { ...state.expanded };
            const id = action.payload;
            if (expanded[id]) {
                expanded[id] = false;
            } else {
                expanded[id] = true;
            }
            return { ...state, expanded };
        }
        case constants.SEND_FRAGMENT_TOGGLE_PATH: {
            const expanded = { ...state.expanded };
            const ids = action.payload;
            ids.forEach((id:string) => {
                if (expanded[id]) {
                    expanded[id] = false;
                } else {
                    expanded[id] = true;
                }
            });
            return { ...state, expanded };
        }
        case constants.SEND_FRAGMENT_LOADING: {
            const { id, loading: l } = action.payload;
            if (!id) {
                return { ...state, treeLoading: l }
            }
            const loading = { ...state.loading }
            loading[id] = l;
            return { ...state, loading };
        }
        case constants.SEND_FRAGMENT_ERROR: {
            const { id, error: e } = action.payload;
            if (!id) {
                return { ...state, treeError: e }
            }
            const error = { ...state.error }
            error[id] = e;
            return { ...state, error };
        }
        // case constants.SEND_SEARCH_INPUT_FRAGMENT:
        //     return { ...state, searchInput: action.payload };
    }
    return state;
}
export default reducer;