import { Action } from 'redux';

import * as constants from '../constants/modal';

export interface I18NString {
    id: string
    defaultMessage?: string
    values?: { [K: string]: any }
}

export function isI18NString(s: any): s is I18NString {
    return typeof s == 'object' && typeof s.id === "string";
}

export type CommonModalOptionsSize = "small" | "medium" | "large" | "extra-large" | "fullscreen"

export interface CommonModalOptions {
    title?: string | I18NString
    body?: string | I18NString
    footer?: string | I18NString
    data?: any
    size?: CommonModalOptionsSize
    backdrop?: 'static' | boolean
}

export interface PortalOptions extends CommonModalOptions {
    onOk?: OkCallback;
    onCancel?: CancelCallback;
    onClose?: CloseCallback;
  }

export interface TableModalOptions extends CommonModalOptions {
    tableId: string
    forcedSelectType?: "checkbox" | "radio"
    parameters?: { [k: string]: string }
    height?: number
    type?: "finder" | "table"
    paramsReadOnly?: boolean
    showToolbar?: boolean
    checkFunction?: (row: any, callback: (isValid: boolean) => void) => boolean
}

export interface TreeModalOptions extends CommonModalOptions {
    treeId: string
    rootRdfId?: string
    nodeTypeId?: string
    className?: string
    height?: number
    selectFragment?: boolean
}

export interface QuizModalOptions extends CommonModalOptions {
    type: "radio" | "checkbox"
    options: { value: string, label: string | I18NString }[]
    parsedOptions?: { value: string, label: string }[]
    selected?: string | string[]
    allowDeselect?: boolean
    allowEmpty?: boolean
}

export type ModalOptions = CommonModalOptions | TableModalOptions | TreeModalOptions | QuizModalOptions;

export interface ModalInfo {
    id: string
    type: string
    options: ModalOptions
}

export interface TableModalInfo extends ModalInfo {
    options: TableModalOptions
}

export interface TreeModalInfo extends ModalInfo {
    options: TreeModalOptions
}

export type ModalStatus = constants.MODAL_STATUS_OK | constants.MODAL_STATUS_CLOSE | constants.MODAL_STATUS_CANCEL

export type OkCallback = (result: any, extraData?: any) => (void | boolean | Promise<void | boolean>)
export type CloseCallback = () => void
export type CancelCallback = () => void
export type PortalCallback = (container: Element) => void

export interface ModalCallbacks {
    okCallback?: OkCallback,
    closeCallback?: CloseCallback,
    cancelCallback?: CancelCallback,
    portalCallback?: PortalCallback
}

export type ModalCallbacksRegistry = { [ID: string]: ModalCallbacks }

///////////
//Actions//
///////////
export interface SendOpenModal extends Action {
    type: constants.OPEN_MODAL;
    payload: ModalInfo
}

export interface SendUpdateModal extends Action {
    type: constants.UPDATE_MODAL;
    payload: {
        id: string,
        options: ModalOptions
    }
}

export interface SendUpdateModalTitle extends Action {
    type: constants.UPDATE_MODAL_TITLE;
    payload: {
        id: string,
        title?: string | I18NString
    }
}

export interface SendCloseModal extends Action {
    type: constants.CLOSE_MODAL;
    payload: {
        id: string,
        status: ModalStatus
    }
}

export type ModalAction = SendOpenModal | SendUpdateModal | SendUpdateModalTitle | SendCloseModal

/////////
//State//
/////////
export interface ModalState {
    modalList: ModalInfo[]
}