import React from "react";

import StatusPage from "./components/statuspage/StatusPage";

export interface AsyncModule {
    default: React.ComponentClass | React.FunctionComponent<any>;
}

export type ImportComponentCallback = () => Promise<AsyncModule | AsyncClass>
export type ModuleHookCallback = (module: AsyncModule) => void

export type AsyncClass = React.ComponentClass;

export type AsyncComponentStateValue = React.ComponentClass | React.FunctionComponent | null

export interface AsyncComponentState {
    asynComponentStateValue: AsyncComponentStateValue
    asyncComponentStateError?: any
}

export interface AsyncComponentProps {
    //alertError?: (reason: string) => void
    //moduleHook?: ModuleHookCallback
    [P: string]: any
}

export interface AsyncComponentRendererProps extends AsyncComponentState {
    alertError?: (reason: string) => void
    [P: string]: any
}

export function isAsyncModule(arg: AsyncModule | AsyncClass): arg is AsyncModule {
    return typeof (arg as AsyncModule).default != 'undefined';
}

export async function importAsyncComponent(
    importComponent: ImportComponentCallback, 
    moduleHook?: ModuleHookCallback): Promise<Partial<AsyncComponentState>>
{
    let r = null;
    try {
        r = await importComponent();
        if (isAsyncModule(r)) {
            if (moduleHook) {
                moduleHook(r);
            }
            return { asynComponentStateValue: r.default as AsyncComponentStateValue }
        } else {
            return { asynComponentStateValue: r }
        }
    } catch (e) {
        return { asyncComponentStateError: e as any }
    }
}

export const AsyncComponentRenderer  = (props: AsyncComponentRendererProps) => {
    const { asynComponentStateValue: C, asyncComponentStateError: error, alertError, ...otherProps } = props;
    if (error) {
        if (alertError) {
            alertError(error);
        }
        return <StatusPage status={error.code} />;
    }
    return C != null ? <C {...otherProps} /> : null
}


export default function asyncComponent<P = any>(callback: ImportComponentCallback) {

    class AsyncComponent extends React.PureComponent<P, AsyncComponentState> {

        constructor(props: P) {
            super(props);
            this.state = {
                asynComponentStateValue: null
            };
        }

        async componentDidMount() {
            const r = await importAsyncComponent(callback)
            this.setState(r as AsyncComponentState)
        }

        render() {
            //alertError={this.props.alertError}
            return <AsyncComponentRenderer {...this.props} {...this.state}/>
        }
    }

    return AsyncComponent;
}