import React from 'react';

interface SettingsProps {
    width: number
    height: number
    hatWidth: number
    hatHeight: number
    headWidth: number
    headHeight: number
    bodyWidth: number
    bodyHeight: number
    stroke: number
}

interface AccessDeniedIconProps {
    logo?: string
}
const AccessDeniedIcon: React.FunctionComponent<AccessDeniedIconProps> = React.memo((props) => {
    /**Settings values */
    const settings: SettingsProps = {
        width: 100,
        height: 100,
        hatWidth: 50,
        hatHeight: 27,
        headWidth: 32,
        headHeight: 30,
        bodyWidth: 85,
        bodyHeight: 40,
        stroke: 3
    }


    return <svg className="w-100 h-100" viewBox={`0 0 100 100`}>
        <PoliceHat {...settings} />
        <Face {...settings} />
        <Body {...settings} logo={props.logo} />
        <Tie {...settings} />
    </svg>;
});

const PoliceHat: React.FunctionComponent<SettingsProps> = (props) => {
    const yPadding = (100 - props.height) / 2;
    const hatWidth = props.hatWidth;
    const hatHeight = props.hatHeight;
    const headHeight = props.headHeight;
    const headWidth = props.headWidth;
    const hatHalfWidth = hatWidth / 2;
    const headHalfWidth = headWidth / 2;

    const headLeft = 50 - headHalfWidth - props.stroke / 2;
    const headRight = 50 + headHalfWidth + props.stroke / 2;
    const hatLeft = 50 - hatHalfWidth - props.stroke / 2;
    const hatRight = 50 + hatHalfWidth + props.stroke / 2;

    const hatBaseHeight = -2;
    const hatLineHeight = 3.75;
    const hatVisorHeight = 1.25;
    const hatTopHeight = hatHeight - hatBaseHeight - hatLineHeight - hatVisorHeight;
    const hatTopCenter = hatTopHeight * 0.6;
    return <g>
        <path
            style={{
                stroke: "none",
                // fill: "black"
            }}
            d={`M${headLeft} ${yPadding + hatHeight} 
                Q50 ${yPadding + hatHeight + headHeight * 0.35}, ${headRight} ${yPadding + hatHeight}
                Q${headRight + 1} ${yPadding + hatHeight - 3}, ${headRight - 1.75} ${yPadding + hatHeight - 2}
                Q50 ${yPadding + hatHeight + 1.25}, ${headLeft + 1.75} ${yPadding + hatHeight - 2}
                Q${headLeft - 1} ${yPadding + hatHeight - 3}, ${headLeft} ${yPadding + hatHeight} 
                M${headLeft} ${yPadding + hatHeight - 5}
                Q50 ${yPadding + hatHeight - 1.75}, ${headRight} ${yPadding + hatHeight - 5}
                C${headRight} ${yPadding + hatHeight * 0.9 - 5}, ${hatRight - hatWidth * 0.2} ${yPadding + hatHeight * 0.7 - 5}, ${hatRight} ${yPadding + hatTopCenter}
                Q50 ${yPadding + hatTopCenter + 3.25}, ${hatLeft} ${yPadding + hatTopCenter}
                C${hatLeft + hatWidth * 0.2} ${yPadding + hatHeight * 0.7 - 5}, ${headLeft} ${yPadding + hatHeight * 0.9 - 5}, ${headLeft} ${yPadding + hatHeight}`}
        />
        <path
            style={{
                stroke: "none",
                // fill: "black"
            }}
            d={`M${hatLeft} ${yPadding + hatTopCenter}
                C${hatLeft + hatWidth * 0.05} ${yPadding + hatTopHeight}, ${hatRight - hatWidth * 0.05} ${yPadding + hatTopHeight}, ${hatRight} ${yPadding + hatTopCenter}
                C${hatRight} ${yPadding}, ${hatLeft} ${yPadding}, ${hatLeft} ${yPadding + hatTopCenter}`}
        />
    </g>;
};

const Face: React.FunctionComponent<SettingsProps> = (props) => {
    const yTop = (100 - props.height) / 2;
    const hatHeight = props.hatHeight;
    const headWidth = props.headWidth;
    const headHeight = props.headHeight;
    const headHalfWidth = headWidth / 2;
    return <g>
        <path
            strokeWidth={props.stroke}
            style={{
                // stroke: "black",
                fill: "none"
            }}
            d={`M${50 - headHalfWidth} ${yTop + hatHeight} 
                C${50 - headWidth * 0.47} ${yTop + hatHeight + headHeight / 2}, ${50 - headWidth * 0.3} ${yTop + hatHeight + headHeight * 0.95}, 50 ${yTop + hatHeight + headHeight}
                C${50 + headWidth * 0.3} ${yTop + hatHeight + headHeight * 0.95}, ${50 + headWidth * 0.47} ${yTop + hatHeight + headHeight / 2}, ${50 + headHalfWidth} ${yTop + hatHeight}`}
        />
    </g>;
};

interface BodyProps extends SettingsProps {
    logo?: string
}
const Body: React.FunctionComponent<BodyProps> = (props) => {
    const hatHeight = props.hatHeight;
    const headWidth = props.headWidth;
    const headHeight = props.headHeight;
    const bodyWidth = props.bodyWidth;
    const bodyHeight = props.bodyHeight;

    const halfBodyWidth = bodyWidth / 2;

    const yPadding = (100 - props.height) / 2;
    const yTop = yPadding + hatHeight + headHeight;
    const yBottom = yTop + bodyHeight;

    const neckWidth = headWidth * 0.8;
    const neckHeight = headHeight * 0.31;

    const shouldersWidth = bodyWidth * 0.8;
    const armUplift = bodyHeight * 0.3;
    return <g>
        <path
            style={{
                stroke: "none",
                // fill: "black"
            }}
            d={`M${50 - neckWidth / 2} ${yTop - neckHeight} 
                C${50 - neckWidth * 0.6} ${yTop}, ${50 - neckWidth / 2} ${yTop + bodyHeight * 0.6}, 50 ${yTop + bodyHeight * 0.67}
                C${50 + neckWidth / 2} ${yTop + bodyHeight * 0.6}, ${50 + neckWidth * 0.6} ${yTop}, ${50 + neckWidth / 2} ${yTop - neckHeight}
                C${50 + shouldersWidth / 4} ${yTop - neckHeight * 0.8}, ${50 + shouldersWidth * 0.3} ${yTop - neckHeight * 0.5}, ${50 + shouldersWidth / 2} ${yTop}
                C${50 + halfBodyWidth * 0.9} ${yTop - bodyHeight * 0.02}, ${50 + halfBodyWidth * 0.97} ${yTop + bodyHeight * 0.5}, ${50 + halfBodyWidth * 0.97} ${yBottom - armUplift}
                C${50 - halfBodyWidth * 0.2} ${yBottom}, ${50 + halfBodyWidth * 0.2} ${yBottom}, ${50 - halfBodyWidth * 0.97} ${yBottom - armUplift}
                C${50 - halfBodyWidth * 0.97} ${yTop + bodyHeight * 0.5}, ${50 - halfBodyWidth * 0.9} ${yTop - bodyHeight * 0.02}, ${50 - shouldersWidth / 2} ${yTop}
                C${50 - shouldersWidth * 0.3} ${yTop - neckHeight * 0.5}, ${50 - shouldersWidth / 4} ${yTop - neckHeight * 0.8}, ${50 - neckWidth / 2} ${yTop - neckHeight}
                ${getPoliceBadge(props)}`}
        />
        <PoliceBadgeImg {...props} />
    </g>;
};

const Tie: React.FunctionComponent<SettingsProps> = (props) => {
    const hatHeight = props.hatHeight;
    const headHeight = props.headHeight;
    const bodyHeight = props.bodyHeight;

    const yPadding = (100 - props.height) / 2;
    const yTop = yPadding + hatHeight + headHeight;

    const yKnot = yTop + 2;
    const knotWidth = 8;
    const knotHeight = 7;
    const knotMiddleHeight = 5;

    const neckTieWidth = 18;
    const neckTieHeight = 8;

    const startWidth = 4;
    const endWidth = 13;

    return <g>
        <path
            strokeWidth={props.stroke}
            style={{
                stroke: "none",
                // fill: "black"
            }}
            d={`M${50 - startWidth / 2} ${yTop}
                L${50 - startWidth / 2} ${yKnot}
                L${50 - neckTieWidth / 2} ${yKnot + neckTieHeight}
                L${50 - knotWidth / 2} ${yKnot + knotMiddleHeight}
                L${50 - startWidth / 2} ${yKnot + knotHeight}
                L${50 - endWidth / 2} ${yTop + bodyHeight * 0.8}
                L50 ${yTop + bodyHeight * 0.9}
                L${50 + endWidth / 2} ${yTop + bodyHeight * 0.8}
                L${50 + startWidth / 2} ${yKnot + knotHeight}
                L${50 + knotWidth / 2} ${yKnot + knotMiddleHeight}
                L${50 + neckTieWidth / 2} ${yKnot + neckTieHeight}
                L${50 + startWidth / 2} ${yKnot}
                L${50 + startWidth / 2} ${yTop}`}
        />
    </g>;
};

const getPoliceBadgeBox = (props: SettingsProps) => {
    const hatHeight = props.hatHeight;
    const headHeight = props.headHeight;
    const bodyWidth = props.bodyWidth;
    const bodyHeight = props.bodyHeight;

    const xCenter = 50 + bodyWidth * 0.23;
    const yPadding = (100 - props.height) / 2;
    const yTop = yPadding + hatHeight + headHeight + bodyHeight * 0.3;

    const badgeHeight = 15;
    const badgeWidth = 10;

    const topCurveHeight = 3;

    return {
        xCenter,
        yTop,
        badgeHeight,
        badgeWidth,
        topCurveHeight
    }
}

const getPoliceBadge = (props: SettingsProps) => {
    const badgeBox = getPoliceBadgeBox(props);

    const xCenter = badgeBox.xCenter;
    const yTop = badgeBox.yTop;
    const badgeHeight = badgeBox.badgeHeight;
    const badgeWidth = badgeBox.badgeWidth;
    const topCurveHeight = badgeBox.topCurveHeight;
    return `M${xCenter - badgeWidth / 2} ${yTop + topCurveHeight}
            C${xCenter - badgeWidth / 2} ${yTop + badgeHeight / 2}, ${xCenter - badgeWidth / 2} ${yTop + badgeHeight * 0.8}, ${xCenter} ${yTop + badgeHeight}
            C${xCenter + badgeWidth / 2} ${yTop + badgeHeight * 0.8}, ${xCenter + badgeWidth / 2} ${yTop + badgeHeight / 2}, ${xCenter + badgeWidth / 2} ${yTop + topCurveHeight}
            Q${xCenter} ${yTop}, ${xCenter - badgeWidth / 2} ${yTop + topCurveHeight}`;
};

const PoliceBadgeImg: React.FunctionComponent<BodyProps> = (props) => {
    const badgeBox = getPoliceBadgeBox(props);

    const x = badgeBox.xCenter - badgeBox.badgeWidth * 0.45;
    const y = badgeBox.yTop + badgeBox.badgeHeight * 0.14;
    const width = badgeBox.badgeWidth * 0.9;
    const height = badgeBox.badgeHeight * 0.6;

    return <g>
        <image x={x} y={y} width={width} height={height} href={props.logo} fill="red" />
    </g>;
};

export default AccessDeniedIcon;