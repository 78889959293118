import { Reducer } from 'redux';

import { MenuState, MenuAction, } from '../types/menu';
import * as constants from '../constants/menu';
import { getMenuDefaultState, storeMenuState } from '../services/menu';

export const DEFAULT_MENU_STATE: MenuState = getMenuDefaultState();

const reducer: Reducer<MenuState, MenuAction> = (state: MenuState = DEFAULT_MENU_STATE, action: MenuAction): MenuState => {
    switch (action.type) {
        case constants.SEND_MENU_MINIMISED: {
            const minimized = { ...state.minimized, [action.payload.type]: action.payload.minimized };
            const nextState = { ...state, minimized };
            storeMenuState(nextState);
            return nextState;
        }
        case constants.SEND_MENU_CLOSED: {
            const closed = { ...state.closed, [action.payload.type]: action.payload.closed };
            const nextState = { ...state, closed };
            storeMenuState(nextState);
            return nextState;
        }
        case constants.SEND_MENU_ACTIVE: {
            const minimized = { ...state.minimized };
            if (minimized[action.payload] === undefined && state.closed[action.payload] !== true) {
                // minimized[action.payload] = true
            } 
            return { ...state, active: action.payload, minimized };
        }
        default:
            return state;
    }
}

export default reducer;