 

export const SEND_MENU_EDITOR_PATH_ACTIVE = 'SEND_MENU_EDITOR_PATH_ACTIVE';
export type SEND_MENU_EDITOR_PATH_ACTIVE  = typeof SEND_MENU_EDITOR_PATH_ACTIVE;

export const SEND_MENU_EDITOR_CLEAR_MENU = 'SEND_MENU_EDITOR_CLEAR_MENU';
export type SEND_MENU_EDITOR_CLEAR_MENU  = typeof SEND_MENU_EDITOR_CLEAR_MENU;

export const SEND_MENU_EDITOR_MENU_TOGGLE = 'SEND_MENU_EDITOR_MENU_TOGGLE';
export type SEND_MENU_EDITOR_MENU_TOGGLE  = typeof SEND_MENU_EDITOR_MENU_TOGGLE;

export const SEND_MENU_EDITOR_MENU_ACTIVE = 'SEND_MENU_EDITOR_MENU_ACTIVE';
export type SEND_MENU_EDITOR_MENU_ACTIVE  = typeof SEND_MENU_EDITOR_MENU_ACTIVE;

export const SEND_MENU_EDITOR_MENU_ACTIVE_ALL = 'SEND_MENU_EDITOR_MENU_ACTIVE_ALL';
export type SEND_MENU_EDITOR_MENU_ACTIVE_ALL  = typeof SEND_MENU_EDITOR_MENU_ACTIVE_ALL;
 

export const SEND_MENU_EDITOR_LOADING = 'SEND_MENU_EDITOR_LOADING';
export type SEND_MENU_EDITOR_LOADING  = typeof SEND_MENU_EDITOR_LOADING;

export const SEND_MENU_EDITOR_MENU = 'SEND_MENU_EDITOR_MENU';
export type SEND_MENU_EDITOR_MENU  = typeof SEND_MENU_EDITOR_MENU;

export const SEND_MENU_EDITOR_EDITING_MENU = 'SEND_MENU_EDITOR_EDITING_MENU';
export type SEND_MENU_EDITOR_EDITING_MENU  = typeof SEND_MENU_EDITOR_EDITING_MENU;

export const SEND_MENU_EDITOR_SAVE_MENU = 'SEND_MENU_EDITOR_SAVE_MENU';
export type SEND_MENU_EDITOR_SAVE_MENU  = typeof SEND_MENU_EDITOR_SAVE_MENU;

export const SEND_MENU_EDITOR_SAVE_MENU_ITEM = 'SEND_MENU_EDITOR_SAVE_MENU_ITEM';
export type SEND_MENU_EDITOR_SAVE_MENU_ITEM  = typeof SEND_MENU_EDITOR_SAVE_MENU_ITEM;

export const SEND_MENU_EDITOR_REMOVE_MENU = 'SEND_MENU_EDITOR_REMOVE_MENU';
export type SEND_MENU_EDITOR_REMOVE_MENU  = typeof SEND_MENU_EDITOR_REMOVE_MENU;

export const SEND_MENU_EDITOR_REMOVE_ITEM = 'SEND_MENU_EDITOR_REMOVE_ITEM';
export type SEND_MENU_EDITOR_REMOVE_ITEM  = typeof SEND_MENU_EDITOR_REMOVE_ITEM;

export const SEND_MENU_EDITOR_CUT_ITEM = 'SEND_MENU_EDITOR_CUT_ITEM';
export type SEND_MENU_EDITOR_CUT_ITEM  = typeof SEND_MENU_EDITOR_CUT_ITEM;

export const SEND_MENU_EDITOR_COPY_ITEM = 'SEND_MENU_EDITOR_COPY_ITEM';
export type  SEND_MENU_EDITOR_COPY_ITEM  = typeof SEND_MENU_EDITOR_COPY_ITEM;

export const SEND_MENU_EDITOR_CTX_MENU_LIST = 'SEND_MENU_EDITOR_CTX_MENU_LIST';
export type SEND_MENU_EDITOR_CTX_MENU_LIST  = typeof SEND_MENU_EDITOR_CTX_MENU_LIST;

export const SEND_MENU_EDITOR_CTX_MENU_EDITOR = 'SEND_MENU_EDITOR_CTX_MENU_EDITOR';
export type SEND_MENU_EDITOR_CTX_MENU_EDITOR  = typeof SEND_MENU_EDITOR_CTX_MENU_EDITOR;

export const SEND_MENU_EDITOR_OPERATION = 'SEND_MENU_EDITOR_OPERATION';
export type SEND_MENU_EDITOR_OPERATION  = typeof SEND_MENU_EDITOR_OPERATION;

export const SEND_MENU_EDITOR_FILTER_FOLDERS = 'SEND_MENU_EDITOR_FILTER_FOLDERS';
export type SEND_MENU_EDITOR_FILTER_FOLDERS  = typeof SEND_MENU_EDITOR_FILTER_FOLDERS;

export const SEND_MENU_EDITOR_ACL_LIST = 'SEND_MENU_EDITOR_ACL_LIST';
export type SEND_MENU_EDITOR_ACL_LIST  = typeof SEND_MENU_EDITOR_ACL_LIST;
  
 
export const SEND_MENU_EDITOR_ITEM_SOURCE_CODE = 'SEND_MENU_EDITOR_ITEM_SOURCE_CODE';
export type SEND_MENU_EDITOR_ITEM_SOURCE_CODE = typeof SEND_MENU_EDITOR_ITEM_SOURCE_CODE;

export const SEND_MENU_EDITOR_ITEM_SOURCE_CODE_LOADING = 'SEND_MENU_EDITOR_ITEM_SOURCE_CODE_LOADING';
export type SEND_MENU_EDITOR_ITEM_SOURCE_CODE_LOADING = typeof SEND_MENU_EDITOR_ITEM_SOURCE_CODE_LOADING;

export enum CtxMenuId{
    LIST='listCtxMenu',
    EDITOR='editorCtxMenu'
}

export enum DividerType{
    AFTER='after',
    BEFORE='before',
    BOTH='both'
    
}
export enum EventType{
    UPDATE_MENU_LIST_TREE='updateMenuListTree'
}

export enum MenuOperations{
    COPY_ITEM='copy_item',
    CUT_ITEM='cut_item'
}
 