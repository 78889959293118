export const SEND_ALL_CLASSES = 'SEND_ALL_CLASSES'
export type SEND_ALL_CLASSES = typeof SEND_ALL_CLASSES

export const SEND_DICTIONARY = 'SEND_DICTIONARY'
export type SEND_DICTIONARY = typeof SEND_DICTIONARY

export const SEND_DATATYPE_LIST = 'SEND_DATATYPE_LIST'
export type SEND_DATATYPE_LIST = typeof SEND_DATATYPE_LIST

export const SEND_PACKAGE_CLASSES = 'SEND_PACKAGE_CLASSESS'
export type SEND_PACKAGE_CLASSES = typeof SEND_PACKAGE_CLASSES

export const SEND_PACKAGE_DATA = 'SEND_PACKAGE_DATA'
export type SEND_PACKAGE_DATA = typeof SEND_PACKAGE_DATA

export const SEND_CLASS_INFO = 'SEND_CLASS_INFO'
export type SEND_CLASS_INFO = typeof SEND_CLASS_INFO

export const SEND_ROOT_PACKAGES = 'SEND_ROOT_PACKAGES'
export type SEND_ROOT_PACKAGES = typeof SEND_ROOT_PACKAGES


export const SEND_PROFILE_TREE_LOADING = 'SEND_PROFILE_TREE_LOADING'
export type SEND_PROFILE_TREE_LOADING = typeof SEND_PROFILE_TREE_LOADING

export const SEND_PROFILE_TREE_ERROR = 'SEND_PROFILE_TREE_ERROR'
export type SEND_PROFILE_TREE_ERROR = typeof SEND_PROFILE_TREE_ERROR

export const SEND_EXPAND_TREE_NODE = 'SEND_EXPAND_TREE_NODE'
export type SEND_EXPAND_TREE_NODE = typeof SEND_EXPAND_TREE_NODE

export const SEND_ACTIVE_TREE_NODE = 'SEND_ACTIVE_TREE_NODE'
export type SEND_ACTIVE_TREE_NODE = typeof SEND_ACTIVE_TREE_NODE;

export const SEND_INITIALIZE_CLASS_CARD = 'SEND_INITIALIZE_CLASS_CARD';
export type SEND_INITIALIZE_CLASS_CARD = typeof SEND_INITIALIZE_CLASS_CARD;

export const SEND_CLASS_CARD_TAB = 'SEND_CLASS_CARD_TAB';
export type SEND_CLASS_CARD_TAB = typeof SEND_CLASS_CARD_TAB;

export const SEND_CLASS_CHILDREN_INFO = 'SEND_CLASS_CHILDREN_INFO'
export type SEND_CLASS_CHILDREN_INFO = typeof SEND_CLASS_CHILDREN_INFO

export const SEND_CLASS_TREE_NODE_COLLAPSED = 'SEND_CLASS_TREE_NODE_COLLAPSED'
export type SEND_CLASS_TREE_NODE_COLLAPSED = typeof SEND_CLASS_TREE_NODE_COLLAPSED

export const SEND_ACTIVE_RELATION_INFO = 'SEND_ACTIVE_RELATION_INFO';
export type SEND_ACTIVE_RELATION_INFO = typeof SEND_ACTIVE_RELATION_INFO;

export const SEND_ACTIVE_PREDICATE_ID = 'SEND_ACTIVE_PREDICATE_ID ';
export type SEND_ACTIVE_PREDICATE_ID = typeof SEND_ACTIVE_PREDICATE_ID;

export const SEND_CLASS_CARD_EDIT = 'SEND_CLASS_CARD_EDIT';
export type SEND_CLASS_CARD_EDIT = typeof SEND_CLASS_CARD_EDIT;

export const SEND_PREDICATE_ID_ON_DELETE = 'SEND_PREDICATE_ID_ON_DELETE';
export type SEND_PREDICATE_ID_ON_DELETE = typeof SEND_PREDICATE_ID_ON_DELETE;

export const SEND_ALL_PREDICATES_ID_ON_DELETE = 'SEND_ALL_PREDICATES_ID_ON_DELETE';
export type SEND_ALL_PREDICATES_ID_ON_DELETE = 'SEND_ALL_PREDICATES_ID_ON_DELETE';

export const SEND_PREDICATE_CREATE = 'SEND_PREDICATE_CREATE';
export type SEND_PREDICATE_CREATE = typeof SEND_PREDICATE_CREATE;

export const SEND_SAVE_CLASS_INFO = 'SEND_SAVE_CLASS_INFO';
export type SEND_SAVE_CLASS_INFO = typeof SEND_SAVE_CLASS_INFO;

export const SEND_EDIT_CLASS_FINISH = 'SEND_EDIT_CLASS_FINISH';
export type SEND_EDIT_CLASS_FINISH = typeof SEND_EDIT_CLASS_FINISH;

export const SEND_EDIT_CLASS = 'SEND_EDIT_CLASS';
export type SEND_EDIT_CLASS = typeof SEND_EDIT_CLASS;
 

export const SEND_NEW_CLASS_PACKAGE_ID = 'SEND_NEW_CLASS_PACKAGE_ID';
export type SEND_NEW_CLASS_PACKAGE_ID = typeof SEND_NEW_CLASS_PACKAGE_ID;

export const SEND_UPDATE_TREE_NODE = 'SEND_UPDATE_TREE_NODE';
export type SEND_UPDATE_TREE_NODE = typeof SEND_UPDATE_TREE_NODE;

export const SEND_CONTEXT_MENU_NODE_ID = 'SEND_CONTEXT_MENU_NODE_ID';
export type SEND_CONTEXT_MENU_NODE_ID = typeof SEND_CONTEXT_MENU_NODE_ID;

export const SEND_CLEAR_CLASS_DATA = 'SEND_CLEAR_CLASS_DATA';
export type SEND_CLEAR_CLASS_DATA = typeof SEND_CLEAR_CLASS_DATA;


export const SEND_EDIT_PACKAGE_NODE = 'SEND_EDIT_PACKAGE_NODE';
export type SEND_EDIT_PACKAGE_NODE = typeof SEND_EDIT_PACKAGE_NODE;

export const SEND_MOVE_NODE = 'SEND_MOVE_NODE';
export type SEND_MOVE_NODE = typeof SEND_MOVE_NODE;

export const SEND_CLASS_INFO_LOADING = 'SEND_CLASS_INFO_LOADING';
export type SEND_CLASS_INFO_LOADING = typeof SEND_CLASS_INFO_LOADING;

export const SEND_SEARCH_INPUT_PROFILE = 'SEND_SEARCH_INPUT_PROFILE';
export type SEND_SEARCH_INPUT_PROFILE  = typeof SEND_SEARCH_INPUT_PROFILE; 

export const SEND_FILTERED_TREE_ITEMS = 'SEND_FILTERED_TREE_ITEMS';
export type SEND_FILTERED_TREE_ITEMS = typeof SEND_FILTERED_TREE_ITEMS;

export const SEND_FILTERED_DATA ='SEND_FILTERED_DATA';
export type SEND_FILTERED_DATA = typeof SEND_FILTERED_DATA;


export const SEND_SEARCH_LOADING = 'SEND_SEARCH_LOADING';
export type SEND_SEARCH_LOADING = typeof SEND_SEARCH_LOADING;
// export const SEND_DELETE_CLASS_FROM_TREE = 'SEND_DELETE_CLASS_FROM_TREE'

export const PROFILEEDITOR_URL = "/rest/profileeditor";
export const EDITOR_SERVICE_URL = PROFILEEDITOR_URL + "/editattributes";
export const CREATE_PREDICATE = "CreatePredicate";
export const UPDATE_PREDICATE = "UpdatePredicate";
export const REMOVE_PREDICATE = "RemovePredicate";

export const PROFILE_CONTEXT_PATH = '/developer/profileeditor';

export const PROFILE_EXPORT_RDF_URL = `/rest/rdfmodel/export/profile`;
export const PROFILE_IMPORT_RDF_URL = '/rest/rdfmodel/import/rdf'
export const PROFILE_DICTIONARY_URL = '/rest/profileeditor/dictionary';
export const PROFILE_PACKAGEDATA_URL = '/rest/profileeditor/packagedata';
export const PROFILE_CLASSES_LIST_URL = '/rest/profileeditor/allclasses'
export const PROFILE_FILTER_URL = '/rest/profileeditor/filter'
export const PROFILE_DATATYPE_LIST_URL = '/rest/profileeditor/getdatatypelist'
export const PROFILE_CLASS_INFO_URL = '/rest/profileeditor/classinfo'
export const PROFILE_CLASS_CHILDREN_INFO_URL = '/rest/profileeditor/classchildreninfo'
export const PROFILE_PACKAGE_CLASSES_URL = '/rest/profileeditor/packageclasses'
export const PROFILE_EDITOR_URL = '/rest/profileeditor'
export const PROFILE_REMOVE_CLASS_URL = '/rest/profileeditor/removeclass'
export const PROFILE_REMOVE_CLASS_BATCH_URL = '/rest/profileeditor/removeclass/batch'
export const PROFILE_REMOVE_PACKAGE_URL = '/rest/profileeditor/removepackage'
