import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faInfoCircle, fas, faSave, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ActionMeta, ValueType } from 'react-select/src/types';
import { CSSTransition } from "react-transition-group";
import { DividerType } from '../../../constants/menueditor';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { CheckBoxKeys } from '../../../constants/subject';
import { ApplicationState } from '../../../types';
import { Acl, AclMap, extractFaFullName, isFullIconName, MenuItem } from '../../../types/menueditor';
import { isI18NString, ModalInfo, ModalStatus } from '../../../types/modal';
import { wrapWithTooltip } from '../../developer/menueditor/MenuEditorPage';
import { SliderCheckbox } from '../../objectcard/inputs/CheckboxVariants';
import ModalView from '../ModalView';
import './MenuInfoModal.css';
library.add(fas);

type OptionType = {label:JSX.Element,value:DividerType}
type OptionTypeAcl = {label:string,value:Acl}

type IconOptionType = { label: any, value: string }
type DataTypeList = { [LANG: string]: string }
type DataType = { lang: string, value: string }
type MenuItemKeyType = keyof MenuItem;


enum MenuPage {
    MAIN = 'main',
    LOGO = 'logo',
    TITLE = 'title',
    AUTH = 'auth',
    ICON = 'icon'
}

enum SelectOptionKey {
    DIVIDER = "d",
    ICON = "i"
}


const renderIcon = (iconName: string) => {
    if (!iconName || !iconName.trim()) {
        return null;
    }
    let icon = <FontAwesomeIcon icon={iconName as IconName} />

    if (isFullIconName(iconName)) {
        icon = <i className={iconName}></i>;

    }

    return (
        <div>
            {icon}
            <span className="ml-2">{iconName}</span>
        </div>
    )
}

interface MenuItemModalProps {
    modal: ModalInfo,
    aclMap?: AclMap,
    childrenMap?: { [ID: string]: string[] }
    closeModal: (status: ModalStatus, result: any) => void
}

interface MenuItemModalState {
    item?: MenuItem
    isMenu: boolean
    fileName?: string
    page: MenuPage
    isNameEmpty?: boolean
    isFileNameEmpty?: boolean
    selected: AclMap
    isIconSelected: boolean
    // label: string,
    // description: string,
}

class MenuItemModal extends React.Component<MenuItemModalProps, MenuItemModalState> {

    title = { id: "MENU_EDITOR_INFO_MODAL_TITLE_ITEM_EDIT" };
    iconOptions: IconOptionType[] = [];

    constructor(props: MenuItemModalProps) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
        const body = this.props.modal.options.body;
        const title = this.props.modal.options.title;
        this.title = isI18NString(title) ? title :
            title ? { id: title } : { id: 'TITLE' };
        if (typeof body === 'string') {
            const menuInfo = JSON.parse(body);

            const menu: MenuItem = menuInfo.item;
            const isMenu = menuInfo.isMenu || false;
            const fileName = menuInfo.fileName
            const aclStr = menu ? menu.a : undefined;
            const selected: AclMap = aclStr ? this.listToAclRules(aclStr) : {};
            this.state = { item: menu, isMenu, page: MenuPage.MAIN, fileName, selected, isIconSelected: false };
        } else {
            this.state = { item: {}, isMenu: false, page: MenuPage.MAIN, selected: {}, isIconSelected: false }
        }
        this.iconOptions.length === 0 && this.initializeIconOption();
    }
    handleChangePage = (page: MenuPage) => () => {
        this.setState({ page })
    }
    handleOptionPickUp = (key: SelectOptionKey) => (option: ValueType<OptionType,any>, actionMeta: ActionMeta<any>) => {

        const picked = option as OptionType;
        const newItem = { ...this.state.item } as MenuItem;
        if (actionMeta && actionMeta.action === 'clear') {
            newItem[key] = undefined;
        } else {
            newItem[key] = picked.value;
        }
        this.setState({ item: newItem })
    }

    initializeIconOption = () => {
        Object.entries(fas).forEach(e => {
            const [name, i] = e
            const { icon, iconName, prefix } = i;
            const label = renderIcon(iconName);
            this.iconOptions.push({ label, value: iconName })
        })
    }

    getDividerTypeOption(value: DividerType) {
        switch (value) {
            case DividerType.AFTER:
                return { value: DividerType.AFTER, label: <FormattedMessage id="MENU_EDITOR_DIVIDER_TYPE_AFTER" /> };
            case DividerType.BEFORE:
                return { value: DividerType.BEFORE, label: <FormattedMessage id="MENU_EDITOR_DIVIDER_TYPE_BEFORE" /> };
            case DividerType.BOTH:
                return { value: DividerType.BOTH, label: <FormattedMessage id="MENU_EDITOR_DIVIDER_TYPE_BOTH" /> };
            default:
                return undefined;
        }
    }
    changeState = (key: MenuItemKeyType, value: any) => {
        const newMenuItem = { ...this.state.item, [key]: value }
        this.setState({ item: newMenuItem });
    }

    changeInputValue(key: MenuItemKeyType, event: any) {
        const newValue = event.target.value;
        this.changeState(key, newValue);
    }
    changeCheck(key: MenuItemKeyType, event: any) {
        const newValue = event.target.checked;
        this.changeState(key, newValue);
    }
    changeFileName = (event: any) => {
        const newValue = event.target.value;
        this.setState({ fileName: newValue });
    }
    saveValue = (key: MenuItemKeyType) => (d: DataType) => {
        const { lang, value } = d
        const val = { [lang]: value };
        let item = this.state.item;
        if (key === 't' || key === 'l') {
            if (item) {
                this.changeState(key, { ...item[key], ...val })
            } else {
                this.changeState(key, val)
            }
        }
    }
    removeValue = (key: MenuItemKeyType) => (lang: string) => {
        let item = this.state.item;
        if (key === 't' || key === 'l') {
            if (item) {
                const i = item[key];
                i && (delete i[lang])
                this.changeState(key, { ...i })
            }
        }
    }
    addAuth = (auth: Acl) => {
        const { rdfId } = auth;
        const selectedAcl: AclMap = { ...this.state.selected }
        selectedAcl[rdfId] = auth;
        this.setState({ selected: selectedAcl });
    }
    removeAuth = (rdfId: string) => {
        const selectedAcl: AclMap = { ...this.state.selected }
        delete selectedAcl[rdfId];
        this.setState({ selected: selectedAcl });
    }
    renderEmpty = (isEmpty: boolean | undefined) => {
        return isEmpty && (<Form.Text className="text-danger">
            <FormattedMessage id={"MENU_EDITOR_FIELD_EMPTY"} />
        </Form.Text>)
    }

    renderAuthPage = () => {
        const { aclMap } = this.props;
        const { selected } = this.state;

        return (
            <>
                {this.renderTitle("MENU_EDITOR_MENU_PAGE_AUTH")}
                <AuthList list={aclMap || {}} selected={selected} add={this.addAuth} remove={this.removeAuth} />
            </>
        )
    }

    renderFileNameInput = () => {
        const { isMenu, isFileNameEmpty: isEmpty } = this.state;
        if (!isMenu) {
            return <></>
        }

        return (
            <Form.Group controlId="name">
                <Form.Label>
                    <FormattedMessage id={"MENU_EDITOR_FILE_NAME"} />
                </Form.Label>
                <Form.Control type="input" value={this.state.fileName} onChange={this.changeFileName} />
                {this.renderEmpty(isEmpty)}
            </Form.Group>
        )

    }
    renderDividerType() {
        const { item } = this.state;
        const options = [
            { value: DividerType.AFTER, label: <FormattedMessage id="MENU_EDITOR_DIVIDER_TYPE_AFTER" /> },
            { value: DividerType.BEFORE, label: <FormattedMessage id="MENU_EDITOR_DIVIDER_TYPE_BEFORE" /> },
            { value: DividerType.BOTH, label: <FormattedMessage id="MENU_EDITOR_DIVIDER_TYPE_BOTH" /> }
        ]
        let current;
        if (item && item.d) {
            current = this.getDividerTypeOption(item.d);
        }
        return (
            <Form.Group controlId="reference">
                <Form.Label>
                    <FormattedMessage id="MENU_EDITOR_DIVIDER_TYPE" />
                </Form.Label>
                <Select
                    className="npt-select"
                    isClearable={true}
                    value={current}
                    placeholder={<FormattedMessage id="MENU_EDITOR_DIVIDER_PLACEHOLDER" />}
                    options={options}
                    onChange={this.handleOptionPickUp(SelectOptionKey.DIVIDER)} 
                />
            </Form.Group>
        )
    }

    changeIconMode = (isSelect: boolean) => {
        this.setState({ isIconSelected: isSelect })
    }
    renderIconPage() {
        const { item, isIconSelected } = this.state;
        const currentName = item && item.i || undefined;
        const variants = [];
        variants.push({ label: { id: 'MENU_EDITOR_WRITE_ICON' }, value: false, key: CheckBoxKeys.NO })
        variants.push({ label: { id: 'MENU_EDITOR_SELECT_ICON' }, value: true, key: CheckBoxKeys.YES })

        return <>
            <Row className="d-flex flex-row justify-content-between mb-4">
                <Col md={11} className="  ">
                    <h5>
                        <FormattedMessage id={"MENU_EDITOR_MENU_ICON"} />
                    </h5>
                    <div>
                        <SliderCheckbox variants={variants} value={isIconSelected} change={this.changeIconMode} />
                    </div>
                </Col>
                <Col md={1} className=" d-flex  align-items-start  col-md-1">
                    <Button variant="outline-primary" className="px-2  ml-auto" onClick={this.handleChangePage(MenuPage.MAIN)}>
                        <FontAwesomeIcon icon={faUndo} />
                    </Button>
                </Col>
            </Row>
            <IconEditor iconOptions={this.iconOptions}
                currentIconName={currentName}
                selectMode={isIconSelected}
                setIcon={(value: string) => { this.changeState('i', value) }}
                changePage={(page: MenuPage) => this.setState({ page })}
            />
        </>
    }
    renderMainPage() {
        const { item, isNameEmpty: isEmpty } = this.state
        const { childrenMap } = this.props;
        const hasChildren = childrenMap && item && item.id && childrenMap[item.id] || false;
        return (
            <Form>
                {this.renderFileNameInput()}
                <Form.Group className="mb-1" controlId="reference">
                    <ButtonGroup>
                        <Button variant="outline-success" className="px-2" onClick={this.handleChangePage(MenuPage.TITLE)}>
                            <FormattedMessage id={"MENU_EDITOR_SHOW_TITLE"} />
                        </Button>
                        {/* <Button className="ml-1 px-2" variant="outline-success"  onClick={this.handleChangePage(MenuPage.LOGO)}>
                            <FormattedMessage id={"MENU_EDITOR_SHOW_LOGO"} />
                        </Button> */}
                        <Button className="ml-1 px-2" variant="outline-success" onClick={this.handleChangePage(MenuPage.AUTH)}>
                            <FormattedMessage id={"MENU_EDITOR_MENU_PAGE_AUTH"} />
                        </Button>
                        <Button className="ml-1 px-2" variant="outline-success" onClick={this.handleChangePage(MenuPage.ICON)}>
                            <FormattedMessage id={"MENU_EDITOR_MENU_PAGE_ICON"} />
                        </Button>
                    </ButtonGroup>
                </Form.Group>
                <Form.Group className="mb-1" controlId="name">
                    <Form.Label>
                        <FormattedMessage id={"MENU_EDITOR_NAME"} />
                    </Form.Label>
                    <Form.Control type="input" value={item && item.n || ''} onChange={this.changeInputValue.bind(this, "n")} />
                    {this.renderEmpty(isEmpty)}
                </Form.Group>

                {!hasChildren && <Form.Group className="mb-1" controlId="reference">
                    <Form.Label>
                        <FormattedMessage id={"MENU_EDITOR_REFERENCE"} />
                    </Form.Label>
                    <Form.Control type="input" value={item && item.r || ''} onChange={this.changeInputValue.bind(this, "r")} />
                </Form.Group>}
                <Form.Group controlId="reference" className="d-flex flex-row">
                    <Form.Label className="mr-1">
                        <FormattedMessage id={"MENU_EDITOR_HIDDEN"} />
                    </Form.Label>
                    <Form.Check checked={item && item.h || false} onChange={this.changeCheck.bind(this, "h")} />
                </Form.Group>
                {this.renderDividerType()}
            </Form>
        )
    }
    renderTitle = (title: string) => {
        return (
            <Row className="d-flex flex-row justify-content-between mb-4">
                <Col md={11} className=" ">
                    <h5>
                        <FormattedMessage id={title} />
                    </h5>
                </Col>
                <Col md={1} className="d-flex flex-column  align-items-end   ">
                    <Button className="px-2 " variant="outline-primary" onClick={this.handleChangePage(MenuPage.MAIN)}>
                        <FontAwesomeIcon icon={faUndo} />
                    </Button>
                </Col>
            </Row>
        )
    }
    renderTitlePage = () => {
        const { item } = this.state;
        const title = item ? item.t : undefined;
        return (
            <>
                {this.renderTitle("MENU_EDITOR_MENU_PAGE_TITLE")}
                <LocalizedValue values={title}
                    removeValue={this.removeValue("t")}
                    saveValue={this.saveValue("t")}
                />
            </>
        )
    }
    renderLogoPage = () => {
        const { item } = this.state;
        const logo = item ? item.l : undefined;
        return (
            <>
                {this.renderTitle("MENU_EDITOR_MENU_PAGE_LOGO")}
                <LocalizedValue values={logo}
                    removeValue={this.removeValue("l")}
                    saveValue={this.saveValue("l")}
                />
            </>
        )
    }
    renderTemplate(): React.ReactElement {
        const { item, page } = this.state
        let template = this.renderMainPage();
        switch (page) {
            case MenuPage.TITLE:
                template = (<div className="npt-menu-e-modal-logo">{this.renderTitlePage()}</div>); break;
            // case MenuPage.LOGO:
            //     template= (<div className="npt-menu-e-modal-logo"> {this.renderLogoPage()}</div>);break;
            case MenuPage.AUTH:
                template = (<div className="npt-menu-e-modal-auth">{this.renderAuthPage()}</div>); break;
            case MenuPage.ICON:
                template = (<div className="npt-menu-e-modal-icon">{this.renderIconPage()}</div>); break;
        }
        return <div className="npt-menu-editor-modal">{template}</div>
    }
    aclRulesToList = () => {
        const { selected } = this.state
        let authRights: string[] = [];

        Object.values(selected).forEach(v => {
            const { rdfId, namespace } = v;
            authRights.push(`${namespace}:${rdfId}`);
        })
        return authRights;
    }
    listToAclRules = (rules: string[]) => {
        const { aclMap } = this.props;
        if (!aclMap || Object.keys(aclMap).length === 0) {
            return {};
        }

        let authRights: AclMap = {};
        for (let r of rules) {
            if (!r.includes(':')) {
                continue;
            }
            const [namespace, rdfId] = r.split(':');
            const acl = aclMap[rdfId];
            acl && (authRights[rdfId] = acl);
        }

        return authRights;
    }
    validate() {
        const { fileName, item, isMenu } = this.state;
        let isValid = true;
        const validateState = { isNameEmpty: false, isFileNameEmpty: false };
        if (!item || !item.n || !item.n.trim()) {
            validateState.isNameEmpty = true;
            isValid = false;
        }
        if (isMenu) {
            if (!fileName || !fileName.trim()) {
                validateState.isFileNameEmpty = true;
                isValid = false;
            }
        }
        this.setState(validateState)
        return isValid;
    }
    closeModal(status: ModalStatus, result: any) {
        const { fileName: fn, item, isMenu } = this.state;
        if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
            this.props.closeModal(status, result);
        }

        if (status == MODAL_STATUS_OK && this.validate()) {
            let fileName = fn;
            if (fileName && !fileName.toLocaleLowerCase().includes('menu')) {
                fileName += '_menu'
            }
            item && (item.a = this.aclRulesToList())
            this.props.closeModal(status, { fileName, item });
            return;
        }

    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}

export default connect((state: ApplicationState) => {
    const activeMenu = state.menueditor && state.menueditor.menuActive;
    const aclMap = activeMenu && state.menueditor && state.menueditor.editorState[activeMenu].aclMap || undefined;
    const childrenMap = activeMenu && state.menueditor?.editorState[activeMenu].childrenById || undefined;
    return {
        aclMap,
        childrenMap
    }
})(MenuItemModal)

interface LocalizedValueProps {
    values?: DataTypeList
    saveValue: (d: DataType) => void
    removeValue: (lang: string) => void
}

const LocalizedValue = (props: LocalizedValueProps) => {
    const { saveValue, removeValue, values } = props;
    const [lang, setLang] = useState('');
    const [value, setValue] = useState('');
    const [isLangEmpty, setLangEmpty] = useState(false)
    const [isValueEmpty, setValueEmpty] = useState(false)

    const style: any = {
        top: '-1.2rem',
        position: 'absolute',
        left: '1rem',
        zIndex: 1,
        backgroundColor: 'transparent'
    }
    const handleRemoveValue = (lang: string) => () => {
        removeValue(lang)
    }
    const handleAddValue = () => {

        if (!value.trim()) {
            setValueEmpty(true);
        } else {
            setValueEmpty(false);
        }
        if (!lang.trim()) {
            setLangEmpty(true);
        } else {
            setLangEmpty(false);
        }
        if (value && lang) {
            saveValue({ lang: lang.trim(), value: value.trim() });
        }
    }

    const selectValueToEdit = (lang: string, value: string) => () => {
        setLang(lang)
        setValue(value)
    }
    const renderEmpty = (isEmpty: boolean) => {
        if (!isEmpty) {
            return null;
        }
        return (
            <Form.Text className="text-danger">
                <FormattedMessage id="MENU_EDITOR_FIELD_EMPTY" />
            </Form.Text>
        )
    }
    const renderButton = () => {
        return (
            <Button className="px-2 mb-auto" variant="outline-success" onClick={handleAddValue}>
                <FontAwesomeIcon icon={faSave} />
            </Button>
        )
    }
    const renderSaveWidget = () => {
        return (
            <div className="pt-4 pb-2 px-2  rounded border shadow-sm bg-white">
                <Row>
                    <Col md={2} className=" ">
                        <Form.Group className="mb-0 position-relative" controlId="locale">
                            <Form.Label style={style}>
                                <span className="bg-white"><FormattedMessage id={"MENU_EDITOR_LOCALE"} /></span>
                            </Form.Label>
                            <Form.Control type="input" value={lang} onChange={(e: any) => setLang(e.target.value)} />
                            {renderEmpty(isLangEmpty)}
                        </Form.Group>

                    </Col>
                    <Col md={9} className="  ">
                        <Form.Group className="mb-0  position-relative" controlId="value">
                            <Form.Label style={style}>
                                <FormattedMessage id={"MENU_EDITOR_VALUE"} />
                            </Form.Label>
                            <Form.Control type="input" value={value} onChange={(e: any) => setValue(e.target.value)} />
                            {renderEmpty(isValueEmpty)}
                        </Form.Group>
                    </Col >
                    <Col md={1} className="  d-flex flex-row justify-content-end">
                        {renderButton()}
                    </Col>
                </Row>
            </div>
        )
    }
    const renderValue = (lang: string, value: string) => {
        const langValue = lang.trim() || <FormattedMessage id="MENU_EDITOR_DEFAULT_LOCALE" />
        return (
            <div className="d-flex flex-row justify-content-between  py-2">

                <div className="ml-2  rounded pt-0 pb-1 px-2 bg-success text-white font-weight-bold">{langValue}</div>
                <div className="ml-1 font-weight-bold">{value}</div>


                <div className="mx-2">
                    <span style={{ cursor: 'pointer' }} className="text-primary mr-1   pr-1   " onClick={selectValueToEdit(lang, value)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </span>
                    <span style={{ cursor: 'pointer' }} className="text-danger     " onClick={handleRemoveValue(lang)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </div>

            </div>
        )
    }
    const renderDisplayWidget = () => {
        if (!values) {
            return null;
        }
        const size = Object.values(values).length
        if (size < 1) {
            return null;
        }
        return (
            <div className="mt-2 rounded border bg-white   shadow-sm">
                {
                    values && Object.entries(values).map((e: [string, string], idx: number, array: [string, string][]) => {
                        const [lang, val] = e;
                        const total = array.length;
                        return <div key={idx} className="">
                            {renderValue(lang, val)}
                            {(total !== 1 && idx !== total - 1) && <hr className="my-0 bg-secondary" />}
                        </div>
                    })
                }
            </div>
        )
    }



    return (
        <div className='npt-loc-value-selector'>
            {renderSaveWidget()}
            {renderDisplayWidget()}
        </div>
    )
}




interface AuthListProps {
    list: AclMap
    selected: AclMap
    add: (auth: Acl) => void
    remove: (auth: string) => void
}

const AuthList = (props: AuthListProps) => {
    const { list: l, selected, add, remove } = props;
    const [auth, setAuth] = useState('')

    const list = { ...l };
    const selectedKeys = Object.keys(selected);
    if (selectedKeys.length > 0) {
        selectedKeys.forEach(k => delete list[k])
    }

    const handleSelect = (option: ValueType<OptionTypeAcl,any> ) => {
        const value = (option as OptionTypeAcl).value;
        add(value);
    }

    const renderAuthItem = (item: Acl, divided: boolean = false) => {
        const { label, rdfId } = item;
        return (<React.Fragment key={rdfId}>
            <div className="py-2 px-2 d-flex justify-content-between">
                <span className="font-weight-bolder">{label}</span>
                <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => remove(rdfId)}>
                    <FontAwesomeIcon icon={faTrash} />
                </span>
            </div>
            {divided && <hr className="my-0 bg-secondary" />}
        </React.Fragment>
        )
    }
    const renderAuthList = () => {
        const size = Object.keys(selected).length;
        if (size < 1) {
            return null;
        }
        const divided = (idx: number) => size > 1 && idx < size - 1;
        return <div className="rounded border shadow-sm  bg-white  mt-3">
            {Object.values(selected).map((l: Acl, idx: number) => renderAuthItem(l, divided(idx)))}
        </div>
    }
    const renderAuthControl = () => {
        const options = Object.values(list).map(l => ({ label: l.label, value: l }));

        return (<div className="d-flex flex-row rounded shadow-sm px-1 py-2 border bg-white">
            <Select className="w-100 npt-select"
                placeholder={<FormattedMessage id="MENU_EDITOR_ACL_PLACEHOLDER" />}
                options={options}
                onChange={handleSelect}
            />
        </div>
        )
    }
    return (
        <div className="npt-auth-selector">
            {renderAuthControl()}
            {renderAuthList()}
        </div>
    )
}

interface IconEditorProps {
    iconOptions: IconOptionType[]
    currentIconName?: string
    selectMode: boolean
    changePage: (page: MenuPage) => void
    // selectIcon: (option: any, actionMeta: ActionMeta) => void
    setIcon: (value: string) => void
}

const IconEditor = (props: IconEditorProps) => {
    const { currentIconName: cin, iconOptions, setIcon, changePage, selectMode: disableSelect } = props;
    const initialIconName = cin && isFullIconName(cin) ? cin : '';
    const initialIconNameSelect = cin && !isFullIconName(cin) ? cin : '';
    const [iconName, setIconName] = useState(initialIconName);
    const [validName, setValidName] = useState<boolean>(true);

    const [iconNameSelect, setIconNameSelect] = useState(initialIconNameSelect);
    // const [disableSelect, setDisabled] = useState(false); 
    const style: any = {
        top: '-1.2rem',
        position: 'absolute',
        left: '1rem',
        backgroundColor: 'transparent'
    }



    const sendIconName = (iconName: string | null) => {
        if (iconName === null) {
            setValidName(false);
            return;
        }

        const iconN = iconName.trim();

        setIcon(iconN);
        changePage(MenuPage.MAIN)
        setValidName(true)
    }

    const handleOptionPickUp = (option: ValueType<IconOptionType,any>, actionMeta: ActionMeta<IconOptionType>) => {

        const picked = (option as IconOptionType).value;
        if (actionMeta && actionMeta.action === 'clear') {
            setIconNameSelect('')
        } else {
            setIconNameSelect(picked);
        }
    }
    const renderInfoIcon = () => {
        const info = (
            <span className="ml-1">
                <FontAwesomeIcon className={`text-${validName ? 'primary' : 'danger'}`} icon={faInfoCircle} />
            </span>
        )
        return wrapWithTooltip("MENU_EDITOR_ICON_INFO", info)
    }
    const renderIconRepresentation = () => {
        if (!iconName) {
            return null;
        }
        const style = {
            minWidth: '2rem',
            zIndex: 1,
            right: '0.2rem',
            top: '0.2rem',
            minHeight: '2rem'
        };

        const fullName = extractFaFullName(iconName);
        return fullName && (<div className=" position-absolute px-2 pt-1" style={style}>
            <i className={fullName}></i>
        </div>)
    }
    const renderSelectIcon = () => {
        const currentIcon = { label: renderIcon(iconNameSelect), value: iconNameSelect };
        const zIndex = !disableSelect && { zIndex: 100 } || {}
        return (
            <div style={zIndex} className={`pt-4 pb-2 px-2 ${disableSelect && 'fade-enter'}  rounded border shadow-sm position-relative bg-white npt-icon-selector`}>
                <Row>
                    <Col md={11} className=" ">
                        <Form.Group className="mb-0  position-relative" controlId="value">
                            <Form.Label style={{ ...style, zIndex: 3 }}>
                                <span className="bg-white"><FormattedMessage id={"MENU_EDITOR_ICON_SELECT"} /></span>
                            </Form.Label>
                            <div style={{ zIndex: 2 }} className="position-relative">
                                <Select
                                    className="npt-select"
                                    isClearable={true}
                                    value={currentIcon}
                                    placeholder={<FormattedMessage id="MENU_EDITOR_DIVIDER_PLACEHOLDER" />}
                                    options={iconOptions as any}
                                    onChange={handleOptionPickUp}
                                />
                            </div>
                            {/* {renderEmpty(isValueEmpty)} */}
                        </Form.Group>
                    </Col >
                    <Col md={1} className="   d-flex flex-row justify-content-end"  >
                        <Button variant="outline-success" className="  px-2" onClick={() => sendIconName(`${iconNameSelect}`)}  >
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </Col>
                </Row>
            </div>
        )

    }
    const renderInputIconName = () => {
        const zIndex = disableSelect && { zIndex: 100 } || {}
        const setIcon = (name: string) => {
            if (!name.trim()) {
                sendIconName(name)
                return;
            }
            const fullName = extractFaFullName(name);
            sendIconName(fullName)
        }
        return (
            <div style={{ ...zIndex, top: '-4.5rem' }} className={`pt-4 pb-2 px-2   rounded border shadow-sm  ${!disableSelect && 'fade-enter'} position-relative bg-white npt-icon-selector`}>
                <Row>
                    <Col md={11} className=" ">
                        <Form.Group className="mb-0  position-relative" controlId="value">
                            <Form.Label style={{ ...style, zIndex: 1 }}>
                                <span className="bg-white"><FormattedMessage id={"MENU_EDITOR_WRITE_ICON_NAME"} />  {renderInfoIcon()} </span>
                            </Form.Label>

                            <Form.Control type="input" value={iconName} onChange={(e: any) => setIconName(e.target.value)} />
                            {renderIconRepresentation()}
                            {/* {renderEmpty(isValueEmpty)} */}
                        </Form.Group>
                    </Col >

                    <Col md={1} className="   d-flex flex-row justify-content-end"   >
                        <Button variant="outline-success" disabled={!validName} className="px-2" onClick={() => setIcon(iconName)}  >
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    return (<div style={{ height: '5rem' }}>

        <CSSTransition in={!disableSelect} unmountOnExit={false} classNames="fade" timeout={500}>
            {renderSelectIcon()}
        </CSSTransition>
        <CSSTransition in={disableSelect} unmountOnExit={false} classNames="fade" timeout={500}>
            {renderInputIconName()}
        </CSSTransition>

    </div>
    )
}

