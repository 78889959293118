import React from 'react';
import { ModuleImportFunction } from '../../types';

function getErrorMessage(e: any) {
    try {
        if (typeof e === "object") {
            return e.message;
        }
        return e.toString();
    } catch (e) {
        console.error(e);
    }
}

function reload() {
    document.location.reload();
}

interface PluginOptions {
    [P: string]: any
}

export interface PluginProps {
    plugin: string
    options?: PluginOptions
}

export interface PluginState {
    error: any
    component: React.ComponentClass<PluginOptions> | React.FunctionComponent<PluginOptions> | null;
}

export default class Plugin extends React.Component<PluginProps, PluginState> {

    constructor(props: PluginProps) {
        super(props);
        this.state = {
            error: null,
            component: null
        };
    }

    async componentDidMount() {
        const npt = (window as any).__NPT__;
        if (typeof npt == 'undefined') {
            console.error("__NPT__ is not defined in window");
            this.setState({ error: "__NPT__ is not defined in window" });
            return;
        }
        const imp = npt.import as ModuleImportFunction;
        if (!imp) {
            console.error("__NPT__.import function is not defined");
            this.setState({ error: "__NPT__.import function is not defined" });
            return;
        }
        const plugins = (window as any).__ui_plugins__ as { [P: string]: string };
        if (!plugins) {
            console.error("__ui_plugins__ are not defined");
            this.setState({ error: "__ui_plugins__ are not defined" });
            return;
        }
        let pluginName = this.props.plugin;
        let pluginComponent = 'default';
        const idx = pluginName.indexOf('/');
        if (idx > 0) {
            pluginComponent = pluginName.substr(idx + 1);
            pluginName = pluginName.substr(0, idx);
        }
        const path = plugins[pluginName];
        if (!path) {
            this.setState({ error: "Plugin not found: " + pluginName });
            return;
        }
        try {
            const module = await imp(path);
            console.log("Plugin module", module)
            const component = (module as any)[pluginComponent] || (module as any)?.views?.[pluginComponent];
            if (typeof component != 'undefined') {
                this.setState({ component });
            } else {
                console.error("Component not found in plugin: " + pluginComponent, module);
                this.setState({ error: "Component not found in plugin: " + pluginComponent })
            }
        } catch (e) {
            console.error("Plugin module was not loaded " + pluginName, e)
            this.setState({ error: e })
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("Catch plugin module error", error, errorInfo)
        this.setState({
            error
        });
    }

    render() {
        const e = this.state.error;
        if (e) {
            return <div className="d-flex flex-column w-100 h-100 justify-content-center bg-light">
                <div className="alert alert-danger align-self-center" role="alert">
                    <p>{getErrorMessage(e)}</p>
                    <button onClick={() => reload()} type="button" className="btn btn-danger">
                        <i className="fa fa-refresh a-5x" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        }
        const {options, ...rest} = this.props;
        const pluginProps = typeof options == 'object' && options != null? options: rest;
        const Component = this.state.component;
        if (Component != null) {
            return <Component {...pluginProps}/>
        }
        return <div></div>
    }
}