import * as React from "react";
import { ReactReduxContext } from "react-redux";
import { AnyAction, Store } from "redux";
import {
  Column,
  instanceOfLocalPageableTableApi,
  isExternalTable,
  isLocalTable,
  LocalPageableTableApi,
  LocalSimpleTableApi,
  RowData,
  RowDataMap,
  TableDragOptions,
  TableDropOptions,
  TableParameter,
  TableRequest,
  ToolbarItem,
  ToolbarReport,
} from "../../types/table";
import ExternalTable from "./ExternalTable";
import LocalTable from "./LocalTable";

interface AsyncTableProps {
  table?: string;
  gantOptions?: any;
  finderOptions?: any;
  forceLoadData?: boolean;
  forcedFields?: any;
  initialFields?: any;
  initialSorting?: any;
  paramsReadOnly?: boolean;
  clientSideFilter?: boolean;
  resetFields?: boolean;
  scrollable?: boolean;
  className?: string;
  style?: React.CSSProperties;
  drag?: TableDragOptions;
  drop?: TableDropOptions;
  toolbarHidden?: boolean;
  toolbarHiddenItems?: { [k: string]: boolean };
  localApi?: LocalSimpleTableApi | LocalPageableTableApi;
  columns?: NptTableColumnProps[];
  rows?: RowData[] | RowDataMap[];
  toolbar?: ToolbarItem[];
  automation?: (() => void) | string;
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
  children?: any;
}

export default class AsyncTable extends React.Component<AsyncTableProps> {
  constructor(props: AsyncTableProps) {
    super(props);
  }
  componentDidMount() {
    console.log("table mount");
  }
  renderChildren() {
    const { columns, toolbar } = this.props;

    const elements = [];
    if (columns) {
      elements.push(
        <NptTableColumns>
          {columns.map((columnProps, idx) => (
            <NptTableColumn {...columnProps} key={idx as any} />
          ))}
        </NptTableColumns>
      );
    }
    if (toolbar) {
      elements.push(
        <NptTableToolbarItems>
          {toolbar.map((toolbarItem, idx) => (
            <NptTableToolbarItem {...toolbarItem} key={idx as any} />
          ))}
        </NptTableToolbarItems>
      );
    }
    return elements.length > 0 ? elements : null;
  }
  wrapWithContainer = (element: any) => {
    const { className, style } = this.props;
    if (className || style) {
      return (
        <div className={className} style={style}>
          {element}
        </div>
      );
    }
    return element;
  };
  render() {
    let tableId = this.props.table || "";
    if (tableId && tableId[0] !== "/") {
      tableId = `/${tableId}`;
    }
    if (
      isExternalTable(this.props.columns, this.props.toolbar, this.props.rows)
    ) {
      const element = (
        <ExternalTable
          tableId={tableId as string}
          scrollable={this.props.scrollable}
          gantViewOptions={this.props.gantOptions}
          forceLoadData={this.props.forceLoadData}
          finderViewOptions={this.props.finderOptions}
          initialFields={this.props.initialFields}
          forcedFields={this.props.forcedFields}
          initialSorting={this.props.initialSorting}
          paramsReadOnly={this.props.paramsReadOnly}
          clientSideFilter={this.props.clientSideFilter}
          resetFields={this.props.resetFields}
          drag={this.props.drag}
          drop={this.props.drop}
          toolbarHidden={this.props.toolbarHidden}
          toolbarHiddenItems={this.props.toolbarHiddenItems}
          onFetch={this.props.onFetch}
          onSelectionChange={this.props.onSelectionChange}
        />
      );
      return this.wrapWithContainer(element);
    }
    if (this.props.localApi) {
      const element = (
        <LocalTable
          tableId={tableId}
          {...this.props.localApi}
          scrollable={this.props.localApi.scrollable}
          data={this.props.rows || []}
          automation={this.props.automation}
          toolbarHidden={this.props.toolbarHidden}
        >
          {this.renderChildren()}
        </LocalTable>
      );
      return this.wrapWithContainer(element);
    }
    return null;
  }
}

/**********************************
 * Local Table Options Components *
 **********************************/
/**
 * ALERT: this components using only to declare local table
 *        options.
 * DO NOT use them standalone.
 */

/** Columns component */
export interface NptTableColumnsProps {
  children:
    | React.ReactElement<NptTableColumnProps, typeof NptTableColumn>
    | React.ReactElement<NptTableColumnProps, typeof NptTableColumn>[];
}
export class NptTableColumns extends React.PureComponent<NptTableColumnsProps> {
  render() {
    return this.props.children;
  }
}
export type NptTableColumnProps = Column & { isKey?: boolean };
export const NptTableColumn = React.memo((props: NptTableColumnProps) => {
  return null;
});

/** Parameters component */
export interface NptTableParametersProps {
  children:
    | React.ReactElement<NptTableParameterProps, typeof NptTableParameter>
    | React.ReactElement<NptTableParameterProps, typeof NptTableParameter>[];
}
export class NptTableParameters extends React.PureComponent<NptTableParametersProps> {
  render() {
    return this.props.children;
  }
}
interface NptTableParameterProps extends TableParameter {
  value?: string;
}
export const NptTableParameter = React.memo((props: NptTableParameterProps) => {
  return null;
});

/** Reports component */
export interface NptTableReportsProps {
  children:
    | React.ReactElement<ToolbarReport, typeof NptTableReport>
    | React.ReactElement<ToolbarReport, typeof NptTableReport>[];
}
export class NptTableReports extends React.PureComponent<NptTableReportsProps> {
  render() {
    return this.props.children;
  }
}
export const NptTableReport = React.memo((props: ToolbarReport) => {
  return null;
});

/** Toolbar items component */
export interface NptTableToolbarItemsProps {
  children:
    | React.ReactElement<ToolbarItem, typeof NptTableToolbarItem>
    | React.ReactElement<ToolbarItem, typeof NptTableToolbarItem>[];
}
export class NptTableToolbarItems extends React.PureComponent<NptTableToolbarItemsProps> {
  render() {
    return this.props.children;
  }
}
export const NptTableToolbarItem = React.memo((props: ToolbarItem) => {
  return null;
});
