import { NamespaceData } from "./namespace";

import {
    SEND_ALL_CLASSES,
    SEND_DICTIONARY,
    SEND_DATATYPE_LIST,
    SEND_PACKAGE_CLASSES,
    SEND_CLASS_INFO,
    SEND_PROFILE_TREE_LOADING,
    SEND_PROFILE_TREE_ERROR,
    SEND_ROOT_PACKAGES,
    SEND_EXPAND_TREE_NODE,
    SEND_ACTIVE_TREE_NODE,
    SEND_INITIALIZE_CLASS_CARD,
    SEND_CLASS_CARD_TAB,
    SEND_CLASS_CHILDREN_INFO,
    SEND_CLASS_TREE_NODE_COLLAPSED,
    SEND_ACTIVE_RELATION_INFO,
    SEND_ACTIVE_PREDICATE_ID,
    SEND_PREDICATE_ID_ON_DELETE,
    SEND_ALL_PREDICATES_ID_ON_DELETE,
    SEND_PREDICATE_CREATE,
    SEND_SAVE_CLASS_INFO,
    SEND_EDIT_CLASS_FINISH,
    SEND_EDIT_CLASS,
    SEND_NEW_CLASS_PACKAGE_ID,
    SEND_UPDATE_TREE_NODE,
    SEND_CONTEXT_MENU_NODE_ID,
    SEND_PACKAGE_DATA,
    SEND_CLEAR_CLASS_DATA,
    SEND_EDIT_PACKAGE_NODE,
    SEND_MOVE_NODE,
    SEND_CLASS_INFO_LOADING,
    SEND_SEARCH_INPUT_PROFILE,
    SEND_FILTERED_TREE_ITEMS,
    SEND_FILTERED_DATA,
    SEND_SEARCH_LOADING
} from "../constants/profile";
import { Action } from "redux";
import { FetchError } from "./error";
import { NavItem } from "react-bootstrap";

export interface PackageData {
    id: number
    namespaceId: number
    name: string
    label: string
    description: string   
}

export interface FilteredItem {
    type: number
    label: string
    packageId: number
    classId: number
    id: number
    stereotype?:number
    prefix:string
    name:string
}


export interface FilteredData {
    classes: FilteredItem[]
    packages: FilteredItem[]
    predicates: FilteredItem[]
}
// export interface CimClass {
//     id: number
//     namespaceId: number
//     packageId: number
//     name: string
//     label: string
//     stereotype: number
//     storeType: number
//     description: string
// }
export interface DataType {
    primitive: boolean
    prefix: string
    label: string
    stereotype: number
    id: number
}
export interface PredicateData {
    id?: number,
    // namespace?: NamespaceData
    namespaceId?: number
    name?: string
    label?: string
    description?: string
    dataTypeId?: number
    multiplicity?: number
    classId?: number
    systemDriven?: boolean
}
export interface PredicateEditorInfo {
    type: string
    predicate: PredicateData
    relation?: PredicateRelation
}
export interface PredicateRelation {
    classId: number
    inverseRole?: PredicateData
    type: number
}

export interface EditorDictionary {
    multiplicityMap: { [value: number]: Multiplicity }
    namespaceMap: { [idx: number]: NamespaceData }
    stereoTypeMap: { [value: number]: Stereotype }
    storeTypeMap: { [value: number]: StoreType }
    relationMap: { [value: number]: RelationType }
}

interface CimInheritance {
    id: number
    parentClassId: number
    childClassId: number
}
interface InheritanceInfo extends CimInheritance {
    peerClass: ClassInfo
}

interface CimPredicate {
    id?: number
    namespaceId: number
    classId: number
    rangeId?: number
    inverseRole?: number
    multiplicity: number
    dataTypeId?: number
    name: string
    label: string
    description: string
    isAggregate?: boolean
    sortKey?: number
    readRole?: number
    writeRole?: number
    systemDriven?: boolean
}
interface CimClassRelation {
    id: number
    class1Id: number
    predicateId: number
    class2Id: number
    predicate2Id: number
    relationType: number
}

export interface RelationInfo extends CimClassRelation {
    peerClass: ClassInfo
    inversePredicateInfo: Predicate
    relationTypeInfo: string
    subjectFirst: boolean
}
interface PredicateInfo extends CimPredicate {
    namespace: NamespaceData
    multiplicityInfo: string
    dataType: ClassInfo
    classRelationInfo: RelationInfo
    layout: string
}

// export interface ClassInfo  {
//     id:number
//     namespaceId:number
//     packageId:number
//     name:string
//     label:string
//     stereotype:number
//     namespace: NamespaceData
//     parentList: InheritanceInfo[]
//     storeTypeInfo: string
//     stereotypeInfo: string
//     predicateList: PredicateInfo[]
//     ////
//     enumerationInfo: []
//     automation: string
//     ///
//     triggerList:any[]
// }
// ////////////////////////////////////////////////
// export interface ClassCard{
//     data:ClassInfo
// }


export interface Package {
    id: string | number
    namespaceId: number
    name: string
    label: string
    description: string
    parentId: string | null
    packageId: number
}

export interface CimClass {
    id?: number
    namespaceId: number
    name: string
    label: string
    description?: string
    parentId: string
    packageId: number
    layout?: string | null
    enumerationDepth?: number | null
    stereotype: number | null
    storeType: number | null
}


// export interface ChildNodeType extends NodeType{
//     packageId:number
// }
export interface TreeType {
    rootNodesIds: string[]
    nodeById: { [id: string]: Package | CimClass }
    children: { [packageId: string]: string[] }
    // filteredTreeItems?: string[]
}
export interface ActiveRelationInfo {
    classId: number,
    relationId: number,
    inverse?: boolean
}

//if packageId exists and classId not exists - create a new class
//If only classId exists - edit class
//other cases are invalid
export interface SaveClassInfo {
    packageId?: number
    classId?: number
}
export interface ProfileEditorState {
    tree?: TreeType
    card?: ClassCard
    loadingClassInfo: boolean
    loadingTree:boolean
    loadingSearch?:boolean
    active: string | null
    expanded?: { [id: string]: boolean }
    inplace?: string
    error?: boolean
    infoLoaded?: boolean
    tab: string | null
    activePredicateId?: number
    activeRelation?: ActiveRelationInfo
    predicatesOnDelete?: { [id: number]: boolean }
    newClassPackageId?: number
    editedClass?: ClassInfo
    contextMenuNodeId?: string | number
    search?: string
    filteredData?:FilteredData
}



export interface SendRootPackages extends Action<SEND_ROOT_PACKAGES> {
    payload: Package[]
}

export interface SendPackageClasses extends Action<SEND_PACKAGE_CLASSES> {
    payload: {
        packageId: number,
        classes: CimClass[]
    }
}

export interface SendAllClasses extends Action<SEND_ALL_CLASSES> {
    payload: ClassInfo[]
}

export interface SendDictionary extends Action<SEND_DICTIONARY> {
    payload: EditorDictionary
}

export interface SendDatatypeList extends Action<SEND_DATATYPE_LIST> {
    payload: CimClass[]
}
export interface SendClassInfo extends Action<SEND_CLASS_INFO> {
    payload: {
        classInfo: ClassInfo,
        classId: number
    }
}

export interface SendProfileTreeLoading extends Action<SEND_PROFILE_TREE_LOADING> {
    payload: boolean
}
export interface SendProfileTreeError extends Action<SEND_PROFILE_TREE_ERROR> {
    payload: {
        error: FetchError;
    }
}

export interface SendExpandTreeNode extends Action<SEND_EXPAND_TREE_NODE> {
    payload: {
        expand: boolean
        id: string
    }
}

export interface SendActiveTreeNode extends Action<SEND_ACTIVE_TREE_NODE> {
    payload: string
}

export interface SendInitializeClassCard extends Action<SEND_INITIALIZE_CLASS_CARD> {
    payload: ClassCard
}

export interface SendClassCardTab extends Action<SEND_CLASS_CARD_TAB> {
    payload: string
}

export interface SendClassChildrenInfo extends Action<SEND_CLASS_CHILDREN_INFO> {
    payload: ChildInfo[]
}

export interface SendClassTreeNodeCollapsed extends Action<SEND_CLASS_TREE_NODE_COLLAPSED> {
    payload: {
        treeId: string,
        nodeId: number,
        collapsed: boolean
    }
}

export interface SendActivePredicateId extends Action<SEND_ACTIVE_PREDICATE_ID> {
    payload: number
}
export interface SendActiveRelationInfo extends Action<SEND_ACTIVE_RELATION_INFO> {
    payload: {
        classId: number,
        relationId: number,
        inverse?: boolean
    }
}

export interface SendPredicateIdOnDelete extends Action<SEND_PREDICATE_ID_ON_DELETE> {
    payload: {
        id: number,
        checked: boolean
    }
}

export interface SendAllPredicatesIdOnDelete extends Action<SEND_ALL_PREDICATES_ID_ON_DELETE> {
    payload: boolean
}

export interface SendPredicateCreate extends Action<SEND_PREDICATE_CREATE> {

}
export interface SendEditClassFinish extends Action<SEND_EDIT_CLASS_FINISH> {

}

export interface SendEditClass extends Action<SEND_EDIT_CLASS> {
    payload: ClassInfo
}

export interface SendNewClassPackageId extends Action<SEND_NEW_CLASS_PACKAGE_ID> {
    payload: number
}

export interface SendUpdateTreeNode extends Action<SEND_UPDATE_TREE_NODE> {
    payload: {
        id: number
        label: string
    }
}
export interface SendContextMenuNodeId extends Action<SEND_CONTEXT_MENU_NODE_ID> {
    payload: number | string
}

export interface SendPackageData extends Action<SEND_PACKAGE_DATA> {
    payload: PackageContainer
}

export interface SendClearClassData extends Action<SEND_CLEAR_CLASS_DATA> {
    payload: null
}

export interface SendEditPackageNode extends Action<SEND_EDIT_PACKAGE_NODE> {
    payload: {
        id: number,
        label: string
    }
}

export interface SendMoveClass extends Action<SEND_MOVE_NODE> {
    payload: {
        oldPackage: number,
        newPackage: number,
        classId: number
    }
}

export interface SendClassInfoLoading extends Action<SEND_CLASS_INFO_LOADING> {
    payload: null
}

export interface SendSearchInput extends Action<SEND_SEARCH_INPUT_PROFILE> {
    payload: string
}

export interface SendSearchLoading extends Action<SEND_SEARCH_LOADING>{
    payload:null;
}

export interface SendFilteredTreeItems extends Action<SEND_FILTERED_TREE_ITEMS> {
    payload: string[]
}

export interface SendFilteredData extends Action<SEND_FILTERED_DATA> {
    payload: FilteredData|undefined
}

function isClass(data: CimClass | Package): data is CimClass {
    return (data as CimClass).enumerationDepth !== undefined
        && (data as CimClass).layout !== undefined
        && (data as CimClass).storeType !== undefined
        && (data as CimClass).stereotype !== undefined;
}

export type ProfileEditorAction = SendDictionary |
    SendAllClasses |
    SendDatatypeList |
    SendClassInfo |
    SendRootPackages |
    SendProfileTreeLoading |
    SendProfileTreeError |
    SendPackageClasses |
    SendExpandTreeNode |
    SendActiveTreeNode |
    SendInitializeClassCard |
    SendClassCardTab |
    SendClassChildrenInfo |
    SendClassTreeNodeCollapsed |
    SendActivePredicateId |
    SendActiveRelationInfo |
    SendPredicateIdOnDelete |
    SendAllPredicatesIdOnDelete |
    SendEditClassFinish |
    SendEditClass |
    SendNewClassPackageId |
    SendUpdateTreeNode |
    SendContextMenuNodeId |
    SendPackageData |
    SendClearClassData |
    SendEditPackageNode |
    SendMoveClass |
    SendClassInfoLoading |
    SendSearchInput |
    SendFilteredTreeItems |
    SendFilteredData|
    SendSearchLoading;


export interface PrimitiveType {
    id: number
    namespaceId: number
    namespace: NamespaceData
    packageId: number
    name: string
    label: string
    stereotype: number
    storeType: number
}

export interface PrimitiveTypeContainer {
    list: PrimitiveType[]
    loadingState: string
    typeById: { [id: number]: PrimitiveType }
}


export interface ClassInfo {
    id: number
    name: string
    label: string
    description: string
    packageId: number
    namespaceId: number

    layout: string
    automation: string
    namespace: NamespaceData
    predicateList: Predicate[]
    parentList: ParentInfo[]
    childrenList: ChildInfo[]
    triggerList: string[]
    enumerationInfo: EnumerationInfo
    enumerationDepth: number
    stereotype: number
    storeType: number
}

export interface ClassInfoContainer {
    loadingState: string
    list: ClassInfo[]
}

export interface Notification {
    type: string | null
    text: string | null
}

export interface Multiplicity {
    value: number
    template: string
}
export interface MultiplicityContainer {
    list: Multiplicity[]
    multiplicityByValue: { [value: number]: Multiplicity }
}

export interface RelationType {
    value: number
    // relation:string
    template: string
}

export interface RelationTypeContainer {
    list: RelationType[]
    relationTypeByValue: { [value: number]: RelationType }
}

export interface Stereotype {
    value: number
    template: string
}

export interface StereotypeContainer {
    list: Stereotype[]
    stereotypeByValue: { [value: number]: Stereotype }
}

export interface StoreType {
    value: number
    template: string
}
export interface StoreTypeContainer {
    list: StoreType[]
    storeByValue: { [value: number]: StoreType }
}

export interface PackageContainer {
    list: Package[]
    packageById: { [id: number]: Package }
}

export interface NamespaceContainer {
    list: NamespaceData[]
    namespaceById: { [id: number]: NamespaceData }
}

export interface ParentInfo {
    id: number
    childClassId: number
    parentClassId: number
    peerClass: ClassInfo
}
export interface ChildInfo {
    id: number
    childClassId: number
    parentClassId: number
    peerClass: ClassInfo
}



export interface Predicate {
    id: number
    name: string
    label: string
    description: string
    classId: number
    dataTypeId: number
    sortKey: number
    namespace: NamespaceData
    multiplicity: number
    multiplicityInfo: string
    dataType: PrimitiveType
    classRelationInfo: RelationInfo
    layout: string
    systemDriven: boolean
}

export interface EnumerationData {
    $class: string
    $classId: number
    $description: string
    $id: number
    $label: string
    $namespace: string
    $rdfId: string
}
export interface EnumerationInfo {
    data: EnumerationData
    children: EnumerationInfo[]
    rootLevel: boolean
    lastLevel: boolean
    broken: boolean
}

export interface ClassCard {
    loadingState: string
    data?: ClassInfo
    primitiveTypes: PrimitiveTypeContainer
    allClasses: ClassInfoContainer
    notification: Notification
    packages: PackageContainer
    multiplicity: MultiplicityContainer
    relationType: RelationTypeContainer
    stereotype: StereotypeContainer
    storeType: StoreTypeContainer
    namespace: NamespaceContainer
    tab: any | null
    predicateList: Predicate[]
    relationList: RelationType[]
    parentList: ParentInfo[]
    selectedRows: []
    classTrees: { [id: string]: { nodeId: number, collapsed: boolean } }
}

export const isDirectory = (id: string) => id.includes('p.')
export const convertDirectoryId = (id: string) => +id.replace('p.', '');