import * as React from 'react';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import ModalView from '../ModalView';

interface StringInputModalProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void
}

export default class StringInputModal extends React.Component<StringInputModalProps> {

    input: string;

    constructor(props: StringInputModalProps) {
        super(props);

        this.input = props.modal.options?.data || "";

        this.closeModal = this.closeModal.bind(this);
    }

    changeInput(event: any) {
        this.input = event.target.value || "";
    }

    renderTemplate(): React.ReactElement {
        return <input className="w-100 form-control" defaultValue={this.input} onChange={this.changeInput} />;
    }

    closeModal(status: ModalStatus, result: any) {
        if (status == MODAL_STATUS_OK) {
            this.props.closeModal(status, this.input);
        } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
            this.props.closeModal(status, null);
        }
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { ...modal.options, title: modal.options.title || { id: "STRING_INPUT_MODAL_TITLE" } };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}