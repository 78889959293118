import React from 'react';

const dividerWidth = 5;
const minWidth = 100;

interface ControlWindowProps {
    width?: number
}

interface ControlWindowState {
    sideWidth: {
        left: number,
        right: number
    }
}

export default class ControlWindow extends React.Component<ControlWindowProps, ControlWindowState> {

    constructor(props: ControlWindowProps) {
        super(props);

        this.state = {
            sideWidth: {
                left: this.getUsableWidth() / 2,
                right: this.getUsableWidth() / 2
            }
        }
    }

    /* Get width that can be reassigned (total width - divider width) */
    getUsableWidth(props = this.props) {
        return props.width && (props.width - dividerWidth) > minWidth ? props.width - dividerWidth : minWidth;
    }

    componentWillReceiveProps(nextProps: ControlWindowProps) {
        if (this.props.width != nextProps.width) {
            const multiplier = this.getUsableWidth(nextProps) / this.getUsableWidth();
            this.state.sideWidth.left *= multiplier;
            this.state.sideWidth.right *= multiplier;
        }
    }

    render() {
        return null;
    }
}