var __npt_listeners = {};

/**
 * bindEvent({
 *     element: window, //May be omitted
 *     event: "resize.npt.uniqId",
 *     handler: doSmth
 * })
 *
 * State after command:
 * {
 *     window: {
 *         functionByEventId: {
 *             "resize.npt.uniqId": doSmthId
 *         }
 *         functionIdListByEventId: {
 *             "resize": ["resize.npt.uniqId"],
 *             "resize.npt": ["resize.npt.uniqId"],
 *             "resize.npt.uniqId": ["resize.npt.uniqId"]
 *         }
 *     }
 * }
 *
 * Function doSmth will be triggered on "resize", "resize.npt" and "resize.npt.uniqId" events
 *
 * If user will try to overwrite binding - previous binding will be automatically removed
 **/

function addBinding(element, compositePath, event) {
  if (!__npt_listeners[element].functionIdListByEventId[compositePath]) {
    __npt_listeners[element].functionIdListByEventId[compositePath] = [];
  }
  const functionIdList =
    __npt_listeners[element].functionIdListByEventId[compositePath];

  functionIdList.push(event);
}

function removeBinding(element, compositePath, event) {
  if (!__npt_listeners[element].functionIdListByEventId[compositePath]) {
    return;
  }
  const functionIdList =
    __npt_listeners[element].functionIdListByEventId[compositePath];

  for (let i = 0; i < functionIdList.length; ++i) {
    if (functionIdList[i] == compositePath) {
      functionIdList.splice(i, 1);
    }
  }
  if (functionIdList.length == 0) {
    delete __npt_listeners[element].functionIdListByEventId[compositePath];
  }
}

export function bindEvent({ element = window, event, handler }) {
  if (!event || !handler) {
    console.error("Can't bind undefined event or undefined handler");
    return;
  }
  if (!element.addEventListener) {
    // DOM Level 2 browsers (IE > 8)
    console.error(
      "Sorry, your browser doesn't support npt platform event system."
    );
    return;
  }
  if (__npt_listeners[element]?.functionByEventId[event]) {
    unbindEvent({ element, event });
  }

  if (!__npt_listeners[element]) {
    __npt_listeners[element] = {
      functionByEventId: {},
      functionIdListByEventId: {},
    };
  }

  const eventPartials = event.split(".");
  let compositePath = eventPartials[0];
  for (let i = 0; i < eventPartials.length; ++i) {
    if (i != 0) {
      compositePath += "." + eventPartials[i];
    }
    element.addEventListener(compositePath, handler, false);
    addBinding(element, compositePath, event);
  }

  __npt_listeners[element].functionByEventId[event] = handler;
  return;
}

export function unbindEvent({ element = window, event }) {
  if (!event) {
    console.error("Can't unbind undefined event");
    return;
  }
  if (
    !__npt_listeners[element] ||
    !__npt_listeners[element].functionByEventId[event]
  ) {
    return;
  }
  const handler = __npt_listeners[element].functionByEventId[event];

  const eventPartials = event.split(".");
  let compositePath = eventPartials[0];
  for (let i = 0; i < eventPartials.length; ++i) {
    if (i != 0) {
      compositePath += "." + eventPartials[i];
    }
    element.removeEventListener(compositePath, handler);
    removeBinding(element, compositePath, event);
  }

  delete __npt_listeners[element].functionByEventId[event];
  if (Object.keys(__npt_listeners[element].functionByEventId).length === 0) {
    delete __npt_listeners[element];
  }

  return;
}

export function triggerEvent({ element = window, event, detail = null }) {
  if (!event) {
    console.error("Can't trigger undefined event");
    return;
  }
  if (
    !__npt_listeners[element] ||
    !__npt_listeners[element].functionIdListByEventId[event]
  ) {
    return;
  }

  const documentEvent = new CustomEvent(event, { detail });
  element.dispatchEvent(documentEvent);
}
