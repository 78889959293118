import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../types';
import { isLoggedInUser, MenuData } from '../../types/security';

import { ConnectedUserMenu, ToggleWithoutArrow, UserLabel } from './UserMenu';

interface MinifiedMenuProps {
    language: string
    menu?: MenuData
    logoInfo?: { icon?: string, style?: React.CSSProperties, src?: string } | null
    children?: any
}
const MinifiedMenu: React.FunctionComponent<MinifiedMenuProps> = React.memo((props) => {
    return <Navbar className='bg-primary py-1' expand="lg">
        <Navbar.Collapse className="w-100 d-flex justify-content-between" id="sidemenu-navbar">
            <div className="d-flex align-items-center">
                <MinifiedMenuLogo logo={props.menu?.logoByLang[props.language]} logoInfo={props.logoInfo} />
                {props.children}
            </div>
            <Nav>
                <div className="mx-1 d-flex justify-content-center align-items-center text-white">
                    <UserLabel />
                </div>
                <ConnectedUserMenu as={ToggleWithoutArrow} />
            </Nav>
        </Navbar.Collapse>
    </Navbar>
});

interface MinifiedMenuLogoProps {
    logo?: string
    logoInfo?: { icon?: string, style?: React.CSSProperties, src?: string } | null
}
export const MinifiedMenuLogo: React.FunctionComponent<MinifiedMenuLogoProps> = React.memo((props) => {
    if (props.logoInfo) {
        let logo = null;
        if(props.logoInfo.icon){
            logo = <i className={`fa ${props.logoInfo.icon}`} style={props.logoInfo.style} />;
        } else if (props.logoInfo.src){
            logo = <img src={props.logoInfo.src} alt="logo" style={props.logoInfo.style} />;
        }
        return <Link key="menu-logo" to="/" className="navbar-brand px-2 py-0 ">
            <div className="App-logo d-flex justify-content-center align-items-center">
                {logo}
            </div>
        </Link>;
    }
    if (!props.logo) {
        return <FontAwesomeIcon key="menu-logo" icon={faExclamationTriangle} />
    }
    return <Link key="menu-logo" to="/" className="navbar-brand px-2">
        <img src={props.logo} className="App-logo" alt="logo" />
    </Link>;
})

export default connect(
    (state: ApplicationState) => {
        if (isLoggedInUser(state.security.loginStatus)) {
            return {
                language: state.locale.language,
                menu: state.security.loginStatus.mainMenu
            }
        }
        return {
            language: state.locale.language
        }
    })(MinifiedMenu);