import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { ButtonVariant } from 'react-bootstrap/esm/types';

import { ModalInfo, ModalStatus, isI18NString, I18NString, CommonModalOptions } from '../../types/modal';
import { MODAL_STATUS_CLOSE, MODAL_STATUS_CANCEL, MODAL_STATUS_OK } from '../../constants/modal';
import { MSG_CONFIRM_ACTION } from '../messages';

import styles from './ModalView.module.css';

interface ModalViewBtn {
  label: string | I18NString
  variant?: ButtonVariant
  className?: string
  style?: React.CSSProperties
  closeStatus?: ModalStatus
  closeValue?: any
  onClick?: () => void
}

export interface ModalViewOptions extends CommonModalOptions {
  hideCloseButton?: boolean
  footerMainButtons?: ModalViewBtn[]
  footerButtons?: ModalViewBtn[]
}

export interface ModalViewInfo extends ModalInfo {
  options: ModalViewOptions
}

export interface ModalViewProps {
  modal: ModalViewInfo,
  template?: React.ReactElement,
  closeModal: (status: ModalStatus, result: any) => void
}

export default class ModalView extends React.Component<ModalViewProps> {

  renderTitle() {
    const t = this.props.modal.options.title;
    if (!t) {
      return <Modal.Title>{MSG_CONFIRM_ACTION}</Modal.Title>;
    }
    if (isI18NString(t)) {
      return <Modal.Title><FormattedMessage id={t.id} values={t.values} /></Modal.Title>
    }
    return <Modal.Title>{t}</Modal.Title>
  }

  renderBody() {
    /* Used by templates */
    if (this.props.template) {
      return this.props.template;
    }
    const b = this.props.modal.options.body;
    if (!b) {
      return null;
    }
    if (isI18NString(b)) {
      return <p><FormattedMessage id={b.id} values={b.values} /></p>
    }
    return <p>{b}</p>
  }

  renderMainFooterButtons() {
    const fmb = this.props.modal.options.footerMainButtons;
    if (!fmb) {
      return <FooterButton
        variant="outline-success"
        className="px-2"
        closeStatus={MODAL_STATUS_OK}
        closeModal={this.props.closeModal}
        label={{ id: "MSG_BUTTON_OK" }}
        focused={true}
      />
    }
    return fmb.map((btnProps, idx) =>
      <FooterButton key={idx} {...btnProps} closeModal={this.props.closeModal} focused={idx == 0} />
    )
  }

  renderCloseFooterButtons() {
    const hidden = this.props.modal.options.hideCloseButton;
    if (hidden) {
      return null;
    }
    return <FooterButton
      variant="outline-primary"
      className="px-2"
      closeStatus={MODAL_STATUS_CANCEL}
      closeModal={this.props.closeModal}
      label={{ id: "MSG_BUTTON_CANCEL" }}
    />
  }

  renderAdditionalFooterButtons() {
    const fb = this.props.modal.options.footerButtons;
    if (!fb) {
      return null;
    }
    return fb.map((btnProps, idx) =>
      <FooterButton key={idx} {...btnProps} closeModal={this.props.closeModal} focused={false} />
    )
  }

  render() {
    const size = this.props.modal.options.size;
    const backdrop = this.props.modal.options.backdrop;
    let modalDialogSize: "sm" | "lg" | "xl" | undefined = undefined;
    let modalDialogCls = styles.modalContainer;
    if (size == "small") {
      modalDialogSize = "sm";
    } else if (size == "large") {
      modalDialogSize = "lg";
    } else if (size == "extra-large") {
      modalDialogSize = "xl";
    } else if (size == "fullscreen") {
      modalDialogCls += ` ${styles.modalFullscreen}`;
    }
    return <Modal
      backdrop={typeof backdrop !== "undefined" ? backdrop : "static"}
      className={modalDialogCls}
      size={modalDialogSize}
      enforceFocus={false}
      centered
      show
      onHide={() => this.props.closeModal(MODAL_STATUS_CLOSE, null)}
    >
      <Modal.Header closeButton>
        {this.renderTitle()}
      </Modal.Header>
      <Modal.Body >
        {this.renderBody()}
      </Modal.Body>
      <Modal.Footer>
        {this.renderMainFooterButtons()}
        {this.renderCloseFooterButtons()}
        {this.renderAdditionalFooterButtons()}
      </Modal.Footer>
    </Modal>;
  }
}

interface FooterButtonProps extends ModalViewBtn {
  focused?: boolean
  closeModal: (status: ModalStatus, result: any) => void
}

class FooterButton extends React.PureComponent<FooterButtonProps> {

  private buttonRef = React.createRef<HTMLButtonElement>();

  constructor(props: FooterButtonProps) {
    super(props)
  }

  componentDidMount() {
    window.setTimeout((() => {
      if (this.props.focused && this.buttonRef.current) {
        this.buttonRef.current.focus();
      }
    }).bind(this), 0)
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
    if (this.props.closeStatus) {
      this.props.closeModal(this.props.closeStatus, this.props.closeValue || null)
    }
  }

  render() {
    return <Button
      ref={this.buttonRef}
      variant={this.props.variant || "outline-primary"}
      className={`px-2 ${this.props.className}`}
      style={this.props.style}
      onClick={this.onClick}>
      {isI18NString(this.props.label) ? <FormattedMessage id={this.props.label.id} values={this.props.label.values} /> : this.props.label}
    </Button>
  }
}