import shortid from "shortid";
import { ADD_NEW_SUBJECT_EVENT, NEW_SUBJECT_ADDED_EVENT } from "../constants/subject";
import { SelectionInfo } from "../types/selection";
import { UploadTask, FileValue, NewSubjectEventOptions, SubjectData, AddedSubjectEventOptions, getSubjectKey } from "../types/subject";

/**Anti pattern! But nobody knows how to do it better.
Global upload map used to link between upload keys and real files
Each value is task
let task = {
    key: key, //pass unique key here
    file: file //pass file to be uploaded
}*/
const globalUploadMap:{[key:string]:any} = {};


//Called by 
export function enqueUpload(file:any) {
    const key = shortid.generate();
    globalUploadMap[key] = file;
    return key;
}
export function getUpload(key:string) {
    return globalUploadMap[key];
}


function recursiveUploadSearch(nodeId:string, data:any, path:string[], taskList:UploadTask[]) {
    if (Array.isArray(data) ) {
        for (let idx in data) {
            path.push(idx); 
            recursiveUploadSearch(nodeId, data[idx], path, taskList);
            path.pop();
        }
    } else  if(data && typeof data === "object") {
        if (typeof data.$uploadKey != 'undefined') {
            let task:UploadTask = {
                nodeId, //node id
                key: data.$uploadKey, //save key to clear upload map
                file: globalUploadMap[data.$uploadKey],
                path: ([] as any[]).concat(path) //copy path in node!
            }
            if (typeof task.file != 'undefined') {
                taskList.push(task);
            } else {
                console.error("Unknown file key", data.$uploadKey);
            } 
        } else {
             
            for (let p in data) {
                path.push(p); 
                type FileValueKey = keyof FileValue;
                recursiveUploadSearch(nodeId, data[p as FileValueKey], path, taskList);
                path.pop();
            }
        }
    }
}
 
/**Map between node id and path of upload*/
export function getUploadTaskList(values:{[NODE_ID:string]:any}) {
    const taskList:UploadTask[] = [];
     
    for (const nodeId in values) {
        const value = values[nodeId]; 
        recursiveUploadSearch(nodeId, value, [], taskList);
    }
    return taskList;
}

export function clearUploadTasks(taskList:UploadTask[]) {
    if (taskList.length == 0) {
        return;
    }
    console.log("Before clearUploadTasks:", globalUploadMap);
    for (let task of taskList) {
        delete globalUploadMap[task.key];
    }
    console.log("After clearUploadTasks:", globalUploadMap);
}

export function buildInnerCardId(formId:string, nodeId:string, namespace:string, rdfId:string) {
    return formId + "_" + nodeId.replace(/\./g, "_") + "_" + (namespace ? namespace + "_" : "") + rdfId;
}

export function toUrlSearchParams(params:any){
    if(Object.keys(params).length>0){
      return Object.keys(params).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
        }).join('&')
    }
    return null;
}


export function addSubjectFire(data:SubjectData,nodeId:string){
    let eventOptions:NewSubjectEventOptions = {
        notifyId: nodeId,
        data: data
    };
    const newSubjectEvent = new CustomEvent(ADD_NEW_SUBJECT_EVENT,{detail:eventOptions})
    
    document.dispatchEvent(newSubjectEvent);
}

//For search update to new rdfId
export function subjectAddedFire(newRdfId:string,oldRdfId:string){
    let eventOptions:AddedSubjectEventOptions = {
        newRdfId,
        oldRdfId
    };
    const newSubjectAddedEvent = new CustomEvent(NEW_SUBJECT_ADDED_EVENT,{detail:eventOptions})
    
    document.dispatchEvent(newSubjectAddedEvent);
}

//Parse legacy links to selection object
export function getSelectionFromLink(link: string):SelectionInfo | null{
    const searchStart = link.indexOf("?");
    if(searchStart === -1){
        return null;
    }
    const info: SelectionInfo = {};
    const search = link.substring(searchStart + 1);
    for(let param of search.split("&")){
        const pair = param.split("=");
        if(pair[0] === "object"){
            info.object = pair[1];
        }
        if(pair[0] === "type"){
            info.type = pair[1];
        }
    }
    return info;
}

export const getRdfId=(data:SubjectData)=>{
    if(data.$rdfId){
      return data.$rdfId
    }
    if(data.$class && data.$class.includes(":")){
      const[ns,rdfId] = data.$class.split(":")
      return rdfId
    }
    console.error("no rdfId was found in",data)
    return null
  }

  export const getSubjectKeyFromData = (data: SubjectData) => {
    const ns = data.$namespace
    if (data.$rdfId) {
        return getSubjectKey(data.$rdfId,ns)
    }
    if (data.$class) {
        return data.$class
    }
    console.error("no rdfId was found in", data)
    return null
}