import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select';
import { ThunkDispatch } from 'redux-thunk';
import { MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE, MODAL_STATUS_OK } from '../../../constants/modal';
import { ApplicationAction, ApplicationState } from '../../../types';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import { ClassCard, NamespaceContainer, Package } from '../../../types/profile';
import MaskedInput from '../../maskedinput';
import ModalView from '../ModalView';

type  OptionType = {label:string,value:number}

export const DEPTHS = [
    { name: "one", label: "Один уровень", value: 1 },
    { name: "two", label: "Два уровня", value: 2 },
    { name: "three", label: "Три уровня", value: 3 }
];

export interface PackageData {
    id?: number
    label: string
    name: string
    namespaceId: number
    description?: string
    parentId: string | null
}

interface SavePackageModalProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void,
    card?: ClassCard
    // editedClass?: ClassInfo
}

interface SavePackageModalState {
    packageData: PackageData | null
    isNameValid: boolean
    isLabelValid: boolean
}

const NAME_FIRST_SYMBOL = "a-zA-Z_";
const NAME_SYMBOLS = NAME_FIRST_SYMBOL + "0-9";

const LABEL_FIRST_SYMBOL = undefined;
const LABEL_SYMBOLS = undefined;

class SaveClassModal extends React.Component<SavePackageModalProps, SavePackageModalState> {

    private title: any;

    constructor(props: SavePackageModalProps) {
        super(props);
        const packageId = this.props.modal.options.body;
        this.closeModal = this.closeModal.bind(this);
        this.title = packageId !== undefined ? { id: "CLASSCARD_EDIT_PACKAGE" } : { id: "CLASSCARD_CREATE_PACKAGE" };

        const packageData: PackageData | null = this.initializeState();
        this.state = {
            packageData,
            isNameValid: packageData ? this.validateName(packageData.name) : false,
            isLabelValid: packageData ? this.validateLabel(packageData.label) : false
        };
    }

    getNamespaceId(namespaceContainer: NamespaceContainer, url: string) {
        const idEntry = Object.entries(namespaceContainer.namespaceById).find(entry => entry[1].url === url);
        return idEntry && +idEntry[0];
    }


    initializeState() {
        const { card, } = this.props;
        const packageId = this.props.modal.options.body;
        if (!card) {
            return null;
        }

        let packageData: PackageData | null = null;
        if (!packageId) {
            if (Object.keys(card.namespace.namespaceById).length === 0) {
                return null
            }
            const namespaceId = +Object.entries(card.namespace.namespaceById)[0][0];
            packageData = {
                label: '',
                name: '',
                namespaceId,
                description: '',
                parentId: null
            }

        } else {

            const packages = card.packages;
            const existedPackageData: Package = packages.packageById[parseInt(packageId.toString())];
            if (existedPackageData) {
                const { id, label, description, name, namespaceId, packageId, parentId } = existedPackageData;
                const pId = id.toString();

                packageData = {
                    id: +pId, label, name, description, namespaceId, parentId
                }
            }
        }
        return packageData;
    }


    validateName(name: string) {
        return !!name;
    }

    validateLabel(label: string) {
        return !!label;
    }

    getNamespaceSelectData(namespaceContainer: NamespaceContainer) {
        const options:OptionType[] = [];
        Object.entries(namespaceContainer.namespaceById).forEach(entry => {
            const [value, ns] = entry;
            const label = ns.prefix;
            options.push({ label, value:+value })
        })
        return options;
    }

    changeSelect = (fieldName: string) => (option: ValueType<OptionType,false>) => {
        const { value } = option as OptionType;
        const oldPackageData = this.state.packageData;
        if (isFinite(value) && oldPackageData) {
            let newValue: number | null = value;
            if (!isFinite(newValue)) {
                newValue = null;
            }
            let packageData: PackageData = { ...oldPackageData, [fieldName]: newValue };
            this.setState({ packageData })
        }
    }
    changeInput = (fieldName: string) => (value: string) => {
        const nextState: SavePackageModalState = { ...this.state };
        let classData: PackageData | null = nextState.packageData;
        if (!classData) {
            return;
        }
        classData = { ...classData, [fieldName]: value };
        nextState.packageData = classData;
        switch (fieldName) {
            case 'name':
                nextState.isNameValid = this.validateName(value);
                break;
            case 'label':
                nextState.isLabelValid = this.validateLabel(value);
                break;
        }
        this.setState(nextState);
    }


    renderNamespace(packageData: PackageData, namespaceContainer: NamespaceContainer) {
        const namespaces = this.getNamespaceSelectData(namespaceContainer);
        const { namespaceId: value } = packageData;
        const { prefix: label } = namespaceContainer.namespaceById[value];
        const checkedValue: OptionType = { value, label };
        return (<Form.Group as={Row} className="mb-2" controlId="namespace">
            <Col md={3}>
                <Form.Label>
                    <FormattedMessage id={"CLASSCARD_NAMESPACE"} />
                </Form.Label>
            </Col>
            <Col md={9}>
                <Select
                    className="npt-select"
                    value={checkedValue}
                    isSearchable={true}
                    options={namespaces}
                    onChange={this.changeSelect('namespaceId')} />
            </Col>
        </Form.Group>)
    }


    renderIdentifier(packageData: PackageData) {
        const { name } = packageData;
        return (
            <Form.Group className="mb-2" controlId="name">
                <Row>
                <Col md={3}>
                    <Form.Label>
                        <FormattedMessage id={"CLASSCARD_NAME_OF_PREDICATE"} />
                    </Form.Label>
                </Col>
                <Col md={9}>
                    <MaskedInput
                        className={"form-control" + (name ? "" : " is-invalid")}
                        value={name}
                        onChange={this.changeInput("name")}
                        allowedSymbols={NAME_SYMBOLS}
                        firstSymbol={NAME_FIRST_SYMBOL}
                    />
                </Col>
                </Row>
                <Row>
                    <Col md={3}>
                    </Col>
                    <Col md={9}>
                        <Form.Text className="text-info" >
                            <FormattedMessage id="CLASSCARD_ALLOWED_SYMBOLS" values={{ symbols: NAME_SYMBOLS }} />
                        </Form.Text>
                    </Col>
                </Row>
            </Form.Group>
        )
    }
    renderLabel(packageData: PackageData) {
        const { label } = packageData;
        return (
            <Form.Group as={Row} className="mb-2" controlId="label">
                <Col md={3}>
                    <Form.Label>
                        <FormattedMessage id={"CLASSCARD_LABEL_OF_PREDICATE"} />
                    </Form.Label>
                </Col>
                <Col md={9}>
                    <MaskedInput
                        className={"form-control" + (label ? "" : " is-invalid")}
                        value={label}
                        onChange={this.changeInput("label")}
                        allowedSymbols={LABEL_SYMBOLS}
                        firstSymbol={LABEL_FIRST_SYMBOL}
                    />
                </Col>
            </Form.Group>
        )
    }

    renderDescription(packageData: PackageData) {
        const { description } = packageData;
        return (
            <Form.Group as={Row} className="mb-2" controlId="description">
                <Col md={3}>
                    <Form.Label>
                        <FormattedMessage id={"CLASSCARD_DESCRIPTION"} />
                    </Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control as="textarea" rows={3} value={description}
                        onChange={(e: any) => this.changeInput("description")(e.target.value)}
                    />
                </Col>
            </Form.Group>
        )
    }

    renderTemplate(): React.ReactElement {
        const { packageData } = this.state;
        const { card } = this.props;
        if (!packageData || !card) {
            return <></>;
        }

        const { packages, namespace, allClasses, stereotype, storeType } = card;
        return (
            <Form>
                {this.renderNamespace(packageData, namespace)}
                {this.renderIdentifier(packageData)}
                {this.renderLabel(packageData)}
                {this.renderDescription(packageData)}
            </Form>
        );
    }

    isPackageValid() {
        const { isLabelValid, isNameValid } = this.state;
        return isLabelValid && isNameValid;
    }

    closeModal(status: ModalStatus, result: any) {
        const { packageData } = this.state;
        if (status == MODAL_STATUS_OK && packageData && this.isPackageValid()) {
            this.props.closeModal(status, packageData);
        } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
            this.props.closeModal(status, result);
        }
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}

export default connect((state: ApplicationState) => {
    if (!state.profileeditor || !state.profileeditor.card) {
        return {}
    }
    return {
        card: state.profileeditor.card,
        newClassPackageId: state.profileeditor.newClassPackageId,
        editedClass: state.profileeditor.editedClass
    }
},
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
        return {

        }
    })(SaveClassModal)

