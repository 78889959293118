export const SEND_SOURCE_TREE = 'SEND_SOURCE_TREE';
export type SEND_SOURCE_TREE = typeof SEND_SOURCE_TREE;

export const SEND_SOURCE_TREE_LOADING = 'SEND_SOURCE_TREE_LOADING';
export type SEND_SOURCE_TREE_LOADING = typeof SEND_SOURCE_TREE_LOADING;

export const SEND_SOURCE_TREE_ERROR = 'SEND_SOURCE_TREE_ERROR';
export type SEND_SOURCE_TREE_ERROR = typeof SEND_SOURCE_TREE_ERROR;

export const SEND_SOURCE_TREE_TOGGLE = 'SEND_SOURCE_TREE_TOGGLE';
export type SEND_SOURCE_TREE_TOGGLE = typeof SEND_SOURCE_TREE_TOGGLE;

export const SEND_SOURCE_TREE_ACTIVE = 'SEND_SOURCE_TREE_ACTIVE';
export type SEND_SOURCE_TREE_ACTIVE = typeof SEND_SOURCE_TREE_ACTIVE;

export const SEND_SOURCE_TREE_SEARCH_FILTER_LOADING = 'SEND_SOURCE_TREE_SEARCH_FILTER_LOADING';
export type SEND_SOURCE_TREE_SEARCH_FILTER_LOADING = typeof SEND_SOURCE_TREE_SEARCH_FILTER_LOADING;

export const SEND_SOURCE_TREE_SEARCH_FILTER = 'SEND_SOURCE_TREE_SEARCH_FILTER';
export type SEND_SOURCE_TREE_SEARCH_FILTER = typeof SEND_SOURCE_TREE_SEARCH_FILTER;

export const SEND_SOURCE_CODE_TREE_TOGGLE_INPLACE = 'SEND_SOURCE_CODE_TREE_TOGGLE_INPLACE';
export type SEND_SOURCE_CODE_TREE_TOGGLE_INPLACE = typeof SEND_SOURCE_CODE_TREE_TOGGLE_INPLACE;

export const SEND_SOURCE_TREE_CREATE_DIRECTORY = 'SEND_SOURCE_TREE_CREATE_DIRECTORY';
export type SEND_SOURCE_TREE_CREATE_DIRECTORY = typeof SEND_SOURCE_TREE_CREATE_DIRECTORY;

export const SEND_SOURCE_CODE = 'SEND_SOURCE_CODE';
export type SEND_SOURCE_CODE = typeof SEND_SOURCE_CODE;

export const SEND_SOURCE_CODE_LOADING = 'SEND_SOURCE_CODE_LOADING';
export type SEND_SOURCE_CODE_LOADING = typeof SEND_SOURCE_CODE_LOADING;

export const SEND_SOURCE_CODE_ERROR = 'SEND_SOURCE_CODE_ERROR';
export type SEND_SOURCE_CODE_ERROR = typeof SEND_SOURCE_CODE_ERROR;

export const SEND_SOURCE_CODE_INIT_EDIT = 'SEND_SOURCE_CODE_INIT_EDIT';
export type SEND_SOURCE_CODE_INIT_EDIT = typeof SEND_SOURCE_CODE_INIT_EDIT;

export const SEND_SOURCE_CODE_TEXT_CHANGES = 'SEND_SOURCE_CODE_TEXT_CHANGES';
export type SEND_SOURCE_CODE_TEXT_CHANGES = typeof SEND_SOURCE_CODE_TEXT_CHANGES;

export const SEND_SOURCE_CODE_METADATA_CHANGES = 'SEND_SOURCE_CODE_METADATA_CHANGES';
export type SEND_SOURCE_CODE_METADATA_CHANGES = typeof SEND_SOURCE_CODE_METADATA_CHANGES;

export const SEND_SOURCE_CODE_EDIT_CANCEL = 'SEND_SOURCE_CODE_EDIT_CANCEL';
export type SEND_SOURCE_CODE_EDIT_CANCEL = typeof SEND_SOURCE_CODE_EDIT_CANCEL;

export const SEND_SOURCE_CODE_CHANGE_TEMPLATE = 'SEND_SOURCE_CODE_CHANGE_TEMPLATE';
export type SEND_SOURCE_CODE_CHANGE_TEMPLATE = typeof SEND_SOURCE_CODE_CHANGE_TEMPLATE;

export const SEND_SOURCE_CODE_CANCEL_TEMPLATE = 'SEND_SOURCE_CODE_CANCEL_TEMPLATE';
export type SEND_SOURCE_CODE_CANCEL_TEMPLATE = typeof SEND_SOURCE_CODE_CANCEL_TEMPLATE;

export const SEND_SOURCE_CODE_APPLY_TEMPLATE = 'SEND_SOURCE_CODE_APPLY_TEMPLATE';
export type SEND_SOURCE_CODE_APPLY_TEMPLATE = typeof SEND_SOURCE_CODE_APPLY_TEMPLATE;

export const SEND_SOURCE_CODE_CHANGE_TEMPLATE_NODE = 'SEND_SOURCE_CODE_CHANGE_TEMPLATE_NODE';
export type SEND_SOURCE_CODE_CHANGE_TEMPLATE_NODE = typeof SEND_SOURCE_CODE_CHANGE_TEMPLATE_NODE;

export const SEND_SOURCE_CODE_TOGGLE_META_DATA = 'SEND_SOURCE_CODE_TOGGLE_META_DATA';
export type SEND_SOURCE_CODE_TOGGLE_META_DATA = typeof SEND_SOURCE_CODE_TOGGLE_META_DATA;

