import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { sendSelection } from "../actions/selection";
import { confirmPasteTreeChild, fetchTreeNodes, sendContextMenuInfo, sendTreeNodeActive, sendTreeNodeToggle } from "../actions/tree";
import { ContextNode, ListTree, Node } from "../components/tree/ListTree";
import { NODE_DND_ID, TreeEvent } from "../constants/tree";
import { ApplicationAction, ApplicationState } from "../types";
import { FetchError, isFetchError } from "../types/error";
import { CimClass, Package } from "../types/profile";
import { isLoggedInUser } from "../types/security";
import { createObject } from "../types/selection";
import { SourceTreeNode } from "../types/sourceeditor";
import { isSubject, Subject } from "../types/subject";
import { ExpandTreeNodeEventOptions, TreeAutomationBindingFunctions, TreeAutomationBindings, TreeHeaderFilter, TreeNode, TreeState } from "../types/tree";
import { registerFunction, retrieveFunction } from "./automation";

export function expandTreeNodeFire(treeId: string, nodeId: string) {
    let eventOptions: ExpandTreeNodeEventOptions = {
        treeId,
        nodeId
    };
    const newSubjectEvent = new CustomEvent(TreeEvent.EXPAND_NODE, { detail: eventOptions })

    document.dispatchEvent(newSubjectEvent);
}

export function sortTreeNodes(nodes: TreeNode[], parentNode?: TreeNode, automation?: TreeAutomationBindings): TreeNode[] {
    let sortedNodes = nodes.sort((nodeA, nodeB) => {
        if (!nodeA.leaf) {
            if (nodeB.leaf) {
                /**NodeA is directory, nodeB isn't */
                return -1;
            }
        } else if (!nodeB.leaf) {
            /**NodeB is directory, nodeA isn't */
            return 1;
        }

        /**Both nodes have same type => sort by name */
        if (nodeA.name) {
            if (!nodeB.name) {
                /**NodeA have name, nodeB haven't */
                return -1;
            }
        } else if (nodeB.name) {
            /**NodeB have name, nodeA haven't */
            return 1;
        } else {
            /**Both nodes haven't name */
            return 0;
        }

        /**Both nodes have name */
        if (nodeA.name > nodeB.name) {
            return 1;
        }
        if (nodeA.name < nodeB.name) {
            return -1;
        }
        return 0;
    });
    if (parentNode && automation && automation.sortBindings && automation.sortBindings[parentNode.typeId]) {
        const sortFunction = retrieveFunction(automation.sortBindings[parentNode.typeId]);
        sortedNodes = sortedNodes.sort(sortFunction);
    }
    return sortedNodes;
}

export function sortSourceTreeNodes(nodesIds: string[], nodeById: { [ID: string]: SourceTreeNode }): string[] {
    return nodesIds.sort((nodeAId, nodeBId) => {
        const nodeA = nodeById[nodeAId];
        const nodeB = nodeById[nodeBId];

        if (nodeA) {
            if (!nodeB) {
                /**NodeA is defined, nodeB isn't */
                return -1;
            }
        } else if (nodeB) {
            /**NodeB is defined, nodeA isn't */
            return 1;
        } else {
            /**Both nodes isn't defined */
            return 0;
        }

        if (nodeA.directory) {
            if (!nodeB.directory) {
                /**NodeA is directory, nodeB isn't */
                return -1;
            }
        } else if (nodeB.directory) {
            /**NodeB is directory, nodeA isn't */
            return 1;
        }

        /**Both nodes have same type => sort by name */
        if (nodeA.name) {
            if (!nodeB.name) {
                /**NodeA have name, nodeB haven't */
                return -1;
            }
        } else if (nodeB.name) {
            /**NodeB have name, nodeA haven't */
            return 1;
        } else {
            /**Both nodes haven't name */
            return 0;
        }

        /**Both nodes have name */
        if (nodeA.name > nodeB.name) {
            return 1;
        }
        if (nodeA.name < nodeB.name) {
            return -1;
        }
        return 0;
    });
}

export function sortProfileeditorTreeNodes(nodesIds: string[], nodeById: { [ID: string]: Package | CimClass }): string[] {
    return nodesIds.sort((nodeAId, nodeBId) => {
        const nodeA = nodeById[nodeAId];
        const nodeB = nodeById[nodeBId];

        if (nodeA) {
            if (!nodeB) {
                /**NodeA is defined, nodeB isn't */
                return -1;
            }
        } else if (nodeB) {
            /**NodeB is defined, nodeA isn't */
            return 1;
        } else {
            /**Both nodes isn't defined */
            return 0;
        }

        /**Both nodes have same type => sort by name */
        if (nodeA.label) {
            if (!nodeB.label) {
                /**NodeA have name, nodeB haven't */
                return -1;
            }
        } else if (nodeB.label) {
            /**NodeB have name, nodeA haven't */
            return 1;
        } else {
            /**Both nodes haven't name */
            return 0;
        }

        /**Both nodes have name */
        if (nodeA.label > nodeB.label) {
            return 1;
        }
        if (nodeA.label < nodeB.label) {
            return -1;
        }
        return 0;
    });
}

/**
 * Use composition function to synchronize generation of id in service and in component. 
 * If new option will be added - we can prevent id mismatch errors.
 **/
export function composeNodeDecoratorId(type: string) {
    return type;
}
export function composeNodeActionId(actionId: string, type?: string) {
    if (!type) {
        return actionId;
    }
    return `${type}.${actionId}`;
}

export function generateTreeBindings(automation: TreeAutomationBindings): TreeAutomationBindingFunctions {
    return {
        bindSort: function (parentType: string | string[], func: Function) { //function(nodeA, nodeB) => number
            if (!automation.sortBindings) {
                automation.sortBindings = {};
            }
            if (Array.isArray(parentType)) {
                for (let type of parentType) {
                    automation.sortBindings[type] = registerFunction(func);
                }
            } else {
                automation.sortBindings[parentType] = registerFunction(func);
            }
        },
        bindAction: function (options: { action: string, type?: string | string[] }, func: Function) { //function(tree) => void
            if (!automation.actionBindings) {
                automation.actionBindings = {};
            }
            if (Array.isArray(options.type)) {
                for (let type of options.type) {
                    automation.actionBindings[composeNodeActionId(options.action, type)] = registerFunction(func);
                }
            } else {
                automation.actionBindings[composeNodeActionId(options.action, options.type)] = registerFunction(func);
            }
        },
        bindDecorator: function (options: { type: string | string[] }, func: Function) { //function(tree) => void
            if (!automation.decoratorBindings) {
                automation.decoratorBindings = {};
            }
            if (Array.isArray(options.type)) {
                for (let type of options.type) {
                    automation.decoratorBindings[composeNodeDecoratorId(type)] = registerFunction(func);
                }
            } else {
                automation.decoratorBindings[composeNodeDecoratorId(options.type)] = registerFunction(func);
            }
        },
        bindReverseDecorator: function (options: { type: string | string[] }, func: Function) { //function(tree) => void
            if (!automation.reverseDecoratorBindings) {
                automation.reverseDecoratorBindings = {};
            }
            if (Array.isArray(options.type)) {
                for (let type of options.type) {
                    automation.reverseDecoratorBindings[composeNodeDecoratorId(type)] = registerFunction(func);
                }
            } else {
                automation.reverseDecoratorBindings[composeNodeDecoratorId(options.type)] = registerFunction(func);
            }
        }
    };
}

function getFilterValue(nodeFilterValue: string, filter: TreeHeaderFilter): boolean | null {
    const filterOption = filter.options.find((option) => option.value === nodeFilterValue);
    if (filterOption) {
        return filterOption.checked;
    }
    return null;
}

/**Check if node is hidden by filter */
export function isNodeHiddenByFilter(node: TreeNode, filter?: TreeHeaderFilter) {
    if (!filter || !node.cache || !node.cache[filter.key]) {
        return false;
    }
    const nodeFilterValue = node.cache[filter.key];
    if (typeof nodeFilterValue === "string") {
        const filterValue = getFilterValue(nodeFilterValue, filter);
        if (filterValue === false) {
            return true;
        }
        return false;
    }
    if (!Array.isArray(nodeFilterValue)) {
        return false;
    }
    let hidden = true;
    for (let value of nodeFilterValue) {
        const filterValue = getFilterValue(value, filter);
        if (filterValue === true) {
            hidden = false;
        }
    }
    return hidden;
}

/**Search for node path in loaded tree */
export function getNodePathByRdfId(tree: TreeState, rdfId: string): string[] | null {
    const nodeId = tree.nodeIdByRdfId?.[rdfId];
    if (!nodeId) {
        return null;
    }
    let node = tree.nodeById?.[nodeId];
    if (!node) {
        return null;
    }
    const path = [node.id];
    while (node?.parentId) {
        path.unshift(node.parentId);
        node = tree.nodeById?.[node.parentId];
    }
    return path;
}

/*************************
 * Tree props connectors *
 *************************/
function getDecoratedNodeProps(treeState: TreeState, nodeById: { [ID: string]: TreeNode }, subjects: { [SUBJECT_KEY: string]: Subject | FetchError }, nodeId: string) {

    const node = nodeById[nodeId] as TreeNode;
    const rdfId: string | null = node.data && node.data.$rdfId;
    const active = treeState.active ? treeState.active === nodeId : false;
    let icon: string | undefined = node.icon;
    let iconColor: string | null | undefined = node.iconColor;
    let iconClass: string | null | undefined = null;
    const toggled = treeState.toggled ? treeState.toggled[nodeId] : false;
    if (rdfId && subjects && subjects[rdfId]) {
        const subject = subjects[rdfId];
        if (isSubject(subject) && !subject.isNew && subject.subjectData.$lock && subject.subjectData.$lock.status) {
            icon = "fa-edit";
            iconColor = null;
            iconClass = `text-${active ? 'white' : 'success'}`;
        }
    }

    let children = undefined;
    if (!node.leaf) {
        children = treeState.children && treeState.children[nodeId] || null;
    }

    const hidden = isNodeHiddenByFilter(node, treeState?.header?.filter);
    return {
        name: node.label,
        expanded: toggled,
        active: active,
        hidden: hidden,
        inplace: false,
        edgeTriggerInplace: true,
        loading: treeState.loading && treeState.loading[node.id],
        error: Boolean(node.error),
        editing: false,
        changed: false,
        childrenIds: children,
        data: node,
        icon: icon,
        iconColor: iconColor,
        iconClass: iconClass,
        decorator: treeState.automation.decoratorBindings?.[composeNodeDecoratorId(node.typeId)],
        reverseDecorator: treeState.automation.reverseDecoratorBindings?.[composeNodeDecoratorId(node.typeId)],
        treeFilter: treeState.header?.filter
    }
}

function getDndNodeProps(treeState: TreeState, nodeById: { [ID: string]: TreeNode }, subjects: { [SUBJECT_KEY: string]: Subject | FetchError }, nodeId: string, isSuperUser?: boolean) {
    const decoratedProps = getDecoratedNodeProps(treeState, nodeById, subjects, nodeId);
    const node = nodeById[nodeId] as TreeNode;

    return {
        ...decoratedProps,
        dragType: NODE_DND_ID,
        dropTypes: [NODE_DND_ID],
        pasteTypes: node.pasteTypes,
        isSuperUser: isSuperUser
    }
}

const nodeSimpleActionsConnector = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: { treeId: string, id: string }) => {
    const { id, treeId } = ownProps;
    return {
        toggle: (expanded: boolean) => {
            dispatch(sendTreeNodeToggle(treeId, id, expanded));
        },
        activate: (node: TreeNode) => {
            dispatch(sendTreeNodeActive(treeId, id));
        },
        loadChildren: () => {
            dispatch(fetchTreeNodes(treeId, id))
        }
    }
}

const nodeActionsConnector = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: { treeId: string, id: string }) => {
    const { id, treeId } = ownProps;
    return {
        ...nodeSimpleActionsConnector(dispatch, ownProps),
        activate: (node: TreeNode) => {
            if (!node.data) {
                return;
            }
            const rdfId = node.data.$rdfId
            const namespace = node.data.$namespace
            const type = node.typeId
            dispatch(sendTreeNodeActive(treeId, id));
            if (rdfId) {
                dispatch(sendSelection(createObject(rdfId, namespace), type || ''))
            }
        }
    }
}

const nodeWithMenuActionsConnector = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: { treeId: string, id: string }) => {
    const { id, treeId } = ownProps;
    return {
        ...nodeActionsConnector(dispatch, ownProps),
        contextMenu: () => {
            dispatch(sendContextMenuInfo(treeId, id))
        }
    }
}

const nodeDropConnector = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: { treeId: string, id: string }) => {
    const { id, treeId } = ownProps;
    return {
        ...nodeWithMenuActionsConnector(dispatch, ownProps),
        drop: (data: any, isCopy: boolean) => {
            dispatch(confirmPasteTreeChild(treeId, data?.targetId, id, isCopy));
        }
    }
}

/*******************
 * Tree components *
 *******************/
export const ConnectedDndNode = connect((_, ownProps: { treeId: string, id: string }) => {
    return (state: ApplicationState) => {
        const { treeId, id } = ownProps;
        const treeState = state.tree?.treeInfo?.[treeId];
        if (!treeState || !treeState?.nodeById?.[id] || isFetchError(treeState.nodeById[id])) {
            return {
                error: true
            };
        }

        const { loginStatus: loginInfo } = state.security;
        const isSuperUser = isLoggedInUser(loginInfo) ? loginInfo.superUser : undefined;
        return getDndNodeProps(treeState, treeState.nodeById, state?.subject?.subjects || {}, id, isSuperUser);
    }
}, nodeDropConnector)(ContextNode);


export const ConnectedSimpleNode = connect((_, ownProps: { treeId: string, id: string }) => {
    return (state: ApplicationState) => {
        const { treeId, id } = ownProps;
        const treeState = state.tree?.treeInfo?.[treeId];
        if (!treeState || !treeState?.nodeById?.[id] || isFetchError(treeState.nodeById[id])) {
            return {
                error: true
            };
        }

        return getDecoratedNodeProps(treeState, treeState.nodeById, state?.subject?.subjects || {}, id);
    }
}, nodeSimpleActionsConnector)(Node);

export const ConnectedListTree = connect((state: ApplicationState, ownProps: { treeId: string, initRootRdfId?: string }) => {
    const { treeId, initRootRdfId } = ownProps;
    const treeState = state.tree.treeInfo?.[treeId];
    let roots = treeState?.rootNodesIds;
    if (roots?.length && initRootRdfId) {
        roots = [];
        const rootNodeId = treeState?.nodeIdByRdfId?.[initRootRdfId];
        if (rootNodeId) {
            roots.push(rootNodeId);
        }
    }

    return {
        menuId: treeId,
        treeId: treeId,
        roots: roots,

    }
}, (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>, ownProps: { treeId: string }) => {
    const { treeId } = ownProps;
    return {
        loadRoots: () => {
            dispatch(fetchTreeNodes(treeId))
        }
    }
})(ListTree)