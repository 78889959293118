import React from 'react';
import { connect } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from '../../../types';
import { SearchData } from '../../../types/location';
import { changeSearch } from '../../../actions/location';
import { getQueryParamsFromLink } from '../../../services/location';

interface SubjectLinkProps extends LinkProps {
    changeQueryParams: (queryParams: SearchData) => void
}

class SubjectLink extends React.PureComponent<SubjectLinkProps> {

    constructor(props: SubjectLinkProps) {
        super(props);
    }

    render() {
        const changeSelection = () => {
            const queryParams = getQueryParamsFromLink(this.props.to.toString());
            if (queryParams === null) {
                return;
            }
            this.props.changeQueryParams(queryParams);
        }
        const { className } = this.props
        return <Link style={{textDecoration:'none'}} to={this.props.to} className={className} onClick={changeSelection}>{this.props.children}</Link>
    }
}

export default connect(null, (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
    return {
        changeQueryParams: (queryParams: SearchData) => {
            dispatch(changeSearch(queryParams));
        }
    }
})(SubjectLink);