import React from 'react';
import ReactDOM from 'react-dom';

export interface TooltipProps {
    tooltip: string
    delegate?: React.ComponentType<any>
    [P: string]: any
}

//Mimics React.MouseEvent
export interface TooltipOpenEvent {
    clientX: number,
    clientY: number
}

export interface TooltipState extends TooltipOpenEvent {
    domNode: HTMLDivElement | null
    visible: boolean
    extraProps?: object
}

export default class Tooltip extends React.Component<TooltipProps, TooltipState> {

    private domNode: HTMLDivElement | null = null;

    constructor(props: TooltipProps) {
        super(props)
        this.state = {
            domNode: null,
            visible: false,
            clientX: 0,
            clientY: 0
        }
    }

    getTooltipId = () => {
        return this.props.id || this.props.tooltip
    }

    componentDidMount(): void {
        const id = this.getTooltipId();
        if (typeof id == 'string') {
            registerNamedTooltip(id, this);
        }
        this.domNode = document.createElement("div");
        this.domNode.style.cssText = "position: absolute; top: 0; left: 0; z-index: 100; display: none;";
        document.body.appendChild(this.domNode);
        this.setState({ domNode: this.domNode })
    }

    componentWillUnmount(): void {
        const id = this.getTooltipId();
        if (typeof id == 'string') {
            unregisterNamedTooltip(id)
        }
        if (this.domNode) {
            this.domNode.remove()
        }
    }

    open = (evt: TooltipOpenEvent, extraProps?: object) => {
        this.setState({
            visible: true,
            clientX: evt.clientX,
            clientY: evt.clientY,
            extraProps: extraProps
        });
    }

    close = () => {
        this.setState({
            visible: false,
            extraProps: undefined
        });
    }

    render() {
        const domNode = this.state.domNode
        if (!domNode) {
            return null;
        }

        const visible = this.state.visible
        domNode.style.display = visible? 'block': 'none'
        if (!visible) {
            return null;
        }
        
        domNode.style.left = this.state.clientX + "px";
        domNode.style.top = this.state.clientY + "px";

        //Extra props have priority over modal props!
        const extraProps = this.state.extraProps ? { ...this.props, ...this.state.extraProps} : this.props;
        if (this.props.delegate) {
            const C = this.props.delegate
            return ReactDOM.createPortal(
                <C {...extraProps} />,
                domNode);
        }

        let el = React.Children.only(this.props.children);
        if (React.isValidElement<any>(el)) {
            el = React.cloneElement(el, extraProps)
        }
        return ReactDOM.createPortal(el, domNode);
    }
}


/////////////////////////////////////////////
//PUBLIC API TO OPERATE WITH NAMED TOOLTIPS//
/////////////////////////////////////////////
export type NamedTooltipRegistry = { [ID: string]: Tooltip }

export function isNamedTooltipRegistry(x: any): x is NamedTooltipRegistry {
    return typeof x == 'object' && x != null;
}

export function registerNamedTooltip(id: string, tooltip: Tooltip) {
    const namedTooltips = (window as any).__NPT__?.namedTooltips
    if (!isNamedTooltipRegistry(namedTooltips)) {
        console.error("No named tooltip registry: __NPT__.namedTooltips");
        return
    }
    namedTooltips[id] = tooltip
}

export function unregisterNamedTooltip(id: string) {
    const namedTooltips = (window as any).__NPT__?.namedTooltips
    if (!isNamedTooltipRegistry(namedTooltips)) {
        console.error("No named tooltip registry: __NPT__.namedTooltips");
        return
    }
    delete namedTooltips[id]
}

export function isRegisteredNamedTooltip(id: string): boolean {
    const namedTooltips = (window as any).__NPT__?.namedTooltips
    if (!isNamedTooltipRegistry(namedTooltips)) {
        console.error("No named tooltip registry: __NPT__.namedTooltips");
        return false
    }
    const tooltip = namedTooltips[id]
    return typeof tooltip != 'undefined';
}

export function openNamedTooltip(id: string, evt: TooltipOpenEvent, extraProps?: { [PROP: string]: any }) {
    const namedTooltips = (window as any).__NPT__?.namedTooltips
    if (!isNamedTooltipRegistry(namedTooltips)) {
        console.error("No named tooltip registry: __NPT__.namedTooltips");
        return false
    }
    const tooltip = namedTooltips[id];
    if (!tooltip) {
        console.error("No named tooltip: " + id);
        return false
    }
    tooltip.open(evt, extraProps);
}
