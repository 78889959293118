import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterData, StringFilterData } from '../../../types/table';
import { isEmptyObject } from '../../../services/app';

import DebounceInput from '../../debounce/debounceinput';
import FilterOptions from './FilterOptions';
import EmptyCellsFilter from './EmptyCellsFilter';

import styles from '../Table.module.css';

/** Filter dropdown content for string type columns */
interface StringFilterProps {
    loading: boolean
    error: boolean
    filterData: StringFilterData | null
    selectionOptions: string[]
    onChange: (filterData: StringFilterData) => void
    onEnter: (filterData: StringFilterData) => void
    fetchColumnSelection: (filterData: FilterData | null) => void
}
const StringFilter: React.FunctionComponent<StringFilterProps> = React.memo((props) => {
    const stringFilter = props.filterData as StringFilterData;
    const propsSelectedValues = props.filterData ? stringFilter.selected : {};
    const contains = props.filterData ? stringFilter.contain : "";
    const propsAllowNulls = props.filterData ? stringFilter.allowNulls : false;

    const [value, setValue] = React.useState(contains);
    const [selectedValues, setSelectedValues] = React.useState<{ [k: string]: boolean }>(propsSelectedValues);
    const [allowNulls, setAllowNulls] = React.useState<boolean>(propsAllowNulls);
    const [isOptionsToggled, setIsOptionsToggled] = React.useState(!isEmptyObject(propsSelectedValues));
    const filterOptions = (value: string) => {
        if (!value) {
            return props.selectionOptions;
        }
        return props.selectionOptions.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }
    const [filteredOptions, setFilteredOptions] = React.useState(filterOptions(value));
    React.useEffect(() => {
        if (!isOptionsToggled) {
            return;
        }
        props.fetchColumnSelection({
            type: "string",
            contain: "",
            selected: {},
            allowNulls: false
        });
    }, [isOptionsToggled]);
    React.useEffect(() => {
        props.onChange({
            type: "string",
            contain: value,
            selected: selectedValues,
            allowNulls
        });
    }, [value, selectedValues, allowNulls]);
    React.useEffect(() => {
        setFilteredOptions(filterOptions(value));
    }, [props.selectionOptions, value]);
    React.useEffect(() => {
        if (props.loading) {
            return;
        }
        let newSelectedValues: { [k: string]: boolean } = {};
        let updateNeeded = false;
        for (let p in selectedValues) {
            if (filteredOptions.indexOf(p) === -1) {
                updateNeeded = true;
                continue;
            }
            newSelectedValues[p] = selectedValues[p];
        }
        if (updateNeeded) {
            setSelectedValues(newSelectedValues);
        }
    }, [filteredOptions]);

    const changeValue = (value: any) => {
        if (typeof value !== "string") {
            setValue("");
            return;
        }
        setValue(value);
    }

    const onEnter = (value: string) => {
        props.onEnter({
            type: "string",
            contain: value,
            selected: selectedValues,
            allowNulls
        });
    }

    return <div className={`w-100 d-flex flex-column`}>
        <div className={`w-100 d-flex ${styles.nptFilterValues}`}>
            <DebounceInput
                className={"form-control"}
                editable={true}
                focus={true}
                value={value}
                format={"string"}
                change={changeValue}
                onEnter={onEnter}
            />
        </div>
        <EmptyCellsFilter value={allowNulls} onChange={setAllowNulls}/>
        <FilterOptions
            loading={props.loading}
            error={props.error}
            toggled={isOptionsToggled}
            selectedValues={selectedValues}
            selectionOptions={filteredOptions}
            onChange={setSelectedValues}
            onToggle={setIsOptionsToggled}
        />
    </div>;
});

export default StringFilter;