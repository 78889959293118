import * as React from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { SortDirection } from '../../types/table';
import styles from './Common.module.css';

interface Option {
    value: string
    label: string
    removable?: boolean
    hidden?: boolean
    dynamicHidden?: boolean
}

interface SortOption extends Option {
    direction: SortDirection
}
function isSortOption(option: Option): option is SortOption {
    if (!option) {
        return false;
    }
    return typeof (option as SortOption).direction !== "undefined";
}

interface DndOptionsProps {
    type: string
    options: (Option | SortOption)[]
    showHidden?: boolean
    dynamicHidden?: boolean
    onChange: (options: Option[]) => void
}
const DndOptions: React.FunctionComponent<DndOptionsProps> = React.memo((props) => {
    const replaceItem = (newIndex: number, oldIndex: number) => {
        if (newIndex === oldIndex) {
            return;
        }
        const newOptions: (Option | SortOption)[] = [];
        const diff = newIndex > oldIndex ? 1 : -1;
        for (let i = 0; i < props.options.length; ++i) {
            if (i === newIndex) {
                newOptions.push(props.options[oldIndex]);
                continue;
            }
            if (diff === 1) {
                if (i >= oldIndex && i < newIndex) {
                    newOptions.push(props.options[i + 1]);
                } else {
                    newOptions.push(props.options[i]);
                }
                continue;
            }
            if (i <= oldIndex && i > newIndex) {
                newOptions.push(props.options[i - 1]);
            } else {
                newOptions.push(props.options[i]);
            }
        }
        props.onChange(newOptions);
    }
    const deleteItem = (index: number) => {
        const newOptions = props.options.slice();
        newOptions.splice(index, 1);
        props.onChange(newOptions);
    }
    const sortItem = (index: number) => {
        const newOptions = props.options.slice();
        newOptions[index] = Object.assign(
            {},
            newOptions[index],
            { direction: (newOptions[index] as SortOption).direction === "asc" ? "desc" : "asc" }
        );
        props.onChange(newOptions);
    }
    const hideItem = (index: number) => {
        const newOptions = props.options.slice();
        newOptions[index] = Object.assign(
            {},
            newOptions[index],
            { dynamicHidden: !newOptions[index].dynamicHidden }
        );
        props.onChange(newOptions);
    }
    return <div className={`${styles.dndOptionsContainer}`}>
        {props.options.map((option, idx) => <DndOptionItem
            key={idx}
            index={idx}
            type={props.type}
            value={option}
            showHidden={props.showHidden}
            useDynamicHidden={props.dynamicHidden}
            replaceItem={replaceItem}
            deleteItem={deleteItem}
            sortItem={sortItem}
            hideItem={hideItem}
        />)}
    </div>
});

interface DndOptionItemProps {
    index: number
    type: string
    value: Option | SortOption
    showHidden?: boolean
    useDynamicHidden?: boolean
    replaceItem: (newIndex: number, oldIndex: number) => void
    deleteItem: (index: number) => void
    sortItem: (index: number) => void
    hideItem: (index: number) => void
}
const DndOptionItem: React.FunctionComponent<DndOptionItemProps> = React.memo((props) => {
    const [collectedDragProps, drag] = useDrag({
        type: props.type,
        item: { index: props.index, value: props.value },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const [collectedDropProps, drop] = useDrop({
        accept: props.type,
        drop: (item) => props.replaceItem(props.index, (item as any).index)
    });
    const isSortable = isSortOption(props.value);
    const isRemovable = props.value.removable !== false;
    const isHidden = props.value.hidden === true;
    const isDynamicHidden = props.value.dynamicHidden === true;
    let itemClassName = styles.dndOptionItem;
    if (isHidden) {
        if (props.showHidden === false) {
            return null;
        }
        itemClassName += ` ${styles.hidden}`;
    }
    if (isDynamicHidden) {
        itemClassName += ` ${styles.hidden}`;
    }
    if (isSortable) {
        itemClassName += ` ${styles.sortable}`;
    }
    if (isRemovable) {
        itemClassName += ` ${styles.removable}`;
    }
    // const ref = collectedProps.isDragging ? dragPreview : drag;
    return <div className={`${styles.dndOptionItemDropzone}`} ref={drop}>
        <div className={itemClassName} ref={drag}>
            {props.value.label}
            {isSortable && <div className={`${styles.dndOptionItemSort}`} onClick={() => props.sortItem(props.index)}>
                <i className={`fa fa-sort-amount-${(props.value as SortOption).direction}`} />
            </div>}
            {isRemovable && <div className={`${styles.dndOptionItemTimes}`} onClick={() => props.deleteItem(props.index)}>
                <i className="fa fa-times" />
            </div>}
            <DndOptionItemHidden
                removable={props.value.removable}
                hidden={props.value.hidden}
                dynamicHidden={props.value.dynamicHidden}
                useDynamicHidden={props.useDynamicHidden}
                hideItem={props.hideItem.bind(null, props.index)}
            />
        </div>
    </div>
});

interface DndOptionItemHiddenProps {
    removable?: boolean
    hidden?: boolean
    dynamicHidden?: boolean
    useDynamicHidden?: boolean
    hideItem: () => void
}
const DndOptionItemHidden: React.FunctionComponent<DndOptionItemHiddenProps> = React.memo((props) => {
    const isRemovable = props.removable !== false;
    if (isRemovable) {
        return null;
    }
    const isHidden = props.hidden === true;
    const isDynamicHidden = props.useDynamicHidden && props.dynamicHidden === true;
    if (!props.useDynamicHidden) {
        if (!isHidden) {
            return null;
        }
        return <div className={`${styles.dndOptionItemHidden}`}>
            <i className="fa fa-eye-slash" />
        </div>
    }
    return <div className={`${styles.dndOptionItemHidden} ${styles.dynamic} ${isDynamicHidden ? styles.selected : ""}`} onClick={props.hideItem}>
        <i className="fa fa-eye-slash" />
    </div>
});

export default DndOptions