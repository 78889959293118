import { Reducer } from 'redux';

import { LocaleState, LocaleAction } from '../types/locale';
import * as constants from '../constants/clipboard';
import { ApplicationAction } from '../types';
import { ClipboardState } from '../types/clipboard';


const reducer: Reducer<ClipboardState, ApplicationAction> = (state: ClipboardState = {}, action: ApplicationAction): ClipboardState => {
    switch (action.type) {
        case constants.SAVE_TO_CLIPBOARD:
            return { ...state, data: action.payload };
    }
    return state;
}

export default reducer;