import { faBars, faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { NavLink } from './Menu';
import { DividerType, MenuData, MenuItem } from '../../types/security';
import { isFullIconName } from '../../types/menueditor';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CustomNavDropdown } from './CustomNavDropdown';
import { OverflowDropDown } from './OverflowDropDown';
import shortid from 'shortid';


export const getItemTitle = (item: MenuItem, id: string, language: string) => {

    const { d, h, i, n, r, t, v } = item
    const title = (t && t[language]) || n || id;
    let icon = <></>
    if (i) {
        icon = isFullIconName(i) ? <i className={`${i}`} /> :
            <FontAwesomeIcon icon={i as IconProp} />;
    }
    return <span>
        {icon}
        {title}
    </span>
}
export const attachDivider = (element: any, key: string, divider?: DividerType, vertical: boolean = false) => {
    if (!divider) {
        return element;
    }
    const dividerElement = vertical ? <span className="py-1  mx-1 d-flex flex-column justify-content-center"><span className="flex-fill border border-right "></span></span> : <Dropdown.Divider />;
    const verticalCalss = 'd-flex flex-row ';
    if (divider === 'after') {
        return <React.Fragment key={key}>
            {element}
            {dividerElement}
        </React.Fragment>
    } else if (divider === 'before') {
        return <React.Fragment key={key}>
            {dividerElement}
            {element}
        </React.Fragment>
    } else if (divider === 'both') {
        return <React.Fragment key={key}>
            {dividerElement}
            {element}
            {dividerElement}
        </React.Fragment>
    }
    return element;
}

export interface DropDownMenuProps {
    language: string,
    iconsOnly?: boolean,
    title?: any
    menu?: MenuData
}


export class DropDownMenu extends React.Component<DropDownMenuProps>{

    constructor(props: DropDownMenuProps) {
        super(props);

    }

    renderRegMenuTitle() {
        const { title } = this.props;
        if (title) {
            return title;
        }
        return <FontAwesomeIcon key="ico" icon={faBars} size="lg" />
    }
    renderItem(id: string, dropDown: boolean, menu: MenuData, language: string, vertical: boolean = false) {
        const item = menu.itemById[id];

        if (typeof item == 'undefined') {
            return null;
        }

        const { d, h, r, v } = item
        const title = getItemTitle(item, id, language);
        const children = menu.childrenById && menu.childrenById[id];
        let itemElement = null;

        if (h) {
            return itemElement;
        }
        if (typeof children != 'undefined') {
            itemElement = <OverflowDropDown className="dropdown-item" direction="left" title={title} id={id} key={id}>
                {children.map((childId) => childId == id ? null : this.renderItem(childId, true, menu, language))}
            </OverflowDropDown >
            // itemElement = <CustomNavDropdown alignRight title={title} id={id} key={id}>
            //     {children.map((childId) => childId == id ? null : this.renderItem(childId, true, menu, language))}
            // </CustomNavDropdown>
        } else {
            itemElement = <NavLink exact={true} to={r || ''} dropDown={dropDown} key={id} >{title}</NavLink>
        }
        return attachDivider(itemElement, id, d, vertical);
    }
    renderNav() {
        const { menu, language } = this.props;
        const m = menu;
        if (typeof m == 'undefined' || typeof m.roots == 'undefined') {
            return null;
        }
        return (<OverflowDropDown direction="left" className="dropdown-item" title={this.renderRegMenuTitle()} id={`nav-menu-${shortid.generate()}`} key="dropdownMenu">
            {m.roots.map((id) => this.renderItem(id, true, m, language))}
        </OverflowDropDown>)
        // return <CustomNavDropdown alignRight  title={this.renderRegMenuTitle()} id={'reg menu'} key="dropdownMenu">
        //     {m.roots.map((id) => this.renderItem(id, true, m, language))}
        // </CustomNavDropdown>
    }

    render() {
        console.log('render nav')
        return this.renderNav();
    }
}

