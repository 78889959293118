import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ServerError } from '../../actions/utils';
import { buildUrl } from '../../services/location';

export interface FetchProps {
    fetch: string
    params?: { [K: string]: any }
    onReady?: (data: { payload: any }) => void
    [P: string]: any
}

export interface FetchState {
    loading: boolean,
    error: boolean,
    payload?: any
}

const Fetch: React.FunctionComponent<FetchProps> = React.memo((props) => {
    const [state, setState] = React.useState<FetchState>({
        loading: true,
        error: false
    });

    const { fetch: fetchUrl, params, onReady, children, ...rest } = props;

    let search: { [k: string]: string } | null = null;
    if (params) {
        search = {}
        for (let k in params) {
            const value = params[k]
            if (typeof value == 'undefined' || value == null) {
                continue
            }
            if (typeof value == 'string') {
                search[k] = value
            } else {
                search[k] = `${value}`
            }
        }
    }

    const fetchData = async (smooth?: boolean) => {
        if (!smooth) {
            setState({
                loading: true,
                error: false
            });
        }
        try {
            const url = buildUrl({ url: fetchUrl, search });
            const data = await fetch(url).then((resp) => {
                if (!resp.ok) {
                    throw new ServerError(resp.status, resp.statusText);;
                }
                return resp.json();
            }).then((json) => {
                return json;
            });
            if (typeof onReady == 'function') {
                onReady({ payload: data });
            }
            setState({
                loading: false,
                error: false,
                payload: data
            });
        } catch (e) {
            setState({
                loading: false,
                error: true
            });
            console.error(e);
        }
    }
    React.useEffect(() => {
        fetchData();
    }, []);
    if (state.error) {
        return <FillPlaceholder>
            <ErrorPlaceholder fetchData={fetchData} />
        </FillPlaceholder>;
    }
    if (state.loading) {
        return <FillPlaceholder>
            <LoadingPlaceholder />
        </FillPlaceholder>;
    }
    const count = React.Children.count(children)
    if (count == 0) {
        return null
    } else if (count == 1) {
        const el = React.Children.only(children);
        if (React.isValidElement<any>(el)) {
            return React.cloneElement(el, { ...rest, payload: state.payload, reload: fetchData })
        }
        console.error("Invalid element type in fetch", el)
        return null;
    } else {
        return <>
            {
                React.Children.map(children, (el) => {
                    if (React.isValidElement<any>(el)) {
                        return React.cloneElement(el, { ...rest, payload: state.payload, reload: fetchData })
                    }
                    console.error("Invalid element type in fetch", el)
                    return null
                })
            }
        </>
    }
});

const FillPlaceholder = React.memo((props) => <div className="w-100 h-100 d-flex justify-content-center align-items-center">
    {props.children}
</div>);

interface ErrorPlaceholderProps {
    fetchData: () => void
}
const ErrorPlaceholder: React.FunctionComponent<ErrorPlaceholderProps> = React.memo((props) => <div className="alert alert-danger">
    <FormattedMessage
        id="NPT_FETCH_ERROR"
        defaultMessage="Error occured during data fetch"
        description="Fetch error placeholder"
    />
    <button className="btn btn-danger ml-2" onClick={props.fetchData}>
        <i className="fa fa-refresh"></i>
    </button>
</div>);

const LoadingPlaceholder = React.memo(() => <div className="d-flex align-items-center alert alert-info">
    <i className="fa fa-circle-o-notch fa-spin fa-2x mr-2"></i>
    <FormattedMessage
        id="NPT_FETCH_LOADING"
        defaultMessage="Loading..."
        description="Fetch loading placeholder"
    />
</div>);

export default Fetch;