import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from '../../../types';
import { I18NString, ModalInfo, ModalStatus } from '../../../types/modal';
import { ActiveRelationInfo, ClassCard, ClassInfo, Predicate, RelationTypeContainer } from '../../../types/profile';
import { AggregationSVG, AssociationSVG, CompositionSVG } from '../../developer/profileeditor/classcard/svg/RelationsSvg';
import ModalView from '../ModalView';


interface RelationPredicateData {
    title: string
    name?: string
    label?: string
    multiplicityInfo?: string
    description?: string
}
interface RelationData {
    predicate: RelationPredicateData
    inverseRole: RelationPredicateData
    type: number
}

interface RelationViewerModalProps {
    modal: ModalInfo
    card?: ClassCard
    closeModal: (status: ModalStatus, result: any) => void
    activeRelation?: ActiveRelationInfo
}

interface RelationViewerModalState {
}


export const RELATION_ASSOTIATION = 0;
export const RELATION_AGGREGATION = 1;
export const RELATION_COMPOSITION = 2;

export const getRelationByLabel = (label: string) => {
    switch (label.toLowerCase()) {
        case 'association':
            return RELATION_ASSOTIATION;
        case 'aggregation':
            return RELATION_AGGREGATION;
        case 'composition':
            return RELATION_COMPOSITION;
        default:
            return RELATION_COMPOSITION;
    }
}
export const getRelationMessageById = (id: number) => {
    console.log("id")
    console.log(id)
    switch (id) {
        case 0: 
            return "CLASSCARD_RELATION_TYPE_ASSOCIATION" 
        case 1: 
            return "CLASSCARD_RELATION_TYPE_AGGREGATION" 
        case 2: 
            return "CLASSCARD_RELATION_TYPE_COMPOSITION"
        default:
            return "CLASSCARD_RELATION_TYPE_COMPOSITION"
    }
}


class RelationViewerModal extends React.Component<RelationViewerModalProps, RelationViewerModalState> {

    title:I18NString = { id: "CLASSCARD_VIEW_RELATION" };
    constructor(props: RelationViewerModalProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    mapRelationTypeToSvg(type: number) {
        switch (type) {
            case RELATION_ASSOTIATION:
                return <AssociationSVG />;
            case RELATION_COMPOSITION:
                return <CompositionSVG />;
            case RELATION_AGGREGATION:
                return <AggregationSVG />
            default:
                return <></>
        }
    }

    isPredicateMatches(activeRelation: ActiveRelationInfo, predicate: Predicate) {
        const { classId, relationId } = activeRelation;
        const { classRelationInfo, classId: predicateClassId } = predicate;
        if (!classRelationInfo) {
            return false;
        }
        const { id, class2Id } = classRelationInfo;
        return relationId === id && predicateClassId === classId;
    }

    renderTitle(relationInfo: number, title: string, titleInv: string, relationContainer: RelationTypeContainer) {
        // const { relationTypeInfo, relationType } = relationInfo;
        const svgRelation = this.mapRelationTypeToSvg(relationInfo);

        let defaultLabel = { label: svgRelation, value: relationInfo };
        const paths = [{ label: <AssociationSVG />, value: RELATION_ASSOTIATION },
        { label: <CompositionSVG />, value: RELATION_COMPOSITION },
        { label: <AggregationSVG />, value: RELATION_AGGREGATION }
        ];
        return (
            <Form.Group as={Row} controlId="classRelations">
                <Col md={4}></Col>
                <Col md={3} className="d-flex flex-column " >
                    <Form.Label className="d-flex justify-content-center">
                        <FormattedMessage id={"CLASSCARD_RELATION_POPUP_FIRST_CLASS"} />
                    </Form.Label>
                    <Form.Label className="d-flex justify-content-center" >
                        {title}
                    </Form.Label>
                </Col>
                <Col md={2}>
                    <Select isDisabled={true}
                        className="npt-select"
                        value={defaultLabel}
                        options={paths}
                    />
                </Col>
                <Col md={3} className="d-flex flex-column">
                    <Form.Label className="d-flex justify-content-center">
                        <FormattedMessage id={"CLASSCARD_RELATION_POPUP_SECOND_CLASS"} />
                    </Form.Label>
                    <Form.Label className="d-flex justify-content-center" >
                        {titleInv}
                    </Form.Label>
                </Col>
            </Form.Group>
        );
    }
    renderLabels() {
        return (<>
            <Row className="mb-1">
                <Col className="py-2"><FormattedMessage id="CLASSCARD_NAME_OF_PREDICATE" /></Col>
            </Row>
            <Row className="mb-1">
                <Col className="py-2"><FormattedMessage id="CLASSCARD_LABEL_OF_PREDICATE" /></Col>
            </Row >
            <Row className="mb-1">
                <Col className="py-2">  <FormattedMessage id="CLASSCARD_MULTIPLICITY" /> </Col>
            </Row>
            <Row className="mb-1">
                <Col className="py-2">  <FormattedMessage id="CLASSCARD_DESCRIPTION" /> </Col>
            </Row>
        </>
        )
    }

    renderRelationContent(relationObject: RelationPredicateData) {
        if (relationObject.name === undefined) {
            return (<div className="h-100 d-flex align-items-stretch">
                <div className="rounded mb-1 px-2 d-flex flex-column justify-content-center" style={{ backgroundColor: '#e3e7eb' }}>
                    <FormattedMessage id="CLASSCARD_NO_REVERSE_PREDICATE" />
                </div>
            </div>)
        }
        const { name, label, multiplicityInfo, description } = relationObject;
        return (<>
            <Row className="mb-1">
                <Col><Form.Control value={name} disabled /></Col>
            </Row>
            <Row className="mb-1">
                <Col><Form.Control value={label} disabled /></Col>
            </Row>
            <Row className="mb-1">
                <Col><Form.Control value={multiplicityInfo} disabled /></Col>
            </Row>
            <Row className="mb-1">
                <Col><Form.Control as="textarea" rows={3} value={description} disabled /></Col>
            </Row>
        </>
        )
    }
    getPredicateWithRelation = (relationId: number, data: ClassInfo): RelationData | null => {
        const { predicateList } = data;
        if (!predicateList) {
            return null;
        }
        let relationInfo: RelationData | null = null;
        let predicate: RelationPredicateData = { title: data.label };

        for (let i = 0; i < predicateList.length; i++) {
            if (predicateList[i].classRelationInfo && predicateList[i].classRelationInfo.id == relationId) {
                predicate.name = predicateList[i].name;
                predicate.label = predicateList[i].label;
                predicate.description = predicateList[i].description;
                predicate.multiplicityInfo = predicateList[i].multiplicityInfo;
                const inversePredicateInfo: Predicate = predicateList[i].classRelationInfo.inversePredicateInfo;
                let relationInfoData: RelationPredicateData = { title: predicateList[i].classRelationInfo.peerClass.label };
                const type: number = getRelationByLabel(predicateList[i].classRelationInfo.relationTypeInfo);

                relationInfo = { predicate, inverseRole: relationInfoData, type };
                if (inversePredicateInfo) {
                    relationInfoData.label = inversePredicateInfo.label;
                    relationInfoData.name = inversePredicateInfo.name;
                    relationInfoData.description = inversePredicateInfo.description;
                    relationInfoData.multiplicityInfo = inversePredicateInfo.multiplicityInfo;
                }
                return relationInfo;
            }
        }

        if (data.parentList) {
            for (let i = 0; i < data.parentList.length; i++) {
                relationInfo = this.getPredicateWithRelation(relationId, data.parentList[i].peerClass);
                if (relationInfo) {
                    return relationInfo;
                }
            }
        }
        return null;
    }
    renderTemplate(relationInfo:any,activeRelation:ActiveRelationInfo,card:ClassCard ): React.ReactElement {
        const { relationId, inverse } = activeRelation; 
        const { inverseRole: ir, predicate: p, type } = relationInfo
        const inverseRole = inverse ? p : ir;
        const predicate = inverse ? ir : p;

        return (
            <Form>
                {this.renderTitle(type, predicate.title, inverseRole.title, card.relationType)}
                <Form.Group as={Row} controlId="identifier">
                    <Col md={4}>
                        {this.renderLabels()}
                    </Col>
                    <Col md={4}>
                        {this.renderRelationContent(predicate)}
                    </Col>
                    <Col md={4}>
                        {this.renderRelationContent(inverseRole)}
                    </Col>
                </Form.Group>
            </Form>
        );
    }

    closeModal(status: ModalStatus, result: any) {
        // if (
        this.props.closeModal(status, result);
    }

    render() {
        const { card, activeRelation } = this.props;

        if (!card || !card.data || !activeRelation) {
            return <></>;
        }
         

        const { relationId, inverse } = activeRelation; 
         
        const relationInfo = this.getPredicateWithRelation(relationId, card.data);
        if (!relationInfo) {
            return <></>;
        }


        if(this.props.activeRelation){ 
            const relationMessageId = getRelationMessageById( relationInfo.type);
            relationId&&(this.title.values=  { name: <FormattedMessage id={relationMessageId}/> }) 
        }
         
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate( relationInfo,activeRelation,card)} closeModal={this.closeModal} />;
    }
}

export default connect((state: ApplicationState) => {
    return {
        card: state.profileeditor && state.profileeditor.card,
        activeRelation: state.profileeditor && state.profileeditor.activeRelation
    }
},
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
        return {

        }
    }
)(RelationViewerModal);