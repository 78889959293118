import { ThunkAction } from 'redux-thunk';
import * as Action from '../constants/modal';
import { ApplicationAction, ApplicationState } from '../types';
import { CancelCallback, CloseCallback, I18NString, ModalCallbacksRegistry, ModalOptions, ModalStatus, OkCallback, PortalCallback, SendCloseModal, SendOpenModal, SendUpdateModal, SendUpdateModalTitle } from '../types/modal';

/**This is hack: because we cannot pass callbacks to reducer (they are not serializable so we store them in global map) */
const modalCallbacksRegistry: ModalCallbacksRegistry = {};
export function getPortalCallback(id: string): PortalCallback | undefined {
    return modalCallbacksRegistry[id]?.portalCallback;
}

/*****************
 * Plain actions *
 *****************/
export function openModal(
    id: string,
    type: string,
    options: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback,
    portalCallback?: PortalCallback): SendOpenModal {
    modalCallbacksRegistry[id] = { okCallback, cancelCallback, closeCallback, portalCallback };
    return {
        type: Action.OPEN_MODAL,
        payload: { id, type, options }
    };
}

export function updateModal(id: string, options: ModalOptions): SendUpdateModal {
    return {
        type: Action.UPDATE_MODAL,
        payload: { id, options }
    };
}

export function updateModalTitle(id: string, title?: string | I18NString): SendUpdateModalTitle {
    return {
        type: Action.UPDATE_MODAL_TITLE,
        payload: { id, title }
    };
}

function plainCloseModal(id: string, status: ModalStatus): SendCloseModal {
    return {
        type: Action.CLOSE_MODAL,
        payload: { id, status }
    };
}

/***********
 * Actions *
 ***********/
export function closeModal(id: string, status: ModalStatus, result?: any, extraData?: any): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
    return async (dispatch, getState) => {
        const callbacks = modalCallbacksRegistry[id];
        if (callbacks) {
            switch (status) {
                case Action.MODAL_STATUS_OK:
                    if (callbacks.okCallback) {
                        const okResult = callbacks.okCallback(result, extraData);
                        if (okResult === false) {
                            return;
                        }
                    }
                    break;
                case Action.MODAL_STATUS_CANCEL:
                    if (callbacks.cancelCallback) {
                        callbacks.cancelCallback();
                    }
                    break;
                case Action.MODAL_STATUS_CLOSE:
                    if (callbacks.cancelCallback) {
                        callbacks.cancelCallback();
                    }
                    break;
            }
            delete modalCallbacksRegistry[id];
        }
        dispatch(plainCloseModal(id, status));
    }
}