import React from 'react'
import { connect } from 'react-redux';
import { ApplicationState } from '../../../types';
import { LayoutNode, Layout, isSubject } from '../../../types/subject';
import CardArea  from '../CardArea';
import { UI_GRID, UI_SPLITTER_ELEMENT, UI_SPLITTER_PANEL } from '../../../constants/subject';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Divider, DivPanel, Splitter } from '../../divider';
import Panel from './Panel';
import SubjectLink from '../inputs/SubjectLink';



export interface SplitterContainerProps {
    subjectKey: string
    nodeId: string
    layout?: Layout
    childrenIds?: string[]
    visible?: boolean
    nodes?: { [K: string]: LayoutNode }
}



class SplitterContainer extends React.Component<SplitterContainerProps> {

    render() {
        const { childrenIds, layout, visible, nodeId, nodes, subjectKey } = this.props
        const childrenCount = childrenIds?.length || 0
        if (!layout || !childrenIds || !visible || !nodes) {
            console.error('Splitter must have 2 children')
            return null;
        }

        const containerNode = nodes[nodeId]
        const orientation = containerNode?.options?.orientation || 'horizontal'

        const renderElement = (node: LayoutNode,key:string) => {
            switch (node.ui) {
                case UI_SPLITTER_PANEL:
                    return <DivPanel key={key} className="overflow-auto">
                        <CardArea subjectKey={this.props.subjectKey} nodeId={node.id} />
                    </DivPanel>
                case UI_SPLITTER_ELEMENT:
                    return <Splitter key={key} className={`m${orientation === 'horizontal' ? 'y' : 'x'}-2`} />
            }
        }

        return (
            <Divider orientation={orientation}>
                {childrenIds.map((chId, idx) =>  renderElement(nodes[chId],`${idx}`) )}
            </Divider>
        )
    }
}

export default connect(
    (state: ApplicationState, ownProps: { subjectKey: string, nodeId: string }) => {
        const subject = state.subject && state.subject.subjects[ownProps.subjectKey];
        if (!isSubject(subject)) {
            return null;
        }
        const childrenIds = subject && subject.childrenNodesById[ownProps.nodeId];
        const visible = subject.visibility[ownProps.nodeId] ? true : false;
        return {
            layout: subject,
            childrenIds: childrenIds,
            visible,
            nodes: subject.nodeById
        }
    })(SplitterContainer);