import React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { ApplicationAction, ApplicationState } from "../../types";
import { isLoggedInUser } from "../../types/security";
import styles from "./UnreadMessages.module.css";
interface UnreadMessagesProps {
  unread?: number;
  className?: string;
}
export class UnreadMessages extends React.Component<UnreadMessagesProps> {
  constructor(props: UnreadMessagesProps) {
    super(props);
  }

  render() {
    const { unread, className } = this.props;
    let unreadMessages = unread || 0;
    if (!unreadMessages) {
      return null;
    }

    return (
      <span
        className={`unread-messages badge badge-pill badge-danger ${className} ${styles.unreadBadge}`}
      >
        {unreadMessages > 99 ? "99+" : unreadMessages}
      </span>
    );
  }
}

const ConnectedUnreadMessages = connect((state: ApplicationState) => {
  if (isLoggedInUser(state.security.loginStatus)) {
    return {
      unread: state.security?.loginStatus?.messages?.unread,
    };
  }
  return {
    unread: 0,
  };
}, null)(UnreadMessages);

export default ConnectedUnreadMessages;
