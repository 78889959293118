import * as React from 'react';

import styles from '../Table.module.css';

export const FilterTabs: React.FunctionComponent = React.memo((props) => {
    return <div className={`w-100 d-flex ${styles.nptFilterTabs}`}>{props.children}</div>
});

interface FilterTabProps {
    tabName: string
    activeTab: string
    changeTab: (tab: string) => void
}
export const FilterTab: React.FunctionComponent<FilterTabProps> = React.memo((props) => {
    return <div
        className={`w-50 ${styles.nptFilterTab} ${props.activeTab === props.tabName ? styles.active : ""}`}
        onClick={props.changeTab.bind(null, props.tabName)}
    >
        {props.children}
    </div>
});

interface FilterTabContentProps {
    tabName: string
    activeTab: string
}
export const FilterTabContent: React.FunctionComponent<FilterTabContentProps> = React.memo((props) => {
    return <div className={`w-100 ${styles.nptFilterValues} ${styles.nptFilterTabContent} ${props.activeTab === props.tabName ? styles.active : ""}`}>
        {props.children}
    </div>
});