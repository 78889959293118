import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { MODAL_STATUS_OK } from '../../../constants/modal';
import { ApplicationAction, ApplicationState } from '../../../types';
import { ModalInfo, ModalStatus } from '../../../types/modal';
import { ClassInfo } from '../../../types/profile';
import ModalView from '../ModalView';



interface ClassCardEditModalProps {
    modal: ModalInfo,
    data?: ClassInfo,
    closeModal: (status: ModalStatus, result: any) => void
}

interface ClassCardEditModalState {
}

class ClassCardEditModal extends React.Component<ClassCardEditModalProps, ClassCardEditModalState> {

    title = { id: "CLASSCARD_EDIT_CLASS" };
    constructor(props: ClassCardEditModalProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    renderTemplate(): React.ReactElement {
        const { data } = this.props;
        if (!data  ) {
            return <></>;
        }



        return (
            <Form>
                <Form.Group as={Row} className="mb-1" controlId="package">
                    <Col md={3}>
                        <Form.Label>
                            <FormattedMessage id={"CLASSCARD_PACKAGE"} />
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Control disabled as="select">
                            <option>Пакет</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}  className="mb-1" controlId="namespace">
                    <Col md={3}>
                        <Form.Label>
                            <FormattedMessage id={"CLASSCARD_NAMESPACE"} />
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Control disabled as="select">
                            <option>npt</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}  className="mb-1" controlId="identifier">
                    <Col md={3}>
                        <Form.Label>
                            <FormattedMessage id={"CLASSCARD_NAME_OF_PREDICATE"} />
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Control value={"name"} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}  className="mb-1" controlId="label">
                    <Col md={3}>
                        <Form.Label>
                            <FormattedMessage id={"CLASSCARD_LABEL_OF_PREDICATE"} />
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Control value={"label"} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}  className="mb-1" controlId="parents">
                    <Col md={3}>
                        <Form.Label>
                            <FormattedMessage id={"CLASSCARD_PARENTS_CLASSES"} />
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Control disabled as="select">
                            <option>parent</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}  className="mb-1" controlId="stereotype">
                    <Col md={3}>
                        <Form.Label>
                            <FormattedMessage id={"CLASSCARD_STEREOTYPE"} />
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Control disabled as="select" >
                            <Form.Control disabled as="select">
                                <option>stereotype</option>
                            </Form.Control>
                        </Form.Control>
                    </Col>
                </Form.Group>
                 
                <Form.Group as={Row}  className="mb-1" controlId="description">
                    <Col md={3}>
                        <Form.Label>
                            <FormattedMessage id={"CLASSCARD_DESCRIPTION"} />
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Control as="select" >
                            <option>{"stereotype"}</option>
                        </Form.Control>
                    </Col>
                </Form.Group>                 
            </Form>
        );
    }

    closeModal(status: ModalStatus, result: any) {
        if (status == MODAL_STATUS_OK /*&& this.checkNewPassword() && this.checkConfirmPassword()*/) {
            // this.props.closeModal(status, { current: this.state.oldPassword, new: this.state.newPassword });
            return;
        }
        this.props.closeModal(status, result);
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}

export default connect((state: ApplicationState) => {
    return {        
        data: state.profileeditor && state.profileeditor.card && state.profileeditor.card.data
    }
},
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => { return {} })(ClassCardEditModal)