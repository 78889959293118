import React, { Children, cloneElement } from "react";
import { TransitionGroup } from "react-transition-group";
import styles from "./AlertContainer.module.css";
import AlertTransition from "./AlertTransition";

export const ENTER_TIMEOUT = 500;
export const EXIT_TIMEOUT = 300;

export type AlertPosition = "top-right" |
	"top-left" |
	"bottom-right" |
	"bottom-left"

export interface AlertContainerProperties {
	position?: AlertPosition
	children: any
}

function retrievePositionClass(position?: AlertPosition) {
	if (position === "top-right") {
		return styles.topRightPosition;
	} else if (position === "top-left") {
		return styles.topLeftPosition;
	} else if (position === "bottom-right") {
		return styles.bottomRightPosition;
	} else if (position === "bottom-left") {
		return styles.bottomLeftPosition;
	} else {
		return styles.bottomRightPosition;
	}
}
//Wraps Alert appending to it css animation
const AlertContainer = (props: AlertContainerProperties) => {
	const position = retrievePositionClass(props.position);
	return (
		<div className={`${styles.container} ${position}`}>
			<TransitionGroup>
				{Children.map(
					props.children,
					child =>
						child ? (
							<AlertTransition key={child.props.id}>
								{cloneElement(child)}
							</AlertTransition>
						) : null
				)}
			</TransitionGroup>
		</div>
	);
};

export default AlertContainer;