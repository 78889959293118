import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { changePassword, invokeAvatarHelp, sendMenuClosed, sendMenuMinimized, setupHomePage } from "../../actions/menu";
import { logout } from "../../actions/security";
import { ApplicationAction, ApplicationState } from "../../types";
import { isLoggedInUser } from "../../types/security";
import SideMenuLayout, { NavbarWrapper } from "../menu/SideMenuLayout";

export default connect(
  (state: ApplicationState) => {
    const status = isLoggedInUser(state.security.loginStatus) ? state.security.loginStatus : undefined;
    const options = status && status.options && status.options
    return {
      language: state.locale.language,
      userLabel: status && status.label || "",
      menu: status && status.developerMenu || undefined,
      systemMenu: status && status.systemMenu || undefined,
      iconsOnly: true,
      dark: false,
      closed: state.menu.closed['developerMenu'] == true,
      minimized: state.menu.minimized['developerMenu'] == true,
      uiOptions:options
    }
  },
  (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
    return {
      changePassword: () => {
        //thunk actions are not actions because they do not have type field.
        //So we need this ugly hack
        dispatch(changePassword() as any as AnyAction);
      },
      setupHomePage: () => {
        dispatch(setupHomePage() as any as AnyAction);
      },
      logout: () => {
        dispatch(logout() as any as AnyAction);
      },
      setupMinimize: (minimize: boolean) => {
        dispatch(sendMenuMinimized(minimize, 'developerMenu'))
      },
      setupClose: (close: boolean) => {
        dispatch(sendMenuClosed(close, 'developerMenu'))
      }
    }
  }
)(SideMenuLayout)


export const DeveloperNavbarWrapper = connect(
  (state: ApplicationState) => {
    return {
      className:"npt-navbar",
      userLabel: isLoggedInUser(state.security.loginStatus) ? state.security.loginStatus.label : "",
      closed: state.menu.closed['developerMenu'] == true,
      isAvatar:isLoggedInUser(state.security.loginStatus) ? state.security?.loginStatus?.avatar:false
     
    }
  },
  (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
    return {
      setupClose: (close: boolean) => {
        dispatch(sendMenuClosed(close, 'developerMenu'))
      }, 
      invokeAvatarHelp:()=>dispatch(invokeAvatarHelp() as any as AnyAction)
    }
  }
)(NavbarWrapper)