import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { CommonModalOptions } from "../../../../types/modal";
import NamedModal from "../../../helpers/NamedModal";
import { FileInfo } from "../../../../types/filearchive";

interface UploadedFileInfoModalProps {}
interface UploadedFileInfoModalState {}

export default class UploadedFileInfoModal extends React.Component<
  UploadedFileInfoModalProps,
  UploadedFileInfoModalState
> {
  static ID = "UploadedFileInfoModal";
  title = { id: "FILEARCHIVE_FILE_INFO_TITLE" };

  render() {
    return (
      <NamedModal modal={UploadedFileInfoModal.ID} title={this.title}>
        <UploadFileTemplate />
      </NamedModal>
    );
  }
}

interface UploadFileTemplateProps extends CommonModalOptions {
  fileInfo?: FileInfo;
}

const UploadFileTemplate: React.FC<UploadFileTemplateProps> = ({
  fileInfo,
}) => {
  if (!fileInfo) {
    return <></>;
  }
  const {
    contentLength,
    contentType,
    originalName,
    sha1,
    uploadTimestamp,
    storageUrl,
  } = fileInfo as any;
  return (
    <Container>
      <Row className="mb-2">
        <Col md={5}>
          <FormattedMessage id="FILEARCHIVE_ORIGINAL_FILE_NAME" />:
        </Col>
        <Col md={7}>{originalName}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <FormattedMessage id="FILEARCHIVE_STORAGE_URL" />:
        </Col>
        <Col md={7}>{storageUrl}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <FormattedMessage id="FILEARCHIVE_CONTENT_TYPE" />:
        </Col>
        <Col md={7}>{contentType}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <FormattedMessage id="FILEARCHIVE_SHA1" />:
        </Col>
        <Col md={7}>{sha1}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <FormattedMessage id="FILEARCHIVE_FILE_INFO_SIZE" />:
        </Col>
        <Col md={7}>{contentLength}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <FormattedMessage id="FILEARCHIVE_UPLOAD_TIMESTAMP" />:
        </Col>
        <Col md={7}>{moment(uploadTimestamp).format("L")}</Col>
      </Row>
    </Container>
  );
};
