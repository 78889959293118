import { joinParams } from '../actions/utils';

//Basic url for all ajax requests
export const BASE_FETCH_INFO_URL = "/rest/tree/header";
export const BASE_FETCH_URL = "/rest/tree/fetch";
export const BASE_ADD_URL = "/rest/tree/new";
export const BASE_PASTE_URL = "/rest/tree/paste";
export const BASE_PATH_URL = "/rest/tree/path";
export const BASE_SEARCH_URL = "/rest/tree/search";
export const BASE_OPTIONS_URL = "/rest/tree/options";
export const BASE_ZIP_URL = "/rest/tree/zip";

function cleanParams(params) {
    if (!params) {
        return null;
    }
    for (let p in params) {
        if (typeof params[p] == "undefined" || params[p] == null) {
            delete (params[p]);
        }
    }
    return params;
}

export function composeUrl(baseUrl, treeId, parameters) {
    let url = treeId;
    if (!url.startsWith("/")) {
        url = "/" + url;
    }
    let params = "";
    if (parameters) {
        params = "?" + joinParams(parameters);
    }
    return `${baseUrl}${url}${params}`;
}

export function composeFetchInfoUrl(treeId) {
    let url = composeUrl(BASE_FETCH_INFO_URL, treeId);
    return `${url}`;
}

export function composeFetchUrl(treeId, id, params) {
    let url = composeUrl(BASE_FETCH_URL, treeId);
    let urlParams = cleanParams(Object.assign({ node: id }, params));
    return `${url}?${joinParams(urlParams)}`;
}

export function composeFetchPathUrl(treeId, rdfId, namespace) {
    const url = composeUrl(BASE_PATH_URL, treeId);
    let params = { rdfId };
    if (namespace) {
        params.namespace = namespace;
    }
    return `${url}?${joinParams(params)}`;
}

export function composeAddUrl(treeId, id, addId, params) {
    let url = composeUrl(BASE_ADD_URL, treeId);
    let urlParams = cleanParams(Object.assign({ node: id, add: addId }, params));
    return `${url}?${joinParams(urlParams)}`;
}

export function composePasteUrl(treeId, id, sourceId, copy, lockIgnore) {
    let url = composeUrl(BASE_PASTE_URL, treeId);
    let p = { target: id, source: sourceId, copy, force: lockIgnore };
    let params = joinParams(p);
    return `${url}?${params}`;
}

export function composeZipUrl(treeId, id, lockIgnore) {
    let url = composeUrl(BASE_ZIP_URL, treeId);
    let p = { source: id, force: lockIgnore };
    let params = joinParams(p);
    return `${url}?${params}`;
}

export function getBasename(path) {
    let idx = path.lastIndexOf('/');
    return path.substring(0, idx);
}

export function getFilename(path) {
    let idx = path.lastIndexOf('/');
    return path.substring(idx + 1);
}