import {
  faArrowCircleLeft,
  faCaretLeft,
  faCaretRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import * as React from "react";
import Arrow from "./Arrow";
import styles from "./Calendar.module.css";
import CalendarCell from "./CalendarCell";
import Select, { ValueType } from "react-select";
import calendar, {
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  isDate,
  isSameDay,
  isSameMonth,
} from "./helpers/calendar";
import {
  MSG_SELECT_LOADING,
  MSG_SELECT_PLACEHOLDER,
  MSG_SELECT_NO_RESULTS,
} from "../messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SelectOption {
  value: number | null;
  label: string;
}
interface YearSelectorProps {
  year?: number;
  valid?: boolean;
  disabled?: boolean;
  changeYear: (year: number | null) => void;
}

export const YearSelector: React.FC<YearSelectorProps> = ({
  year,
  disabled,
  valid,
  changeYear,
}) => {
  console.log("selector", year);
  const [years, setYears] = React.useState<SelectOption[]>([]);
  const defaultValue = { label: `Выберите год`, value: null };
  React.useEffect(() => {
    const years = [];
    years.push(defaultValue);
    for (let i = 1900; i < 2100; i++) {
      years.push({ label: `${i}`, value: i });
    }
    setYears(years);
  }, []);
  //   React.useEffect(()=>{

  //   },[year])
  const yearRef = React.useRef(null);
  const prefix = "CalendarYear";
  const onMenuOpen = (ref: React.RefObject<any>, prefix: string) => () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        `${prefix}__option--is-selected`
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ block: "nearest", inline: "start" });
      }
    }, 15);
  };
  //   const selectedValue: SelectOption = { label: `${year}`, value: year };
  const getSelected = () => {
    if (!year) {
      return defaultValue;
    }
    return { label: `${year}`, value: year };
  };
  const changeYearHandler = (option: ValueType<SelectOption, false>) => {
    const year = (option as SelectOption).value;
    changeYear(year);
  };
  return (
    <Select
      ref={yearRef}
      isDisabled={disabled}
      searchable="true"
      loadingPlaceholder={MSG_SELECT_LOADING}
      placeholder={MSG_SELECT_PLACEHOLDER}
      noResultsText={MSG_SELECT_NO_RESULTS}
      menuShouldScrollIntoView={true}
      simpleValue={true}
      onMenuOpen={onMenuOpen(yearRef, prefix)}
      classNamePrefix={prefix}
      maxMenuHeight={200}
      value={getSelected()}
      onChange={changeYearHandler}
      className={`minified-react-select card-input npt-select flex-fill ${
        valid ? "is-invalid" : ""
      }`}
      options={years}
    ></Select>
  );
};
