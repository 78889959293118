import * as constants from '../constants/scm';
import { FetchError } from './error';
import { Action } from 'redux';

export interface SCMVersion {
    branch: string
    hash: string
    timestamp: string
    version: string
}

export interface TmMetadata {
    address: string
    description: string
}

export interface PmMetadata {
    serverLabel: string
    serverUuid: string
    SCMLabel: string
    SCMUuid: string
    licenseIsValid: boolean
    tm?: TmMetadata[]
}

export interface SCMInformation {
    cluster: boolean
    homeDir: string
    homeSource: string
    needsDbConfigure: boolean
    version: SCMVersion
    pm?: PmMetadata
}

///////////
//Actions//
///////////
export interface SendSCMInfo extends Action {
    type: constants.SEND_SCM_INFO
    payload: {
        info: SCMInformation
    }
}

export interface SendSCMInfoError extends Action {
    type: constants.SEND_SCM_INFO_ERROR
    payload: {
        error: FetchError
    }
}

export interface SendSCMInfoLoading extends Action {
    type: constants.SEND_SCM_INFO_LOADING
}

export type SCMAction =
    SendSCMInfo |
    SendSCMInfoError |
    SendSCMInfoLoading;

/////////
//State//
/////////
export interface SCMState {
    loading?: boolean
    info?: SCMInformation | FetchError
}

export function isSCMInformation(info: SCMInformation | FetchError | undefined): info is SCMInformation {
    return typeof info != 'undefined' &&
        typeof (info as SCMInformation).homeDir == 'string' &&
        typeof (info as SCMInformation).homeSource == 'string' &&
        typeof (info as SCMInformation).needsDbConfigure == 'boolean';
}

export function isFetchError(info: SCMInformation | FetchError | undefined): info is FetchError {
    return typeof info != 'undefined' && typeof (info as FetchError).message == 'string';
}