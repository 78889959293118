import React from "react";
import { connect } from "react-redux";
import { ApplicationState, ApplicationAction } from "../../../types";
import Tabs, { TabsProps } from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Layout, isSubject, LayoutNode } from "../../../types/subject";
import CardArea from "../CardArea";
import { UI_TAB } from "../../../constants/subject";
import styles from "./TabNav.module.css";
import { ThunkDispatch } from "redux-thunk";
import { sendSubjectChangeTab } from "../../../actions/subject";
import { isCardEdit, isEditable } from "../../../services/layout";

export interface TabNavProps {
  subjectKey: string;
  nodeId: string;
  layout?: Layout;
  childrenIds?: string[];
  activeKey?: string;
  errorTabId?: string;
  node?: LayoutNode;
  editable?: boolean;
  visible?: boolean;
  visibility?: { [ID: string]: boolean };
  changeTab: (tabId: string | null) => void;
}

class TabNav extends React.Component<TabNavProps> {
  componentDidMount() {}
  render() {
    const { visibility, node } = this.props;
    if (!this.props.layout || !this.props.childrenIds || !this.props.visible) {
      return null;
    }
    const { changeTab, activeKey, errorTabId, editable } = this.props;
    const tabs = [];
    const tabsProps: TabsProps = {
      id: this.props.subjectKey + "." + this.props.nodeId,
    };
    for (let nodeId of this.props.childrenIds) {
      const node = this.props.layout.nodeById[nodeId];
      const nodeVisible = visibility?.[nodeId];
      if (!node || node.ui != UI_TAB || node.hidden || nodeVisible === false) {
        continue;
      }
      if (!tabsProps.defaultActiveKey) {
        tabsProps.defaultActiveKey = node.id;
      }
      // console.log('nodeId tab ----',nodeId,visibility?.[nodeId])
      const errorTab =
        errorTabId === nodeId && editable ? "bg-warning text-white" : "";

      tabs.push(
        <Tab
          mountOnEnter={node?.options?.mount || undefined}
          unmountOnExit={node?.options?.mount || undefined}
          key={nodeId}
          eventKey={nodeId}
          title={<strong>{node.label}</strong>}
          className="card-tab-content  "
          tabClassName={errorTab}
        >
          <CardArea subjectKey={this.props.subjectKey} nodeId={nodeId} />
        </Tab>
      );
    }
    return (
      <Tabs
        activeKey={activeKey}
        onSelect={(k: string | null) => changeTab(k)}
        {...tabsProps}
        className={styles.tabs}
      >
        {tabs}
      </Tabs>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const { nodeId, subjectKey } = ownProps;
    if (!state.subject) {
      return null;
    }
    const subject = state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return null;
    }
    const childrenIds = subject && subject.childrenNodesById[ownProps.nodeId];

    const tabs = state.subject.tabs;
    const tab = tabs && tabs[subjectKey];
    const activeKey = (tab && tab[nodeId] && tab[nodeId].active) || undefined;
    const node = subject.nodeById[nodeId];
    const valueId = (node.options && node.options.src) || nodeId; //Support for values from other other sources

    const errorTabId = childrenIds.find((cId) => {
      return subject.validation[cId];
    });
    const editCard = isCardEdit(subject);
    const editable = isEditable(subject, nodeId, editCard);
    const visible = subject.visibility[ownProps.nodeId] ? true : false;
    return {
      layout: subject,
      childrenIds,
      activeKey,
      errorTabId,
      editable,
      node,
      visible,
      visibility: subject.visibility,
    };
  },
  (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const { subjectKey, nodeId } = ownProps;
    return {
      changeTab: (tabId: string | null) =>
        tabId && dispatch(sendSubjectChangeTab(subjectKey, nodeId, tabId)),
    };
  }
)(TabNav);
