import React from "react";
import { Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FormattedMessage } from "react-intl";
import { I18NString } from "../../../types/modal";
import { LayoutNode, SubjectComment } from "../../../types/subject";

import { Comment } from "./BasicInput";

import styles from "../boxes/Panel.module.css";

export interface ToolbarInputProps {
  id: string;
  node: LayoutNode;
  children: any;
  visible: boolean;
  hideLabel?: boolean;
  error?: string | I18NString;
  editable?: boolean;
  cardEditable?: boolean;
  comment?: SubjectComment;
}

export default class ToolbarInput extends React.Component<ToolbarInputProps> {
  getMandatory() {
    if (this.props.node.mandatory) {
      return <span className="text-danger">*</span>;
    }
    return null;
  }

  renderError() {
    const { error } = this.props;
    if (!error || typeof error === "boolean") {
      return null;
    }
    return (
      <Form.Text className="text-danger">
        {typeof error === "string" ? (
          error
        ) : (
          <FormattedMessage id={error.id} values={error.values} />
        )}
      </Form.Text>
    );
  }

  render() {
    if (!this.props.visible) {
      return null;
    }
    if (this.props.hideLabel) {
      return this.props.children;
    }
    const isErrorDisplayed = this.props.editable && this.props.error;
    const cardClassName = `mb-1 border ${
      isErrorDisplayed ? "border-danger" : "border-primary"
    } npt-toolbar-input`;
    const headerClassName = `${
      isErrorDisplayed ? "bg-danger " : "bg-primary"
    } font-weight-bold px-1 card-title clearfix text-white`;
    return (
      <div className="card-row npt-objectcard-toolbar-input">
        <Card className={cardClassName}>
          <Card.Header className={headerClassName}>
            {this.props.node.label} {this.getMandatory()}
            {this.props.children[0]}
          </Card.Header>
          <Card.Body className={styles.body}>
            {this.props.children[1]}
            <div>{isErrorDisplayed && this.renderError()}</div>
            <Comment
              className="w-100 text-center"
              value={this.props.comment}
              editable={this.props.editable}
              cardEditable={this.props.cardEditable}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}
