import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import {
    FinderClassesStore,
    FinderClassLevel,
    FinderFragmentLevel,
    FinderFragmentsStore,
    FinderData,
    FinderOptions
} from '../../types/finder';
import { MSG_SELECT_PLACEHOLDER } from '../messages';

import styles from './Finder.module.css';

function deleteKeysFromArray(array: any[], valueArray: any) {
    valueArray = valueArray.slice();
    for (let i = array.length - 1; i >= 0; --i) {
        for (let j = valueArray.length - 1; j >= 0; --j) {
            if (array[i] == valueArray[j]) {
                array.splice(i, 1);
                valueArray.splice(j, 1);
                break;
            }
        }
        if (valueArray.length == 0) {
            return;
        }
    }
}

function getSelectOptions(normalizedArray: { byId: any }, localSelection: string[]) {
    let options: { value: string, label: string }[] = [];
    localSelection.map(function (fragmentId, index) {
        let object = normalizedArray.byId[fragmentId];
        options.push({ value: object.id, label: object.label })
    });
    return options;
}

interface FinderSideBarProps {
    isHidden: boolean
    isManual?: boolean
    loadedFragments: FinderFragmentsStore
    loadedClasses: FinderClassesStore
    finderOptions: FinderOptions
    finderData: FinderData
    finderDataChanges: FinderData | null
    fetchFragments: (fragmentsIds: string[] | null, types: string | string[]) => void
    selectFragment: (oldFragmentId: string | null, newFragmentId: string | null, force?: boolean) => void
    fetchClasses: (parentClassId: string) => void
    selectClass: (oldClassId: string | null, newClassId: string | null, classLevelIdx: number) => void
    sendHidden: (value?: boolean) => void
}

/****************************
 * Finder SideBar Component *
 ****************************/
const FinderSideBar: React.FunctionComponent<FinderSideBarProps> = React.memo((props) => {
    const isHidden = props.isHidden;
    if (isHidden) {
        return null;
    }

    const isFragmentTreeVisible = props.finderOptions.fragmentTree && !props.finderOptions.fragmentTree?.hidden;
    const isClassTreeVisible = props.finderOptions.classTree && !props.finderOptions.classTree?.hidden;

    if (!isFragmentTreeVisible && !isClassTreeVisible) {
        return null;
    }

    const finderActualData = props.finderDataChanges || props.finderData;

    const minimize = props.isManual || props.finderOptions.criteriaTree ? null : props.sendHidden;

    return <div className='d-flex flex-column col-md-2 h-100 p-0' style={{ overflowY: "auto" }}>
        {isFragmentTreeVisible && <FragmentLevels
            loadedFragments={props.loadedFragments}
            fragmentLevels={finderActualData.sideBar.fragmentLevels}
            fetchFragments={props.fetchFragments}
            selectFragment={props.selectFragment}
            minimize={minimize}
        />}
        {isClassTreeVisible && <ClassLevels
            loadedClasses={props.loadedClasses}
            classLevels={finderActualData.sideBar.classLevels}
            fetchClasses={props.fetchClasses}
            selectClass={props.selectClass}
            minimize={minimize}
        />}
    </div>;
});

interface FragmentLevelsProps {
    loadedFragments: FinderFragmentsStore
    fragmentLevels: FinderFragmentLevel[]
    fetchFragments: (fragmentsIds: string[] | null, types: string | string[]) => void
    selectFragment: (oldFragmentId: string | null, newFragmentId: string | null, force?: boolean) => void
    minimize: null | (() => void)
}
const FragmentLevels: React.FunctionComponent<FragmentLevelsProps> = React.memo((props) => {
    if (!props.fragmentLevels) {
        return null;
    }
    return <div className={`card ${styles.sideBarCard} npt-finder-sidebar-card`}>
        <div className={`card-header ${styles.tableHeading}`}>
            <h6 className="card-title font-weight-bold">
                <FormattedMessage
                    id="FINDER_SELECTION_AREA"
                    defaultMessage="Selection area"
                    description="Selection area" />
            </h6>
            {props.minimize ? <span className={`fa fa-window-minimize ${styles.toggleButton}`} onClick={props.minimize}>&nbsp;</span> : null}
        </div>
        <div className='card-body panel-bar'>
            {props.fragmentLevels?.map((level, index) => <FragmentLevelSelection
                key={index}
                loadedFragments={props.loadedFragments}
                fragmentLevel={level}
                parentLevelSelected={index == 0 ? null : props.fragmentLevels[index - 1].selected}
                fetchFragments={props.fetchFragments}
                selectFragment={props.selectFragment}
            />)}
        </div>
    </div>
});

interface FragmentLevelSelectionProps {
    loadedFragments: FinderFragmentsStore
    fragmentLevel: FinderFragmentLevel
    parentLevelSelected: string[] | null
    fetchFragments: (fragmentsIds: string[] | null, types: string | string[]) => void
    selectFragment: (oldFragmentId: string | null, newFragmentId: string | null, force?: boolean) => void
}
const FragmentLevelSelection: React.FunctionComponent<FragmentLevelSelectionProps> = React.memo((props) => {
    let selection: string[] = [];
    const selectedFragments = props.fragmentLevel.selected;
    if (props.parentLevelSelected == null) {
        /* Possible only for OST selection */
        selection = props.loadedFragments.rootIds.slice();
    } else {
        for (let parentId of props.parentLevelSelected) {
            selection = selection.concat(props.loadedFragments.children[parentId]);
        }
    }

    /* Remove already selected ids from selection array */
    deleteKeysFromArray(selection, selectedFragments);

    /* Check if all dependencies was downloaded */
    let isLoading = false;
    let isFetched = true;
    if (props.parentLevelSelected == null) {
        isLoading = props.loadedFragments.loading["null"];
        isFetched = props.loadedFragments.fetched["null"];
    } else {
        for (let parentId of props.parentLevelSelected) {
            if (props.loadedFragments.loading[parentId]) {
                isLoading = true;
                isFetched = false;
                break;
            }
            if (!props.loadedFragments.fetched[parentId] && isFetched) {
                isFetched = false;
            }
        }
    }

    React.useEffect(() => {
        if (!props.fragmentLevel.autofill || isLoading) {
            return;
        }
        if (!isFetched) {
            props.fetchFragments(props.parentLevelSelected, props.fragmentLevel.types);
            return;
        }
        if (selection.length !== 0) {
            props.selectFragment(null, selection[0], true);
        }
    }, [props.fragmentLevel, isLoading, isFetched, selection.length]);

    const changeFragment = (fragmentId: string | null) => {
        if (!fragmentId) {
            /* Happens when user use backspace on empty search */
            return;
        }
        props.selectFragment(null, fragmentId);
    }

    /* Check if selection is enabled */
    let selectionEnabled = selection.length != 0 || isLoading || !isFetched;

    let className = `col-md-12 input-group ${styles.sideBarFragment}`;

    if (props.fragmentLevel.hidden) {
        return null;
    }

    return <div className={`col-md-12 ${styles.sideBarFragmentContainer}`}>
        <label>
            <b>
                {props.fragmentLevel.name}
            </b>
        </label>
        <SelectionLevel
            normalizedObjects={props.loadedFragments}
            className={className}
            isLoading={isLoading}
            isFetched={isFetched}
            isDisabled={!selectionEnabled}
            removeDisabled={props.fragmentLevel.autofill}
            selection={selection}
            selectedObjects={selectedFragments}
            changeHandler={changeFragment}
            selectHandler={props.selectFragment}
            openHandler={props.fetchFragments.bind(this, props.parentLevelSelected, props.fragmentLevel.types)}
        />
    </div>
});

interface ClassLevelsProps {
    loadedClasses: FinderClassesStore
    classLevels: FinderClassLevel[]
    fetchClasses: (parentClassId: string) => void
    selectClass: (oldClassId: string | null, newClassId: string | null, classLevelIdx: number) => void
    minimize: null | (() => void)
}
const ClassLevels: React.FunctionComponent<ClassLevelsProps> = React.memo((props) => {
    if (!props.classLevels || props.classLevels.length === 0) {
        return null;
    }
    return <>
        {props.classLevels.map((level, index) => <ClassLevelsCard
            key={index}
            loadedClasses={props.loadedClasses}
            classLevel={level}
            classLevelIdx={index}
            fetchClasses={props.fetchClasses}
            selectClass={props.selectClass}
            minimize={props.minimize}
        />)}
    </>
});

interface ClassLevelsCardProps {
    loadedClasses: FinderClassesStore
    classLevel: FinderClassLevel
    classLevelIdx: number
    fetchClasses: (parentClassId: string) => void
    selectClass: (oldClassId: string | null, newClassId: string | null, classLevelIdx: number) => void
    minimize: null | (() => void)
}
const ClassLevelsCard: React.FunctionComponent<ClassLevelsCardProps> = React.memo((props) => {
    return <div className={`card ${styles.sideBarCard} npt-finder-sidebar-card`}>
        <div className={`card-header ${styles.tableHeading}`}>
            <h6 className="card-title font-weight-bold">
                <span>{props.classLevel.name}</span>
            </h6>
            {props.minimize ? <span className={`fa fa-window-minimize ${styles.toggleButton}`} onClick={props.minimize}>&nbsp;</span> : null}
        </div>
        <div className='card-body panel-bar'>
            <div className='col-md-12'>
                <ClassLevelSelection
                    loadedClasses={props.loadedClasses}
                    classLevel={props.classLevel}
                    classLevelIdx={props.classLevelIdx}
                    fetchClasses={props.fetchClasses}
                    selectClass={props.selectClass}
                />
            </div>
        </div>
    </div>
});

interface ClassLevelSelectionProps {
    loadedClasses: FinderClassesStore
    classLevel: FinderClassLevel
    classLevelIdx: number
    fetchClasses: (parentClassId: string) => void
    selectClass: (oldClassId: string | null, newClassId: string | null, classLevelIdx: number) => void
}
const ClassLevelSelection: React.FunctionComponent<ClassLevelSelectionProps> = React.memo((props) => {

    let selection = props.loadedClasses.selection[props.classLevel.id]?.slice() || [];
    let selectedClasses = props.classLevel.selected;

    /* Remove already selected ids from selection array */
    deleteKeysFromArray(selection, selectedClasses);

    /* Check if all dependencies was downloaded */
    let isLoading = props.loadedClasses.loading[props.classLevel.id];
    let isFetched = props.loadedClasses.fetched[props.classLevel.id];
    /* Check if selection is enabled */
    let selectionEnabled = selection.length != 0 || isLoading || !isFetched;

    let className = `col-md-12 input-group ${styles.sideBarClass}`;

    const changeClass = (classId: string | null) => {
        if (!classId) {
            /* Happens when user use backspace on empty search */
            return;
        }
        props.selectClass(null, classId, props.classLevelIdx);
    }

    const selectClass = (oldClassId: string | null, newClassId: string | null) => {
        props.selectClass(oldClassId, newClassId, props.classLevelIdx);
    }

    return <SelectionLevel
        normalizedObjects={props.loadedClasses}
        className={className}
        isLoading={isLoading}
        isFetched={isFetched}
        isDisabled={!selectionEnabled}
        removeDisabled={false}
        selection={selection}
        selectedObjects={selectedClasses}
        changeHandler={changeClass}
        selectHandler={selectClass}
        openHandler={props.fetchClasses.bind(this, props.classLevel.id)}
    />
});


interface SelectionRowProps {
    normalizedObjects: { byId: { [k: string]: any } }
    className: string
    isLoading: boolean
    isFetched: boolean
    isDisabled: boolean
    removeDisabled: boolean
    selection: string[]
    selectedObjects: string[]
    changeHandler: (fragmentId: string | null) => void
    selectHandler: (oldClassId: string | null, newClassId: string | null) => void
    openHandler: () => void
}
const SelectionLevel: React.FunctionComponent<SelectionRowProps> = React.memo((props) => {
    return <div className="col-md-12 p-0">
        <Selection
            normalizedObjects={props.normalizedObjects}
            objectId={null}
            isDisabled={props.isDisabled}
            isLoading={props.isLoading}
            isFetched={props.isFetched}
            selection={props.selection}
            className={props.className}
            isSingle={props.selectedObjects.length == 0}
            changeHandler={props.changeHandler}
            openHandler={props.openHandler}
        />
        {props.selectedObjects.map((objectId, index) => <SelectedObject
            key={index}
            normalizedObjects={props.normalizedObjects}
            objectId={objectId}
            isDisabled={props.removeDisabled}
            selectHandler={props.selectHandler}
        />
        )}
    </div >
});

interface SelectionProps {
    normalizedObjects: { byId: { [k: string]: any } }
    objectId: string | null
    isDisabled: boolean
    isLoading: boolean
    isFetched: boolean
    selection: string[]
    className: string
    isSingle: boolean
    changeHandler: (fragmentId: string | null) => void
    openHandler: () => void
}
const Selection: React.FunctionComponent<SelectionProps> = React.memo((props) => {
    const intl = useIntl();
    if (props.isDisabled) {
        return <div className="col-md-12 input-group">
            <Select
                name="select"
                className="minified-react-select npt-select w-100"
                loadingMessage={() => intl.formatMessage({ id: "MSG_SELECT_LOADING" })}
                placeholder={MSG_SELECT_PLACEHOLDER}
                noOptionsMessage={() => intl.formatMessage({ id: "MSG_SELECT_NO_RESULTS" })}
                value={null}
                isClearable={false}
                isDisabled={true}
            />
        </div>
    }
    let value = null;
    let localSelection = props.selection.slice();
    if (props.objectId) {
        localSelection.unshift(props.objectId);
    }
    const selectOptions = getSelectOptions(props.normalizedObjects, localSelection);

    if (props.objectId) {
        value = selectOptions[0];
    }

    const changeHandler = (option?: { value: string, label: string } | null) => {
        if (!option) {
            props.changeHandler(null);
            return;
        }
        props.changeHandler(option.value);
    }

    return <div className={props.className}>
        <Select
            name="select"
            className="minified-react-select npt-select w-100"
            loadingMessage={() => intl.formatMessage({ id: "MSG_SELECT_LOADING" })}
            placeholder={MSG_SELECT_PLACEHOLDER}
            noOptionsMessage={() => intl.formatMessage({ id: "MSG_SELECT_NO_RESULTS" })}
            isLoading={props.isLoading}
            onMenuOpen={!props.isFetched ? props.openHandler : undefined}
            value={value}
            options={selectOptions}
            onChange={changeHandler}
            isClearable={false}
        />
    </div>
});

interface SelectedObjectProps {
    normalizedObjects: { byId: { [k: string]: any } }
    objectId: string
    isDisabled: boolean
    selectHandler: (oldObjectId: string | null, newObjectId: string | null) => void
}
const SelectedObject: React.FunctionComponent<SelectedObjectProps> = React.memo((props) => {
    const object = props.normalizedObjects.byId[props.objectId];
    return <div className={`col-md-12 input-group input-group-sm ${styles.sideBarFragment}`}>
        <input type="text" className="form-control card-input" disabled={true} value={object.label} />
        {!props.isDisabled && <span className="input-group-append">
            <button
                className={`btn btn-secondary btn-xs card-input ${styles.sideBarFragmentButton} h-100`}
                type="button"
                onClick={() => props.selectHandler(props.objectId, null)}>
                <i className="fa fa-times" aria-hidden="true"></i>
            </button>
        </span>}
    </div>
});

export default FinderSideBar;