import React, { FC } from "react";
import styles from "./FullScreenWrapper.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
interface FullScreenWrapperProps {
  on: boolean;
  toggle?: (on: boolean) => void;
  children: any;
}

export const FullScreenWrapper: FC<FullScreenWrapperProps> = ({
  on,
  toggle,
  children,
}) => {
  const close = () => {
    if (!toggle) {
      return;
    }
    toggle(false);
  };
  if (!on) {
    return children;
  }
  const renderCloseButton = () => {
    return (
      <div
        onClick={close}
        className={`${styles.close} position-absolute p-1 rounded bg-white border mt-1`}
      >
        <span className="mr-2">Закрыть</span>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    );
  };
  return (
    <div className={`position-fixed ${styles.wrapper} bg-white pt-4   `}>
      {children}
    </div>
  );
};
