import * as React from 'react';
import { Link as ReactLink } from 'react-router-dom';

import styles from "./Navigation.module.css";

const renderMark = (num: number) => {
    return (
        <div className={styles.markBorder}>
            <div className={`${styles.markPulse}  font-weight-bold`}  >{num}</div>
        </div>
    )
}

interface NavigationLinkProps {
    label: string
    href: string
    icon?: string
    inset?: boolean
    altKeyNumber?: number
    isIconsExists?: boolean
    allowedLinks: { [href: string]: boolean }
}
interface NavigationLinkState {
    showMark: boolean
}

export default class NavigationLink extends React.Component<NavigationLinkProps, NavigationLinkState> {
    state = { showMark: false }


    keyDownHandler = (e: any) => {
        if (e.key === "Alt") {
            e.preventDefault()
        }
        const { altKeyNumber, href } = this.props;
        if (!altKeyNumber || (altKeyNumber < 0 || altKeyNumber > 10)) {
            console.error('altKey must in [0 , 9] range')
            return
        }
        if (e.altKey) {
            this.setState({ showMark: true })
            const n = 48 + (+altKeyNumber)
            if (e.keyCode === n) { //Ctrl + F
                console.log('alt', href)
                // this.setState({ showMark: false })
                const link = document.createElement("a");
                link.href = href
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }
        }
    }

    keyUpHandler = (e: any) => {
        this.setState({ showMark: false })
    }

    componentDidMount() {
        if (!this.props.altKeyNumber) {
            return
        }
        document.addEventListener('keydown', this.keyDownHandler)
        document.addEventListener('keyup', this.keyUpHandler)
    }
    componentWillUnmount() {
        if (!this.props.altKeyNumber) {
            return
        }
        document.removeEventListener('keydown', this.keyDownHandler)
        document.removeEventListener('keyup', this.keyUpHandler)
    }

    render() {
        if (!this.props.allowedLinks[this.props.href]) {
            return null;
        }

        const { showMark } = this.state
        const { altKeyNumber, isIconsExists } = this.props
        if (this.props.inset) {
            return <InsetLink
                label={this.props.label}
                href={this.props.href}
                icon={this.props.icon}
                showMark={showMark}
                altKey={altKeyNumber}
                isIconsExists={isIconsExists}
            />;
        }
        return <Link
            label={this.props.label}
            href={this.props.href}
            icon={this.props.icon}
            showMark={showMark}
            altKey={altKeyNumber}
            isIconsExists={isIconsExists}
        />;
    }
}

interface LinkProps {
    label: string
    href: string
    icon?: string
    showMark?: boolean
    altKey?: number
    isIconsExists?: boolean
}
class Link extends React.PureComponent<LinkProps> {
    renderIcon() {
        const { isIconsExists } = this.props
        if (isIconsExists && !this.props.icon) {
            return <i className={`fa fa-fw fa-map mt-1 mx-2 d-flex align-items-start`} style={{ opacity: 0 }} aria-hidden="true"></i>
        }

        if (this.props.icon) {
            return <i className={`fa fa-fw ${this.props.icon} mt-1 mx-2 d-flex align-items-start`} aria-hidden="true"></i>
        }

        return null
    }
    render() {
        const { altKey, showMark } = this.props
        return <div className="btn-group">
            <ReactLink className={`${styles.nptNavigationGroup} ${styles.nptNavigationLink} btn btn-light dropdown-link d-flex justify-content-left position-relative`} to={this.props.href || ""}>
                {typeof altKey !== 'undefined' && showMark && renderMark(altKey)}
                <div className="d-flex   flex-fill">
                    {this.renderIcon()}
                    <div className="ml-1 strong d-flex flex-fill align-items-left">{this.props.label}</div>
                </div>
            </ReactLink>
        </div>
    }
};

interface InsetLinkProps {
    label: string
    href: string
    icon?: string
    showMark?: boolean
    altKey?: number
    isIconsExists?: boolean
}
class InsetLink extends React.PureComponent<InsetLinkProps> {
    renderIcon() {
        const { isIconsExists } = this.props
        if (isIconsExists && !this.props.icon) {
            return <i className={`fa fa-fw fa-map mt-1 mx-2 d-flex align-items-start`} style={{ opacity: 0 }} aria-hidden="true"></i>
        }

        if (this.props.icon) {
            return <i className={`fa fa-fw ${this.props.icon} mt-1 mx-2 d-flex align-items-start`} aria-hidden="true"></i>
        }

        return null
    }
    render() {
        const { altKey, showMark } = this.props
        return <ReactLink className={`${styles.nptNavigationLink} btn dropdown-link d-flex justify-content-left psition-relative`} to={this.props.href}>
            {typeof altKey !== 'undefined' && showMark && renderMark(altKey)}
            <div className="d-flex   flex-fill">
                {this.renderIcon()}
                <div className="ml-1 strong d-flex flex-fill align-items-left">{this.props.label}</div>
            </div>
        </ReactLink>
    }
};