import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface UseDomainCheckboxGroupProps {
    checkBoxRef: React.MutableRefObject<HTMLInputElement | null>,
    onChange: (useDomain: boolean) => void
}

const UseDomainCheckboxGroup = (props: UseDomainCheckboxGroupProps) =>
    <div className="form-check">
        <input id="adCheckbox" name="ad"
            className="form-check-input"
            type="checkbox"
            defaultChecked={true}
            onChange={(evt) => props.onChange(evt.target.checked)}
            ref={props.checkBoxRef} />
        <label className="form-check-label" htmlFor="adCheckbox">
            <FormattedMessage
                id="LOGIN_PANEL_USE_DOMAIN_LABEL"
                defaultMessage="Use Active Directory"
                description="Label for use domain authorization" />
        </label>

    </div>;

export default UseDomainCheckboxGroup;