export const SEND_LOGIN = 'SEND_LOGIN';
export type SEND_LOGIN = typeof SEND_LOGIN;

export const SEND_LOGIN_LOADING = 'SEND_LOGIN_LOADING ';
export type SEND_LOGIN_LOADING = typeof SEND_LOGIN_LOADING;

export const SEND_LOGIN_ERROR = 'SEND_LOGIN_ERROR ';
export type SEND_LOGIN_ERROR = typeof SEND_LOGIN_ERROR;

export const SEND_LOGIN_CODE_REQUIRED = 'SEND_LOGIN_CODE_REQUIRED'
export type SEND_LOGIN_CODE_REQUIRED = typeof SEND_LOGIN_CODE_REQUIRED;

export const SEND_MESSAGE_STAT_UPDATE = 'SEND_MESSAGE_STAT_UPDATE'
export type SEND_MESSAGE_STAT_UPDATE = typeof SEND_MESSAGE_STAT_UPDATE;

