import * as React from 'react';
import { getChildrenLinks } from '../../services/navigation';
import { connect } from 'react-redux';
import styles from "./Navigation.module.css";
import { ApplicationState } from '../../types';
import { isLoggedInUser } from '../../types/security';

export const isAnyItemWithIconExists = (children:any) => { 
    if(children  ){
        if(Array.isArray(children)){
           return  children.some(ch=>!!ch?.props?.icon)
        }else{
            return !!children?.props?.icon
        }
    }
    return false
}

interface NavigationProps {
    allowedLinks: { [k: string]: boolean }
    className?:string
}
interface NavigationState {

}
class Navigation extends React.Component<NavigationProps, NavigationState> {

    constructor(props: NavigationProps) {
        super(props);
    }


    render() {
        const { allowedLinks, className } = this.props
        const isIconsExists = isAnyItemWithIconExists(this.props.children)
        console.log('icon exists', isIconsExists)
        return <div className="position-relative h-100">
            <div className={`${styles.nptNavigation} h-100 overflow-auto`}>
                {React.Children.map(
                    this.props.children,
                    (child, idx) => React.cloneElement(child as any, { key: idx, allowedLinks, isIconsExists })
                )}
            </div>
        </div>
    }
}


export default connect((state: ApplicationState) => {
    const menuNodes = isLoggedInUser(state.security.loginStatus) ? state.security.loginStatus.mainMenu?.itemById : null

    let allowedLinks: { [href: string]: boolean } = {}
    if (menuNodes) {
        for (let node of Object.values(menuNodes)) {
            node.r && (allowedLinks[node.r] = true)
        }
    }
    return {
        allowedLinks
    }
}, null)(Navigation);