import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

function dateFormatter(cell, row) {
    if (!cell) {
        return "";
    }
    return moment(cell).format('L');
}
export function booleanFormatter(cell, row) {
    if (!cell) {
        return "";
    }
    return <span>
        <FontAwesomeIcon className={`text-${cell ? 'success' : 'danger'}`} icon={cell ? faPlusCircle : faMinusCircle} />
    </span>
}

function dateTimeFormatter(cell, row) {
    if (!cell) {
        return "";
    }
    let m = moment(cell);
    return m.format('L') + ' ' + m.format('LTS');
}

function formatSingleFile(file, contextPath) {
    let filename = file.$originalName;

    if (typeof filename == 'undefined') {
        filename = file.$label;
    }

    if (file.$sha1) { //File is already on server 
        return (
            <a href={`/rest/file/download/${file.$sha1}`} download={filename}>
                {file.$label}
            </a>);
    }
    return file.$label;
}

function formatSingleFileForPrint(file) {
    let filename = file.$originalName;
    if (typeof filename == 'undefined') {
        filename = file.$label;
    }
    if (file.$sha1) { //File is already on server
        return (
            <div>
                {file.$label}
            </div>);
    }

    return file.$label;
}

function fileFormatter(cell, row, contextPath) {
    if (!cell) {
        return ''; //Field may be null
    }
    if (Array.isArray(cell)) {
        let spanList = [];

        for (let f of cell) {//For each file
            let key = f.$sha1;
            if (typeof key == 'undefined') {
                key = f.$uploadKey;
            }
            //Add file reference

            spanList.push(<span key={key}>
                {formatSingleFile(f, contextPath)}
            </span>);
            //Add separator
            spanList.push(<span key={key + "@separator"}> | </span>);
        }
        if (spanList.length != 0) {
            spanList.pop(); //remove last separator
        }
        return (<span>{spanList}</span>);
    } else if (typeof cell == 'object') {
        return formatSingleFile(cell, contextPath);
    }
    return null;
}

function fileFormatterForPrint(cell) {
    if (!cell) {
        return ''; //Field may be null
    }
    if (typeof cell == 'object') {
        return formatSingleFileForPrint(cell);
    } else if (Array.isArray(cell)) {
        let spanList = [];
        for (let f of cell) {//For each file
            let key = f.$sha1;
            if (typeof key == 'undefined') {
                key = f.$uploadKey;
            }
            //Add file reference
            spanList.push(<span key={key}>
                {formatSingleFileForPrint(f)}
            </span>);
            //Add separator
            spanList.push(<span key={key + "@separator"}> | </span>);
        }
        if (spanList.length != 0) {
            spanList.pop(); //remove last separator
        }
        return (<span>{spanList}</span>);
    }
    return null;
}

function fragmentFormatter(cell, row) {
    if (!cell) {
        return ''; //Field may be null
    }
    if (typeof cell == 'object') {
        return cell.$label;
    } else if (Array.isArray(cell)) {
        let spanList = [];
        for (let f of cell) {//For each file
            let key = f.$rdfId;
            //Add file reference
            spanList.push(<span key={key}>
                {cell.$label}
            </span>);
            //Add separator
            spanList.push(<span key={key + "@separator"}> | </span>);
        }
        if (spanList.length != 0) {
            spanList.pop(); //remove last separator
        }
        return (<span>{spanList}</span>);
    }
    return null;
}

function enumerationFormatter(cell, row) {

    if (!cell) {
        return ''; //Field may be null
    }
    if (Array.isArray(cell)) {
        let spanList = [];
        for (let en of cell) {
            let key = en.$rdfId;
            if (en.$namespace) {
                key = en.$namespace + ":" + key;
            }
            //Add enumeration value
            spanList.push(<span key={key}>{en.$label}</span>);
            //Add separator
            spanList.push(<span key={key + "@separator"}> | </span>);
        }
        if (spanList.length != 0) {
            spanList.pop(); //remove last separator
        }
        return (<span>{spanList}</span>);
    } else if (typeof cell == 'object') {
        return cell.$label;
    } else
        return null;
}

function formatSingleReference(ref, contextPath) {
    return (<Link target="_blank" to={`/objectcard/${ref.$rdfId}`}>{ref.$label}</Link>);
}

function referenceFormatter(cell, row, contextPath) {
    if (!cell) {
        return ''; //Field may be null
    }
    if (typeof cell == 'object') {
        return formatSingleReference(cell, contextPath);
    } else if (Array.isArray(cell)) {
        let spanList = [];
        for (let ref of cell) {//For each file
            let key = ref.$rdfId;
            //Add file reference
            spanList.push(<span key={key}>
                {formatSingleReference(ref, contextPath)}
            </span>);
            //Add separator
            spanList.push(<span key={key + "@separator"}> | </span>);
        }
        if (spanList.length != 0) {
            spanList.pop(); //remove last separator
        }
        return (<span>{spanList}</span>);
    }
    return null;
}

function refTableForPrintFormatter(cell, row) {
    if (!cell) {
        return []; //Field may be null
    }
    if (typeof cell == 'object') {
        return [{ value: cell.label, description: cell.description }];
    } else if (Array.isArray(cell)) {
        let refList = [];
        for (let entry of cell) {
            refList.push({ value: entry.label, description: entry.description })
        }
        return refList;
    }
    return null;
}

function fileOptions(field, info) {
    if (!info.print) {
        return {
            dataFormat: fileFormatter,
            formatExtraData: info.contextPath
        }
    }
    if (field.ui) {
        /* In printcard input-file is showing as table */
        return null;
    }
    return {
        dataFormat: fileFormatterForPrint,
        formatExtraData: info.contextPath
    }
}

function fragmentOptions(field) {
    return {
        dataFormat: fragmentFormatter
    }
}

function enumerationOptions(field) {
    return {
        dataFormat: enumerationFormatter,
        dataSort: true
    }
}

function refTableForPrintOptions(field) {
    return {
        dataFormat: refTableForPrintFormatter,
        dataSort: true
    }
}

function referenceOptions(field, info) {
    return {
        dataFormat: referenceFormatter,
        formatExtraData: info.contextPath
    }
}

function refTableOptions(field, info) {
    return info.print && info.reftable ? refTableForPrintOptions(field) : referenceOptions(field, info); //use the same values as enumeration
}

function objectTableOptions(field) {
    return {
        hidden: true //Hide column if nested table
    };
}

function primitiveOptions(field) {
    let dataType = field.dataType.name;
    let options = {
        sortable: true
    }
    if (dataType == "boolean") {
        options.dataFormat = booleanFormatter
        //nothing to do now
    } else if (dataType == "date") {
        options.dataFormat = dateFormatter;
    } else if (dataType == "dateTime") {
        options.dataFormat = dateTimeFormatter;
    } else if (dataType == "float") {
        //nothing to do now
    } else if (dataType == "integer") {
        //nothing to do now
    } else {
        //nothing to do now
    }
    return options;
}

/**
 * Returns TableHeaderColumn options for column predicate
 * @see http://allenfang.github.io/react-bootstrap-table/docs.html
 * 
 * This function also used for printing of cards
 */
export default function (field, info) {
    //First check for complex types

    if (typeof field.classRelationInfo != 'undefined' && field.classRelationInfo != null) {
        let peerClass = field.classRelationInfo.peerClass;

        if (peerClass.stereotypeInfo == "enumeration") { //Combobox
            return enumerationOptions(field);
        } else {
            let relationTypeInfo = field.classRelationInfo.relationTypeInfo.toLowerCase();
            if (relationTypeInfo == 'composition') { //Table
                return objectTableOptions(field);
            } else {
                return refTableOptions(field, info);
            }
        }
    } else if (typeof field.dataType != 'undefined' && field.dataType != null) {
        let dataType = field.dataType.name;
        if (dataType === "anyURI") {
            return fragmentOptions(field);
        } else if (dataType === "base64Binary") {
            return fileOptions(field, info);
        } else {
            return primitiveOptions(field);
        }
    }
    return {
        hidden: true //Hide column by default
    };
}