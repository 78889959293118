import * as React from 'react';
import { ModalInfo, ModalStatus, I18NString } from '../../../types/modal';
import ModalView from '../ModalView';
import { Form, Col, Row } from 'react-bootstrap'; 
import { MODAL_STATUS_OK, MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE } from '../../../constants/modal';
import { ApplicationState, ApplicationAction } from '../../../types';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {   CimClass, ClassCard,   convertDirectoryId,   isDirectory,   Package } from '../../../types/profile';
 
import TableComponent, { ColumnInfo } from '../../developer/profileeditor/table/TableComponent'; 
export interface PackageTableData extends Package {
    checked:boolean
}
 


interface ExportPackagesModalProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void,
    treeNodes?:(Package|CimClass)[] 
    // editedClass?: ClassInfo
}

interface ExportPackagesModalState {
    checked: { [id: number]: boolean }
}


class ExportPackagesModal extends React.Component<ExportPackagesModalProps, ExportPackagesModalState> {
    private packageTableData:PackageTableData[] = []; 
    private title: any = {id:"CLASSCARD_PACKAGES_EXPORT"};

    constructor(props: ExportPackagesModalProps) {
        super(props);

        this.state = {
            checked: {}
        };
        const {treeNodes} = this.props;
        if (treeNodes  ) {
            for(const item of treeNodes){ 
                const {id:rawId,...other} = item; 
                if(typeof rawId !=='string' || !isDirectory(rawId)){
                    continue;
                }
                
                const id =  convertDirectoryId(rawId) ;
                id!==-1 && this.packageTableData.push({...(other as Package) ,id ,checked:false})
            } 
        } 
        
    } 
    // checkItem: (id: number, checked: boolean) => dispatch(sendPredicateIdOnDelete(id, checked)),
    // checkAllItems: (checked: boolean) => dispatch(sendAllPredicatesIdOnDelete(checked))

    checkItem=(id: number, checked: boolean)=>{
         this.packageTableData.forEach(p=>{
             if(p.id === id){
                 p.checked = checked;
             }
         })
         this.setState({checked:{...this.state.checked,[id]:checked}})
    }
    checkAll=(checkedAll :boolean)=>{
        const checkedObj:{[id:number]:boolean} = {...this.state.checked};
        this.packageTableData.forEach(p=>{             
            p.checked = checkedAll; 
            checkedObj[+p.id] = checkedAll;
        })
        this.setState({checked:checkedObj});
    }
    

    renderTable( ) {
        
        const columnsInfo: ColumnInfo[] = [
            { label: { id: "CLASSCARD_ID_OF_PREDICATE" }, width: 60, dataKey: "id",identifier:true, type:'number' },
        { label: { id: "CLASSCARD_LABEL_OF_PREDICATE" }, width: 200, dataKey: "label",sort:true ,type:'string'},
        { label: { id: "CLASSCARD_NAME_OF_PREDICATE" }, width: 200, dataKey: "name" ,sort:true,type:'string'}
        ];
          
        return  <div className="shadow">
                    <TableComponent   checkable={true} rows={ this.packageTableData }  columnsInfo={columnsInfo} checkItem={this.checkItem} checkAllItems={this.checkAll}/>
                </div>
    }

    renderTemplate(): React.ReactElement { 
        
 
        return (
            <Form>
                <Form.Group as={Row} controlId="namespace" className=" ">
                    <Col md={12} >
                        {this.renderTable()}
                    </Col>
                </Form.Group>
            </Form>
        );
    }
 
    closeModal =(status: ModalStatus, result: any)=> {
        const {checked} = this.state; 
        const packages = Object.entries(checked).map(entry=>{
            const [id,check] = entry;
            if(check){
                return id;
            }
        }) 
        if (status == MODAL_STATUS_OK  ) { 
            packages && packages.length!==0 && this.props.closeModal(status,packages);
        } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
            this.props.closeModal(status,result);
        }
    }

    render() { 
        const modal = { ...this.props.modal };
        modal.options = { title: this.title, ...modal.options };
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}

export default connect((state: ApplicationState) => {
    if (!state.profileeditor ) {
        return {}
    }
    const tree = state.profileeditor.tree 
    return {
        treeNodes: tree && tree.nodeById && Object.values(tree.nodeById),
        newClassPackageId: state.profileeditor.newClassPackageId,
        editedClass: state.profileeditor.editedClass
    }
},
    (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
        return {

        }
    })(ExportPackagesModal)

