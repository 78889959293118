import * as React from 'react';
import { EqualityValue } from '../../../types/table';

import styles from '../Table.module.css';

const options = [{
    value: "less",
    label: "<"
}, {
    value: "lessOrEqual",
    label: "≤"
}, {
    value: "equal",
    label: "="
}, {
    value: "moreOrEqual",
    label: "≥"
}, {
    value: "more",
    label: ">"
}];

interface EqualitySelectorProps {
    value: EqualityValue
    onChange: (value: EqualityValue) => void
}
const EqualitySelector: React.FunctionComponent<EqualitySelectorProps> = React.memo((props) => {
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onChange(event.target.value as EqualityValue);
    }
    return <select className={`${styles.nptFilterEqualitySelector}`} onChange={onChange} defaultValue={props.value}>
        {options.map((option) => <option key={option.value} value={option.value} >
            {option.label}
        </option>)}
    </select>
});

export default EqualitySelector;