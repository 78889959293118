import React from "react";
import { Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { change } from "../../../actions/subject";
import { CheckBoxKeys } from "../../../constants/subject";
import { isCardEdit, isEditable } from "../../../services/layout";
import { ApplicationAction, ApplicationState } from "../../../types";
import { I18NString } from "../../../types/modal";
import {
  CheckVariant,
  isSubject,
  LayoutNode,
  SubjectComment,
} from "../../../types/subject";
import BasicInput from "./BasicInput";
import {
  BUTTONS,
  ButtonsCheckbox,
  DROPDOWN,
  DropDownCheckbox,
  SLIDER,
  SliderCheckbox,
} from "./CheckboxVariants";

export interface CheckboxInputProps {
  subjectKey: string;
  nodeId: string;
  visible: boolean;
  node?: LayoutNode;
  value?: boolean;
  editable?: boolean;
  cardEditable?: boolean;
  change: (data: any, options?: any) => void;
  valid?: boolean;
  error?: I18NString;
  comment?: SubjectComment;
}

class CheckboxInput extends React.Component<CheckboxInputProps> {
  changeCheck = (value: any, options: any) => {
    const { change } = this.props;
    change(value, options);
  };
  chooseType = (node: LayoutNode) => {
    const { editable, value, subjectKey, visible, change } = this.props;

    if (!node.options || !node.options.type) {
      // return (<BasicInput id={subjectKey + "." + node.id} node={node} hideLabel={true} visible={visible}>
      return (
        <input
          className="mr-1"
          disabled={!editable}
          type="checkbox"
          onChange={(e: any) => change(e.target.checked)}
          checked={value || false}
        />
      );
      {
        /* <Form.Label className="d-inline">
                    {node.label}
                </Form.Label> */
      }
      // </BasicInput>)
    }
    const { type, values } = node.options;
    if (type && typeof type === "string") {
      //нестандартный вид
      let variants: CheckVariant[] = [];
      if (values && Array.isArray(values)) {
        //нестандартные опции
        if (values.length === 2) {
          variants.push({
            label: values[0],
            value: false,
            key: CheckBoxKeys.NO,
          });
          variants.push({
            label: values[1],
            value: true,
            key: CheckBoxKeys.YES,
          });
          //Опции нет да по порядку
        } else if (values.length === 3) {
          variants.push({
            label: values[0],
            value: {},
            key: CheckBoxKeys.NOTHING,
          });
          variants.push({
            label: values[1],
            value: false,
            key: CheckBoxKeys.NO,
          });
          variants.push({
            label: values[2],
            value: true,
            key: CheckBoxKeys.YES,
          });
          //Опции опция когда значение не задано, нет, да, по порядку
        }
      }
      let component = null;
      switch (type) {
        case SLIDER:
          return (
            <SliderCheckbox
              variants={variants}
              change={this.changeCheck}
              value={value}
              disabled={!editable}
            />
          );
        case BUTTONS:
          return (
            <ButtonsCheckbox
              variants={variants}
              change={this.changeCheck}
              value={value}
              disabled={!editable}
            />
          );
        case DROPDOWN:
          return (
            <DropDownCheckbox
              variants={variants}
              change={this.changeCheck}
              value={value}
              disabled={!editable}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };
  render() {
    const {
      node,
      subjectKey,
      nodeId,
      value,
      editable,
      cardEditable,
      visible,
      error,
      comment,
    } = this.props;
    if (!node) {
      return null;
    }
    const checkbox = this.chooseType(node);
    if (!checkbox) {
      return null;
    }
    return (
      <BasicInput
        id={subjectKey + "." + nodeId}
        hideLabel={false}
        editable={editable}
        cardEditable={cardEditable}
        error={error}
        node={node}
        visible={visible}
        comment={comment}
      >
        {checkbox}
        {/* <Form.Label className="d-inline">
                    {node.label}
                </Form.Label> */}
      </BasicInput>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const { subjectKey, nodeId } = ownProps;
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return { visible: false };
    }
    const node = subject && subject.nodeById[ownProps.nodeId];
    const value = subject && subject.values[ownProps.nodeId];
    // const fragmentList = subject && subject.fragmentList;

    const cardEditable = isCardEdit(subject);
    const editable = isEditable(subject, nodeId, cardEditable);

    const error = subject.validation[nodeId];
    const visible = subject.visibility[ownProps.nodeId] ? true : false;

    let valid = !error || !cardEditable ? true : false;
    let mandatory = subject.mandatorySet[node.id];
    if (
      mandatory &&
      (!value || (typeof value === "object" && Object.keys(value).length === 0))
    ) {
      valid = false;
    }
    return {
      node,
      value: value,
      editable,
      cardEditable,
      visible,
      error,
      valid,
      comment: subject.comment[nodeId],
    };
  },
  (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const { subjectKey, nodeId } = ownProps;
    return {
      change: (data: any, options?: any) =>
        dispatch(change(ownProps.subjectKey, ownProps.nodeId, data, options)),
    };
  }
)(CheckboxInput);
