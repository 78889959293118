import * as React from 'react';
import Header from './Header';
import Body from './Body';

import styles from "./Sitemap.module.css";
import { formatSitemap, getTreeRoot, isLinkDisplayed, prepareJson } from '../../services/sitemap';
import { normalizeUrl } from '../../services/location';

function fetchSitemap(sitemapId: string, callback: (root: any) => void) {
    fetch(normalizeUrl(`/rest/script/${sitemapId}`)).then(function (result) {
        if (!result.ok) {
            throw new Error("Failed to load site map");
            return {};
        }
        return result.json();
    }).then(function (json) {
        prepareJson(json);
        formatSitemap(json);
        callback(json);
    });
}

interface SitemapProps {
    sitemapId: string
}
interface SitemapState {
    initialJson: any
    root: any
}
export default class Sitemap extends React.PureComponent<SitemapProps, SitemapState> {

    constructor(props: SitemapProps) {
        super(props);

        this.state = {
            initialJson: null,
            root: null
        }

        fetchSitemap(props.sitemapId, (json) => {
            this.setState({ initialJson: json, root: getTreeRoot(json) });
        });

        this.filterSitemap = this.filterSitemap.bind(this);
        this.filterDirective = this.filterDirective.bind(this);
    }

    filterSitemap(filterString: string, json = this.state.initialJson) {
        if (!filterString) {
            this.setState({ root: getTreeRoot(json) });
            return;
        }
        if(typeof filterString == "object"){
            filterString = "";
        }
        filterString = filterString.toLowerCase();
        const filteredRoot = this.filterDirective(json, filterString);
        formatSitemap(filteredRoot);
        this.setState({ root: getTreeRoot(filteredRoot) });
    }

    filterDirective(node: any, filterString: string) {
        var filteredNode = Object.assign({}, node);
        var children = [];
        for (var i = 0; i < filteredNode.children.length; ++i) {
            var child = filteredNode.children[i];
            if (typeof child.href == "undefined") {
                child = this.filterDirective(child, filterString);
                if (child.children.length != 0) {
                    children.push(child);
                }
                continue;
            }
            if (isLinkDisplayed(child, filterString)) {
                children.push(child);
            }
        }
        filteredNode.children = children;
        return filteredNode;
    }

    render() {
        return <div className={styles.nptSitemapContainer}>
            <Header filterSitemap={this.filterSitemap}></Header>
            <Body root={this.state.root}></Body>
        </div>;
    }
}