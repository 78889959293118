import * as React from 'react';

import { FinderProps } from '../../types/finder';
import { connectFinder } from '../../services/finder';

import FinderSideBar from './FinderSideBar';
import FinderCriteriaBar from './FinderCriteriaBar';

import styles from './Finder.module.css';

/******************************
 *  Finder Toolbar Component  *
 ******************************/
const Finder: React.FunctionComponent<React.PropsWithChildren<FinderProps>> = React.memo((props) => {
    return <div className="d-flex flex-fill overflow-hidden">
        <FinderSideBar
            isHidden={props.isHidden}
            loadedFragments={props.loadedFragments}
            loadedClasses={props.loadedClasses}
            finderOptions={props.options}
            finderData={props.data}
            finderDataChanges={props.changes}
            fetchFragments={props.fetchFragments}
            selectFragment={props.selectFragment}
            fetchClasses={props.fetchClasses}
            selectClass={props.selectClass}
            sendHidden={props.sendHidden}
        />
        <div className="d-flex flex-column flex-fill overflow-hidden">
            <FinderCriteriaBar
                isHidden={props.isHidden}
                view={props.view}
                loadedFields={props.loadedFields}
                loadedObjectcards={props.loadedObjectcards}
                loadedPredicates={props.loadedPredicates}
                finderOptions={props.options}
                finderData={props.data}
                finderDataChanges={props.changes}
                sendHidden={props.sendHidden}
                fetchFields={props.fetchFields}
                fetchObjectcard={props.fetchObjectcard}
                changeFinderView={props.changeFinderView}
                addCriteria={props.addCriteria}
                addCriteriaRelation={props.addCriteriaRelation}
                removeCriteria={props.removeCriteria}
                removeCriteriaRelation={props.removeCriteriaRelation}
                changeCriteriaRelation={props.changeCriteriaRelation}
                changeCriteriaField={props.changeCriteriaField}
                openModal={props.openModal}
                addAlert={props.addAlert}
            />
            <div className={`d-flex flex-fill overflow-hidden ${styles.innerContainer}`}>
                {props.children}
            </div>
        </div>
    </div>;
});

export default connectFinder(Finder);