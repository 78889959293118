import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from '../Table.module.css';

interface EmptyCellsFilterProps {
    value: boolean
    onChange: (value: boolean) => void
}
const EmptyCellsFilter: React.FunctionComponent<EmptyCellsFilterProps> = React.memo((props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.checked);
    }
    return <div className={`w-100 d-flex ${styles.nptFilterEmptyCell}`}>
        <input className="mr-2" type="checkbox" defaultChecked={props.value} onChange={onChange} />
        <FormattedMessage
            id="NPT_TABLE_FILTER_EMPTY_CELLS"
            defaultMessage='Blank cells'
            description="Empty cells filter label" />
    </div>
});

export default EmptyCellsFilter;