import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TableFilterItem } from '../../../types/table';
import { isEmptyObject } from '../../../services/app';

import styles from '../Table.module.css';

/** Filter's available options */
interface FilterOptionsProps {
    loading: boolean
    error: boolean
    toggled: boolean
    selectedValues: { [k: string]: boolean }
    selectionOptions: any[]
    onChange: (selectedValues: { [k: string]: boolean }) => void
    onToggle: (toggled: boolean) => void
}
const FilterOptions: React.FunctionComponent<FilterOptionsProps> = React.memo((props) => {
    /**Set toggled at mount to false to prevent displaying old state of filter */
    const [toggled, setToggledImpl] = React.useState(false);
    React.useEffect(() => {
        if (!props.toggled) {
            setToggled(true);
        }
    }, []);
    const [selectedValues, setSelectedValues] = React.useState<{ [k: string]: boolean }>(props.selectedValues);
    React.useEffect(() => {
        setSelectedValues(props.selectedValues);
    }, [props.selectedValues]);
    const optionItems = props.selectionOptions.map((option) => { return { label: option, value: option } });
    const optionItemHeight = 40;
    const setToggled = (toggled: boolean) => {
        props.onToggle(toggled);
        setToggledImpl(toggled);
    }
    const onItemToggle = (value: string, toggled: boolean) => {
        const newSelectedValues = Object.assign({}, selectedValues);
        if (!toggled) {
            delete (newSelectedValues[value]);
        } else {
            newSelectedValues[value] = true;
        }
        setSelectedValues(newSelectedValues);
        props.onChange(newSelectedValues)
    }
    const onAllItemsToggle = (newSelectedValues: { [k: string]: boolean }) => {
        setSelectedValues(newSelectedValues);
        props.onChange(newSelectedValues)
    }
    let height: string | number = 0;
    if (toggled) {
        if (props.error) {
            height = "auto";
        } else if (optionItems.length !== 0) {
            height = (optionItems.length + 1) * optionItemHeight;
        }
    }
    return <div className={`w-100 d-flex flex-column ${styles.nptFilterOptions}`}>
        <div className={`${styles.nptFilterOptionsToggler} ${toggled ? styles.toggled : ""} ${props.loading ? styles.loading : ""}`} onClick={() => setToggled(!toggled)}>
            <div className={`${styles.loadingIcon}`}>
                <i className={`fa fa-circle-o-notch fa-spin`} />
            </div>
            <i className={`fa fa-chevron-right ${styles.toggleIcon}`} />
            <FormattedMessage
                id="NPT_TABLE_FILTER_TOGGLER"
                defaultMessage='Select'
                description="Table filter toggler label" />
        </div>
        <div
            className={`${styles.nptFilterOptionsBody} ${toggled ? styles.toggled : ""}`}
            style={{ height }}
        >
            <FilterOptionsItemAll items={optionItems} selectedValues={selectedValues} onToggle={onAllItemsToggle} />
            {props.error && <ErrorPlaceholder />}
            {optionItems.map((item) => <FilterOptionsItem
                key={item.value}
                checked={Boolean(selectedValues[item.value])}
                onToggle={(toggled) => onItemToggle(item.value, toggled)}
            >
                {item.label}
            </FilterOptionsItem>)}
        </div>
    </div>;
});

const ErrorPlaceholder: React.FunctionComponent = React.memo((props) => <div className="alert alert-danger my-2">
    <FormattedMessage
        id="NPT_TABLE_FILTER_LOADING_ERROR"
        defaultMessage="Error occured during data fetch"
        description="Fetch error placeholder"
    />
</div>);

/** Filter's select all row */
interface FilterOptionsItemAllProps {
    items: TableFilterItem[]
    selectedValues: { [k: string]: boolean }
    onToggle: (selectedValues: {}) => void
}
const FilterOptionsItemAll: React.FunctionComponent<FilterOptionsItemAllProps> = React.memo((props) => {
    if (props.items.length === 0) {
        return null;
    }
    let checked = true;
    for (let item of props.items) {
        if (!props.selectedValues[item.value]) {
            checked = false;
            break;
        }
    }
    const indeterminate = !checked && !isEmptyObject(props.selectedValues);
    const onToggle = () => {
        if (checked) {
            props.onToggle({});
            return;
        }
        let selectedValues: { [k: string]: boolean } = {};
        for (let items of props.items) {
            selectedValues[items.value] = true;
        }
        props.onToggle(selectedValues);
    }
    return <FilterOptionsItem checked={checked} indeterminate={indeterminate} onToggle={onToggle}>
        <FormattedMessage
            id="NPT_TABLE_FILTER_SELECT_ALL"
            defaultMessage='(Select all)'
            description="Table filter select all label" />
    </FilterOptionsItem>;
});

/** Filter's available options row */
interface FilterOptionsItemProps {
    checked: boolean
    indeterminate?: boolean
    onToggle: (toggled: boolean) => void
}
const FilterOptionsItem: React.FunctionComponent<FilterOptionsItemProps> = React.memo((props) => {
    const ref = React.useRef<HTMLInputElement | null>(null);
    React.useEffect(() => {
        if (!ref.current || typeof props.indeterminate === "undefined") {
            return;
        }
        const indeterminate = Boolean(props.indeterminate);
        (ref.current as HTMLInputElement).indeterminate = indeterminate;
    });
    return <div className={`w-100 d-flex ${styles.nptFilterOptionsItem}`} onClick={() => props.onToggle(!props.checked)}>
        <div className={`${styles.nptFilterOptionsItemSelect}`}>
            <input
                type="checkbox"
                checked={props.checked}
                ref={ref}
                readOnly={true}
            />
        </div>
        <div className={`${styles.nptFilterOptionsItemLabel}`}><span>{props.children}</span></div>
    </div>;
});

export default FilterOptions;