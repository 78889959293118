import { Reducer } from 'redux';

import * as constants from '../constants/tree';

import {
    TreeReducerState,
    TreeState,
    TreeNode,
    TreeAction,
    isTreeNode,
    isTreeNodes,
    TreeAutomationBindings,
    TreeAutomationBindingFunctions
} from '../types/tree';
import { isFetchError, FetchError } from '../types/error';
import { sortTreeNodes, generateTreeBindings } from '../services/tree';
import { scriptCompiler } from '../services/automation';

export const initialTreeState: TreeState = {
    treeId: "",
    rootNodesIds: [],
    nodeById: {},
    children: {},
    toggled: {},
    active: null,
    automation: {
        sortBindings: null,
        actionBindings: null,
        decoratorBindings: null,
        reverseDecoratorBindings: null
    }
}

const initialNodeState: TreeNode = {
    id: "",
    nodeId: "",
    parentId: null,
    name: "",
    loading: false,
    leaf: true,
    deleteLock: true,
    data: null,
    cache: null,
    description: '',
    label: '',
    moveLock: false,
    copyLock: false,
    typeId: '',
    visible: true,

}


function updateTreeState(state: TreeReducerState, nodes: TreeNode[] | FetchError, path: string, parentId?: string) {
    const nextTreeObj = { ...state.treeInfo };
    const nextTree = { ...(nextTreeObj[path] || initialTreeState) };

    if (!nextTree.loading) {
        nextTree.loading = {};
    }
    nextTree.loading[parentId || path] = false;
    if (nextTree.updateNodes) {
        if (!parentId) {
            nextTree.updateNodes = nextTree.updateNodes.filter(nodeId => nodeId !== null);
        } else {
            nextTree.updateNodes = nextTree.updateNodes.filter(nodeId => nodeId !== parentId);
        }
        if (nextTree.updateNodes.length === 0) {
            delete (nextTree.updateNodes);
        }
    }
    if (!isTreeNodes(nodes)) {
        if (!parentId) {
            nextTree.error = nodes;
            return { ...state, treeInfo: nextTreeObj };
        }
        nextTree.nodeById = { ...nextTree.nodeById };
        nextTree.nodeById[parentId] = { ...nextTree.nodeById[parentId], error: nodes }
        if (nextTree.toggled && nextTree.toggled[parentId]) {
            nextTree.toggled = { ...nextTree.toggled };
            nextTree.toggled[parentId] = false;
        }
        return { ...state, treeInfo: nextTreeObj };
    }
    if (!parentId) {
        delete (nextTree.error);
    } else if (nextTree.nodeById && nextTree.nodeById[parentId] && nextTree.nodeById[parentId].error) {
        nextTree.nodeById = { ...nextTree.nodeById };
        nextTree.nodeById[parentId] = { ...nextTree.nodeById[parentId] }
        delete (nextTree.nodeById[parentId].error);

        /**Prevent recursion */
        const parentIdsMap = { [parentId]: true };
        let parent = nextTree.nodeById[parentId];
        while (parent.parentId) {
            parentIdsMap[parent.parentId] = true;
            parent = nextTree.nodeById[parent.parentId];
        }

        let allowedNodes: TreeNode[] = [];
        for (let node of nodes) {
            if(!parentIdsMap[node.id]){
                allowedNodes.push(node);
            }
        }
        nodes = allowedNodes;
    }

    const ids = nodes.map(n => n.id);
    if (!parentId) {
        nextTree.rootNodesIds = ids
    } else {
        nextTree.children = { ...nextTree.children, [parentId]: ids }
    }

    let byRdfId: { [RDF_ID: string]: string } = {};
    let byId: { [ID: string]: TreeNode } = {};

    nodes.forEach(node => {
        node.parentId = parentId || null;
        if (node.data && !node.syntetic) {
            byRdfId[node.data.$rdfId] = node.id;
        }
        byId[node.id] = node;
    })
    nextTree.nodeIdByRdfId = { ...nextTree.nodeIdByRdfId, ...byRdfId };
    nextTree.nodeById = { ...nextTree.nodeById, ...byId };
    nextTreeObj[path] = nextTree;
    return { ...state, treeInfo: nextTreeObj };
}

// /*******************
//  * Utils funcitons *
//  *******************/

/**Parse user script to get binding functions */
function parseTreeAutomation(script?: string): TreeAutomationBindings {
    const parsedAutomation: TreeAutomationBindings = Object.assign({}, initialTreeState.automation);
    if (!script) {
        return parsedAutomation;
    }
    const bindings: TreeAutomationBindingFunctions = generateTreeBindings(parsedAutomation);
    scriptCompiler(script, bindings);
    return parsedAutomation;
}

// /*********************
//  * Reducer funcitons *
//  *********************/

function recursiveExpand(node: TreeNode | FetchError, tree: TreeState) {
    if (!isTreeNode(node)) {
        return
    }
    if (!tree.toggled) {
        tree.toggled = {}
    }
    tree.toggled[node.id] = true;
    node.parentId && tree?.nodeById?.[node.parentId] && recursiveExpand(tree?.nodeById?.[node.parentId] as TreeNode, tree);
}
function expandTreeWithEditingNodes(state: TreeReducerState, treeId: string, editingNodes: string[]): TreeReducerState {
    const treeState = { ...(state.treeInfo[treeId] || initialTreeState), treeId };

    for (const n of editingNodes) {
        const node = treeState?.nodeById?.[n];
        node && recursiveExpand(node, treeState);
    }
    const treeInfo = { ...state.treeInfo, [treeId]: treeState }
    return { ...state, treeInfo }
}
const reducer: Reducer<TreeReducerState, TreeAction> = (state: TreeReducerState = { treeInfo: {} }, action: TreeAction): TreeReducerState => {
    switch (action.type) {
        case constants.SEND_TREE_HEADER: {
            const { path, header } = action.payload;
            const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
            nextTree.treeId = path;
            nextTree.header = header;
            nextTree.automation = parseTreeAutomation(header.automation)
            const nextTreeObj = { ...state.treeInfo, [path]: nextTree }
            return { ...state, treeInfo: nextTreeObj };
        }
        case constants.SEND_TREE_NODES: {
            const { nodes, path, parentId } = action.payload;
            const treeState = state.treeInfo?.[path];
            const parentNode = parentId ? treeState?.nodeById?.[parentId] : undefined;
            const sortedNodes = isFetchError(nodes) ? nodes : sortTreeNodes(nodes, parentNode, treeState?.automation);
            return updateTreeState(state, sortedNodes, path, parentId);
        }
        case constants.SEND_TREE_LOADING: {
            const { path, loading, id } = action.payload;
            const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
            if (!nextTree.loading) {
                nextTree.loading = {}
            } else {
                nextTree.loading = { ...nextTree.loading };
            }
            nextTree.loading[id] = loading;
            const nextTreeObj = { ...state.treeInfo, [path]: nextTree }
            return { ...state, treeInfo: nextTreeObj };
        }
        case constants.SEND_TREE_NODE_TOGGLE: {
            const { path, nodeId, expanded } = action.payload;
            const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
            if (!nextTree.toggled) {
                nextTree.toggled = {};
            } else {
                nextTree.toggled = { ...nextTree.toggled };
            }
            nextTree.toggled[nodeId] = expanded;
            const nextTreeObj = { ...state.treeInfo, [path]: nextTree }
            return { ...state, treeInfo: nextTreeObj };
        }
        case constants.SEND_TREE_NODE_ACTIVE: {
            const { path, nodeId } = action.payload;
            const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
            const node = nextTree?.nodeById?.[nodeId]
            if (nextTree.active && nextTree.active === nodeId) {
                /** TODO: handle deselect with ctrl */
                // nextTree.active = null
            } else {
                nextTree.active = nodeId
                nextTree.valid = { id: nodeId, isValid: isTreeNode(node) };
            }
            const nextTreeObj = { ...state.treeInfo, [path]: nextTree }
            return { ...state, treeInfo: nextTreeObj };
        }
        case constants.SEND_TREE_ACTIVE_NODE_VALID: {
            const { isValid, path, id } = action.payload;
            const nextTree = { ...(state.treeInfo[path] || initialTreeState) };

            if (!nextTree.active) {
                return state;
            }
            nextTree.valid = { id, isValid };

            const nextTreeObj = { ...state.treeInfo, [path]: nextTree }
            return { ...state, treeInfo: nextTreeObj };
        }
        case constants.SEND_TREE_COMPRESS: {
            const { path } = action.payload;
            const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
            nextTree.toggled = {};
            const nextTreeObj = { ...state.treeInfo, [path]: nextTree }
            return { ...state, treeInfo: nextTreeObj };
        }
        case constants.SEND_TREE_NODES_FORCE_UPDATE: {
            const { path, nodesIdList } = action.payload;
            const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
            let updateNodes = nextTree.updateNodes ? nextTree.updateNodes.concat(nodesIdList) : nodesIdList;
            /**Remove duplicates */
            updateNodes = updateNodes.filter(function (item, pos) {
                return updateNodes.indexOf(item) == pos;
            });
            nextTree.updateNodes = updateNodes;
            const nextTreeObj = { ...state.treeInfo, [path]: nextTree }
            return { ...state, treeInfo: nextTreeObj };
        }
        case constants.SEND_TREE_NODE_CTX_MENU_INFO: {
            const { nodeId, treeId } = action.payload
            const tree = { ...state.treeInfo };
            tree[treeId].contextMenuInfo = action.payload
            return { ...state, treeInfo: tree }
        }
        case constants.SEND_TREE_FILTER_CHANGE: {
            const { path, value, checked } = action.payload
            const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
            if (nextTree.header && nextTree.header.filter) {
                nextTree.header = { ...nextTree.header };
                if (nextTree.header.filter) {
                    nextTree.header.filter = { ...nextTree.header.filter };
                    nextTree.header.filter.options = nextTree.header.filter.options.slice();
                    const optionIdx = nextTree.header.filter.options.findIndex((option) => option.value === value);
                    nextTree.header.filter.options[optionIdx] = { ...nextTree.header.filter.options[optionIdx], checked };
                }
            }

            const nextTreeObj = { ...state.treeInfo, [path]: nextTree }
            return { ...state, treeInfo: nextTreeObj };
        }
        // case constants.SEND_TREE_LOADING: return receiveTreeLoading(state, (action as SendTreeLoading).payload, (action as SendTreeLoading).treeId);
        // case constants.SEND_TREE_NODES: return receiveTreeNodes(state, (action as SendTreeNodes).payload, (action as SendTreeNodes).treeId);
        // case constants.SEND_TREE_NODE_TOGGLE: return receiveTreeNodeToggle(state, (action as SendTreeNodeToggle).payload, (action as SendTreeNodeToggle).treeId);
        // case constants.SEND_TREE_NODE_ACTIVE: return receiveTreeNodeActive(state, (action as SendTreeNodeActive).payload, (action as SendTreeNodeActive).treeId);
        case constants.SEND_EXPAND_TREE_WITH_EDITING_SUBJECTS: return expandTreeWithEditingNodes(state, action.payload.treeId, action.payload.nodes);
        // case constants.SEND_TREE_NODE_CTX_MENU_INFO:{ 
        //     const treeState = {...state};
        //     treeState.contextMenuInfo = action.payload
        //     return {...treeState}
        // }          
    }
    return state;
}

export default reducer;