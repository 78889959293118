import { Action, AnyAction } from "redux";

import { FetchError } from "./error";
import * as constants from '../constants/sourceeditor';
import { SubjectData } from "./subject";

export type SourceEditorType = "vieweditor" | "scripteditor";

export interface CodePosition{
    line:number
    column:number
}

export interface SourceTreeNode {
    id: string,
    name: string,
    path: string | null,
    directory: boolean,
    parentId: string | null
}

export interface SourceEditorTreeData {
    rootNodesIds?: string[] | FetchError
    nodeById?: { [ID: string]: SourceTreeNode }
    childrenIds?: { [ID: string]: string[] }
    
}

export interface SourceEditorTreeState extends SourceEditorTreeData {
    treeLoading?: boolean,
    expanded?: { [ID: string]: boolean },
    active?: string
    inplace?: string
    filterLoading?: boolean
    filterValue?: string
    filter?: { [ID: string]: boolean }
}

export interface SourceMetaData {
    id?:number
    path: string
    description: string
    mime: string
}

export interface SourceEditorCodeData extends SourceMetaData {
    code: string
    type: "view" | "script"
}

export interface SourceCodeDeleteInfo {
    path: string
    directory: boolean
    count: number
}

export interface SourceCodeCopyMoveInfo {
    source: string
    target: string
    directory: boolean
    count: number
}

export function isSourceEditorCodeData(code?: SourceEditorCodeData | FetchError): code is SourceEditorCodeData {
    return typeof code != 'undefined' && typeof (code as SourceEditorCodeData).code == 'string';
}

export interface SourceHistoryInfo {
    id: number
    mime: string
    operation: number
    operationTimestamp: string
    path: string
    user: SubjectData
    userId: number
}

export interface BasicTemplateTreeNode {
    /** Type of node (used for the visualization) */
    type: string
    /** Code to be serialized */
    code: string
    /** Children placeholder (string that will be replaced with children while serializing) */
    placeholder?: string
    /** Data used by visualizers */
    data?: any
    /** Node code is simple inplace text (without any indent!) */
    inplace?: boolean
}

/**
 * We could represent any code as template tree (which could be visualized and serialized)
 * 
 * Templates are used to build initial code for the editor
 */
export interface TemplateTreeNode extends BasicTemplateTreeNode {
    /** Unique ID in tree (autogenerated) */
    id: string
}

export interface TemplateTree {
    rootNodesIds: string[],
    nodeById: { [ID: string]: TemplateTreeNode },
    childrenIds: { [ID: string]: string[] }
    code: string //root code
    placeholder?: string //root placeholder
}

export interface Template extends SourceEditorCodeData {
    templateTree?: TemplateTree
}

export interface HierarchicalTemplateTreeNode extends BasicTemplateTreeNode {
    children?: HierarchicalTemplateTreeNode[]
}

export interface HierarchicalTemplateTree extends HierarchicalTemplateTreeNode {
    title: any
    mime: string
}

///////////
//Actions//
///////////
export interface SendSourceTreeInfo extends Action {
    type: constants.SEND_SOURCE_TREE,
    sourceEditorType: SourceEditorType,
    payload: {
        list: string[]
    }
}

export interface SendSourceTreeSearchFilterLoading extends Action {
    type: constants.SEND_SOURCE_TREE_SEARCH_FILTER_LOADING,
    sourceEditorType: SourceEditorType
}

export interface SendSourceTreeSearchFilter extends Action {
    type: constants.SEND_SOURCE_TREE_SEARCH_FILTER,
    sourceEditorType: SourceEditorType,
    payload: {
        filterValue: string
        filter: string[] | null
    }
}

export interface SendSourceTreeError extends Action {
    type: constants.SEND_SOURCE_TREE_ERROR,
    sourceEditorType: SourceEditorType,
    payload: {
        error: FetchError;
    }
}

export interface SendSourceTreeLoading extends Action {
    type: constants.SEND_SOURCE_TREE_LOADING,
    sourceEditorType: SourceEditorType,
}

export interface SendSourceTreeToggle extends Action {
    type: constants.SEND_SOURCE_TREE_TOGGLE,
    sourceEditorType: SourceEditorType,
    payload: {
        nodeId: string,
        expanded: boolean
    }
}

export interface SendSourceTreeActive extends Action {
    type: constants.SEND_SOURCE_TREE_ACTIVE,
    sourceEditorType: SourceEditorType,
    payload: {
        nodeId: string
    }
}

export interface SendSourceTreeToggleInplace extends Action {
    type: constants.SEND_SOURCE_CODE_TREE_TOGGLE_INPLACE,
    sourceEditorType: SourceEditorType,
    payload: {
        nodeId: string
        inplace: boolean
    }
}

export interface SendSourceTreeCreateDirectory extends Action {
    type: constants.SEND_SOURCE_TREE_CREATE_DIRECTORY,
    sourceEditorType: SourceEditorType,
    payload: {
        path: string
    }
}

export interface SendSourceCodeInfo extends Action {
    type: constants.SEND_SOURCE_CODE,
    sourceEditorType: SourceEditorType,
    payload: {
        path: string
        code: SourceEditorCodeData
    }
}

export interface SendSourceCodeError extends Action {
    type: constants.SEND_SOURCE_CODE_ERROR,
    sourceEditorType: SourceEditorType,
    payload: {
        path: string
        error: FetchError;
    }
}

export interface SendSourceCodeLoading extends Action {
    type: constants.SEND_SOURCE_CODE_LOADING,
    sourceEditorType: SourceEditorType,
    payload: {
        path: string
        loading?: boolean
    }
}

export interface SendSourceCodeInitEdit extends Action {
    type: constants.SEND_SOURCE_CODE_INIT_EDIT,
    sourceEditorType: SourceEditorType,
    payload: {
        path: string
    }
}

export interface SendSourceCodeTextChanges extends Action {
    type: constants.SEND_SOURCE_CODE_TEXT_CHANGES,
    sourceEditorType: SourceEditorType,
    payload: {
        path: string
        code: string
    }
}

export interface SendSourceCodeMetaDataChanges extends Action {
    type: constants.SEND_SOURCE_CODE_METADATA_CHANGES,
    sourceEditorType: SourceEditorType,
    payload: {
        path: string
        metadata: SourceMetaData
    }
}

export interface SendSourceCodeEditCancel extends Action {
    type: constants.SEND_SOURCE_CODE_EDIT_CANCEL,
    sourceEditorType: SourceEditorType,
    payload: {
        path: string
    }
}

export interface SendSourceCodeChangeTemplate extends Action {
    type: constants.SEND_SOURCE_CODE_CHANGE_TEMPLATE,
    sourceEditorType: SourceEditorType,
    payload: {
        template: Template
    }
}

export interface SendSourceCodeCancelTemplate extends Action {
    type: constants.SEND_SOURCE_CODE_CANCEL_TEMPLATE,
    sourceEditorType: SourceEditorType
}

export interface SendSourceCodeApplyTemplate extends Action {
    type: constants.SEND_SOURCE_CODE_APPLY_TEMPLATE,
    sourceEditorType: SourceEditorType
    payload: {
        path: string
    }
}

export interface SendSourceCodeChangeTemplateNode extends Action {
    type: constants.SEND_SOURCE_CODE_CHANGE_TEMPLATE_NODE,
    sourceEditorType: SourceEditorType,
    payload: {
        node: TemplateTreeNode
    }
}

export interface SendSourceCodeToggleMetaData extends Action {
    type: constants.SEND_SOURCE_CODE_TOGGLE_META_DATA,
    sourceEditorType: SourceEditorType,
}

export type SourceEditorAction = SendSourceTreeInfo | SendSourceTreeError | SendSourceTreeLoading | 
    SendSourceTreeSearchFilterLoading | SendSourceTreeSearchFilter |
    SendSourceTreeToggle | SendSourceTreeActive | SendSourceTreeToggleInplace | SendSourceTreeCreateDirectory |
    SendSourceCodeInfo | SendSourceCodeError | SendSourceCodeLoading |
    SendSourceCodeInitEdit | SendSourceCodeTextChanges | SendSourceCodeMetaDataChanges | SendSourceCodeEditCancel |
    SendSourceCodeChangeTemplate | SendSourceCodeCancelTemplate | SendSourceCodeApplyTemplate | SendSourceCodeChangeTemplateNode |
    SendSourceCodeToggleMetaData;

export function isSourceEditorAction(action: AnyAction): action is SourceEditorAction {
    return (typeof action.sourceEditorType == 'string');
}

/////////
//State//
/////////
export interface SourceEditorState extends SourceEditorTreeState {
    //Path -> Code Data
    code?: { [P: string]: SourceEditorCodeData | FetchError },
    //Code loading flags
    codeLoading?: { [P: string]: boolean },
    //Code of sources that are currently edited
    editCode?: { [P: string]: SourceEditorCodeData }
    //New source code template (to provide wizard for code creation)
    template?: Template
    //Display code metada
    showMetaData?: boolean
}

