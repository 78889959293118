import React from 'react';
import ReactDOM from 'react-dom';

class MaskedInput extends React.Component {

    constructor(props) {
        super(props);

        this.input = null;

        this.checkNewValue = this.checkNewValue.bind(this);
    }

    reset(input) {
        input.innerText = this.props.value;
    }

    select(input, selectionText, start, end) {
        if (!input || (!selectionText && (typeof start == "undefined" || typeof end == "undefiner"))) {
            console.error("Can't create selection of input");
            return;
        }
        input.focus();
        if (typeof input.selectionStart != "undefined") {
            input.selectionStart = start;
            input.selectionEnd = end;
        } else if (document.selection && document.selection.createRange) {
            // IE branch
            input.select();
            var range = document.selection.createRange();
            range.collapse(true);
            range.moveEnd("character", start);
            range.moveStart("character", end);
            range.select();
        }
    }

    checkNewValue(event) {
        let input = event.target;
        let value = input.value;
        if (this.props.allowedSymbols) {
            let reg = new RegExp("[^" + this.props.allowedSymbols + "]", "g");
            value = value.replace(reg, "");
        }
        if (this.props.firstSymbol) {
            let reg = new RegExp("^[^" + this.props.firstSymbol + "]");
            while (value.search(reg) != -1) {
                value = value.replace(reg, "");
            }
        }
        if (this.props.startsWith && value.indexOf(this.props.startsWith) != 0) {
            this.reset(input);
            return;
        }
        this.props.onChange(value);
    }

    componentDidMount() {
        let input = ReactDOM.findDOMNode(this);
        if (this.props.initialFocus) {
            input.focus();
        }
        if (this.props.initialSelection || (this.props.initialSelectionStart && this.props.initialSelectionEnd)) {
            window.setTimeout(() =>
                this.select(input, this.props.initialSelection, this.props.initialSelectionStart, this.props.initialSelectionEnd), 800);
        }
    }

    render() {
        return (
            <input
                className={this.props.className}
                style={this.props.style}
                type="text"
                id={this.props.id}
                value={this.props.value}
                onChange={this.checkNewValue}
                disabled={this.props.disabled} />
        );
    }
}

export default MaskedInput;


