import * as constants from '../constants/alert';
import { AlertState, AlertInfo } from '../types/alert';
import { ApplicationAction } from '../types';

function addAlert(state: AlertState, alert: AlertInfo) {
    const alertList = [...state.alertList, alert];
    return { ...state, alertList };
}

function removeAlert(state: AlertState, id: string) {
    const alertList = state.alertList.filter(alert => (alert.id != id));
    return { ...state, alertList };
}

const reducer = (state: AlertState = { alertList: [] }, action: ApplicationAction): AlertState => {
    switch (action.type) {
        case constants.SEND_ADD_ALERT: {
            return addAlert(state, action.payload);
        }
        case constants.SEND_REMOVE_ALERT: {
            return removeAlert(state, action.payload);
        }
    }
    return state;
}
export default reducer;