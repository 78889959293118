import React from 'react';

export interface RepeatProps {
    repeat: string
    keyField?: string
    delegate?: React.ComponentType<any>
    [P: string]: any
}

function getDataList(repeat: string, props: any): any {
    let path = repeat.split('.');
    let payload = props;
    for (let p of path) {
        if (typeof payload == 'object' && payload != null) {
            payload = payload[p];
        } else {
            payload = null;
            break;
        }
    }
    return payload
}

function getKey(item: any, index: number, keyField?: string) {
    return (typeof keyField == 'string' && typeof item == 'object' && item[keyField]) ?
        item[keyField] : index;
}

const Repeat: React.FunctionComponent<RepeatProps> = React.memo((props: RepeatProps) => {

    const { repeat, keyField, delegate, children, ...rest } = props

    //By default use list property
    let dataList = props['list']

    //If repeat was set up then get field from props
    if (repeat) {
        dataList = getDataList(repeat, props);
    }

    if (!Array.isArray(dataList)) {
        return null
    }

    if (delegate) {
        const C = delegate
        return <>
            {
                dataList.map((item, index) => {
                    if (!item) {
                        return null;
                    }
                    const key = getKey(item, index, keyField)
                    return <C key={key} {...rest} item={item} index={index} />
                })
            }
        </>
    }

    const el = React.Children.only(children);
    return <>
        {
            dataList.map((item, index) => {
                const key = getKey(item, index, keyField)
                return React.cloneElement(el, { ...rest, key, item, index })
            })
        }
    </>
})

export default Repeat;