import { Reducer } from "redux";

import {
  SecurityState,
  SecurityAction,
  isLoginPageInfo,
  isLoginStatus,
  isLoggedInUser,
  LoginStatus,
  LoggedInUser,
} from "../types/security";
import * as constants from "../constants/security";
import { fillDomainMap } from "../services/security";

const DEFAULT_STATE: SecurityState = {};

const reducer: Reducer<SecurityState, SecurityAction> = (
  state: SecurityState = DEFAULT_STATE,
  action: SecurityAction
): SecurityState => {
  switch (action.type) {
    case constants.SEND_LOGIN:
      const loginStatus = { ...action.payload.info };
      if (isLoginPageInfo(loginStatus)) {
        fillDomainMap(loginStatus);
      }
      return { ...state, loading: false, loginStatus };
    case constants.SEND_LOGIN_ERROR:
      return { ...state, loading: false, loginStatus: action.payload.error };
    case constants.SEND_LOGIN_LOADING:
      return { ...state, loading: true };
    case constants.SEND_MESSAGE_STAT_UPDATE:
      const stats = action.payload;
      if (isLoggedInUser(state.loginStatus)) {
        const newState = { ...state };
        newState.loginStatus = { ...newState.loginStatus } as LoggedInUser;
        newState.loginStatus.messages = stats;
        return newState;
      }
      return state;
    case constants.SEND_LOGIN_CODE_REQUIRED: {
      const x = state.loginStatus;
      if (isLoginPageInfo(x)) {
        const loginStatus = { ...x };
        loginStatus.exception = "TOTPCodeRequiredException";
        return { ...state, loginStatus };
      }
      return state;
    }
  }
  return state;
};

export default reducer;
