export function joinParams(obj: { [K: string]: string }) {
  return Object.keys(obj)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]))
    .join("&");
}

export class ServerError extends Error {
  code: number;

  constructor(code: number, text: string) {
    super(text);
    this.code = code;
  }
}

export async function receiveServerError(resp: Response): Promise<ServerError> {
  return new ServerError(resp.status, await receiveServerMessage(resp));
}

export async function receiveServerMessage(resp: Response): Promise<string> {
  const code = resp.status;
  let message = resp.statusText;
  if (
    message &&
    (code === 200 || code === 403 || code === 404 || code === 500)
  ) {
    return parseServerMessage(code, message);
  }

  try {
    const text = await resp.text();
    return text || message;
  } catch (e) {}
  return message;
}

export function parseServerMessage(code: number, message: string): string {
  if (code === 200) {
    return "formattedId::NPT_SERVER_MESSAGE_SUCCESS";
  }

  if (code === 403) {
    return "formattedId::NPT_SERVER_MESSAGE_ACCESS_DENIED";
  }
  if (code === 404) {
    return "formattedId::NPT_SERVER_MESSAGE_NOT_FOUND";
  }
  if (code === 500) {
    return !message.includes("<!doctype")
      ? message
      : "formattedId::NPT_SERVER_MESSAGE_SERVER_ERROR";
  }
  return message;
}
