import React from 'react';

interface ToolbarProps {
}

export default class Toolbar extends React.Component<ToolbarProps>{

    constructor(props: ToolbarProps) {
        super(props);

    }

    render() {
        return null;
    }
}