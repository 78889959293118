import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCode,
  faEdit,
  faEye,
  faEyeSlash,
  faMinusCircle,
  faPencilAlt,
  faPlusCircle,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Children,
  ComponentClass,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import {
  DndContext,
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from "react-dnd";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { sendMenuEditorMenuToggled } from "../../../actions/menueditor";
import { EditorState } from "../../../types/menueditor";
import { NodeProps, TreeProps } from "../../../types/tree";
import { NodeState } from "../../tree/ListTree";
import styles from "../../tree/ListTree.module.css";
import { wrapWithTooltip } from "./MenuEditorPage";

const MENU_EDITOR_FILLER_CLASS = "npt-menu-e-filler";
const MENU_EDITOR_ITEM_CLASS = "npt-menu-e-item";
interface DndProps {
  setOverId: (id: string) => void;
  getOverId: () => string;
  expandNode: (id: string, isExpanded: boolean) => void;
  setDraggedItem: (id: string) => void;
}
const initial: DndProps = {
  setOverId: (id: string) => {},
  getOverId: () => "",
  expandNode: (id: string, isExpanded: boolean) => {},
  setDraggedItem: (id: string) => {},
};
export const DndMenuContext = React.createContext<DndProps>(initial);

export const DragHeader = (props: {
  id: string;
  dragType: string;
  data: object;
  children: (isDragging: boolean) => any;
}) => {
  const dnd = useContext(DndMenuContext);
  const { setDraggedItem } = dnd;
  const { id } = props;
  const [collectedProps, drag] = useDrag({
    type: props.dragType,
    item: { ...props.data },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // begin: (monitor: DragSourceMonitor) => {
    //     setDraggedItem(id)
    // },
    end: (dropResult: any | undefined, monitor: DragSourceMonitor) => {
      setDraggedItem("");
    },
  });

  return (
    <div
      ref={drag}
      className="position-relative"
      style={{ display: "inline", zIndex: 20 }}
    >
      {props.children(collectedProps.isDragging)}
    </div>
  );
};

export const DropHeaderBase = (props: {
  id: string;
  className?: string;
  isLeaf: boolean;
  isExpanded: boolean;
  dropTypes: string[];
  drop: (data: object) => void;
  children: (isOver: boolean) => any;
}) => {
  const { isExpanded, className, id } = props;
  const [expanded, setExpanded] = useState(false);
  const dnd = useContext(DndMenuContext);
  const { expandNode } = dnd;
  const [{ isOver, item }, drop] = useDrop({
    accept: props.dropTypes,
    drop: (item: any) => props.drop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
  });
  if (isOver && !expanded) {
    if (isExpanded) {
      expandNode(id, false);
    } else {
      expandNode(id, true);
    }
    setExpanded(true);
  } else if (!isOver && expanded) {
    setExpanded(false);
  }

  return (
    <div
      className={className}
      ref={drop}
      style={{ display: "inline-block", position: "relative", zIndex: 20 }}
    >
      {props.children(isOver)}
    </div>
  );
};

export const DropOrderHeader = (props: {
  id: string;
  isBefore: boolean;
  dropTypes: string[];
  drop: (data: object) => void;
  children: (dropRef: any) => any;
}) => {
  const { id, isBefore } = props;
  const dnd = useContext(DndMenuContext);
  const { getOverId, setOverId } = dnd;
  const refBefore = useRef(null);
  const refAfter = useRef(null);
  const [{ isOver, item }, drop] = useDrop({
    accept: props.dropTypes,
    drop: (item: any) => props.drop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
  });

  const expandBlock = (ref: any, height: string) => {
    if (!ref || !ref.current || (item && id === item.id)) {
      return;
    }
    const style = (ref.current as any).style;
    style["height"] = height;
    style["transition-property"] = "height";
    style["transition-duration"] = "200ms";
  };
  setInterval(() => {
    const isOverId = getOverId() === id;
    if (isBefore) {
      if (isOverId) {
        expandBlock(refBefore, "2.4ref");
        expandBlock(refAfter, "0");
      } else {
        expandBlock(refBefore, "0");
        expandBlock(refAfter, "0");
      }
    } else {
      if (isOverId) {
        expandBlock(refBefore, "0");
        expandBlock(refAfter, "2.4ref");
      } else {
        expandBlock(refBefore, "0");
        expandBlock(refAfter, "0");
      }
    }
  }, 1000);

  if (isOver) {
    setOverId(id);
  }
  const renderFiller = (ref: any) => {
    return (
      // <div   ref={ref} className={`${isOver?'p-1':''}`} style={{ height: isOver ? '2.4rem' : '0', transition: 'height 300ms' }}  >
      <div
        className={MENU_EDITOR_FILLER_CLASS}
        ref={ref}
        style={{ height: isOver ? "2.4rem" : "0", transition: "height 300ms" }}
      >
        <div
          style={{ opacity: 0.3 }}
          className={`bg-success mb-1  rounded w-100 h-100`}
        ></div>
      </div>
    );
  };
  return (
    <div ref={drop} style={{ display: "inline" }}>
      {isBefore && renderFiller(refBefore)}
      {props.children(drop)}
      {!isBefore && renderFiller(refAfter)}
    </div>
  );
};

const DropHeader = connect(
  null,
  (dispatch: ThunkDispatch<{}, {}, any>, ownProps: { path: string }) => {
    const { path } = ownProps;
    return {
      expandNode: (id: string, expanded: boolean) =>
        dispatch(sendMenuEditorMenuToggled(expanded, id, path)),
    };
  }
)(DropHeaderBase);

export const MenuHeader = (props: {
  menuId?: string;
  isDragging?: boolean;
  children: any;
}) => {
  if (!props.menuId) {
    return props.children;
  }

  return (
    <ContextMenuTrigger
      holdToDisplay={-1}
      id={props.menuId}
      disable={props.isDragging ? true : false}
      renderTag="div"
      attributes={{ style: { width: "100%" } }}
    >
      {props.children}
    </ContextMenuTrigger>
  );
};

interface MenuNodeProps extends NodeProps {
  order?: number;
  expandedNodes?: string[];
  isDragging?: boolean;
  first?: boolean;
  last?: boolean;
  draggedId?: string;
  isRoot?: boolean;
  editItem?: (id: string, path: string) => void;
  createMenuItem?: (path: string, parentId?: string) => void;
  goToView?: (path: string, id: string) => void;
  changeOrder?: (itemId: string, isBefore: boolean) => void;
}
interface MenuNodeStates extends NodeState {
  checked?: boolean;
}
export class MenuNode extends React.Component<MenuNodeProps, MenuNodeStates> {
  constructor(props: MenuNodeProps) {
    super(props);
    this.collect = this.collect.bind(this);
    this.state = {
      expanded: this.props.expanded, //copy initial value
      prevInplace:
        typeof this.props.inplace == "boolean" ? this.props.inplace : false,
    };
  }

  /**
   *
   * Implement edge trigger inplace
   *
   * https://ru.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
   *
   * @param props component properties
   * @param state component state
   */

  static getDerivedStateFromProps(props: NodeProps, state: NodeState) {
    const currentInplace =
      typeof props.inplace == "boolean" ? props.inplace : false;
    if (currentInplace !== state.prevInplace) {
      //Edge trigger inplace
      if (currentInplace && props.edgeTriggerInplace) {
        return {
          prevInplace: true,
          inplace: props.name || props.id,
        };
      }
      return {
        prevInplace: currentInplace,
      };
    }
    return null;
  }

  toggle() {
    //Toggle
    const t =
      this.props.toggle || ((expanded: boolean) => this.setState({ expanded }));
    const s = this.isExpanded();
    const l = this.props.loadChildren;
    if (!s && this.props.childrenIds == null && l) {
      l();
    }
    t(!s);
  }

  toggleInplace(value: boolean) {
    if (value) {
      this.setState({ inplace: this.props.name || this.props.id });
    } else {
      const e = this.props.editInplace;
      if (e) {
        e(this.state.inplace || "");
      }
      this.setState({ inplace: undefined });
    }
  }

  contextMenu() {
    //Activate
    const cm = this.props.contextMenu;
    if (cm) {
      cm();
    }
  }

  linkHandle(link: string): void {
    //Handle links location
    const lh = this.props.linkHandle;
    if (lh) {
      lh(link);
    }
  }

  activate() {
    //Activate
    const a = this.props.activate;
    if (a) {
      a(this.props.data);
    }
  }

  isLeaf() {
    return typeof this.props.childrenIds == "undefined";
  }

  isInplace() {
    return typeof this.state.inplace != "undefined";
  }

  isExpanded() {
    if (this.props.toggle) {
      //controlled externally
      return this.props.expanded || false;
    }
    return this.state.expanded || false; //self controlled
  }

  renderCheck() {
    const icon = this.props.active ? faCheckSquare : faSquare;
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.activate()}
        className="px-2 my-1 mr-1 bg-white rounded border"
      >
        <FontAwesomeIcon icon={icon} className="text-warning m-0 p-0" />
      </div>
    );
  }
  renderEdit() {
    const { editItem, id, treeId } = this.props;
    if (!editItem) {
      return null;
    }
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => editItem(id, treeId)}
        className="pr-1 pl-2 my-1 bg-white rounded border"
      >
        <FontAwesomeIcon icon={faEdit} className="text-success m-0 p-0" />
      </div>
    );
  }
  renderCreate() {
    const { createMenuItem, id, treeId } = this.props;
    if (!createMenuItem) {
      return null;
    }
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => createMenuItem(treeId, id)}
        className="pr-1 pl-2 my-1 bg-white rounded border"
      >
        <FontAwesomeIcon icon={faWrench} className="text-success m-0 p-0" />
      </div>
    );
  }

  renderGoToView() {
    const { goToView, id, treeId } = this.props;
    if (!goToView) {
      return null;
    }
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => goToView(treeId, id)}
        className="px-1 my-1 bg-white rounded border"
      >
        <FontAwesomeIcon icon={faEye} className="text-success m-0 p-0" />
      </div>
    );
  }
  renderDragAnchor() {
    return (
      <DragHeader
        id={this.props.id}
        dragType={"drop-item"}
        data={this.collect()}
      >
        {(isDragging) => (
          <div
            style={{
              height: "1.6rem",
              width: "0.35rem",
              cursor: isDragging ? "grabbing" : "grab",
            }}
            className="bg-white ml-1 my-1 rounded border "
          >
            {" "}
          </div>
        )}
      </DragHeader>
    );
  }
  renderToggle() {
    const { id, name, dropTypes } = this.props;
    if (this.isLeaf()) {
      return (
        <FontAwesomeIcon
          icon={faMinusCircle}
          style={{ visibility: "hidden" }}
          className={" tree-node-toggle ml-1 text-white"}
        />
      );
    }
    const icon = this.isExpanded() ? faMinusCircle : faPlusCircle;

    return (
      <DropHeader
        id={this.props.id}
        drop={() => {}}
        dropTypes={["drop-item"]}
        isExpanded={this.isExpanded()}
        isLeaf={this.isLeaf()}
        path={this.props.treeId}
      >
        {(isOver) => (
          <a
            style={{ paddingTop: ".1rem" }}
            className="m-1 py-1 bg-success rounded text-secondary"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.toggle();
            }}
          >
            <FontAwesomeIcon
              icon={icon}
              className={" tree-node-toggle mx-1 text-white"}
            />
          </a>
        )}
      </DropHeader>
    );
  }

  renderEditToggle() {
    return (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          this.toggleInplace(true);
        }}
      >
        <FontAwesomeIcon icon={faPencilAlt} className="tree-node-leaf ml-2" />
      </a>
    );
  }

  collect() {
    return {
      id: this.props.id,
      isLeaf: this.isLeaf(),
      isExpanded: this.isExpanded(),
    };
  }

  renderHeaderInplace() {
    return (
      <span>
        <input
          className="form-control w-auto h-auto p-1 d-inline"
          autoFocus
          type="text"
          value={this.state.inplace}
          onChange={(e) => this.setState({ inplace: e.currentTarget.value })}
          onBlur={(e) => this.toggleInplace(false)}
          onKeyUp={(e) => {
            if (e.keyCode == 13) this.toggleInplace(false);
          }}
        />
      </span>
    );
  }

  renderLinkContent() {
    return (
      <>
        <span className={"font-weight-bold ml-2 w-100  "}>
          {this.props.editing && " • "}
          {this.props.name || this.props.id}
        </span>
      </>
    );
  }
  /**
   * Pure header link (without wrappers)
   */
  renderHeaderLink() {
    const { contextPath: ctx, hidden } = this.props;
    return (
      <div
        className="font-weight-bold ml-2 h-100  "
        style={{ userSelect: "none" }}
        onClick={(e) => {
          this.activate();
        }}
      >
        {hidden && (
          <span className="mr-2 text-success">
            <FontAwesomeIcon icon={faEyeSlash} />
          </span>
        )}
        <span>{this.props.name || this.props.id}</span>
      </div>
    );
  }

  /**
   * Header wrapped as menu trigger
   */
  renderMenuHeader(isDragging?: boolean) {
    const menuId = this.props.menuId;
    // if (menuId) {
    //     return <MenuHeader menuId={menuId}  >
    //         {this.renderHeaderLink()}
    //     </MenuHeader>
    // }
    return this.renderHeaderLink();
  }

  /**
   * Header wrapped as drag source
   */
  renderDragHeader() {
    const dragType = this.props.dragType;
    return (
      <div className="">
        <DragHeader
          id={this.props.id}
          dragType={"drop-item"}
          data={this.collect()}
        >
          {(isDragging) => this.renderMenuHeader(isDragging)}
        </DragHeader>
      </div>
    );
  }

  renderChildren() {
    if (this.isLeaf() || !this.props.expanded) {
      return null;
    }
    const { id, isDragging } = this.props;
    const l = this.props.childrenIds;
    const C = this.props.renderComponent || MenuNode;
    if (!Array.isArray(l) || typeof C == "undefined") {
      return null;
    }

    return (
      <ul className={"pl-4 " + styles.unstyled}>
        {l.map((id, idx) => {
          const count = l.length;
          let first = idx === 0;
          let last = idx === count - 1;
          return (
            <C
              {...this.props}
              isRoot={false}
              key={id}
              first={first}
              last={last}
              order={idx}
              id={id}
            />
          );
        })}
      </ul>
    );
  }

  renderOrderDropPoints(isBefore: boolean) {
    const {
      id,
      name,
      dropTypes,
      isDragging,
      first,
      last,
      changeOrder: co,
      isRoot,
    } = this.props;
    const bottomStyle = !isBefore ? { top: "1.22rem" } : {};
    if (
      (isRoot && first && last) ||
      (first === undefined && last === undefined)
    ) {
      return null;
    }
    if (isRoot && !isBefore && (first || (!first && !last))) {
      return null;
    }
    return (
      <DropOrderHeader
        id={this.props.id}
        drop={(item: any) => {
          co && co(item.id, isBefore);
        }}
        dropTypes={["drop-item"]}
        isBefore={isBefore}
      >
        {(ref) => (
          <div
            ref={ref}
            className="  "
            onContextMenu={(e) => {
              this.contextMenu();
            }}
            style={{
              height: !last ? "1.2rem" : "1rem",
              zIndex: 10,
              width: "100%",
              position: "absolute",
              ...bottomStyle,
            }}
          ></div>
        )}
      </DropOrderHeader>
    );
  }

  render() {
    // if (this.props.hidden) {
    //     return null;
    // }
    const dropType = this.isLeaf() ? "item-leaf" : "item-container";
    const { id, name, dropTypes, first, last, draggedId, menuId } = this.props;
    return (
      <li
        className={`${styles.item} ${first && "mt-1"} mb-1 position-relative  `}
        key={id}
      >
        <MenuHeader menuId={menuId}>
          {this.renderOrderDropPoints(true)}

          <div
            onContextMenu={(e) => {
              this.contextMenu();
            }}
            style={{ opacity: draggedId === id ? 0.5 : 1 }}
            className={`border rounded ${
              draggedId === id ? "bg-warning" : "bg-secondary"
            } shadow-sm   d-flex flex-row justify-content-between  ${MENU_EDITOR_ITEM_CLASS} `}
          >
            <div className="d-flex flex-row w-100 position-relative">
              {this.renderDragAnchor()}
              {this.renderMenuHeader(false)}
            </div>
            <div className="d-flex flex-row" style={{ zIndex: 20 }}>
              <div className="d-flex flex-row toolbar">
                {this.props.inplace &&
                  !this.isInplace() &&
                  this.renderEditToggle()}
                {wrapWithTooltip(
                  "MENU_EDITOR_INFO_MODAL_TITLE_ITEM_EDIT",
                  this.renderEdit()
                )}
                {wrapWithTooltip(
                  "MENU_EDITOR_INFO_MODAL_TITLE_ITEM_CREATE",
                  this.renderCreate()
                )}
                {wrapWithTooltip(
                  "MENU_EDITOR_INFO_MODAL_TITLE_GO_TO_VIEW",
                  this.renderGoToView()
                )}
                {this.renderCheck()}
              </div>
              <div
                className={`${
                  this.isLeaf()
                    ? "ml-2"
                    : "d-flex flex-column justify-content-center"
                } mr-1`}
              >
                {this.renderToggle()}
              </div>
            </div>
          </div>
        </MenuHeader>
        {this.renderChildren()}
        <MenuHeader menuId={menuId}>
          {this.renderOrderDropPoints(false)}
        </MenuHeader>
      </li>
    );
  }
}

/*
    List Tree Component itself
 
    http://cssdeck.com/labs/pure-css3-expand-collapse
    https://codeburst.io/how-to-make-a-collapsible-menu-using-only-css-a1cd805b1390
    https://developers.google.com/web/updates/2017/03/performant-expand-and-collapse
*/

interface MenuTreeProps extends TreeProps {
  expandNode: (id: string, isExpanded: boolean) => void;
}
interface MenuTreeStates {
  overId: string;
  isDragging: boolean;
  draggedItemId: string;
}
export class MenuListTree extends React.Component<
  MenuTreeProps,
  MenuTreeStates
> {
  expandedNodeStack: string[] = [];
  overId: string = "";
  state = { overId: "", isDragging: false, draggedItemId: "" };
  componentDidMount() {
    const l = this.props.loadRoots;
    if (l && typeof this.props.roots == "undefined") {
      l();
    }
  }

  treeContextMenuHandler = (e: any) => {
    if (e.nativeEvent.which === 3) {
    }
  };

  setOverId = (id: string) => {
    this.overId = id;
  };
  getOverId = () => {
    return this.overId;
  };

  setDraggedItem = (id: string) => {
    this.setState({ draggedItemId: id });
  };
  getDndContextProps = (): DndProps => {
    return {
      setOverId: this.setOverId,
      getOverId: this.getOverId,
      expandNode: this.props.expandNode,
      setDraggedItem: this.setDraggedItem,
    };
  };
  render() {
    const C = this.props.renderComponent || MenuNode;
    const r = this.props.roots || [];

    const contextMenuId = this.props.menuId;
    return (
      <DndMenuContext.Provider value={this.getDndContextProps()}>
        <ul
          onClick={this.treeContextMenuHandler}
          className={"p-1   " + styles.unstyled}
        >
          {r.map((id, idx) => {
            const count = r.length;
            let first = idx === 0;
            let last = idx === count - 1;
            return (
              <C
                isRoot={true}
                key={id}
                draggedId={this.state.draggedItemId}
                isDragging={this.state.isDragging}
                first={first}
                last={last}
                order={idx}
                menuId={contextMenuId}
                id={id}
                treeId={this.props.treeId}
                renderComponent={this.props.renderComponent}
              />
            );
          })}
        </ul>
      </DndMenuContext.Provider>
    );
  }
}
