import * as Redux from "redux";
import { openModal as openModalAction } from "./actions/modal";
import { logout, sendMesageStatUpdate } from "./actions/security";
import { fetchWorker } from "./services/worker";
import { ApplicationAction, ApplicationState } from "./types";
import { dispatchErrorV2, sendAddAlert } from "./actions/alert";
import {
  CancelCallback,
  CloseCallback,
  I18NString,
  ModalOptions,
  OkCallback,
  PortalCallback,
  PortalOptions,
} from "./types/modal";
import { isLoggedInUser, MessagesStatistics } from "./types/security";
import { AlertLevelType, AlertOptions } from "./types/alert";
import {
  isRegisteredNamedModal,
  openNamedModal,
} from "./components/helpers/NamedModal";
import {
  TooltipOpenEvent,
  openNamedTooltip,
} from "./components/helpers/NamedTooltip";
import { changePassword, setupHomePage } from "./actions/menu";
import { ThunkDispatch } from "redux-thunk";
import { menuComponentWithStore } from "./components/menu/MinifiedMenuControls";
import { closeMenuWithStore } from "./components/menu/SideMenuLayout";

export function createUtils(
  store: Redux.Store<ApplicationState, ApplicationAction>
) {
  const openTooltip = (
    id: string,
    evt: TooltipOpenEvent,
    extraProps?: { [PROP: string]: any }
  ) => {
    openNamedTooltip(id, evt, extraProps);
  };

  const openModal = (
    id: string,
    typeOrExtraProps?: string | { [PROP: string]: any },
    options?: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback,
    portalCallback?: PortalCallback
  ) => {
    if (typeof typeOrExtraProps == "string") {
      if (typeof options == "undefined") {
        console.error("NPT standard modal options must be specified");
        return;
      }
      store.dispatch(
        openModalAction(
          id,
          typeOrExtraProps,
          options,
          okCallback,
          cancelCallback,
          closeCallback,
          portalCallback
        )
      );
    } else {
      if (!isRegisteredNamedModal(id)) {
        console.error("Named modal is not registered: " + id);
        return;
      }
      if (typeof typeOrExtraProps == "object" && typeOrExtraProps != null) {
        openNamedModal(id, typeOrExtraProps);
      } else {
        openNamedModal(id);
      }
    }
  };

  const updateMessageStat = (stats: MessagesStatistics) => {
    store.dispatch(sendMesageStatUpdate(stats));
  };

  //Used by plugins to popup dialogs
  const openPortalModal = (
    id: string,
    portalCallback?: PortalCallback,
    options?: PortalOptions
  ) => {
    const { onOk, onCancel, onClose, ...rest } = options
      ? options
      : ({} as PortalOptions);
    openModal(id, "portal", rest, onOk, onCancel, onClose, portalCallback);
  };
  const addAlert = (
    id: string,
    type: AlertLevelType,
    message: string | I18NString,
    options?: AlertOptions
  ) => {
    store.dispatch(sendAddAlert(id, type, message, options));
  };

  const dispatchError = (messageId: string, e: any, options?: any) => {
    dispatchErrorV2(`formattedId::${messageId}`, e, store.dispatch, options);
  };

  const hasAuthority = (authority: string) => {
    const state = store.getState();
    const status = state.security.loginStatus;
    if (isLoggedInUser(status)) {
      return status.authorities.includes(authority);
    }
    return false;
  };

  const getLocationParams = () => {
    return store.getState().location.params;
  };
  const getMenuComponent = menuComponentWithStore(store);
  const getMenuClose = closeMenuWithStore();
  return {
    openTooltip,
    openModal,
    openPortalModal,
    fetchWorker,
    hasAuthority,
    addAlert,
    dispatchError,
    updateMessageStat,
    getLocationParams,
    getMenuComponent,
    getMenuClose,
  };
}
