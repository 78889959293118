import React from "react";
import { IntlShape, useIntl } from "react-intl";
import { TransitionGroup } from "react-transition-group";
import { AlertInfo, AlertLevelType } from "../../types/alert";
import { I18NString, isI18NString } from "../../types/modal";
import { parseAlertMessage } from "../../services/alert";

import Container, { AlertPosition } from "./AlertContainer";
import Alert from "./AlertTimer";
import AlertTransition from "./AlertTransition";

interface AlertListProps {
	alerts: AlertInfo[]
	timeout: number
	position?: AlertPosition
	onDismiss?: (id: string) => void
};

function replaceFormattedMessagePlaceholder(message: string, intl: IntlShape): string {
	if (typeof message !== "string" || message.indexOf("::") === -1) {
		return message;
	}
	const formattedMessageStart = message.indexOf("formattedId::");
	console.log(formattedMessageStart)
	if (formattedMessageStart !== -1) {
		const searchSubstr = message.substring(formattedMessageStart + "formattedId::".length);
		let formattedMessageLength = searchSubstr.search(/[^A-Z_]/);
		if (formattedMessageLength === -1) {
			formattedMessageLength = searchSubstr.length;
		}
		const formattedId = searchSubstr.substring(0, formattedMessageLength);
		const formattedMessage = intl.formatMessage({ id: formattedId });
		message = message.substring(0, formattedMessageStart) + formattedMessage + message.substring(formattedMessageStart + "formattedId::".length + formattedMessageLength);
	}
	return message;
}

/**Handle special message values to change alert type */
function parseItemMessage(item: AlertInfo, intl: IntlShape): { message: string | I18NString, type: AlertLevelType } {
	if (!item.message) {
		return item;
	}
	if (isI18NString(item.message)) {
		if (!item.message.values) {
			return item;
		}
		const message = { ...item.message };
		message.values = { ...message.values };
		for (let p in message.values) {
			message.values[p] = replaceFormattedMessagePlaceholder(message.values[p], intl);
		}
		return Object.assign({}, item, { message });
	}
	if (item.message.indexOf("::") === -1) {
		return item;
	}
	const { message, type } = parseAlertMessage(item.message, item.type);
	return Object.assign({}, item, {
		message: replaceFormattedMessagePlaceholder(message, intl),
		type
	});
}

//Allowes to display list of alerts
const AlertList: React.FunctionComponent<AlertListProps> = React.memo((props) => {
	const intl = useIntl();
	const { position, alerts, onDismiss } = props;
	return (
		<Container position={position}   >
			<TransitionGroup>
				{alerts.map((item: AlertInfo) => {
					const dismiss = onDismiss ? () => onDismiss(item.id) : null;
					const alertProps = item;
					const { message, type } = parseItemMessage(item, intl);
					return (
						<AlertTransition key={item.id}>
							<Alert {...props} {...alertProps} type={type} onDismiss={dismiss}>
								{message}
							</Alert>
						</AlertTransition>
					);
				})}
			</TransitionGroup>
		</Container>
	)
});

export default AlertList;