import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const MSG_BUTTON_CLOSE = (
    <FormattedMessage id="MSG_BUTTON_CLOSE"
        defaultMessage="Close"
        description="Close button caption" />);

export const MSG_BUTTON_OK = (
    <FormattedMessage id="MSG_BUTTON_OK"
        defaultMessage="OK"
        description="OK button caption" />);

export const MSG_BUTTON_CONFIRM = (
    <FormattedMessage
        id="MSG_BUTTON_CONFIRM"
        defaultMessage="Confirm"
        description="Confirm button caption" />);

export const MSG_BUTTON_CANCEL = (
    <FormattedMessage id="MSG_BUTTON_CANCEL"
        defaultMessage="Cancel"
        description="Cancel button caption" />);

export const MSG_BUTTON_ADD = (
    <FormattedMessage id="MSG_BUTTON_ADD"
        defaultMessage="Add"
        description="Add button caption" />);

export const MSG_BUTTON_UPLOAD = (
    <FormattedMessage id="MSG_BUTTON_UPLOAD"
        defaultMessage="Upload"
        description="Upload button caption" />);

export const MSG_BUTTON_DELETE = (
    <FormattedMessage id="MSG_BUTTON_DELETE"
        defaultMessage="Delete"
        description="Delete button caption" />);

export const MSG_BUTTON_SAVE = (
    <FormattedMessage id="MSG_BUTTON_SAVE"
        defaultMessage="Save"
        description="Save button caption" />);

export const MSG_BUTTON_WAIT = (
    <FormattedMessage
        id="MSG_BUTTON_WAIT"
        defaultMessage="Wait"
        description="Placeholder for disabled buttons that are waiting for action" />);

export const MSG_MONTH_JANUARY = (
    <FormattedMessage
        id="MSG_MONTH_JANUARY"
        defaultMessage="January"
        description="January" />)

export const MSG_MONTH_FEBURARY = (
    <FormattedMessage
        id="MSG_MONTH_FEBURARY"
        defaultMessage="Feburary"
        description="Feburary" />)

export const MSG_MONTH_MARCH = (
    <FormattedMessage
        id="MSG_MONTH_MARCH"
        defaultMessage="March"
        description="March" />)

export const MSG_MONTH_APRIL = (
    <FormattedMessage
        id="MSG_MONTH_APRIL"
        defaultMessage="April"
        description="April" />)

export const MSG_MONTH_MAY = (
    <FormattedMessage
        id="MSG_MONTH_MAY"
        defaultMessage="May"
        description="May" />)

export const MSG_MONTH_JUNE = (
    <FormattedMessage
        id="MSG_MONTH_JUNE"
        defaultMessage="June"
        description="June" />)

export const MSG_MONTH_JULY = (
    <FormattedMessage
        id="MSG_MONTH_JULY"
        defaultMessage="July"
        description="July" />)

export const MSG_MONTH_AUGUST = (
    <FormattedMessage
        id="MSG_MONTH_AUGUST"
        defaultMessage="August"
        description="August" />)

export const MSG_MONTH_SEPTEMBER = (
    <FormattedMessage
        id="MSG_MONTH_SEPTEMBER"
        defaultMessage="September"
        description="September" />)

export const MSG_MONTH_OCTOBER = (
    <FormattedMessage
        id="MSG_MONTH_OCTOBER"
        defaultMessage="October"
        description="October" />)

export const MSG_MONTH_NOVEMBER = (
    <FormattedMessage
        id="MSG_MONTH_NOVEMBER"
        defaultMessage="November"
        description="November" />)

export const MSG_MONTH_DECEMBER = (
    <FormattedMessage
        id="MSG_MONTH_DECEMBER"
        defaultMessage="December"
        description="December" />)

export const MSG_WEEK_DAY_MONDAY = (
    <FormattedMessage
        id="MSG_WEEK_DAY_MONDAY"
        defaultMessage="Monday"
        description="Monday" />)

export const MSG_WEEK_DAY_TUESDAY = (
    <FormattedMessage
        id="MSG_WEEK_DAY_TUESDAY"
        defaultMessage="Tuesday"
        description="Tuesday" />)

export const MSG_WEEK_DAY_WEDNESDAY = (
    <FormattedMessage
        id="MSG_WEEK_DAY_WEDNESDAY"
        defaultMessage="Wednesday"
        description="Wednesday" />)

export const MSG_WEEK_DAY_THURSDAY = (
    <FormattedMessage
        id="MSG_WEEK_DAY_THURSDAY"
        defaultMessage="Thursday"
        description="Thursday" />)

export const MSG_WEEK_DAY_FRIDAY = (
    <FormattedMessage
        id="MSG_WEEK_DAY_FRIDAY"
        defaultMessage="Friday"
        description="Friday" />)

export const MSG_WEEK_DAY_SATURDAY = (
    <FormattedMessage
        id="MSG_WEEK_DAY_SATURDAY"
        defaultMessage="Saturday"
        description="Saturday" />)

export const MSG_WEEK_DAY_SUNDAY = (
    <FormattedMessage
        id="MSG_WEEK_DAY_SUNDAY"
        defaultMessage="Sunday"
        description="Sunday" />)

export const MSG_TABLE_NO_DATA_TO_DISPLAY = (
    <FormattedMessage
        id="MSG_TABLE_NO_DATA_TO_DISPLAY"
        defaultMessage="There is no data to display"
        description="This message will be displayed in empty tables" />)

export const MSG_TABLE_NO_DATA = (
    <FormattedMessage
        id="MSG_TABLE_NO_DATA"
        defaultMessage="There is no data"
        description="This message will be displayed in empty tables" />)

export const MSG_OR = (
    <FormattedMessage
        id="MSG_OR"
        defaultMessage="Or"
        description="Or" />)

export const MSG_FROM = (
    <FormattedMessage
        id="MSG_FROM"
        defaultMessage="From"
        description="From" />)

export const MSG_TO = (
    <FormattedMessage
        id="MSG_TO"
        defaultMessage="To"
        description="To" />)

export const MSG_ALL = (
    <FormattedMessage
        id="MSG_ALL"
        defaultMessage="All"
        description="All" />)

export const MSG_ERROR = (
    <FormattedMessage
        id="MSG_ERROR"
        defaultMessage="Error"
        description="Error" />)

export const MSG_CONFIRM_ACTION = (
    <FormattedMessage
        id="MSG_CONFIRM_ACTION"
        defaultMessage="Confirm action"
        description="Confirm action" />)

export const MSG_SELECT_PLACEHOLDER = (
    <FormattedMessage
        id="MSG_SELECT_PLACEHOLDER"
        defaultMessage="Select..."
        description="User must enter text to find predicate" />)

export const MSG_SELECT_NO_RESULTS = (
    <FormattedMessage
        id="MSG_SELECT_NO_RESULTS"
        defaultMessage="No results found"
        description="There's no results corresponding to user's filter" />)

export const MSG_SELECT_LOADING = (
    <FormattedMessage
        id="MSG_SELECT_LOADING"
        defaultMessage="Loading..."
        description="Available predicates is loading" />)

export const MSG_STATUS_FORBIDDEN = (
    <FormattedMessage id="MSG_STATUS_FORBIDDEN"
        defaultMessage="Access forbidden"
        description="Access to the requested resource is forbidden" />);

export const MSG_STATUS_NOT_FOUND = (
    <FormattedMessage id="MSG_STATUS_NOT_FOUND"
        defaultMessage="Not found"
        description="Requested resource wasn't found" />);

export const MSG_STATUS_LOADING = (
    <FormattedMessage id="MSG_STATUS_LOADING"
        defaultMessage="Loading"
        description="Waiting for response" />);

export const MSG_STATUS_ERROR = (
    <FormattedMessage id="MSG_STATUS_ERROR"
        defaultMessage="Error"
        description="Error status" />);

export const MSG_LOADING_ERROR = (
    <FormattedMessage id="MSG_LOADING_ERROR"
        defaultMessage="Error on loading"
        description="Request failed" />);