import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  add,
  change,
  changeAt,
  createNewSubject,
  fetchLock,
  fetchSubject,
  initializeStore,
  saveSubject,
  sendSubjectDestroy,
  sendSubjectSaveCancel,
  sendSubjectStartSave,
  startSave,
} from "../../../actions/subject";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import {
  SAVE_STATE_START,
  SAVE_STATE_UPLOADS_READY,
  SAVE_STATE_WAIT_SERVER,
} from "../../../constants/subject";
import { ApplicationAction, ApplicationState } from "../../../types";
import { FetchError } from "../../../types/error";
import { ModalInfo, ModalStatus } from "../../../types/modal";
import {
  isSubject,
  LayoutNode,
  SaveStateType,
  Subject,
  SubjectData,
} from "../../../types/subject";
import { Deferred } from "../../../utils/deffered";
import CardForm from "../../objectcard/CardForm";
import UploadStatus from "../../objectcard/UploadStatus";
import ModalView from "../ModalView";

const getObjTableOptions = (modal: ModalInfo) => {
  const data = modal.options.data ? modal.options.data : modal.options;
  if (!data || typeof data !== "object") {
    return null;
  }
  const options = data as CardModalOptions;
  if (typeof options.isNew === "undefined") {
    options.isNew = options.initialData.$isNew;
  }
  if (typeof options.className === "undefined") {
    options.className = options.initialData.$class;
  }
  return options || null;
};

export interface CardModalOptions {
  initialData: {
    $isNew: boolean;
    $tmpKey: string;
    $class: string;
    [k: string]: any;
  };
  nodeId: string;
  subjectKey: string;
  isNew?: boolean;
  className?: string;
  fetch?: any;
  layoutClass?: any;
  rdfId?: string;
  namespace?: string;
  index?: number;
  isEdit?: boolean;
  save?: boolean;
  uploadFiles?: boolean;
  parent?: string;
  parentRef?: string;
  _prototype?: string;
  notifyId?: string;
  forceEdit?: boolean;
  escapeEditTrigger?: boolean;
  destroyOnClose?: boolean;
}

interface CardModalProps {
  subjectKey: string;
  options?: CardModalOptions;
  modal: ModalInfo;
  loading?: boolean;
  saveState?: SaveStateType;
  subjects?: { [SUBJECT_KEY: string]: Subject | FetchError };
  subject?: Subject;
  error?: any;
  node?: LayoutNode;
  subjChangeLoading?: boolean;
  closeModal: (status: ModalStatus, result: any) => void;
  startSave: (subjectKey: string) => void;
  initializeStore: (
    subjectKey: string,
    subjectData: any,
    layoutClass?: string
  ) => void;
  createNewSubject: (
    subjectKey: string,
    className: string,
    parent?: string,
    parentRef?: string,
    prototype?: string,
    notifyId?: string,
    initialData?: any
  ) => void;
  cancelSave: (subjectKey: string) => void;
  saveSubject: (
    subjectKey: string,
    deffered?: any,
    saveCallback?: (subject: SubjectData) => void
  ) => void;
  add: (subjectKey: string, nodeId: string, data: Subject) => void;
  change: (subjectKey: string, data: any, options?: any) => void;
  changeAt: (
    subjectKey: string,
    nodeId: string,
    index: number,
    data: any
  ) => void;
  destroy: (subjectKey: string) => void;
  fetchSubject: (subjectKey: string) => void;
  editSubject: (subjectKey: string) => void;
  unlockSubject: (subjectKey: string) => void;
}

interface CardModalStates {
  loadingSave: boolean;
  // options: CardModalOptions | null
}

class CardModal extends React.Component<CardModalProps, CardModalStates> {
  title = { id: "OBJECTCARD_POPUP_TITLE" };
  // options: ObjectTableOptions|null;
  // saveDeferred?: Promise<Subject>;
  saveDeferred?: any;
  nodeId?: string;
  doNotSave?: boolean;
  doUploadFiles?: boolean;

  constructor(props: CardModalProps) {
    super(props);
    this.state = { loadingSave: false };
    // this.state = { loadingSave: false, options }
    this.closeModal = this.closeModal.bind(this);
  }

  onAdd = (subjectKey: string, nodeId: string, data: Subject) => {
    const { add, node, change } = this.props;
    if (!node) {
      return;
    }
    if (node.multiple) {
      add(subjectKey, nodeId, data);
    } else {
      change(subjectKey, data.values[node.id]);
    }
  };

  onEdit = (
    subjectKey: string,
    nodeId: string,
    data: Subject,
    index?: number
  ) => {
    if (typeof index !== "undefined") {
      this.props.changeAt(subjectKey, nodeId, index, data);
    } else {
      this.props.change(subjectKey, nodeId, data);
    }
  };

  startSaveSubject() {
    const { subjectKey, startSave } = this.props;
    if (this.saveDeferred) {
      this.saveDeferred = null;
    }
    if (!this.saveDeferred) {
      this.saveDeferred = Deferred();
      startSave(subjectKey);
    }
    return this.saveDeferred;
  }

  cancelEdit(subjectKey: string) {
    const { saveState, options, cancelSave, destroy, unlockSubject } =
      this.props;
    if (saveState) {
      //Save is in progress
      cancelSave(subjectKey); //Try to stop save progress
      return false; //But now we cannot do anything more so return false
    }
    if (!options) {
      return;
    }
    if (options.isNew || options.destroyOnClose) {
      destroy(subjectKey);
    } else if (options.forceEdit && !options.escapeEditTrigger) {
      unlockSubject(subjectKey);
    }
    return true;
  }

  componentDidUpdate(prevProps: CardModalProps, prevState: CardModalStates) {
    ////////////////
    //Track events//
    ////////////////
    const prevSaveState = prevProps.saveState;
    const { saveState, saveSubject, options } = this.props;
    if (!options) {
      return;
    }
    if (
      (options && !prevProps.options) ||
      (options.className && options.className !== prevProps.options?.className)
    ) {
      this.initializeSubject(true);
    }

    if (saveState === prevSaveState || !options) {
      return;
    }

    if (!saveState) {
      //Save was canceled or terminated
      if (this.saveDeferred) {
        if (prevSaveState === SAVE_STATE_WAIT_SERVER && !this.props.error) {
          //Subject was saved OK
          console.log("Save modal card done!");
          this.saveDeferred.resolve(this.props.subject);
          this.saveDeferred = null;
        } else {
          //Someting went wrong!
          console.log("Save modal card canceled!");
          this.saveDeferred.reject(this.props.subject);
          this.saveDeferred = null;
        }
      }
    } else if (saveState === SAVE_STATE_START) {
      //We do not have upload component
      if (!options.save && !options.uploadFiles) {
        this.resolveWithoutServer();
      }
    } else if (
      saveState === SAVE_STATE_UPLOADS_READY &&
      !this.props.subjChangeLoading
    ) {
      if (!options.save) {
        //Check if we should not go futher
        this.resolveWithoutServer();
      } else {
        saveSubject(this.props.subjectKey, null, (data: SubjectData) => {
          this.props.closeModal(MODAL_STATUS_OK, data);
          this.props.destroy(this.props.subjectKey);
        });
      }
    }
  }

  resolveWithoutServer() {
    const { options } = this.props;
    if (!options) {
      return;
    }
    if (this.saveDeferred) {
      this.props.saveSubject(this.props.subjectKey, this.saveDeferred);
    }
    this.saveDeferred.then((s: Subject) => {
      this.props.closeModal(MODAL_STATUS_OK, s);
      this.props.destroy(this.props.subjectKey);
    });
  }

  initializeSubject(reinit?: boolean) {
    const {
      subjectKey,
      subject,
      options,
      initializeStore,
      createNewSubject,
      editSubject,
    } = this.props;
    console.log("Card modal options data", options);
    if (!options) {
      return;
    }

    if (options.isNew && options.className) {
      console.log("Create new subject", options);
      if (reinit || !subject) {
        createNewSubject(
          subjectKey,
          options.className,
          options.parent,
          options.parentRef,
          options._prototype,
          options.notifyId,
          options.initialData
        );
      }
    } else if (options.fetch) {
      let subId = options.rdfId;
      if (!subId) {
        console.error("Unable to fetch subject. RdfId not found");
        return;
      }
      if (options.namespace) {
        subId += `:${options.namespace}`;
      }
      this.props.fetchSubject(subId);
    } else {
      let initialData = options.initialData;
      if (options.forceEdit && options.escapeEditTrigger) {
        initialData = { ...initialData, $lock: { status: true } };
      }
      !subject && initializeStore(subjectKey, initialData, options.layoutClass);
      if (options.forceEdit && !options.escapeEditTrigger) {
        editSubject(subjectKey);
      }
    }
    this.doNotSave = !options.save; //Do not save data to server
    if (this.doNotSave && options.uploadFiles) {
      //Should we upload files ?
      this.doUploadFiles = true;
    }
  }

  componentDidMount() {
    this.initializeSubject();
  }

  getUploadStatus() {
    const { options } = this.props;
    if (!options || (!options.save && !options.uploadFiles)) {
      return null;
    }

    const style: any = {};
    if (this.props.saveState != SAVE_STATE_START) {
      style.display = "none";
    }
    return (
      <div className="row" style={style}>
        <div className="col-md-12">
          <UploadStatus {...this.props} />
        </div>
      </div>
    );
  }

  renderTemplate(): React.ReactElement {
    const { options } = this.props;
    console.log("option data template", options);
    if (!options) {
      return <></>;
    }
    if (this.props.loading) {
      return (
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon icon={faSpinner} size="2x" />
        </div>
      );
    }
    return (
      <div className="mb-3 pb-3">
        <CardForm subjectKey={this.props.subjectKey} />
        {this.getUploadStatus()}
      </div>
    );
  }

  closeModal(status: ModalStatus, result: any) {
    const { subject, modal, startSave, cancelSave, options } = this.props;
    // const { options } = this.state;
    if (status == MODAL_STATUS_OK && subject && options) {
      this.startSaveSubject();
    } else if (status == MODAL_STATUS_CANCEL || status === MODAL_STATUS_CLOSE) {
      this.cancelEdit(this.props.subjectKey);
      this.props.closeModal(status, null);
    }
  }

  render() {
    console.log("render ______ ", this.props.options);
    const modal = { ...this.props.modal };
    modal.options = { title: this.title, ...modal.options };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: { modal: ModalInfo }) => {
    const options = getObjTableOptions(ownProps.modal);
    if (!options) {
      return { subjectKey: ownProps.modal.id };
    }

    const subjectKey = options.subjectKey || ownProps.modal.id;

    const subject = state.subject && state.subject.subjects[subjectKey];
    const loadingSubject = state.subject && state.subject.loading[subjectKey];
    if (!isSubject(subject)) {
      return {
        locale: state.locale.language,
        loading: loadingSubject,
        subjectKey,
        options,
      };
    }
    const loadingLayout =
      state.subject && state.subject.layoutsLoading[subjectKey];
    const loading = loadingLayout || loadingSubject;
    const className = subject.className;

    const serverLock = subject.subjectData.$lock;
    const serverLockReady = serverLock && serverLock.status;
    const isNew = subject.isNew;
    const editCard = serverLockReady || isNew;

    const saveState = state.subject && state.subject.saveState[subjectKey];
    const error = subject.subjectData.$error;
    const errorOperation = subject.subjectData.$errorOperation;
    const node = subject.nodeById[options.nodeId];
    const subjChangeLoading =
      state.subject && state.subject.changeLoading[subjectKey];

    return {
      subjects: state.subject.subjects,
      saveState,
      error,
      loading,
      node,
      subjChangeLoading,
      subject,
      subjectKey,
      options,
    };
  },
  (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
    ownProps: { modal: ModalInfo }
  ) => {
    return {
      editSubject: (subjectKey: string) =>
        dispatch(fetchLock(subjectKey, true)),
      unlockSubject: (subjectKey: string) =>
        dispatch(fetchLock(subjectKey, false)),
      startSave: (subjectKey: string) => dispatch(startSave(subjectKey)),
      saveSubject: (
        subjectKey: string,
        deffered?: any,
        saveCallback?: (subject: SubjectData) => void
      ) => dispatch(saveSubject(subjectKey, deffered, saveCallback)),
      initializeStore: (
        subjectKey: string,
        subjectData: any,
        layoutClass?: string
      ) => dispatch(initializeStore(subjectKey, subjectData, layoutClass)),
      createNewSubject: (
        subjectKey: string,
        className: string,
        parent?: string,
        parentRef?: string,
        prototype?: string,
        notifyId?: string,
        initialData?: any
      ) =>
        dispatch(
          createNewSubject(
            subjectKey,
            className,
            parent,
            parentRef,
            prototype,
            notifyId,
            initialData
          )
        ),
      cancelSave: (subjectKey: string) =>
        dispatch(sendSubjectSaveCancel(subjectKey)),
      add: (subjectKey: string, nodeId: string, data: Subject) =>
        dispatch(add(subjectKey, nodeId, data)),
      change: (subjectKey: string, nodeId: string, data: any, options?: any) =>
        dispatch(change<Subject>(subjectKey, nodeId, data, options)),
      destroy: (subjectKey: string) => dispatch(sendSubjectDestroy(subjectKey)),
      changeAt: (
        subjectKey: string,
        nodeId: string,
        index: number,
        data: Subject
      ) => dispatch(changeAt<Subject>(subjectKey, nodeId, index, data)),
      fetchSubject: (subjectKey: string) => dispatch(fetchSubject(subjectKey)),
    };
  }
)(CardModal);
