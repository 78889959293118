import { SEND_SELECTION, SEND_SELECTION_REDO, SEND_SELECTION_UNDO } from "../constants/selection";
import { SendSelection, SendSelectionRedo, SendSelectionUndo } from "../types/selection";

export function sendSelection(object: string, type: string): SendSelection {
    return {
        type: SEND_SELECTION,
        payload: { object, type }
    }
}

export function sendSelectionUndo(): SendSelectionUndo {
    return {
        type: SEND_SELECTION_UNDO,
        payload: null
    }
}

export function sendSelectionRedo(): SendSelectionRedo {
    return {
        type: SEND_SELECTION_REDO,
        payload: null
    }
}