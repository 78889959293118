export const FINDER_VIEW_TYPE_EDIT = "edit";
export type FINDER_VIEW_TYPE_EDIT = typeof FINDER_VIEW_TYPE_EDIT;

export const FINDER_VIEW_TYPE_ADD = "add";
export type FINDER_VIEW_TYPE_ADD = typeof FINDER_VIEW_TYPE_ADD;

//Type constants
export const TYPE = {
  STRING: 0,
  NUMBER: 1,
  BOOLEAN: 2,
  FILE: 3,
  FRAGMENT: 4,
  REFERENCE: 5,
  TABLE: 6,
  ENUMERATION: 7,
  DATE: 8,
  DATETIME: 9,
};

//Type lists
export const numberRelationTypeList = [
  {
    value: "equal",
    label: "FINDER_NUMBER_EQUAL",
  },
  {
    value: "notEqual",
    label: "FINDER_NUMBER_NOT_EQUAL",
  },
  {
    value: "moreOrEqual",
    label: "FINDER_NUMBER_MORE_OR_EQUAL",
  },
  {
    value: "lessOrEqual",
    label: "FINDER_NUMBER_LESS_OR_EQUAL",
  },
  {
    value: "more",
    label: "FINDER_NUMBER_MORE",
  },
  {
    value: "less",
    label: "FINDER_NUMBER_LESS",
  },
  {
    value: "between",
    label: "FINDER_NUMBER_BETWEEN",
  },
];

export const dateRelationTypeList = [
  {
    value: "equal",
    label: "FINDER_DATE_EQUAL",
  },
  {
    value: "notEqual",
    label: "FINDER_DATE_NOT_EQUAL",
  },
  {
    value: "moreOrEqual",
    label: "FINDER_DATE_MORE_OR_EQUAL",
  },
  {
    value: "lessOrEqual",
    label: "FINDER_DATE_LESS_OR_EQUAL",
  },
  {
    value: "more",
    label: "FINDER_DATE_MORE",
  },
  {
    value: "less",
    label: "FINDER_DATE_LESS",
  },
  {
    value: "between",
    label: "FINDER_DATE_BETWEEN",
  },
];

export const stringRelationTypeList = [
  {
    value: "contains",
    label: "FINDER_CONTAINS",
  },
  {
    value: "notContains",
    label: "FINDER_NOT_CONTAINS",
  },
  {
    value: "equalIgnoreCase",
    label: "FINDER_EQUAL_IGNORE_CASE",
  },
  {
    value: "notEqualIgnoreCase",
    label: "FINDER_NOT_EQUAL_IGNORE_CASE",
  },
];

export const fileRelationTypeList = [
  {
    value: "attached",
    label: "FINDER_FILE_ATTACHED",
  },
  {
    value: "notAttached",
    label: "FINDER_FILE_NOT_ATTACHED",
  },
];

export const fragmentRelationTypeList = [
  {
    value: "equal",
    label: "FINDER_EQUAL_FRAGMENT_ID",
  },
];

export const referenceRelationTypeList = [
  {
    value: "equal",
    label: "FINDER_EQUAL_RDF_ID",
  },
];

export const enumerationRelationTypeList = [
  {
    value: "equal",
    label: "FINDER_ENUMERATION_EQUAL",
  },
  {
    value: "notEqual",
    label: "FINDER_ENUMERATION_NOT_EQUAL",
  },
];

export const booleanRelationTypeList = [
  {
    value: "equal",
    label: "FINDER_EQUAL_BOOLEAN",
  },
];

export const booleanRelationValueList = [
  {
    value: "true",
    label: "FINDER_TRUE",
  },
  {
    value: "false",
    label: "FINDER_FALSE",
  },
];

/* Actions */
export const SEND_FINDER_OPTIONS = "SEND_FINDER_OPTIONS";
export type SEND_FINDER_OPTIONS = typeof SEND_FINDER_OPTIONS;

export const SEND_FINDER_HIDDEN = "SEND_FINDER_HIDDEN";
export type SEND_FINDER_HIDDEN = typeof SEND_FINDER_HIDDEN;

export const SEND_FINDER_VIEW = "SEND_FINDER_VIEW";
export type SEND_FINDER_VIEW = typeof SEND_FINDER_VIEW;

export const SEND_FINDER_FRAGMENTS_LOADING = "SEND_FINDER_FRAGMENTS_LOADING";
export type SEND_FINDER_FRAGMENTS_LOADING =
  typeof SEND_FINDER_FRAGMENTS_LOADING;

export const SEND_FINDER_FRAGMENTS_TREE_HEADER =
  "SEND_FINDER_FRAGMENTS_TREE_HEADER";
export type SEND_FINDER_FRAGMENTS_TREE_HEADER =
  typeof SEND_FINDER_FRAGMENTS_TREE_HEADER;

export const SEND_FINDER_FRAGMENTS_TREE_ERROR =
  "SEND_FINDER_FRAGMENTS_TREE_ERROR";
export type SEND_FINDER_FRAGMENTS_TREE_ERROR =
  typeof SEND_FINDER_FRAGMENTS_TREE_ERROR;

export const SEND_FINDER_FRAGMENTS = "SEND_FINDER_FRAGMENTS";
export type SEND_FINDER_FRAGMENTS = typeof SEND_FINDER_FRAGMENTS;

export const SEND_FINDER_FRAGMENTS_ERROR = "SEND_FINDER_FRAGMENTS_ERROR";
export type SEND_FINDER_FRAGMENTS_ERROR = typeof SEND_FINDER_FRAGMENTS_ERROR;

export const SEND_FINDER_SELECT_FRAGMENT = "SEND_FINDER_SELECT_FRAGMENT";
export type SEND_FINDER_SELECT_FRAGMENT = typeof SEND_FINDER_SELECT_FRAGMENT;

export const SEND_FINDER_CLASSES_LOADING = "SEND_FINDER_CLASSES_LOADING";
export type SEND_FINDER_CLASSES_LOADING = typeof SEND_FINDER_CLASSES_LOADING;

export const SEND_FINDER_CLASSES = "SEND_FINDER_CLASSES";
export type SEND_FINDER_CLASSES = typeof SEND_FINDER_CLASSES;

export const SEND_FINDER_SELECT_CLASS = "SEND_FINDER_SELECT_CLASS";
export type SEND_FINDER_SELECT_CLASS = typeof SEND_FINDER_SELECT_CLASS;

export const SEND_FINDER_FIELDS_LOADING = "SEND_FINDER_FIELDS_LOADING";
export type SEND_FINDER_FIELDS_LOADING = typeof SEND_FINDER_FIELDS_LOADING;

export const SEND_FINDER_FIELDS = "SEND_FINDER_FIELDS";
export type SEND_FINDER_FIELDS = typeof SEND_FINDER_FIELDS;

export const SEND_FINDER_OBJECTCARD = "SEND_FINDER_OBJECTCARD";
export type SEND_FINDER_OBJECTCARD = typeof SEND_FINDER_OBJECTCARD;

export const SEND_FINDER_OBJECTCARD_ERROR = "SEND_FINDER_OBJECTCARD_ERROR";
export type SEND_FINDER_OBJECTCARD_ERROR = typeof SEND_FINDER_OBJECTCARD_ERROR;

export const SEND_FINDER_CRITERIA_ADD = "SEND_FINDER_CRITERIA_ADD";
export type SEND_FINDER_CRITERIA_ADD = typeof SEND_FINDER_CRITERIA_ADD;

export const SEND_FINDER_CRITERIA_RELATION_ADD =
  "SEND_FINDER_CRITERIA_RELATION_ADD";
export type SEND_FINDER_CRITERIA_RELATION_ADD =
  typeof SEND_FINDER_CRITERIA_RELATION_ADD;

export const SEND_FINDER_CRITERIA_REMOVE = "SEND_FINDER_CRITERIA_REMOVE";
export type SEND_FINDER_CRITERIA_REMOVE = typeof SEND_FINDER_CRITERIA_REMOVE;

export const SEND_FINDER_CRITERIA_RELATION_REMOVE =
  "SEND_FINDER_CRITERIA_RELATION_REMOVE";
export type SEND_FINDER_CRITERIA_RELATION_REMOVE =
  typeof SEND_FINDER_CRITERIA_RELATION_REMOVE;

export const SEND_FINDER_CRITERIA_RELATIONS_UNLOCK =
  "SEND_FINDER_CRITERIA_RELATIONS_UNLOCK";
export type SEND_FINDER_CRITERIA_RELATIONS_UNLOCK =
  typeof SEND_FINDER_CRITERIA_RELATIONS_UNLOCK;

export const SEND_FINDER_CRITERIA_RELATION = "SEND_FINDER_CRITERIA_RELATION";
export type SEND_FINDER_CRITERIA_RELATION =
  typeof SEND_FINDER_CRITERIA_RELATION;

export const SEND_FINDER_PREDICATE = "SEND_FINDER_PREDICATE";
export type SEND_FINDER_PREDICATE = typeof SEND_FINDER_PREDICATE;

export const SEND_FINDER_CRITERIA_FIELD = "SEND_FINDER_CRITERIA_FIELD";
export type SEND_FINDER_CRITERIA_FIELD = typeof SEND_FINDER_CRITERIA_FIELD;

export const SEND_FINDER_SEARCH = "SEND_FINDER_SEARCH";
export type SEND_FINDER_SEARCH = typeof SEND_FINDER_SEARCH;

export const SEND_FINDER_CHANGES_CONFIRM = "SEND_FINDER_CHANGES_CONFIRM";
export type SEND_FINDER_CHANGES_CONFIRM = typeof SEND_FINDER_CHANGES_CONFIRM;

export const SEND_FINDER_CHANGES_DENY = "SEND_FINDER_CHANGES_DENY";
export type SEND_FINDER_CHANGES_DENY = typeof SEND_FINDER_CHANGES_DENY;
