import SockJS from "sockjs-client";
import { Client, Message } from '@stomp/stompjs';

//check:
//try { console.log("GOOD", await (await __NPT__.utils.fetchWorker('/rest/script/test/caller')).json()) } catch(ex) {console.log("BAD", ex)}

class WorkerWaiter {

    private readyCallback: ((value?: any) => void) | null = null;
    private errorCallback: ((error: string) => void) | null = null;
    private connectionErrorCallback: ((error: string) => void) | null = null;
    private uuid: string | null = null
    private buffer: any[] = []
    private stmpClient: Client;
    private sessionTimer: number | null = null;
    private closed: boolean = false


    constructor() {
        this.stmpClient = new Client({
            reconnectDelay: 0, //disable reconnect
            webSocketFactory: () => {
                return new SockJS("/ws/event")
            },
            onStompError: () => {
                console.error("WorkerWaiter STOMP error")
                this.error("WorkerWaiter STOMP error")
            },
            onWebSocketError: () => {
                console.error("WorkerWaiter WebSocket error")
                this.error("WorkerWaiter WebSocket error")
            },
            onWebSocketClose: () => {
                if (!this.closed) {
                    console.error("WorkerWaiter connection was closed by peer")
                    this.error("WorkerWaiter connection was closed by peer")
                }
            }
        })
    }

    onReady = (callback: (value?: any) => void) => {
        this.readyCallback = callback;
    }

    onError = (callback: (error: string) => void) => {
        this.errorCallback = callback;
    }

    connect = (): Promise<boolean> => {
        if (!this.onReady || !this.onError) {
            console.error("WorkerWaiter onReady and onError callbacks must be specified before connect");
            return Promise.reject();
        }
        const self = this;
        return new Promise((resolve, reject) => {
            self.stmpClient.onConnect = () => {
                console.log("WorkerWaiter connected");
                self.stmpClient.subscribe("/topic/cim.script.worker", (message: any) => {
                    self.receive(message.body)
                })
                resolve(true);
            }
            self.connectionErrorCallback = (error) => reject(error)
            self.stmpClient.activate();
        })
    }

    ready = (value: object) => {
        this.close()
        if (this.readyCallback) {
            this.readyCallback(value);
        }
        this.invalidate();
    }

    error = (error: string) => {
        this.close()
        if (this.connectionErrorCallback) {
            this.connectionErrorCallback(error)
        }
        if (this.errorCallback) {
            this.errorCallback(error);   
        }
        this.invalidate();
    }

    invalidate = () => {
        this.readyCallback = null;
        this.errorCallback = null;
        this.connectionErrorCallback = null;
    }

    close = () => {
        if (!this.closed) {
            this.closed = true
            if (typeof this.sessionTimer == 'number') {
                window.clearInterval(this.sessionTimer)
                this.sessionTimer = null;
            }
            this.stmpClient.deactivate()
        }
    }

    receive = (body?: any) => {
        if (typeof body != 'string') {
            return
        }
        const json = JSON.parse(body)
        if (this.uuid != null) {
            if (json?.payload?.worker == this.uuid) {
                this.ready(json?.payload);
            }
        } else {
            this.buffer.push(json)
        }
    }

    wait = (uuid: string, sessionTimeout: number) => {
        this.uuid = uuid;
        for (let json of this.buffer) {
            if (json?.payload?.worker == this.uuid) {
                //console.log("Already buffered", json)
                this.ready(json?.payload);
                break;
            }
        }
        
        this.buffer = [];

        //Start session timer if session is more then one second
        if (sessionTimeout > 1000 && !this.closed && typeof this.sessionTimer != 'number') {
            this.sessionTimer = window.setInterval(async () => {
                await (await fetch('/rest/login')).json();
            }, sessionTimeout/2)
        }
    }
}

export async function fetchWorker(input: RequestInfo, init?: RequestInit) {
    //Create waiter and promise before sending request
    const waiter = new WorkerWaiter();
    const promise = new Promise<any>((resolve, reject) => {
        //Set callbacks
        waiter.onReady(resolve)
        waiter.onError(reject);
    })

    //Connect and wait before sending fetch request
    await waiter.connect();

    const resp = await fetch(input, init);
    if (!resp.ok) {
        waiter.close();
        return resp
    }

    //Worker must response with json {worker: uuid, report: uuid}
    const contentType = resp.headers.get('content-type');
    if (!contentType || !contentType.startsWith('application/json')) {
        waiter.close();
        throw "Worker must response with application/json";
    }
    const json = await resp.json()
    if (typeof json != 'object' || json == null) {
        console.error("Worker response", json)
        waiter.close();
        throw "Worker must response with JSON object";
    }

    const worker = json.worker
    const report = json.report
    const sessionTimeout = json.sessionTimeout

    if (typeof worker != 'string' || typeof report != 'string' || typeof sessionTimeout != 'number') {
        console.error("Worker response", json)
        waiter.close();
        throw "Worker response must include worker, report and sessionTimeout"
    }

    //Setup worker UUID and session timeout
    waiter.wait(worker, sessionTimeout);

    //Wait for worker
    const value = await promise;
    if (value?.status != 200) {
        throw value?.error || "Worker unknown error";
    }

    return fetch("/rest/file/report/" + report);
}