export const FRAGMENT = 'fragment';

export const SEND_FRAGMENTS = 'SEND_FRAGMENTS';
export type SEND_FRAGMENTS = typeof SEND_FRAGMENTS;

export const SEND_FRAGMENT_ERROR = 'SEND_FRAGMENT_ERROR';
export type SEND_FRAGMENT_ERROR = typeof SEND_FRAGMENT_ERROR;

export const SEND_FRAGMENT_UPLOAD_NODE_OVER = 'SEND_FRAGMENT_UPLOAD_NODE_OVER';
export type SEND_FRAGMENT_UPLOAD_NODE_OVER = typeof SEND_FRAGMENT_UPLOAD_NODE_OVER;

export const SEND_FRAGMENT_LOADING = 'SEND_FRAGMENT_LOADING';
export type SEND_FRAGMENT_LOADING = typeof SEND_FRAGMENT_LOADING; 

export const SEND_FRAGMENT_ACTIVE = 'SEND_FRAGMENT_ACTIVE';
export type SEND_FRAGMENT_ACTIVE = typeof SEND_FRAGMENT_ACTIVE;

export const SEND_FRAGMENT_TOGGLE = 'SEND_FRAGMENT_TOGGLE';
export type SEND_FRAGMENT_TOGGLE = typeof SEND_FRAGMENT_TOGGLE;

export const SEND_FRAGMENT_TOGGLE_PATH = 'SEND_FRAGMENT_TOGGLE_PATH';
export type  SEND_FRAGMENT_TOGGLE_PATH = typeof SEND_FRAGMENT_TOGGLE_PATH; 

export const SEND_FRAGMENT_EDITED_UPDATE = 'SEND_FRAGMENT_EDITED_UPDATE';
export type SEND_FRAGMENT_EDITED_UPDATE = typeof SEND_FRAGMENT_EDITED_UPDATE; 

export const SEND_FRAGMENT_EDITED_START = 'SEND_FRAGMENT_EDITED_START';
export type SEND_FRAGMENT_EDITED_START = typeof SEND_FRAGMENT_EDITED_START; 

export const SEND_FRAGMENT_EDITED_STOP = 'SEND_FRAGMENT_EDITED_STOP';
export type SEND_FRAGMENT_EDITED_STOP = typeof SEND_FRAGMENT_EDITED_STOP;

export const SEND_FRAGMENT_CTX_MENU = 'SEND_FRAGMENT_CTX_MENU';
export type SEND_FRAGMENT_CTX_MENU = typeof SEND_FRAGMENT_CTX_MENU;

export const SEND_FRAGMENT_SAVE_EDITED = 'SEND_FRAGMENT_SAVE_EDITED';
export type SEND_FRAGMENT_SAVE_EDITED = typeof SEND_FRAGMENT_SAVE_EDITED;

export const SEND_ADDED_FRAGMENT = 'SEND_ADDED_FRAGMENT';
export type SEND_ADDED_FRAGMENT = typeof SEND_ADDED_FRAGMENT; 

export const SEND_UPDATED_FRAGMENT = 'SEND_UPDATED_FRAGMENT';
export type SEND_UPDATED_FRAGMENT = typeof SEND_UPDATED_FRAGMENT;

export const SEND_MOVED_FRAGMENT = 'SEND_MOVED_FRAGMENT';
export type SEND_MOVED_FRAGMENT = typeof SEND_MOVED_FRAGMENT;

export const SEND_DELETED_FRAGMENT = 'SEND_DELETED_FRAGMENT';
export type SEND_DELETED_FRAGMENT = typeof SEND_DELETED_FRAGMENT;  

export const SEND_FRAGMENT_CUT_TARGET = 'SEND_FRAGMENT_CUT_TARGET';
export type SEND_FRAGMENT_CUT_TARGET = typeof SEND_FRAGMENT_CUT_TARGET;  

export const SEND_FRAGMENT_SELECT = 'SEND_FRAGMENT_SELECT';
export type SEND_FRAGMENT_SELECT = typeof SEND_FRAGMENT_SELECT;

export const SEND_CLEAR_FRAGMENT_SELECT = 'SEND_CLEAR_FRAGMENT_SELECT';
export type SEND_CLEAR_FRAGMENT_SELECT = typeof SEND_CLEAR_FRAGMENT_SELECT;
  
export const SEND_FRAGMENT_RANGE = 'SEND_FRAGMENT_RANGE';
export type SEND_FRAGMENT_RANGE = typeof SEND_FRAGMENT_RANGE;  


export const FETCH_FRAGMENTS_URL = '/rest/fragment/tree'
export const FETCH_SAVE_FRAGMENT_URL = '/rest/fragment/entity'

export const FRAGMENT_UPLOAD_URL = '/rest/fragment/entity/list';
export const FRAGMENT_DELETE_BATCH_URL = '/rest/fragment/deletebatch';
 
export const FRAGMENT_EDIT_URL = "/rest/fragment/entity";
export const FRAGMENT_URL = '/rest/fragment';

export const CTX_MENU_ID = 'fragment_tree'

export enum CtxOperation{
    COPY,CUT
}