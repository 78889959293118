import React from 'react'; 
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export interface UsernameGroupProps{
    inputRef: React.MutableRefObject<HTMLInputElement | null>
    ldapAuth: boolean
    onChange:(value: string)=>void
}
 
const UsernameGroup = (props: UsernameGroupProps) => 
    <Form.Group>
    <label htmlFor="usernameInput">
        <FormattedMessage
            id="LOGIN_PANEL_SIMPLE_LOGIN_LABEL"
            defaultMessage="Login"
            description="Label for login field for simple authentification" />
        {props.ldapAuth && <span>
            {" ("}
            <FormattedMessage
                id="LOGIN_PANEL_LOGIN_SAMPLE_DOMAIN"
                defaultMessage="Domain"
                description="Domain part of login sample" />
            \
            <FormattedMessage
                id="LOGIN_PANEL_LOGIN_SAMPLE_USER"
                defaultMessage="Username"
            description="Username part of login sample" />
            {"):"}
        </span>}
        {!props.ldapAuth && ":"}
    </label>
    <input id="usernameInput" name="username"
        className="form-control"
        onChange={(evt) => props.onChange(evt.target.value)} 
        ref={props.inputRef} />
    </Form.Group>

export default UsernameGroup;