import React from "react";

export const CompositionSVG = () => {
    return (
        <svg   width="50" height="20">
                    <line x1='0' x2='50' y1='10' y2='10' stroke='black' strokeWidth='2' />
                    <polyline points="0,10 10,4 20,10 10,16 0,10" fill='black'/> 
        </svg>
    );
}

export const AssociationSVG = () => {
    return  (   <svg   width="50" height="20">
                    <line x1='0' x2='50' y1='10' y2='10' stroke='black' strokeWidth='2' />
                    <line x1='0' x2='10' y1='10' y2='0' stroke='black' strokeWidth='2' />
                    <line x1='0' x2='10' y1='10' y2='20' stroke='black' strokeWidth='2' />
                </svg>
             
    );
}

export const AggregationSVG =()=>{
    return  (  <svg   width="50" height="20">
                    <line x1='0' x2='50' y1='10' y2='10' stroke='black' strokeWidth='2' />
                    <polyline points="0,10 10,4 20,10 10,16 0,10" stroke="black" strokeWidth='2' fill='white'/> 
        </svg>
    );
}