import * as React from 'react';
import { Form } from 'react-bootstrap';
import styles from './TimeSelector.module.css';

interface TimerSelectorStates {
}
interface TimeSelectorProps {
    timeSegments: { [segment: string]: string },
    onChangeTime: (segmentName: string, segmentValue: string) => void,

}
export default class TimerSelector extends React.Component<TimeSelectorProps, TimerSelectorStates>{
    constructor(props: TimeSelectorProps) {
        super(props);
        this.onChangeTime = this.onChangeTime.bind(this);
    }
    onSelectInput(evt: any) {
        evt.target.select();
    }
    onChangeTime(evt: any) {
        const timeSegmentValue = evt.target.value;
        const timeSegmentName = evt.target.name;
        if (this.validateIfNumber(timeSegmentValue, timeSegmentName)) {
            this.props.onChangeTime(timeSegmentName, timeSegmentValue);
        }
    }

    validateIfNumber(timeSegmentValue: string, timeSegmentName: string) {
        const intValue = Number.parseInt(timeSegmentValue);
        if (!isNaN(intValue) && intValue >= 0) {
            return this.validateRage(intValue, timeSegmentName);
        }
        return false;
    }

    validateRage(value: number, timeSegmentName: string) {
        if (timeSegmentName === 'milliseconds' && value < 1000 && value.toString().length < 4) {
            return true;
        } else if (timeSegmentName === 'hours' && value < 24 && value.toString().length < 3) {
            return true;
        } else if ((timeSegmentName === 'minutes' || timeSegmentName === 'seconds') && value < 60 && value.toString().length < 3) {
            return true;
        }
        return false;
    }

    render() {
        const { timeSegments } = this.props;
        return <div className={styles.timeFooter} onChange={this.onChangeTime}>
            <Form.Control name="hours" onClick={this.onSelectInput} className={styles.timeSegment} type="input" value={timeSegments['hours']} /><span className={styles.timeSegmentDevider}>:</span>
            <Form.Control name="minutes" onClick={this.onSelectInput} className={styles.timeSegment} type="input" value={timeSegments['minutes']} /><span className={styles.timeSegmentDevider}>:</span>
            <Form.Control name="seconds" onClick={this.onSelectInput} className={styles.timeSegment} type="input" value={timeSegments['seconds']} /><span className={styles.timeSegmentDevider}>:</span>
            <Form.Control name="milliseconds" onClick={this.onSelectInput} className={styles.millisecondsSegment} type="input" value={timeSegments['milliseconds']} />
        </div>
    }
}