import { FetchError, isFetchError } from "../types/error";
import { ServerError } from "./utils";
import {
  Layout,
  LayoutNode,
  SubjectAction,
  SubjectState,
  Subject,
  AutomationForm,
  SendSubjectError,
  SendSubject,
  getSubjectKey,
  SendCachedLayout,
  SendSubjectLoading,
  SendEnumerationLoading,
  SendEnumeration,
  SendEnumerationError,
  EnumerationClass,
  EnumerationInfo,
  EnumerationNode,
  SendLayoutLoading,
  SubjectDiff,
  NormalizedAutomation,
  Value,
  DiffState,
  Validation,
  Visibility,
  Lock,
  isSubject,
  OperationType,
  SendSubjectChangeData,
  SendSubjectStartSave,
  SaveStateType,
  SendSubjectChangeSaveState,
  SendSubjectSaveWait,
  SendSubjectSaveCancel,
  SendSubjectChangeLoading,
  SendSubjectDestroy,
  SendSubjectSaveDone,
  SendSubjectComponentUpdated,
  SendSubjectChangeTab,
  UploadTask,
  FileValue,
  RawLink,
  Link,
  SubjectData,
  SubjectCardInfo,
  Column,
  UploadMetaData,
  ReportParams,
  SendSubjectLockLoading,
  SendRemoveSubjectFromStore,
  SendEditingSubject,
  SendNewSubject,
  SendFragmentLoading,
  SendFragmentError,
  SendFragmentTreeBranch,
  SendFragmentSelected,
} from "../types/subject";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ApplicationState, ApplicationAction } from "../types";
import shortid from "shortid";
import { openModal } from "./modal";
import * as c from "../constants/subject";
import {
  fetchFragmentsImpl,
  fetchFragmentsPathImpl,
  parseFragments,
} from "./fragment";
import {
  generateBindings,
  normalizePredicate,
  buildLabelInfo,
} from "../services/layout";
import {
  scriptCompiler,
  retrieveFunction,
  checkMandatory,
} from "../services/automation";
import {
  ModalOptions,
  CloseCallback,
  CancelCallback,
  OkCallback,
  I18NString,
  TableModalOptions,
  TreeModalOptions,
} from "../types/modal";
import { addAlert, dispatchError, dispatchSuccess } from "./alert";
import {
  ALERT_LEVEL_SUCCESS,
  ALERT_LEVEL_DANGER,
  ALERT_LEVEL_WARNING,
} from "../constants/alert";
import { AnyAction } from "redux";
import { buildUrl, getSearchData } from "../services/location";
import {
  enqueUpload,
  getUploadTaskList,
  clearUploadTasks,
  toUrlSearchParams,
  subjectAddedFire,
  addSubjectFire,
} from "../services/subject";
import {
  composeDecendantsRefsUrl,
  composePathToNodeUrl,
  composeTreeFetchUrl,
  notifyTreeNodeUpdate,
  notifyTreeSubjectUpdate,
  sendTreeNodesForceUpdate,
} from "./tree";
import { FragmentData, FragmentTreeBranch } from "../types/fragment";
// import { LAYOUT } from './LAYOUT';
// import { SUBJECT } from './SUBJECT2';

const MANDATORY_ERROR = { id: "OBJECTCARD_FIELD_IS_MANDATORY" };
const MINIMUM_ITEMS_ERROR = { id: "OBJECTCARD_LESS_THAN_MINIMUM_ITEMS" };
const MAXIMUM_ITEMS_ERROR = { id: "OBJECTCARD_MORE_THAN_MAXIMUM_ITEMS" };

//UTIL FUNCTIONS
function refreshLinkSubject(subjectData: any) {
  return {
    $namespace: subjectData.$namespace,
    $rdfId: subjectData.$rdfId,
    $label: subjectData.$label,
    $description: subjectData.$description,
  };
}

export async function fetchAutocomplete(
  script: string,
  search: string,
  contextSearch?: string | object
): Promise<string[]> {
  let params = [];
  if (contextSearch) {
    const param =
      typeof contextSearch !== "string"
        ? JSON.stringify(contextSearch)
        : contextSearch;
    params.push(`c=${encodeURIComponent(param)}`);
  }
  if (search) {
    params.push(`q=${encodeURIComponent(search)}`);
  }
  let queryString = params.join("&");

  if (queryString) {
    queryString = `?${queryString}`;
  }
  const response = await fetch(`${script}${queryString}`);

  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return await response.json();
}

function linkSubject(
  subjectKey: string,
  nodeId: string,
  valuesDiff: any,
  state: SubjectState,
  dispatch: any
) {
  const subject = state && state.subjects[subjectKey];
  if (!isSubject(subject)) {
    return;
  }

  const { className } = subject;
  const layout = state.layouts[className];
  const currentState = makeState(subject);
  dispatch(
    sendSubjectChangeData(
      makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
      nodeId
    )
  );
}

function getLockStatus(subject: Subject) {
  if (!subject.subjectData || !subject.subjectData.$lock) {
    return false;
  }
  return subject.subjectData.$lock.status;
}
function isValueEmpty(value: any) {
  let isValueEmpty = false;
  if (typeof value === "undefined") {
    isValueEmpty = true;
  } else if (value === null) {
    isValueEmpty = true;
  } else if (typeof value === "object" && Object.keys(value).length === 0) {
    isValueEmpty = true;
  }
  return isValueEmpty;
}
const applyBindFillForValues = (
  values: Value,
  layout: Layout,
  subject: Subject
) => {
  const predicateIds = Object.keys(values);
  if (!values) {
    return values;
  }
  const newValues = { ...values };

  const hasFill =
    layout.automation && Object.keys(layout.automation.fillBindings).length > 0;

  if (layout.automation && hasFill) {
    const form = makeForm(newValues, subject.subjectData);
    for (let nodeId of predicateIds) {
      if (
        !isValueEmpty(newValues[nodeId]) ||
        !layout.automation.fillBindings[nodeId]
      ) {
        //Do not fill ready fields
        continue;
      }
      console.log("Process fill bindings:", nodeId);
      const funcId = layout.automation.fillBindings[nodeId];
      const func = retrieveFunction(funcId);
      try {
        const d = func(form);
        if (d) {
          newValues[nodeId] = d;
        }
      } catch (ex) {
        console.log("Fill binding error", nodeId, ex);
      }
    }
  }
  return newValues;
};
export function mergeSubjectValues(
  subject: Subject,
  layout: Layout,
  values: Value
) {
  //Make deep copy of subject
  const subjectData = subject.subjectData;
  const valuesDiff: Value = applyBindFillForValues(values, layout, subject);
  const newSubjectData = { ...subjectData };
  delete newSubjectData.$notifyId;
  for (let id in valuesDiff) {
    const value = valuesDiff[id];
    if (value == null) {
      continue;
    }
    const path = id.split(".");
    let obj = newSubjectData;
    while (path.length > 1) {
      const key = path.shift();
      if (key) {
        if (!obj[key]) {
          obj[key] = {};
        }
        obj = obj[key];
      }
    }
    obj[path[0]] = value;
  }
  return newSubjectData;
}
// export function mergeSubjectValues(subjectData: any, values: Value) {
//     //Make deep copy of subject
//     const newSubjectData = { ...subjectData };
//     delete newSubjectData.$notifyId;
//     for (let id in values) {
//         const value = values[id];
//         if (value == null) {
//             continue;
//         }
//         const path = id.split(".");
//         let obj = newSubjectData;
//         while (path.length > 1) {
//             const key = path.shift();
//             if (key) {
//                 if (!obj[key]) {
//                     obj[key] = {};
//                 }
//                 obj = obj[key];
//             }
//         }
//         obj[path[0]] = value;
//     }
//     return newSubjectData;
// }

function isEditable(subject: SubjectData) {
  return (
    subject.$isNew === true || typeof subject?.$lock?.status !== "undefined"
  );
}

function makeState(data: Subject): DiffState {
  return {
    values: data.values || {},
    validation: data.validation || {},
    visibilityDemand: data.visibilityDemand || {},
    lockDemand: data.lockDemand || {},
  };
}

function isObjectEmpty(obj: object) {
  return obj && Object.getOwnPropertyNames(obj).length === 0;
}

function wrapValue(value: any) {
  if (
    typeof value == "undefined" ||
    (typeof value == "object" && isObjectEmpty(value))
  ) {
    return null;
  }
  return value;
}

type PathIndex = string | number;

//Obtain data by path
export function obtainData(path: string | PathIndex[], data: any): any {
  //Support for string path

  let realPath: PathIndex[];
  if (typeof path == "string") {
    realPath = path.split(".");
  } else {
    realPath = path;
  }
  let result = data;
  for (let subPath of realPath) {
    if (typeof subPath == "string" && /^[0-9]+$/.test(subPath)) {
      //because javascript array index is string not number
      subPath = parseInt(subPath);
    }
    if (typeof subPath == "string") {
      if (typeof result == "object" && typeof result[subPath] != "undefined") {
        result = result[subPath];
        continue;
      }
    } else if (typeof subPath == "number") {
      if (Array.isArray(result) && typeof result[subPath] != "undefined") {
        result = result[subPath];
        continue;
      }
    }
    return null;
  }
  return result;
}

/*Create form for automation*/
export function makeForm(
  values: { [K: string]: any },
  subjectData: SubjectData,
  dispatch?: ThunkDispatch<ApplicationState, {}, ApplicationAction>
): AutomationForm {
  let form: AutomationForm = {
    obtain: (p: string) => {
      p = p.replace(":", ".");
      const value = values[p];
      if (typeof value != "undefined") {
        return wrapValue(value);
      }
      const path = p.split(".");
      if (path[0].indexOf("$") == 0) {
        //Special symbol and special fields
        const v = values[path[0]];
        if (typeof v != "undefined") {
          const key = path.shift(); //remove first part from path
          return wrapValue(obtainData(path, v));
        }
        //Fallback to data
        return wrapValue(obtainData(path, subjectData));
      }
      //Predicate must have at least three parts: namespace, classname, predicate name.
      //We use 4 because 3 parts were already checked at the begining!
      if (path.length < 4) {
        return wrapValue(value); //return undefined value
      }
      const p2 = [path.shift(), path.shift(), path.shift()].join(".");
      const value2 = values[p2]; //New value
      //console.log("Obtain from predicate sub value", p2, value2, path);
      if (!value2) {
        return wrapValue(value2); //return undefined or null
      }
      return wrapValue(obtainData(path, value2)); //path is rest of starting path without three elements, value2 is from values
    },
  };

  if (dispatch) {
    form.reload = () => {
      dispatch(
        fetchSubject(getSubjectKey(subjectData.$rdfId, subjectData.$namespace))
      );
    };

    form.change = (predicate: string, value: any) => {
      let nodeId = predicate.replace(":", ".");
      //TODO: change subject
      //dispatch(change(store, nodeId, value));
    };
    form.openModal = (
      type: string,
      options: ModalOptions,
      okCallback?: OkCallback | undefined,
      cancelCallback?: CancelCallback | undefined,
      closeCallback?: CloseCallback | undefined
    ) => {
      const modalId = shortid.generate();
      const safeOkCallback = (result: any) => {
        try {
          return okCallback && okCallback(result);
        } catch (e) {
          dispatchError("SUBJECT_USER_MODAL_ERROR", e, dispatch);
        }
      };
      dispatch(
        openModal(
          modalId,
          type,
          options,
          safeOkCallback,
          cancelCallback,
          closeCallback
        )
      );
    };
  }
  return form;
}

function makeDiff(
  subjectKey: string,
  layout: Layout,
  subject: Subject,
  valuesDiff: Value, //Current state
  diffState: DiffState,
  invalidFormat?: any
): SubjectDiff {
  //List of invalid format nodes
  //Make validation and automation
  const { lockDemand, validation, values, visibilityDemand } = diffState;
  let nextValues = Object.assign({}, values, valuesDiff);
  //Bind values
  const form = makeForm(nextValues, subject.subjectData);
  const editable = isEditable(subject.subjectData);
  //Bind values only in editable mode
  if (editable && layout.automation) {
    //Make several iteration to obtain final result of value bindings
    for (let i = 0; i < 10; ++i) {
      let changed = false; //flag to exit from iterations
      for (let nodeId in layout.automation.valueBindings) {
        const funcId = layout.automation.valueBindings[nodeId];
        const func = retrieveFunction(funcId);
        try {
          const value = func(form);
          if (values[nodeId] != value && nextValues[nodeId] != value) {
            valuesDiff[nodeId] = value;
            changed = true;
          }
        } catch (ex) {
          console.log("Value binding error", nodeId, ex);
        }
      }
      if (!changed) {
        //nothing have been changed so exit from iterations
        break;
      }
      //Update next values
      nextValues = Object.assign({}, values, valuesDiff);
    }
  }
  //Process validation
  let validationDiff: Validation | null = null;
  for (let nodeId of layout.predicateNodesIds) {
    const value = nextValues[nodeId];
    let error: boolean | I18NString = false;
    if (invalidFormat && invalidFormat[nodeId]) {
      //If we have map of invalid format values
      error = invalidFormat[nodeId];
    }
    if (!error && layout.mandatorySet[nodeId]) {
      //Check mandatory first
      if (!checkMandatory(value)) {
        error = MANDATORY_ERROR;
      }
    }
    if (
      !error &&
      layout.automation &&
      layout.automation.validationBindings[nodeId]
    ) {
      //Check bindings
      const funcId = layout.automation.validationBindings[nodeId];
      const func = retrieveFunction(funcId);
      try {
        error = func(value, form);
      } catch (ex) {
        console.log("Validation binding error", nodeId, ex);
      }
    }
    const prevError = validation[nodeId];
    if (error != prevError) {
      if (!validationDiff) {
        validationDiff = {};
      }
      validationDiff[nodeId] = error;
    }
  }
  //Process visibility
  let visibilityDiff: Visibility | null = null;
  if (layout.automation) {
    for (let nodeId in layout.automation.visibilityBindings) {
      const funcId = layout.automation.visibilityBindings[nodeId];
      const func = retrieveFunction(funcId);
      try {
        const visible = func(form);
        if (visibilityDemand[nodeId] != visible) {
          if (!visibilityDiff) {
            visibilityDiff = {};
          }
          visibilityDiff[nodeId] = visible;
        }
      } catch (ex) {
        // console.log("Visibility binding error", nodeId, ex);
      }
    }
  }
  //Process locks
  let lockDiff: Lock | null = null;
  //First: make common lock checks
  if (layout.automation) {
    for (let funcId of layout.automation.commonLockChecks) {
      const func = retrieveFunction(funcId);
      for (let nodeId of layout.predicateNodesIds) {
        try {
          const lock = func(form, layout.nodeById[nodeId], nodeId);
          if (lockDemand[nodeId] != lock) {
            if (!lockDiff) {
              lockDiff = {};
            }
            lockDiff[nodeId] = lock;
          }
        } catch (ex) {
          console.log("Common lock checks error", nodeId, ex);
        }
      }
    }
  }

  //Second: make specific lock checks for predicates
  if (layout.automation) {
    for (let nodeId in layout.automation.lockBindings) {
      const funcId = layout.automation.lockBindings[nodeId];
      const func = retrieveFunction(funcId);
      try {
        const lock = func(form);
        if (lockDemand[nodeId] != lock) {
          if (!lockDiff) {
            lockDiff = {};
          }
          lockDiff[nodeId] = lock;
        } else if (lockDiff) {
          delete lockDiff[nodeId];
        }
      } catch (ex) {
        console.log("Lock binding error", nodeId, ex);
      }
    }
  }

  return {
    subjectKey,
    values: valuesDiff,
    validation: validationDiff,
    visibility: visibilityDiff,
    lock: lockDiff,
  };
}

function syncDataAndLayout(
  subjectKey: string,
  subject: Subject,
  layout: Layout,
  forceUpdate: boolean
) {
  const currentState = makeState(subject);
  const valuesDiff: Value = {};

  if (forceUpdate || Object.keys(subject.values).length === 0) {
    currentState.values = {};
    currentState.validation = {};
    currentState.visibilityDemand = {};
    currentState.lockDemand = {};
    const predicateIds = layout.predicateNodesIds.slice();
    if (subject.ignore) {
      for (let p in subject.ignore) {
        predicateIds.push(`$ignore.${p}`);
      }
    }
    for (let nodeId of predicateIds) {
      const d = obtainData(nodeId, subject.subjectData);
      if (typeof d !== "undefined") {
        valuesDiff[nodeId] = d;
      } else {
        valuesDiff[nodeId] = null; //not undefined!
      }
    }
    //Check if we need to prefill some data
    const hasFill =
      layout.automation &&
      Object.keys(layout.automation.fillBindings).length > 0;
    const editable = isEditable(subject.subjectData);
    if (editable && layout.automation && hasFill) {
      const form = makeForm(valuesDiff, subject.subjectData);
      for (let nodeId of predicateIds) {
        if (valuesDiff[nodeId] || !layout.automation.fillBindings[nodeId]) {
          //Do not fill ready fields
          continue;
        }
        console.log("Process fill bindings:", nodeId);
        const funcId = layout.automation.fillBindings[nodeId];
        const func = retrieveFunction(funcId);
        try {
          const d = func(form);
          if (d) {
            valuesDiff[nodeId] = d;
          }
        } catch (ex) {
          console.log("Fill binding error", nodeId, ex);
        }
      }
    }
  }
  return makeDiff(subjectKey, layout, subject, valuesDiff, currentState);
}

function parseTableNode(tableNode: any, mainNode: LayoutNode) {
  /** If current node isn't predicate - it is not column */
  if (!tableNode.p) {
    if (tableNode.content && Array.isArray(tableNode.content)) {
      for (let child of tableNode.content) {
        parseTableNode(child, mainNode);
      }
    }
    return;
  }

  const column: Column = {
    id: tableNode.id,
    predicateId: tableNode.id,
    path: tableNode.id.split("."),
    label: tableNode.label,
    labelInfo: buildLabelInfo(tableNode.label),
    format: c.UI_INPUT_FORMAT[tableNode.ui] || "string",
  };
  /** Setup predicate for cell factory */
  switch (tableNode.ui) {
    case c.UI_COMBOBOX:
      column.predicate = {
        classRelationInfo: {
          peerClass: {
            stereotypeInfo: "enumeration",
          },
        },
      };
      break;
    case c.UI_FILE:
      column.predicate = {
        dataType: {
          name: "base64Binary",
        },
      };
      break;
    case c.UI_FRAGMENT:
      column.predicate = {
        dataType: {
          name: "anyURI",
        },
      };
      break;
    case c.UI_OBJECT_TABLE:
      column.predicate = {
        classRelationInfo: {
          peerClass: {},
          relationTypeInfo: "composition",
        },
      };
      break;
    case c.UI_REF_TABLE:
      column.predicate = {
        classRelationInfo: {
          peerClass: {},
          relationTypeInfo: "aggregation",
        },
      };
      break;
    case c.UI_CHECKBOX:
      column.predicate = {
        dataType: {
          name: "boolean",
        },
      };
      break;
    case c.UI_DATE:
      column.predicate = {
        dataType: {
          name: "date",
        },
      };
      break;
    case c.UI_DATE_TIME:
      column.predicate = {
        dataType: {
          name: "dateTime",
        },
      };
      break;
    case c.UI_FLOAT:
      column.predicate = {
        dataType: {
          name: "float",
        },
      };
      break;
    case c.UI_INT:
      column.predicate = {
        dataType: {
          name: "integer",
        },
      };
      break;
    default:
      column.predicate = {
        dataType: {
          name: "string",
        },
      };
      break;
  }
  column.predicate &&
    (column.predicate["table-header-width"] =
      tableNode.options && tableNode.options.width);
  Object.assign(column, tableNode.options);
  mainNode.options.columns.push(column);
}
function parseTable(node: LayoutNode) {
  node.options.columns = [];
  if (
    !node.options.layout ||
    !node.options.layout.content ||
    !Array.isArray(node.options.layout.content)
  ) {
    return;
  }
  if (node.options["columns-order"]) {
    for (let i = 0; i < node.options["columns-order"].length; ++i) {
      node.options["columns-order"][i] = normalizePredicate(
        node.options["columns-order"][i]
      );
    }
  }

  for (let child of node.options.layout.content) {
    parseTableNode(child, node);
  }
  delete node.options.layout;
}
function parsePredicate(node: LayoutNode) {
  node.label = node?.options?.label || node.label;
}
function normalizeLayout(content: any, layout: Layout, parentId?: string) {
  if (!content || !Array.isArray(content)) {
    return;
  }
  for (const item of content) {
    //Cast to layout node
    const node: LayoutNode = item;
    layout.nodeById[node.id] = node;
    if (parentId) {
      layout.parentNodeById[node.id] = parentId;
      if (layout.childrenNodesById[parentId]) {
        layout.childrenNodesById[parentId].push(node.id);
      } else {
        layout.childrenNodesById[parentId] = [node.id];
      }
    } else {
      layout.rootNodesIds.push(node.id);
    }
    //Check predicate marker
    if (item.p) {
      parsePredicate(node);
      layout.predicateNodesIds.push(node.id);
      delete item.p;
    }
    if (node?.options?.src) {
      node.options.src = normalizePredicate(node.options.src);
    }
    //Add enumeration to fetch list
    if (node.ui == c.UI_COMBOBOX && node.options.cls) {
      layout.enumerationsMap[node.options.cls] = true;
    }
    //Check mandatory flag
    let mandatory = node.mandatory;
    if (typeof node?.options?.mandatory !== "undefined") {
      mandatory = node.options.mandatory;
    }
    if (mandatory) {
      layout.mandatorySet[node.id] = true;
    }
    if (node.ui == c.UI_OBJECT_TABLE) {
      parseTable(node);
    }
    if (node.ui == c.UI_TAB) {
      layout.tabsIds.push(node.id);
    }
    //Recursive parse
    if (item.content) {
      normalizeLayout(item.content, layout, node.id);
      delete item.content;
    } else {
      item.leaf = true;
    }
  }
}
/**
 * Search layout nodes for speciefic ui that require
 * functions to be runned before objectcard save function
 */
function getPreSaveFunctions(layout: Layout) {
  let preSave: any = {};
  if (!layout.nodeById) {
    return preSave;
  }
  for (let nodeId in layout.nodeById) {
    if (layout.nodeById[nodeId].ui == c.UI_RPA_SETPOINTS) {
      preSave[c.UI_RPA_SETPOINTS] = layout.nodeById[nodeId].options.src;
    }
  }
  return preSave;
}
function getAutomation(script: string, className: string) {
  const normalizedAutomation: NormalizedAutomation = {
    optionsBindings: {},
    valueBindings: {},
    enumerationBindings: {},
    clickBindings: {},
    validationBindings: {},
    lockBindings: {},
    visibilityBindings: {},
    fillBindings: {},
    linkBindings: {},
    commonLockChecks: [], //Lock checks not specific for the predicate
    commentBindings: {},
  };
  // console.log(script)
  if (script) {
    const bindings = generateBindings(normalizedAutomation, className);
    // console.log(script)
    scriptCompiler(script, bindings);
    // scriptCompiler(SCRIPT, bindings);
  }
  return normalizedAutomation;
}
function parseLayout(json: any, className: string) {
  const layout: Layout = {
    automation: getAutomation(json.automation, className),
    toolbar: json.toolbar,
    rootNodesIds: [],
    override: null,
    predicateNodesIds: [],
    nodeById: {},
    childrenNodesById: {},
    parentNodeById: {},
    //Placeholders
    parentPlaceHolderId: null, //Parent place holder id (ui id).
    childPlaceHolderId: null, //Child place holder id (ui id).
    selfPlaceHolderId: null, //Self placeholder id (ui id).
    enumerationsMap: {},
    mandatorySet: {},
    tabsIds: [],
    rootId: null,
  };
  /** Manually create root node */
  const rootId = "__npt.objectcard.root";
  layout.rootId = rootId;
  layout.nodeById[rootId] = {
    id: rootId,
    options: {},
  };
  layout.childrenNodesById[layout.rootId] = [];

  //Add special fields to make available for placeholders
  const specialList: LayoutNode[] = [
    {
      id: "$label",
      ui: c.UI_LABEL,
      options: {},
      leaf: true,
    },
    {
      id: "$description",
      ui: c.UI_DESCRIPTION,
      options: {},
      leaf: true,
    },
  ];
  for (let special of specialList) {
    layout.nodeById[special.id] = special;
    layout.predicateNodesIds.push(special.id);
    //Add to parent but not to child list of parent (so that those items will not be displayed by default)
    layout.parentNodeById[special.id] = layout.rootId;
  }
  normalizeLayout(json.content, layout);
  for (let nodeId of layout.rootNodesIds) {
    layout.parentNodeById[nodeId] = rootId;
    layout.childrenNodesById[layout.rootId].push(nodeId);
  }

  layout.preSave = getPreSaveFunctions(layout);

  layout.childPlaceHolderId = layout.nodeById["$child"] ? "$child" : null;
  layout.parentPlaceHolderId = layout.nodeById["$parent"] ? "$parent" : null;
  layout.selfPlaceHolderId = layout.nodeById["$self"] ? "$self" : null;

  return layout;
}

function checkLayoutFetchNeeded(className: string, state?: SubjectState) {
  const status = state && state.layoutsStatus[className];
  return typeof status === "undefined";
}

function checkSavePossible(
  subject: Subject,
  subjectKey: string,
  state: SubjectState
) {
  if (!subject.subjectData) {
    return false;
  }
  const areFilesUploaded =
    state.saveState[subjectKey] == c.SAVE_STATE_UPLOADS_READY;
  return (
    (subject.subjectData.$isNew || getLockStatus(subject)) && areFilesUploaded
  );
}

function parseEnumerationChildren(json: any): EnumerationNode[] {
  const enumerationNodes: EnumerationNode[] = [];
  if (!json || !Array.isArray(json)) {
    return enumerationNodes;
  }
  for (let child of json) {
    const enumerationNode: EnumerationNode = {
      lastLevel: Boolean(child.lastLevel),
    };

    if (child.data) {
      enumerationNode.data = child.data;
    }
    if (child.children) {
      enumerationNode.children = parseEnumerationChildren(child.children);
    }
    enumerationNodes.push(enumerationNode);
  }
  return enumerationNodes;
}

export function parseEnumeration(json: any): EnumerationInfo {
  const enumerationInfo: EnumerationInfo = {
    rootLevel: Boolean(json.rootLevel),
    children: parseEnumerationChildren(json.children),
  };
  return enumerationInfo;
}

function composeValidateLinkUrl(relatedClass: string, reference: RawLink) {
  const link = `${c.VALIDATE_LINK_URL}/${relatedClass.replace(":", "/")}`;
  let search = "?";
  if (reference.$namespace) {
    search += `namespace=${reference.$namespace}&`;
  }
  search += `rdfId=${reference.$rdfId}`;
  return `${link}${search}`;
}

function wrapSubjectData(subjectData: SubjectData) {
  const subject: Subject = {
    // ...layout,
    childrenNodesById: {},
    enumerationsMap: {},
    mandatorySet: {},
    nodeById: {},
    parentNodeById: {},
    predicateNodesIds: [],
    rootNodesIds: [],
    automation: {
      optionsBindings: {},
      clickBindings: {},
      commonLockChecks: [],
      enumerationBindings: {},
      fillBindings: {},
      linkBindings: {},
      lockBindings: {},
      validationBindings: {},
      valueBindings: {},
      visibilityBindings: {},
      commentBindings: {},
    },
    isNew: subjectData.$isNew,
    toolbar: { buttons: [] },
    subjectData,
    className: subjectData.$class,
    values: {},
    visibility: {},
    visibilityDemand: {},
    validation: {},
    tabsIds: [],
    lock: {},
    lockDemand: {},
    ignore: {},
    comment: {},
    rootId: null,
  };
  return subject;
}

//TODO
function makeLayout(classInfo: any) {
  // console.log('make layout')
  // if (!classInfo) {
  //     return null;
  // }
  // const layout = layoutAll(classInfo, false);
  // return layout;
  // return null
}

export function ajaxUploadFiles(
  taskList: UploadTask[],
  uploadComplete: (task: UploadTask, e: any) => void,
  uploadProgress: Function
) {
  let uploads: Promise<UploadMetaData>[] = [];
  let loadedList = [];
  let totalList = [];
  //Upload tasks
  for (let task of taskList) {
    let index = loadedList.length;
    loadedList.push(0);
    totalList.push(0);
    uploads.push(
      ajaxUpload(
        task,
        index,
        loadedList,
        totalList,
        uploadComplete,
        uploadProgress
      )
    );
  }
  return uploads;
}

//TODO
function runPreSave(
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
  layout: Layout,
  callback: Function
) {
  let binded = false;
  // for (let funcId in layout.preSave) {
  // if (funcId == Action.UI_RPA_SETPOINTS) {
  //     binded = true;
  //     /* Remove all previously binded save funcitons */
  //     $(window).unbind('rpasetpoints.save');
  //     $(window).bind('rpasetpoints.save', (event, rdfId) => {
  //         $(window).unbind('rpasetpoints.save');
  //         callback({ [layout.preSave[funcId]]: rdfId || "" });
  //     });
  //     dispatch(saveToServer());
  // }
  // }
  if (!binded) {
    callback();
  }
}

export async function validateLinks(
  contextPath: string,
  relatedClass: string,
  references: RawLink[],
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>
) {
  const validLinks: Link[] = [];
  const promises = [];
  try {
    for (let reference of references) {
      promises.push(fetchValidateLink(contextPath, relatedClass, reference));
    }
  } catch (e: any) {
    dispatchError("OBJECTCARD_VALIDATION_ERROR", e, dispatch, {
      error: e?.message,
    });
  }

  await Promise.all(promises)
    .then((values) => {
      for (let value of values) {
        if (value == null) {
          continue;
        }
        validLinks.push(value);
      }
    })
    .catch((e) => {
      dispatchError("OBJECTCARD_VALIDATION_ERROR", e, dispatch, {
        error: e?.message,
      });
    });
  return validLinks;
}

export function downloadFile(href: string, filename?: string) {
  let isChromium =
    navigator.userAgent.toLowerCase().indexOf("chrome") != -1 ||
    navigator.userAgent.toLowerCase().indexOf("safari") != -1;
  let composedLink = href;
  if (isChromium && typeof getSearchData().debug === "undefined") {
    let a = document.createElement("a");
    a.href = composedLink;
    a.download = filename || "";
    a.click();
    return;
  }
  // Force file download (whether supported by server).
  if (composedLink.indexOf("?") > 0) {
    composedLink = composedLink.replace("?", "?download&");
  } else {
    composedLink += "?download";
  }
  if (filename) {
    composedLink += "&_filename=" + filename;
  }
  window.open(composedLink);
}

async function fetchPathToNodeImpl(
  path: string,
  rdfId: string
): Promise<string[]> {
  const response = await fetch(composePathToNodeUrl(path, rdfId));
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  return await response.json();
}
async function fetchDecendantsRefsImpl(
  path: string,
  nodeId: string
): Promise<any> {
  const response = await fetch(composeDecendantsRefsUrl(path, nodeId));
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  return await response.json();
}
//////// FETCH FUNCTIONS

export async function safeFetchValidateLink(
  contextPath: string,
  relatedClass: string,
  reference: any,
  omitErrors?: boolean
) {
  try {
    return {
      failed: false,
      data: await fetchValidateLink(contextPath, relatedClass, reference),
    };
  } catch (e) {
    if (omitErrors === false) {
      console.error(e);
    }
    return { failed: true, error: e };
  }
}

export async function fetchValidateLink(
  contextPath: string,
  relatedClass: string,
  reference: any
) {
  const response = await fetch(
    composeValidateLinkUrl(relatedClass, reference),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, statusText);
  }

  return await response.json();
}

export async function downloadFileImpl(url: string, fname?: string) {
  const response = await fetch(url);
  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  const contentDesp = response.headers.get("Content-Disposition");
  let filename = fname;
  if (contentDesp) {
    filename = decodeURIComponent(contentDesp.split("filename=")[1]).replace(
      /\+/g,
      " "
    );
    filename = filename.slice(0, filename.length - 1);
  }

  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = filename || "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  });
}

async function saveSubjectImpl(subjectData: SubjectData): Promise<SubjectData> {
  const response = await fetch("/rest/subject/entity", {
    method: "POST",
    headers: {
      // 'Accept': 'application/json',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(subjectData),
  });
  if (!response.ok) {
    const { status, statusText } = response;
    const errorText = await response.text();
    throw new ServerError(status, errorText || statusText);
  }
  return await response.json();
}

// async function fetchValidateLink(contextPath: string, relatedClass: string, reference: any) {
//     const response = await fetch(composeValidateLinkUrl(relatedClass, reference));
//     if (!response.ok) {
//         const { status, statusText } = response;
//         throw new ServerError(status, statusText);
//     }
//     return await response.json();
// }

async function fetchLayoutImpl(
  prefix: string,
  className: string
): Promise<Layout> {
  // return parseLayout(LAYOUT, className)
  const response = await fetch(`/rest/subject/layout/${prefix}/${className}`);
  if (!response.ok) {
    const { status, statusText } = response;
    const errorText = await response.text();
    throw new ServerError(status, errorText || statusText);
  }

  return parseLayout(await response.json(), className);
}

async function fetchSubjectImpl(
  rdfId: string,
  prefix?: string
): Promise<SubjectData> {
  const url = prefix
    ? `/rest/subject/entity/${prefix}/${rdfId}`
    : `/rest/subject/entity/${rdfId}`;
  const response = await fetch(url);
  if (!response.ok) {
    const { status, statusText } = response;
    const errorText = await response.text();
    throw new ServerError(status, errorText || statusText);
  }
  return await response.json();
}

async function fetchLockImpl(
  rdfId: string,
  acquire: boolean,
  prefix?: string
): Promise<SubjectData> {
  const data = new FormData();
  data.append("rdfId", rdfId);
  data.append("acquire", JSON.stringify(acquire));
  prefix && data.append("namespace", prefix);
  const response = await fetch("/rest/subject/lock", {
    method: "POST",
    body: data,
  });

  if (!response.ok) {
    const { statusText, status } = response;
    const errorText = await response.text();
    throw new ServerError(status, errorText || statusText);
  }

  return await response.json();
}

export function fetchEnumeration(className: string) {
  const path = className.split(":");

  return fetch(`/rest/subject/enumeration/${path[0]}/${path[1]}`)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function ajaxUpload(
  task: UploadTask,
  index: number,
  loadedList: number[],
  totalList: number[],
  uploadComplete: (task: UploadTask, e: any) => void,
  uploadProgress: Function,
  headers?: { [KEY: string]: string }
): Promise<UploadMetaData> {
  let formData = new FormData();
  formData.append("file", task.file);

  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();

    // обработчик для отправки
    xhr.upload.onprogress = function (evt) {
      // log(event.loaded + ' / ' + event.total);

      if (evt.lengthComputable) {
        loadedList[index] = evt.loaded;
        totalList[index] = evt.total;
        //Sum of loaded
        let loaded: number = 0;
        for (let s of loadedList) {
          loaded += s;
        }
        //Sum of total
        let total: number = 0;
        for (let s of totalList) {
          total += s;
        }
        let percentComplete = (loaded / total) * 100;
        if (!uploadProgress(percentComplete)) {
          console.log("Abort upload!");
          xhr.abort();
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      }
    };

    // обработчики успеха и ошибки
    // если status == 200, то это успех, иначе ошибка
    xhr.onload = xhr.onerror = function (e: any) {
      if (this.status == 200) {
        try {
          const upload: UploadMetaData = JSON.parse(xhr.response);
          upload.nodeId = task.nodeId;

          resolve(upload);
          uploadComplete(task, upload.sha1);
        } catch (e) {
          reject({
            status: 404,
            statusText: e,
          });
        }
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    if (headers) {
      Object.keys(headers).forEach(function (key) {
        xhr.setRequestHeader(key, headers[key]);
      });
    }
    xhr.open("POST", c.UPLOAD_URL, true);
    xhr.send(formData);
  });
}

function filterReferences(references: RawLink[], data: RawLink[]) {
  if (!Array.isArray(data)) {
    return references;
  }
  return references.filter((reference) => {
    if (!reference.$rdfId) {
      return false;
    }
    for (let row of data) {
      if (row.$rdfId == reference.$rdfId) {
        return false;
      }
    }
    return true;
  });
}

function fetchNewSubject(
  subjectKey: string,
  className: string,
  parent?: string,
  parentRef?: string,
  prototype?: string,
  initialData?: any
): Promise<any> {
  const parts = className.split(":");
  const params: { [k: string]: string } = {};
  if (parent) {
    params.parent = parent;
  }
  if (parentRef) {
    params["parent-ref"] = parentRef;
  }
  if (prototype) {
    params._prototype = prototype;
  }
  const url = buildUrl({
    url: `${c.SUBJECT_NEW_URL}/${parts[0]}/${parts[1]}`,
    search: params,
  });
  return fetch(url)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      if (typeof initialData == "object") {
        Object.assign(json, initialData);
      }
      return json;
    });
}

//ACTIONS
export function sendSubject(
  subjectKey: string,
  subject: Subject,
  operation: OperationType,
  notifyId: any,
  diff?: SubjectDiff
): SendSubject {
  return {
    type: c.SEND_SUBJECT,
    payload: {
      subjectKey,
      subject,
      operation,
      notifyId,
      diff,
    },
  };
}

export function sendSubjectChangeTab(
  subjectKey: string,
  navId: string,
  tabId: string
): SendSubjectChangeTab {
  return {
    type: c.CHANGE_TAB,
    payload: { subjectKey, tabId, navId },
  };
}
export function sendRemoveSubjectFromStore(
  subjectKey: string
): SendRemoveSubjectFromStore {
  return {
    type: c.SEND_SUBJECT_REMOVE_FROM_STORE,
    payload: subjectKey,
  };
}

export function sendSubjectError(
  subjectKey: string,
  error: FetchError
): SendSubjectError {
  return {
    type: c.SEND_SUBJECT_ERROR,
    payload: {
      subjectKey,
      error,
    },
  };
}

export function sendSubjectSaveCancel(
  subjectKey: string
): SendSubjectSaveCancel {
  return {
    type: c.SEND_SUBJECT_CANCEL_SAVE,
    payload: subjectKey,
  };
}

export function sendSubjectSaveDone(subjectKey: string): SendSubjectSaveDone {
  return {
    type: c.SEND_SUBJECT_SAVE_DONE,
    payload: subjectKey,
  };
}

export function sendSubjectComponentUpdated(
  subjectKey: string,
  nodeId: string,
  updated: boolean
): SendSubjectComponentUpdated {
  return {
    type: c.SEND_SUBJECT_COMPONENT_UPDATED,
    payload: { subjectKey, nodeId, updated },
  };
}

export function sendSubjectLoading(
  subjectKey: string,
  loading: boolean
): SendSubjectLoading {
  return {
    type: c.SEND_SUBJECT_LOADING,
    payload: {
      subjectKey,
      loading,
    },
  };
}

export function sendSubjectLockLoading(
  subjectKey: string,
  loading: boolean
): SendSubjectLockLoading {
  return {
    type: c.SEND_SUBJECT_LOCK_LOADING,
    payload: {
      subjectKey,
      loading,
    },
  };
}

export function sendSubjectSaveWait(subjectKey: string): SendSubjectSaveWait {
  return {
    type: c.SEND_SUBJECT_SAVE_WAIT,
    payload: subjectKey,
  };
}

export function sendLayoutLoading(
  subjectClass: string,
  loading: boolean
): SendLayoutLoading {
  return {
    type: c.SEND_LAYOUT_LOADING,
    payload: {
      subjectClass,
      loading,
    },
  };
}

export function sendCachedLayout(
  className: string,
  subjectKey: string,
  layout: Layout,
  storeDiffList?: SubjectDiff[]
): SendCachedLayout {
  return {
    type: c.SEND_CACHED_LAYOUT,
    payload: {
      className,
      layout,
      storeDiffList,
      subjectKey,
    },
  };
}

function sendSubjectChangeData(
  data: SubjectDiff,
  nodeId: string
): SendSubjectChangeData {
  return {
    type: c.SEND_SUBJECT_CHANGE_DATA,
    payload: {
      data,
      nodeId,
    },
  };
}

export function sendSubjectStartSave(subjectKey: string): SendSubjectStartSave {
  // const event = {
  //     formId: subjectKey
  // };
  // $(document).trigger(Action.EVENT_FLUSH_DEBOUNCE, event);
  console.log("Debounce ready!");
  return {
    type: c.SEND_SUBJECT_START_SAVE,
    payload: subjectKey,
  };
}

export function sendSubjectChangeSaveState(
  subjectKey: string,
  saveState: SaveStateType
): SendSubjectChangeSaveState {
  return {
    type: c.SEND_SUBJECT_CHANGE_SAVE_STATE,
    payload: { subjectKey, saveState },
  };
}

function sendEnumerationLoading(className: string): SendEnumerationLoading {
  return {
    type: c.SEND_ENUMERATION_LOADING,
    payload: { className },
  };
}

function sendEnumeration(
  className: string,
  data: EnumerationInfo
): SendEnumeration {
  return {
    type: c.SEND_ENUMERATION,
    payload: { className, data },
  };
}

function sendEnumerationError(
  className: string,
  error: any
): SendEnumerationError {
  return {
    type: c.SEND_ENUMERATION_ERROR,
    payload: { className, error },
  };
}

function sendSubjectChangeLoading(
  subjectKey: string
): SendSubjectChangeLoading {
  return {
    type: c.SEND_SUBJECT_CHANGE_LOADING,
    payload: { subjectKey },
  };
}

export function sendSubjectDestroy(subjectKey: string): SendSubjectDestroy {
  return {
    type: c.SEND_SUBJECT_DESTROY,
    payload: subjectKey,
  };
}

export function sendEditingSubject(
  rdfId: string,
  isEditing: boolean
): SendEditingSubject {
  return {
    type: c.SEND_SUBJECT_EDITING,
    payload: { rdfId, isEditing },
  };
}
export function sendNewSubject(rdfId: string, isNew: boolean): SendNewSubject {
  return {
    type: c.SEND_SUBJECT_NEW,
    payload: { rdfId, isNew },
  };
}

export function sendFragmentTree(
  l: FragmentData[],
  r?: string
): SendFragmentTreeBranch {
  return {
    type: c.SEND_FRAGMENT_TREE,
    payload: { l, r },
  };
}
export function sendFragmentLoading(
  loading: boolean = true
): SendFragmentLoading {
  return {
    type: c.SEND_FRAGMENT_TREE_LOADING,
    payload: {
      loading,
    },
  };
}

export function sendFragmentError(
  error: FetchError,
  id?: string
): SendFragmentError {
  return {
    type: c.SEND_FRAGMENT_TREE_ERROR,
    payload: {
      error,
      id,
    },
  };
}
export function sendFragmentSelected(id?: string): SendFragmentSelected {
  return {
    type: c.SEND_FRAGMENT_TREE_SELECTED,
    payload: { id },
  };
}

export function startSave(
  subjectKey: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(sendSubjectStartSave(subjectKey));
    const current = getState().subject?.saveState[subjectKey];
    const subject = getState().subject?.subjects[subjectKey];

    if (
      !isFetchError(subject) &&
      subject.validation &&
      current &&
      current === c.SAVE_STATE_SHOW_ERRORS
    ) {
      for (let val of Object.values(subject.validation)) {
        if (val?.id === "OBJECTCARD_FIELD_IS_MANDATORY") {
          dispatch(
            addAlert(ALERT_LEVEL_DANGER, {
              id: "OBJECTCARD_MANDATORY_NOT_PROVIDED",
            })
          );
          return;
        }
      }
    }
  };
}

export function getFragmentsBranch(
  rdfId: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    try {
      const nodeById = getState().subject?.fragmentTree?.nodeById;
      const path = await fetchFragmentsPathImpl(rdfId);

      if (!path || !path.length) {
        return;
      }

      path.forEach((p) => {
        if (!nodeById || !nodeById[p]) {
          dispatch(fetchFragments(p));
        }
      });
    } catch (e) {
      dispatchError("FRAGMENT_SAVE_ERROR", e, dispatch);
    }
  };
}

export function fetchFragments(
  parentId?: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async (dispatch, getState) => {
    try {
      const s = getState().fragment;
      // if(!parentId && s?.rootNodesIds){
      //     return;
      // } else if(parentId && s&&s.childrenIds&&s.childrenIds[parentId]){
      //     return;
      // }
      // if (parentId && s && s.childrenIds && s.childrenIds[parentId]) {
      //     return;
      // }
      // const fragmentState = s[constants.FRAGMENT];
      // if (fragmentState && fragmentState.loading &&parentId&& fragmentState.loading[parentId]) {
      //     return;
      // }
      dispatch(sendFragmentLoading());
      const fragmentTreeBranch = await fetchFragmentsImpl(parentId);
      const { l, r } = fragmentTreeBranch;
      dispatch(sendFragmentTree(parseFragments(l), r));
    } catch (e: any) {
      dispatch(sendFragmentLoading(false));
      if (e instanceof ServerError) {
        dispatch(sendFragmentError({ code: e.code, message: e.message }));
      } else if (typeof e.message == "string") {
        dispatch(sendFragmentError({ code: -1, message: e.message }));
      } else {
        dispatch(sendFragmentError({ code: -1, message: "Unknown error" }));
      }
    }
  };
}

//ACTION GENERATORS
async function mergeSubjectAndLayout(
  subjectKey: string,
  subjectData: SubjectData,
  operation: OperationType,
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
  state: ApplicationState,
  notifyId?: string
) {
  const className = subjectData.$class;
  let cachedLayout = state.subject && state.subject.layouts[className];
  dispatch(fetchFragments());

  let subject: Subject = wrapSubjectData(subjectData);
  // subject.fragmentList = fragmentData.list;

  let diff; //Data difference
  if (cachedLayout) {
    // subject = { ...subject, ...cachedLayout };
    diff = syncDataAndLayout(subjectKey, subject, cachedLayout, true);
  }
  dispatch(sendSubject(subjectKey, subject, operation, notifyId, diff));
  if (/*state &&*/ checkLayoutFetchNeeded(className, state.subject)) {
    dispatch(sendLayoutLoading(className, true));
    const parts = className.split(":");
    try {
      cachedLayout = await fetchLayoutImpl(parts[0], parts[1]);

      dispatch(fetchLayoutEnumerations(className, cachedLayout));
      dispatch(sendLayout(subject, cachedLayout, subjectKey));
    } catch (e) {
      dispatch(sendLayoutLoading(className, false));
      dispatchError("SUBJECT_LAYOUT_LOADING_ERROR", e, dispatch, {
        name: className,
      });
    }
  }
}

export function sendLayout(
  subject: Subject,
  cachedLayout: Layout,
  subjectKey: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async (dispatch, getState) => {
    const state = getState().subject;
    const { subjectData } = subject;

    let storeDiff = [];
    if (state) {
      for (let rdfId in state.subjects) {
        const subj = state.subjects[rdfId];
        if (isSubject(subj) && subj.className == subjectData.$class) {
          const diff = syncDataAndLayout(rdfId, subj, cachedLayout, false);
          const { lock, validation, values, visibility } = diff;
          if (lock || validation || values || visibility) {
            storeDiff.push(syncDataAndLayout(rdfId, subj, cachedLayout, false));
          }
        }
      }
    }
    dispatch(
      sendCachedLayout(subject.className, subjectKey, cachedLayout, storeDiff)
    );
  };
}

export function fetchSubject(
  subjectKey: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async (dispatch, getState) => {
    const state = getState().subject;
    if (!subjectKey || !state || state.loading[subjectKey]) {
      return;
    }
    // if (!state.subjects[subjectKey] || force) {
    dispatch(sendSubjectLoading(subjectKey, true));
    try {
      const parts = subjectKey.split(":");
      const subjectData =
        parts.length > 1
          ? await fetchSubjectImpl(parts[1], parts[0])
          : await fetchSubjectImpl(subjectKey);
      mergeSubjectAndLayout(
        subjectKey,
        subjectData,
        c.SUBJECT_OPERATION_GET,
        dispatch,
        getState()
      );
    } catch (e) {
      dispatch(sendSubjectLoading(subjectKey, false));
      dispatchError("SUBJECT_FETCH_ERROR", e, dispatch, { name: subjectKey });
    }
    // }
  };
}

export function initializeStore(
  subjectKey: string,
  subjectData: SubjectData,
  layoutClass?: any
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async (dispatch, getState) => {
    dispatch(sendSubjectLoading(subjectKey, true));
    const state = getState().subject;
    const className = subjectData.$class;
    let cachedLayout = state && state.layouts[className];
    const layout = cachedLayout ? cachedLayout : makeLayout(layoutClass);

    // const layout = await getLayout(subjectData.$class as string, dispatch, getState);
    let fragmentData: any = [];
    try {
      fragmentData = await fetchFragmentsImpl();
    } catch (e) {
      dispatchError("SUBJECT_FRAGMENT_FETCH_ERROR", e, dispatch);
    }

    const fragments = !fragmentData
      ? []
      : fragmentData.list
      ? fragmentData.list
      : fragmentData;
    let subject: Subject = wrapSubjectData(subjectData);

    let diff; //Data difference
    if (layout) {
      // subject = { ...subject, ...cachedLayout };
      diff = syncDataAndLayout(subjectKey, subject, layout, true);
    }
    dispatch(
      sendSubject(subjectKey, subject, c.SUBJECT_OPERATION_INIT, null, diff)
    );

    //Fetch layout if needed
    if (state && checkLayoutFetchNeeded(className, state)) {
      dispatch(sendLayoutLoading(className, true));
      const parts = className.split(":");
      try {
        cachedLayout = await fetchLayoutImpl(parts[0], parts[1]);
        dispatch(fetchLayoutEnumerations(className, cachedLayout));
        dispatch(sendLayout(subject, cachedLayout, subjectKey));
      } catch (e) {
        dispatch(sendSubjectLoading(subjectKey, false));
        dispatch(sendLayoutLoading(className, false));
        dispatchError("SUBJECT_LAYOUT_LOADING_ERROR", e, dispatch, {
          name: className,
        });
        console.error(e);
      }
    }
  };
}

export function createNewSubject(
  subjectKey: string,
  className: string,
  parent?: string,
  parentRef?: string,
  prototype?: string,
  notifyId?: string,
  initialData?: any
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async function (dispatch, getState) {
    try {
      dispatch(
        addNewSubject(
          subjectKey,
          await fetchNewSubject(
            subjectKey,
            className,
            parent,
            parentRef,
            prototype,
            initialData
          ),
          notifyId || ""
        )
      );
    } catch (e) {
      console.error(e);
      dispatch(sendSubjectLoading(subjectKey, false));
      dispatch(sendLayoutLoading(className, false));
      dispatchError("SUBJECT_LAYOUT_LOADING_ERROR", e, dispatch, {
        name: className,
      });
    }
  };
}

export function add<T>(
  subjectKey: string,
  nodeId: string,
  data: T
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    const state = getState().subject;
    const subject = state && state.subjects[subjectKey];
    if (!state || !subject || !isSubject(subject)) {
      return;
    }
    dispatch(sendSubjectChangeLoading(subjectKey));
    const layoutClass = subject.subjectData.$class;
    const layout = state.layouts[layoutClass];

    const values = subject.values || {}; //Get current values
    const valuesDiff: any = {};
    if (Array.isArray(values[nodeId])) {
      // valuesDiff[nodeId] = [data.values[nodeId]];
      valuesDiff[nodeId] = [...values[nodeId], data];
    } else {
      if (!values[nodeId]) {
        values[nodeId] = [];
      }
      // valuesDiff[nodeId] = values[nodeId].concat(data.values[nodeId]);
      valuesDiff[nodeId] = values[nodeId].concat(data);
    }
    //Dispatch difference
    const currentState = makeState(subject);
    dispatch(
      sendSubjectChangeData(
        makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId
      )
    );
  };
}

export function change<T>(
  subjectKey: string,
  nodeId: string,
  data: T,
  options?: any
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    const state = getState().subject;
    const subject = state && state.subjects[subjectKey];
    if (!state || !subject || !isSubject(subject)) {
      return;
    }
    const layout = state.layouts[subject.className];
    if (!layout) {
      return;
    }
    dispatch(sendSubjectChangeLoading(subjectKey));
    const valuesDiff = { [nodeId]: data };
    const invalidFormat =
      options && options.invalidFormat
        ? { [nodeId]: options.invalidFormat }
        : undefined;
    const currentState = makeState(subject);
    //Dispatch difference
    // const layout = card.layoutCache[card.data[store].$class];
    dispatch(
      sendSubjectChangeData(
        makeDiff(
          subjectKey,
          layout,
          subject,
          valuesDiff,
          currentState,
          invalidFormat
        ),
        nodeId
      )
    );
  };
}

export function changeAt<T>(
  subjectKey: string,
  nodeId: string,
  index: number,
  data: T
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    const state = getState().subject;

    const subject = state && state.subjects[subjectKey];
    if (!state || !subject || !isSubject(subject)) {
      return;
    }
    const layout = state.layouts[subject.className];
    if (!layout) {
      return;
    }
    const values = subject.values || {}; //Get current values
    const valuesDiff: { [nodeId: string]: any } = {};
    if (Array.isArray(values[nodeId])) {
      valuesDiff[nodeId] = values[nodeId].map(
        (x: any, currentIndex: number) => {
          if (index != currentIndex) {
            return x;
          }
          return data;
        }
      );
    } else {
      console.error("changeAt is not an array", values[nodeId]);
    }
    //Dispatch difference
    // const layout = card.layoutCache[card.data[store].$class];
    const currentState = makeState(subject);
    dispatch(
      sendSubjectChangeData(
        makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId
      )
    );
  };
}

export function patchUpload(
  subjectKey: string,
  nodeId: string,
  path: string[],
  sha1: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    const state = getState().subject;
    const subject = state && state.subjects[subjectKey];
    if (!state || !subject || !isSubject(subject)) {
      return;
    }
    const layout = state.layouts[subject.className];
    if (!layout) {
      return;
    }
    const values = subject.values || {}; //Get current values
    const valuesDiff: { [nodeId: string]: any } = {};
    if (path.length == 0) {
      //Shallow copy
      valuesDiff[nodeId] = Object.assign({}, values[nodeId], { $sha1: sha1 });
      delete valuesDiff[nodeId].$uploadKey;
    } else {
      //Make deep copy
      const current = values[nodeId];
      let obj;
      if (Array.isArray(current)) {
        obj = [];
        for (let f of current) {
          obj.push({ ...f });
        }
      } else {
        obj = { ...current };
      }
      valuesDiff[nodeId] = obj;
      for (let p of path) {
        obj = obj[p];
      }
      obj.$sha1 = sha1;
      delete obj.$uploadKey;
    }
    //Dispatch difference
    // const layout = card.layoutCache[card.data[store].$class];
    const currentState = makeState(subject);
    dispatch(
      sendSubjectChangeData(
        makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId
      )
    );
  };
}

export function uploadFiles(
  subjectKey: string,
  handleUploadProgress: (progress: number) => void
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return (dispatch, getState) => {
    const state = getState().subject;
    const subject = state && state.subjects[subjectKey];
    if (!state || !subject || !isSubject(subject)) {
      return;
    }
    const values = subject.values;
    // const uploadTasks = getUploadTaskList(values);
    const uploadTasks: UploadTask[] = getUploadTaskList(values);
    if (uploadTasks.length > 0) {
      //With uploads
      // (task, data) => dispatch(patchUpload(subjectKey, task.nodeId, task.path, data.sha1))
      Promise.all(
        ajaxUploadFiles(
          uploadTasks,
          (task, sha1) =>
            dispatch(patchUpload(subjectKey, task.nodeId, task.path, sha1)),
          handleUploadProgress
        )
      ).then(
        (uploads: UploadMetaData[]) => {
          clearUploadTasks(uploadTasks);
          dispatch(
            sendSubjectChangeSaveState(subjectKey, c.SAVE_STATE_UPLOADS_READY)
          );
        },
        (error) => {
          //Uploads error
          const saveState = state.saveState[subjectKey];
          if (saveState) {
            //If save was in progress then show error
            dispatchError("OBJECTCARD_UPLOAD_FAILED", error, dispatch);
            dispatch(sendSubjectSaveCancel(subjectKey));
          } else {
            console.log("Upload was aborted!!!");
          }
        }
      );
    } else {
      //Without uploads
      dispatch(
        sendSubjectChangeSaveState(subjectKey, c.SAVE_STATE_UPLOADS_READY)
      );
    }
  };
}

export function saveSubject(
  subjectKey: string,
  deffered?: any,
  saveCallback?: (subject: SubjectData) => void
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    const state = getState().subject;
    const subject = state && state.subjects[subjectKey];
    if (
      !state ||
      !subject ||
      !isSubject(subject) ||
      state.loading[subjectKey]
    ) {
      return;
    }
    dispatch(sendSubjectLoading(subjectKey, true));
    const layout = state.layouts[subject.className];
    if (deffered) {
      //Make deep copy of data
      const newSubjectData = mergeSubjectValues(
        subject,
        layout,
        subject.values
      );
      deffered.resolve(newSubjectData);
    } else if (checkSavePossible(subject, subjectKey, state) && layout) {
      runPreSave(dispatch, layout, async (changedPredicates: Value) => {
        //Get notify id
        const notifyId = subject.notifyId;
        //Make deep copy of data
        const diffValue: Value = { ...subject.values, ...changedPredicates };
        const newSubjectData = mergeSubjectValues(subject, layout, diffValue);
        if (!saveCallback) {
          dispatch(sendSubjectSaveWait(subjectKey));
        }
        try {
          const subjectData = await saveSubjectImpl(newSubjectData);

          subject.isNew
            ? mergeSubjectAndLayout(
                subjectData.$rdfId,
                subjectData,
                c.SUBJECT_OPERATION_SAVE,
                dispatch,
                getState()
              )
            : mergeSubjectAndLayout(
                subjectKey,
                subjectData,
                c.SUBJECT_OPERATION_SAVE,
                dispatch,
                getState()
              );
          const message: I18NString = {
            id: "SUBJECT_SAVE_SUCCESS",
          };
          dispatch(addAlert(ALERT_LEVEL_SUCCESS, message));

          /**Used on modals to prevent default card saving callback */
          if (saveCallback) {
            saveCallback(subjectData);
            return;
          }
          if (subject.isNew) {
            subjectAddedFire(subjectData.$rdfId, subjectKey);
            //remove subject from new subject list
            dispatch(sendNewSubject(subjectData.$rdfId, false));
          } else {
            dispatch(sendEditingSubject(subjectKey, false));
          }
          dispatch(sendSubjectSaveDone(subjectKey));
          if (notifyId) {
            dispatch(notifyTreeNodeUpdate(notifyId));
          } else {
            dispatch(notifyTreeSubjectUpdate(subjectKey));
          }
        } catch (e) {
          dispatchError("SUBJECT_SAVE_ERROR", e, dispatch, {
            name: subject.className,
          });
          dispatch(sendSubjectChangeSaveState(subjectKey, "show_errors"));
          if (!saveCallback) {
            // dispatch(sendSubjectSaveDone(subjectKey));
          }
        }
      });
    } else {
      console.error(
        "Cannot save. Save state must be with ready uploads, lock required or subject must be new: ",
        getLockStatus(subject)
      );
    }
  };
}
// export function saveSubject(subjectKey: string,deffered:any callback?: (subject: Subject) => void): ThunkAction<void, ApplicationState, {}, SubjectAction> {
//     return function (dispatch, getState) {
//         const state = getState().subject;
//         const subject = state && state.subjects[subjectKey]
//         if (!state || !subject || !isSubject(subject) || state.loading[subjectKey]) {
//             return;
//         }
//         dispatch(sendSubjectLoading(subjectKey, true));
//         const layout = state.layouts[subject.className];
//         if (callback) {
//             //Make deep copy of data
//             const newSubjectData = mergeSubjectValues(subject.subjectData, subject.values);
//             callback(newSubjectData);
//         } else if (checkSavePossible(subject, subjectKey, state) && layout) {

//             runPreSave(dispatch, layout, async (changedPredicates: Value) => {
//                 //Get notify id
//                 const notifyId = subject.subjectData.$notifyId;
//                 //Make deep copy of data
//                 const diffValue: Value = { ...subject.values, ...changedPredicates }
//                 const newSubjectData = mergeSubjectValues(subject.subjectData, diffValue);
//                 dispatch(sendSubjectSaveWait(subjectKey));
//                 try {

//                     const subjectData = await saveSubjectImpl(newSubjectData);

//                     subject.isNew ? mergeSubjectAndLayout(subjectData.$rdfId, subjectData, c.SUBJECT_OPERATION_SAVE, dispatch, getState())
//                         : mergeSubjectAndLayout(subjectKey, subjectData, c.SUBJECT_OPERATION_SAVE, dispatch, getState());
//                     const message: I18NString = { id: "SUBJECT_SAVE_SUCCESS", values: { name: subject.className } };
//                     dispatch(addAlert(ALERT_LEVEL_SUCCESS, message));

//                     if (subject.isNew) {
//                         subjectAddedFire(subjectData.$rdfId, subjectKey);
//                         //remove subject from new subject list
//                         dispatch(sendNewSubject(subjectData.$rdfId, false))
//                     } else {
//                         dispatch(sendEditingSubject(subjectKey, false))
//                     }
//                     dispatch(sendSubjectSaveDone(subjectKey));

//                 } catch (e) {
//                     dispatchError("SUBJECT_SAVE_ERROR", e, dispatch, { name: subject.className });
//                     dispatch(sendSubjectSaveDone(subjectKey));
//                 }

//             });
//         } else {
//             console.error("Cannot save. Save state must be with ready uploads, lock required or subject must be new: ", getLockStatus(subject));
//         }
//     }
// }

export function fetchLock(
  subjectKey: string,
  acquire: boolean,
  force?: boolean
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async (dispatch, getState) => {
    const state = getState().subject;

    try {
      dispatch(sendSubjectLockLoading(subjectKey, true));
      dispatch(sendSubjectLoading(subjectKey, true));
      const parts = subjectKey.split(":");
      const subjectData =
        parts.length > 1
          ? await fetchLockImpl(parts[1], acquire, parts[0])
          : await fetchLockImpl(subjectKey, acquire);
      const operation = acquire
        ? c.SUBJECT_OPERATION_LOCK
        : c.SUBJECT_OPERATION_UNLOCK;
      mergeSubjectAndLayout(
        subjectKey,
        subjectData,
        operation,
        dispatch,
        getState()
      );
      if (acquire) {
        dispatch(sendEditingSubject(subjectKey, true));
      } else {
        dispatch(sendEditingSubject(subjectKey, false));
      }
    } catch (e) {
      dispatch(sendSubjectLockLoading(subjectKey, false));
      dispatch(sendSubjectLoading(subjectKey, false));
      dispatchError("OBJECTCARD_LOCK_FAILED", e, dispatch);
    }
  };
}

export function ajaxFetchEnumeration(
  className: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async (dispatch, getState) => {
    dispatch(sendEnumerationLoading(className));
    try {
      dispatch(
        sendEnumeration(
          className,
          parseEnumeration(await fetchEnumeration(className))
        )
      );
    } catch (e) {
      dispatch(sendEnumerationError(className, e));
      dispatchError("OBJECTCARD_LOADING_ENUMERATIONS_FAILD", e, dispatch);
    }
  };
}
// async function ajaxFetchEnumeration(dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>, className: string) {
//     // console.log("AJAX call to fetch enumeration:", cls);
//     dispatch(sendEnumerationLoading(className));
//     try {
//         dispatch(sendEnumeration(className, parseEnumeration(await fetchEnumeration(className))));
//     } catch (e) {
//         dispatch(sendEnumerationError(className, e));
//         dispatchError("OBJECTCARD_LOADING_ENUMERATIONS_FAILD", e, dispatch);
//     }
// }

export function fetchLayoutEnumerations(
  className: string,
  layout: Layout
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async (dispatch, getState) => {
    const state = getState().subject;

    // if (!state) {
    //     return;
    // }

    for (let enumerationClsName in layout.enumerationsMap) {
      if (
        state.enumerations[enumerationClsName] &&
        !isFetchError(state.enumerations[enumerationClsName]) &&
        !(state.enumerations[enumerationClsName] as EnumerationClass).error
      ) {
        continue;
      }
      // ajaxFetchEnumeration(dispatch, enumerationClsName);

      dispatch(ajaxFetchEnumeration(enumerationClsName));
    }
  };
}

export function subjectInfo(
  data: SubjectCardInfo
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal(
        "subject.info",
        "subjectInfo",
        { body: JSON.stringify(data) },
        async (result) => {}
      )
    );
  };
}

export function showSaveChanges(
  subjectKey: string,
  name: string,
  okCallback: Function,
  cancelCallback: Function
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal(
        "subject.showSaveChanges",
        "showSaveChanges",
        { body: JSON.stringify({ subjectKey, name }) },
        async (result) => {
          if (result) {
            okCallback();
          } else if (result === false) {
            cancelCallback();
          }
        }
      )
    );
  };
}

export function addObjTableModal(
  options: ModalOptions,
  modify: (subject: Subject) => void
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal("subject.addObjTable", "card", options, async (result) => {
        if (result) {
          modify(result);
          // dispatch(sendSubjectComponentUpdated(options.currentSubjectKey, options.nodeId, true))
        }
      })
    );
  };
}
export function addTableRefTable(
  options: TableModalOptions,
  modify: (subject: Subject) => void
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal("subject.addRefTable", "cimtable", options, async (result) => {
        if (result) {
          modify(result);
        }
      })
    );
  };
}

export function confirmItemRemoveModal(
  callback: () => void
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal(
        "subject.confirmItemRemoveModal",
        "confirmItemRemoveModal",
        {
          title: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE" },
          body: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW",
        },
        async (result) => {
          if (result) {
            callback();
          }
        }
      )
    );
  };
}

export function addTreeRefTable(
  subjectKey: string,
  nodeId: string,
  options: TreeModalOptions
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal("subject.addRefTable", "addRefTable", options, async (data) => {
        if (data) {
          dispatch(link(subjectKey, nodeId, data));
        }
      })
    );
  };
}

export function pasteRefTableModal(
  subjectKey: string,
  nodeId: string,
  className: string,
  multiple: boolean
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal(
        "subject.pasteRefTable",
        "pasteRefTable",
        {},
        async (refs: RawLink[]) => {
          if (refs) {
            dispatch(
              pasteRefs(
                subjectKey,
                nodeId,
                className,
                multiple ? refs : refs.slice(0, 1)
              )
            );
          }
        }
      )
    );
  };
}

export function pasteRefs(
  subjectKey: string,
  nodeId: string,
  relatedClass: string,
  references: RawLink[]
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async function (dispatch, getState) {
    const state = getState().subject;
    const subject = state && state.subjects[subjectKey];
    if (
      !state ||
      !subject ||
      !isSubject(subject) ||
      state.loading[subjectKey]
    ) {
      return;
    }
    const contextPath = getState().location.contextPath;
    const data: RawLink[] = obtainData(nodeId, subject.subjectData);

    let validLinks: Link[] = await validateLinks(
      contextPath,
      relatedClass,
      filterReferences(references, data),
      dispatch
    );

    if (!validLinks || !validLinks.length) {
      dispatch(
        addAlert(ALERT_LEVEL_WARNING, { id: "OBJECTCARD_VALIDATION_EMPTY" })
      );
      return;
    }

    validLinks &&
      dispatch(
        openModal(
          "subject.confirmPasteRefTable",
          "confirmPasteRefTable",
          { body: JSON.stringify(validLinks) },
          (links) => {
            if (links && Array.isArray(links)) {
              for (let linkData of links) {
                dispatch(
                  link(subjectKey, nodeId, {
                    $rdfId: linkData.rdfId,
                    $label: linkData.label,
                    $description: linkData.description,
                  })
                );
              }
            }
          }
        )
      );
  };
}

export function remove(
  subjectKey: string,
  nodeId: string,
  indexList: number[] | number
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return function (dispatch, getState) {
    const state = getState().subject;
    if (!state) {
      return;
    }
    const subject = state && state.subjects[subjectKey];

    if (
      !state ||
      !subject ||
      !isSubject(subject) ||
      state.loading[subjectKey]
    ) {
      return;
    }
    const className = subject.className;
    const layout = state.layouts[className];
    const values = subject.values || {}; //Get current values
    const valuesDiff: { [nodeId: string]: any } = {};
    const removeMap: { [ids: number]: boolean } = {};
    if (Array.isArray(indexList)) {
      //index list
      for (let idx of indexList) {
        removeMap[idx] = true;
      }
    } else {
      //one index
      removeMap[indexList] = true;
    }
    if (Array.isArray(values[nodeId])) {
      valuesDiff[nodeId] = values[nodeId].filter(
        (_: any, currentIndex: number) => !Boolean(removeMap[currentIndex])
      );
    }
    //Dispatch difference
    const currentState = makeState(subject);
    dispatch(
      sendSubjectChangeData(
        makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId
      )
    );
  };
}

export function link(
  subjectKey: string,
  nodeId: string,
  data: any
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async function (dispatch, getState) {
    const state = getState().subject;
    if (!state) {
      return;
    }
    const subject = state && state.subjects[subjectKey];
    if (!isSubject(subject)) {
      return;
    }
    const layout = state.layouts[subject.className];
    const node = layout.nodeById[nodeId];
    if (!node) {
      return;
    }
    const values = subject.values || {}; //Get current values
    const multiple =
      layout.nodeById[nodeId] && layout.nodeById[nodeId].multiple
        ? true
        : false;
    const valuesDiff: { [nodeId: string]: any } = {};
    if (multiple) {
      if (!Array.isArray(values[nodeId])) {
        valuesDiff[nodeId] = [data];
      } else {
        valuesDiff[nodeId] = values[nodeId].concat(data);
      }
    } else {
      valuesDiff[nodeId] = data;
    }
    const funcId = layout.automation && layout.automation.linkBindings[nodeId];

    if (funcId) {
      //We have link action to do so we need to fetch subject from server
      let linkedSubject: SubjectData | null = null;
      try {
        linkedSubject = await fetchSubjectImpl(data.$rdfId, data.$namespace);
      } catch (e) {
        dispatchError("SUBJECT_FETCH_ERROR", e, dispatch, {
          name: data.$rdfId,
        });
      }
      if (!linkedSubject) {
        return;
      }
      if (multiple) {
        valuesDiff[nodeId].pop();
        valuesDiff[nodeId].push(refreshLinkSubject(linkedSubject));
      } else {
        valuesDiff[nodeId] = refreshLinkSubject(linkedSubject);
      }
      const nextValues = Object.assign({}, subject.values, valuesDiff);
      const form = makeForm(nextValues, subject.subjectData);
      const func = retrieveFunction(funcId);
      try {
        const result = func(form, linkedSubject);
        //Check if we need to fill other predicates
        if (result && typeof result === "object") {
          for (let predicateNodeId of layout.predicateNodesIds) {
            const d = obtainData(predicateNodeId, result);
            if (d) {
              valuesDiff[predicateNodeId] = d;
            }
          }
        }
        linkSubject(subjectKey, nodeId, valuesDiff, state, dispatch);
      } catch (e) {
        dispatchError("SUBJECT_LINK_BINDING_ERROR", e, dispatch, {
          name: subjectKey,
        });
      }
    } else {
      //Dispatch difference immediately

      linkSubject(subjectKey, nodeId, valuesDiff, state, dispatch);
    }
  };
}

export function exportCard(
  rdfId: string,
  namespace: any,
  type: c.EXPORT_TYPE,
  contextPath: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    let href = `${contextPath}${c.SUBJECT_EXPORT_URL}`;
    if (namespace) {
      if (typeof namespace == "object") {
        namespace = namespace.$prefix;
      }
      href += `/${namespace}`;
    }
    href += `/${rdfId}?type=${type}`;
    try {
      downloadFileImpl(href);
    } catch (e) {
      dispatchError("FILEARCHIVE_NOT_FOUND", e, dispatch);
    }
  };
}

export function checkRef(
  relatedClass: string,
  reference: any,
  callback: Function,
  omitErrors?: boolean
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async function (dispatch, getState) {
    const contextPath = getState().location.contextPath;
    try {
      const result = await fetchValidateLink(
        contextPath,
        relatedClass,
        reference
      );
      callback(Boolean(result));
    } catch (e) {
      callback(false);
      if (!omitErrors) {
        dispatchError("OBJECTCARD_VALIDATION_ERROR", e, dispatch);
      }
    }
  };
}

export function safeCheckRef(
  relatedClass: string,
  reference: any,
  callback: Function,
  omitErrors?: boolean
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async function (dispatch, getState) {
    const contextPath = getState().location.contextPath;
    const result = await safeFetchValidateLink(
      contextPath,
      relatedClass,
      reference
    );
    if (
      result.failed &&
      (!omitErrors ||
        (result as any).error.code === 403 ||
        (result as any).error.code === 500)
    ) {
      dispatchError("OBJECTCARD_VALIDATION_ERROR", result.error, dispatch);
    }
    callback(result);
  };
}

export function registerUpload(
  subjectKey: string,
  nodeId: string,
  files: File[],
  multiple: boolean
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    //Prepare data for redux
    let data: FileValue[] | FileValue | null = null;
    if (multiple) {
      data = [];
    }
    for (let file of files) {
      //Add data to upload queue
      const key = enqueUpload(file);
      //Create redux data
      let actionData: FileValue = {
        $uploadKey: key,
        $tmpName: file.name,
        $label: file.name,
        $description: "",
        $contentType: file.type,
        $contentSize: file.size,
      };
      if (multiple && Array.isArray(data)) {
        data.push(actionData);
      } else {
        data = actionData;
      }
    }
    //Send data to redux
    const state = getState().subject;
    if (!state) {
      return;
    }
    const subject = state && state.subjects[subjectKey];
    if (!isSubject(subject)) {
      return;
    }
    const values = subject.values || {}; //Get current values
    const layout = state.layouts[subject.className];
    const valuesDiff: { [nodeId: string]: any } = {};
    if (Array.isArray(data)) {
      //Special case for multiple uploads
      if (Array.isArray(values[nodeId])) {
        valuesDiff[nodeId] = values[nodeId].concat(data);
      } else {
        valuesDiff[nodeId] = data;
      }
    } else {
      valuesDiff[nodeId] = data;
    }
    //Dispatch difference
    // const layout = card.layoutCache[card.data[store].$class];
    const currentState = makeState(subject);
    dispatch(
      sendSubjectChangeData(
        makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId
      )
    );
  };
}

export function click(
  subjectKey: string,
  buttonId: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    const state = getState().subject;
    if (!state) {
      return;
    }
    const subject = state && state.subjects[subjectKey];
    if (!isSubject(subject)) {
      return;
    }
    const values = subject.values || {}; //Get current values
    const layout = state.layouts[subject.className];

    const data = subject.subjectData;
    const form = makeForm(values, data, dispatch);
    if (!layout.automation) {
      return;
    }
    const funcId = layout.automation.clickBindings[buttonId];

    const func = retrieveFunction(funcId);
    try {
      func(form);
    } catch (ex) {
      if (typeof func !== "function") {
        dispatchError("SUBJECT_CLICK_BINDING_ERROR_NO_FUNC", ex, dispatch);
        return;
      }
      dispatchError("SUBJECT_CLICK_BINDING_ERROR", ex, dispatch);
    }
  };
}

export function reportClick(
  subjectKey: string,
  buttonId: string,
  href: string,
  params: ReportParams
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return function (dispatch, getState) {
    const state = getState().subject;
    if (!state) {
      return;
    }
    const subject = state && state.subjects[subjectKey];
    if (!isSubject(subject)) {
      return;
    }
    const values = subject.values || {}; //Get current values
    const layout = state.layouts[subject.className];

    const data = subject.subjectData;
    if (!layout.automation) {
      return;
    }
    const funcId = layout.automation.clickBindings[buttonId];
    if (funcId) {
      const form = makeForm(values, data, dispatch);
      const func = retrieveFunction(funcId);
      try {
        const overrideParams = func(form, { filename: params.filename });
        params.filename = overrideParams.filename || params.filename;
      } catch (ex) {
        console.log("Report click binding error", buttonId, ex);
      }
    }
    href += "?" + toUrlSearchParams(params);
    downloadFile(href, params.filename);
  };
}

export function addNewSubject(
  subjectKey: string,
  subjectData: SubjectData,
  notifyId: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async function (dispatch, getState) {
    await mergeSubjectAndLayout(
      subjectKey,
      subjectData,
      c.SUBJECT_OPERATION_CREATE,
      dispatch,
      getState(),
      notifyId
    );

    /**Fetch ref subjects data that added to subject manually */
    dispatch(connectRefPredicates(subjectKey));
  };
}

function connectRefPredicates(
  subjectKey: string
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async function (dispatch, getState) {
    const subjectsStore = getState().subject.subjects;
    const subject = subjectsStore[subjectKey];
    if (!subject || isFetchError(subject)) {
      return;
    }

    /**Find all references without label and fetch subjects to fill values */
    const updatePredicatesBySubject: {
      [k: string]: (string | { predicateId: string; idx: number })[];
    } = {};
    for (let predicateId in subject.values) {
      const value = subject.values[predicateId];
      if (!value) {
        continue;
      }

      if (!Array.isArray(value)) {
        if (typeof value !== "object" || !value.$rdfId || value.$label) {
          continue;
        }
        const subjectKey = value.$namespace
          ? `${value.$namespace}:${value.$rdfId}`
          : value.$rdfId;
        if (!updatePredicatesBySubject[subjectKey]) {
          updatePredicatesBySubject[subjectKey] = [];
        }
        updatePredicatesBySubject[subjectKey].push(predicateId);
        continue;
      }
      for (let i = 0; i < value.length; ++i) {
        const item = value[i];
        if (typeof item !== "object" || !item.$rdfId || item.$label) {
          continue;
        }

        const subjectKey = item.$namespace
          ? `${item.$namespace}:${item.$rdfId}`
          : item.$rdfId;
        if (!updatePredicatesBySubject[subjectKey]) {
          updatePredicatesBySubject[subjectKey] = [];
        }

        updatePredicatesBySubject[subjectKey].push({ predicateId, idx: i });
      }
    }

    for (let fetchSubjectKey in updatePredicatesBySubject) {
      const fetchStoreSubject = subjectsStore[fetchSubjectKey];

      dispatch(
        updatePredicatesSubjectData(
          subjectKey,
          fetchSubjectKey,
          updatePredicatesBySubject[fetchSubjectKey],
          fetchStoreSubject
        )
      );
    }
  };
}

function updatePredicatesSubjectData(
  subjectKey: string,
  fetchSubjectKey: string,
  predicates: (string | { predicateId: string; idx: number })[],
  storedSubject?: Subject | FetchError
): ThunkAction<void, ApplicationState, {}, SubjectAction> {
  return async function (dispatch, getState) {
    const parts = fetchSubjectKey.split(":");

    try {
      const subjectData =
        parts.length > 1
          ? await fetchSubjectImpl(parts[1], parts[0])
          : await fetchSubjectImpl(fetchSubjectKey);
      const predicateData = {
        $namespace: subjectData.$namespace,
        $rdfId: subjectData.$rdfId,
        $label: subjectData.$label,
        $description: subjectData.$description,
      };
      for (let predicate of predicates) {
        if (typeof predicate === "string") {
          dispatch(change(subjectKey, predicate, predicateData));
          continue;
        }
        dispatch(
          changeAt(
            subjectKey,
            predicate.predicateId,
            predicate.idx,
            predicateData
          )
        );
      }
    } catch (e) {}
  };
}
