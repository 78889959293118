import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../types';
import { isLoggedInUser } from '../../types/security';

import AccessDeniedIcon from '../../icons/AccessDeniedIcon';
import NotFoundIcon from '../../icons/NotFoundIcon';

import styles from './StatusPage.module.css';

interface StatusPageProps {
    status?: number | string
}
const StatusPage: React.FunctionComponent<StatusPageProps> = React.memo((props) => {
    return <div className={`${styles.statusPageContainer} text-primary stroke-primary fill-primary`}>
        <StatusPageIcon status={props.status} />
        <StatusPageInfo status={props.status} />
    </div>;
});

const StatusPageIcon: React.FunctionComponent<StatusPageProps> = (props) => {
    let icon = null;
    if (props.status === 403) {
        icon = <AccessDeniedIcon />
    } else {
        icon = <NotFoundIcon />
    }
    return <div className={styles.statusPageIcon}>{icon}</div>;
};

const StatusPageInfo: React.FunctionComponent<StatusPageProps> = (props) => {
    if (props.status === "CSS_CHUNK_LOAD_FAILED") {
        const linkClick = (event: React.MouseEvent) => {
            event.preventDefault();
            document.location.reload()
        }
        return <div className={`d-flex flex-column justify-content-center ${styles.statusPageInfo}`}>
            <div className={styles.statusPageMessage}>
                <FormattedMessage
                    id="VIEW_PAGE_PLATFORM_UPDATED"
                    defaultMessage="Platform was updated. Please, reload page."
                    description="Platform updated message for view" />
                <div>
                    <a href="#" className={styles.helpLink} onClick={linkClick}>
                        <FormattedMessage
                            id="VIEW_RELOAD"
                            defaultMessage="Reload"
                            description="Reload link" />
                    </a>
                </div>
            </div>
        </div>;
    }
    return <div className={`d-flex flex-column justify-content-center ${styles.statusPageInfo}`}>
        <div className={`${styles.statusPageCode} ${typeof props.status !== "number" ? styles.nonNumber : ""}`}>{props.status}</div>
        <div className={styles.statusPageMessage}>
            <StatusPageMessage status={props.status} />
            <div>
                <Link to="/help" className={styles.helpLink}>
                    <FormattedMessage
                        id="VIEW_HELP"
                        defaultMessage="Help"
                        description="Help link" />
                </Link>
            </div>
        </div>
    </div>;
};

const StatusPageMessage: React.FunctionComponent<StatusPageProps> = (props) => {
    if (props.status === 403) {
        return <>
            <FormattedMessage
                id="VIEW_ACCESS_DENIED"
                defaultMessage="User is not allowed to view this page. Please contact administrators to get access."
                description="Access denied message for view" />
        </>
    }
    if (props.status === 404) {
        return <>
            <FormattedMessage
                id="VIEW_PAGE_NOT_FOUND"
                defaultMessage="Page not found."
                description="Page not found message for view" />
        </>
    }
    return <>
        <FormattedMessage
            id="VIEW_PAGE_FETCH_ERROR"
            defaultMessage="Error occured during view fetch."
            description="Error message for view" />
    </>;
};

export default connect(
    (state: ApplicationState) => {
        if (isLoggedInUser(state.security.loginStatus)) {
            return {
                logo: state.security.loginStatus?.mainMenu?.logoByLang?.[state.locale.language]
            }
        }
        return {}
    })(StatusPage);