import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from '../../types';
import { CancelCallback, CloseCallback, I18NString, ModalOptions, OkCallback } from '../../types/modal';
import { AlertLevelType } from '../../types/alert';
import { SearchData } from '../../types/location';
import { ALERT_LEVEL_DANGER } from '../../constants/alert';
import { addAlert } from '../../actions/alert';

import asyncComponent, { AsyncComponentRenderer, AsyncComponentState, AsyncModule, importAsyncComponent } from '../../AsyncComponent';
import { ViewContext } from './ViewContext';
import { useEffect } from 'react';

interface ViewProps {
    viewPath: string
    printing?: boolean
    onViewReady?: () => void
    loadingErrorHandler: (level: AlertLevelType, messge: I18NString) => void
    moduleHook?: (module: AsyncModule) => void
    [P: string]: any
}

const View = ({ viewPath, loadingErrorHandler, moduleHook, ...otherProps }: ViewProps) => {

    const [state, setState] = useState<AsyncComponentState>({ asynComponentStateValue: null })

    useEffect(() => {
        const fetch = async () => {
            const r = await importAsyncComponent(
                () => (window as any).__NPT__.import('/rest/js' + viewPath), 
                moduleHook);
            setState(r as AsyncComponentState)
        }
        fetch().catch(console.error);
    }, [viewPath])

    const page = useContext(ViewContext);
    const errorHandler = (error: any) => {
        if (error && typeof error === "object") {
            error = error.message;
        }
        const msgObject: I18NString = { id: "VIEW_LOAD_ERROR", values: { error } };
        loadingErrorHandler(ALERT_LEVEL_DANGER, msgObject);
    }
    return <AsyncComponentRenderer 
        alertError={(error: any) => errorHandler(error)}
        {...otherProps}
        {...state}
        page={page} />;
}

export const ConnectedView = connect(null, (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>) => {
    return {
        loadingErrorHandler: (level: AlertLevelType, messge: I18NString) => dispatch(addAlert(level, messge))
    }
})(View)

export default View;