import { MenuData } from "../types/security";

export function isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}
export function round(n: number, d: number) {
    if (typeof n === 'undefined') {
        return n
    }
    var digits = +"1".padEnd(d + 1, "0");
    return Math.round(n * digits) / digits;
}

export const getMenuReferences = (prefix: string, menu?: MenuData) => {
    const refs: string[] = []
    if (menu?.roots?.length) {
        const { roots, itemById } = menu
        roots.forEach(root => {
            const { r } = itemById[root]
            if (r) {
                const ref = r.replace(prefix, "");
                refs.push(ref.toLowerCase())
            }
        })
    }
    return refs
}
export const isPageAllowed = (prefix: string, pathname: string, menuIds?: string[]) => {
    if (!menuIds?.length) {
        return false
    }
    const part = pathname.replace(prefix, '').split("/")?.[1]

    if (part) {
        return menuIds.includes(part.toLowerCase())
    }
    return true
}