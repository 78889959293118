import { Reducer } from 'redux';

import * as constants from '../constants/location';
import { LocationState, LocationAction, SendSearchParameters, SearchData, SendHideQuery } from '../types/location';
import { isEmptyObject } from '../services/app';

const initialState: LocationState = {
    params: {},
    contextPath: "",
    hideQuery: false
}

function receiveSearchParameters(state: LocationState, payload: { params: SearchData, reset: boolean }) {
    if(isEmptyObject(state.params) && isEmptyObject(payload.params)){
        return state;
    }
    if(!payload.reset){
        let diffFound = false;
        for (let p in payload.params) {
            if (state.params[p] === payload.params[p]) {
                continue;
            }
            diffFound = true;
        }
        if (!diffFound) {
            return state;
        }
    }
    const nextState = { ...state };
    const parameters = { ...payload.params };
    delete (parameters.object);
    delete (parameters.type);
    if(payload.reset){
        nextState.params = Object.assign({}, parameters);
    } else {
        nextState.params = Object.assign({}, state.params, parameters);
    }
    return nextState;
}

function receiveHideQuery(state: LocationState, payload: { hideQuery: boolean }) {
    return { ...state, hideQuery: payload.hideQuery };
}

const reducer: Reducer<LocationState, LocationAction> = (state: LocationState = initialState, action: LocationAction): LocationState => {
    switch (action.type) {
        case constants.SEND_SEARCH_PARAMETERS: return receiveSearchParameters(state, (action as SendSearchParameters).payload);
        case constants.SEND_HIDE_QUERY: return receiveHideQuery(state, (action as SendHideQuery).payload);
    }
    return state;
}

export default reducer;