
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import shortid from 'shortid';
import * as c from '../constants/tree';
import { ApplicationAction, ApplicationState } from '../types';
import {
    TreeState,
    TreeNode,
    SendTreeLoading,
    SendTreeNodes,
    SendTreeNodeToggle,
    TreeAction,
    SendTreeNodeActive,
    SendContextMenuInfo,
    TreeReducerState,
    isTreeNode,
    SendTreeCompress,
    SendTreeActiveNodeValid,
    SendExpandTreeWithEditingSubjects,
    TreeHeader,
    SendTreeHeader,
    TreeNodeType,
    TreeNodeData,
    isTreeNodeAddAction,
    SendTreeNodesForceUpdate,
    TreeNodeAddAction,
    TreeNodeGenericAction,
    isTreeNodeGenericAction,
    ServerTreeNode,
    SendTreeFilterChange
} from '../types/tree';
import { FetchError } from '../types/error';
import { SubjectData } from '../types/subject';
import { isLoginStatus } from '../types/security';
import { addAlert, dispatchError, dispatchSuccess } from './alert';
import { ServerError } from './utils';
import { openModal } from './modal';
import { downloadFile, sendNewSubject } from './subject';
import { composeAddUrl, composeFetchUrl, composePasteUrl, composeZipUrl } from '../services/npt-treebeard';
import { getSearchData } from '../services/location';
import { copyTextToClipboard } from '../services/clipboard';
import { addSubjectFire, getRdfId } from '../services/subject';
import { composeNodeActionId, getNodePathByRdfId } from '../services/tree';
import { retrieveFunction } from '../services/automation';
import { CancelCallback, CloseCallback, ModalOptions, OkCallback } from '../types/modal';
import { AlertLevelType } from '../types/alert';
import { sendSelection } from './selection';
import { changeSearch } from './location';
import { createObject } from '../types/selection';

/***********************
 * Composers functions *
 ***********************/
export function getModelPath(path: string) {
    const idx = path.indexOf('?');
    if (idx >= 0) {
        return path.substring(0, idx);
    }
    return path;
}

export function composeTreeFetchUrl(path: string, parentNodeId?: string) {
    const link = `${c.TREE_FETCH_URL}${getModelPath(path)}`;
    let search = "";
    if (parentNodeId) {
        search += `?id=${parentNodeId}`;
    }
    return `${link}${search}`;
}
export function composePathToNodeUrl(path: string, rdfId: string) {
    const idx = path.indexOf('?');
    if (idx >= 0) { //we have already model parameters (may be &m=sha1?)
        return `${c.TREE_PATH_URL}${path}&object=${rdfId}`;
    } else {
        return `${c.TREE_PATH_URL}${path}?object=${rdfId}`;
    }
}
export function composeDecendantsRefsUrl(path: string, rdfId: string) {
    const link = `${c.TREE_DECENDANTS_REFS_URL}${getModelPath(path)}?node=${rdfId}`;
    return link;
}

export async function fetchTreeHeaderImpl(path: string): Promise<TreeHeader> {
    const response = await fetch(`${c.TREE_FETCH_HEADER_URL}${path}`);
    if (!response.ok) {
        const { status, statusText } = response;
        const resp = await response.text();
        throw new ServerError(status, resp);
    }
    return await response.json();
}

export async function fetchTreeNodesImpl(path: string, parentId?: string): Promise<{ hash: string, nodes: ServerTreeNode[] }> {

    const response = await fetch(composeTreeFetchUrl(path, parentId));
    if (!response.ok) {
        const { status, statusText } = response;
        const resp = await response.text();
        throw new ServerError(status, resp);
    }

    return await response.json();
}

/*******************
 * Parse functions *
 *******************/
function parseTreeNode(typeById: { [k: number]: TreeNodeType }, node: ServerTreeNode): TreeNode {
    const type = typeById[node.t];
    const typeName = type ? type.type : "undefined";
    let nodeId = typeof node.m == 'string'? node.m + "." + node.t: node.t.toString();
    let nodeDescription = !node.d || node.d === "null" ? "" : node.d;
    let nodeData: TreeNodeData | null = null;
    if (node.o) {
        nodeId += `.${node.o}`;
        const objectDelimiterIdx = node.o.indexOf(":");
        if (objectDelimiterIdx !== -1) {
            nodeData = {
                $namespace: node.o.substring(0, objectDelimiterIdx),
                $rdfId: node.o.substring(objectDelimiterIdx + 1),
                $label: node.l,
                $description: nodeDescription
            }
        } else {
            nodeData = {
                $rdfId: node.o,
                $label: node.l,
                $description: nodeDescription
            }
        }
        if (node.f) {
            nodeData.$fragment = { $rdfId: node.f };
        }
    }
    return {
        id: nodeId,
        nodeId: nodeId,
        parentId: null,
        typeId: typeName,
        label: node.l,
        description: nodeDescription,
        leaf: type.leaf,
        visible: true, //TODO
        deleteLock: Boolean(type.deleteLock),
        moveLock: Boolean(type.moveLock),
        copyLock: Boolean(type.copyLock),
        data: nodeData,
        cache: node.cd || null,
        name: node.l,
        loading: false,
        icon: node.icon || type.icon,
        iconColor: node.iconColor || type.iconColor,
        addActions: getAddActions(type),
        genericActions: getGenericActions(type),
        syntetic: node.s,
        pasteTypes: type.pasteTypes
    }
}

function getAddActions(type: TreeNodeType) {
    if (!type.actions) {
        return undefined;
    }
    return type.actions.filter(action => isTreeNodeAddAction(action)) as TreeNodeAddAction[];
}

function getGenericActions(type: TreeNodeType) {
    if (!type.actions) {
        return undefined;
    }
    return type.actions.filter(action => isTreeNodeGenericAction(action)) as TreeNodeGenericAction[];
}

export function parseTreeNodes(header: TreeHeader, treeData: { hash: string, nodes: ServerTreeNode[] }): TreeNode[] {
    const typeById: { [k: number]: TreeNodeType } = {};
    for (let typeName in header.typeMap) {
        const type = header.typeMap[typeName];
        typeById[type.id] = type;
    }
    return treeData.nodes.map((node: any) => parseTreeNode(typeById, node));
}

function getFetchIdByNodeId(header: TreeHeader, id?: string): string | undefined {
    if (typeof id != 'string') {
        return header.model
    } else {
        return id;
    }
}

/*******************
 * Fetch functions *
 *******************/

async function fetchPathToNodeImpl(path: string, rdfId: string): Promise<string[]> {
    const response = await fetch(composePathToNodeUrl(path, rdfId));
    if (!response.ok) {
        const { status, statusText } = response;
        const resp = await response.text();
        throw new ServerError(status, resp);
    }
    return await response.json();
}
async function fetchDecendantsRefsImpl(path: string, nodeId: string): Promise<any> {
    const response = await fetch(composeDecendantsRefsUrl(path, nodeId));
    if (!response.ok) {
        const { status, statusText } = response;
        const resp = await response.text();
        throw new ServerError(status, resp);
    }
    return await response.json();
}
async function fetchAddChildImpl(treeId: string, nodeId: string, addActionId: string, params: any): Promise<SubjectData> {
    const response = await fetch(composeAddUrl(treeId, nodeId, addActionId, params));
    if (!response.ok) {
        const { status, statusText } = response;
        const resp = await response.text();
        throw new ServerError(status, resp);
    }
    const data: SubjectData = await response.json();
    return data;
}
async function fetchPasteChildImpl(treeId: string, nodeId: string, sourceId: string, copy: boolean, lockIgnore: boolean): Promise<{ parentSubject: SubjectData, subject: SubjectData, targetSubject: SubjectData }> {
    const response = await fetch(composePasteUrl(treeId, nodeId, sourceId, copy, lockIgnore), { method: 'POST' });
    if (!response.ok) {
        const { status, statusText } = response;
        const resp = await response.text();
        throw new ServerError(status, resp);
    }
    const data: { parentSubject: SubjectData, subject: SubjectData, targetSubject: SubjectData } = await response.json();
    return data;
}
async function fetchDeleteNodeImpl(treeId: string, nodeId: string, isSelectedNode: boolean, deleteLockIgnore: boolean): Promise<number> {
    const response = await fetch(composeFetchUrl(treeId, nodeId, { force: deleteLockIgnore }), { method: 'DELETE' });
    if (!response.ok) {
        const { status, statusText } = response;
        const resp = await response.text();
        throw new ServerError(status, resp);
    }
    const data = await response.json();
    const numberOfDeleted = data.numberOfDeleted;
    return numberOfDeleted;
}


export function sendContextMenuInfo(path: string, nodeId: string): SendContextMenuInfo {
    return {
        type: c.SEND_TREE_NODE_CTX_MENU_INFO,
        payload: {
            nodeId,
            treeId: path
        }
    }
}
export function sendExpandTreeWithEditingSubjects(treeId: string, nodes: string[]): SendExpandTreeWithEditingSubjects {
    return {
        type: c.SEND_EXPAND_TREE_WITH_EDITING_SUBJECTS,
        payload: {
            nodes,
            treeId
        }
    }
}

function isFetchTreeNodesNeeded(state: TreeReducerState, path: string, nodeId?: string) {
    if (!nodeId) {
        return true;
    }
    const node = state.treeInfo[path]?.nodeById?.[nodeId];
    if (!isTreeNode(node)) {
        return false
    }
    return !node.leaf;
}

export function sendTreeHeader(path: string, header: TreeHeader): SendTreeHeader {
    return {
        type: c.SEND_TREE_HEADER,
        payload: {
            path, header
        }
    }
}

export function sendTreeNodes(path: string, nodes: TreeNode[] | FetchError, parentId?: string): SendTreeNodes {
    return {
        type: c.SEND_TREE_NODES,
        payload: {
            path, nodes, parentId
        }
    }
}

export function sendTreeLoading(path: string, id: string, loading: boolean = true): SendTreeLoading {
    return {
        type: c.SEND_TREE_LOADING,
        payload: { path, loading, id }
    }
}

export function sendTreeNodeActive(path: string, nodeId: string): SendTreeNodeActive {
    return {
        type: c.SEND_TREE_NODE_ACTIVE,
        payload: { path, nodeId }
    }
}

export function sendTreeActiveNodeValid(path: string, isValid: boolean, id: string): SendTreeActiveNodeValid {
    return {
        type: c.SEND_TREE_ACTIVE_NODE_VALID,
        payload: { path, isValid, id }
    }
}

export function sendTreeNodeToggle(path: string, nodeId: string, expanded: boolean): SendTreeNodeToggle {
    return {
        type: c.SEND_TREE_NODE_TOGGLE,
        payload: { path, nodeId, expanded }
    }
}

export function sendTreeCompress(path: string): SendTreeCompress {
    return {
        type: c.SEND_TREE_COMPRESS,
        payload: { path }
    }
}

export function sendTreeNodesForceUpdate(path: string, nodesIdList: (string | null)[]): SendTreeNodesForceUpdate {
    return {
        type: c.SEND_TREE_NODES_FORCE_UPDATE,
        payload: { path, nodesIdList }
    }
}

export function sendTreeFilterChange(path: string, value: string, checked: boolean): SendTreeFilterChange {
    return {
        type: c.SEND_TREE_FILTER_CHANGE,
        payload: { path, value, checked }
    }
}

/***********
 * Actions *
 ***********/
export function expandTreeNode(treeId: string, nodeId: string, rdfId?: string): ThunkAction<void, ApplicationState, {}, TreeAction> {
    return async (dispatch, getState) => {
        dispatch(sendTreeNodeToggle(treeId, nodeId, true));
        const state = getState();
        if (!isFetchTreeNodesNeeded(state.tree, treeId, nodeId)) {
            return;
        }
        const tree = state.tree?.treeInfo?.[treeId];
        try {
            dispatch(sendTreeLoading(treeId, nodeId));
            let treeHeader = tree?.header;
            if (!treeHeader) {
                treeHeader = await fetchTreeHeaderImpl(treeId);
                dispatch(sendTreeHeader(treeId, treeHeader));
                /**TODO: find type id by type name */
            }
            const nodes = parseTreeNodes(treeHeader, await fetchTreeNodesImpl(treeId, getFetchIdByNodeId(treeHeader, nodeId)));
            if (rdfId) {
                const activeNode: TreeNode | null = getNodeByRdfId(rdfId, nodes)
                activeNode && dispatch(sendTreeNodeActive(treeId, activeNode.id));
            }
            dispatch(sendTreeNodes(treeId, nodes, nodeId));

        } catch (e) {
            let fetchError: FetchError = dispatchError("SUBJECT_TREE_FETCH_ERROR", e, dispatch);
            dispatch(sendTreeNodes(treeId, fetchError, nodeId));
        }

    };
}

function getNodeByRdfId(rdfId: string, nodes: TreeNode[]) {
    for (const node of nodes) {

        if (isTreeNode(node) && node.data && node.data.$rdfId === rdfId) {
            return node;
        }
    }
    return null;
}

export function expandTreeWithEditingNodes(treeId: string): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        const subjects = getState().subject;
        const tree = getState().tree.treeInfo[treeId];
        if (subjects && subjects.editingSubjects) {
            const editingSubjects: string[] = [];
            for (const e of Object.entries(subjects.editingSubjects)) {
                const [rdfId, v] = e;
                let nodeId = null;
                if (v && tree?.nodeById) {
                    for (const n of Object.values(tree?.nodeById)) {
                        if (isTreeNode(n) && n.data && n.data.$rdfId === rdfId) {
                            nodeId = n.id;
                            editingSubjects.push(nodeId);
                            break;
                        }
                    }
                }
            }
            dispatch(sendExpandTreeWithEditingSubjects(treeId, editingSubjects))
        }
    };
}

export function fetchTreeHeader(path: string, parentNodeId?: string): ThunkAction<void, ApplicationState, {}, TreeAction> {
    return async (dispatch, getState) => {
        const state = getState().tree;

        const id = path;
        const tree = state?.treeInfo?.[path];
        const loading = tree && tree.loading;
        if (loading && loading[id]) {
            return;
        }

        try {
            let treeHeader = tree && tree.header;
            if (treeHeader) {
                sendTreeLoading(path, id, false)
                return;
            }
            dispatch(sendTreeHeader(path, await fetchTreeHeaderImpl(path)));
        }
        catch (e) {
            sendTreeLoading(path, id, false)
            dispatchError("SUBJECT_TREE_FETCH_HEADER_ERROR", e, dispatch);
        }

    }
}

export function fetchTreeNodes(path: string, parentNodeId?: string): ThunkAction<void, ApplicationState, {}, TreeAction> {
    return async (dispatch, getState) => {
        const state = getState().tree;

        const id = parentNodeId || path;
        const tree = state?.treeInfo?.[path];
        const loading = tree && tree.loading;
        if (loading && loading[id]) {
            return;
        }

        try {
            if (!state || isFetchTreeNodesNeeded(state, path, parentNodeId)) {
                dispatch(sendTreeLoading(path, id));
                let treeHeader = tree && tree.header;
                if (!treeHeader) {
                    treeHeader = await fetchTreeHeaderImpl(path);
                    dispatch(sendTreeHeader(path, treeHeader));
                }
                const nodes = parseTreeNodes(treeHeader, await fetchTreeNodesImpl(path, getFetchIdByNodeId(treeHeader, parentNodeId)));
                dispatch(sendTreeNodes(path, nodes, parentNodeId));
            }
        }
        catch (e) {
            const id = parentNodeId || path;
            sendTreeLoading(path, id, false)
            dispatchError("SUBJECT_TREE_FETCH_ERROR", e, dispatch)
            let fetchError: FetchError = { code: -1, message: 'unknown error' }
            if (e instanceof ServerError) {
                fetchError = { code: e.code, message: e.message }
            }
            dispatch(sendTreeNodes(path, fetchError, parentNodeId));
            // dispatch(sendTreeNodes(path,fetchError));
        }

    }
}

export function expandTreeUsingPath(treeId: string, rdfId: string, rootNodeRdfId?: string, changeSelection?: boolean): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        try {
            const treeState = getState().tree;
            const tree = treeState?.treeInfo[treeId];
            if (!tree || (tree.nodeIdByRdfId?.[rdfId] && tree.nodeIdByRdfId?.[rdfId] === tree.active)) {
                return;
            }
            let nodePath: string[] = getNodePathByRdfId(tree, rdfId) || await fetchPathToNodeImpl(treeId, rdfId);
            /**Reduce node path to specified node to prevent additional loadings */
            if (rootNodeRdfId) {
                let pathToRoot: string[] | null = rootNodeRdfId === rdfId ? nodePath : getNodePathByRdfId(tree, rootNodeRdfId);
                if (!pathToRoot) {
                    pathToRoot = await fetchPathToNodeImpl(treeId, rootNodeRdfId)
                }
                if (pathToRoot.length !== 0) {
                    const rootId = pathToRoot[pathToRoot.length - 1];
                    const rootParentNodeId = pathToRoot.length === 1 ? null : pathToRoot[pathToRoot.length - 2];
                    const indexInPath = nodePath.indexOf(rootId);
                    /**If selected node is not part of filtered tree, then we need to load only tree roop parent */
                    if (indexInPath === -1) {
                        // dispatchError("NAVTREE_FETCH_TREE_PATH_ERROR", new Error("Selected node isn't part of filtered tree"), dispatch);
                        nodePath = rootParentNodeId ? [rootParentNodeId] : [];
                    } else if (rootParentNodeId) {
                        /**If parent node is not null, then node path should be sliced up to parent node id */
                        nodePath = nodePath.slice(nodePath.indexOf(rootParentNodeId));
                    }
                } else {
                    console.error(`Can't set root node, object with rdfId "${rootNodeRdfId}" not exist in tree "${treeId}"`);
                }
            }
            for (let index = 0; index < nodePath.length; index++) {
                const nodeId = nodePath[index];
                try {
                    if (!tree.children || !tree.children[nodeId]) {
                        dispatch(sendTreeLoading(treeId, nodeId));
                        let treeHeader = tree && tree.header;
                        if (!treeHeader) {
                            treeHeader = await fetchTreeHeaderImpl(treeId);
                            dispatch(sendTreeHeader(treeId, treeHeader));
                            /**TODO: find type id by type name */
                        }
                        const nodes = parseTreeNodes(treeHeader, await fetchTreeNodesImpl(treeId, nodeId));
                        dispatch(sendTreeNodes(treeId, nodes, nodeId));
                    }
                    if (index !== nodePath.length - 1) {
                        if (!tree.toggled || !tree.toggled[nodeId]) {
                            dispatch(sendTreeNodeToggle(treeId, nodeId, true))
                        }
                        continue;
                    }
                    if (index === nodePath.length - 1 && tree.active !== nodeId) {
                        dispatch(sendTreeNodeActive(treeId, nodeId));
                    }
                } catch (e) {
                    let fetchError: FetchError = dispatchError("SUBJECT_TREE_FETCH_ERROR", e, dispatch);
                    dispatch(sendTreeNodes(treeId, fetchError, nodeId));
                }
            }
        } catch (e) {
            // dispatchError("NAVTREE_FETCH_TREE_PATH_ERROR", 'fail to expand tree', dispatch);
        }
    };
}

function fetchDeleteNode(treeId: string, nodeId: string, isSelectedNode: boolean, deleteLockIgnore: boolean): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
    return async (dispatch, getState) => {
        try {
            const parentId = getState().tree.treeInfo[treeId]?.nodeById?.[nodeId]?.parentId || undefined;
            const deletedCount = await fetchDeleteNodeImpl(treeId, nodeId, isSelectedNode, deleteLockIgnore);
            dispatchSuccess("SUBJECT_TREE_DELETE_SUCCESS", dispatch, { count: deletedCount });
            if (isSelectedNode) {
                dispatch(sendSelection("", ""));
            }
            dispatch(fetchTreeNodes(treeId, parentId));
        } catch (e) {
            let fetchError: FetchError = dispatchError("SUBJECT_TREE_DELETE_ERROR", e, dispatch);
            // dispatch(sendTreeNodes(path, fetchError, parentNodeId));
        }
    };
}

export function confirmDeleteTreeNode(node: TreeNode, treeId: string): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        const options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "NAVTREE_DELETE_CONFIRM", values: { node: node.label } },
            type: "checkbox",
            options: [{ value: "deleteLock", label: { id: "NAVTREE_IGNORE_LOCK" } }]
        }
        const loginStatus = getState().security.loginStatus;
        let isSuperUser = isLoginStatus(loginStatus) && loginStatus.superUser;
        dispatch(openModal("tree.confirmDelete", isSuperUser ? "quiz" : "confirm", options, async (result) => {
            const deleteLockIgnore = isSuperUser && result !== null;
            let isSelectedNode = false;
            if (node.data) {
                const searchData = getSearchData();
                if (node.data.$namespace == searchData['namespace']
                    && node.data.$rdfId == searchData['object']) {
                    isSelectedNode = true;
                }
            }
            dispatch(fetchDeleteNode(treeId, node.id, isSelectedNode, deleteLockIgnore));
        }));
    };
}

export function addChild(data: SubjectData, nodeId: string): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        addSubjectFire(data, nodeId);
        dispatch(sendNewSubject(data.$rdfId || data.$class, true));
    };
}

export function fetchAddChild(treeId: string, nodeId: string, addActionId: string, prototype?: any): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        try {
            const data: SubjectData = await fetchAddChildImpl(treeId, nodeId, addActionId, { prototype });
            dispatch(addChild(data, nodeId));
        } catch (e) {
            let fetchError: FetchError = dispatchError("SUBJECT_TREE_FETCH_ADD_ERROR", e, dispatch);
            // dispatch(sendTreeNodes(path, fetchError, parentNodeId));
        }
    };
}

function fetchPasteChild(treeId: string, targetId: string, sourceId: string, copy: boolean, lockIgnore: boolean): ThunkAction<void, ApplicationState, {}, ApplicationAction> {
    return async (dispatch, getState) => {
        const treeState = getState().tree.treeInfo[treeId]
        const sourceNode = treeState?.nodeById?.[sourceId];
        const sourceParentId = sourceNode?.parentId || undefined;
        try {
            const result = await fetchPasteChildImpl(treeId, targetId, sourceId, copy, lockIgnore);
            dispatch(fetchTreeNodes(treeId, targetId));
            if (copy) {
                dispatchSuccess("SUBJECT_TREE_COPY_SUCCESS", dispatch);
                dispatch(expandTreeUsingPath(treeId, result?.subject?.$rdfId));
                dispatch(sendSelection(result?.subject?.$rdfId, getState().selection.info?.type || ""));
                return;
            }
            dispatchSuccess("SUBJECT_TREE_MOVE_SUCCESS", dispatch);
            dispatch(fetchTreeNodes(treeId, sourceParentId));
            return;
        } catch (e) {
            dispatchError(copy ? "SUBJECT_TREE_COPY_ERROR" : "SUBJECT_TREE_MOVE_ERROR", e, dispatch);
        }
    };
}

export function confirmPasteTreeChild(treeId: string, targetId: string, sourceId: string, copy: boolean): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        const treeState = getState().tree.treeInfo[treeId]
        const sourceNode = treeState?.nodeById?.[sourceId];
        const targetNode = treeState?.nodeById?.[targetId];
        const sourceParentId = sourceNode?.parentId || undefined;
        const options: ModalOptions = {
            title: { id: "MSG_CONFIRM_ACTION" },
            type: "checkbox",
            options: [{ value: "lock", label: { id: "NAVTREE_IGNORE_LOCK" } }]
        }
        const loginStatus = getState().security.loginStatus;
        let isSuperUser = isLoginStatus(loginStatus) && loginStatus.superUser;
        if (!isSuperUser) {
            if (copy && sourceNode?.copyLock) {
                dispatch(openModal("tree.confirmPaste", "alert", { body: { id: "NAVTREE_COPY_LOCKED", values: { node: sourceNode?.label } } }));
                return;
            }
            if (!copy && sourceNode?.moveLock) {
                dispatch(openModal("tree.confirmPaste", "alert", { body: { id: "NAVTREE_MOVE_LOCKED", values: { node: sourceNode?.label } } }));
                return;
            }
        }
        if (copy) {
            if (targetId === sourceParentId) {
                options.body = { id: "NAVTREE_DUPLICATE_CONFIRM", values: { node: sourceNode?.label } };
            } else {
                options.body = { id: "NAVTREE_COPY_CONFIRM", values: { target: targetNode?.label, source: sourceNode?.label } };
            }
        } else {
            options.body = { id: "NAVTREE_MOVE_CONFIRM", values: { target: targetNode?.label, source: sourceNode?.label } };
        }
        dispatch(openModal("tree.confirmPaste", isSuperUser ? "quiz" : "confirm", options, async (result) => {
            const lockIgnore = isSuperUser && result !== null;
            dispatch(fetchPasteChild(treeId, targetId, sourceId, copy, lockIgnore));
        }));
    };
}

export function copyNodeRef(node: TreeNode): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        if (!node.data) {
            return
        }
        const ref = JSON.stringify([{ "$rdfId": node.data.$rdfId, "$namespace": node.data.$namespace || null }]);

        if (copyTextToClipboard(ref)) {
            dispatchSuccess("NAVTREE_REF_COPY_SUCCESS", dispatch);
        } else {
            dispatchError("NAVTREE_REF_COPY_ERROR", 'fail to copy node reference', dispatch);
        }
    };
}

export function copyNodeWithDescendantsRefs(treeId: string, nodeId: string): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        const refs = await fetchDecendantsRefsImpl(treeId, nodeId);

        if (copyTextToClipboard(JSON.stringify(refs))) {
            dispatchSuccess("NAVTREE_REF_COPY_SUCCESS", dispatch);
        } else {
            dispatchError("NAVTREE_REF_COPY_ERROR", 'fail to copy to clip board', dispatch);
        }
    };
}

export function confirmDownloadNodeZip(treeId: string, nodeId: string): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        const treeState = getState().tree.treeInfo[treeId]
        const node = treeState?.nodeById?.[nodeId];
        const options: ModalOptions = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "NAVTREE_DOWNLOAD_ZIP_CONFIRM", values: { node: node?.label } },
            type: "checkbox",
            options: [{ value: "lock", label: { id: "NAVTREE_IGNORE_LOCK" } }]
        }
        const loginStatus = getState().security.loginStatus;
        let isSuperUser = isLoginStatus(loginStatus) && loginStatus.superUser;
        dispatch(openModal("tree.confirmDownloadZip", isSuperUser ? "quiz" : "confirm", options, async (result) => {
            const lockIgnore = isSuperUser && result !== null;
            downloadFile(composeZipUrl(treeId, nodeId, lockIgnore), `${node?.label}.zip`);
        }));
    };
}

/**Try to notify node update to trees that have node with id == nodeId */
export function notifyTreeNodeUpdate(nodeId: string): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        const treeState = getState().tree;
        for (let treeId in treeState.treeInfo) {
            if (!treeState.treeInfo[treeId].nodeById?.[nodeId]) {
                continue;
            }
            dispatch(sendTreeNodesForceUpdate(treeId, [nodeId]));
        }
    };
}

/**Try to notify node update to trees that have node with subjectKey */
export function notifyTreeSubjectUpdate(subjectKey: string): ThunkAction<void, ApplicationState, {}, AnyAction> {
    return async (dispatch, getState) => {
        const treeState = getState().tree;
        for (let treeId in treeState.treeInfo) {
            const nodeId = treeState.treeInfo[treeId].nodeIdByRdfId?.[subjectKey];
            if (!nodeId) {
                continue;
            }
            const node = treeState.treeInfo[treeId].nodeById?.[nodeId];
            if (!node || node.syntetic) {
                continue;
            }
            dispatch(sendTreeNodesForceUpdate(treeId, [node.parentId]));
        }
    };
}