import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { ThunkDispatch } from 'redux-thunk';
import { dispatchError } from '../../../actions/alert';
import { sendSubjectChangeTab } from '../../../actions/subject';
import { normalizePredicate } from '../../../services/layout';
import { getUpload } from '../../../services/subject';
import { ApplicationState, ApplicationAction } from '../../../types/index.js';
import { ActiveTabs, isSubject, Layout, LayoutNode } from '../../../types/subject';
import Resizer from '../../resizer/Resizer';

interface Point {
    x: number
    y: number
}

//https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
const isAbsolute = new RegExp('^([a-z]+://|//)', 'i');

const NS = "http://www.w3.org/2000/svg";

function addGlowFilter(element: any) {
    const defs = document.createElementNS(NS, "defs");

    const filter = document.createElementNS(NS, "filter");
    filter.setAttribute("id", "glow");
    filter.setAttribute("x", "-200%");
    filter.setAttribute("y", "-200%");
    filter.setAttribute("width", "500%");
    filter.setAttribute("height", "500%");

    const blur = document.createElementNS(NS, "feGaussianBlur");
    blur.setAttribute("stdDeviation", "1.5");
    blur.setAttribute("result", "coloredBlur");

    const merge = document.createElementNS(NS, "feMerge");
    const mergeNode1 = document.createElementNS(NS, "feMergeNode");
    mergeNode1.setAttribute("in", "coloredBlur");
    const mergeNode2 = document.createElementNS(NS, "feMergeNode");
    mergeNode2.setAttribute("in", "SourceGraphic");
    merge.appendChild(mergeNode1);
    merge.appendChild(mergeNode2);

    filter.appendChild(blur);
    filter.appendChild(merge);

    defs.appendChild(filter);
    element.appendChild(defs);
}

function parsePoint(stringPoint: string) {
    const coordinate = stringPoint.trim().replace(/[a-zA-Z]/, "").split(" ");
    return {
        x: parseFloat(coordinate[0]),
        y: parseFloat(coordinate[1])
    };
}


interface ImageProps {
    contextPath: string,
    subjectKey: string
    nodeId: string
    node?: LayoutNode
    layout?: Layout
    tabs?: ActiveTabs
    value?: any
    visible?: boolean
    dispatchError: (mess: string, error: any) => void
    changeTab: (tabId: string, navId: string) => void
}
interface ImageStates {
    fullScreen: boolean
    pan: boolean
    zoom: number
    initialHeight: number | null
    initialWidth: number | null
}
class Image extends React.Component<ImageProps, ImageStates> {

    private maxWidth: number = 0
    private maxHeight: number = 0
    private imageRef: any | null;
    private bindedTabs: any;
    private initialHeight: number = 0
    private initialWidth: number = 0
    constructor(props: ImageProps) {
        super(props);
        this.changeZoom = this.changeZoom.bind(this);
        this.svgOnLoad = this.svgOnLoad.bind(this);
        const zoom = props.node?.options?.resizerOptions?.zoom || 1
        const width = props.node?.options?.width || null
        const height = props.node?.options?.height || null
        this.state = {
            zoom,
            pan: false,
            fullScreen: false,
            initialHeight: null,
            initialWidth: null
        }

        this.maxWidth = this.getDimension(width, 1000);
        this.maxHeight = this.getDimension(height, this.maxWidth);

        this.imageRef = null;

        this.bindedTabs = {};

        this.changeTab = this.changeTab.bind(this);
    }

    /* All size checks were happened in resizer */
    changeZoom(zoom: number) {
        this.setState({ zoom });
    }


    parseRefSearchParams(str: string) {
        if (!str || !str.includes('#?')) {
            return null
        }
        str = str.replace('#?', '')
        if (!str) {
            return null
        }
        const params = str.split('&');
        const paramsMap: { [KEY: string]: string } = {}
        params.forEach(q => {
            const param = q.trim().split('=')
            paramsMap[param[0]] = param[1]
        })
        return Object.keys(paramsMap).length === 0 ? null : paramsMap
    }


    handleKeydown = (e: any) => {
        const { fullScreen, pan } = this.state
        if (fullScreen && e.keyCode === 27) {
            this.toggleFullScreen()
        }

        if (e.shiftKey && !pan) {
            this.togglePan()
        }
    }
    handleKeyUp = (e: any) => {
        const { pan } = this.state
        if (!e.shiftKey && pan) {
            this.togglePan()
        }
    }
    /* CHECK SVG */
    /* Check Links baseUrl */
    svgOnLoad(event: any) {
        this.bindedTabs = {};
        /*https://gist.github.com/leonderijke/c5cf7c5b2e424c0061d2*/
        const baseUrl = window.location.href.replace(window.location.hash, "");
        const svg = event.target.contentDocument.documentElement
        if (!svg) {
            return
        }
        addGlowFilter(svg)
        svg.addEventListener('keydown', this.handleKeydown)
        svg.addEventListener('keyup', this.handleKeyUp)
        const aList: any[] = event.target.contentDocument.querySelectorAll('a');
        if (!aList) {
            return aList
        }
        aList.forEach(e => {
            e.setAttribute('target', '_top')
            let ref = e.getAttribute('xlink:href')
            if (typeof ref == 'string' && !isAbsolute.test(ref) && ref.charAt(0) != '/') {
                if (this.isTabLink(ref)) {
                    const tabName = this.getTabLink(ref);
                    this.bindTab(e, tabName);
                    e.onclick = this.changeTab.bind(this, tabName)
                    // $(el).on("click", this.changeTab.bind(this, tabName));
                } else if (ref.charAt(0) == '#') {
                    const paramsMap = this.parseRefSearchParams(ref)
                    if (!paramsMap) {
                        return
                    }
                    const url = new URL(baseUrl);
                    url.searchParams.set('object', paramsMap['object'])
                    e.setAttribute("xlink:href", url.toString())
                } else {
                    e.setAttribute("xlink:href", baseUrl + "/" + ref);
                }
            }
        })
        // $(event.target.contentDocument).find('a').each((_, el) => {
        //     $(el).attr("target", "_top");
        //     const ref = $(el).attr("xlink:href");
        //     if (typeof ref == 'string' && !isAbsolute.test(ref) && ref.charAt(0) != '/') {
        //         if (this.isTabLink(ref)) {
        //             const tabName = this.getTabLink(ref);
        //             this.bindTab(el, tabName);
        //             $(el).on("click", this.changeTab.bind(this, tabName));
        //         } else if (ref.charAt(0) == '#') {
        //             $(el).attr("xlink:href", baseUrl + ref);
        //         } else {
        //             $(el).attr("xlink:href", baseUrl + "/" + ref);
        //         }
        //     }
        // });
        this.selectBindedElement();
    }

    isTabLink(ref: string) {
        return ref.startsWith("#?tab=");
    }

    getTabLink(ref: string) {
        return ref.replace("#?tab=", "");
    }

    bindTab(el: any, tab: any) {
        if (this.bindedTabs[tab]) {
            if (!Array.isArray(this.bindedTabs[tab].el)) {
                this.bindedTabs[tab].el = [this.bindedTabs[tab].el];
            }
            this.bindedTabs[tab].el.push(el);
            return;
        }
        const tabId = this.getTabIdByLink(tab);
        const navId = this.getNavIdByTabId(tabId || '');
        this.bindedTabs[tab] = {
            id: tabId,
            navId: navId,
            el: el,
            selected: false
        }
    }

    /* CHECK tabs */
    getTabIdByLink(tab: string) {
        const { layout } = this.props
        if (!layout?.tabsIds) {
            return null;
        }
        for (let tabId of layout.tabsIds) {
            if (tabId.endsWith(tab)) {
                return tabId;
            }
        }
        return null;
    }

    getNavIdByTabId(tabId: string) {
        if (!this.props.layout || !this.props.layout.parentNodeById[tabId]) {
            return null;
        }
        return this.props.layout.parentNodeById[tabId];
    }

    changeTab(tabName: string, event: any) {
        event.preventDefault();
        this.props.changeTab(this.bindedTabs[tabName].id, this.bindedTabs[tabName].navId);
    }

    buildEmptyImage() {
        // return (<center><i className="fa fa-file-image-o fa-5x" aria-hidden="true"></i></center>);
        return (<div className="d-flex justify-content-center"><i className="fa fa-file-image-o fa-5x" aria-hidden="true"></i></div>);
    }

    getDimension(x: number, defaultValue: number) {
        if (typeof x == 'number') {
            return x;
        }
        if (typeof x == 'string') {
            const parsed = parseInt(x);
            if (!isNaN(parsed)) {
                return parsed;
            }
        }
        return defaultValue;
    }
    getImgHeight = () => {
        return this.initialHeight ? this.initialHeight * this.state.zoom : undefined
    }
    getImgWidth = () => {
        return this.initialWidth ? this.initialWidth * this.state.zoom : undefined
    }
    getImageStyle() {
        //https://www.w3.org/Style/Examples/007/center.en.html
        const style: CSSProperties = {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: this.getImgHeight() || "auto",
            width: this.initialWidth ? this.initialWidth * this.state.zoom : "auto"
        }
        if (!this.initialHeight) {
            style.maxHeight = this.maxHeight
        }
        if (!this.initialWidth) {
            style.maxWidth = this.maxWidth
        }
        return style
    }

    getResizerOptions() {
        if (!this.props.node?.options?.resizing) {
            return {};
        }
        let resizerOptions = {
            maxWidth: this.maxWidth,
            maxHeight: this.maxHeight
        };
        if (this.props.node.options.resizerOptions) {
            Object.assign(resizerOptions, {
                minZoom: this.props.node.options.resizerOptions.minZoom,
                maxZoom: this.props.node.options.resizerOptions.maxZoom,
                zoomPoints: this.props.node.options.resizerOptions.zoomPoints,
                theme: this.props.node.options.resizerOptions.theme,
                fixed: this.props.node.options.resizerOptions.fixed,
            });
        }
        return resizerOptions;
    }
    isEmptyObject(obj: any) {
        return !obj || Object.values(obj).length === 0
    }
    getFileUploadData(file: any) {
        if ((typeof file != "object" || this.isEmptyObject(file))) {
            return null;
        }
        if (!file.$sha1 && !file.$uploadKey) {
            return null;
        }
        const upload = file.$uploadKey && getUpload(file.$uploadKey);
        if (upload && !upload.preview) {
            return null;
        }
        return upload;
    }




    buildImage(file: any, upload = this.getFileUploadData(file)) {
        if (upload === null) {
            return this.buildEmptyImage();
        }
        const cp = this.props.contextPath;
        const ref = upload ? upload.preview : `${cp}rest/file/download/${file.$sha1}`;
        const alt = upload ? upload.name : file.$originalName;
        const imgStyle = this.getImageStyle();
        if (file.$contentType == "image/svg+xml") {
            return (<object data={ref} type="image/svg+xml" onLoad={this.svgOnLoad} style={imgStyle} ref={ref => this.imageRef = ref}>{alt} (SVG is unsupported!)</object>);
        }
        return (<img src={ref} alt={alt} style={imgStyle} ref={this.imageRef} />);
    }

    /* CHECK remove */
    removeGlowFromChildren(element: any) {
        if (Array.isArray(element)) {
            for (let item of element) {
                this.removeGlowFromChildren(item);
            }
            return;
        }

        const elem = element.querySelector('.glow');
        elem.parentNode.removeChild(elem);
    }

    /* CHECK add element */
    addGlow(child: any) {
        const copy = child.cloneNode();
        copy.setAttribute("filter", "url(#glow)");
        copy.style.filter = "url(#glow)";
        copy.classList.add('glow')
        child.parentNode.prepend(copy);
    }

    /* Adding glow filter to horizontal/vertical line requires rect element as base */
    addStraightLineGlow(child: any, startPoint: Point, endPoint: Point) {
        const width = parseFloat(child.style.strokeWidth) * 0.65;
        const $rect = document.createElementNS(NS, "rect");
        $rect.setAttribute("x", `${startPoint.x}`);
        $rect.setAttribute("y", `${startPoint.y}`);
        $rect.setAttribute("width", `${endPoint.x - startPoint.x}`);
        $rect.setAttribute("height", `${endPoint.y - startPoint.y}`);
        const widthAttr = $rect.getAttribute("width")
        if (typeof widthAttr !== 'undefined' && widthAttr !== null && parseFloat(widthAttr) == 0) {
            $rect.setAttribute("x", `${startPoint.x - width / 2}`);
            $rect.setAttribute("width", `${width}`);
        } else {
            $rect.setAttribute("y", `${startPoint.y - width / 2}`);
            $rect.setAttribute("height", `${width}`);
        }
        $rect.style.fill = child.style.stroke;
        $rect.style.filter = "url(#glow)";
        $rect.classList.add("glow");
        child.parentNode.prepend($rect);
    }

    addPathGlow(child: any) {
        /* If path is horizontal/vertical line - we need to replace it with rectangle for correct glow effect */
        let horizontal = true;
        let vertical = true;

        // const d = $(child).attr("d");
        const d = child.getAttribute("d");

        if (d.search(/[a-kn-zA-KN-Z]/) != -1) {
            this.addGlow(child);
            return;
        }

        const points = d.replace("M", "").split(/[lmLM]/);
        const startPoint = parsePoint(points.splice(0, 1)[0]);
        let endPoint = null;
        for (let point of points) {
            endPoint = parsePoint(point);
            if (vertical && endPoint.x != startPoint.x) {
                vertical = false;
            }
            if (horizontal && endPoint.y != startPoint.y) {
                horizontal = false;
            }
            if (!horizontal && !vertical) {
                this.addGlow(child);
                return;
            }
        }
        if (!endPoint) {
            return;
        }
        if (horizontal) {
            this.addStraightLineGlow(child, startPoint, endPoint);
            return;
        }
        if (vertical) {
            this.addStraightLineGlow(child, startPoint, endPoint);
            return;
        }
    }

    addLineGlow(child: any) {
        const startPoint = {
            x: parseFloat(child.getAttribute("x1")),
            y: parseFloat(child.getAttribute("y1"))
        }
        const endPoint = {
            x: parseFloat(child.getAttribute("x2")),
            y: parseFloat(child.getAttribute("y2"))
        }
        if (startPoint.x == endPoint.x) {
            this.addStraightLineGlow(child, startPoint, endPoint);
            return;
        }
        if (startPoint.y == endPoint.y) {
            this.addStraightLineGlow(child, startPoint, endPoint);
            return;
        }
        this.addGlow(child);
    }

    addGlowToChildren(element: any) {
        if (Array.isArray(element)) {
            for (let item of element) {
                this.addGlowToChildren(item);
            }
            return;
        }
        element.querySelectorAll('rect').forEach((child: any) => {
            this.addGlow(child)
        })
        element.querySelectorAll('path').forEach((child: any) => {
            this.addPathGlow(child)
        })
        element.querySelectorAll('line').forEach((child: any) => {
            this.addLineGlow(child)
        })
        element.querySelectorAll('ellipse').forEach((child: any) => {
            this.addGlow(child)
        })
        element.querySelectorAll('polyline').forEach((child: any) => {
            this.addGlow(child)
        })
    }

    deselectBindedElement(props = this.props) {
        for (let tabName in this.bindedTabs) {
            if (this.bindedTabs[tabName].selected) {
                this.removeGlowFromChildren(this.bindedTabs[tabName].el);
            }
        }
    }

    selectBindedElement(props = this.props) {
        for (let tabName in this.bindedTabs) {
            const tabId = this.bindedTabs[tabName].id;
            const navId = this.bindedTabs[tabName].navId;
            const activeTabId = props.tabs && props.tabs[navId] && props.tabs[navId].active;
            if (tabId == activeTabId) {
                this.addGlowToChildren(this.bindedTabs[tabName].el);
                this.bindedTabs[tabName].selected = true;
            }
        }
    }

    readInitialSizes(elm: any) {
        this.initialHeight = elm.offsetHeight;
        this.initialWidth = elm.offsetWidth;
        if (!this.initialHeight || !this.initialWidth) {
            return;
        }
        if (this.state.zoom != 1) {
            this.forceUpdate();
        }
    }

    setupInitialWidth() {
        const image = this.imageRef
        // const image = this.imageRef?.current
        // const image = ReactDOM.findDOMNode(this.imageRef); 
        if (!image) {
            return;
        }
        if (image.contentDocument) {
            this.readInitialSizes(image);
            return;
        }
        image.onload = () => {
            this.readInitialSizes(image);
        }
    }

    componentWillReceiveProps(nextProps: ImageProps) {
        if (this.props.tabs != nextProps.tabs) {
            this.deselectBindedElement(nextProps);
            this.selectBindedElement(nextProps);
        }
    }

    componentDidMount() {
        if (!this.props.node || !this.props.node.options.resizing) {
            return;
        }
        this.setupInitialWidth();
    }

    componentDidUpdate() {
        if (!this.initialHeight || !this.initialWidth) {
            console.warn("Objectcard image can't setup initial sizes (img, height, width):", this.imageRef, this.initialHeight, this.initialWidth)
            // console.warn("Objectcard image can't setup initial sizes (img, height, width):", ReactDOM.findDOMNode(this.imageRef), this.initialHeight, this.initialWidth)
        }
        if (!this.props.node || !this.props.node.options.resizing || (this.initialHeight && this.initialWidth)) {
            return;
        }
        this.setupInitialWidth();
    }
    toggleFullScreen = () => {
        const { fullScreen } = this.state
        this.setState({ fullScreen: !fullScreen })
    }
    togglePan = () => {
        const { pan } = this.state
        this.setState({ pan: !pan })
    }
    render() {

        const style = this.props.visible ? null : { display: "none" };
        const { value } = this.props;
        const { fullScreen, pan } = this.state
        //For single image
        if (!Array.isArray(value)) {
            const upload = this.getFileUploadData(value);
            if (this.props.node && this.props.node.options.resizing && upload !== null) {
                return <Resizer
                    pan={pan}
                    theme={'primary'}
                    height={this.getImgHeight()}
                    width={this.getImgWidth()}
                    togglePan={this.togglePan}
                    fullscreen={fullScreen}
                    toggleFullScreen={this.toggleFullScreen}
                    zoom={this.state.zoom}
                    changeZoom={this.changeZoom}
                    style={style || undefined}
                    {...this.getResizerOptions()}
                >
                    {this.buildImage(value, upload)}
                </Resizer>
            };
            return <div className="position-relative overflow-auto" style={Object.assign({ maxHeight: this.maxHeight, maxWidth: this.maxWidth }, style)}>
                {this.buildImage(value, upload)}
            </div>
        }

        if (value.length == 0) {
            return (<div style={style || {}}>{this.buildEmptyImage()}</div>);
        }

        const settings = {
            dots: true,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const _this = this;

        return (
            <Slider {...settings} >
                {value.map((file: any, index: number) => (<div key={index}>{_this.buildImage(file)}</div>))}
            </Slider>);

    }
}

export default connect(
    (state: ApplicationState, ownProps: { subjectKey: string, nodeId: string }) => {
        const { subjectKey } = ownProps
        const subject = state.subject && state.subject.subjects[ownProps.subjectKey];
        if (!isSubject(subject)) {
            return {
                contextPath: "/"
            };
        }
        const node = subject.nodeById[ownProps.nodeId];
        const valueId = node && node.options.src;
        const val = valueId && subject.values[normalizePredicate(valueId)];
        const visible = subject.visibility[ownProps.nodeId] ? true : false;

        return {
            tabs: state.subject?.tabs?.[subjectKey],
            node,
            value: val,
            contextPath: state.location.contextPath,
            visible,
            layout: subject
        }
    }, (dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>, ownProps: { subjectKey: string, nodeId: string }) => {
        return {
            dispatchError: (mess: string, error: any) => { console.error(mess, error) },
            // dispatchError:(mess:string,error:any )=> dispatchError(mess,error,dispatch ),
            changeTab: (tabId: string, navId: string) => dispatch(sendSubjectChangeTab(ownProps.subjectKey, navId, tabId))
        }
    })(Image);