import { Action } from 'redux';

import * as constants from '../constants/subject';
import { ModalOptions, OkCallback, CancelCallback, CloseCallback, I18NString } from './modal';
import { FetchError } from './error';
import { FragmentData,   FragmentTreeData } from './fragment';
import { CheckBoxKeys, UI_INPUT_FORMAT } from '../constants/subject';
import { TreeHeaderFilter, TreeNode, TreeState } from './tree';

 

export interface NewSubjectEventOptions{
    notifyId:string
    data:SubjectData
}
export interface AddedSubjectEventOptions{
    newRdfId:string
    oldRdfId:string
}

export interface Parameter{
    object: string
    addId: string
    namespace?:any
}
export interface AddAction{
    id: string
    clazz: string
    parentRef: string
    label: string
    table?:any
}
export interface MenuItemAction{
    id?: string
    label?: string
    icon?: string
    onAction:(param?:any)=>void
}

export interface ReportParams {
    file: string
    filename: string
    type: string
}

export interface RefTableTreeOptions {
    treeId: string
    nodeTypeId: string
    size: number
    height: number
    selectFragment?: boolean
    className: string
}

export interface UploadMetaData {
    contentLength: number
    contentType: string
    sha1: string
    storageUrl: string
    uploadTimestamp: string
    nodeId: string
}

export interface LabelInfo {
    words: string[]
    maxLength: number
    longestWord: string
}
export interface ClassRelationTypeInfo {
    peerClass?: {
        stereotypeInfo?: string
    }
    relationTypeInfo?: string
}
export interface Predicate {
    classRelationInfo?: ClassRelationTypeInfo
    dataType?: {
        name: string
    }
    [PROPS: string]: any
}

export interface Column {
    id: string
    predicateId: string
    path: string[]
    label: string
    labelInfo: LabelInfo
    format: string
    predicate?: Predicate
}

export type TextInputValue = string | { $value: string, $transform: any }

export interface SubjectCardInfo {
    contextPath: string
    isSuperUser: boolean
    data: SubjectData
}

export interface CheckVariant {
    label: string|I18NString
    value: boolean | {}
    key: CheckBoxKeys
}

export interface SubjectData {
    $id: number
    $classId: number
    $namespace?: string
    $class: string
    $rdfId: string
    $label: string
    $description: string
    [OBJECT: string]: any
}

export interface FragmentDataRaw {
    $rdfId: string
    $label: string
    $description: string
}
export interface RawLink {
    $rdfId: string
    $namespace: string | null
    $label?: string
    $description?: string
    $classId?: number
    $class?: string
    $fragment?: FragmentDataRaw
}
export interface Link {
    rdfId: string
    namespace: string | null
    label: string
    description: string
    classId: string
    className: string
    fragmentRdfId: string
    fragmentLabel: string
    fragmentDescription: string
}

export interface UploadTask {
    nodeId: string
    key: string
    path: string[]
    file: string
}

export interface FileValue {
    $contentSize: number
    $contentType: string
    $tmpName: string
    $uploadKey?: string
    $sha1?: string
    $description: string
    $label: string
    $originalName?: string
    [OBJECT: string]: any
}

export interface SubjectTableValue {
    $isNew?: boolean
    $tmpKey?: string
    $class: string
    $classId: number
    $description: string
    $id: number
    $label: string
    $rdfId?: string
    [OBJECT: string]: any
}

export interface SubjectComment {
    text: string
    color?: string
    isHtml?: boolean
    hideOnLock?: boolean
    hideOnEdit?: boolean
    hideOnCardLock?: boolean
    hideOnCardEdit?: boolean
}


////////////
// Automation //
////////////

export interface NormalizedAutomation {
    optionsBindings: { [PREDICATE_ID: string]: string },
    valueBindings: { [PREDICATE_ID: string]: string },
    enumerationBindings: { [PREDICATE_ID: string]: string },
    clickBindings: { [PREDICATE_ID: string]: string },
    validationBindings: { [PREDICATE_ID: string]: string },
    lockBindings: { [PREDICATE_ID: string]: string },
    visibilityBindings: { [PREDICATE_ID: string]: string },
    fillBindings: { [PREDICATE_ID: string]: string },
    linkBindings: { [PREDICATE_ID: string]: string },
    commonLockChecks: any[] //Lock checks not specific for the predicate
    commentBindings: { [PREDICATE_ID: string]: string }
};

////////////
// Layout //
////////////
export type UiType = constants.UI_PANEL |
    constants.UI_GRID |
    constants.UI_GRID_CELL |
    constants.UI_TEXT |
    constants.UI_FLOAT |
    constants.UI_INT |
    constants.UI_DATE |
    constants.UI_DATE_TIME |
    constants.UI_CHECKBOX |
    constants.UI_FRAGMENT |
    constants.UI_FILE |
    constants.UI_LABEL |
    constants.UI_DESCRIPTION |
    constants.UI_COMBOBOX |
    constants.UI_REF_TABLE |
    constants.UI_OBJECT_TABLE |
    constants.UI_TAB_NAV |
    constants.UI_TAB |
    constants.UI_IMAGE |
    constants.UI_CARD |
    constants.UI_RPA_SETPOINTS |
    constants.UI_IMAGE |
    constants.UI_BUTTON |
    constants.UI_LINK |
    constants.UI_SPLITTER_PANEL  |
    constants.UI_SPLITTER_CONTAINER  |
    constants.UI_SPLITTER_ELEMENT|
    constants.UI_PLUGIN | 
    constants.UI_VIEW;
    
    // constants.PREDICATE_PLACEHOLDER |
    // constants.SELF_PLACEHOLDER |
    // constants.PARENT_PLACEHOLDER | 
    // constants.CHILD_PLACEHOLDER



export type SaveStateType = constants.SAVE_STATE_START |
    constants.SAVE_STATE_UPLOADS_READY |
    constants.SAVE_STATE_SHOW_ERRORS |
    constants.SAVE_STATE_WAIT_SERVER


export type OperationType = constants.SUBJECT_OPERATION_CREATE |
    constants.SUBJECT_OPERATION_GET |
    constants.SUBJECT_OPERATION_INIT |
    constants.SUBJECT_OPERATION_LOCK |
    constants.SUBJECT_OPERATION_SAVE |
    constants.SUBJECT_OPERATION_UNLOCK;

export interface LayoutNode {
    id: string
    ui?: UiType
    label?: string
    multiple?: boolean
    mandatory?: boolean
    hidden?: boolean
    options?: any
    leaf?: boolean
    description?:string
    ref?:string
    layoutInColumns?:number
    content?:any
    src?:string
}

export interface LayoutToolButton {
    id: string
    label: string
    acl?: string
    group?: LayoutToolButton[]
}

export interface LayoutToolbar {
    buttons: LayoutToolButton[]
}

export interface Layout {
    //Mark layout as not ready
    toolbar?: LayoutToolbar
    automation?: NormalizedAutomation
    //Layout root nodes
    rootNodesIds: string[]
    //Layout nodes with predicates
    predicateNodesIds: string[]
    //Node by id
    nodeById: { [K: string]: LayoutNode }
    //Layout children nodes
    childrenNodesById: { [K: string]: string[] }
    //Parent node
    parentNodeById: { [K: string]: string }
    //Map of enumerations used in layout
    enumerationsMap: { [K: string]: boolean }
    ///
    mandatorySet: { [ID: string]: boolean }
    tabsIds:string[]
    rootId: string | null,
    override?:boolean|null

    preSave?:{[ID:string]:any}

    predicatePlaceHolderIds?:string[]
    nonPredicatePlaceholderIds?:string[]
    predicateUrlMap?:{[URL:string]:any}
    layoutInColumns?:any
    childPlaceHolderId?:string|null
    parentPlaceHolderId?:string|null
    selfPlaceHolderId?:string|null
    counter?:number
    // className:string
}

//Object for automation bindings
export interface AutomationForm {
    obtain: (path: string) => any
    openModal?: (type: string,
        options: ModalOptions,
        okCallback: OkCallback,
        cancelCallback: CancelCallback,
        closeCallback: CloseCallback) => void
    reload?: () => void
    change?: (predicate: string, value: any) => void
}

///////////////////////////////////////////
// Subject information including layout  //
///////////////////////////////////////////
export interface Subject extends Layout {

    //Subject data that was received from server
    subjectData: SubjectData
    //Class name used for layout
    className: string
    //Predicate values
    values: { [K: string]: any }
    //Layout nodes that are visible in object card
    visibility: { [ID: string]: boolean }
    //Layout nodes that are requested to be visible by automation
    visibilityDemand: { [ID: string]: boolean }
    // fragmentList: FragmentData[]
    //Layout nodes that are locked (not editable)
    lock: { [ID: string]: boolean }
    //Layout nodes that are requested to be locked by automation
    lockDemand: { [ID: string]: boolean }
    //Layout nodes that are requested to be validated by automation
    validation: { [ID: string]: any }
    ignore: {

    }
    //Comments for layout nodes binded by automation
    comment: { [ID: string]: SubjectComment }
    //////
    isNew?: boolean
    notifyId?:string
    componentUpdated?: { [NODE_ID: string]: boolean }
}

//////////////////
// Enumeration  //
//////////////////
export interface EnumerationClass {
    //Data of enumeration
    enumerationInfo: EnumerationInfo | null
    //Loading flag
    loading: boolean
    //Error flag
    error: boolean
}

export interface EnumerationInfo {
    rootLevel: boolean,
    children: EnumerationNode[]
}

export interface EnumerationNode {
    lastLevel?: boolean,
    children?: EnumerationNode[],
    data?: { [K: string]: any }
}
export interface SubjectDiff {
    subjectKey: string
    values: { [NODE_ID: string]: any }
    validation: { [NODE_ID: string]: any } | null
    visibility: { [NODE_ID: string]: boolean } | null
    lock: { [NODE_ID: string]: boolean } | null
}
export interface Value {
    [NODE_ID: string]: any
}
export interface Validation {
    [NODE_ID: string]: any
}
export interface Visibility {
    [NODE_ID: string]: boolean
}
export interface Lock {
    [NODE_ID: string]: boolean
}


export interface DiffState {
    values: Value
    validation: Validation
    visibilityDemand: Visibility
    lockDemand: Lock
}
export interface LayoutStatus {
    [SUBJECT_CLASS: string]: constants.STATUS_READY | constants.STATUS_LOADING
}

///////////
//Actions//
///////////
export interface SendSubject extends Action {
    type: constants.SEND_SUBJECT;
    payload: {
        subjectKey: string
        subject: Subject
        operation: any
        notifyId: any
        diff?: SubjectDiff
    }
}
export interface SendSubjectChangeData extends Action<constants.SEND_SUBJECT_CHANGE_DATA> {
    payload: { data: SubjectDiff, nodeId: string }
}
export interface SendSubjectStartSave extends Action<constants.SEND_SUBJECT_START_SAVE> {
    payload: string
}
export interface SendSubjectChangeSaveState extends Action<constants.SEND_SUBJECT_CHANGE_SAVE_STATE> {
    payload: {
        subjectKey: string,
        saveState: SaveStateType
    }
}
export interface SendSubjectSaveWait extends Action<constants.SEND_SUBJECT_SAVE_WAIT> {
    payload: string
}
export interface SendSubjectSaveDone extends Action<constants.SEND_SUBJECT_SAVE_DONE> {
    payload: string
}
export interface SendSubjectChangeLoading extends Action<constants.SEND_SUBJECT_CHANGE_LOADING> {
    payload: { subjectKey: string }
}
export interface SendSubjectSaveCancel extends Action<constants.SEND_SUBJECT_CANCEL_SAVE> {
    payload: string
}
export interface SendSubjectDestroy extends Action<constants.SEND_SUBJECT_DESTROY> {
    payload: string
}
export interface SendSubjectComponentUpdated extends Action<constants.SEND_SUBJECT_COMPONENT_UPDATED> {
    payload: {
        subjectKey: string
        nodeId: string
        updated: boolean
    }
}
// export interface SendSubject extends Action {
//     type: constants.SEND_SUBJECT;
//     payload: {
//         subjectKey: string
//         subject: Subject
//     }
// }



export interface SendSubjectError extends Action {
    type: constants.SEND_SUBJECT_ERROR;
    payload: {
        subjectKey: string
        error: FetchError
    }
}


export interface SendSubjectLoading extends Action {
    type: constants.SEND_SUBJECT_LOADING;
    payload: {
        subjectKey: string
        loading: boolean
    }
}
export interface SendSubjectLockLoading extends Action {
    type: constants.SEND_SUBJECT_LOCK_LOADING;
    payload: {
        subjectKey: string
        loading: boolean
    }
}

export interface SendLayoutLoading extends Action {
    type: constants.SEND_LAYOUT_LOADING;
    payload: {
        subjectClass: string
        loading: boolean
    }
}

export interface SendCachedLayout extends Action {
    type: constants.SEND_CACHED_LAYOUT;
    payload: {
        className: string
        layout: Layout
        storeDiffList?: SubjectDiff[],
        subjectKey: string
    }
}
// export interface SendCachedLayout extends Action {
//     type: constants.SEND_CACHED_LAYOUT;
//     payload: {
//         className: string
//         layout: Layout
//     }
// }


export interface SendEnumeration extends Action {
    type: constants.SEND_ENUMERATION;
    payload: {
        className: string
        data: EnumerationInfo
    }
}

export interface SendEnumerationError extends Action {
    type: constants.SEND_ENUMERATION_ERROR;
    payload: {
        className: string
        error: any
    }
}

export interface SendEnumerationLoading extends Action {
    type: constants.SEND_ENUMERATION_LOADING;
    payload: {
        className: string
    }
}
export interface SendSubjectChangeTab extends Action {
    type: constants.CHANGE_TAB;
    payload: {
        subjectKey: string, navId: string, tabId: string
    }
}
 
export interface SendRemoveSubjectFromStore extends Action {
    type: constants.SEND_SUBJECT_REMOVE_FROM_STORE;
    payload: string
}
export interface SendEditingSubject extends Action {
    type: constants.SEND_SUBJECT_EDITING;
    payload: {rdfId:string,isEditing:boolean}
}
export interface SendNewSubject extends Action {
    type: constants.SEND_SUBJECT_NEW;
    payload: {rdfId:string,isNew:boolean} 
}
export interface SendFragmentTreeBranch extends Action<constants.SEND_FRAGMENT_TREE> {
    payload: {
        l:FragmentData[],
        r?:string
    }
}

export interface SendFragmentError extends Action<constants.SEND_FRAGMENT_TREE_ERROR> {
    
    payload: {
        id?:string
        error:FetchError
    }
}

export interface SendFragmentLoading extends Action<constants.SEND_FRAGMENT_TREE_LOADING> {
       payload:{ 
           loading:boolean
       }
} 

 
export interface SendFragmentSelected extends Action<constants.SEND_FRAGMENT_TREE_SELECTED> {
    payload: {
        id?:string
    }
}
 



export type SubjectAction = SendSubject |
    SendSubjectError |
    SendSubjectLoading |
    SendCachedLayout |
    SendEnumeration |
    SendEnumerationError |
    SendEnumerationLoading |
    SendLayoutLoading |
    SendSubjectChangeData |
    SendSubjectStartSave |
    SendSubjectChangeSaveState |
    SendSubjectSaveWait |
    SendSubjectSaveCancel |
    SendSubjectChangeLoading |
    SendSubjectDestroy |
    SendSubjectSaveDone |
    SendSubjectComponentUpdated |
    SendSubjectChangeTab | 
    SendSubjectLockLoading|
    SendRemoveSubjectFromStore|
    SendEditingSubject|
    SendNewSubject| 
    SendFragmentTreeBranch|
    SendFragmentError|
    SendFragmentLoading|
    SendFragmentSelected;
 
enum ModalSize{
    SMALL='small',
    MEDIUM='medium',
    LARGE='large',
    FULLSCREEN='fullscreen'
}
interface ModalSizeType{
    value:ModalSize
}
export interface TreeHeader{
    filter?:TreeHeaderFilter
    modalSize?:ModalSizeType
}

 

export interface FragmentTreeState extends FragmentTreeData{
    selected?:string
    treeLoading?:boolean
    error?:FetchError
    loading?:{[ID:string]:boolean}
}

/////////
//State//
/////////
export type ActiveTabs = { [NAV_ID: string]: { active: string } }
export interface SubjectState {
    //Layout cache  by class name
    layouts: { [CLASSNAME: string]: Layout }
    //Subject stores by (prefix && rdfId) or rdfId or autogenerated unique id
    subjects: { [SUBJECT_KEY: string]: Subject | FetchError }
    fragmentTree?: FragmentTreeState     
    saveState: { [SUBJECT_KEY: string]: SaveStateType }
    //Enumeration stores by class name
    enumerations: { [CLASSNAME: string]: EnumerationClass }
    //Subject loading indicator
    loading: { [SUBJECT_KEY: string]: boolean }
    loadingLock: { [SUBJECT_KEY: string]: boolean }
    layoutsStatus: LayoutStatus
    layoutsLoading: { [SUBJECT_CLASS: string]: boolean }
    changeLoading: { [SUBJECT_CLASS: string]: boolean }
    tabs?: { [SUBJECT_KEY: string]: ActiveTabs }
    editingSubjects?:{[SUBJECT_KEY:string]:boolean}
    newSubjects?:{[SUBJECT_KEY:string]:boolean}
}

export const getSubjectKey = (rdfId: string, prefix?: string) => prefix ? prefix + ":" + rdfId : rdfId;

export function isSubject(x: Subject | FetchError | undefined): x is Subject {
    return typeof x != 'undefined' && typeof (x as Subject).className != 'undefined';
}
 
export function isFetchError(x: FetchError | TreeNode | undefined): x is FetchError {
    return typeof x != 'undefined' && typeof (x as FetchError).code !== 'undefined';
}