import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DomainInfo } from '../../types/security';

export interface DomainListGroupProps {
    domains: DomainInfo[];
    selectRef: React.MutableRefObject<HTMLSelectElement | null>,
    onChange: (domain: string) => void
}

const DomainListGroup = (props: DomainListGroupProps) =>
    <div className="form-group">
        <label htmlFor="domainSelect">
            <FormattedMessage
                id="LOGIN_PANEL_DOMAIN_LIST_LABEL"
                defaultMessage="Domains:"
                description="Label for domains select" />
        </label>
        <select id="domainSelect" className="form-control" onChange={(evt) => props.onChange(evt.target.value)}
            ref={props.selectRef}>
            <option value="">--</option>
            {props.domains.map((domain) => {
                return (<option key={domain.name} value={domain.name}>{domain.name}</option>)
            })}
        </select>
    </div>;

export default DomainListGroup;