import * as React from 'react';
import { connect } from 'react-redux';
import { ModalInfo, ModalStatus } from '../../types/modal';
import { ApplicationState } from '../../types';
import { closeModal } from '../../actions/modal';

import ModalApi from './ModalApi';
import { ThunkDispatch } from 'redux-thunk';

export interface ModalStackProps {
  modalList: ModalInfo[]
  closeModal: (id: string, status: ModalStatus, result: any, extraData?: any) => void
}

class ModalStack extends React.Component<ModalStackProps> {

  constructor(props: ModalStackProps) {
    super(props);
  }

  render() {
    const len = this.props.modalList.length;
    if (len == 0) {
      return null;
    }
    const modals = [];
    for (let modal of this.props.modalList) {
      modals.push(<ModalApi key={modal.id} modal={modal} closeModal={this.props.closeModal} />);
    }
    return <>
      {modals}
    </>
  }

}

export default connect(
  (state: ApplicationState) => {
    return {
      modalList: state.modal.modalList
    }
  },
  (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
      closeModal: (id: string, status: ModalStatus, result: any, extraData?: any) => dispatch(closeModal(id, status, result, extraData))
    }
  })(ModalStack)