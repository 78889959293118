import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  EqualityValue,
  FilterData,
  NumberFilterData,
} from "../../../types/table";

import DebounceInput from "../../debounce/debounceinput";
import { FilterTab, FilterTabContent, FilterTabs } from "./FilterTabs";
import EqualitySelector from "./EqualitySelection";
import EmptyCellsFilter from "./EmptyCellsFilter";

import styles from "../Table.module.css";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NumberFilterProps {
  loading: boolean;
  error: boolean;
  filterData: NumberFilterData | null;
  range: { min: any; max: any } | null;
  onChange: (filterData: NumberFilterData) => void;
  fetchColumnRange: (filterData: FilterData | null) => void;
}
const NumberFilter: React.FunctionComponent<NumberFilterProps> = React.memo(
  (props) => {
    const propsOperation = props.filterData
      ? props.filterData.operation
      : "equal";
    const propsValue = props.filterData ? props.filterData.value : null;
    const propsValueFrom = props.filterData ? props.filterData.valueFrom : null;
    const propsValueTo = props.filterData ? props.filterData.valueTo : null;
    const propsAllowNulls = props.filterData
      ? props.filterData.allowNulls
      : false;

    const [activeTab, setActiveTab] = React.useState(
      propsOperation === "between" ? "range" : "single"
    );
    const [operation, setOperation] = React.useState<EqualityValue>(
      propsOperation === "between" ? "equal" : propsOperation
    );
    const [value, setValue] = React.useState(propsValue);
    const [valueFrom, setValueFrom] = React.useState(propsValueFrom);
    const [valueTo, setValueTo] = React.useState(propsValueTo);
    const [allowNulls, setAllowNulls] =
      React.useState<boolean>(propsAllowNulls);

    let displayValueFrom = valueFrom;
    let displayValueTo = valueTo;
    if (displayValueFrom === null && props.range) {
      displayValueFrom = props.range.min;
    }
    if (displayValueTo === null && props.range) {
      displayValueTo = props.range.max;
    }

    const changeValue = (value: number | {}) => {
      if (typeof value !== "number") {
        setValue(null);
        return;
      }
      setValue(value);
    };

    const changeValueFrom = (value: number | {}) => {
      if (typeof value !== "number") {
        setValueFrom(null);
        return;
      }
      setValueFrom(value);
    };

    const changeValueTo = (value: number | {}) => {
      if (typeof value !== "number") {
        setValueTo(null);
        return;
      }
      setValueTo(value);
    };

    React.useEffect(() => {
      props.onChange({
        type: "number",
        operation: activeTab == "range" ? "between" : operation,
        value,
        valueFrom: displayValueFrom,
        valueTo: displayValueTo,
        allowNulls,
      });
    }, [
      activeTab,
      operation,
      value,
      valueFrom,
      valueTo,
      allowNulls,
      props.range,
    ]);
    React.useEffect(() => {
      if (activeTab !== "range") {
        return;
      }
      props.fetchColumnRange(null);
    }, [activeTab]);

    return (
      <div className={`w-100 d-flex flex-column`}>
        <FilterTabs>
          <FilterTab
            tabName="single"
            activeTab={activeTab}
            changeTab={setActiveTab}
          >
            <FormattedMessage
              id="NPT_TABLE_FILTER_VALUE"
              defaultMessage="Value"
              description="Single value"
            />
          </FilterTab>
          <FilterTab
            tabName="range"
            activeTab={activeTab}
            changeTab={setActiveTab}
          >
            <FormattedMessage
              id="NPT_TABLE_FILTER_RANGE"
              defaultMessage="Range"
              description="Range value"
            />
          </FilterTab>
        </FilterTabs>
        <FilterTabContent tabName="single" activeTab={activeTab}>
          <DebounceInput
            className={"form-control"}
            editable={true}
            focus={true}
            size="md"
            // valid={valid}
            value={value}
            // locale={locale}
            // options={node.options}
            format={"float"}
            change={changeValue}
            withNull={true}
            // signalInvalidFormat={this.signalInvalidFormat}
          >
            <div className="input-group-prepend">
              <span className="input-group-text p-0">
                <EqualitySelector value={operation} onChange={setOperation} />
              </span>
            </div>
          </DebounceInput>
        </FilterTabContent>
        <FilterTabContent tabName="range" activeTab={activeTab}>
          <div className="d-flex flex-column">
            {props.loading && <Loading />}
            <div className="w-100">
              <DebounceInput
                className={"form-control"}
                editable={valueFrom != null || !props.loading}
                focus={true}
                // valid={valid}
                value={displayValueFrom}
                // locale={locale}
                // options={node.options}
                format={"float"}
                change={changeValueFrom}
                withNull={true}
                // signalInvalidFormat={this.signalInvalidFormat}
              />
              <span className="d-flex align-items-center px-2">-</span>
              <DebounceInput
                className={"form-control"}
                editable={valueTo != null || !props.loading}
                // valid={valid}
                value={displayValueTo}
                // locale={locale}
                // options={node.options}
                format={"float"}
                change={changeValueTo}
                withNull={true}
                // signalInvalidFormat={this.signalInvalidFormat}
              />
            </div>
            {props.error && <ErrorPlaceholder />}
          </div>
        </FilterTabContent>
        <EmptyCellsFilter value={allowNulls} onChange={setAllowNulls} />
      </div>
    );
  }
);
const Loading = () => {
  return (
    <div className="alert alert-info p-1">
      <FontAwesomeIcon className="mr-1" icon={faSpinner} />
      <FormattedMessage id="NPT_TABLE_LOADING" />
    </div>
  );
};
const ErrorPlaceholder: React.FunctionComponent = React.memo((props) => (
  <div className="alert alert-danger my-2">
    <FormattedMessage
      id="NPT_TABLE_FILTER_LOADING_ERROR"
      defaultMessage="Error occured during data fetch"
      description="Fetch error placeholder"
    />
  </div>
));

export default NumberFilter;
