import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { isI18NString, ModalInfo, ModalStatus } from '../../../types/modal';
import ModalView, { ModalViewOptions } from '../ModalView';

interface AlertModalProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void
}

export default class AlertModal extends React.Component<AlertModalProps> {

    constructor(props: AlertModalProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }
    renderTemplate(): React.ReactElement {
        const { body } = this.props.modal.options;

        let message = <></>;
        if (isI18NString(body)) {
            message = <FormattedMessage id={body.id} values={body.values} defaultMessage="" />;
        } else if (typeof body === "string") {
            message = <>{body}</>;
        }

        return <div>{message}</div>;
    }


    closeModal(status: ModalStatus, result: any) {
        this.props.closeModal(status, null);
    }

    render() {
        const modal = { ...this.props.modal };
        modal.options = { ...modal.options, title: modal.options.title || { id: "ALERT_MODAL_TITLE" }, hideCloseButton: true } as ModalViewOptions;
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}