import React from "react";
import { MODAL_STATUS_OK } from "../../../constants/modal";
import { ModalInfo, ModalStatus } from "../../../types/modal";
import ModalView from "../ModalView";
import Plugin from '../../plugin/Plugin';
interface PluginProps {
    modal: ModalInfo,
    closeModal: (status: ModalStatus, result: any) => void
}

export default class PluginModal extends React.Component<PluginProps> {

    constructor(props: PluginProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }
    renderTemplate(): React.ReactElement {
        const { modal} = this.props;
        if(!modal){
            return <></>
        }
        const {options:o} = modal    
        if(!o ){
            return <></>
        }
        const plugin = (o as any).plugin
        const view = (o as any).view
        const options = (o as any).dataOptions 
        return <Plugin key={`${plugin}/${view}`} plugin={`${plugin}/${view}`} options={options} />
    }


    closeModal(status: ModalStatus, result: any) {
        this.props.closeModal(status, true);
    }

    render() {
        
        const modal = { ...this.props.modal };
        modal.options = { ...modal.options, title: modal.options.title || { id: "Title" } };
       
        return <ModalView modal={modal} template={this.renderTemplate()} closeModal={this.closeModal} />;
    }
}