import * as React from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import { isI18NString, ModalInfo, ModalStatus } from "../../../types/modal";
import ModalView from "../ModalView";

export interface TextInputModalProps {
  modal: ModalInfo;
  closeModal: (status: ModalStatus, result: any) => void;
}

export interface TextInputModalState {
  value: string;
}

export default class TextInputModal extends React.Component<
  TextInputModalProps,
  TextInputModalState
> {
  state = {
    value: "",
  };

  constructor(props: TextInputModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  changeInputValue(key: string, event: any) {
    const newValue = event.target.value;
    this.setState({ ...this.state, [key]: newValue });
  }

  renderTemplate() {
    const b = this.props.modal.options.body;
    let label: any = "";
    if (isI18NString(b)) {
      label = <FormattedMessage id={b.id} values={b.values} />;
    } else {
      label = b;
    }
    return (
      <Form>
        <Form.Group controlId="value">
          <Form.Label>{label}</Form.Label>
          <Form.Control
            type="input"
            required
            value={this.state.value}
            onChange={this.changeInputValue.bind(this, "value")}
          />
        </Form.Group>
      </Form>
    );
  }

  closeModal(status: ModalStatus, result: any) {
    if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, null);
    }
    if (status == MODAL_STATUS_OK) {
      this.props.closeModal(status, this.state.value);
      return;
    }
  }

  render() {
    return (
      <ModalView
        modal={this.props.modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}
