export const NODE_DND_ID = "cimTreeNode";

//Url constants
export const TREE_FETCH_URL = "/rest/tree/fetch";
export const TREE_FETCH_HEADER_URL = "/rest/tree/header"; 
export const TREE_ADD_URL = "/rest/tree/new";
export const TREE_DECENDANTS_REFS_URL = "/rest/tree/refs";
export const TREE_MOVE_URL = "/rest/tree/move";
export const TREE_PATH_URL = "/rest/tree/path";
export const TREE_SEARCH_URL = "/rest/tree/search";
export const TREE_OPTIONS_URL = "/rest/tree/options";


//Actions
export const SEND_TREE_LOADING = 'SEND_TREE_LOADING';
export type SEND_TREE_LOADING = typeof SEND_TREE_LOADING;

export const SEND_TREE_HEADER = 'SEND_TREE_HEADER';
export type SEND_TREE_HEADER = typeof SEND_TREE_HEADER;

export const SEND_TREE_NODES = 'SEND_TREE_NODES';
export type SEND_TREE_NODES = typeof SEND_TREE_NODES;

export const SEND_TREE_NODE_TOGGLE = 'SEND_TREE_NODE_TOGGLE';
export type SEND_TREE_NODE_TOGGLE = typeof SEND_TREE_NODE_TOGGLE;

export const SEND_TREE_NODE_ACTIVE = 'SEND_TREE_NODE_ACTIVE';
export type SEND_TREE_NODE_ACTIVE = typeof SEND_TREE_NODE_ACTIVE;

export const SEND_TREE_NODE_CTX_MENU_INFO = 'SEND_TREE_NODE_CTX_MENU_INFO';
export type SEND_TREE_NODE_CTX_MENU_INFO = typeof SEND_TREE_NODE_CTX_MENU_INFO;

export const SEND_EXPAND_TREE_WITH_EDITING_SUBJECTS = 'SEND_EXPAND_TREE_WITH_EDITING_SUBJECTS';
export type  SEND_EXPAND_TREE_WITH_EDITING_SUBJECTS = typeof SEND_EXPAND_TREE_WITH_EDITING_SUBJECTS;

export const SEND_TREE_COMPRESS = "SEND_SUBJECT_TREE_COMPRESS";
export type SEND_TREE_COMPRESS = typeof SEND_TREE_COMPRESS;

export const SEND_TREE_NODES_FORCE_UPDATE = "SEND_TREE_NODES_FORCE_UPDATE";
export type SEND_TREE_NODES_FORCE_UPDATE = typeof SEND_TREE_NODES_FORCE_UPDATE;

export const SEND_TREE_ACTIVE_NODE_VALID = "SEND_SUBJECT_TREE_ACTIVE_NODE_VALID";
export type SEND_TREE_ACTIVE_NODE_VALID = typeof SEND_TREE_ACTIVE_NODE_VALID;
 
export const SEND_ROLE_NODE_CTX_MENU_INFO = "SEND_ROLE_NODE_CTX_MENU_INFO";
export type SEND_ROLE_NODE_CTX_MENU_INFO = typeof SEND_ROLE_NODE_CTX_MENU_INFO;
 
export const SEND_TREE_FILTER_CHANGE = "SEND_TREE_FILTER_CHANGE";
export type SEND_TREE_FILTER_CHANGE = typeof SEND_TREE_FILTER_CHANGE;

export enum TreeEvent{
    EXPAND_NODE='expandTreeNodeEvent'
}

