import { Reducer } from 'redux'

import {
    SourceEditorState,
    SourceEditorType,
    isSourceEditorAction,
    SourceEditorTreeData,
    SourceTreeNode,
    isSourceEditorCodeData,
    SourceEditorCodeData,
    Template,
    TemplateTree,
} from '../types/sourceeditor'
import * as constants from '../constants/sourceeditor'
import { ApplicationAction } from '../types'
import { sortSourceTreeNodes } from '../services/tree'

export function parseTreeList(list: string[]): SourceEditorTreeData {
    const nodes: SourceTreeNode[] = []
    const nodeById: { [ID: string]: SourceTreeNode } = {}
    const children: { [ID: string]: string[] } = {}
    for (let s of list) {
        //Remove starting end terminating separator
        const path = s.trim().replace(/\/+$/, '').replace(/^\/+/, '').split('/')
        let id = ''
        let parentId: string | null = null
        for (var i = 0; i < path.length; ++i) {
            let name = path[i]
            id += '/' + name
            if (id === '/') {
                continue
            }
            if (!nodeById[id]) {
                const last = i == path.length - 1
                const n: SourceTreeNode = {
                    id,
                    name,
                    path: last ? id : null,
                    directory: false,
                    parentId,
                }
                nodeById[id] = n
                nodes.push(n)
                if (parentId && nodeById[parentId]) {
                    const parent = nodeById[parentId]
                    parent.directory = true
                    if (children[parentId]) {
                        children[parentId].push(id)
                    } else {
                        children[parentId] = []
                        children[parentId].push(id)
                    }
                }
            }
            if (parentId && children[parentId]) {
                children[parentId] = sortSourceTreeNodes(
                    children[parentId],
                    nodeById
                )
            }
            parentId = id
        }
    }
    const rootNodesIds = nodes.filter((n) => !n.parentId).map((n) => n.id)
    return {
        rootNodesIds: sortSourceTreeNodes(rootNodesIds, nodeById),
        childrenIds: children,
        nodeById,
    }
}

export function generateNode(id: string, directory: boolean): SourceTreeNode {
    const path = id.trim().replace(/\/+$/, '').replace(/^\/+/, '').split('/')
    const len = path.length
    const name = path[len - 1]
    const parentId = len == 1 ? null : '/' + path.slice(0, len - 1).join('/')
    return {
        id: '/' + path.join('/'),
        name,
        directory: directory,
        path: directory ? null : '/' + path.join('/'),
        parentId,
    }
}

export function generateNodeCode(
    id: string,
    templateTree: TemplateTree,
    indent: string
): string {
    const n = templateTree.nodeById[id]
    if (!n) {
        return ''
    }
    const placeholder = n.placeholder
    if (!placeholder) {
        return n.code
    }

    const children = templateTree.childrenIds[id]
    if (children) {
        return generateNodeListCode(
            n.code,
            placeholder,
            children,
            templateTree,
            indent + '\t'
        )
    }
    return n.code.replace(placeholder, '')
}

export function generateNodeListCode(
    code: string,
    placeholder: string,
    list: string[],
    templateTree: TemplateTree,
    indent: string
) {
    const idx = code.indexOf(placeholder)
    if (idx < 0) {
        return code
    }
    let innerText = '\n'
    for (let child of list) {
        innerText +=
            indent + '\t' + generateNodeCode(child, templateTree, indent) + '\n'
    }
    const part1 = code.substring(0, idx)
    const part2 = code.substring(idx + placeholder.length)
    return part1 + innerText + indent + part2
}

export function generateTemplateTreeCode(templateTree: TemplateTree): string {
    if (templateTree.placeholder) {
        return generateNodeListCode(
            templateTree.code,
            templateTree.placeholder,
            templateTree.rootNodesIds,
            templateTree,
            ''
        )
    }
    return templateTree.code
}

export function expandParents(
    id: string,
    nodeById: { [ID: string]: SourceTreeNode },
    expanded: { [ID: string]: boolean }
) {
    const n = nodeById[id]
    if (!n) {
        return
    }
    if (n.parentId) {
        expanded[n.parentId] = true
        expandParents(n.parentId, nodeById, expanded)
    }
}

export function createRecursiveNode(
    state: SourceEditorState,
    id: string,
    directory: boolean
): SourceEditorState {
    const n: SourceTreeNode = generateNode(id, directory)
    //Create root directory
    if (n.parentId == null) {
        const nodeById = state.nodeById ? { ...state.nodeById } : {}
        nodeById[n.id] = n
        const rootNodesIds = Array.isArray(state.rootNodesIds)
            ? [...state.rootNodesIds]
            : []
        rootNodesIds.push(n.id)
        rootNodesIds.sort()
        return { ...state, nodeById, rootNodesIds }
    }
    //Check if parent exists
    const parent = state.nodeById && state.nodeById[n.parentId]
    if (!parent) {
        //Update state with parent created
        state = createRecursiveNode(state, n.parentId, true)
    }
    const nodeById = state.nodeById ? { ...state.nodeById } : {}
    nodeById[n.id] = n
    const childrenIds = state.childrenIds ? { ...state.childrenIds } : {}
    const children = childrenIds[n.parentId] ? [...childrenIds[n.parentId]] : []
    childrenIds[n.parentId] = children
    children.push(n.id)
    children.sort()
    return { ...state, nodeById, childrenIds }
}

/**
 * View and Script editor have common logic,
 * so we use high order reducer for both
 *
 * Read more here
 *
 * https://alligator.io/redux/higher-order-reducers/
 */

const reducer = (
    sourceEditorType: SourceEditorType
): Reducer<SourceEditorState, ApplicationAction> => {
    return (
        state: SourceEditorState = {},
        action: ApplicationAction
    ): SourceEditorState => {
        if (!isSourceEditorAction(action)) {
            return state
        }
        if (action.sourceEditorType != sourceEditorType) {
            return state
        }
        switch (action.type) {
            case constants.SEND_SOURCE_TREE:
                return {
                    ...state,
                    ...parseTreeList(action.payload.list),
                    treeLoading: false,
                }
            case constants.SEND_SOURCE_TREE_ERROR:
                return {
                    ...state,
                    rootNodesIds: action.payload.error,
                    treeLoading: false,
                }
            case constants.SEND_SOURCE_TREE_LOADING:
                return { ...state, treeLoading: true }
            case constants.SEND_SOURCE_TREE_TOGGLE:
                const expanded = state.expanded ? { ...state.expanded } : {}
                expanded[action.payload.nodeId] = action.payload.expanded
                return { ...state, expanded }
            case constants.SEND_SOURCE_TREE_ACTIVE: {
                const nodeById = state.nodeById || {}
                const expanded = state.expanded ? { ...state.expanded } : {}
                expandParents(action.payload.nodeId, nodeById, expanded)
                return { ...state, active: action.payload.nodeId, expanded }
            }
            case constants.SEND_SOURCE_TREE_SEARCH_FILTER_LOADING: {
                return { ...state, filterLoading: true }
            }
            case constants.SEND_SOURCE_TREE_SEARCH_FILTER: {
                if (action.payload.filter == null) {
                    return {
                        ...state,
                        filterValue: undefined,
                        filter: undefined,
                        filterLoading: undefined,
                    }
                }
                const tree = parseTreeList(action.payload.filter)
                const filter: { [ID: string]: boolean } = {}
                for (let id of Object.getOwnPropertyNames(tree.nodeById)) {
                    filter[id] = true
                }
                return {
                    ...state,
                    filterValue: action.payload.filterValue,
                    filter,
                    filterLoading: undefined,
                }
            }
            case constants.SEND_SOURCE_CODE_TREE_TOGGLE_INPLACE: {
                if (action.payload.inplace) {
                    return { ...state, inplace: action.payload.nodeId }
                }
                return { ...state, inplace: undefined }
            }
            case constants.SEND_SOURCE_TREE_CREATE_DIRECTORY: {
                return createRecursiveNode(state, action.payload.path, true)
            }
            case constants.SEND_SOURCE_CODE: {
                const code = state.code ? { ...state.code } : {}
                const codeLoading = state.codeLoading
                    ? { ...state.codeLoading }
                    : {}
                let active = state.active
                let editCode = state.editCode
                //Code path have been changed (maybe after save)
                if (action.payload.path != action.payload.code.path) {
                    //Remove code on previous path
                    if (code[action.payload.path]) {
                        delete code[action.payload.path]
                    }
                    //Check if we need to update active path
                    if (active && active == action.payload.path) {
                        active = action.payload.code.path
                    }
                }
                //Insert code on new path
                code[action.payload.code.path] = action.payload.code
                //Remove loading flag
                if (codeLoading[action.payload.path]) {
                    delete codeLoading[action.payload.path]
                }
                if (editCode && editCode[action.payload.path]) {
                    editCode = { ...state.editCode }
                    delete editCode[action.payload.path]
                }
                return { ...state, code, editCode, active, codeLoading }
            }
            case constants.SEND_SOURCE_CODE_ERROR: {
                const code = state.code ? { ...state.code } : {}
                code[action.payload.path] = action.payload.error
                const codeLoading = state.codeLoading
                    ? { ...state.codeLoading }
                    : {}
                codeLoading[action.payload.path] = false
                return { ...state, code, codeLoading }
            }
            case constants.SEND_SOURCE_CODE_LOADING: {
                const loading =
                    typeof action.payload.loading == 'undefined'
                        ? true
                        : action.payload.loading
                const codeLoading = state.codeLoading
                    ? { ...state.codeLoading }
                    : {}
                codeLoading[action.payload.path] = loading
                return { ...state, codeLoading }
            }
            case constants.SEND_SOURCE_CODE_INIT_EDIT: {
                const path = action.payload.path
                const code = state.code && state.code[path]
                if (!isSourceEditorCodeData(code)) {
                    return state
                }
                const editCode = state.editCode ? { ...state.editCode } : {}
                editCode[path] = { ...code }
                return { ...state, editCode }
            }
            case constants.SEND_SOURCE_CODE_TEXT_CHANGES: {
                const path = action.payload.path
                if (!state.editCode) {
                    return state
                }
                const code = state.editCode[path]
                const changedCode = action.payload.code
                if (
                    !isSourceEditorCodeData(code) ||
                    code.code == action.payload.code
                ) {
                    return state
                }
                const editCode = { ...state.editCode }
                editCode[path] = { ...code, code: changedCode }
                return { ...state, editCode }
            }
            case constants.SEND_SOURCE_CODE_METADATA_CHANGES: {
                const path = action.payload.path
                if (!state.editCode) {
                    return state
                }
                const code = state.editCode[path]
                if (!isSourceEditorCodeData(code)) {
                    return state
                }
                const metadata = action.payload.metadata
                if (
                    code.mime == metadata.mime &&
                    code.path == metadata.path &&
                    code.description == metadata.description
                ) {
                    return state
                }
                const editCode = { ...state.editCode }
                editCode[path] = { ...code, ...metadata }
                return { ...state, editCode }
            }
            case constants.SEND_SOURCE_CODE_EDIT_CANCEL: {
                const path = action.payload.path
                if (state.editCode && state.editCode[path]) {
                    const editCode = { ...state.editCode }
                    delete editCode[path]
                    const code = state.code && state.code[path]
                    if (typeof code == 'undefined') {
                        //It is new source code and we need to remove node
                        const nodeById = state.nodeById && { ...state.nodeById }
                        const node = nodeById && nodeById[path]
                        const childrenIds = state.childrenIds && {
                            ...state.childrenIds,
                        }
                        const parentId = node && node.parentId
                        const children =
                            node &&
                            parentId &&
                            childrenIds &&
                            childrenIds[parentId]
                        if (
                            nodeById &&
                            node &&
                            parentId &&
                            childrenIds &&
                            children
                        ) {
                            childrenIds[parentId] = children.filter(
                                (id) => id != node.id
                            )
                            delete nodeById[node.id]
                            return { ...state, nodeById, childrenIds, editCode }
                        }
                    } else {
                        return { ...state, editCode }
                    }
                }
                return state
            }
            case constants.SEND_SOURCE_CODE_CANCEL_TEMPLATE: {
                return { ...state, template: undefined }
            }
            case constants.SEND_SOURCE_CODE_CHANGE_TEMPLATE: {
                const template = { ...action.payload.template }
                const templateTree = template.templateTree
                if (templateTree) {
                    template.code = generateTemplateTreeCode(templateTree)
                }
                return { ...state, template }
            }
            case constants.SEND_SOURCE_CODE_APPLY_TEMPLATE: {
                const template = state.template
                if (!template) {
                    return state
                }
                const path = action.payload.path
                state = createRecursiveNode(state, path, false)
                //Add only to edit code (without code)
                const editCode = state.editCode ? { ...state.editCode } : {}
                editCode[path] = {
                    type: template.type,
                    mime: template.mime,
                    code: template.code,
                    path,
                    description: template.description,
                }
                return { ...state, editCode, template: undefined }
            }
            case constants.SEND_SOURCE_CODE_CHANGE_TEMPLATE_NODE: {
                const template = state.template && { ...state.template }
                if (!template) {
                    return state
                }
                const templateTree = template.templateTree && {
                    ...template.templateTree,
                }
                if (!templateTree) {
                    return state
                }
                const nodeBydId = templateTree.nodeById && {
                    ...templateTree.nodeById,
                }
                nodeBydId[action.payload.node.id] = action.payload.node
                templateTree.nodeById = nodeBydId
                template.templateTree = templateTree
                template.code = generateTemplateTreeCode(templateTree)
                return { ...state, template }
            }
            case constants.SEND_SOURCE_CODE_TOGGLE_META_DATA: {
                const showMetaData = state.showMetaData ? false : true
                return { ...state, showMetaData }
            }
        }
        return state
    }
}

export const viewEditorReducer = reducer('vieweditor')
export const scriptEditor = reducer('scripteditor')
