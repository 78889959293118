export const FILEARCHIVE = 'filearchive'; 

export const SEND_FILES = 'SEND_FILES';
export type SEND_FILES = typeof SEND_FILES;

export const SEND_FILEARCHIVE_ERROR = 'SEND_FILEARCHIVE_ERROR';
export type SEND_FILEARCHIVE_ERROR = typeof SEND_FILEARCHIVE_ERROR;
 

export const SEND_FILEARCHIVE_LOADING = 'SEND_FILEARCHIVE_LOADING';
export type SEND_FILEARCHIVE_LOADING = typeof SEND_FILEARCHIVE_LOADING; 

export const SEND_LIMIT = 'SEND_LIMIT';
export type SEND_LIMIT = typeof SEND_LIMIT;

export const SEND_OFFSET = 'SEND_OFFSET';
export type SEND_OFFSET = typeof SEND_OFFSET;

export const SEND_ITEM_CHECKED = 'SEND_ITEM_CHECKED';
export type SEND_ITEM_CHECKED = typeof SEND_ITEM_CHECKED;

export const SEND_ALL_ITEM_CHECKED = 'SEND_ALL_ITEM_CHECKED';
export type SEND_ALL_ITEM_CHECKED = typeof SEND_ALL_ITEM_CHECKED;

export const SEND_START_EDIT = 'SEND_START_EDIT';
export type SEND_START_EDIT = typeof SEND_START_EDIT;

export const SEND_STOP_EDIT = 'SEND_STOP_EDIT';
export type SEND_STOP_EDIT = typeof SEND_STOP_EDIT;

export const SEND_SEARCH_INPUT_FILE = 'SEND_SEARCH_INPUT_FILE';
export type SEND_SEARCH_INPUT_FILE = typeof SEND_SEARCH_INPUT_FILE;