import React from "react";
import { AsyncClass, AsyncComponentProps, AsyncComponentState, AsyncModule, isAsyncModule } from "./AsyncComponent";

export default function asyncNonVisualComponent<P extends AsyncComponentProps>(importComponent: () => Promise<AsyncModule | AsyncClass>) {

    class AsyncComponent extends React.Component<P, AsyncComponentState> {

        constructor(props: P) {
            super(props);
            this.state = {
                asynComponentStateValue: null
            };
        }

        async componentDidMount() {
            let r = null;
            try {
                r = await importComponent();
                if (isAsyncModule(r)) {
                    this.setState({ asynComponentStateValue: r.default });
                } else {
                    this.setState({ asynComponentStateValue: r });
                }
            } catch (e) {
                this.setState({ asyncComponentStateError: e });
            }
        }

        render() {
            const { asynComponentStateValue: C } = this.state;
            return C != null ? <C {...this.props} /> : null
        }
    }
    return AsyncComponent;
}