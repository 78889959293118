import * as React from "react";
import { connect } from "react-redux";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import { ApplicationState } from "../../../types";
import { ModalStatus, TableModalInfo } from "../../../types/modal";
import { RowData } from "../../../types/table";
import AsyncTable from "../../table/AsyncTable";
import LocalTable, { LocalSimpleTableApi } from "../../table/LocalTable";

import TableContainer from "../../table/TableContainer";
import ModalView from "../ModalView";

interface CardTableModalProps {
  modal: TableModalInfo;
  rowByIdx: { [rowIdx: number]: RowData };
  closeModal: (status: ModalStatus, result: any, extraData?: any) => void;
}

interface CardTableModalStates {
  result: any;
}

class CardTableModal extends React.Component<
  CardTableModalProps,
  CardTableModalStates
> {
  title = { id: "CIMTABLE_MODAL_TITLE" };
  checkStack: any;
  validatedRows: any;

  constructor(props: CardTableModalProps) {
    super(props);
    this.state = { result: null };

    this.selectData = this.selectData.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  checkFinish(isValid: boolean, callback: (valid: boolean) => void) {
    this.checkStack.shift();
    callback(isValid);
    if (this.checkStack.length !== 0) {
      this.recursiveCheck(this.checkStack[0].data, this.checkStack[0].callback);
    }
  }

  recursiveCheck(data: any, callback: (valid: boolean) => void) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    const promises = [];
    for (let row of data) {
      const promise = new Promise((resolve, reject) => {
        if (typeof this.validatedRows[row.key] != "undefined") {
          if (this.validatedRows[row.key]) {
            resolve(true);
            return;
          }
          reject();
          return;
        }
        this.props.modal.options.checkFunction?.(row, (isValid: boolean) => {
          this.validatedRows[row.key] = isValid;
          if (isValid) {
            resolve(true);
            return;
          }
          reject();
        });
      });
      promises.push(promise);
    }

    Promise.all(promises).then(
      (values) => {
        this.checkFinish(true, callback);
      },
      (reason) => {
        this.checkFinish(false, callback);
      }
    );
  }

  checkData(data: any, callback: (isValid: boolean) => void) {
    this.checkStack.push({ data, callback });
    /* If stack contains more than one element - then chain of checks will be evaluated directly from inside of checking cycle */
    if (this.checkStack.length !== 1) {
      return;
    }
    this.recursiveCheck(data, callback);
  }

  setResult(data: any) {
    if (!data || typeof this.props.modal.options.checkFunction != "function") {
      this.setState({ result: data });
      return;
    }
    this.checkData(data, (isValid) => {
      if (!isValid) {
        return;
      }
      this.setState({ result: data });
    });
  }

  selectData(selectedRows: { [k: string]: boolean }, rowsData: RowData[]) {
    if (rowsData.length === 0) {
      this.setResult(null);
    } else {
      this.setResult(rowsData);
    }
  }

  getResult() {
    if (!this.state || !this.state.result) {
      return null;
    }
    if (this.props.modal.options.forcedSelectType === "radio") {
      return this.state.result[0].data;
    }
    return this.state.result.map((row: RowData) => row.data);
  }

  getBody() {
    let optional: any = {};
    if (this.props.modal.options.forcedSelectType) {
      optional.forcedSelectType = this.props.modal.options.forcedSelectType;
    }

    if (this.props.modal.options.parameters) {
      optional.forcedParameters = this.props.modal.options.parameters;
    }

    const Container =
      this.props.modal.options.type === "finder"
        ? null
        : // FinderContainer :
          TableContainer;

    // const style = Object.assign({}, tableStyle);
    if (!Container) {
      console.error("Finder wasn't implemented");
      return <></>;
    }

    const style: React.CSSProperties = {};
    style.height = this.props.modal.options.height || "40rem";

    //Create table component

    return (
      <div style={style} className="cim-table-modal">
        <AsyncTable
          table={this.props.modal.options.tableId}
          // forcedFields={optional}
          forcedFields={this.props.modal.options.parameters}
          paramsReadOnly={this.props.modal.options.paramsReadOnly}
          resetFields={true}
          toolbarHidden={!this.props.modal.options.showToolbar}
          onSelectionChange={this.selectData}
        />
      </div>
    );
  }

  renderTemplate(): React.ReactElement {
    return this.getBody();
  }

  closeModal(status: ModalStatus, result: any) {
    const data = this.getResult();
    const rows = [];
    if (this.props.rowByIdx) {
      for (let row of Object.values(this.props.rowByIdx)) {
        rows.push(row);
      }
    }
    if (status === MODAL_STATUS_CANCEL || status === MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, result, { rows });
      return;
    }
    if (status === MODAL_STATUS_OK && data) {
      this.props.closeModal(status, data, { rows });
      return;
    }
    this.props.closeModal(status, null, { rows });
  }

  render() {
    const modal = { ...this.props.modal };
    modal.options = { title: this.title, ...modal.options };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: { modal: TableModalInfo }) => {
    return {
      rowByIdx: state.table[ownProps.modal?.options?.tableId]?.rowByIdx,
    };
  }
)(CardTableModal);
