import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, ButtonGroup, FormControl, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useDrop } from "react-dnd";
import { ThunkDispatch } from "redux-thunk";
import { ADD_BUTTON_ICON_STYLE, REMOVE_BUTTON_ICON_STYLE } from "./Style";
import { MSG_TABLE_NO_DATA_TO_DISPLAY } from "../../messages";
import { TABLE_CLASS } from "../../admin/VirtualizedTable";
import { ApplicationAction, ApplicationState } from "../../../types";
import {
  getSubjectKey,
  isSubject,
  LayoutNode,
  RawLink,
  RefTableTreeOptions,
  SubjectComment,
  SubjectTableValue,
} from "../../../types/subject";
import {
  I18NString,
  TableModalOptions,
  TreeModalOptions,
} from "../../../types/modal";
import { RowDataMap } from "../../../types/table";
import { TreeNode } from "../../../types/tree";
import { NODE_DND_ID } from "../../../constants/tree";
import {
  addTableRefTable,
  addTreeRefTable,
  change,
  confirmItemRemoveModal,
  link,
  pasteRefTableModal,
  remove,
  safeCheckRef,
} from "../../../actions/subject";
import { obtainData } from "../../../services/automation";
import { isCardEdit, isEditable } from "../../../services/layout";

import BasicInput from "./BasicInput";
import LargeInput from "./LargeInput";
import SubjectLink from "./SubjectLink";
import ToolbarInput from "./ToolbarInput";
import { booleanFormatter } from "../cellFactory";

import styles from "./Table.module.css";

const DEFAULT_LINK_FORMAT = "objectcard/${$rdfId}";

/*https://jsfiddle.net/m3mjo0f2/6/*/
function evalExpr(s: string, vars: any) {
  return s.replace(/\$\{(.+?)\}/g, function (q, g1) {
    return vars[g1] || "";
  });
}

function refFormatter(ref: any, _: any, refExtra: any) {
  if (refExtra.disabled) {
    return (
      <Link to={"#"} className={refExtra.className} style={refExtra.style}>
        <FontAwesomeIcon icon={faEye} />
      </Link>
    );
  }
  const cp = refExtra.contextPath;
  let link = evalExpr(refExtra.link || DEFAULT_LINK_FORMAT, ref);
  if (link.startsWith("/")) {
    link = link.substring(1);
  }
  return (
    <SubjectLink
      to={`${cp}${link}`}
      target="_blank"
      className={refExtra.className}
      style={refExtra.style}
    >
      <FontAwesomeIcon icon={faEye} />
    </SubjectLink>
  );
}

function descrFormatter(cell: any, row: any) {
  let html = { __html: cell };
  return <div dangerouslySetInnerHTML={html} />;
}

// 59, label: 471, description: 648, ref: 118
export const DEFAULT_REF_TABLE_COLUMNS: any = {
  key: {
    getter: (ref: any) => ref?.$rdfId || "",
    label: "OBJECTCARD_RDF_ID",
    isKey: true,
    hidden: true,
    default: true,
  },
  num: {
    getter: (_: any, num: number) => num,
    label: "OBJECTCARD_REFTABLE_NUMBER",
    width: 59,
    default: true,
  },
  label: {
    getter: (ref: any) => ref?.$label || "",
    label: "OBJECTCARD_REFTABLE_NAME",
    width: 471,
    default: true,
  },
  description: {
    getter: (ref: any) => ref?.$description || "",
    label: "OBJECTCARD_REFTABLE_DESCRIPTION",
    dataFormat: descrFormatter,
    width: 648,
    default: true,
  },
  ref: {
    getter: (ref: any) => ref,
    label: "OBJECTCARD_REFTABLE_CARD",
    dataFormat: refFormatter,
    width: 90,
    dataAlign: "center",
    default: true,
  },
};

function parseTableRow(row: RowDataMap, fieldsMap?: { [k: string]: string }) {
  if (!fieldsMap) {
    return row;
  }
  const parsedRow = { ...row };
  for (let field in fieldsMap) {
    const newField = fieldsMap[field];
    parsedRow[newField] = row[field];
  }
  return parsedRow;
}

interface ReferenceTableProps {
  subjectKey: string;
  nodeId: string;
  visible: boolean;
  editable?: boolean;
  cardEditable?: boolean;
  values?: { [K: string]: any };
  subjectData?: { [K: string]: any };
  node?: LayoutNode;
  error?: I18NString | string;
  value: SubjectTableValue | SubjectTableValue[];
  contextPath?: string;
  wasTableUpdated?: boolean;
  valid?: boolean;
  comment?: SubjectComment;
  remove: (idxList: number[]) => void;
  removeModal: (callback: () => void) => void;
  safeCheckRef: (
    relatedClass: string,
    reference: any,
    callback: Function,
    omitErrors?: boolean
  ) => void;
  link: (data: any) => void;
  change: (
    subjectKey: string,
    nodeId: string,
    data: any,
    options?: any
  ) => void;
  pasteModal: (className: string, multiple: boolean) => void;
  treeModal: (options: TreeModalOptions) => void;
  tableModal: (options: TableModalOptions, modify: (data: any) => void) => void;
}

interface ReferenceTableStates {
  selected: { [num: number]: string };
  columnsWidths: { [columnName: string]: number };
  // it's necessary to know names of two nearest columns  and x coordinate between them
  //thanks to it we know what column(it's name)   has to be expanded and which one has to be narrowed down
  draggedColumns: {
    col1: string;
    col2: string;
    x: number;
  };
}

class ReferenceTable extends React.PureComponent<
  ReferenceTableProps,
  ReferenceTableStates
> {
  private headers: any = {};
  private rows: any[] = [];
  constructor(props: ReferenceTableProps) {
    super(props);

    this.state = {
      selected: {},
      columnsWidths: {},
      draggedColumns: {
        col1: "",
        col2: "",
        x: 0,
      },
    };

    this.onAdd = this.onAdd.bind(this);
  }

  componentDidMount() {
    this.initHeaders();
    this.initRows();
  }

  onRemove = () => {
    const { node, subjectKey } = this.props;
    if (!node) {
      return;
    }
    if (node.multiple) {
      this.props.remove(
        Object.keys(this.state.selected).map((k) => Number(k) - 1)
      );
    } else {
      this.props.change(subjectKey, node.id, {});
    }
    /*updComp(true);*/
    this.setState({ selected: {} });
  };

  onAdd(data: RowDataMap[] | RowDataMap) {
    const { node, link } = this.props;
    if (!node) {
      return null;
    }
    if (node.options.tree) {
      link(data);
    } else if (node.options.table || node.options.finder) {
      if (node.multiple) {
        for (let row of data as RowDataMap[]) {
          const parsedRow = parseTableRow(row, node.options.fieldsMap);
          link({
            $rdfId: parsedRow.key,
            $label: parsedRow.label,
            $description: parsedRow.description,
            $class: parsedRow.class,
          });
        }
      } else {
        let rowData = data as RowDataMap;
        const parsedRow = parseTableRow(rowData, node.options.fieldsMap);
        link({
          $rdfId: parsedRow.key,
          $label: parsedRow.label,
          $description: parsedRow.description,
          $class: parsedRow.class,
        });
      }
    }
  }

  addClicked() {
    const { node, tableModal } = this.props;
    if (!node) {
      return;
    }
    if (node.options.tree) {
      const options: TreeModalOptions = {
        treeId: node.options.tree,
        nodeTypeId: node.options.nodeTypeId,
        size: node.options.modalSize,
        height: node.options.height,
        className: node.options.cls,
        rootRdfId: node.options.rootRdfId,
        // checkFunction: (node, callback) => {
        //     return this.props.checkRef(this.props.node.options.cls, { $rdfId: node.$rdfId, $namespace: node.$namespace }, callback);
        // }
      };
      if (node.options.rootPredicate) {
        const predicatePath = node.options.rootPredicate.replace(/:/g, ".");
        let rootRdfId = obtainData(predicatePath, this.props.values);
        if (rootRdfId === null) {
          rootRdfId = obtainData(predicatePath, this.props.subjectData);
        }
        if (rootRdfId !== null) {
          options.rootRdfId = rootRdfId;
        }
      }
      this.props.treeModal(options);
    } else if (node.options.table || node.options.finder) {
      const parameters = {
        ...node.options.parameters,
        _ignoreLocationFields: "true",
      };
      if (node.options.predicateParameters) {
        for (let param in node.options.predicateParameters) {
          const predicatePath = node.options.predicateParameters[param].replace(
            /:/g,
            "."
          );
          let paramValue = obtainData(predicatePath, this.props.values);
          if (paramValue === null) {
            paramValue = obtainData(predicatePath, this.props.subjectData);
          }
          if (paramValue !== null) {
            parameters[param] = paramValue;
          }
        }
      }
      const options: TableModalOptions = {
        tableId: node.options.table || node.options.finder,
        forcedSelectType: node.multiple ? "checkbox" : "radio",
        parameters,
        size: node.options.modalSize,
        height: node.options.height,
        // filter: node.options.filter,
        type: node.options.finder ? "finder" : "table",
        // checkFunction: (row, callback) => {
        //     return this.props.checkRef(this.props.node.options.cls, { $rdfId: row.rdfId || row.key, $namespace: row.namespace }, callback);
        // }
        paramsReadOnly: true,
      };
      tableModal(options, this.onAdd);
    }
  }

  //Fix unkillable dragging
  clearSelection() {
    if (window.getSelection) {
      let selection = window.getSelection();
      if (selection == null) {
        return;
      }
      if (selection.empty) {
        // Chrome
        selection.empty();
      } else if (selection.removeAllRanges) {
        // Firefox
        selection.removeAllRanges();
      }
    }
  }
  ////////////////////DRAGGABLE COLUMNS/////////////////////////////

  getColumnIndex(columnName: string) {
    let i = 0;
    for (let col of this.headers) {
      if (col.key === columnName) {
        break;
      }
      i++;
    }
    return i;
  }
  //////////////////// END DRAGGABLE COLUMNS/////////////////////////////

  pasteClicked = () => {
    const { node } = this.props;
    if (!node || !node.options || !node.options.cls) {
      return;
    }
    const { cls } = node.options;
    this.props.pasteModal(node.options.cls, Boolean(node.multiple));
  };

  haveAddingFunction() {
    const { node } = this.props;
    if (!node) {
      return null;
    }
    return node.options.tree || node.options.table || node.options.finder;
  }

  getAddButton(showText: boolean) {
    if (!this.haveAddingFunction()) {
      return null;
    }
    return (
      <Button
        style={{ zIndex: 0 }}
        variant="secondary"
        className="px-1 border "
        aria-hidden="true"
        onClick={() => this.addClicked()}
      >
        <i
          className="fa fa-plus-circle fa-fw"
          style={ADD_BUTTON_ICON_STYLE}
          aria-hidden="true"
        ></i>
        {showText ? (
          <FormattedMessage
            id="OBJECTCARD_REFTABLE_ADD_BUTTON"
            defaultMessage="Add"
            description="User should click this button to add items into the table"
          />
        ) : null}
      </Button>
    );
  }

  getRemoveButton(showText: boolean, removeDisabled: boolean) {
    return (
      <Button
        style={{ zIndex: 0 }}
        className="px-1 border "
        variant="secondary"
        onClick={() => {
          this.props.removeModal(this.onRemove); /*updComp(true)*/
        }}
        disabled={removeDisabled}
      >
        <i
          className="fa fa-minus-circle fa-fw"
          style={REMOVE_BUTTON_ICON_STYLE}
          aria-hidden="true"
        ></i>
        {showText ? (
          <FormattedMessage
            id="OBJECTCARD_REFTABLE_REMOVE_BUTTON"
            defaultMessage="Remove"
            description="User should click this button to remove items from table"
          />
        ) : null}
      </Button>
    );
  }

  getPasteButton(showText: boolean) {
    const style = showText ? {} : { padding: "2px" };
    return (
      <button
        type="button"
        className="btn btn-secondary"
        onClick={this.pasteClicked}
        style={{ ...style, zIndex: 0 }}
      >
        <i className="fa fa-paste fa-fw" aria-hidden="true"></i>
        {showText && (
          <FormattedMessage
            id="OBJECTCARD_REFTABLE_PASTE_BUTTON"
            defaultMessage="Paste"
            description="User should click this button to add items into the table via references paste"
          />
        )}
      </button>
    );
  }

  getToolbar() {
    const removeDisabled = Object.keys(this.state.selected).length == 0;
    return (
      <ButtonGroup className="pull-right " role="group" aria-label="...">
        {this.getPasteButton(true)}
        {this.getAddButton(true)}
        {this.getRemoveButton(true, removeDisabled)}
      </ButtonGroup>
    );
  }

  getToolButton() {
    const { node, contextPath, value } = this.props;
    if (!node || Array.isArray(value)) {
      return null;
    }
    const editable = this.isEditable();
    const hasValue = value && value.$rdfId ? true : false;

    let disabled = true;
    if (!editable) {
      let link = node.options.link;

      if (hasValue) {
        //We have some value
        disabled = false;
        link = evalExpr(link || DEFAULT_LINK_FORMAT, value);
        if (link.startsWith("/")) {
          link = link.substring(1);
        }
      }
      let path = disabled ? "#" : `${contextPath}${link}`;

      return (
        <Button
          style={{ zIndex: 0 }}
          variant="outline-secondary"
          className="p-0  border d-flex flex-column justify-content-center  "
          disabled={disabled}
        >
          <SubjectLink
            to={path}
            className="  px-2 pt-2 d-flex flex-column align-items-center h-100 "
          >
            {" "}
            <i
              className={"fa fa-eye align-middle text-dark"}
              aria-hidden="true"
            ></i>{" "}
          </SubjectLink>
        </Button>
      );
    }

    //////////////////////
    //Editable reference//
    //////////////////////
    if (hasValue) {
      //We have some value
      return this.getRemoveButton(false, false);
    }
    return (
      <ButtonGroup>
        {this.getAddButton(false)}
        {this.getPasteButton(false)}
      </ButtonGroup>
    );
  }

  /////////////////////DEFAULT PARAMS
  getRefExtra(
    className: string = "",
    style: any = {},
    disabled: boolean = false
  ) {
    //////////////////
    if (!this.props.node) {
      return {};
    }
    /////////////////
    return {
      contextPath: this.props.contextPath,
      link: this.props.node.options.link,
      className,
      style,
      disabled,
    };
  }

  isEditable() {
    const { node, editable } = this.props;
    return editable;
  }

  rowClassName = ({ index }: any) => {
    const { editable } = this.props;
    const className = editable ? styles.rowHover : "";
    const selectedStyle = this.state.selected[index + 1]
      ? styles.selectedRow
      : "";
    if (index < 0) {
      return ` ${styles.headerRow}   text-dark ${selectedStyle}`;
    } else if (index % 2 !== 0) {
      // return TABLE_BODY_ROW_CLASS;
      return `  ${styles.bodyRow} ${className}`;
    } else {
      return ` ${styles.bodyRow} ${className}`;
    }
  };

  // //if grid width equals to header width
  // //right borders of body rows is not visible
  // //so we increase   width of grid a bit (to 1 %)
  gridStyle(width: number, height: number) {
    const perc = width / 100;
    const newWidth = width + perc + "px";
    return { width: newWidth, height };
  }

  isColumnHidden(columnName: string) {
    return columnName === "key" ? true : false;
  }

  addCheckable() {
    if (this.props.editable && !this.headers["check"]) {
      const headerEntries = Object.entries(this.headers);
      headerEntries.unshift(["check", { key: "check", checkable: true }]);
      this.headers = Object.fromEntries(headerEntries);
    } else if (!this.props.editable && this.headers["check"]) {
      delete this.headers["check"];
    }
  }
  parseColumn(col: any) {
    if (col.field) {
      return {
        key: col.field,
        label: col.label,
      };
    }
    return null;
  }

  initHeaders() {
    const { node, editable } = this.props;
    let headers =
      node && node.options.columns
        ? node.options.columns
        : [
            //Default columns
            // { "field": "key" },
            { key: "label" },
            { key: "description" },
            { key: "ref" },
          ];

    if (node?.options?.addCountColumn) {
      headers.unshift({ key: "num" });
    }

    if (Array.isArray(headers)) {
      headers.forEach((e) => {
        const newCol = { ...e };
        if (newCol.field) {
          const tKey = newCol.field;
          delete newCol["field"];
          newCol["key"] = tKey;
        }
        const key = newCol.key || newCol.field;
        if (key) {
          const data = DEFAULT_REF_TABLE_COLUMNS[key] || newCol;
          data && (newCol["data"] = data);
        }
        this.headers[key] = newCol;
        // return newCol;
      });
    }

    // this.headers = [...headers];
    // return headers;
  }

  initRows() {
    const { value } = this.props;
    if (!Array.isArray(value) || value.length == 0) {
      this.rows = [];
      return;
    }
    const columns = this.headers;
    const rows = [];
    for (let idx = 0; idx < value.length; idx++) {
      const ref = value[idx];
      const num = idx + 1;
      const row: any = {};
      for (let c of Object.values(columns)) {
        const col = c as any;
        if (col.path) {
          row[col.key] = obtainData(col.path, ref);
        } else {
          // const defaultColumn = DEFAULT_FIELDS[col.field];
          const defaultColumn = col.data;
          if (defaultColumn) {
            row[col.key] = defaultColumn.getter(ref, num);
          } else {
            row[col.key] = "";
          }
        }
      }
      row["num"] = num;
      rows.push(row);
    }

    this.rows = [...rows];
  }

  selectHandler = (num: number, id: string) => {
    let newSelected: { [num: number]: string } = { ...this.state.selected };
    if (newSelected[num]) {
      delete newSelected[num];
    } else {
      newSelected[num] = String(id);
    }
    this.setState({ selected: newSelected });
  };
  selectAll = () => {
    const { value } = this.props;
    if (Array.isArray(value)) {
      if (Object.keys(this.state.selected).length !== 0) {
        this.setState({ selected: {} });
        return;
      }
      let newSelected = { ...this.state.selected };
      value.forEach((v: any, idx: number) => {
        if (v.$tmpKey) {
          newSelected[idx + 1] = v.$tmpKey;
        } else if (v.$rdfId) {
          newSelected[idx + 1] = v.$rdfId;
        }
      });
      this.setState({ selected: newSelected });
    }
  };

  renderTable() {
    const editable = this.isEditable();
    // const columns = this.getColumns();
    // const rows = this.getRows();
    this.initHeaders();
    this.initRows();
    this.addCheckable();
    // const contents = this.tableComponent(columns, this.getRows())
    const { node, visible, cardEditable, comment, error } = this.props;

    const table = (
      <RefTableDropzone {...this.props}>
        <TableComponent
          editable={editable}
          getRowCssClass={this.rowClassName}
          referenceFormatter={this.getRefExtra.bind(this)}
          columns={this.headers}
          rows={this.rows}
          select={this.selectHandler}
          selectAll={this.selectAll}
          selected={this.state.selected}
        />
      </RefTableDropzone>
    );
    const toolbar = this.getToolbar();
    if (!node) {
      return null;
    }

    if (editable) {
      return (
        <ToolbarInput
          id={this.props.subjectKey + "." + this.props.nodeId}
          node={node}
          error={error}
          visible={visible}
          editable={editable}
          cardEditable={cardEditable}
          comment={comment}
        >
          {toolbar}
          {table}
        </ToolbarInput>
      );
    }
    return (
      <LargeInput
        id={this.props.subjectKey + "." + this.props.nodeId}
        node={node}
        error={error}
        visible={visible}
        editable={editable}
        cardEditable={cardEditable}
        comment={comment}
      >
        {table}
      </LargeInput>
    );
  }

  renderInput() {
    const {
      node,
      value: val,
      visible,
      error,
      editable,
      cardEditable,
      valid,
      comment,
    } = this.props;
    if (Array.isArray(val)) {
      return null;
    }
    const value = (val && val.$label) || "--";

    if (!node) {
      return null;
    }
    const validClass = !valid ? "is-invalid" : "border";
    return (
      <BasicInput
        id={this.props.subjectKey + "." + this.props.nodeId}
        editable={editable}
        cardEditable={cardEditable}
        error={error}
        node={node}
        visible={visible}
        comment={comment}
      >
        <RefTableDropzone {...this.props} className="mb-1">
          <InputGroup>
            <FormControl
              type="text"
              className={`form-control card-input   ${validClass}`}
              disabled={true}
              value={value}
            />
            <InputGroup.Append>{this.getToolButton()}</InputGroup.Append>
          </InputGroup>
        </RefTableDropzone>
      </BasicInput>
    );
  }

  render() {
    if (!this.props.node) {
      return null;
    }
    if (this.props.node.multiple) {
      const table = this.renderTable();
      return table;
    }
    const inputComp = this.renderInput();
    return inputComp;
  }
}

interface RefTableDropzoneProps extends ReferenceTableProps {
  className?: string;
}
const RefTableDropzone: React.FunctionComponent<RefTableDropzoneProps> =
  React.memo((props) => {
    const [linksFetchMap, setLinksFetchMap] = React.useState<{
      [k: string]: boolean;
    }>({});
    const [linksCheckMap, setLinksCheckMap] = React.useState<{
      [k: string]: boolean;
    }>({});
    /**Add special fetch info to correctly display canDrop value with async request */
    const [fetchDropInfo] = React.useState<{ canDrop: boolean }>({
      canDrop: false,
    });
    const [{ canDrop, isOver }, drop] = useDrop(
      {
        accept: NODE_DND_ID,
        drop: (item: TreeNode) => props.link(item.data),
        canDrop: (item, monitor) => {
          if (!props.editable || !props.node?.options.cls || !item.data) {
            return false;
          }

          const linkKey = getSubjectKey(item.data.$rdfId, item.data.$namespace);
          if (typeof linksCheckMap[linkKey] !== "undefined") {
            return linksCheckMap[linkKey];
          }

          return false;
        },
        hover: (item, monitor) => {
          if (!props.editable || !props.node?.options.cls || !item.data) {
            return;
          }
          const linkKey = getSubjectKey(item.data.$rdfId, item.data.$namespace);
          if (
            typeof linksCheckMap[linkKey] !== "undefined" ||
            linksFetchMap[linkKey]
          ) {
            return;
          }
          /**Directly modify fetch map to prevent double fetch */
          linksFetchMap[linkKey] = true;

          props.safeCheckRef(
            props.node?.options.cls,
            item.data as RawLink,
            (
              result:
                | { failed: boolean; data: any; error?: undefined }
                | { failed: boolean; error: any; data?: undefined }
            ) => {
              if (
                result.failed &&
                (!result.error.code ||
                  result.error.code === 403 ||
                  result.error.code === 500)
              ) {
                /**Do not save invalid fetch result*/
                fetchDropInfo.canDrop = false;
                linksFetchMap[linkKey] = false;
                return;
              }
              /**Update special drop info to fetched value */
              fetchDropInfo.canDrop = !result.failed;
              setLinksCheckMap({ ...linksCheckMap, [linkKey]: !result.failed });
            },
            true
          );
        },
        // Props to collect
        collect: (monitor) => ({
          canDrop: (() => {
            const canDrop = monitor.canDrop();
            /**Reset special drop info to collected value */
            fetchDropInfo.canDrop = canDrop;
            return canDrop;
          })(),
          isOver: monitor.isOver({ shallow: true }),
        }),
      },
      [
        props.node?.options.nodeTypeId,
        props.editable,
        linksFetchMap,
        linksCheckMap,
      ]
    );

    return (
      <div
        className={`${styles.refTableDropzone} ${
          isOver && fetchDropInfo.canDrop
            ? `${styles.glow} glow-success`
            : "glow-none"
        } ${props.className ? props.className : ""}`}
        ref={drop}
      >
        {props.children}
      </div>
    );
  });

export default connect(
  (
    state: ApplicationState,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const { subjectKey, nodeId } = ownProps;
    const subject = state.subject && state.subject.subjects[subjectKey];
    if (!isSubject(subject)) {
      return { visible: false };
    }
    const error = subject.validation[nodeId];
    const node = subject && subject.nodeById[nodeId];
    const value = subject && subject.values[nodeId];
    const cardEditable = isCardEdit(subject);
    const editable = isEditable(subject, nodeId, cardEditable);
    const wasTableUpdated =
      subject.componentUpdated && subject.componentUpdated[nodeId];
    const visible = subject.visibility[ownProps.nodeId] ? true : false;
    let valid = !error || !cardEditable ? true : false;
    let mandatory = subject.mandatorySet[node.id];
    if (
      mandatory &&
      (!value || (typeof value === "object" && Object.keys(value).length === 0))
    ) {
      valid = false;
    }
    return {
      subjectData: subject.subjectData,
      values: subject.values,
      node,
      value,
      editable,
      cardEditable,
      contextPath: state.location.contextPath,
      wasTableUpdated,
      visible,
      error,
      valid,
      comment: subject.comment[nodeId],
    };
  },
  (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const { subjectKey, nodeId } = ownProps;
    return {
      safeCheckRef: (
        relatedClass: string,
        reference: any,
        callback: Function,
        omitErrors?: boolean
      ) =>
        dispatch(safeCheckRef(relatedClass, reference, callback, omitErrors)),
      link: (data: any) => dispatch(link(subjectKey, nodeId, data)),
      tableModal: (options: TableModalOptions, modify: (data: any) => void) =>
        dispatch(addTableRefTable(options, modify)),
      change: (subjectKey: string, nodeId: string, data: any, options?: any) =>
        dispatch(change(subjectKey, nodeId, data, options)),
      remove: (idxList: number[]) =>
        dispatch(remove(subjectKey, nodeId, idxList)),
      removeModal: (callback: () => void) =>
        dispatch(confirmItemRemoveModal(callback)),
      pasteModal: (className: string, multiple: boolean) =>
        dispatch(pasteRefTableModal(subjectKey, nodeId, className, multiple)),
      treeModal: (options: TreeModalOptions) =>
        dispatch(addTreeRefTable(subjectKey, nodeId, options)),
    };
  }
)(ReferenceTable);

interface TableComponentProps {
  editable?: boolean;
  columns: any;
  rows: any[];
  selected?: { [num: number]: string };
  select: (num: number, id: string) => void;
  selectAll: () => void;
  getRowCssClass: (index: any) => string;
  referenceFormatter: (
    className?: string,
    style?: any,
    disabled?: boolean
  ) => void;
}
class TableComponent extends React.Component<TableComponentProps> {
  constructor(props: TableComponentProps) {
    super(props);
    this.bodyCellRenderer = this.bodyCellRenderer.bind(this);
  }

  noDataRowRenderer() {
    const { columns } = this.props;
    const colspan = Object.values(columns).length;
    return (
      <tr>
        <th colSpan={colspan}>
          <div className="text-center">{MSG_TABLE_NO_DATA_TO_DISPLAY}</div>
        </th>
      </tr>
    );
  }

  isColumnHidden(col: any) {
    return col?.data?.hidden;
  }

  renderHeaderColumn = (columnData: any) => {
    if (columnData.checkable) {
      const checked =
        this.props.selected &&
        this.props.rows.length > 0 &&
        this.props.rows.length === Object.keys(this.props.selected).length;

      return (
        <div className="pl-1">
          {" "}
          <input
            className={styles.check}
            type="checkbox"
            checked={checked}
            onChange={() => this.props.selectAll()}
          />
        </div>
      );
    }
    if (!columnData?.data) {
      return null;
    }

    const label = columnData.data.label || columnData.key;
    const cell = columnData.data.default ? (
      <div>
        {" "}
        <FormattedMessage id={label} />
      </div>
    ) : (
      label
    );
    return cell;
  };

  headerCellRenderer = (data: any) => {
    const cell = this.renderHeaderColumn(data);

    return (
      <th
        key={data?.key}
        scope="col px-2 "
        className={`npt-table-header-column`}
      >
        <div className="w-100 d-flex flex-column justify-content-center">
          {cell}
        </div>
      </th>
    );
  };

  renderHeaderColumns() {
    const { columns, rows } = this.props;
    const headerColumns = [];
    for (let c of Object.values(columns)) {
      const column = c as any;
      if (!this.isColumnHidden(column)) {
        headerColumns.push(this.headerCellRenderer(column));
      }
    }
    return headerColumns;
  }

  bodyCellRenderer(key: string, columnData: any, data: any) {
    const { referenceFormatter, columns, selected, select, rows } = this.props;
    if (columnData.checkable) {
      const checked = selected && selected[data.num];
      return (
        <td key={key}>
          <div className="pl-1">
            {" "}
            <input
              className={styles.check}
              type="checkbox"
              checked={!!checked}
              onChange={() => select(data.num, data.ref.$rdfId)}
            />
          </div>
        </td>
      );
    }
    let label = data[key];
    if (label && typeof label === "object" && !Object.keys(label).length) {
      label = null;
    }
    if (!label) {
      return <td key={key}> </td>;
    }

    const dataAlign = columnData?.data?.dataAlign;
    const dataFormat = columnData?.data?.dataFormat;
    if (dataFormat) {
      label = dataFormat(label, null, referenceFormatter());
    }

    if (typeof label === "boolean") {
      label = booleanFormatter(label);
    }

    const style: any = {};
    if (dataAlign) {
      style["textAlign"] = dataAlign;
    }

    return (
      <td key={key}>
        <div style={style}>{label.$label || label}</div>
      </td>
    );
  }

  bodyRowRenderer(row: any, index: number) {
    const { columns } = this.props;
    const rowData = [];
    const colEntries = Object.entries(columns);
    for (let c of colEntries) {
      const [key, value] = c;
      const column = value as any;
      const hidden = column?.data?.hidden;
      if (hidden) {
        continue;
      }
      rowData.push(this.bodyCellRenderer(key, value, row));
    }
    return <tr key={index}>{rowData}</tr>;
  }

  render() {
    const { rows, editable } = this.props;

    return (
      <div className={`${TABLE_CLASS} ${styles.cardTable}   `}>
        <div
          className={`rounded bg-white w-100 npt-objectcard-compound-table ${styles.compoundTable}`}
        >
          <table
            className={`table mb-0 table-bordered table-hover ${styles.table}`}
          >
            <thead className={styles.header}>
              <tr>{this.renderHeaderColumns()}</tr>
            </thead>
            <tbody>
              {rows.length === 0
                ? this.noDataRowRenderer()
                : rows.map((r, idx) => this.bodyRowRenderer(r, idx))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
