//Actions
export const OPEN_MODAL = "SEND_MODAL_OPEN";
export type OPEN_MODAL = typeof OPEN_MODAL;

export const UPDATE_MODAL = "SEND_MODAL_UPDATE";
export type UPDATE_MODAL = typeof UPDATE_MODAL;

export const UPDATE_MODAL_TITLE = "SEND_UPDATE_MODAL_TITLE";
export type UPDATE_MODAL_TITLE = typeof UPDATE_MODAL_TITLE;

export const CLOSE_MODAL = "SEND_MODAL_CLOSE";
export type CLOSE_MODAL = typeof CLOSE_MODAL;

//Status
export const MODAL_STATUS_OK = "OK";
export type MODAL_STATUS_OK = typeof MODAL_STATUS_OK;

export const MODAL_STATUS_CANCEL = "CANCEL";
export type MODAL_STATUS_CANCEL = typeof MODAL_STATUS_CANCEL;

export const MODAL_STATUS_CLOSE = "CLOSE";
export type MODAL_STATUS_CLOSE = typeof MODAL_STATUS_CLOSE;